export const CURRENCY_SELECT = [
  {
    value: 'zl',
    name: 'zl',
    label: 'zł',
  },
  {
    value: 'euro',
    name: 'euro',
    label: 'euro',
  },
];

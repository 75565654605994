import { object } from "prop-types";
import React from "react";

const Word = ({data}) => (
  <div className="productNameWrapper">
    <div className="dictionary__word">{data?.word}</div>
  </div>
);

export default Word;

Word.propTypes = {
  data: object.isRequired,
};
import React from 'react';
import { SVG, Button } from 'shared/components';
import { SVG_TYPE, ROUTES } from 'shared/consts';
import { useHistory } from 'react-router-dom';

const SendActiveMail = ({ intl: { formatMessage } }) => {
  const history = useHistory();

  return (
    <main className="login__wrapper">
      <div className="login__logo">
        <SVG type={SVG_TYPE.LOGO} />
      </div>
      <div className="login__form">
        <div className="login__item login__item--send">
          <h1 className="login--header login--header--small">{formatMessage({ id: 'global.confirm_account_creation' })}</h1>
          <p className="login__paragraph login__paragraph--black">{formatMessage({ id: 'global.sending_link' })}</p>
          <Button
            className="btn--orange"
            label={formatMessage({ id: 'inputs.login' })}
            type="submit"
            onClick={() => history.push(ROUTES.LOGIN)}
          />
        </div>
      </div>
    </main>
  );
};

export default SendActiveMail;

import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { TRANSFER_STATUSES, TRANSFER_STATUSES_TRANSLATION } from 'screens/magazine/utils/consts';
import PropTypes from 'prop-types';
import moment from 'moment';

const TransferStatus = ({ status, isMobile, date, formatMessage }) => (
  <>
    <div className="transferStatus">
      <div>
        <SVG type={SVG_TYPE[TRANSFER_STATUSES[status]]} />
      </div>
      {!isMobile && (
        <>
          <div>{formatMessage({ id: TRANSFER_STATUSES_TRANSLATION[status] })}</div>
          <div className="transferStatus__date">{moment(date).format('DD.MM.YYYY')}</div>
        </>
      )}
    </div>
  </>
);

export default TransferStatus;

TransferStatus.propTypes = {
  date: PropTypes.string.isRequired,
  formatMessage: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};

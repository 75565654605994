import React from 'react';
import IMG from 'assets/files/image.jpg';
import { SVG } from 'shared/components';
import { bool, func } from 'prop-types';
import { SVG_TYPE } from 'shared/consts';

const LISTS = formatMessage => [
  {
    heading: formatMessage({ id: 'global.how_it_works_1_heading' }),
    image: IMG,
    paragraph: formatMessage({ id: 'global.how_it_works_1_paragraph' }),
  },
  {
    heading: formatMessage({ id: 'global.how_it_works_2_heading' }),
    image: IMG,
    paragraph: formatMessage({ id: 'global.how_it_works_2_paragraph' }),
  },
  {
    heading: formatMessage({ id: 'global.how_it_works_3_heading' }),
    image: IMG,
    paragraph: formatMessage({ id: 'global.how_it_works_3_paragraph' }),
  },
];

const HowItWorks = ({ isMobile, formatMessage }) => (
  <div className="partnerProgram__howitworks">
    <p className="partnerProgram__howitworks--heading">{formatMessage({ id: 'global.how_it_works' })}</p>
    <div className="partnerProgram__howitworks--lists">
      {LISTS(formatMessage).map(({ heading, image, paragraph }, idx) => (
        <div className="partnerProgram__howitworks--single">
          <div className="partnerProgram__howitworks__image">
            <img className="partnerProgram__howitworks--photo" src={image} alt="takie tam kurde" />
          </div>
          <div className="partnerProgram__info">
            <h4 className="partnerProgram__howitworks__info--step">{heading}</h4>
            <p className="partnerProgram__howitworks__info--paragraph">{paragraph}</p>
          </div>
          {isMobile && LISTS(formatMessage).length !== idx + 1 && (
            <div className="partnerProgram__arrow">
              <SVG type={SVG_TYPE.LONG_ARROW} />
            </div>
          )}
        </div>
      ))}
    </div>
  </div>
);

export default HowItWorks;

HowItWorks.propTypes = {
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
};

import React from 'react';
import * as Connector from 'shared/connectors/priceConnector';
import { PRICE } from 'store/types';
import { NOTIFY_TYPE } from 'shared/consts';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';

const init = () => ({
  type: PRICE.EDIT_CURRENCY_EXCHANGE_INIT,
});

export const success = () => ({
  type: PRICE.EDIT_CURRENCY_EXCHANGE_SUCCESS,
});

const failure = () => ({
  type: PRICE.EDIT_CURRENCY_EXCHANGE_FAILURE,
});

const editCurrencyExchange = (uuid, value) => async dispatch => {
  dispatch(init());
  try {
    await Connector.editCurrencyExchange(uuid, value.item);
    toast(<Notify label="global.successful_edit_currency" type={NOTIFY_TYPE.CHECKED} />);
    return true;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default editCurrencyExchange;

import React from 'react';
import { Field } from 'formik';
import { RegularField } from 'shared/components';
import { object, func } from 'prop-types';

const Localization = ({ values, setFieldValue, errors, formatMessage, delivery, form }) => {
  const { delivery_uuid } = values;
  const handleDeliveryMethod = uuid => {
    if (delivery_uuid.includes(uuid)) {
      const elementIndex = delivery_uuid.indexOf(uuid);
      delivery_uuid.splice(elementIndex, 1);
      setFieldValue('delivery_uuid', delivery_uuid);
    } else {
      setFieldValue('delivery_uuid', [...delivery_uuid, uuid]);
    }
  };

  const checkIsDeliveryActive = uuid => {
    if (delivery_uuid.includes(uuid)) {
      return true;
    }
    return false;
  };

  const isError = form && !!(form.touched.delivery_uuid && errors.delivery_uuid);

  return (
    <section>
      <h3 id="validate--delivery_uuid" className="heading">
        {formatMessage({ id: 'global.localization_delivery' })}
      </h3>
      <div className="advertisement-localization__wrapper">
        <Field
          className="add-advertisement__half-input"
          placeholder={formatMessage({ id: 'global.enter_address' })}
          component={RegularField}
          errors={errors}
          label={formatMessage({ id: 'global.localization_product' })}
          name="location"
        />
        <div className="advertisement-localization__gray">
          <p>{formatMessage({ id: 'global.popular_delivery' })}</p>
          <p>{formatMessage({ id: 'global.suggested_cost' })}</p>
        </div>
        <div className="advertisement-localization__list">
          {delivery.map(({ name, price, uuid, currency }) => (
            <div className="advertisement-localization__item">
              <div
                className={`advertisement-localization__item--label ${
                  checkIsDeliveryActive(uuid) ? 'advertisement-localization__item--label--active' : ''
                }`}
                onClick={() => handleDeliveryMethod(uuid)}
              >
                {name}
              </div>
              <div className="advertisement-localization__item--price-wrapper">
                {price} {currency.toUpperCase()}
              </div>
            </div>
          ))}
        </div>
      </div>
      {isError && <div className="advertisement-localization--error">{errors.delivery_uuid}</div>}
    </section>
  );
};

export default Localization;

Localization.propTypes = {
  delivery: object.isRequired,
  errors: object.isRequired,
  form: object.isRequired,
  formatMessage: func.isRequired,
  setFieldValue: func.isRequired,
  values: object.isRequired,
};

import * as Connector from 'shared/connectors/allegroConnector';
import { ALLEGRO } from 'store/types';

const init = () => ({
  type: ALLEGRO.GET_ALLEGRO_USER_INIT,
});

export const success = () => ({
  type: ALLEGRO.GET_ALLEGRO_USER_SUCCESS,
});

const failure = () => ({
  type: ALLEGRO.GET_ALLEGRO_USER_FAILURE,
});

const getUrl = code => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getAllegroUser(code);
    dispatch(success(data));
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default getUrl;

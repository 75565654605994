import React from 'react';
import { func, number } from 'prop-types';

const ProgressIndicator = ({activeNumber, formatMessage}) => (
  <div className='progress-indicator__wrapper'>
    <div className="progress-indicator">
      <div className='point__wrapper'>
        <div className="point point--active">
          1
        </div>
        <p className='point__label point__label--active'>{formatMessage({ id: 'global.shopping_cart' })}</p>
      </div>

      <div className={`line ${activeNumber >= 2 ? 'line--active' : ''}`} />

      <div className='point__wrapper'>
        <div className={`point ${activeNumber >= 2 ? 'point--active' : ''}`}>
          2
        </div>
        <p className={`point__label ${activeNumber >= 2 ? 'point__label--active' : ''}`}>{formatMessage({ id: 'global.address_and_delivery_method' })}</p>
      </div>

      <div className={`line ${activeNumber >= 3 ? 'line--active' : ''}`} />

      <div className='point__wrapper'>
        <div className={`point ${activeNumber >= 3 ? 'point--active' : ''}`}>
          3
        </div>
        <p className={`point__label ${activeNumber >= 3 ? 'point__label--active' : ''}`}>{formatMessage({ id: 'global.summary' })}</p>
      </div>

    </div>
  </div>
)

export default ProgressIndicator;

ProgressIndicator.defaultProps = {
  activeNumber: 0,

}
ProgressIndicator.propTypes = {
  activeNumber: number,
  formatMessage: func.isRequired,
}
const deliveryPriceCounting = (setDeliveryPrice, deliveryPrice, delivery, product, foreign_country) => {
  if (!deliveryPrice) {
    setDeliveryPrice({ arr: [], price: 0 });
  } else {
    const { price, name } = delivery;
    const { user_id, uuid, id } = product;
    const { arr } = deliveryPrice;
    let isSetDeliveryFromThisUser = [];
    if (foreign_country) {
      isSetDeliveryFromThisUser = deliveryPrice.arr?.find(item => item.id === id);
    } else {
      isSetDeliveryFromThisUser = deliveryPrice.arr?.find(item => item.user_id === user_id);
    }
    if (isSetDeliveryFromThisUser) {
      const isProductInArray = isSetDeliveryFromThisUser.products.find(item => item.product_uuid === uuid);
      let seller;
      if (foreign_country) {
        seller = arr.find(item => item.id === id);
      } else {
        seller = arr.find(item => item.user_id === user_id);
      }
      if (isProductInArray) {
        const afterFilter = seller.products.filter(item => item.product_uuid !== uuid);
        seller.products = [];
        seller.products.push(...afterFilter, { name, price, product_uuid: uuid });
        const totalPriceArr = [];
        arr.forEach(item => {
          const uniqueObjArray = [...new Map(item.products.map(el => [el.name, el])).values()];
          const priceArr = [];
          uniqueObjArray.forEach(el => priceArr.push(el.price));
          const pricing = priceArr.reduce(
            (total, arg) =>
              parseFloat(typeof total === 'string' ? total.replaceAll(',', '.') : total) +
              parseFloat(typeof arg === 'string' ? arg.replaceAll(',', '.') : arg),
          );
          totalPriceArr.push(pricing);
        });
        const totalPrice = totalPriceArr.reduce(
          (total, arg) =>
            parseFloat(typeof total === 'string' ? total.replaceAll(',', '.') : total) +
            parseFloat(typeof arg === 'string' ? arg.replaceAll(',', '.') : arg),
        );
        setDeliveryPrice({
          price: parseFloat(typeof totalPrice === 'string' ? totalPrice.replaceAll(',', '.') : totalPrice),
          arr: [...arr],
        });
        console.log(1);
      } else {
        seller.products.push({ name, price, product_uuid: uuid });
        setDeliveryPrice({ price: deliveryPrice.price, arr: [...arr] });
        const thisSameDelivery = isSetDeliveryFromThisUser.products.filter(item => item.name === name);
        if (thisSameDelivery.length > 1) {
          console.log('');
        } else {
          console.log(2);
          setDeliveryPrice({
            price:
              parseFloat(
                typeof deliveryPrice.price === 'string' ? deliveryPrice.price.replaceAll(',', '.') : deliveryPrice.price,
              ) + parseFloat(price.replaceAll(',', '.')),
            arr: [...arr],
          });
        }
      }
    } else {
      setDeliveryPrice({
        price:
          parseFloat(typeof deliveryPrice.price === 'string' ? deliveryPrice.price.replaceAll(',', '.') : deliveryPrice.price) +
          parseFloat(typeof price === 'string' ? price.replaceAll(',', '.') : price),
        arr: foreign_country
          ? [...arr, { id, products: [{ name, price, product_uuid: uuid }] }]
          : [...arr, { user_id, products: [{ name, price, product_uuid: uuid }] }],
      });
      console.log(
        parseFloat(typeof deliveryPrice.price === 'string' ? deliveryPrice.price.replaceAll(',', '.') : deliveryPrice.price) +
          parseFloat(typeof price === 'string' ? price.replaceAll(',', '.') : price),
      );
    }
  }
};

export default deliveryPriceCounting;

import React from 'react';
import { Field } from 'formik';
import { RegularField } from 'shared/components';
import PropTypes from 'prop-types';

const PriceAndCount = ({ errors, formatMessage, currency }) => (
  <section>
    <h3 className="heading">{formatMessage({ id: 'global.price_and_count' })}</h3>
    <div className="add-advertisement__price">
      <div className="add-advertisement__price--currency">
        <Field
          className="add-advertisement__select"
          placeholder="0.00"
          component={RegularField}
          errors={errors}
          label={formatMessage({ id: 'global.price' })}
          name="price"
        />
        <p>{currency.length > 0 && currency[0].currency.toUpperCase()}</p>
      </div>
      <Field
        className="add-advertisement__select"
        placeholder="5"
        component={RegularField}
        label={formatMessage({ id: 'global.number_of_items' })}
        name="amount"
        errors={errors}
      />
    </div>
  </section>
);

export default PriceAndCount;

PriceAndCount.propTypes = {
  currency: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  formatMessage: PropTypes.func.isRequired,
};

import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getAuthorizedUser } from 'store/selectors/userSelector';
import PaymentPayLater from './PaymentPayLater.main';

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
    authorizedUser: getAuthorizedUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PaymentPayLater));

import { connect } from 'react-redux';
import { isMobile as checkIsMobile } from 'shared/utils';
import { injectIntl } from 'react-intl';
import {
  getUsersByRole,
  userSuspension,
  userResendLink,
  editUser,
  editUserByAdmin,
  getUsers,
  addUserByAdmin,
  getUsersForApplication,
  getUserDetails,
  userByAdminResendLink,
} from 'store/actions/user';
import {
  getUsersByRole as getUsersByRoleSelectors,
  getUserDetails as getUserDetailsSelector,
  getUsers as getUsersSelector,
  getUsersForApplication as getUsersForApplicationSelector,
} from 'store/selectors/userSelector';
import { getPartnerRequests as getPartnerRequestsSelectors } from 'store/selectors/partnerSelector';
import { bindActionCreators } from 'redux';
import { getDeliveryCountries } from 'store/actions/delivery';
import { getDeliveryCountries as getDeliveryCountriesSelector } from 'store/selectors/deliverySelector';
import * as ViewManagementSelector from 'store/selectors/viewManagementSelector';
import { getPartnerRequests, managingPartnerApplication } from 'store/actions/partner';
import UserManagement from './UserManagement.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getUsersByRole: role => getUsersByRole(role),
      userSuspension: (uuid, group) => userSuspension(uuid, group),
      userResendLink: email => userResendLink(email),
      editUser: (uuid, form, group) => editUser(uuid, form, group),
      editUserByAdmin: (uuid, form, group) => editUserByAdmin(uuid, form, group),
      getPartnerRequests: () => getPartnerRequests(),
      managingPartnerApplication: data => managingPartnerApplication(data),
      getUsers: () => getUsers(),
      getDeliveryCountries: () => getDeliveryCountries(),
      addUserByAdmin: data => addUserByAdmin(data),
      userByAdminResendLink: uuid => userByAdminResendLink(uuid),
      getUsersForApplication: () => getUsersForApplication(),
      getUserDetails: uuid => getUserDetails(uuid),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  isMobile: checkIsMobile(),
  usersByRole: getUsersByRoleSelectors(state),
  partnerRequests: getPartnerRequestsSelectors(state),
  users: getUsersSelector(state),
  translation: ViewManagementSelector.getTranslation(state),
  deliveryCountries: getDeliveryCountriesSelector(state),
  usersForApplication: getUsersForApplicationSelector(state),
  userDetails: getUserDetailsSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UserManagement));

// import React from 'react';

// my test custom hook

const useQuery = async (getDeliveryCountries, getAllCategoryAndSubcategory, getCategoriesOptions) => {
  await getDeliveryCountries();
  await getAllCategoryAndSubcategory();
  await getCategoriesOptions();
};

export default useQuery;

import React, { useEffect, useState } from 'react';
import { func, shape, object, array, string, bool } from 'prop-types';
import { SVG_TYPE, ROUTES } from 'shared/consts';
import { SVG, ProgressIndicator } from 'shared/components';
import { useHistory } from 'react-router-dom';
import { shoppingCartStickyRight } from 'shared/utils';
import { Left, Right } from './components';

const Checkout = ({
  intl: { formatMessage },
  actions: {
    getShoppingCart,
    getShoppingCartOwner,
    getCheckoutAddress,
    modifyShoppingCart,
    simulateCheckout,
    getDeliveryCountries,
  },
  shoppingCart,
  shoppingCartOwner,
  checkoutAddress,
  authorizedUser,
  deliveryCountries,
  translation,
  isMobile,
}) => {
  const [transactionData, setTransactionData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deliveryPrice, setDeliveryPrice] = useState({ arr: [], price: 0 });
  const [productsInTheBasket, setProductsInTheBasket] = useState([]);
  // const [productValidation, setProductValidation] = useState([]);
  const customCheckoutAddress = checkoutAddress?.data?.checkout_custom_address;
  const history = useHistory();
  const productsBasket = [];
  shoppingCartOwner?.map(item =>
    Object.keys(item.products).map(key =>
      productsBasket.push({
        product_uuid: item.products[key].uuid,
        quantity: item.products[key].pivot_shopping_cart_amount,
        delivery_uuid: null,
      }),
    ),
  );

  useEffect(() => {
    !deliveryCountries && getDeliveryCountries();
  });

  useEffect(() => {
    !shoppingCart && getShoppingCart();
  }, []);
  useEffect(async () => {
    await getShoppingCartOwner();
  }, []);
  useEffect(() => {
    authorizedUser && !checkoutAddress && getCheckoutAddress();
  }, []);

  useEffect(() => {
    productsInTheBasket.forEach(item => {
      const element = productsBasket.find(el => el.product_uuid === item.product_uuid);
      if (element) {
        element.delivery_uuid = item.delivery_uuid;
      }
    });
    setProductsInTheBasket(productsBasket);
  }, [shoppingCartOwner, shoppingCart]);

  useEffect(() => {
    shoppingCart.products_amount === 0 && history.push(ROUTES.BASKET);
  }, [shoppingCart]);

  useEffect(() => {
    shoppingCartStickyRight();
  }, []);

  const basket = { transaction_data: transactionData, products: productsInTheBasket };

  return (
    <main className="checkout">
      <div className="sticky">
        <ProgressIndicator formatMessage={formatMessage} activeNumber={2} />
        <div className="back">
          <div onClick={() => history.push(ROUTES.BASKET)}>
            <SVG type={SVG_TYPE.CHEVRONE} /> {formatMessage({ id: 'global.go_back' })}
          </div>
        </div>
      </div>
      <div className="checkout--content">
        <Left
          setProductsInTheBasket={setProductsInTheBasket}
          simulateCheckout={simulateCheckout}
          basket={basket}
          transactionData={transactionData}
          setDeliveryPrice={setDeliveryPrice}
          deliveryPrice={deliveryPrice}
          setIsSubmitting={setIsSubmitting}
          isSubmitting={isSubmitting}
          modifyShoppingCart={modifyShoppingCart}
          productsInTheBasket={productsInTheBasket}
          setTransactionData={setTransactionData}
          authorizedUser={authorizedUser}
          customCheckoutAddress={customCheckoutAddress}
          shoppingCartOwner={shoppingCartOwner}
          formatMessage={formatMessage}
          deliveryCountries={deliveryCountries}
          isMobile={isMobile}
          translation={translation}
        />
        <Right
          transactionData={transactionData}
          simulateCheckout={simulateCheckout}
          deliveryPrice={deliveryPrice}
          setIsSubmitting={setIsSubmitting}
          basket={basket}
          data={shoppingCart}
          formatMessage={formatMessage}
          translation={translation}
          // setProductValidation={setProductValidation}
        />
      </div>
    </main>
  );
};

export default Checkout;

Checkout.propTypes = {
  actions: shape({
    simulateCheckout: func.isRequired,
    addToFavoritesCheckout: func.isRequired,
    modifyShoppingCart: func.isRequired,
    getShoppingCart: func.isRequired,
    getDeliveryCountries: func.isRequired,
    getShoppingCartOwner: func.isRequired,
    getCheckoutAddress: func.isRequired,
    removeFromFavoritesCheckout: func.isRequired,
  }).isRequired,
  authorizedUser: object.isRequired,
  checkoutAddress: object.isRequired,
  deliveryCountries: object.isRequired,
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
  shoppingCart: object.isRequired,
  shoppingCartOwner: array.isRequired,
  translation: string.isRequired,
};

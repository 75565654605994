import { REQUEST_STATUS } from 'shared/consts';
import { ALLEGRO } from 'store/types';

const INITIAL_STATE = {
  translation: '',
};

const allegroReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ALLEGRO.GET_URL_INIT: {
      return {
        ...state,
        url: null,
        status: REQUEST_STATUS.INIT,
      };
    }
    case ALLEGRO.GET_URL_SUCCESS: {
      return {
        ...state,
          url: action.payload,
        status:REQUEST_STATUS.SUCCESS
      };
    }
    case ALLEGRO.GET_URL_FAILURE: {
      return {
        ...state,
        url: null,
        status: REQUEST_STATUS.FAILURE,
      };
    }
    default:
      return state;
  }
};

export default allegroReducer;

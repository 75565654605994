import React from 'react';
import * as Yup from 'yup';
import { object, func } from 'prop-types';
import { Modal, ModalActions, RegularField, SelectField } from 'shared/components';
import { MODAL_TYPE, VALIDATION_FIELD } from 'shared/consts';
import { Field, Formik } from 'formik';
import { string } from 'yup';

const EditForm = ({ settingsType, setSettingsType, formatMessage, editPriceMargin, type, createPriceMargin }) => {
  const onClose = () => setSettingsType({});

  const handleEdit = async values => {
    settingsType.data.price_margin !== null ? await editPriceMargin({...values}) : await createPriceMargin(values);
    onClose();
  };

  const isCountries = type === 'countries';

  const countryValidation = () =>
    Yup.object().shape({
      foreign_margin: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .max(6, VALIDATION_FIELD(formatMessage).MAX_254)
        .test('is-positive', VALIDATION_FIELD(formatMessage).POSITIVE, value => value > 0)
        .test(
          'no-leading-zero',
          VALIDATION_FIELD(formatMessage).NO_ZERO,
          (value, context) => context.originalValue && !context.originalValue.toString().startsWith('0'),
        ),
      isPercentageForeign: Yup.number().required(VALIDATION_FIELD(formatMessage).REQUIRED),
      isPercentageLocal: Yup.number().required(VALIDATION_FIELD(formatMessage).REQUIRED),
      value: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .test('is-positive', VALIDATION_FIELD(formatMessage).POSITIVE, value => value > 0)
        .max(6, VALIDATION_FIELD(formatMessage).MAX_254)
        .test(
          'no-leading-zero',
          VALIDATION_FIELD(formatMessage).NO_ZERO,
          (value, context) => context.originalValue && !context.originalValue.toString().startsWith('0'),
        ),
    });

  const validation = () =>
    Yup.object().shape({
      isPercentageLocal: Yup.number().required(VALIDATION_FIELD(formatMessage).REQUIRED),
      value: Yup.string()
        .max(20, VALIDATION_FIELD(formatMessage).MAX_20)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .test('is-positive', VALIDATION_FIELD(formatMessage).POSITIVE, value => value > 0)
        .test(
          'no-leading-zero',
          VALIDATION_FIELD(formatMessage).NO_ZERO,
          (value, context) => context.originalValue && !context.originalValue.toString().startsWith('0'),
        ),
    });

  const MarginOptions = [
    {
      label: '%',
      searchableLabel: '%',
      value: 1,
    },
    {
      label: settingsType.data?.currency === 'pln' ? 'PLN' : 'EUR',
      searchableLabel: settingsType?.data?.currency === 'pln' ? 'PLN' : 'EUR',
      value: 0,
    },
  ];

  return (
    <div>
      {settingsType.type === 'edit' && (
        <Modal className={MODAL_TYPE.BASIC} onClose={onClose} opened={settingsType.type === 'edit'}>
          <div className="modal__heading modal__heading--center">{formatMessage({ id: 'global.edition' })}</div>
          <Formik
            validationSchema={isCountries ? countryValidation() : validation()}
            initialValues={
              settingsType.data.price_margin
                ? {
                    uuid: settingsType?.data?.price_margin?.label,
                    itemUuid: settingsType.data.uuid, // reffers to country, partner or product
                    value: settingsType.data.price_margin.value,
                    isPercentageLocal: settingsType.data.price_margin.isPercentageLocal,
                    foreign_margin: settingsType.data.price_margin.foreign_margin,
                    isPercentageForeign: settingsType.data.price_margin.isPercentageForeign,
                    type,
                  }
                : {
                    uuid: settingsType?.data?.price_margin?.label,
                    itemUuid: settingsType.data.uuid, // reffers to country, partner or product
                    value: 0,
                    isPercentageLocal: 0,
                    foreign_margin: '',
                    isPercentageForeign: 0,
                    type,
                  }
            }
            onSubmit={values => {
              handleEdit(values);
            }}
          >
            {({ handleSubmit, errors }) => (
              <form className="price-management--form">
                <div className="price-management__half">
                  <Field component={RegularField} errors={errors} label={formatMessage({ id: 'global.earnings' })} name="value" />
                  <Field component={SelectField} errors={errors} name="isPercentageLocal" options={MarginOptions} isCustomLabel />
                </div>
                {type === 'countries' && (
                  <div className="price-management__half">
                    <Field
                      component={RegularField}
                      errors={errors}
                      label={formatMessage({ id: 'global.foreign_earnings' })}
                      name="foreign_margin"
                    />
                    <Field
                      component={SelectField}
                      errors={errors}
                      name="isPercentageForeign"
                      options={MarginOptions}
                      isCustomLabel
                    />
                  </div>
                )}
                <ModalActions
                  className="modal-actions__company-data"
                  formatMessage={formatMessage}
                  type={MODAL_TYPE.EDIT_PRICE}
                  onClose={onClose}
                  onSubmit={handleSubmit}
                />
              </form>
            )}
          </Formik>
        </Modal>
      )}
    </div>
  );
};

export default EditForm;

EditForm.propTypes = {
  createPriceMargin: func.isRequired,
  editPriceMargin: func.isRequired,
  formatMessage: func.isRequired,
  setSettingsType: func.isRequired,
  settingsType: object.isRequired,
  type: string.isRequired,
};

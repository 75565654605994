import React from 'react';
import { MENU_TYPES } from 'screens/user-management/components/const';
import { Clients, Partners, Coordinators, Conclusions } from 'screens/user-management/components';
import { func, object, bool, array } from 'prop-types';
import { USER_ROLES } from 'shared/consts';

const Content = ({
  menuType,
  formatMessage,
  isMobile,
  usersByRole,
  userSuspension,
  userResendLink,
  editUser,
  getPartnerRequests,
  partnerRequests,
  managingPartnerApplication,
  users,
  editUserByAdmin,
  deliveryCountries,
  usersForApplication,
  getUserDetails,
  userDetails,
  userByAdminResendLink,
}) => {
  switch (menuType) {
    case MENU_TYPES(formatMessage).CLIENTS: {
      return (
        <Clients
          editUser={editUser}
          userResendLink={userResendLink}
          userSuspension={userSuspension}
          formatMessage={formatMessage}
          users={usersByRole.users}
          isMobile={isMobile}
        />
      );
    }
    case MENU_TYPES(formatMessage).PARTNERS: {
      return (
        <Partners
          getUserDetails={getUserDetails}
          userDetails={userDetails}
          editUser={editUser}
          managingPartnerApplication={managingPartnerApplication}
          userSuspension={userSuspension}
          formatMessage={formatMessage}
          isMobile={isMobile}
          data={usersByRole.users}
          deliveryCountries={deliveryCountries}
        />
      );
    }
    case MENU_TYPES(formatMessage).COORDINATORS: {
      return (
        <Coordinators
          editUserByAdmin={editUserByAdmin}
          userSuspension={userSuspension}
          userResendLink={userByAdminResendLink}
          formatMessage={formatMessage}
          users={users}
          isMobile={isMobile}
          data={usersByRole.users}
          deliveryCountries={deliveryCountries}
          type={USER_ROLES.COORDINATOR}
        />
      );
    }
    case MENU_TYPES(formatMessage).STOREMANS: {
      return (
        <Coordinators
          editUserByAdmin={editUserByAdmin}
          userSuspension={userSuspension}
          userResendLink={userByAdminResendLink}
          formatMessage={formatMessage}
          users={users}
          isMobile={isMobile}
          data={usersByRole.users}
          deliveryCountries={deliveryCountries}
          type={USER_ROLES.STOREMAN}
        />
      );
    }
    case MENU_TYPES(formatMessage).CONCLUSIONS: {
      return (
        <Conclusions
          usersForApplication={usersForApplication}
          managingPartnerApplication={managingPartnerApplication}
          partnerRequests={partnerRequests}
          formatMessage={formatMessage}
          getPartnerRequests={getPartnerRequests}
          users={usersByRole.users}
          isMobile={isMobile}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export default Content;

Content.propTypes = {
  deliveryCountries: func.isRequired,
  editUser: func.isRequired,
  editUserByAdmin: func.isRequired,
  formatMessage: func.isRequired,
  getPartnerRequests: func.isRequired,
  getUserDetails: func.isRequired,
  isMobile: bool.isRequired,
  managingPartnerApplication: func.isRequired,
  menuType: object.isRequired,
  partnerRequests: object.isRequired,
  userByAdminResendLink: object.isRequired,
  userDetails: object.isRequired,
  userResendLink: func.isRequired,
  users: object.isRequired,
  usersByRole: object.isRequired,
  usersForApplication: array.isRequired,
  userSuspension: func.isRequired,
};

import * as React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    const { opened } = this.props;
    this.state = {
      isOpened: opened || false,
    };
  }

  componentDidMount() {
    const { onOpen } = this.props;
    if (onOpen) onOpen();
  }

  shouldComponentUpdate(nextProps) {
    const { opened } = this.props;
    if (nextProps.opened !== opened) {
      this.setOpened(nextProps.opened);
    }
    return true;
  }

  componentWillUnmount() {
    const { onClose, openModal } = this.props;
    if (onClose && !openModal?.isOpened) onClose();
  }

  setOpened = isOpened => {
    const { onOpenedChange } = this.props;
    this.setState({ isOpened }, () => onOpenedChange && onOpenedChange(isOpened));
  };

  submit = () => {
    const { onSubmit } = this.props;
    if (onSubmit) onSubmit();
    this.setOpened(false);
  };

  toggle = () => {
    const { isOpened } = this.state;
    this.setOpened(!isOpened);
  };

  close = () => {
    const { onClose } = this.props;
    if (onClose) onClose();
  };

  handleChildClick = e => {
    e.stopPropagation();
  };

  render() {
    const { isOpened } = this.state;
    const { children, className } = this.props;
    return (
      <>
        {isOpened && (
          <div className={`modal ${className}`} onClick={this.close}>
            <div className={`modal__inner modal__inner--${className || ''}`} onClick={this.handleChildClick}>
              <div className="modal__close" onClick={this.close}>
                <SVG className="modal__close--icon" type={SVG_TYPE.CLOSE} />
              </div>
              <div className="modal__content">{children}</div>
            </div>
          </div>
        )}
      </>
    );
  }
}

Modal.defaultProps = {
  className: '',
  handleStepChange: null,
  isMultiStep: false,
  onOpen: null,
  onOpenedChange: null,
  openModal: null,
  step: null,
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  handleStepChange: PropTypes.func,
  isMultiStep: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  onOpenedChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  openModal: PropTypes.func,
  step: PropTypes.number,
  title: PropTypes.element.isRequired,
};

export default props => ReactDOM.createPortal(<Modal {...props} />, document.querySelector('body'));

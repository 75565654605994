import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isMobile as checkIsMobile } from 'shared/utils';
import { injectIntl } from 'react-intl';
import { getAuthorizedUser, getCustomCheckoutAddress } from 'store/selectors/userSelector';
import { getUrl } from 'store/actions/allegro';
import editSelf from 'store/actions/auth/editSelf';
import getCheckoutAddress from 'store/actions/auth/getCheckoutAddress';
import { addInvoiceData, getInvoiceData } from 'store/actions/payments';
import { getDeliveryCountries as getDeliveryCountriesSelector } from 'store/selectors/deliverySelector';
import { getInvoiceData as getInvoiceDataSelector } from 'store/selectors/priceSelector';
import Settings from './Settings.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      editUser: (uuid, form) => editSelf(uuid, form),
      getUrl: () => getUrl(),
      getCheckoutAddress: () => getCheckoutAddress(),
      addInvoiceData: (data) => addInvoiceData(data),
      getInvoiceData:(country_uuid)=> getInvoiceData(country_uuid)
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  isMobile: checkIsMobile(),
  authorizedUser: getAuthorizedUser(state),
  checkoutAddress: getCustomCheckoutAddress(state),
  deliveryCountries: getDeliveryCountriesSelector(state),
  invoiceData: getInvoiceDataSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Settings));

import React from 'react';
import { ROUTES, SVG_TYPE } from 'shared/consts';
import { SVG } from 'shared/components';
import { useHistory } from 'react-router-dom';

const MenuLogo = () => {
  const history = useHistory();
  return (
    <div className="menu__logo">
      <div className="menu__logo--click" onClick={() => history.push(ROUTES.HOME_PAGE)} />
      <span>
        <SVG className="menu__logo--item" type={SVG_TYPE.LOGO} />
      </span>
    </div>
  );
};

export default MenuLogo;

import { connect } from 'react-redux';
import { isMobile as checkIsMobile } from 'shared/utils';
import { injectIntl } from 'react-intl';
import { getMyAdvertisements, deleteAdvertisement, cancelAdvertisement, getAdvertisementsCount, resumeMyProduct, recallMyProduct } from 'store/actions/products';
import {
  getMyAdvertisements as getMyAdvertisementsSelector,
  getAdvertisementsCount as getAdvertisementsCountSelector,
} from 'store/selectors/productsSelector';
import getMyAdvertisementsFiltered from 'store/actions/products/getMyAdvertisementsFiltred';
import { getCategoryAndSubcategory } from 'store/selectors/categorySelector';
import { getAuthorizedUser } from 'store/selectors/userSelector';
import Advertisements from './Advertisements.main';

const mapDispatchToProps = {
  getMyAdvertisements: status => getMyAdvertisements(status),
  getMyAdvertisementsFiltered: (status,filters) => getMyAdvertisementsFiltered(status,filters),
  deleteAdvertisement: uuid => deleteAdvertisement(uuid),
  cancelAdvertisement: uuid => cancelAdvertisement(uuid),
  getAdvertisementsCount: (noInit) => getAdvertisementsCount(noInit),
  resumeMyProduct: uuid => resumeMyProduct(uuid),
  recallMyProduct: uuid => recallMyProduct(uuid),
};

const mapStateToProps = state => ({
  isMobile: checkIsMobile(),
  myAdvertisements: getMyAdvertisementsSelector(state),
  advertisementsCount: getAdvertisementsCountSelector(state),
  getCategoryAndSubcategory: getCategoryAndSubcategory(state),
  authorizedUser: getAuthorizedUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Advertisements));

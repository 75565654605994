const encodeBrand = (name, brands) => {
  if (name && brands) {
    const nameWithSpace = name.replaceAll('-', ' ');
    const brand = brands.filter(item => item.value === nameWithSpace);
    const brandCode = brand.length > 0 && brand[0].label;
    return brandCode;
  }
  return null;
};

export default encodeBrand;

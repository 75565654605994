import React, { useEffect } from 'react';
import { SVG_TYPE } from 'shared/consts';
import { Preloader, SVG } from 'shared/components';
import { ChatForm } from 'shared/components/chat/components';
import moment from 'moment';
import { func, object } from 'prop-types';

const ChatBox = ({
  formatMessage,
  setIsOpen,
  sendMessage,
  rooms,
  roomMessages,
  page,
  setPage,
  setNewMessage,
  authorizedUser,
  createRoom,
  getRooms,
}) => {
  const handleScroll = e => {
    if (e.currentTarget.scrollTop === 0 && rooms.room.page_amount > page) {
      setPage(page + 1);
    }
      // scroll to bottom on new message
    };
    useEffect(() => {
      roomMessages.length &&
        document.getElementById('chat-content').scroll({ top: 10000, behavior: 'smooth' })
    }, [roomMessages.length]);

  return (
    <div className="chat--box">
      <div className="chat--box__head">
        <div>
          <SVG className="icon" type={SVG_TYPE.CHAT_CONSULTANT} />
          {formatMessage({ id: 'global.service_center' })}
        </div>
        <div className="chat--box--dash" onClick={() => setIsOpen(false)}>
          <SVG className="dash" type={SVG_TYPE.DASH} />
        </div>
      </div>
      <div id="chat-content" className="chat--box__content" onScroll={handleScroll}>
        {roomMessages &&
          roomMessages.length > 0 &&
          roomMessages.map(item => (
            <div
              className={`text__container ${
                item.owner === 'client' || item.owner === 'partner' ? 'text__container--client' : 'text__container--assistance'
              }`}
            >
              <div
                className={`text ${item.owner === 'client' || item.owner === 'partner' ? 'text--client' : 'text--assistance'}`}
              >
                {item.content}
              </div>
              <div className="time">{moment(item.created_at).format('HH:mm')}</div>
            </div>
          ))}
        {!rooms && <Preloader />}
      </div>
      <ChatForm
        setNewMessage={setNewMessage}
        roomMessages={roomMessages}
        sendMessage={sendMessage}
        rooms={rooms}
        formatMessage={formatMessage}
        authorizedUser={authorizedUser}
        createRoom={createRoom}
        getRooms={getRooms}
        setPage={setPage}
      />
    </div>
  );
};

export default ChatBox;

ChatBox.propTypes = {
  authorizedUser: object.isRequired,
  createRoom: func.isRequired,
  formatMessage: func.isRequired,
  getRooms: func.isRequired,
  page: object.isRequired,
  roomMessages: object.isRequired,
  rooms: object.isRequired,
  sendMessage: func.isRequired,
  setIsOpen: func.isRequired,
  setNewMessage: func.isRequired,
  setPage: func.isRequired,
};

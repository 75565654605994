import * as Connector from 'shared/connectors/authConnector';
import { AUTH } from 'store/types';
import { getShoppingCart } from 'store/actions/shopping-cart';
import { getAuthorizedUser } from 'store/actions/user';

const init = () => ({
  type: AUTH.LOGIN_INIT,
});

export const success = data => ({
  type: AUTH.LOGIN_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: AUTH.LOGIN_FAILURE,
});

const login = formData => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.login(formData);
    dispatch(success(data));
    data?.token && localStorage.removeItem('notLoggedUserBasketUuid');
    await dispatch(getAuthorizedUser());
    await dispatch(getShoppingCart());
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default login;

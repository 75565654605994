const subcategoriesFromUrl = search => {
  const isFromEngine = search.slice(0, 2) === '?s';
  const isFromMenuOrSidebar = search.slice(0, 5) === '?cat=';
  let splitArr = [];
  if (isFromMenuOrSidebar) {
    const searchUrl = search.slice(5);
    const replace = searchUrl.replace(/%20/g, '_');
    splitArr = replace.split(',');
  } else if (isFromEngine) {
    const searchUrl = search.slice(3);
    const isContainsSubcategory = search.includes('&cat=');
    if (isContainsSubcategory) {
      const str = searchUrl.split('&cat=').pop();
      splitArr = str.split(',');
    }
  }
  return splitArr;
};

export default subcategoriesFromUrl;

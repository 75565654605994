import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { func } from 'prop-types';

const Header = ({ formatMessage }) => (
  <header className="partnerProgram__header">
    <hgroup className="partnerProgram__header--heading">
      <h1>{formatMessage({ id: 'global.partner_program' })}</h1>
      <h2>{formatMessage({ id: 'global.partner_program_use_premium' })}</h2>
      <p>
        {formatMessage({ id: 'global.find_out_more' })}
        <SVG type={SVG_TYPE.ARROW_DOWN} />
      </p>
    </hgroup>
  </header>
);

export default Header;

Header.propTypes = {
  formatMessage: func.isRequired,
};

import React from 'react';
import { func, bool, string, array } from 'prop-types';
import { SVG_TYPE, VALIDATION_FIELD } from 'shared/consts';
import { SVG, RegularField } from 'shared/components';
import { Field, Formik } from 'formik';

import * as Yup from 'yup';

import PriceMenu from '../price-menu';

const Heading = ({ formatMessage, menuType, currencyExchangeList, setMenuType, isMobile, editCurrencyExchange }) => {
  const validation = () =>
    Yup.object().shape({
      item: Yup.string()
        .matches(/^\d{0,100}(\.\d{0,99}||,\d{0,99})?$/, VALIDATION_FIELD(formatMessage).ONLY_NUMBER)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .matches(/^\d{0,3}(\.\d{0,99}||,\d{0,99})?$/, VALIDATION_FIELD(formatMessage).MAX_3)
        .matches(/^[0-9][0-9]*[(.|,)]?[0-9]{0,2}$/, VALIDATION_FIELD(formatMessage).DECIMAL_2)
        .test(
          'no-leading-zero',
          VALIDATION_FIELD(formatMessage).FIRST_NO_ZERO,
          (value, context) => context.originalValue && !context.originalValue.startsWith('0'),
        ),
    });

  const handleOnSubmit = async values => {
    await editCurrencyExchange(currencyExchangeList[0].uuid, values);
  };

  return (
    <div className="user-management__top price-management">
      <div className="user-management__heading">
        <p className="user-management--heading">{formatMessage({ id: 'global.price_management' })}</p>
        <div className="exchange__wrapper">
          <Formik
            enableReinitialize
            validationSchema={validation()}
            initialValues={{
              item: currencyExchangeList && currencyExchangeList.length > 0 && currencyExchangeList[0]?.exchange_value,
            }}
            onSubmit={handleOnSubmit}
          >
            {({ handleSubmit, errors }) => (
              <form onSubmit={handleSubmit}>
                <p className="exchange--header">{formatMessage({ id: 'global.current_rate' })}</p>
                <div className="exchange__item">
                  <p>1 EUR</p>
                  <SVG className="icon" type={SVG_TYPE.EXCHANGE} />
                  <div className="exchange__input--wrapper">
                    <Field
                      type="text"
                      name="item"
                      errors={errors}
                      onBlur={handleSubmit}
                      component={RegularField}
                      className="exchange__input"
                    />
                    <p className="exchange__input--currency"> PLN </p>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <PriceMenu formatMessage={formatMessage} menuType={menuType} setMenuType={setMenuType} isMobile={isMobile} />
    </div>
  );
};

export default Heading;

Heading.propTypes = {
  addUserByAdmin: func.isRequired,
  currencyExchangeList: array.isRequired,
  deliveryCountries: array.isRequired,
  editCurrencyExchange: func.isRequired,
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
  menuType: string.isRequired,
  setMenuType: func.isRequired,
  translation: string.isRequired,
};

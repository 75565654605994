import React, {useEffect} from 'react';
import { func, shape, object, array } from 'prop-types';
import { SVG_TYPE, ROUTES } from 'shared/consts';
import { SVG, ProgressIndicator } from 'shared/components';
import { useHistory } from 'react-router-dom';
import { shoppingCartStickyRight } from 'shared/utils';
import { Left, Right } from './components';

const Summary = ({
  intl: { formatMessage },
  actions: { setCheckout, clearShoppingCart, createCheckoutSession },
  authorizedUser,
}) => {
  const history = useHistory();
  const summary = JSON.parse(localStorage.getItem('summary'));

  useEffect(()=> {
    shoppingCartStickyRight()
  },[]);

  return (
    <>
      {summary ? (
        <main className="checkout">
          <div className="sticky">
            <ProgressIndicator formatMessage={formatMessage} activeNumber={3} />
            <div className="back">
              <div onClick={() => history.push(ROUTES.CHECKOUT)}>
                <SVG type={SVG_TYPE.CHEVRONE} /> {formatMessage({ id: 'global.go_back' })}
              </div>
            </div>
          </div>
          <div className="checkout--content">
            <Left summary={summary} formatMessage={formatMessage} />
            <Right
              authorizedUser={authorizedUser}
              createCheckoutSession={createCheckoutSession}
              clearShoppingCart={clearShoppingCart}
              summary={summary}
              setCheckout={setCheckout}
              formatMessage={formatMessage}
            />
          </div>
        </main>
      ) : (
        history.push(ROUTES.CHECKOUT)
      )}
    </>
  );
};

export default Summary;

Summary.propTypes = {
  actions: shape({
    getCheckout: func.isRequired,
    clearShoppingCart: func.isRequired,
    createCheckoutSession: func.isRequired,
  }).isRequired,
  authorizedUser: object.isRequired,
  checkout: object.isRequired,
  formatMessage: func.isRequired,
  shoppingCartOwner: array.isRequired,
};

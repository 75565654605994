import React from 'react';
import PropTypes from 'prop-types';

const Price = ({ item, isMobile, formatMessage }) => (
  <>
    {!isMobile ? (
      <>
        <p className="product-list-item__right--price">
          {item.price} <spoan className="currency">{item.currency}</spoan>
        </p>
        <p className="product-list-item__right--brutto">{formatMessage({ id: 'global.brutto' })}</p>
      </>
    ) : (
      <>
        <p className="product-list-item__right--price product-list-item-mobile--price--label">
          {item.price} <spoan className="currency">{item.currency}</spoan>
        </p>
        <p className="product-list-item__right--brutto product-list-item-mobile__right--brutto">
          {formatMessage({ id: 'global.brutto' })}
        </p>
      </>
    )}
  </>
);

export default Price;

Price.propTypes = {
  formatMessage: PropTypes.func.isRequired,
  isMobile: PropTypes.string.isRequired,
  item: PropTypes.string.isRequired,
};

import React, { useEffect } from 'react';
import { Table, SkeletonProduct } from 'shared/components';
import PropTypes from 'prop-types';
import { getColumns } from '../utils';

const ListsProducts = ({
  formatMessage,
  isMobile,
  products,
  addToFavorites,
  removeFromFavorites,
  translation,
  setIsEmptyView,
  isModalOpened,
  authorizedUser,
}) => {
  useEffect(() => {
    if (products && products.length === 0 && !isModalOpened) {
      const timer = setTimeout(() => {
        setIsEmptyView(true);
      }, 2000);
      return () => clearTimeout(timer);
    }
    return setIsEmptyView(false);
  }, [products]);

  return (
    <div className="lists-products">
      {products && products.length > 0 ? (
        <>
          <Table
            className="lists-products__table"
            data={products}
            rows={10}
            showPaginationTop
            columns={getColumns(isMobile, addToFavorites, removeFromFavorites, translation, formatMessage, authorizedUser)}
          />
        </>
      ) : (
        <>
          <SkeletonProduct count={4} />
        </>
      )}
    </div>
  );
};

export default ListsProducts;

ListsProducts.propTypes = {
  addToFavorites: PropTypes.func.isRequired,
  authorizedUser: PropTypes.object.isRequired,
  formatMessage: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isModalOpened: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
  removeFromFavorites: PropTypes.func.isRequired,
  setIsEmptyView: PropTypes.func.isRequired,
  translation: PropTypes.string.isRequired,
};

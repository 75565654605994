import { CUSTOM_TRANSLATIONS } from 'store/types';
import { REQUEST_STATUS } from 'shared/consts';

const INITIAL_STATE = {
  customTranslations: {
    data: [],
  },
};

const customTranslationsReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case CUSTOM_TRANSLATIONS.GET_CUSTOM_TRANSLATIONS_SUCCESS: {
      return {
        ...state,
        customTranslations: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case CUSTOM_TRANSLATIONS.GET_CUSTOM_TRANSLATIONS_FAILURE: {
      return {
        ...state,
        customTranslations: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case CUSTOM_TRANSLATIONS.SEARCH_TRANSLATIONS_SUCCESS: {
      return {
        ...state,
        customTranslations: {
          data: {
            
            translations:payload.data.message,
          },
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case CUSTOM_TRANSLATIONS.SEARCH_TRANSLATIONS_FAILURE: {
      return {
        ...state,
        customTranslations: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    default:
      return state;
  }
};

export default customTranslationsReducer;

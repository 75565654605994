import ROUTES from './routes';
import USER_ROLES from './userRoles';

const { ADMIN, COORDINATOR, STOREMAN } = USER_ROLES;

const PROTECTED_ROUTES = new Map([
  [ROUTES.CATEGORY_MANAGEMENT, [ADMIN]],
  [ROUTES.PRICE_MANAGEMENT, [ADMIN]],
  [ROUTES.USER_MANAGEMENT, [ADMIN]],
  [ROUTES.DICTIONARY, [ADMIN, COORDINATOR]],
  [ROUTES.CHAT, [ADMIN, COORDINATOR]],
  [ROUTES.DELIVERY_OPTIONS, [ADMIN, COORDINATOR]],
  [ROUTES.MAGAZINE, [ADMIN, COORDINATOR, STOREMAN]],
]);

export default PROTECTED_ROUTES;

import { bool, func, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import Chatbox from '../chatbox';
import User from '../user/User';

const Content = ({
  updateRoomStatus,
  archivedFilter,
  formatMessage,
  setChatArchived,
  chatArchived,
  rooms,
  getRoomMessages,
  roomMessages,
  sendMessage,
  countryFilter,
  authorizedUser,
}) => {
  const [showChat, setShowChat] = useState(false);
  const [page, setPage] = useState(1);
  const [pageUsers, setPageUsers] = useState(1);
  const [messagesCount, setMessagesCount] = useState(0);
  const chatContent = document.getElementById('chat__messages__list');
  const lastMessageID = roomMessages && roomMessages[roomMessages.length - 1]?.id;
  const scrollBottomChat = () => chatContent && chatContent.scrollTo(0, chatContent.scrollHeight);

  useEffect(async () => {
    showChat && (await getRoomMessages({ room_uuid: showChat, page }, page !== 1));
    page === 1 && scrollBottomChat();
  }, [showChat, page]);
  const handleScroll = e => {
    if (e.currentTarget.scrollTop % 658 === 0) {
      setPageUsers(pageUsers + 1);
    }
  };
  // update messages every 5 sec
  useEffect(() => {
    const getMessagesInterval = setInterval(() => {
      showChat && getRoomMessages({ room_uuid: showChat, page: 1 }, { init: false });
    }, 5000);
    return () => clearInterval(getMessagesInterval);
  }, [showChat, page]);

  useEffect(() => {
    setPage(1);
  }, [showChat]);

  // scroll to bottom on new message
  useEffect(() => {
    messagesCount !== roomMessages.length &&
      (document.getElementById('chat__messages__list').scroll({ top: 10000, behavior: 'smooth' }),
      setMessagesCount(roomMessages.length));
  }, [lastMessageID]);

  useEffect(() => {
    setShowChat(
      rooms?.rooms &&
        rooms.rooms
          .filter(el => (countryFilter === 'all' ? true : el.room_country === countryFilter))
          .filter(item => item.is_closed === archivedFilter)
          .sort(
            (a, b) =>
              (a.last_message &&
                b.last_message &&
                new Date(b.last_message.created_at).getTime() - new Date(a.last_message.created_at).getTime()) ||
              (a.last_message && -1) ||
              (b.last_message && 1),
          )
          .slice(0, 2)
          .filter(user => user.uuid !== showChat && user.uuid !== chatArchived)[0]?.uuid,
    );
  }, [archivedFilter, chatArchived]);

  return (
    <div className="help-chat__content">
      {rooms?.rooms && (
        <div className="help-chat__users__list" onScroll={handleScroll}>
          {rooms?.rooms &&
            rooms.rooms
              .filter(el => (countryFilter === 'all' ? true : el.room_country === countryFilter))
              .filter(item => item.is_closed === archivedFilter)
              .sort(
                (a, b) =>
                  (a.last_message &&
                    b.last_message &&
                    new Date(b.last_message.created_at).getTime() - new Date(a.last_message.created_at).getTime()) ||
                  (a.last_message && -1) ||
                  (b.last_message && 1),
              )
              .slice(0, 19 + (pageUsers - 1) * 20)
              .map(data => (
                <User
                  authorizedUser={authorizedUser}
                  key={data.uuid}
                  data={data}
                  setShowChat={setShowChat}
                  active={showChat === data.uuid}
                  setPage={setPage}
                />
              ))}
        </div>
      )}
      <Chatbox
        authorizedUser={authorizedUser}
        updateRoomStatus={updateRoomStatus}
        showChat={showChat}
        archivedFilter={archivedFilter}
        setShowChat={setShowChat}
        formatMessage={formatMessage}
        setChatArchived={setChatArchived}
        chatArchived={chatArchived}
        roomMessages={roomMessages}
        sendMessage={sendMessage}
        rooms={rooms}
        setPage={setPage}
        page={page}
      />
    </div>
  );
};
export default Content;

Content.propTypes = {
  archivedFilter: bool.isRequired,
  authorizedUser: object.isRequired,
  chatArchived: bool.isRequired,
  countryFilter: string.isRequired,
  formatMessage: func.isRequired,
  getRoomMessages: func.isRequired,
  roomMessages: object.isRequired,
  rooms: object.isRequired,
  sendMessage: func.isRequired,
  setChatArchived: func.isRequired,
  updateRoomStatus: func.isRequired,
};

import React,{useState }  from 'react';
import { object, string } from 'prop-types';
import { injectIntl } from 'react-intl';
import { isMobile } from 'shared/utils';
import { SVG_TYPE } from 'shared/consts';
import { Column, CompanyInfo } from './components';
import { COLUMN_ICON, COLUMN_MID } from './consts';
import SVG from '../SVG';
import CookiesAgreement from '../cookies-agreement';

const MainFooter = ({ intl, authorizedUser, translation }) => {
  const { formatMessage } = intl;
  const addressLang = authorizedUser ? authorizedUser?.country?.short.toLowerCase() : translation;
  const [isCookieOpen, setIsCookieOpen] = useState(false)
  
  return (
    <footer id="footer" className="main-footer__wrapper">
      <div className="main-footer">
        <CompanyInfo addressLang={addressLang} formatMessage={formatMessage} />
        {isMobile() && <Column addressLang mobile links={COLUMN_ICON} icon />}
        <Column header={formatMessage({ id: 'global.important_information' })} links={COLUMN_MID(formatMessage)} />
        {!isMobile() && (
          <section className="main-footer__column">
            <>
              <h4 className="main-footer--header">{formatMessage({ id: 'global.find_us' })}</h4>
              <Column links={COLUMN_ICON} icon />
            </>
          </section>
        )}
        <div className='main-footer--cookie' onClick={()=>setIsCookieOpen(true)}>
          <SVG type={SVG_TYPE.COOKIE_BUTTON} />
          {isCookieOpen && <CookiesAgreement setIsCookieOpen={(value)=>setIsCookieOpen(value)} edit />}
        </div>
      </div>
    </footer>
  );
};

export default injectIntl(MainFooter);

MainFooter.propTypes = {
  authorizedUser: object.isRequired,
  intl: object.isRequired,
  translation: string.isRequired,
};

import * as Connector from 'shared/connectors/productsConnector';
import { PRODUCTS } from 'store/types';

const init = () => ({
  type: PRODUCTS.GET_ADVERTISEMENTS_COUNT_INIT,
});

export const success = data => ({
  type: PRODUCTS.GET_ADVERTISEMENTS_COUNT_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: PRODUCTS.GET_ADVERTISEMENTS_COUNT_FAILURE,
});

const getAdvertisementsCount = (noInit=false) => async dispatch => {
  !noInit && (dispatch(init()));
  try {
    const { data } = await Connector.getAdvertisementsCount();
    dispatch(success(data));
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default getAdvertisementsCount;

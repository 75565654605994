import React from 'react';
import * as Connector from 'shared/connectors/productsConnector';
import { PRODUCTS } from 'store/types';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import { NOTIFY_TYPE } from 'shared/consts';

export const success = () => ({
  type: PRODUCTS.UNMARK_SINGLE_PRODUCT_FAVORITE_SUCCESS,
});

const unmarkSingleProductFavorite = uuid => async dispatch => {
  try {
    const { data } = await Connector.removeFromFavorites(uuid);
    dispatch(success());
    toast(<Notify label="global.remove_from_watchlist" type={NOTIFY_TYPE.REMOVE_FROM_FAVORITE} />);
    return data;
  } catch (err) {
    return err.response;
  }
};

export default unmarkSingleProductFavorite;

import * as Connector from 'shared/connectors/checkoutConnector';
import { CHECKOUT } from 'store/types';

export const success = () => ({
  type: CHECKOUT.SET_CHECKOUT_SUCCESS,
});

const notLoggedUserBasketUuid = localStorage.getItem('notLoggedUserBasketUuid');
const isUserLogged = localStorage.getItem('isUserLogged');

const simulateCheckout = form => async () => {
  try {
    if (notLoggedUserBasketUuid && !isUserLogged) {
      form.shopping_cart_uuid = notLoggedUserBasketUuid;
    }
    const { data } = await Connector.simulateCheckout(form);
    localStorage.setItem('summary', JSON.stringify({ data: data.checkout, transaction_data: form.transaction_data }));
    localStorage.setItem('checkout', JSON.stringify(form));
    return data;
  } catch (err) {
    return err.response.data;
  }
};

export default simulateCheckout;

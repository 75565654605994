import React, { useEffect, useState } from 'react';
import { SingleCategoryBar } from 'shared/components';
import { object, func, bool, string } from 'prop-types';
import { subcategoriesFromUrl, querySearching } from 'shared/utils';

// let subCategory = [];
const CategoryBarLists = ({ categoryByLink, urlChange, setUrlChange, formatMessage, translation, authorizedUser,deliveryCountries }) => {
  const { search, pathname, href } = window.location;
  const isSearchingCategory = search.search('main_cat=') > 0;
  const isSearchingSubCategory = search.includes('&cat=');
  const isSearching = search.slice(0, 2) === '?s';
  const category = categoryByLink;
  const categoryName = pathname.split('/')[2];
  const isChangedCategory = category && category?.category?.link !== categoryName;
  const isClickAllCategory = localStorage.getItem('showAll');
  const allActiveElements = document.getElementsByClassName('single-category-bar__list--active');
  const arraySubCategoryFromUrl = subcategoriesFromUrl(search);
  const isEmpty = href.indexOf('empty') > -1;
  const searchingSubcategoryFromUrl = querySearching().cat && querySearching().cat.split(',');
    const deliveryCountryUuid = authorizedUser ? authorizedUser.country.label : localStorage.getItem('deliveryCountry');
  const countryLabel = !!deliveryCountries.length && !!deliveryCountryUuid && deliveryCountries?.find(item=> item.value ===deliveryCountryUuid)?.label;
  const countryShort = () => {
    if (countryLabel === 'Poland') {
      return 'pl';
    }
    if (countryLabel === 'Germany') {
      return 'de';
    }
    return translation;
  };
  const [country, setCountry] = useState(countryShort());
  useEffect(() => {
    // eslint-disable-next-line no-nested-ternary
    setCountry(countryShort());
  }, [localStorage.getItem('deliveryCountry'), authorizedUser, translation]);


  useEffect(() => {
    const lastItem = pathname.substring(pathname.lastIndexOf('/') + 1);
    const lastItemSearch = search.substring(search.lastIndexOf('&') + 1).slice(0, 3) === 'cat';
    if ((!search && lastItem !== 'empty') || (isSearchingCategory && !lastItemSearch && !isEmpty)) {
      const otherElements = document.getElementsByClassName('single-category-bar__list');
      for (let i = 0; i < otherElements.length; i++) {
        const el = otherElements[i];
        el.classList.add('single-category-bar__list--active');
      }
    }
  }, [categoryByLink, isClickAllCategory]);

  const handleClickCategory = (e, value) => {
    const element = e.target;
    const hasClass = element.classList.contains('single-category-bar__list--active');
    setUrlChange({ trigger: !urlChange.trigger, value, isActive: hasClass });
    hasClass
      ? element.classList.remove('single-category-bar__list--active', 'single-category-bar__list--active-gray')
      : element.classList.add('single-category-bar__list--active');
    if (!search || (isSearchingCategory && !isSearchingSubCategory)) {
      if (href.indexOf('empty') > -1) {
        const cutEmpty = pathname.substring(0, pathname.lastIndexOf('/'));
        const cutProduct = cutEmpty.substring(0, cutEmpty.lastIndexOf('/'));
        window.history.pushState(
          '',
          '',
          `${isSearching ? `category?s=&main_cat=${category.link}&cat=${value}` : `${cutProduct}/?cat=${value}`}`,
        );
      } else {
        const otherElements = document.getElementsByClassName('single-category-bar__list--active');
        otherElements[0].classList.remove('single-category-bar__list--active');
        element.classList.remove('single-category-bar__list--active');
        let strings = '';
        for (let i = 0; i < allActiveElements.length; i++) {
          const el = otherElements[i];
          const span = el.getElementsByTagName('span');
          const link = span[0].innerHTML;
          strings += `${link},`;
        }
        otherElements.length >= 1 // prev   otherElements.length > 2
          ? window.history.pushState('', '', `${href}${isSearchingCategory ? '&' : '/?'}cat=${strings.slice(0, -1)}`)
          : window.history.pushState('', '', `${pathname}${search}&empty`);
      }
    } else if (window.location.href.indexOf(value) > -1 || (isSearchingCategory && hasClass)) {
      if (arraySubCategoryFromUrl.length === 1) {
        const changes = href.replace(`${isSearching ? '&cat=' : '/?cat='}${value}`, '');
        window.history.pushState('', '', `${changes}${isSearching ? '&empty' : '/product/empty'}`);
      } else if (window.location.href.indexOf(`=${value}`) > -1) {
        const changes = href.replace(`${value},`, '');
        window.history.pushState('', '', changes);
      } else {
        const changes = href.replace(`,${value}`, '');
        window.history.pushState('', '', changes);
      }
    } else {
      window.history.pushState('', '', `${href},${value}`);
    }
    const AllElements = document.getElementsByClassName('single-category-bar__list');
    const ActiveElements = document.getElementsByClassName('single-category-bar__list--active');
    const otherElements = document.getElementsByClassName('single-category-bar__list');
    if (AllElements.length === ActiveElements.length + 1 && otherElements.length > 2) {
      !isSearchingCategory && window.history.pushState('', '', `${pathname.slice(0, -1)}`);
      isSearchingCategory && window.history.pushState('', '', `${href.split('&cat=')[0]}`);
      for (let i = 0; i < otherElements.length; i++) {
        const el = otherElements[i];
        el.classList.add('single-category-bar__list--active');
      }
    }
  };

  const clickAllCategory = (e, value) => {
    setUrlChange({ trigger: !urlChange.trigger, value });
    const lastItem = pathname.substring(pathname.lastIndexOf('/') + 1);
    const searchingLastItem = search.substring(search.lastIndexOf('&') + 1);
    const element = e.target;
    const hasClass = element.classList.contains('single-category-bar__list--active');
    const otherElements = document.getElementsByClassName('single-category-bar__list');
    if (search) {
      for (let i = 0; i < otherElements.length; i++) {
        const el = otherElements[i];
        el.classList.add('single-category-bar__list--active');
      }
      if (hasClass && isSearching) {
        window.history.pushState('', '', `${href}&empty`);
        const allSidebarElement = document.getElementsByClassName('single-category-bar__list--active');
        for (let i = 0; i < allSidebarElement.length; i) {
          const el = allSidebarElement[i];
          el.classList.remove('single-category-bar__list--active');
        }
      }
      !isSearching && window.history.pushState('', '', `${pathname.slice(0, -1)}`);
      const link = href.split('&empty')[0];
      !hasClass && isSearching && window.history.pushState('', '', `${link.split('&cat=')[0]}`);
    } else if (lastItem === 'empty') {
      const cutEmpty = pathname.substring(0, pathname.lastIndexOf('/'));
      const cutProduct = cutEmpty.substring(0, cutEmpty.lastIndexOf('/'));
      window.history.pushState('', '', `${cutProduct}`);
      for (let i = 0; i < otherElements.length; i++) {
        const el = otherElements[i];
        el.classList.add('single-category-bar__list--active');
      }
    } else if (searchingLastItem === 'empty') {
      const searchLink = search.substring(0, search.lastIndexOf('&'));
      window.history.pushState('', '', `${pathname}${searchLink}`);
      for (let i = 0; i < otherElements.length; i++) {
        const el = otherElements[i];
        el.classList.add('single-category-bar__list--active');
      }
    } else if (!hasClass) {
      for (let i = 0; i < otherElements.length; i++) {
        const el = otherElements[i];
        el.classList.add('single-category-bar__list--active');
      }
    } else {
      element.classList.add('single-category-bar__list--active');
      // !isSearchingCategory && window.history.pushState('', '', pathname.slice(0, -1));
      for (let i = 0; i < otherElements.length; i++) {
        const el = otherElements[i];
        el.classList.remove('single-category-bar__list--active');
      }
      !isSearchingCategory
        ? window.history.pushState('', '', `${pathname}/product/empty`)
        : window.history.pushState('', '', `${pathname}${search}&empty`);
    }
  };

  useEffect(() => {
    if (isSearching) {
      const otherElements = document.getElementsByClassName('single-category-bar__list');
      if (isSearchingSubCategory) {
        for (let i = 0; i < otherElements.length; i++) {
          const el = otherElements[i];
          el.classList.remove('single-category-bar__list--active');
        }
      }
      searchingSubcategoryFromUrl &&
        searchingSubcategoryFromUrl.length > 0 &&
        searchingSubcategoryFromUrl.forEach(async el => {
          const cat = await document.getElementsByClassName(`category-value--${el}`);
          cat.length > 0 && cat[0].classList.add('single-category-bar__list--active');
        });
    }
  }, [window.location.search, isChangedCategory]);
  return (
    <div id="sidebar--products" className="product-sidebar__list-categories">
      <SingleCategoryBar all value="all" list={clickAllCategory} label={formatMessage({ id: 'global.all' })} />
      {categoryByLink?.subcategories &&
        categoryByLink?.subcategories
          .filter(
            ({ products_count_pl, products_count_de }) =>
              (country === 'pl' ? products_count_pl : products_count_de) > 0,
          )
          .map(({ name, link, products_count_pl, products_count_de, name_ger }) => (
            <SingleCategoryBar
              value={link}
              list={handleClickCategory}
              label={translation === 'pl' ? name : name_ger}
              quantity={country === 'pl' ? products_count_pl : products_count_de}
            />
          ))}
    </div>
  );
};

export default CategoryBarLists;

CategoryBarLists.propTypes = {
  authorizedUser: object.isRequired,
  categoryByLink: object.isRequired,
  deliveryCountries: object.isRequired,
  formatMessage: func.isRequired,
  setUrlChange: func.isRequired,
  translation: string.isRequired,
  urlChange: bool.isRequired,
};

import React from 'react';
import * as Connector from 'shared/connectors/deliveryConnector';
import { DELIVERY } from 'store/types';
import { addFiles } from 'store/actions/utils';
import { getDelivery } from 'store/actions/delivery';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import { NOTIFY_TYPE } from 'shared/consts';

const init = () => ({
  type: DELIVERY.EDIT_DELIVERY_INIT,
});

export const success = () => ({
  type: DELIVERY.EDIT_DELIVERY_SUCCESS,
});

const failure = () => ({
  type: DELIVERY.EDIT_DELIVERY_FAILURE,
});

const editDeliveryMethod = form => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.editDeliveryMethod(form.uuid, {
      price: form.price,
      name: form.name,
      country_uuid: form.country_uuid,
      is_worldwide: form.is_worldwide,
    });
    if (form.files) {
      const fileForm = new FormData();
      fileForm.append('file', form.files[0]);
      await dispatch(addFiles(fileForm, form.uuid, 'delivery_uuid='));
    }
    toast(<Notify label="global.successful_edit_delivery_option" type={NOTIFY_TYPE.CHECKED} />);
    dispatch(getDelivery(form.country_uuid));
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default editDeliveryMethod;

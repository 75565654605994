/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { string, func, bool, object, number } from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';

const PartnerSettings = ({ userSuspension, settingsType, setSettingsType, setOpenModal, data, id, formatMessage }) => {
  const { suspended, uuid, email } = data;
  const settingsTypes = {
    partner: 'partner',
    suspended: 'suspended',
  };

  const handleClickSettings = () => {
    suspended ? setSettingsType({ type: settingsTypes.suspended, id }) : setSettingsType({ type: settingsTypes.partner, id });
  };

  const closeSettings = () => {
    setSettingsType({ type: '', id: '' });
  };

  return (
    <div className="user-management__table__settings">
      <div className="user-management__table__settings--item">
        <a href={`mailto:${email}`}>
          <SVG type={SVG_TYPE.MAIL} className="svg--mail--gray" />
        </a>
        {data.user && (
          <a href="mailto:kik@o2.pl">
            <SVG type={SVG_TYPE.MAIL} className="svg--mail--gray" />
          </a>
        )}
      </div>
      <div
        className={`user-management__table__settings--item ${
          settingsType.id === id ? 'user-management__table__settings--item--disabled' : ''
        } `}
        onClick={() => handleClickSettings()}
      >
        <SVG type={SVG_TYPE.SETTINGS} />
      </div>
      {settingsType.id === id && settingsType.type === settingsTypes.partner && (
        <OutsideClickHandler display="flex" onOutsideClick={() => setSettingsType({ type: '', id: '' }, 1)}>
          <div className="user-management__table__settings__list" id={id}>
            <div
              className="user-management__table__settings__list--item"
              onClick={() => {
                closeSettings();
                setOpenModal({ isOpened: true, data });
              }}
            >
              {formatMessage({ id: 'global.edit' })}
            </div>
            <div
              className="user-management__table__settings__list--item"
              onClick={() => {
                closeSettings();
                userSuspension(uuid, 'partner');
              }}
            >
              {formatMessage({ id: 'global.hang' })}
            </div>
          </div>
        </OutsideClickHandler>
      )}
      {settingsType.id === id && settingsType.type === settingsTypes.suspended && (
        <OutsideClickHandler display="flex" onOutsideClick={() => setSettingsType({ type: '', id: '' }, 10)}>
          <div className="user-management__table__settings__list" id={id}>
            <div
              className="user-management__table__settings__list--item"
              onClick={() => {
                closeSettings();
                userSuspension(uuid, 'partner');
              }}
            >
              {formatMessage({ id: 'global.restore' })}
            </div>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default PartnerSettings;

PartnerSettings.propTypes = {
  data: object.isRequired,
  formatMessage: func.isRequired,
  id: number.isRequired,
  openModal: bool.isRequired,
  setOpenModal: func.isRequired,
  setSettingsType: func.isRequired,
  settingsType: string.isRequired,
  userSuspension: func.isRequired,
};

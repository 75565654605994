import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { func, bool, object } from 'prop-types';
import { Modal, SelectField, ModalActions } from 'shared/components';
import { MODAL_TYPE, VALIDATION_FIELD } from 'shared/consts';
import { Field, Formik } from 'formik';

const AssignPartner = ({ openModal, formatMessage, onClose, users, managingPartnerApplication }) => {
  const [userSelect, setUserSelect] = useState([]);
  const { city, post_code, company_name, house_number, street, phone_prefix, phone_number, nip, type, owner_first_name, owner_last_name } = openModal.data;

  console.log(openModal.data);

  useEffect(() => {
    const arr = [];
    users && users.length > 0 && users.forEach(({ label, value }) => arr.push({ label, searchableLabel: label, value }));
    setUserSelect(arr);
  }, [users]);

  const onSubmit = async values => {
    await managingPartnerApplication({ ...values, acceptance: true, uuid: openModal.data.uuid });
    onClose();
  };

  // const declineTheRequest = async () => {
  //   await managingPartnerApplication({ uuid: openModal.data.uuid, acceptance: false });
  //   onClose();
  // };

  const validation = () =>
    Yup.object().shape({
      client_uuid: Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED),
    });

  return (
    <div>
      {openModal.isOpened === 'assign' && (
        <Modal className={MODAL_TYPE.BASIC} onClose={onClose} opened={openModal.isOpened}>
          <div>
            <div className="modal__heading modal__heading--center">{formatMessage({ id: 'global.assign_customer' })}</div>
            <Formik
              validationSchema={validation()}
              initialValues={{
                client_uuid: '',
              }}
              onSubmit={onSubmit}
            >
              {({ handleSubmit, errors }) => (
                <form className="categoryManagement__addCategory__wrapper" onSubmit={handleSubmit}>
                  <div className="categoryManagement__assign-client">
                    <div className="categoryManagement__form--constant--heading">
                      {formatMessage({ id: 'global.customer_data' })}
                    </div>
                    <Field
                      className="add-advertisement__select"
                      component={SelectField}
                      placeholder={formatMessage({ id: 'inputs.choose' })}
                      label={formatMessage({ id: 'global.assign_client' })}
                      errors={errors}
                      name="client_uuid"
                      options={userSelect}
                      isCustomLabel
                    />
                    <div className="categoryManagement__form--constant">
                      <p className="top">{formatMessage({ id: 'global.name' })}</p>
                      <p className="bottom">{owner_first_name}</p>
                    </div>                    
                    <div className="categoryManagement__form--constant">
                      <p className="top">{formatMessage({ id: 'global.last_name' })}</p>
                      <p className="bottom">{owner_last_name}</p>
                    </div>
                    <div className="categoryManagement__form--constant">
                      <p className="top">{formatMessage({ id: 'inputs.company_name' })}</p>
                      <p className="bottom">{company_name}</p>
                    </div>
                    <div className="categoryManagement__form--constant">
                      <p className="top">{formatMessage({ id: 'global.telephone' })}</p>
                      <p className="bottom">
                        {phone_prefix} {phone_number}
                      </p>
                    </div>
                    <div className="categoryManagement__form--constant">
                      <p className="top">{formatMessage({ id: 'global.nip' })}</p>
                      <p className="bottom">{nip}</p>
                    </div>
                    <div className="categoryManagement__form--constant">
                      <p className="top">{formatMessage({ id: 'global.company_address' })}</p>
                      <p className="bottom">
                        {street} {house_number}
                      </p>
                    </div>
                    <div className="categoryManagement__form--constant">
                      <p className="top">{formatMessage({ id: 'inputs.post_code' })}</p>
                      <p className="bottom">{post_code}</p>
                    </div>
                    <div className="categoryManagement__form--constant">
                      <p className="top">{formatMessage({ id: 'inputs.city' })}</p>
                      <p className="bottom">{city}</p>
                    </div>                    
                    <div className="categoryManagement__form--constant">
                      <p className="top">{formatMessage({ id: 'inputs.subscription_type' })}</p>
                      <p className="bottom">{type}</p>
                    </div>
                    <ModalActions
                      formatMessage={formatMessage}
                      type={MODAL_TYPE.ASSIGN_PARTNER}
                      onClose={()=> onClose()}
                      onSubmit={handleSubmit}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
    </div>
  );
};
export default AssignPartner;

AssignPartner.propTypes = {
  editUser: func.isRequired,
  formatMessage: func.isRequired,
  managingPartnerApplication: func.isRequired,
  onClose: func.isRequired,
  openModal: bool.isRequired,
  users: object.isRequired,
};

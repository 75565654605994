const deliveryCountryLabelShort = label => {
  if (label === 'Poland') {
    return 'PL';
  }
  if (label === 'Germany') {
    return 'DE';
  }
  return true;
};

export default deliveryCountryLabelShort;

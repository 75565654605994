import API from 'shared/connectors/config';

export function getPriceMargin(option, partner_uuid) {
  if (partner_uuid) {
    return API.get(`price-margin/list/${option}/?partner_uuid=${partner_uuid}`);
  }
  return API.get(`price-margin/list/${option}`);
}

export function createPriceMargin(option) {
  return API.post(`price-margin`, option);
}

export function editPriceMargin(uuid, data) {
  return API.put(`price-margin/${uuid}`, data);
}

export function getCurrencyExchangeList() {
  return API.get(`currency/exchange/list`);
}

export function editCurrencyExchange(uuid, value) {
  return API.put(`currency/exchange/${uuid}`, { exchange_value: value });
}

export function deletePriceMargin(uuid) {
  return API.delete(`price-margin/delete/${uuid}`);
}

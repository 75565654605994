import * as Connector from 'shared/connectors/partnerConnector';
import { PARTNER } from 'store/types';

const init = () => ({
  type: PARTNER.PARTNER_REQUEST_INIT,
});

export const success = () => ({
  type: PARTNER.PARTNER_REQUEST_SUCCESS,
});

const failure = () => ({
  type: PARTNER.PARTNER_REQUEST_FAILURE,
});

const partnerRequest = form => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.partnerRequest(form);
    dispatch(success(data));
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default partnerRequest;

/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { array, func,object,string } from 'prop-types';
import SingleBrandBar from '../single-brand-bar';

const PopularBrands = ({ formatMessage, popularBrands,country }) => {
  const [filteredBrands, setFilteredBrands] = useState([]);

  const sortData = arr =>
    arr &&
    arr.sort((a, b) => {
      const textA = a.brand[0].toUpperCase();
      const textB = b.brand[0].toUpperCase();
      const test1 = textA < textB;
      const test2 = textA > textB ? 1 : 0;
      return test1 ? -1 : test2;
    });
  
  useEffect(() => {
    popularBrands &&
      setFilteredBrands(
        sortData(
          popularBrands.filter(
            ({ total_pl, total_de }) => (country === 'pl' ? total_pl : total_de) > 0,
          ),
        ),
      );
  }, [popularBrands, country]);

  return (
    <section className="popular-brands">
      <h2 className="popular-brands--name">{formatMessage({ id: 'global.search_by_popular_brands' })}</h2>
      <div className="popular-brands__list">
        {popularBrands &&
          filteredBrands
            .slice(0, 32)
            .map(({ brand, total }, index) => (
              <SingleBrandBar
                label={brand}
                quantity={total}
                first={filteredBrands[index - 1]?.brand[0] !== filteredBrands[index].brand[0]}
              />
            ))}
      </div>
    </section>
  );
};

export default PopularBrands;
PopularBrands.propTypes = {
  authorizedUser: object.isRequired,
  country: string.isRequired,
  formatMessage: func.isRequired,
  popularBrands: array.isRequired,
  translation: string.isRequired,
};

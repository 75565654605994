import React from 'react';
import { Modal, ModalActions } from 'shared/components';
import { MODAL_TYPE } from 'shared/consts';
import { func, object } from 'prop-types';

const DeleteDeliveryMethod = ({ info, onClose, formatMessage, deleteDeliveryMethod }) => {
  const isOpened = info.which === 'delete';
  return (
    <div>
      {isOpened && (
        <Modal className={MODAL_TYPE.CANCEL} onClose={onClose} opened={isOpened}>
          <div>
            <div className="modal__heading modal__heading--center">{formatMessage({ id: 'global.delete_delivery_method' })}</div>
            <div className="modal__description modal__description--center ">
              {formatMessage({ id: 'global.sure_delete_delivery_method' })}
            </div>
            <ModalActions
              formatMessage={formatMessage}
              type={MODAL_TYPE.DELETE_MODAL}
              onClose={onClose}
              onSubmit={() => {
                deleteDeliveryMethod(info.item);
                onClose();
              }}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};
export default DeleteDeliveryMethod;

DeleteDeliveryMethod.propTypes = {
  deleteDeliveryMethod: func.isRequired,
  formatMessage: func.isRequired,
  info: object.isRequired,
  onClose: func.isRequired,
};

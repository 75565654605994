import React from 'react';
import { Modal, ModalActions, RegularField, SelectField, Title } from 'shared/components';
import { MODAL_TYPE, VALIDATION_FIELD } from 'shared/consts';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';

const AddTransferModal = ({ opened, onClose, formatMessage, data, addTransfer, deliveryCountries }) => {
  const Shema = Yup.object().shape({
    amount: Yup.number().max(data?.data.amount, VALIDATION_FIELD(formatMessage).BAD_VALUE).required('Required'),
  });

  return (
    <>
      <Modal opened={opened} onClose={onClose} className="edit">
        <div>
          <Title title="Transfer" className="modals" />
        </div>
        <div className="productInfo">
          <p className="productInfo__label">{formatMessage({ id: 'global.product_name' })}</p>
          <p className="productInfo__name">
            {data?.data.brand} {data?.data.model}
          </p>
        </div>
        <div className="productInfo">
          <p className="productInfo__label">{formatMessage({ id: 'global.magazine_country' })}</p>
          <p className="productInfo__name">{data?.data.country?.country}</p>
        </div>
        <div>
          <Formik
            initialValues={{
              amount: '',
              product_uuid: data?.data.uuid,
              country_uuid: '',
            }}
            validationSchema={Shema}
            onSubmit={values => {
              addTransfer(values);
              onClose();
            }}
          >
            {({ errors, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="edit__fields">
                  <Field
                    name="country_uuid"
                    component={SelectField}
                    errors={errors}
                    label={formatMessage({ id: 'global.transfer_country' })}
                    className="add-advertisement__select"
                    options={deliveryCountries.filter(({ value }) => value !== data?.data?.country?.uuid)}
                  />
                  <Field
                    name="amount"
                    component={RegularField}
                    errors={errors}
                    type="text"
                    label={formatMessage({ id: 'global.amount' })}
                    className="add-advertisement__field"
                  />
                </div>
                <ModalActions
                  type={MODAL_TYPE.EDIT_WAREHOUSE}
                  formatMessage={formatMessage}
                  onClose={onClose}
                  onSubmit={handleSubmit}
                />
              </form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default AddTransferModal;

AddTransferModal.propTypes = {
  addTransfer: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  deliveryCountries: PropTypes.array.isRequired,
  formatMessage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
};

import React, { useEffect, useState } from 'react';
import { NAVIGATION_ITEMS, NAVIGATION_CATEGORY, SVG_TYPE, ROUTES, USER_ROLES } from 'shared/consts';
import { Basket, SVG, LanguageSwitch, Button } from 'shared/components';
import { NavLink, useHistory } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { object, func, string, array } from 'prop-types';
import { generatePath } from 'react-router';

const MenuDesktop = ({
  authorizedUser,
  modifyShoppingCart,
  mainCategory,
  logout,
  setAppTranslation,
  translation,
  formatMessage,
  shoppingCart,
  createCheckoutSession,
  cancelCheckout,
  country,
  
}) => {
  const history = useHistory();
  const isUserLogged = localStorage.getItem('isUserLogged');
  const [isCategory, setIsCategory] = useState(false);
  const [mainCategories, setMainCategories] = useState([]);
  const showBasketIcon =
    !authorizedUser || authorizedUser.role === USER_ROLES.PARTNER || authorizedUser.role === USER_ROLES.CLIENT;

  const {
    DELIVERY_OPTIONS,
    USER_MANAGEMENT,
    CATEGORY_MANAGEMENT,
    PRICE_MANAGEMENT,
    LOGOUT,
    DICTIONARY,
    CATEGORY_NAME,
    CHAT,
    // SUBCATEGORY_NAME,
    ADVERTISEMENTS,
    ADD_ADVERTISEMENT,
    SETTINGS,
    MAGAZINE,
    ORDERS,
  } = ROUTES;

  const handleClickMenu = id => {
    const subMenuItemActive = document.getElementsByClassName('subMenu--active');
    if (subMenuItemActive.length === 1) {
      if (`subMenu--${id}` === subMenuItemActive[0].id) {
        subMenuItemActive[0].classList.remove('subMenu--active');
      } else {
        subMenuItemActive[0].classList.remove('subMenu--active');
        const element = document.getElementById(`subMenu--${id}`);
        element && element.classList.add('subMenu--active');
      }
    } else {
      const element = document.getElementById(`subMenu--${id}`);
      element && element.classList.add('subMenu--active');
    }
  };

  useEffect(() => {
    const categories = mainCategory?.category;
    const subCategories = NAVIGATION_ITEMS(formatMessage)[1].category[0].sub_category;

    categories &&
      subCategories.length === 0 &&
      categories
        .filter(({ products_count_pl, products_count_de }) => (country === 'pl' ? products_count_pl : products_count_de) > 0)
        .forEach(({ name, link, subcategories, name_ger }) => {
          const arr = () => {
            const subcatArr = [];
            subcategories.forEach(
              item => (country === 'pl' ? item.products_count_pl : item.products_count_de) > 0 && subcatArr.push(item),
            );
            return subcatArr;
          };
          subCategories.push({
            sub_category_route: link,
            sub_category_label: name,
            sub_category_label_ger: name_ger,
            sub_category_icon: arr().length > 0 && subcategories.length > 0 && SVG_TYPE.CHEVRONE,
            subcategories: arr(),
          });
        });
    subCategories.length > 0 && setIsCategory(true);
    subCategories.length > 0 && setMainCategories(subCategories);
  }, [mainCategory.category, country]);

  useEffect(() => {
    const categoryWrapper = document.getElementById('subMenu--0');
    const categoryItems = categoryWrapper && categoryWrapper.getElementsByClassName('subMenu__list--item');
    const seeMoreHidden = document.getElementsByClassName('subMenu__more--hidden');
    seeMoreHidden.length > 0 && seeMoreHidden[0].classList.remove('subMenu__more--hidden');
    categoryItems &&
      Array.from(categoryItems).forEach((el, idx) => {
        idx >= 11 && el.classList.add('subMenu__list--item--hidden');
      });
  });

  const handleCategoryClick = e => {
    const activeSubcategories = document.getElementsByClassName('subMenu__category--active');
    const activeCategories = document.getElementsByClassName('subMenu__list--item--active');
    const removeActiveSubcategories =
      activeSubcategories.length > 0 && activeSubcategories[0].classList.remove('subMenu__category--active');
    const isThisElementActive = e.target.classList.contains('subMenu__list--item--active');
    const thisElement = e.target;
    const thisElementId = thisElement.id;
    const subcategory = document.getElementById(`sub${thisElementId}`);
    const isSubcategoryActive = subcategory.classList.contains('subMenu__category--active');
    !isThisElementActive && activeCategories.length > 0 && activeCategories[0].classList.remove('subMenu__list--item--active');
    isThisElementActive
      ? thisElement.classList.remove('subMenu__list--item--active')
      : (thisElement.classList.add('subMenu__list--item--active'),
        isSubcategoryActive
          ? removeActiveSubcategories
          : (removeActiveSubcategories, subcategory.classList.add('subMenu__category--active')));
  };

  const seeMoreCategory = e => {
    const hiddenElements = document.getElementsByClassName('subMenu__list--item--hidden');
    e.target.classList.add('subMenu__more--hidden');
    hiddenElements &&
      Array.from(hiddenElements).forEach(el => {
        el.classList.remove('subMenu__list--item--hidden');
      });
  };

  const outsideClick = () => {
    const categories = document.getElementsByClassName('subMenu--active');
    const subcategories = document.getElementsByClassName('subMenu__category--active');
    const categoryActive = document.getElementsByClassName('subMenu__list--item--active');
    const categoryWrapper = document.getElementById('subMenu--0');
    const categoryItems = categoryWrapper && categoryWrapper.getElementsByClassName('subMenu__list--item');
    const seeMoreHidden = document.getElementsByClassName('subMenu__more--hidden');
    seeMoreHidden.length > 0 && seeMoreHidden[0].classList.remove('subMenu__more--hidden');
    categoryItems &&
      Array.from(categoryItems).forEach((el, idx) => {
        idx >= 11 && el.classList.add('subMenu__list--item--hidden');
      });
    setTimeout(() => {
      categoryActive.length > 0 && categoryActive[0].classList.remove('subMenu__list--item--active');
      subcategories.length > 0 && subcategories[0].classList.remove('subMenu__category--active');
      categories.length > 0 && categories[0].classList.remove('subMenu--active');
    });
  };
  return (
    <>
      <nav className="menu__items">
        <OutsideClickHandler display="flex" onOutsideClick={() => outsideClick()}>
          {NAVIGATION_ITEMS(formatMessage).map(({ label, route, icon, category, category_class }, idx) => (
            <div className="menu__item--wrapper" key={label}>
              {(label === formatMessage({ id: 'menu.my_account' }) && !isUserLogged) ||
              (label === formatMessage({ id: 'menu.login2' }) && isUserLogged) ? (
                ''
              ) : (
                <>
                  {route ? (
                    <NavLink
                      key={label}
                      activeClassName="menu__item--active"
                      className="menu__item"
                      to={route}
                      // isActive={!route}
                      onClick={() => handleClickMenu(idx)}
                    >
                      <SVG className="menu__icon" type={icon} />
                      {label === formatMessage({ id: 'menu.login2' }) && !isUserLogged ? (
                        <Button className="btn--orange" label={formatMessage({ id: 'menu.login2' })} />
                      ) : (
                        label
                      )}
                    </NavLink>
                  ) : (
                    <div
                      className={`menu__item ${history.location.pathname === '/category/' ? 'menu__item--active' : ''}`}
                      onClick={() => handleClickMenu(idx)}
                    >
                      <SVG className="menu__icon" type={icon} />
                      <p>{label} </p>
                    </div>
                  )}
                </>
              )}

              {(idx ===1 ?isCategory: true) && category && (
                <div id={`subMenu--${idx}`} className={`subMenu subMenu__${category_class}`}>
                  {category.map(({ category_label, sub_category }) => {
                    const submenu = category_class === NAVIGATION_CATEGORY.MAIN ? mainCategories : sub_category;

                    return (
                      <>
                        <div
                          key={category_label}
                          className={`subMenu__${category_class === NAVIGATION_CATEGORY.MAIN ? 'half' : 'fullWidth'} `}
                        >
                          <p className="subMenu--header">{category_label}</p>
                          <div className="subMenu__list">
                            {submenu.map(
                              ({ sub_category_route, sub_category_label, sub_category_icon, sub_category_label_ger }, id) => {
                                const lang = translation === 'pl' ? sub_category_label : sub_category_label_ger;
                                const onlyForAdmin =
                                  sub_category_route === CATEGORY_MANAGEMENT ||
                                  sub_category_route === PRICE_MANAGEMENT ||
                                  sub_category_route === DICTIONARY ||
                                  sub_category_route === CHAT ||
                                  sub_category_route === USER_MANAGEMENT ||
                                  sub_category_route === MAGAZINE ||
                                  sub_category_route === SETTINGS ||
                                  sub_category_route === !LOGOUT ||
                                  sub_category_route === DELIVERY_OPTIONS;
                                const storemanRoute =
                                  sub_category_route === SETTINGS ||
                                  sub_category_route === MAGAZINE
                                const storeman = authorizedUser?.role === USER_ROLES.STOREMAN;
                                const clientOrPartnerRoute =
                                  sub_category_route === SETTINGS ||
                                  sub_category_route === ORDERS ||
                                  sub_category_route === ADVERTISEMENTS ||
                                  sub_category_route === ADD_ADVERTISEMENT;
                                const clientOrPartner =
                                  authorizedUser?.role === USER_ROLES.CLIENT || authorizedUser?.role === USER_ROLES.PARTNER;
                                const coordinatorRoute =
                                  sub_category_route === ADVERTISEMENTS ||
                                  sub_category_route === DELIVERY_OPTIONS ||
                                  sub_category_route === MAGAZINE ||
                                  sub_category_route === DICTIONARY ||
                                  sub_category_route === CHAT ||
                                  sub_category_route === ADD_ADVERTISEMENT ||
                                  sub_category_route === SETTINGS;
                                const coordinator = authorizedUser?.role === USER_ROLES.COORDINATOR;
                                const admin = authorizedUser?.role === USER_ROLES.ADMIN;
                                return (
                                  <>
                                    {onlyForAdmin && (
                                      <>
                                        {admin && (
                                          <NavLink
                                            key={sub_category_label}
                                            activeClassName="subMenu__list--item--active"
                                            className="subMenu__list--item"
                                            to={sub_category_route}
                                            onClick={() => {
                                              outsideClick();
                                              sub_category_route === LOGOUT && logout();
                                            }}
                                          >
                                            {sub_category_label}
                                            {sub_category_icon && (
                                              <SVG className="subMenu__list--item-icon" type={sub_category_icon} />
                                            )}
                                          </NavLink>
                                        )}
                                      </>
                                    )}

                                    {coordinator && (
                                      <>
                                        {coordinatorRoute ? (
                                          <NavLink
                                            key={sub_category_label}
                                            activeClassName="subMenu__list--item--active"
                                            className="subMenu__list--item"
                                            to={sub_category_route}
                                            onClick={outsideClick()}
                                          >
                                            {sub_category_label}
                                            {sub_category_icon && (
                                              <SVG className="subMenu__list--item-icon" type={sub_category_icon} />
                                            )}
                                          </NavLink>
                                        ) : (
                                          ''
                                        )}
                                      </>
                                    )}
                                    {storeman && (
                                      <>
                                        {storemanRoute && (
                                          <NavLink
                                            key={sub_category_label}
                                            activeClassName="subMenu__list--item--active"
                                            className="subMenu__list--item"
                                            to={sub_category_route}
                                            onClick={outsideClick()}
                                          >
                                            {sub_category_label}
                                            {sub_category_icon && (
                                              <SVG className="subMenu__list--item-icon" type={sub_category_icon} />
                                            )}
                                          </NavLink>
                                        )}
                                      </>
                                    )}
                                    {clientOrPartner && (
                                      <>
                                        {clientOrPartnerRoute && (
                                          <NavLink
                                            key={sub_category_label}
                                            activeClassName="subMenu__list--item--active"
                                            className="subMenu__list--item"
                                            to={sub_category_route}
                                            onClick={outsideClick()}
                                          >
                                            {sub_category_label}
                                            {sub_category_icon && (
                                              <SVG className="subMenu__list--item-icon" type={sub_category_icon} />
                                            )}
                                          </NavLink>
                                        )}
                                      </>
                                    )}
                                    {category_class === 'main' && (
                                      <>
                                        {!sub_category_icon ? (
                                          <>
                                            {(admin && sub_category_route === ADVERTISEMENTS) ||
                                            sub_category_route === SETTINGS ||
                                            (admin && sub_category_route === ADD_ADVERTISEMENT)
                                              ? ''
                                              : !(sub_category_route === LOGOUT) && (
                                              <div
                                                className="subMenu__list--item"
                                                onClick={() =>
                                                      sub_category_route === LOGOUT
                                                        ? (logout(), outsideClick())
                                                        : (history.push(
                                                            category_class === NAVIGATION_CATEGORY.MAIN
                                                              ? generatePath(CATEGORY_NAME, { category: sub_category_route })
                                                              : sub_category_route,
                                                          ),
                                                          outsideClick())
                                                    }
                                              >
                                                {category_class === NAVIGATION_CATEGORY.MAIN ? lang : sub_category_label}
                                              </div>
                                                )}
                                          </>
                                        ) : (
                                          <>
                                            <div
                                              className="subMenu__list--item"
                                              id={`category-${id}`}
                                              onClick={e => handleCategoryClick(e)}
                                            >
                                              {category_class === NAVIGATION_CATEGORY.MAIN ? lang : sub_category_label}
                                              {sub_category_icon && (
                                                <SVG className="subMenu__list--item-icon" type={sub_category_icon} />
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </>
                                    )}
                                    <>
                                      {sub_category_route === LOGOUT && (
                                        <NavLink
                                          key={sub_category_label}
                                          activeClassName="subMenu__list--item--active"
                                          className="subMenu__list--item"
                                          to={ROUTES.LOGIN}
                                          onClick={async () => {
                                            outsideClick();
                                            sub_category_route === LOGOUT && (await logout());
                                          }}
                                        >
                                          {sub_category_label}
                                          {sub_category_icon && (
                                            <SVG className="subMenu__list--item-icon" type={sub_category_icon} />
                                          )}
                                        </NavLink>
                                      )}
                                    </>
                                  </>
                                );
                              },
                            )}
                            {submenu.length > 11 && category_class === 'main' && (
                              <div className="subMenu__more" onClick={e => seeMoreCategory(e)}>
                                {formatMessage({ id: 'menu.more' })} <p> + {submenu.length - 11}</p>
                              </div>
                            )}
                          </div>
                        </div>

                        {category_class === NAVIGATION_CATEGORY.MAIN &&
                          mainCategories.length > 0 &&
                          mainCategories.map(({ subcategories, sub_category_label, sub_category_route, id }, elemId) => (
                            <>
                              {subcategories && subcategories.length > 0 && (
                                <div className="subMenu__category" key={id} id={`subcategory-${elemId}`}>
                                  <div className="subMenu__heading">
                                    <p className="subMenu--header">{sub_category_label} </p>
                                    <p className="subMenu__all-category">
                                      <div
                                        onClick={() => {
                                          history.push(generatePath(`${ROUTES.CATEGORY}/?s=&main_cat=${sub_category_route}`));
                                          // history.push(generatePath(CATEGORY_NAME, { category: sub_category_route }));
                                          outsideClick();
                                          localStorage.setItem('showAll', true);
                                        }}
                                      >
                                        {formatMessage({ id: 'menu.all' })}
                                      </div>
                                    </p>
                                  </div>
                                  {subcategories.map(({ link, name, name_ger }) => {
                                    const lang = translation === 'pl' ? name : name_ger;
                                    return (
                                      <>
                                        <div
                                          key={name}
                                          className="subMenu__subcategorylist--item"
                                          onClick={() => {
                                            history.push(`${ROUTES.CATEGORY}/?s=&main_cat=${sub_category_route}&cat=${link}`);
                                            // history.push(
                                            //   generatePath(SUBCATEGORY_NAME, {
                                            //     category: sub_category_route,
                                            //     subcategory: link,
                                            //   }),
                                            // );
                                            localStorage.setItem('isSetCategory', true);
                                            outsideClick();
                                          }}
                                        >
                                          {category_class === NAVIGATION_CATEGORY.MAIN ? lang : sub_category_label}
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              )}
                            </>
                          ))}
                      </>
                    );
                  })}

                  {category_class === NAVIGATION_CATEGORY.HELP && (
                    <div className="subMenu__help--contact">
                      <div className="subMenu__help--icon">
                        <SVG type={SVG_TYPE.PHONE} />
                        <a href={`tel:${formatMessage({ id: 'menu.telephone' })}`} data-rel="external">
                          {formatMessage({ id: 'menu.telephone' })}
                        </a>
                      </div>
                      <div className="subMenu__help--icon">
                        <SVG type={SVG_TYPE.MAIL} />
                        <p>{formatMessage({ id: 'menu.mail' })}</p>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </OutsideClickHandler>
      </nav>
      {showBasketIcon && (
        <Basket
          cancelCheckout={cancelCheckout}
          authorizedUser={authorizedUser}
          modifyShoppingCart={modifyShoppingCart}
          shoppingCart={shoppingCart}
          formatMessage={formatMessage}
          translation={translation}
          createCheckoutSession={createCheckoutSession}
        />
      )}
      <LanguageSwitch translation={translation} setAppTranslation={setAppTranslation} />
    </>
  );
};
export default MenuDesktop;

MenuDesktop.defaultProps = {
  authorizedUser: undefined,
};

MenuDesktop.propTypes = {
  authorizedUser: object,
  cancelCheckout: func.isRequired,
  country: string.isRequired,
  createCheckoutSession: func.isRequired,
  formatMessage: func.isRequired,
  logout: func.isRequired,
  mainCategory: array.isRequired,
  modifyShoppingCart: func.isRequired,
  setAppTranslation: func.isRequired,
  shoppingCart: object.isRequired,
  translation: string.isRequired,
};

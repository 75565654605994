import React from 'react';
import * as Connector from 'shared/connectors/deliveryConnector';
import { DELIVERY } from 'store/types';
import { getDelivery } from 'store/actions/delivery';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import { NOTIFY_TYPE } from 'shared/consts';

const init = () => ({
  type: DELIVERY.DELETE_DELIVERY_METHOD_INIT,
});

export const success = () => ({
  type: DELIVERY.DELETE_DELIVERY_METHOD_SUCCESS,
});

const failure = () => ({
  type: DELIVERY.DELETE_DELIVERY_METHOD_FAILURE,
});

const deleteDeliveryMethod = item => async dispatch => {
  dispatch(init());
  try {
    await Connector.deleteDeliveryMethod(item.uuid);
    toast(<Notify label="global.remove_delivery_option" type={NOTIFY_TYPE.CHECKED} />);
    dispatch(getDelivery(item.country.label));
    return true;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default deleteDeliveryMethod;

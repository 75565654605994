import React from 'react';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import * as Connector from 'shared/connectors/roomsConnector';
import { NOTIFY_TYPE } from 'shared/consts';
import { ROOM } from 'store/types';



export const success = data => ({
  type: ROOM.UPDATE_CHAT_STATUS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: ROOM.UPDATE_CHAT_STATUS_FAILURE,
});

const updateChatStatus = data => async dispatch => {
  try {
    await Connector.updateChatStatus(data);
    dispatch(success(data));
    return true;
  } catch (err) {
    err.response.data.message==="User actually has an active chat" && toast(<Notify label="global.user_actually_has_an_active_chat" type={NOTIFY_TYPE.CHECKED} />,'failure');
    
    dispatch(failure());
    return err.response;
  }
};

export default updateChatStatus;

import React, { useState, useEffect } from 'react';
import { bool, func, object } from 'prop-types';
import { DeliveryAddressForm, DifferentDeliveryAddressModal } from 'screens/checkout/components';

const Address = ({
  customCheckoutAddress,
  formatMessage,
  setTransactionData,
  authorizedUser,
  isSubmitting,
  setIsSubmitting,
  simulateCheckout,
  basket,
  deliveryCountries,
  isMobile,
}) => {
  const deliverySelectionOptions = {
    default: 'default',
    custom: 'custom',
  };
  const storage = JSON.parse(localStorage.getItem('customDeliveryAddress'));
  const { first_name, last_name, email, street, house_number, apartment_number, post_code, city } = customCheckoutAddress;
  const [newDeliveryAddress, setNewDeliveryAddress] = useState({ isModalOpen: false, values: storage });
  const [deliverySelection, setDeliverySelection] = useState(
    storage ? deliverySelectionOptions.custom : deliverySelectionOptions.default,
  );
  const countryUuid = authorizedUser?.country?.label || localStorage.getItem('deliveryCountry');

  const setDeliveryAddress = options => {
    setDeliverySelection(options);
  };

  useEffect(() => {
    if (storage) {
      setNewDeliveryAddress({ isModalOpen: false, values: storage });
      setDeliverySelection(deliverySelectionOptions.custom);
    }
  }, []);

  useEffect(() => {
    if (deliverySelection === deliverySelectionOptions.custom) {
      setTransactionData({
        first_name: newDeliveryAddress?.values?.first_name,
        last_name: newDeliveryAddress?.values?.last_name,
        email: newDeliveryAddress?.values?.email,
        address: `${newDeliveryAddress?.values?.street} ${newDeliveryAddress?.values?.house_number}`,
        post_code: newDeliveryAddress?.values?.post_code,
        city: newDeliveryAddress?.values?.city,
        phone_number: newDeliveryAddress?.values?.phone_number,
        phone_prefix: newDeliveryAddress?.values?.phone_prefix,
        country_of_delivery_uuid: countryUuid,
      });
    } else {
      customCheckoutAddress.first_name &&
        setTransactionData({
          first_name: customCheckoutAddress.first_name,
          last_name: customCheckoutAddress.last_name,
          email: customCheckoutAddress.email,
          address: `${customCheckoutAddress.street} ${customCheckoutAddress.house_number}`,
          post_code: customCheckoutAddress.post_code,
          city: customCheckoutAddress.city,
          phone_number: customCheckoutAddress.phone_number,
          phone_prefix: customCheckoutAddress.phone_prefix,
          country_of_delivery_uuid: countryUuid,
        });
    }
  }, [deliverySelection, customCheckoutAddress]);

  return (
    <div className="content">
      <div className="heading">{formatMessage({ id: `${authorizedUser ? 'global.delivery_address' : 'global.your_data'}` })}</div>
      {first_name ? (
        <>
          <div className="address-box__wrapper">
            {newDeliveryAddress?.values && (
              <div className="address-box__content">
                <p>{formatMessage({ id: 'global.delivery_address' })}</p>
                <div
                  className={`address-box ${
                    newDeliveryAddress?.values
                      ? `address-box--border ${
                          deliverySelection === deliverySelectionOptions.custom ? 'address-box--active' : 'address-box--faded'
                        }`
                      : ''
                  }`}
                  onClick={() => newDeliveryAddress?.values && setDeliveryAddress(deliverySelectionOptions.custom)}
                >
                  <div>
                    <span>{`${newDeliveryAddress?.values?.first_name} ${newDeliveryAddress?.values?.last_name}`}</span>
                    <span>{newDeliveryAddress?.values?.email}</span>
                    <span>
                      {`${newDeliveryAddress?.values?.street} ${newDeliveryAddress?.values?.house_number} ${
                        newDeliveryAddress?.values?.apartment_number ? `/ ${newDeliveryAddress?.values?.apartment_number}` : ''
                      }`}
                    </span>
                    <span>
                      {newDeliveryAddress?.values?.post_code} {newDeliveryAddress?.values?.city}
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div className="address-box__content">
              <p>{formatMessage({ id: 'global.default_address' })}</p>
              <div
                className={`address-box ${
                  newDeliveryAddress.values
                    ? `address-box--border ${
                        deliverySelection === deliverySelectionOptions.default ? 'address-box--active' : 'address-box--faded'
                      }`
                    : ''
                }`}
                onClick={() => newDeliveryAddress?.values && setDeliveryAddress(deliverySelectionOptions.default)}
              >
                <div>
                  <span>{`${first_name} ${last_name}`}</span>
                  <span>{email}</span>
                  <span>{`${street} ${house_number} ${apartment_number ? `/ ${apartment_number}` : ''}`}</span>
                  <span>
                    {post_code} {city}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="address-box--new-address"
            onClick={() => setNewDeliveryAddress({ isModalOpen: true, values: newDeliveryAddress?.values })}
          >
            {formatMessage({ id: 'global.delivery_different_address' })}
          </div>
        </>
      ) : (
        <DeliveryAddressForm
          setTransactionData={setTransactionData}
          basket={basket}
          simulateCheckout={simulateCheckout}
          authorizedUser={authorizedUser}
          setIsSubmitting={setIsSubmitting}
          isSubmitting={isSubmitting}
          formatMessage={formatMessage}
          isMobile={isMobile}
          deliveryCountries={deliveryCountries}
        />
      )}
      <DifferentDeliveryAddressModal
        formatMessage={formatMessage}
        setDeliverySelection={setDeliverySelection}
        setNewDeliveryAddress={setNewDeliveryAddress}
        newDeliveryAddress={newDeliveryAddress}
      />
    </div>
  );
};

export default Address;

Address.propTypes = {
  authorizedUser: object.isRequired,
  basket: object.isRequired,
  customCheckoutAddress: object.isRequired,
  deliveryCountries: object.isRequired,
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
  isSubmitting: bool.isRequired,
  setIsSubmitting: func.isRequired,
  setTransactionData: func.isRequired,
  simulateCheckout: func.isRequired,
  transactionData: object.isRequired,
};

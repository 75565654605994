import React from 'react';
import { shape, func, object } from 'prop-types';
import PaymentSuccess from 'assets/files/payment_success.png';
import { Button } from 'shared/components';
import { SVG_TYPE, ROUTES } from 'shared/consts';
import { useHistory } from 'react-router-dom';

const Advertisements = ({ intl: { formatMessage }, authorizedUser }) => {
  const history = useHistory();

  return (
    <div className="payments">
      <div className="payments__content">
        <p className="descript">{formatMessage({ id: 'global.your_order_has_been_accepted' })}</p>
        <p className="descript">
          {formatMessage({ id: 'global.make_payment_go_to_the_tab' })}{' '}
          <span onClick={() => history.push(ROUTES.ORDERS)}>{formatMessage({ id: 'global.orders' })}.</span>
        </p>
        <div className="payments__image--wrapper">
          <div className="payments__image--content">
            <img src={PaymentSuccess} alt="payment success" />
            {authorizedUser && 
              <Button
                className="btn--orange"
                label={formatMessage({ id: 'global.my_orders' })}
                iconType={SVG_TYPE.ARROW_RIGHT}
                onClick={() => history.push(ROUTES.ORDERS)}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advertisements;

Advertisements.propTypes = {
  authorizedUser: object.isRequired,
  intl: shape({
    formatMessage: func.isRequired,
  }).isRequired,
};

import { CATEGORY } from 'store/types';
import { REQUEST_STATUS } from 'shared/consts';

const INITIAL_STATE = {
  categoriesAndSubcategories: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  categories: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  subcategoryForCategory: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  categoryByLink: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  popularCategories: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
};

const categoryReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case CATEGORY.GET_ALL_CATEGORY_INIT: {
      return {
        ...state,
        categories: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case CATEGORY.DELETE_CATEGORY_SUCCESS: {
      return {
        ...state,
        categories: {
          data: { category: [...state.categories?.data?.category.filter(element => element.uuid !== payload)] },
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    // case CATEGORY.ADD_CATEGORY_SUCCESS: {
    //   return {
    //     ...state,
    //     categories: {
    //       data: { category: [...state.categories?.data?.category, { name: payload.name }] },
    //       status: REQUEST_STATUS.INIT,
    //     },
    //   };
    // }
    case CATEGORY.GET_ALL_CATEGORY_SUCCESS: {
      return {
        ...state,
        categories: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case CATEGORY.GET_ALL_CATEGORY_FAILURE: {
      return {
        ...state,
        categories: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case CATEGORY.GET_ALL_CATEGORY_AND_SUBCATEGORY_SUCCESS: {
      return {
        ...state,
        categoriesAndSubcategories: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case CATEGORY.GET_ALL_CATEGORY_AND_SUBCATEGORY_FAILURE: {
      return {
        ...state,
        categoriesAndSubcategories: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case CATEGORY.GET_SUBCATEGORY_FOR_CATEGORY_INIT: {
      return {
        ...state,
        subcategoryForCategory: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case CATEGORY.GET_SUBCATEGORY_FOR_CATEGORY_SUCCESS: {
      return {
        ...state,
        subcategoryForCategory: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case CATEGORY.GET_SUBCATEGORY_FOR_CATEGORY_FAILURE: {
      return {
        ...state,
        subcategoryForCategory: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case CATEGORY.GET_CATEGORY_BY_LINK_INIT: {
      return {
        ...state,
        categoryByLink: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case CATEGORY.GET_CATEGORY_BY_LINK_SUCCESS: {
      return {
        ...state,
        categoryByLink: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case CATEGORY.GET_CATEGORY_BY_LINK_FAILURE: {
      return {
        ...state,
        categoryByLink: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case CATEGORY.GET_POPULAR_CATEGORIES_INIT: {
      return {
        ...state,
        popularCategories: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case CATEGORY.GET_POPULAR_CATEGORIES_SUCCESS: {
      return {
        ...state,
        popularCategories: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case CATEGORY.GET_POPULAR_CATEGORIES_FAILURE: {
      return {
        ...state,
        popularCategories: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
      
    default:
      return state;
  }
};

export default categoryReducer;

import React from 'react';
import { Modal, ModalActions, SelectField, Title } from 'shared/components';
import { MODAL_TYPE } from 'shared/consts';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import { STATUSES } from '../utils/consts';

const EditTransferModal = ({ opened, onClose, formatMessage, onSubmit, data }) => (
  <>
    <Modal opened={opened} onClose={onClose} className="edit">
      <div>
        <Title title="Edycja" className="modals" />
      </div>
      <div>
        <Formik
          initialValues={{
            uuid: data?.data.uuid,
            status: data?.data.status,
          }}
          onSubmit={values => {
            onSubmit(values);
            onClose();
          }}
        >
          {({ errors, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="edit__fields">
                <Field
                  name="status"
                  component={SelectField}
                  errors={errors}
                  label={formatMessage({ id: 'global.transfer_status' })}
                  className="add-advertisement__select"
                  options={STATUSES}
                />
              </div>
              <ModalActions
                type={MODAL_TYPE.EDIT_WAREHOUSE}
                formatMessage={formatMessage}
                onClose={onClose}
                onSubmit={handleSubmit}
              />
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  </>
);

export default EditTransferModal;

EditTransferModal.propTypes = {
  data: PropTypes.array.isRequired,
  formatMessage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
};

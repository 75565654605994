import React from 'react';
import { func, object, bool } from 'prop-types';
import { MODAL_TYPE } from 'shared/consts';
import { Modal, ModalActions } from 'shared/components';

const RemoveApplication = ({ openModal, formatMessage, onClose, managingPartnerApplication }) => {
  const { uuid } = openModal.data;
  const declineRequest = async () => {
    await managingPartnerApplication({ uuid, acceptance: false });
  };
  return (
    <div>
      {openModal.isOpened === 'remove-application' && (
        <Modal className={MODAL_TYPE.BASIC} onClose={onClose} opened={openModal.isOpened}>
          <div className="modal__heading modal__heading--center">
            {formatMessage({ id: 'global.do_you_really_want_to_decline' })}
          </div>
          <ModalActions
            className="modal-actions__company-data"
            formatMessage={formatMessage}
            type={MODAL_TYPE.DELETE_MODAL}
            onClose={() => {
              onClose();
            }}
            onSubmit={() => {
              declineRequest();
              onClose();
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default RemoveApplication;

RemoveApplication.propTypes = {
  editUser: func.isRequired,
  formatMessage: func.isRequired,
  managingPartnerApplication: func.isRequired,
  onClose: func.isRequired,
  openModal: bool.isRequired,
  users: object.isRequired,
};

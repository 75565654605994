import React from 'react';
import { Field, Formik } from 'formik';
import { SearchField } from 'shared/components';
import { func } from 'prop-types';

const CategorySearcher = ({ formatMessage }) => {
  const handleOnSubmit = values => {
    console.log(values);
  };
  return (
    <Formik initialValues={{ search: '' }} onSubmit={handleOnSubmit}>
      {({ handleSubmit, errors }) => (
        <>
          <form className="category-searcher" onSubmit={handleSubmit}>
            <Field
              className="category-searcher--input"
              placeholder={formatMessage({ id: 'global.search_by_category' })}
              component={SearchField}
              errors={errors}
              name="search"
              type="text"
            />
          </form>
        </>
      )}
    </Formik>
  );
};

export default CategorySearcher;

CategorySearcher.propTypes = {
  formatMessage: func.isRequired,
};

import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import * as UserActions from 'store/actions/user';
import { getAuthorizedUser } from 'store/selectors/userSelector';
import PaymentAccepted from './PaymentAccepted.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getAuthUser: UserActions.getAuthorizedUser,
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  authorizedUser: getAuthorizedUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PaymentAccepted));

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { getAllegroUser } from 'store/actions/allegro';
import AllegroConnection from './AllegroConnection.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getAllegroUser: code => getAllegroUser(code),
    },
    dispatch,
  ),
});

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AllegroConnection));

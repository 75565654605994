import API from 'shared/connectors/config';

export function partnerRequest(data) {
  return API.post('partner/requests', data);
}

export function getPartnerRequests() {
  return API.get('partner/requests/list');
}

export function managingPartnerApplication(data) {
  return API.put('partner/requests/accept', data);
}

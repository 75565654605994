/* eslint react/prop-types: 0 */

import React from 'react';
import { COUNTRY } from 'screens/magazine/utils/consts';

const Country = ({ data }) => (
  <div>
    <span>{COUNTRY[data?.country.country]}</span>
  </div>
);

export default Country;

import React from 'react';
import * as Connector from 'shared/connectors/categoryConnector';
import { CATEGORY } from 'store/types';
import { getAllCategoryAndSubcategory } from 'store/actions/category';
import { NOTIFY_TYPE } from 'shared/consts';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';

export const success = uuid => ({
  type: CATEGORY.DELETE_CATEGORY_SUCCESS,
  payload: uuid,
});

const deleteCategory = uuid => async dispatch => {
  try {
    await Connector.deleteCategory(uuid);
    dispatch(success(uuid));
    dispatch(getAllCategoryAndSubcategory());
    toast(<Notify label="global.delete_category" type={NOTIFY_TYPE.CHECKED} />);
    return true;
  } catch (err) {
    return err.response;
  }
};

export default deleteCategory;

import React from 'react';
import { SVG, Basket } from 'shared/components';
import { ROUTES, SVG_TYPE, USER_ROLES } from 'shared/consts';
import { func, object } from 'prop-types';
import { Link } from 'react-router-dom';

const Icons = ({ authorizedUser, openMenu, formatMessage, shoppingCart, createCheckoutSession, cancelCheckout }) => {
  const showBasket = !authorizedUser || authorizedUser.role === USER_ROLES.PARTNER || authorizedUser.role === USER_ROLES.CLIENT;

  return (
    <div className="menu-mobile__icon--wrapper">
      <Link to={ROUTES.WATCHED_PRODUCTS}>
        <SVG className="menu-mobile__icon--other" type={SVG_TYPE.HEART} />
      </Link>
      {showBasket && (
        <Basket
          createCheckoutSession={createCheckoutSession}
          cancelCheckout={cancelCheckout}
          mobile
          shoppingCart={shoppingCart}
          formatMessage={formatMessage}
        />
      )}
      {/* <SVG className="menu-mobile__icon--other" type={SVG_TYPE.BASKET} /> */}
      <div onClick={() => openMenu()}>
        <SVG className="menu-mobile__icon--menu" type={SVG_TYPE.MENU} onClick={() => openMenu()} />
      </div>
    </div>
  );
};

export default Icons;

Icons.propTypes = {
  authorizedUser: object.isRequired,
  cancelCheckout: func.isRequired,
  createCheckoutSession: func.isRequired,
  formatMessage: func.isRequired,
  openMenu: func.isRequired,
  shoppingCart: object.isRequired,
};

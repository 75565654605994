import React from 'react';
import * as Connector from 'shared/connectors/productsConnector';
import { PRODUCTS } from 'store/types';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import { NOTIFY_TYPE } from 'shared/consts';

export const success = uuid => ({
  type: PRODUCTS.DELETE_FOLLOWED_PRODUCT_SUCCESS,
  payload: {
    uuid,
  },
});

const deleteFollowedProduct = uuid => async dispatch => {
  try {
    const { data } = await Connector.removeFromFavorites(uuid);
    dispatch(success(uuid));
    toast(<Notify label="global.remove_from_watchlist" type={NOTIFY_TYPE.REMOVE_FROM_FAVORITE} />);
    return data;
  } catch (err) {
    return err.response;
  }
};

export default deleteFollowedProduct;

import { func,string } from 'prop-types';
import React from 'react';

const Menu = ({ formatMessage, countryFilter, setCountryFilter }) => (
  <div className="help-chat__menu">
    <div className={`label${countryFilter === 'all' ? ' active' : ''} `} onClick={() => setCountryFilter('all')}>
      {formatMessage({ id: 'global.all' })}
    </div>
    <div className={`label${countryFilter === 'Poland' ? ' active' : ''} `} onClick={() => setCountryFilter('Poland')}>
      {formatMessage({ id: 'global.poland' })}
    </div>
    <div className={`label${countryFilter === 'Germany' ? ' active' : ''} `} onClick={() => setCountryFilter('Germany')}>
      {formatMessage({ id: 'global.germany' })}
    </div>
  </div>
);
export default Menu;
Menu.propTypes = {
  countryFilter:string.isRequired,
  formatMessage: func.isRequired,
  setCountryFilter:func.isRequired,
};

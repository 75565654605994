import { ROUTES, SVG_TYPE } from 'shared/consts';

export const COLUMN_MID = formatMessage => [
  {
    id: 1,
    label: formatMessage({ id: 'global.privacy_policy2' }),
    route: ROUTES.HOME_PAGE,
  },
  {
    id: 2,
    label: formatMessage({ id: 'global.rule' }),
    route: ROUTES.HOME_PAGE,
  },
  {
    id: 5,
    label: formatMessage({ id: 'global.program_partner' }),
    route: ROUTES.PARTNER_PROGRAM,
    yellow: true,
  },
];

export const COLUMN_ICON = [
  {
    id: 1,
    icon_type: SVG_TYPE.FACEBOOK,
    route: ROUTES.HOME_PAGE,
  },
  {
    id: 2,
    icon_type: SVG_TYPE.INSTAGRAM,
    route: ROUTES.HOME_PAGE,
  },
  {
    id: 3,
    icon_type: SVG_TYPE.MESSENGER,
    route: ROUTES.HOME_PAGE,
  },
];

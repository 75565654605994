import React, { useState, useEffect } from 'react';
import PropTypes, { func, shape } from 'prop-types';
import { Heading, Content } from './components';
import { MENU_TYPES } from './components/const';

const UserManagement = ({
  isMobile,
  intl: { formatMessage },
  actions: {
    getUsersByRole,
    addUserByAdmin,
    editUserByAdmin,
    getDeliveryCountries,
    userSuspension,
    userResendLink,
    editUser,
    getPartnerRequests,
    managingPartnerApplication,
    getUsers,
    getUsersForApplication,
    getUserDetails,
    userByAdminResendLink,
  },
  usersByRole,
  users,
  partnerRequests,
  translation,
  deliveryCountries,
  usersForApplication,
  userDetails,
}) => {
  const [menuType, setMenuType] = useState(MENU_TYPES(formatMessage).CLIENTS);
  useEffect(() => {
    getDeliveryCountries();
  }, []);
  useEffect(() => {
    menuType === MENU_TYPES(formatMessage).CLIENTS && getUsersByRole('client');
    menuType === MENU_TYPES(formatMessage).PARTNERS && getUsersByRole('partner');
    menuType === MENU_TYPES(formatMessage).COORDINATORS && getUsersByRole('coordinator');
    menuType === MENU_TYPES(formatMessage).CONCLUSIONS && (
      <>
        {getUsersByRole('client')}
        {getUsersForApplication()}
      </>
    );
    menuType === MENU_TYPES(formatMessage).STOREMANS && getUsersByRole('storeman');
  }, [menuType]);

  return (
    <div className="user-management__wrapper">
      <Heading
        addUserByAdmin={addUserByAdmin}
        deliveryCountries={deliveryCountries}
        translation={translation}
        menuType={menuType}
        setMenuType={setMenuType}
        isMobile={isMobile}
        formatMessage={formatMessage}
      />
      <div className="user-management__content">
        <Content
          userByAdminResendLink={userByAdminResendLink}
          getUserDetails={getUserDetails}
          userDetails={userDetails}
          usersForApplication={usersForApplication}
          editUserByAdmin={editUserByAdmin}
          getUsers={getUsers}
          managingPartnerApplication={managingPartnerApplication}
          users={users}
          partnerRequests={partnerRequests}
          getPartnerRequests={getPartnerRequests}
          editUser={editUser}
          userResendLink={userResendLink}
          userSuspension={userSuspension}
          usersByRole={usersByRole}
          isMobile={isMobile}
          formatMessage={formatMessage}
          menuType={menuType}
          deliveryCountries={deliveryCountries}
        />
      </div>
    </div>
  );
};

export default UserManagement;

UserManagement.propTypes = {
  actions: shape({
    addUserByAdmin: func.isRequired,
    getUsersByRole: func.isRequired,
    getUserDetails: func.isRequired,
    getUsersForApplication: func.isRequired,
    userByAdminResendLink: func.isRequired,
    userResendLink: func.isRequired,
    userSuspension: func.isRequired,
    managingPartnerApplication: func.isRequired,
    getDeliveryCountries: func.isRequired,
    editUserByAdmin: func.isRequired,
  }).isRequired,
  deliveryCountries: PropTypes.array.isRequired,
  formatMessage: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  partnerRequests: PropTypes.object.isRequired,
  translation: PropTypes.string.isRequired,
  userDetails: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  usersByRole: PropTypes.object.isRequired,
  usersForApplication: PropTypes.array.isRequired,
};

import { ROOM } from 'store/types';
import { REQUEST_STATUS } from 'shared/consts';

const INITIAL_STATE = {
  roomMessages: {
    data: [],
  },
  rooms: {
    data: null,
  },
};

const roomReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case ROOM.SEND_MESSAGE_SUCCESS: {
      return {
        ...state,
        roomMessages: {
          data: [...state.roomMessages.data, payload.data],
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case ROOM.GET_ROOM_MESSAGES_INIT: {
      return {
        ...state,
        roomMessages: {
          data: [],
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case ROOM.GET_ROOM_MESSAGES_SUCCESS: {
      let messages = [];
      if (payload.data.length === 0 || !state.roomMessages.data) {
        messages = [...payload.data];
      } else {
        const length = payload.data.length - 1;
        const lastMessageId = payload.data[length].id || null;
        const firstMessageId = payload.data[0].id || null;
        const hasLastMessageInArray = lastMessageId &&state.roomMessages.data&& state.roomMessages.data.find(item => item.id === lastMessageId);
        const hasFirstMessageInArray = firstMessageId && state.roomMessages.data && state.roomMessages.data.find(item => item.id === firstMessageId);
        // console.log(lastMessageId)
        if (state.roomMessages?.data?.length === 0) {
          messages = [...payload.data];
        } else if (hasLastMessageInArray && payload.data.length === 20) {
          messages = [...state.roomMessages.data];
        } else if (!hasLastMessageInArray || payload.data.length < 20) {
          // console.log('first message')
          if (hasFirstMessageInArray) {
            const arrayOfNewItems = []
            payload.data.forEach(item => {
              // console.log(state.roomMessages.data.find(element => element.id === item.id))
              const isInArray = state.roomMessages.data.find(element => element.id === item.id);
              if (!isInArray) {
                // messages = [...state.roomMessages.data, item];
                arrayOfNewItems.push(item)
              }
              messages = [...state.roomMessages.data, ...arrayOfNewItems]
            });
          } else {
            messages = [...payload.data, ...state.roomMessages.data];
          }
        }
      }

      return {
        ...state,
        roomMessages: {
          data: messages,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case ROOM.GET_ROOM_MESSAGES_FAILURE: {
      return {
        ...state,
        roomMessages: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case ROOM.GET_ROOM_INIT: {
      return {
        ...state,
        rooms: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case ROOM.GET_ROOM_SUCCESS: {
      return {
        ...state,
        rooms: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case ROOM.GET_ROOM_FAILURE: {
      return {
        ...state,
        rooms: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case ROOM.UPDATE_CHAT_STATUS_SUCCESS: {
      return {
        ...state,
        rooms: {
          data: {
            ...state.rooms.data,
            rooms: [
              ...state.rooms.data.rooms.map(item =>
                item.uuid === payload.data.room_uuid ? { ...item, is_closed: payload.data.is_closed === false ? 0 : 1 } : item,
              ),
            ],
          },
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case ROOM.UPDATE_CHAT_STATUS_FAILURE: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export default roomReducer;

/* eslint-disable react/jsx-indent */
import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SVG } from 'shared/components';

const Column = ({ header, links, icon, mobile }) => (
  <section className={`main-footer__column ${mobile ? 'main-footer__column--icons' : ''}`}>
    {!icon && header && <h4 className="main-footer--header">{header}</h4>}
    <nav className={`${icon ? 'main-footer__social-icons' : 'main-footer--links'}`}>
      {icon
        ? links.map(({ route, icon_type, id }) => (
            <Link key={id} className="main-footer__social-icon" to={route}>
              <SVG type={icon_type} />
            </Link>
          ))
        : links.map(({ label, route, yellow, id }) => (
            <NavLink key={id} className={`main-footer__link ${yellow && 'main-footer__link--yellow'}`} to={route}>
              {label}
            </NavLink>
          ))}
    </nav>
  </section>
);

export default Column;

Column.defaultProps = {
  header: null,
  icon: false,
  mobile: false,
};

Column.propTypes = {
  header: PropTypes.string,
  icon: PropTypes.bool,
  links: PropTypes.array.isRequired,
  mobile: PropTypes.bool,
};
``;

import * as Connector from 'shared/connectors/paymentsConnector';
import { PRICE } from 'store/types';

export const init = () => ({
  type: PRICE.GET_INVOICE_DATA_INIT,
});

export const success = (data) => ({
    type: PRICE.GET_INVOICE_DATA_SUCCESS,
    payload: {
        data,
      },
});

export const failure = () => ({
  type: PRICE.GET_INVOICE_DATA_FAILURE,
});

const getInvoiceData = country_uuid => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getInvoiceData(country_uuid);
    dispatch(success({data}));
    return data;
  } catch (err) {
      dispatch(failure())
    return err.response;
  }
};

export default getInvoiceData;

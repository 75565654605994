import * as Connector from 'shared/connectors/allegroConnector';
import { ALLEGRO } from 'store/types';

const init = () => ({
    type: ALLEGRO.GET_URL_INIT,
  });

export const success = data => ({
  type: ALLEGRO.GET_URL_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: ALLEGRO.GET_URL_FAILURE,
});

const getUrl = () => async dispatch => {
    dispatch(init())
  try {
    const { data } = await Connector.getUrl();
    window.location.href = data.url;
    dispatch(success(data));
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default getUrl;

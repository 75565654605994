import { func } from 'prop-types';
import React from 'react';
import { SingleFilter } from 'screens/products-lists/components';

const ProductsFilters = ({ formatMessage }) => (
  <div className="products-filters">
    <SingleFilter label={formatMessage({ id: 'global.in_stock' })} />
    <SingleFilter label={formatMessage({ id: 'global.pickup_at_the_point' })} />
    <SingleFilter partner label={formatMessage({ id: 'global.partner' })} />
  </div>
);

export default ProductsFilters;

ProductsFilters.propTypes = {
  formatMessage: func.isRequired,
};

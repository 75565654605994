import { connect } from 'react-redux';
import { isMobile as checkIsMobile } from 'shared/utils';
import { injectIntl } from 'react-intl';
import { getCheckout, getCountCheckoutStatus } from 'store/actions/checkout';
import { createCheckoutSession } from 'store/actions/payments';
import {
  getCheckout as getCheckoutSelector,
  getCountCheckoutStatus as getCountCheckoutStatusSelector,
} from 'store/selectors/checkoutSelector';
import Orders from './Orders.main';

const mapDispatchToProps = {
  getCheckout: values => getCheckout(values),
  getCountCheckoutStatus: () => getCountCheckoutStatus(),
  createCheckoutSession: uuid => createCheckoutSession(uuid),
};

const mapStateToProps = state => ({
  isMobile: checkIsMobile(),
  checkout: getCheckoutSelector(state),
  countCheckoutStatus: getCountCheckoutStatusSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Orders));

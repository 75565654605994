import React from 'react';
import * as Yup from 'yup';
import { func, bool } from 'prop-types';
import { Modal, ModalActions, RegularField } from 'shared/components';
import { MODAL_TYPE, VALIDATION_FIELD } from 'shared/consts';
import { Field, Formik } from 'formik';

const EditPartner = ({ openModal, formatMessage, onClose }) => {
  const { first_name, last_name, email } = openModal.data;

  const onSubmit = () => {
    onClose();
  };

  console.log(openModal.data);

  const validation = () =>
    Yup.object().shape({
      client_uuid: Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED),
    });

  return (
    <div>
      {openModal.isOpened && (
        <Modal className={MODAL_TYPE.BASIC} onClose={onClose} opened={openModal.isOpened}>
          <div>
            <div className="modal__heading modal__heading--center">{formatMessage({ id: 'global.edit_partner' })}</div>
            <Formik
              validationSchema={validation()}
              initialValues={{
                first_name: first_name || '',
                last_name: last_name || '',
                email,
              }}
              onSubmit={onSubmit}
            >
              {({ handleSubmit, errors }) => (
                <form className="categoryManagement__addCategory__wrapper" onSubmit={handleSubmit}>
                  <div className="categoryManagement__addCategory__content">
                    <Field
                      type="text"
                      label={formatMessage({ id: 'global.name' })}
                      name="first_name"
                      component={RegularField}
                      errors={errors}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: 'global.last_name' })}
                      name="last_name"
                      component={RegularField}
                      errors={errors}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: 'global.email' })}
                      name="email"
                      component={RegularField}
                      errors={errors}
                    />
                    {/* {!subcategory && <Button label="Dodaj zdjęcie +" className="btn--gray categoryManagement__header--button" />} */}
                    <ModalActions
                      formatMessage={formatMessage}
                      type={MODAL_TYPE.EDIT_CATEGORY}
                      onClose={onClose}
                      onSubmit={handleSubmit}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
    </div>
  );
};
export default EditPartner;

EditPartner.propTypes = {
  editUser: func.isRequired,
  formatMessage: func.isRequired,
  onClose: func.isRequired,
  openModal: bool.isRequired,
};

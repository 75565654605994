import React from 'react';
import * as Connector from 'shared/connectors/authConnector';
import { AUTH } from 'store/types';
import { getUsersByRole } from 'store/actions/user';
import { NOTIFY_TYPE } from 'shared/consts';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';


export const success = (data) => ({
  type: AUTH.EDIT_USER_SUCCESS,
  payload: {
    data
}
});

const editSelf = (uuid, form, group) => async dispatch => {
  try {
    const data = await Connector.editSelf(uuid, form);
    toast(<Notify label="global.user_editted" type={NOTIFY_TYPE.CHECKED} />);
   dispatch(success(data))
    dispatch(getUsersByRole(group));
    return data;
  } catch (err) {
    return err.response.status;
  }
};

export default editSelf;

import { connect } from 'react-redux';
import { isMobile as checkIsMobile } from 'shared/utils';
import { injectIntl } from 'react-intl';
import { createRoom, getRooms, sendMessage, getRoomMessages,updateRoomStatus } from 'store/actions/room';
import { getRooms as getRoomsSelector, getRoomMessages as getRoomMessagesSelector } from 'store/selectors/roomSelector';
import { getAuthorizedUser } from 'store/selectors/userSelector';
import { bindActionCreators } from 'redux';
import HelpChat from './Help-chat.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createRoom: user => createRoom(user),
      getRooms: uuid => getRooms(uuid),
      sendMessage: data => sendMessage(data),
      getRoomMessages: (data,noInit) => getRoomMessages(data,noInit),
      updateRoomStatus: data=> updateRoomStatus(data)
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  isMobile: checkIsMobile(),
  rooms: getRoomsSelector(state),
  roomMessages: getRoomMessagesSelector(state),
  authorizedUser: getAuthorizedUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(HelpChat));

import React from 'react';
import { SingleTile } from 'shared/components';
import { func } from 'prop-types';

const TwoTiles = ({ formatMessage }) => (
  <section className="two-tiles">
    <SingleTile
      header={formatMessage({ id: 'global.biggest_magazine' })}
      descript={formatMessage({ id: 'global.here_buy_and_sel' })}
      color="light-orange"
      button="arrow-left"
      double
      engine
    />
    <SingleTile
      header={formatMessage({ id: 'global.guaranteed_buy_return' })}
      descript={formatMessage({ id: 'global.guaranteed_30' })}
      secondDescript={formatMessage({ id: 'global.full_refund' })}
      color="orange"
      shield
    />
  </section>
);

export default TwoTiles;

TwoTiles.propTypes = {
  formatMessage: func.isRequired,
};

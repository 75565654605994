/* eslint react/prop-types: 0 */

import React from 'react';

const ProductTitle = ({ data }) => (
  <div>
    <div className="productTitle">{data?.title.toUpperCase()}</div>
  </div>
);

export default ProductTitle;

import React, { useState, useEffect } from 'react';
import { shape, func, bool, array, object } from 'prop-types';
import { ListsProductsItem } from 'screens/products-lists/components';
import { AdvertisementsMenu, AdvertisementsFilters, EmptyView } from './components';
import { MENU_TYPES, ADVERTISEMENT_TYPES } from './components/const';

const Advertisements = ({
  isMobile,
  // getMyAdvertisements,
  myAdvertisements,
  deleteAdvertisement,
  cancelAdvertisement,
  getAdvertisementsCount,
  advertisementsCount,
  getMyAdvertisementsFiltered,
  getCategoryAndSubcategory,
  resumeMyProduct,
  recallMyProduct,
  authorizedUser,
  intl: { formatMessage },
}) => {
  const [menuType, setMenuType] = useState(MENU_TYPES(formatMessage).ALL);
  const [advertisementTypes, setAdvertisementTypes] = useState(ADVERTISEMENT_TYPES.ALL);
  const [filterData, setFilterData] = useState({});
  const [currentlyOpenSettingsUuid, setCurrentlyOpenSettingsUuid ] =useState(null)
  useEffect(async () => {
    await getMyAdvertisementsFiltered(advertisementTypes, filterData);
  }, [filterData, advertisementTypes]);

  useEffect(async () => {
    await getAdvertisementsCount();
  }, []);
  const updateData = () => {
     getMyAdvertisementsFiltered(advertisementTypes, filterData);
     getAdvertisementsCount(true);
  }

  const translation = localStorage.getItem('translation');
  return (
    <div className="advertisements__wrapper">
      <div className="advertisements__top">
        <p className="advertisements--heading">{formatMessage({ id: 'global.my_addvertisement' })}</p>
        <AdvertisementsMenu
          formatMessage={formatMessage}
          menuType={menuType}
          setMenuType={setMenuType}
          setAdvertisementTypes={setAdvertisementTypes}
          isMobile={isMobile}
          count={advertisementsCount}
        />
      </div>
      <div className="advertisements__content">
        {!!advertisementsCount.products_count?.all && advertisementsCount.products_count?.all > 0 && (
          <AdvertisementsFilters
            getCategoryAndSubcategory={getCategoryAndSubcategory}
            setFilterData={setFilterData}
            filterData={filterData}
            isMobile={isMobile}
            formatMessage={formatMessage}
            authorizedUser={authorizedUser}
          />
        )}
        {myAdvertisements &&
          myAdvertisements.length > 0 &&
          myAdvertisements.map(advertisement => (
            <div>
              <ListsProductsItem
                translation={translation}
                formatMessage={formatMessage}
                data={advertisement}
                isMobile={isMobile}
                settings
                deleteAdvertisements={deleteAdvertisement}
                cancelAdvertisement={cancelAdvertisement}
                className="watched-products--item advertisements__filters--product"
                resumeMyProduct={resumeMyProduct}
                advertisement
                currentlyOpenSettingsUuid={currentlyOpenSettingsUuid}
                setCurrentlyOpenSettingsUuid={setCurrentlyOpenSettingsUuid}
                recallMyProduct={recallMyProduct}
                updateData={updateData}
              />
            </div>
          ))}
        {(!myAdvertisements || myAdvertisements.length === 0) && (
          <EmptyView formatMessage={formatMessage} />
        )}
      </div>
    </div>
  );
};

export default Advertisements;

Advertisements.propTypes = {
  advertisementsCount: object.isRequired,
  authorizedUser: object.isRequired,
  cancelAdvertisement: func.isRequired,
  deleteAdvertisement: func.isRequired,
  getAdvertisementsCount: func.isRequired,
  getCategoryAndSubcategory: func.isRequired,
  getMyAdvertisements: func.isRequired,
  getMyAdvertisementsFiltered: func.isRequired,
  intl: shape({
    formatMessage: func.isRequired,
  }).isRequired,
  isMobile: bool.isRequired,
  myAdvertisements: array.isRequired,
  recallMyProduct: func.isRequired,
  resumeMyProduct: func.isRequired,
};

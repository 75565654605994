import { useEffect } from 'react';

const languageSwitch = (getCategoryByLink, categoryName) => {
  const isLanguageSwitch = localStorage.getItem('languageSwitch');
  useEffect(async () => {
    if (isLanguageSwitch) {
      await getCategoryByLink(categoryName);
      localStorage.removeItem('languageSwitch');
    }
  }, [isLanguageSwitch]);
};
export default languageSwitch;

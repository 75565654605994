import { CAR } from 'store/types';
import { REQUEST_STATUS } from 'shared/consts';

const INITIAL_STATE = {
  carBrands: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  carModels: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
};

const carReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case CAR.GET_CAR_BRANDS_INIT: {
      return {
        ...state,
        carBrands: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case CAR.GET_CAR_BRANDS_SUCCESS: {
      return {
        ...state,
        carBrands: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case CAR.GET_CAR_BRANDS_FAILURE: {
      return {
        ...state,
        carBrands: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case CAR.GET_CAR_MODELS_INIT: {
      return {
        ...state,
        carModels: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case CAR.GET_CAR_MODELS_SUCCESS: {
      return {
        ...state,
        carModels: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case CAR.GET_CAR_MODELS_FAILURE: {
      return {
        ...state,
        carModels: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    default:
      return state;
  }
};

export default carReducer;

import React, { useEffect, useState } from 'react';
import { SingleCategoryBar } from 'shared/components';
import { object, string } from 'prop-types';

const PopularCategories = ({ popularCategories, translation, country }) => {
  const [filteredCategories, setFilteredCategories] = useState(
    popularCategories.subcategories_list &&
      popularCategories.subcategories_list?.filter(
        ({ products_count_pl, products_count_de }) => (country === 'pl' ? products_count_pl : products_count_de) > 0,
      ),
  );

  useEffect(() => {
    popularCategories.subcategories_list &&
      setFilteredCategories(
        popularCategories.subcategories_list.filter(
          ({ products_count_pl, products_count_de }) => (country === 'pl' ? products_count_pl : products_count_de) > 0,
        ),
      );
  }, [country,popularCategories.subcategories_list]);
  return (
    <section className="popular-categories">
      <h2 className="popular-categories--name">Popularne kategorie</h2>
      <div className="popular-categories__list">
        {filteredCategories?.map(data => (
          <SingleCategoryBar
            key={data.name}
            label={translation === 'pl' ? data.name : data.name_ger}
            quantity={country === 'pl' ? data.products_count_pl : data.products_count_de}
            data={data}
          />
        ))}
      </div>
    </section>
  );
};

export default PopularCategories;

PopularCategories.propTypes = {
  authorizedUser: object.isRequired,
  country: string.isRequired,
  popularCategories: object.isRequired,
  translation: string.isRequired,
};

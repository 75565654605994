import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SearchEngine } from 'shared/components';
import { ROUTES } from 'shared/consts';
import { injectIntl } from 'react-intl';
import { MenuMobile, MenuDesktop, MenuLogo } from './components';

const NavigationHeader = ({
  isMobile,
  pathname,
  mainCategory,
  authorizedUser,
  logout,
  setAppTranslation,
  translation,
  intl,
  shoppingCart,
  modifyShoppingCart,
  createCheckoutSession,
  cancelCheckout,
  deliveryCountries,
}) => {
  const { formatMessage } = intl;
  const isSearchEngineVisible =
    pathname === ROUTES.HOME_PAGE ||
    pathname === ROUTES.CATEGORY ||
    pathname === ROUTES.SINGLE_PRODUCT;
  
  const deliveryCountryUuid = authorizedUser ? authorizedUser.country.label : localStorage.getItem('deliveryCountry');
  const countryLabel =
    !!deliveryCountries.length &&
    !!deliveryCountryUuid &&
    deliveryCountries?.find(item => item.value === deliveryCountryUuid)?.label;
  const countryShort = () => {
    if (countryLabel === 'Poland') {
      return 'pl';
    }
    if (countryLabel === 'Germany') {
      return 'de';
    }
    return translation;
  };
  const [country, setCountry] = useState(countryShort());
  useEffect(() => {
    // eslint-disable-next-line no-nested-ternary
    setCountry(countryShort());
  }, [localStorage.getItem('deliveryCountry'), authorizedUser, translation]);
  return (
    <>
      <div className="menu__wrapper">
        <MenuLogo />
        {/* {localStorage.removeItem('notLoggedUserBasketUuid')} */}
        {!isMobile ? (
          <MenuDesktop
            modifyShoppingCart={modifyShoppingCart}
            shoppingCart={shoppingCart}
            formatMessage={formatMessage}
            translation={translation}
            setAppTranslation={setAppTranslation}
            logout={logout}
            authorizedUser={authorizedUser}
            mainCategory={mainCategory}
            createCheckoutSession={createCheckoutSession}
            cancelCheckout={cancelCheckout}
            country={country}
          />
        ) : (
          <MenuMobile
            logout={logout}
            formatMessage={formatMessage}
            translation={translation}
            setAppTranslation={setAppTranslation}
            authorizedUser={authorizedUser}
            mainCategory={mainCategory}
            modifyShoppingCart={modifyShoppingCart}
            shoppingCart={shoppingCart}
            createCheckoutSession={createCheckoutSession}
            cancelCheckout={cancelCheckout}
            country={country}
          />
        )}
      </div>
      {isMobile && isSearchEngineVisible && <SearchEngine placeholder="Wyszukaj części" />}
    </>
  );
};

export default injectIntl(NavigationHeader);

NavigationHeader.defaultProps = {
  authorizedUser: undefined,
};
NavigationHeader.propTypes = {
  authorizedUser: PropTypes.object,
  cancelCheckout: PropTypes.func.isRequired,
  createCheckoutSession: PropTypes.func.isRequired,
  deliveryCountries: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  mainCategory: PropTypes.array.isRequired,
  modifyShoppingCart: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  setAppTranslation: PropTypes.func.isRequired,
  shoppingCart: PropTypes.object.isRequired,
  translation: PropTypes.string.isRequired,
};

const translationToCountryLabel = translation => {
  if (translation === 'pl') {
    return 'Poland';
  }
  if (translation === 'de') {
    return 'Germany';
  }
    return null
};

export default translationToCountryLabel
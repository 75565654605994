/* eslint-disable react/prop-types */
// import { Settings } from 'screens/user-management/components';
import React, { useState } from 'react';
import { Table, Badge } from 'shared/components';
import { BADGE_TYPES, USER_ROLES } from 'shared/consts';
import { arrayOf, shape, func, string } from 'prop-types';
import { deliveryCountryLabelShort } from 'shared/utils';
import { CoordinatorSettings } from './component';
import EditUserModal from '../edit-user-modal';

const Coorditnators = ({
  data,
  isMobile,
  editUserByAdmin,
  deliveryCountries,
  formatMessage,
  userSuspension,
  userResendLink,
  type,
}) => {
  const [settingsType, setSettingsType] = useState({ active: '', id: '' });
  const [openModal, setOpenModal] = useState({ isOpened: false });

  const getColumns = () => [
    {
      width: isMobile ? '45' : '162',
      Cell: () => (
        <>
          {type === USER_ROLES.COORDINATOR && (
            <Badge type={BADGE_TYPES.COORDINATOR} label={formatMessage({ id: 'global.coordinator' })} />
          )}{' '}
          {type === USER_ROLES.STOREMAN && <Badge type={BADGE_TYPES.STOREMAN} label={formatMessage({ id: 'global.storeman' })} />}
        </>
      ),
    },
    {
      id: 'first_name',
      accessor: 'first_name',
      width: !isMobile ? '176' : 'auto',
    },
    {
      id: 'last_name',
      accessor: 'last_name',
      width: !isMobile ? '170' : 'auto',
    },
    {
      id: 'email',
      accessor: 'email',
      width: '190',
      className: 'user-management__table__content--desktop',
    },
    {
      id: 'country_of_delivery_label.value',
      accessor: 'country_of_delivery_label.value',
      width: '50',
      className: 'user-management__table__content--lang',
      Cell: ({
        original: {
          country_of_delivery_label: { value },
        },
      }) => deliveryCountryLabelShort(value),
    },
    {
      width: !isMobile ? '140' : 'auto',
      Cell: ({ original: { is_activate, is_suspended } }) =>
        !isMobile ? (
          <>
            {is_suspended ? (
              <Badge type={BADGE_TYPES.SUSPENDED} label={formatMessage({ id: 'global.suspended' })} />
            ) : (
              <> {!is_activate && <Badge type={BADGE_TYPES.UNACTIVE} label={formatMessage({ id: 'global.unactive' })} />} </>
            )}
          </>
        ) : (
          <p className={`user-management__table__content--state user-management__table__content--state--${is_activate} `}>
            {/* zobacz */}
          </p>
        ),
    },
    {
      width: '100',
      Cell: ({
        original: { is_activate, is_suspended, email, uuid, first_name, last_name, country_of_delivery_label, role },
        index,
      }) => (
        <CoordinatorSettings
          countryDelivery={country_of_delivery_label}
          role={role}
          first_name={first_name}
          last_name={last_name}
          formatMessage={formatMessage}
          openModal={openModal}
          setOpenModal={setOpenModal}
          userResendLink={userResendLink}
          uuid={uuid}
          id={index}
          settingsType={settingsType}
          setSettingsType={setSettingsType}
          active={is_activate}
          suspended={is_suspended}
          email={email}
          userSuspension={userSuspension}
          type={type}
        />
      ),
      // className: 'user-management__table__content--marginAuto',
    },
  ];
  return (
    <>
      <div className="user-management__table--wrapper">
        <div className="user-management__table__heading">
          <p className="user-management__table__heading--name">{formatMessage({ id: 'inputs.name' })}</p>
          <p className="user-management__table__heading--surname">{formatMessage({ id: 'inputs.last_name' })}</p>
          <p className="user-management__table__heading--email">{formatMessage({ id: 'inputs.email' })}</p>
          <p className="user-management__table__heading--lang">{formatMessage({ id: 'global.country' })}</p>
        </div>
        <div className="user-management__table__content">{data && <Table data={data} columns={getColumns()} />}</div>
      </div>
      <EditUserModal
        deliveryCountries={deliveryCountries}
        type={type}
        editUser={editUserByAdmin}
        formatMessage={formatMessage}
        openModal={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};

export default Coorditnators;

Coorditnators.propTypes = {
  data: arrayOf(shape({})).isRequired,
  deliveryCountries: func.isRequired,
  editUserByAdmin: func.isRequired,
  formatMessage: func.isRequired,
  type: string.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { MENU_TYPES } from '../const';

const items = formatMessage => [
  {
    type: MENU_TYPES(formatMessage).CLIENTS,
  },
  {
    type: MENU_TYPES(formatMessage).PARTNERS,
  },
  {
    type: MENU_TYPES(formatMessage).COORDINATORS,
  },
  {
    type: MENU_TYPES(formatMessage).STOREMANS,
  },
  {
    type: MENU_TYPES(formatMessage).CONCLUSIONS,
  },
];

const isSmallViewPort = window.innerWidth < 480;
const settings = {
  dots: false,
  arrows: false,
  slidesToShow: isSmallViewPort ? 2 : 3,
};

const UsersMenu = ({ menuType, setMenuType, isMobile, formatMessage }) => (
  <>
    {!isMobile ? (
      <div className="user-management__menu">
        {items(formatMessage).map(({ type }) => (
          <div
            className={`user-management__menu--item ${menuType === type ? 'user-management__menu--active' : ''}`}
            onClick={() => setMenuType(type)}
          >
            <div className="label">{type}</div>
          </div>
        ))}
      </div>
    ) : (
      <div className="user-management__menu">
        <Slider {...settings}>
          {items(formatMessage).map(({ type }) => (
            <div
              className={`user-management__menu--item ${menuType === type ? 'user-management__menu--active' : ''}`}
              onClick={() => setMenuType(type)}
            >
              <div className="label">{type}</div>
            </div>
          ))}
        </Slider>
      </div>
    )}
  </>
);

export default UsersMenu;

UsersMenu.propTypes = {
  formatMessage: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  menuType: PropTypes.bool.isRequired,
  setMenuType: PropTypes.func.isRequired,
};

import React, { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { RegularField, SelectField } from 'shared/components';
import { VALIDATION_FIELD } from 'shared/consts';
import { bool, func, object } from 'prop-types';
import { phonePrefixOptions } from 'shared/utils';

const DeliveryAddressForm = ({
  formatMessage,
  isSubmitting,
  setIsSubmitting,
  authorizedUser,
  setTransactionData,
  deliveryCountries,
  isMobile,
}) => {
  const [shippingAddress, setShippingAddress] = useState(null);
  const addressShipping = localStorage.getItem('addressShipping');
  const countryUuid = localStorage.getItem('deliveryCountry');
  const getCountryUuid = country => deliveryCountries.length && deliveryCountries.find(item => item.label === country);
  const handleOnSubmit = async values => {
    localStorage.setItem('addressShipping', JSON.stringify(values));
    const address = `${values.street} ${values.house_number}${values.apartment_number ? `/${values.apartment_number}` : ''}`;
    delete values.street;
    delete values.house_number;
    delete values.apartment_number;
    values.address = address;
    await setTransactionData(values);
  };

  useEffect(() => {
    addressShipping && setShippingAddress(JSON.parse(addressShipping));
  }, [addressShipping]);

  const validation = () =>
    Yup.object().shape({
      city: Yup.string(VALIDATION_FIELD(formatMessage).REQUIRED)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .min(2, VALIDATION_FIELD(formatMessage).MIN_2)
        .max(50, VALIDATION_FIELD(formatMessage).MAX_50),
      email: Yup.string().email(VALIDATION_FIELD(formatMessage).EMAIL).required(VALIDATION_FIELD(formatMessage).REQUIRED),
      first_name: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .min(2, VALIDATION_FIELD(formatMessage).MIN_2)
        .max(20, VALIDATION_FIELD(formatMessage).MAX_20),
      house_number: Yup.string(VALIDATION_FIELD(formatMessage).REQUIRED)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .max(30, VALIDATION_FIELD(formatMessage).MAX_30),
      last_name: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .min(2, VALIDATION_FIELD(formatMessage).MIN_2)
        .max(50, VALIDATION_FIELD(formatMessage).MAX_50),
      phone_number: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .min(9, VALIDATION_FIELD(formatMessage).DIGITS_9)
        .max(9, VALIDATION_FIELD(formatMessage).DIGITS_9),
      // .nullable(),
      phone_prefix: Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED),
      post_code: Yup.string(VALIDATION_FIELD(formatMessage).REQUIRED)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .test(
          'post-code',
          authorizedUser?.country_id === 2 || countryUuid === getCountryUuid('Germany').value
            ? VALIDATION_FIELD(formatMessage).POST_CODE_DE
            : VALIDATION_FIELD(formatMessage).POST_CODE,
          val =>
            (val && authorizedUser?.country_id === 2) || countryUuid === getCountryUuid('Germany').value
              ? !!/^[0-9]{5}$/i.test(val)
              : /^[0-9]{2}-[0-9]{3}$/i.test(val),
        )
        .test('germany-postcode', VALIDATION_FIELD(formatMessage).BAD_VALUE, val =>
          val && (authorizedUser?.country_id === 2 || countryUuid === getCountryUuid('Germany').value)
            ? !!/\b((?:0[1-46-9]\d{3})|(?:[1-357-9]\d{4})|(?:[4][0-24-9]\d{3})|(?:[6][013-9]\d{3}))\b/.test(val)
            : true,
        ),
      street: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .min(2, VALIDATION_FIELD(formatMessage).MIN_2)
        .max(50, VALIDATION_FIELD(formatMessage).MAX_50),
    });

  // console.log(getCountryUuid('Poland'));
  // console.log(getCountryUuid('Germany'));
  // console.log(countryUuid, getCountryUuid('Germany'));

  return (
    <div>
      <Formik
        validationSchema={validation()}
        initialValues={{
          // city: 'Ełk',
          // email: 'email@o2.pl',
          // first_name: 'imie',
          // house_number: '9',
          // last_name: 'nazwisko',
          // phone_number: '123123123',
          // phone_prefix: '+48',
          // post_code: '19-300',
          // street: 'ulica',
          // apartment_number: '',
          // country_of_delivery_uuid: authorizedUser?.country.label || localStorage.getItem('deliveryCountry'),
          city: shippingAddress ? shippingAddress.city : '',
          email: shippingAddress ? shippingAddress.email : '',
          first_name: shippingAddress ? shippingAddress.first_name : '',
          house_number: shippingAddress ? shippingAddress.house_number : '',
          last_name: shippingAddress ? shippingAddress.last_name : '',
          phone_number: shippingAddress ? shippingAddress.phone_number : '',
          phone_prefix: shippingAddress ? shippingAddress.phone_prefix : '+48',
          post_code: shippingAddress ? shippingAddress.post_code : '',
          street: shippingAddress ? shippingAddress.street : '',
          apartment_number: shippingAddress ? shippingAddress.apartment_number : '',
          country_of_delivery_uuid: authorizedUser?.country.label || localStorage.getItem('deliveryCountry'),
        }}
        onSubmit={handleOnSubmit}
        enableReinitialize
      >
        {({ handleSubmit, errors }) => {
          useEffect(() => {
            if (isSubmitting) {
              handleSubmit();
              setIsSubmitting(false);
            }
          }, [isSubmitting]);
          return (
            <form className="address-form" onSubmit={handleSubmit}>
              <div className="address-form--left">
                <Field
                  type="text"
                  label={formatMessage({ id: 'inputs.name' })}
                  name="first_name"
                  component={RegularField}
                  errors={errors}
                />
                {isMobile ? (
                  <Field
                    type="text"
                    label={formatMessage({ id: 'inputs.last_name' })}
                    name="last_name"
                    component={RegularField}
                    errors={errors}
                  />
                ) : (
                  <Field
                    type="text"
                    label={formatMessage({ id: 'inputs.email' })}
                    name="email"
                    component={RegularField}
                    errors={errors}
                  />
                )}
                {isMobile ? (
                  <Field
                    type="text"
                    label={formatMessage({ id: 'inputs.email' })}
                    name="email"
                    component={RegularField}
                    errors={errors}
                  />
                ) : (
                  <Field
                    type="text"
                    label={formatMessage({ id: 'inputs.street' })}
                    name="street"
                    component={RegularField}
                    errors={errors}
                  />
                )}
                {isMobile ? (
                  <div className="one-line">
                    <Field
                      name="phone_prefix"
                      className="advertisements__filters--sort"
                      placeholder={formatMessage({ id: 'inputs.choose' })}
                      label={`${formatMessage({ id: 'global.telephone' })}`}
                      options={phonePrefixOptions()}
                      component={SelectField}
                      errors={errors}
                      isCustomLabel
                    />
                    <Field type="text" name="phone_number" component={RegularField} errors={errors} />
                  </div>
                ) : (
                  <div className="one-line">
                    <Field
                      type="text"
                      label={formatMessage({ id: 'inputs.build_number' })}
                      name="house_number"
                      component={RegularField}
                      errors={errors}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: 'inputs.apartement_number' })}
                      name="apartment_number"
                      component={RegularField}
                      errors={errors}
                    />
                  </div>
                )}
              </div>

              <div className="address-form--right">
                {isMobile ? (
                  <Field
                    type="text"
                    label={formatMessage({ id: 'inputs.street' })}
                    name="street"
                    component={RegularField}
                    errors={errors}
                  />
                ) : (
                  <Field
                    type="text"
                    label={formatMessage({ id: 'inputs.last_name' })}
                    name="last_name"
                    component={RegularField}
                    errors={errors}
                  />
                )}
                {isMobile ? (
                  <div className="one-line">
                    <Field
                      type="text"
                      label={formatMessage({ id: 'inputs.build_number' })}
                      name="house_number"
                      component={RegularField}
                      errors={errors}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: 'inputs.apartement_number' })}
                      name="apartment_number"
                      component={RegularField}
                      errors={errors}
                    />
                  </div>
                ) : (
                  <div className="one-line">
                    <Field
                      name="phone_prefix"
                      className="advertisements__filters--sort"
                      placeholder={formatMessage({ id: 'inputs.choose' })}
                      label={`${formatMessage({ id: 'global.telephone' })}`}
                      options={phonePrefixOptions()}
                      component={SelectField}
                      errors={errors}
                      isCustomLabel
                    />
                    <Field type="text" name="phone_number" component={RegularField} errors={errors} />
                  </div>
                )}
                <div className="one-line">
                  <Field
                    type="text"
                    label={formatMessage({ id: 'inputs.post_code' })}
                    name="post_code"
                    component={RegularField}
                    errors={errors}
                  />
                  <Field
                    type="text"
                    label={formatMessage({ id: 'inputs.city' })}
                    name="city"
                    component={RegularField}
                    errors={errors}
                  />
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default DeliveryAddressForm;

DeliveryAddressForm.propTypes = {
  authorizedUser: object.isRequired,
  basket: object.isRequired,
  deliveryCountries: object.isRequired,
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
  isSubmitting: bool.isRequired,
  setIsSubmitting: func.isRequired,
  setTransactionData: func.isRequired,
  simulateCheckout: func.isRequired,
};

import React from 'react';
import * as Connector from 'shared/connectors/partnerConnector';
import { PARTNER } from 'store/types';
import { getPartnerRequests } from 'store/actions/partner';
import { NOTIFY_TYPE } from 'shared/consts';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';

export const success = () => ({
  type: PARTNER.MANAGING_PARTNER_APPLICATION_SUCCESS,
});

const managingPartnerApplication = data => async dispatch => {
  try {
    await Connector.managingPartnerApplication(data);
    if (data.acceptance) {
      toast(<Notify label="global.application_accepted" type={NOTIFY_TYPE.CHECKED} />);
    } else {
      toast(<Notify label="global.application_rejected" type={NOTIFY_TYPE.CHECKED} />);
    }
    dispatch(getPartnerRequests());
    return true;
  } catch (err) {
    return err.response;
  }
};

export default managingPartnerApplication;

import React, { useState } from 'react';
import { Button } from 'shared/components';
import { func, bool } from 'prop-types';
import { AddCategoryModal } from 'screens/category-management/components';

const Header = ({ addCategory, isMobile, formatMessage }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const openModal = () => {
    setIsModalOpened(true);
  };
  return (
    <div className="categoryManagement__header">
      <AddCategoryModal
        formatMessage={formatMessage}
        title={formatMessage({ id: 'global.add_category' })}
        addCategory={addCategory}
        isOpened={isModalOpened}
        setIsModalOpened={setIsModalOpened}
      />
      <div id='add-category--button' className="categoryManagement__header__left">
        <p className="categoryManagement__header__left--paragraph">{formatMessage({ id: 'global.category' })}</p>
        <Button
          label={`${isMobile ? formatMessage({ id: 'inputs.add_category_plus' }) : formatMessage({ id: 'inputs.add_plus' })}`}
          className="btn--gray categoryManagement__header--button"
          onClick={openModal}
        />
      </div>
      <div className="categoryManagement__header__right">
        <p>{formatMessage({ id: 'global.subcategory' })}</p>
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  addCategory: func.isRequired,
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
};

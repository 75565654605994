import React from 'react';
import { SVG } from 'shared/components';
import { string, func } from 'prop-types';
import { LANGUAGE_TYPE, SVG_TYPE } from 'shared/consts';
import Select, { components } from 'react-select';

const LanguageSwitch = ({ translation, setAppTranslation }) => {
  const changeLanguage = e => {
    const lang = e.value;
    setAppTranslation(lang);
    localStorage.setItem('languageSwitch', true);
  };

  const DropdownIndicator = props => (
    <components.DropdownIndicator {...props}>
      <SVG type={SVG_TYPE.CHEVRONE} />
    </components.DropdownIndicator>
  );
  return (
    <div className="languageSwitch">
      <div className="select-field__wrapper">
        <div className="select-field__label">{translation}</div>
        <div className="select-field">
          <div className="select-field__container">
            <Select
              components={{ DropdownIndicator }}
              classNamePrefix="react-select"
              className="react-select"
              isMulti={false}
              options={LANGUAGE_TYPE}
              placeholder=""
              onChange={e => changeLanguage(e)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageSwitch;

LanguageSwitch.propTypes = {
  setAppTranslation: func.isRequired,
  translation: string.isRequired,
};

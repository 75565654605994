/* eslint-disable react/prop-types */
import React, { useState } from 'react';
// import React, { useState } from 'react';
import { Table, Badge, SVG } from 'shared/components';
import { BADGE_TYPES, SVG_TYPE } from 'shared/consts';
// import { Settings } from 'screens/user-management/components';
import { arrayOf, shape, func, object } from 'prop-types';
import { deliveryCountryLabelShort } from 'shared/utils';
import { EditPartner, PartnerSettings } from './component';

const Partners = ({
  data,
  isMobile,
  formatMessage,
  deliveryCountries,
  userSuspension,
  editUser,
  getUserDetails,
  userDetails,
}) => {
  const [settingsType, setSettingsType] = useState({ active: '', id: '' });
  const defaultOpenModalState = { isOpened: false, data: {} };
  const [openModal, setOpenModal] = useState(defaultOpenModalState);

  const getColumns = () => [
    {
      width: isMobile ? '45' : '145',
      Cell: () => <Badge type={BADGE_TYPES.PARTNER} label={formatMessage({ id: 'global.partner' })} />,
    },
    {
      id: 'first_name',
      accessor: 'first_name',
      width: !isMobile ? '100' : 'auto',
    },
    {
      id: 'last_name',
      accessor: 'last_name',
      width: !isMobile ? '150' : 'auto',
    },
    {
      id: 'email',
      accessor: 'email',
      width: '180',
      className: 'user-management__table__content--desktop',
    },
    {
      id: 'is_allegro',
      Cell: ({ original: { is_allegro } }) => (
        <>
          {is_allegro ? (
            <SVG type={SVG_TYPE.CORRECT_CIRCLE} className="user-management__content--svg" />
          ) : (
            <SVG type={SVG_TYPE.INCORRECT_CIRCLE} className="user-management__content--svg" />
          )}
        </>
      ),
      width: '100',
      className: 'user-management__table__content--desktop user-management__table__content--type',
    },
    {
      id: 'is_ebay',
      Cell: ({ original: { is_ebay } }) => (
        <div className="user-management__table__content--allegro">
          {is_ebay ? (
            <SVG type={SVG_TYPE.CORRECT_CIRCLE} className="user-management__content--svg" />
          ) : (
            <SVG type={SVG_TYPE.INCORRECT_CIRCLE} className="user-management__content--svg" />
          )}
        </div>
      ),
      width: '100',
      className: 'user-management__table__content--desktop user-management__table__content--type',
    },
    {
      id: 'country_of_delivery_label.value',
      accessor: 'country_of_delivery_label.value',
      width: '60',
      className: 'user-management__table__content--desktop ',
      Cell: ({
        original: {
          country_of_delivery_label: { value },
        },
      }) => deliveryCountryLabelShort(value),
    },
    {
      id: 'partner.type',
      Cell: ({ original: { partner} }) => <p className={`user-management__content--${partner?.type ? partner.type:''}`}>{partner?.type}</p>,
      width: '100',
      className: 'user-management__table__content--desktop user-management__table__content--type',
    },

    {
      width: '0',
      Cell: ({ original }) => (
        <PartnerSettings
          setOpenModal={setOpenModal}
          data={original}
          id={original.id}
          settingsType={settingsType}
          setSettingsType={setSettingsType}
          userSuspension={userSuspension}
          formatMessage={formatMessage}
        />
      ),
      className: 'user-management__table__content--marginAuto',
    },
  ];
  return (
    <>
      <div className="user-management__table--wrapper">
        <div className="user-management__table__headingPartner">
          <p className="user-management__table__headingPartner--partnerName">{formatMessage({ id: 'inputs.name' })}</p>
          <p className="user-management__table__headingPartner--partnerSurname">{formatMessage({ id: 'inputs.last_name' })}</p>
          <p className="user-management__table__headingPartner--partnerEmail">{formatMessage({ id: 'inputs.email' })}</p>
          <p className="user-management__table__headingPartner--partnerAllegro">
            <SVG type={SVG_TYPE.LOGO_ALLEGRO} />
          </p>
          <p className="user-management__table__headingPartner--partnerEbay">
            <SVG type={SVG_TYPE.LOGO_EBAY} />
          </p>
          <p className="user-management__table__headingPartner--partnerCountry">{formatMessage({ id: 'global.country' })}</p>
          <p className="user-management__table__headingPartner--partnerPayments">{formatMessage({ id: 'global.payment' })}</p>
        </div>
        <div className="user-management__table__content">{data && <Table data={data} columns={getColumns()} />}</div>
      </div>
      <EditPartner
        deliveryCountries={deliveryCountries}
        userDetails={userDetails}
        getUserDetails={getUserDetails}
        editUser={editUser}
        openModal={openModal}
        formatMessage={formatMessage}
        onClose={() => setOpenModal(defaultOpenModalState)}
      />
    </>
  );
};

export default Partners;

Partners.propTypes = {
  data: arrayOf(shape({})).isRequired,
  deliveryCountries: func.isRequired,
  editUser: func.isRequired,
  formatMessage: func.isRequired,
  getUserDetails: func.isRequired,
  userDetails: object.isRequired,
  userSuspension: func.isRequired,
};

import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createRoom, getRooms, sendMessage, getRoomMessages } from 'store/actions/room';
import { getRooms as getRoomsSelector, getRoomMessages as getRoomMessagesSelector } from 'store/selectors/roomSelector';
import { getAuthorizedUser } from 'store/selectors/userSelector';
import { getDeliveryCountries as getDelivertCountriesSelector } from 'store/selectors/deliverySelector';
import Chat from './Chat.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createRoom: user => createRoom(user),
      getRooms: uuid => getRooms(uuid),
      sendMessage: data => sendMessage(data),
      getRoomMessages: (data, runner) => getRoomMessages(data, runner),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  rooms: getRoomsSelector(state),
  roomMessages: getRoomMessagesSelector(state),
  authorizedUser: getAuthorizedUser(state),
  deliveryCountries : getDelivertCountriesSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Chat));

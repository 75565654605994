import React from 'react';
import { ProductMagazineAdress } from 'shared/components';
import { FormattedMessage } from 'react-intl';

const ProductDelivery = () => (
  <>
    <div className="product-delivery">
      <p className="product-delivery--name">
        <FormattedMessage id="global.possible_pickup" />
      </p>
      <ProductMagazineAdress />
    </div>
    <div className="product-delivery__shipment">
      <p className="product-delivery__shipment--label">
        <FormattedMessage id="global.delivery_from" />
      </p>
      <p className="product-delivery__shipment--price">
        14.90 <FormattedMessage id="global.currency" />
      </p>
    </div>
  </>
);

export default ProductDelivery;

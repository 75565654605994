/* eslint react/prop-types: 0 */

import moment from 'moment';
import React from 'react';
import { Button, SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import Product from 'screens/magazine/components/orders/Product';
import { bool, func, object } from 'prop-types';

const OrderItem = ({ data, formatMessage, isMobile, createCheckoutSession }) => {
  const { user_first_name, user_last_name, checkouts_products, final_price, status, created_at, currency } = data;
  const getSellerId = product => product.owner.id;
  const getDeliveryId = product => product.delivery.id;

  const groupBySeller = checkouts_products.reduce((sellersSoFar, item) => {
    if (!sellersSoFar[getSellerId(item)]) sellersSoFar[getSellerId(item)] = [];
    sellersSoFar[getSellerId(item)].push(item);
    return sellersSoFar;
  }, {});
  const groupByDelivery = productsList =>
    productsList.reduce((deliverySoFar, item) => {
      if (!deliverySoFar[getDeliveryId(item)]) deliverySoFar[getDeliveryId(item)] = [];
      deliverySoFar[getDeliveryId(item)].push(item);
      return deliverySoFar;
    }, {});

  return (
    <div className={`order-item__wrapper order-item__wrapper--${status}`}>
      <div className="order-item__top">
        <span className="order-item__status">
          {formatMessage({ id: `global.${status}` })}
          {status === 'paid' ? <SVG type={SVG_TYPE.CORRECT_CIRCLE} /> : <SVG type={SVG_TYPE.INCORRECT_CIRCLE} />}
        </span>
        <span>
          {moment(created_at).format('DD.MM.YYYY')} {formatMessage({ id: 'global.at' })} {moment(created_at).format('HH:mm')}{' '}
        </span>
      </div>
      <p className="order-item__person">
        {formatMessage({ id: 'global.buyer' })}:{' '}
        <span className="order-item__name">
          {user_first_name && user_first_name} {user_last_name && user_last_name}
        </span>
      </p>
      {Object.values(groupBySeller).map(products => (
        <>
          <p className="order-item__person">
            {formatMessage({ id: 'global.seller' })}:{' '}
            <span className="order-item__name">
              {products[0].owner.first_name} {products[0].owner.last_name}{' '}
            </span>
          </p>
          <div className="order-item__products">
            {Object.values(groupByDelivery(products)).map(delivery => (
              <>
                {delivery.map(product => (
                  <Product data={product} currency={currency} isMobile={isMobile} formatMessage={formatMessage} />
                ))}
                <div className="order-item__delivery">
                  <span>{formatMessage({ id: 'global.shipment' })}:</span>
                  <span>{delivery[0].delivery.name}</span>
                  <span className="order-item--price">
                    {delivery[0].delivery.price} <span className="currency">{currency}</span>
                  </span>
                </div>
              </>
            ))}
          </div>
        </>
      ))}
      <div className="order-item__total">
        <span className="order-item__total__left">{formatMessage({ id: 'global.total' })}:</span>
        <span className="order-item__total__right">
          {final_price} <span className="currency">{currency}</span>
        </span>
      </div>
      {status === 'unpaid' && (
        <div className="order-item--payments-btn">
          {' '}
          <Button
            onClick={() => createCheckoutSession({ uuid: data.uuid })}
            label={formatMessage({ id: 'global.go_to_payment' })}
            className="btn--orange"
          />{' '}
        </div>
      )}
    </div>
  );
};

export default OrderItem;

OrderItem.propTypes = {
  createCheckoutSession: func.isRequired,
  data: object.isRequired,
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
};

import React, { useEffect, useState } from 'react';
import { MainCategory, PopularCategories } from 'shared/components';
import { func, array, string, shape, object } from 'prop-types';
import PopularBrands from 'shared/components/popular-brands';
import { SearchParts, MapSection, TwoTiles } from './components';

const HomePage = ({
  actions: { getCarModels, getDeliveryCountries, getCarBrands, getProductsByFilter, getPopularBrands, getPopularCategories },
  intl: { formatMessage },
  authorizedUser,
  carBrands,
  deliveryCountries,
  carModels,
  categories,
  translation,
  popularBrands,
  popularCategories,
}) => {
  const marketingCookieAgreement = localStorage.getItem('cookiesMarketingAgreement') === 'true';
  const analiticCookieAgreement = localStorage.getItem('cookiesAnaliticAgreement') === 'true';
  useEffect(async () => {
    window.scroll(0, 500);
    await getCarBrands();
    await getDeliveryCountries();
    await getPopularBrands();
    await getPopularCategories();
  }, []);
  if (marketingCookieAgreement) {
    document.cookie = 'marketingCookie=Hi im marketing cookie!';
  } else {
    document.cookie = 'marketingCookie=Hi im marketing cookie!;expires=Thu, 01 Jan 1970 00:00:00 UTC;';
  }

  if (analiticCookieAgreement) {
    document.cookie = 'analiticCookie=Hi im analitic cookie!';
  } else {
    document.cookie = 'analiticCookie=Hi im analitic cookie!;expires=Thu, 01 Jan 1970 00:00:00 UTC;';
  }
  const deliveryCountryUuid = authorizedUser ? authorizedUser.country.label : localStorage.getItem('deliveryCountry');
  const countryLabel =
    !!deliveryCountries.length &&
    !!deliveryCountryUuid &&
    deliveryCountries?.find(item => item.value === deliveryCountryUuid)?.label;
  const countryShort = () => {
    if (countryLabel === 'Poland') {
      return 'pl';
    }
    if (countryLabel === 'Germany') {
      return 'de';
    }
    return translation;
  };
  const [country, setCountry] = useState(countryShort());
  useEffect(() => {
    // eslint-disable-next-line no-nested-ternary
    setCountry(countryShort());
  }, [popularCategories, localStorage.getItem('deliveryCountry'), authorizedUser, translation]);
  return (
    <main>
      <SearchParts
        getProductsByFilter={getProductsByFilter}
        authorizedUser={authorizedUser}
        deliveryCountries={deliveryCountries}
        getCarModels={getCarModels}
        getCarBrands={getCarBrands}
        carBrands={carBrands}
        carModels={carModels}
        formatMessage={formatMessage}
        categories={categories}
        country={country}
      />
      <MapSection formatMessage={formatMessage} />
      <MainCategory translation={translation} formatMessage={formatMessage} categories={categories.category} country={country} />
      <PopularBrands
        translation={translation}
        formatMessage={formatMessage}
        popularBrands={popularBrands.brand_list}
        country={country}
      />
      <TwoTiles formatMessage={formatMessage} />
      <PopularCategories popularCategories={popularCategories} translation={translation} country={country} />
    </main>
  );
};

// HomePage.propTypes = {};

export default HomePage;

HomePage.defaultProps = {
  formatMessage: () => console.log('t'),
};
HomePage.propTypes = {
  actions: shape({
    getCarBrands: func.isRequired,
    getCarModels: func.isRequired,
    getDeliveryCountries: array.isRequired,
    getProductsByFilter: array.isRequired,
  }).isRequired,
  authorizedUser: object.isRequired,
  carBrands: array.isRequired,
  carModels: array.isRequired,
  categories: array.isRequired,
  deliveryCountries: array.isRequired,
  formatMessage: func,
  popularBrands: array.isRequired,
  popularCategories: object.isRequired,
  translation: string.isRequired,
};

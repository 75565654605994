import React, { useEffect, useState } from 'react';
import { func, bool, shape, object } from 'prop-types';
import { Header, Couriers } from './components';

const DeliveryOptions = ({
  intl: { formatMessage },
  authorizedUser,
  deliveryCountries,
  isMobile,
  actions: { getDelivery, addDeliveryMethod, editDeliveryMethod, getDeliveryCountries, deleteDeliveryMethod, deleteFiles },
  delivery,
}) => {
  const [menuType, setMenuType] = useState(authorizedUser && authorizedUser.country.label);

  useEffect(async () => {
    await getDeliveryCountries();
  }, []);

  useEffect(async () => {
    await getDelivery(menuType);
  }, [menuType]);

  return (
    <main className="delivery-options">
      <Header
        deliveryCountries={deliveryCountries}
        userInfo={authorizedUser}
        addDeliveryMethod={addDeliveryMethod}
        menuType={menuType}
        setMenuType={setMenuType}
        formatMessage={formatMessage}
        isMobile={isMobile}
      />
      <Couriers
        editDeliveryMethod={editDeliveryMethod}
        deleteFiles={deleteFiles}
        isMobile={isMobile}
        deliveryCountries={deliveryCountries}
        deleteDeliveryMethod={deleteDeliveryMethod}
        formatMessage={formatMessage}
        delivery={delivery}
        menuType={menuType}
      />
    </main>
  );
};

// HomePage.propTypes = {};

export default DeliveryOptions;

DeliveryOptions.propTypes = {
  actions: shape({
    getDelivery: func.isRequired,
    getDeliveryCountries: func.isRequired,
    deleteDeliveryMethod: func.isRequired,
    deleteFiles: func.isRequired,
    editDeliveryMethod: func.isRequired,
  }).isRequired,
  authorizedUser: object.isRequired,
  delivery: object.isRequired,
  deliveryCountries: object.isRequired,
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
};

import React, { useState } from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { string, func, object } from 'prop-types';
import { CategorySettings, EditCatModal, DeleteCatModal } from 'screens/category-management/components';

const SingleSubcategory = ({ label, deleteSubcategory, data, editSubcategory, formatMessage }) => {
  const [isSettingsOpened, setIsSettingsOpened] = useState(false);
  const [whichModalIsOpened, setWhichModalIsOpened] = useState({ name: '', uuid: '' });
  const editModalOpened = whichModalIsOpened.name === 'edit';
  const deleteModalOpened = whichModalIsOpened.name === 'delete';
  const isDeletedDisabled = data.used;
  const triggerSettings = () => {
    setIsSettingsOpened(!isSettingsOpened);
  };
  const handleDelete = () => {
    deleteSubcategory(whichModalIsOpened.uuid);
    setWhichModalIsOpened({ name: '', uuid: '' });
  };
  const handleEdit = async values => {
    const response = await editSubcategory(whichModalIsOpened.uuid, values);
    const isLinkTaken = response.data?.message === "Link is already taken"
    !isLinkTaken && setWhichModalIsOpened({ name: '', uuid: '' });
    return response
  };
  return (
    <>
      {data && (
        <div className="categoryManagement__singleSubcat">
          <div className="categoryManagement__singleSubcat--label">{label}</div>
          <div
            className={`categoryManagement__singleSubcat--dots categoryManagement__singleSubcat--dots--${
              isSettingsOpened ? 'none' : ''
            }`}
            onClick={triggerSettings}
          >
            <SVG type={SVG_TYPE.DOTS} />
          </div>
          <CategorySettings
            formatMessage={formatMessage}
            isOpened={isSettingsOpened}
            setIsSettingsOpened={setIsSettingsOpened}
            setWhichModalIsOpened={setWhichModalIsOpened}
            uuid={data.uuid}
            isDeletedDisabled={isDeletedDisabled}
          />
          <EditCatModal
            formatMessage={formatMessage}
            title={formatMessage({ id: 'global.edit_subcategory' })}
            edit={handleEdit}
            data={data}
            isOpened={editModalOpened}
            close={setWhichModalIsOpened}
            subcategory
          />
          <DeleteCatModal
            formatMessage={formatMessage}
            title={formatMessage({ id: 'global.delete_subcategory' })}
            deleteElement={handleDelete}
            isOpened={deleteModalOpened}
            close={setWhichModalIsOpened}
            subcategory
          />
        </div>
      )}
    </>
  );
};

export default SingleSubcategory;

SingleSubcategory.propTypes = {
  data: object.isRequired,
  deleteSubcategory: func.isRequired,
  editSubcategory: func.isRequired,
  formatMessage: func.isRequired,
  label: string.isRequired,
};

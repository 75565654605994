import React from 'react';
import PropTypes from 'prop-types';

const Item = ({ label, onClick }) => (
  <div className="product-list-item__settings--item" onClick={onClick}>
    {label}
  </div>
);

Item.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Item;

import React, { useState } from 'react';
import { Button, Modal, SwitchWithoutText, ModalActions } from 'shared/components';
import { func, object } from 'prop-types';
import { injectIntl } from 'react-intl';
import { MODAL_TYPE } from 'shared/consts';

const CookiesAgreement = ({ intl, setIsCookieOpen, edit, onSubmit }) => {
  const { formatMessage } = intl;
  const [isModalOpen, setIsModalOpen] = useState(!!edit);
  const [agreements, setAgreemnets] = useState({
    necessary: true,
    analytical: localStorage.getItem('cookiesAnaliticAgreement') === 'true',
    marketing: localStorage.getItem('cookiesMarketingAgreement') === 'true',
  });
  const onClose = () => {
    setIsModalOpen(false);
    onSubmit && onSubmit();
  };
  const handleCookiesAccept = () => {
    localStorage.setItem('cookiesNessesaryAgreement', 'true');
    localStorage.setItem('cookiesAnaliticAgreement', 'true');
    localStorage.setItem('cookiesMarketingAgreement', 'true');
    setIsCookieOpen && setIsCookieOpen(false);
    onSubmit && onSubmit();
  };

  const handleCustomCookiesAccept = () => {
    localStorage.setItem('cookiesNessesaryAgreement', `${agreements.necessary}`);
    localStorage.setItem('cookiesAnaliticAgreement', `${agreements.analytical}`);
    localStorage.setItem('cookiesMarketingAgreement', `${agreements.marketing}`);
    onClose();
    setIsCookieOpen && setIsCookieOpen(false);
  };

  return (
    <div className="cookies__wrapper">
      <div className="cookies__container">
        <Modal opened={isModalOpen} onOpenedChange={edit && setIsCookieOpen} onClose={onClose} className="cookies__modal">
          <div className="cookies__modal--wrapper">
            <h2 className="cookies__modal--header">{formatMessage({ id: 'global.manage_agreements' })}</h2>
            <div className="cookies__modal--top">
              <p>{formatMessage({ id: 'global.manage_cookie_header' })}</p>
              <a href="/">
                {formatMessage({ id: 'global.more_informations_about' })} <b>{formatMessage({ id: 'global.cookie_policy' })}</b>{' '}
                {formatMessage({ id: 'global.service' })}
              </a>
            </div>
            <main className="cookies__modal__content">
              <section className="cookies__modal__section">
                <div className="cookies__modal__section--top">
                  <h3 className="cookies__modal__section--header">{formatMessage({ id: 'global.necessary' })}</h3>
                  <SwitchWithoutText checked={agreements.necessary} formatMessage={formatMessage} />
                </div>
                <p className="cookies__modal__section--paragraph">{formatMessage({ id: 'global.necessary_cookie_text' })}</p>
              </section>
              <section className="cookies__modal__section">
                <div className="cookies__modal__section--top">
                  <h3 className="cookies__modal__section--header">{formatMessage({ id: 'global.analytical' })}</h3>
                  <SwitchWithoutText
                    checked={agreements.analytical}
                    formatMessage={formatMessage}
                    onClick={value => setAgreemnets({ ...agreements, analytical: value })}
                  />
                </div>
                <p className="cookies__modal__section--paragraph">{formatMessage({ id: 'global.analytical_cookie_text' })}</p>
              </section>
              <section className="cookies__modal__section">
                <div className="cookies__modal__section--top">
                  <h3 className="cookies__modal__section--header">{formatMessage({ id: 'global.marketing' })}</h3>
                  <SwitchWithoutText
                    checked={agreements.marketing}
                    formatMessage={formatMessage}
                    onClick={value => setAgreemnets({ ...agreements, marketing: value })}
                  />
                </div>
                <p className="cookies__modal__section--paragraph">{formatMessage({ id: 'global.marketing_cookie_text' })}</p>
              </section>
              <ModalActions
                formatMessage={formatMessage}
                type={MODAL_TYPE.COOKIE_ACCEPT}
                // onClose={onClose}
                onSubmit={() => handleCustomCookiesAccept()}
              />
            </main>
          </div>
        </Modal>
        {!isModalOpen && (
          <div className="cookies">
            <p className="cookies--paragraph">
              {formatMessage({ id: 'global.cookie_short' })}
              <span className="cookies--paragraph--yellow">{formatMessage({ id: 'global.privacy_policy3' })}</span> .
            </p>
            <div className="cookies--buttons">
              <Button
                label={formatMessage({ id: 'inputs.accept_all' })}
                className="btn--orange"
                onClick={() => handleCookiesAccept()}
              />
              <Button
                label={formatMessage({ id: 'inputs.settings' })}
                className="btn--orange-border"
                onClick={() => setIsModalOpen(true)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default injectIntl(CookiesAgreement);

CookiesAgreement.propTypes = {
  edit: func.isRequired,
  intl: object.isRequired,
  onSubmit: func.isRequired,
  setCookieAgreement: func.isRequired,
  setCustomCookieAgreement: func.isRequired,
  setIsCookieOpen: func.isRequired,
};

import * as Connector from 'shared/connectors/shoppingCartConnector';
import { SHOPPING_CART } from 'store/types';

const init = () => ({
  type: SHOPPING_CART.GET_SHOPPING_CART_OWNER_INIT,
});

export const success = data => ({
  type: SHOPPING_CART.GET_SHOPPING_CART_OWNER_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: SHOPPING_CART.GET_SHOPPING_CART_OWNER_FAILURE,
});



const getShoppingCartOwner = () => async dispatch => {
  const notLoggedUserBasketUuid = localStorage.getItem('notLoggedUserBasketUuid');
  const isUserLogged = localStorage.getItem('isUserLogged');
  dispatch(init());
  let uuid;
  if (notLoggedUserBasketUuid && !isUserLogged) {
    uuid = notLoggedUserBasketUuid;
  }
  try {
    const { data } = await Connector.getShoppingCartOwner(uuid);
    dispatch(success(data.owners));
    return true;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default getShoppingCartOwner;

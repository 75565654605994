import React, { useState } from 'react';
import { ProductMagazineAdress, StatePart, PartnerBadge, Button, SVG } from 'shared/components';
import { SVG_TYPE, ROUTES } from 'shared/consts';
// import Zderzak1 from 'assets/files/zderzak1.jpg';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { generatePath } from 'react-router';
import { Settings, Price } from './components';
import { LANGUAGE } from './utils/language';

const ProductListItem = ({
  del,
  className,
  isMobile,
  watched,
  settings,
  orders,
  formatMessage,
  item,
  translation,
  deleteFollowedProduct,
  deleteAdvertisements,
  cancelAdvertisement,
}) => {
  const [isSettingsOpened, setIsSettingsOpened] = useState(false);

  const history = useHistory();
  const categoryName = item?.subcategory?.category?.name;
  const detailedProductLink = () =>
    history.push(
      generatePath(ROUTES.SINGLE_PRODUCT, {
        category: categoryName,
        product: item.uuid,
      }),
    );

  const clickDots = () => {
    setIsSettingsOpened(!isSettingsOpened);
  };
  const closeSettings = () => {
    setIsSettingsOpened(false);
  };

  const handleDeleteItem = uuid => {
    deleteFollowedProduct(uuid);
  };
  // console.log(thumbnail)
  return (
    <>
      {!isMobile ? (
        <div className="product-list-item--wrapper">
          <div className={`product-list-item ${className}`}>
            <div className="product-list-item__photography" onClick={() => detailedProductLink()}>
              {item.files &&
                (item.files[0] !== null ? (
                  <img
                    className="product-list-item__photography--image"
                    src={item.files[0]?.path}
                    alt={item && item[LANGUAGE[translation]]}
                  />
                ) : (
                  <SVG className="photo-slider__image" type={SVG_TYPE.DEFAULT_PHOTO} />
                ))}
            </div>
            <div className="product-list-item__mid" onClick={() => detailedProductLink()}>
              <p className="product-list-item__mid--name">{item && item[LANGUAGE[translation]]}</p>
              {/* <p className="product-list-item__mid--name">{item && item.title}</p> */}
              <StatePart formatMessage={formatMessage} type="used" state="magazine" data={item} />
              {watched ? (
                <div className="product-list-item__mid--item">
                  <p className="breadcrumbs__item">
                    nr kat: {item?.category_id} Producent: {item?.brand}
                  </p>
                  <p className="breadcrumbs__item">
                    {item?.brand} {item?.model}
                  </p>
                </div>
              ) : (
                <>
                  <div className="product-list-item__mid--katalog">Marka: {item?.brand.length > 0 ? item?.brand : ''}</div>
                  <div className="product-list-item__mid--katalog">
                    Model: {item?.model && item?.model.length > 0 ? item?.model : ''}
                  </div>
                </>
              )}
              <div className="product-list-item__mid--location">
                <ProductMagazineAdress className="product-list-item__mid--adress" location={item?.location} />
              </div>
            </div>
            <div className="product-list-item__right">
              <Price formatMessage={formatMessage} item={item} />
              {del && (
                <p className="product-list-item--delete">
                  <SVG className="product-list-item--delete--icon" type={SVG_TYPE.BIN} />
                  {formatMessage({ id: 'inputs.delete' })}
                </p>
              )}
              {watched && (
                <Button
                  className="product-list-item__right--button product-list-item__right--button--watched btn--gray"
                  label={formatMessage({ id: 'inputs.you_are_watching' })}
                  iconType={SVG_TYPE.FULL_HEART}
                  onClick={() => handleDeleteItem(item?.uuid)}
                />
              )}
              {settings && (
                <Settings
                  formatMessage={formatMessage}
                  history={history}
                  data={item}
                  deleteAdvertisements={deleteAdvertisements}
                  cancelAdvertisement={cancelAdvertisement}
                />
              )}
              {orders && (
                <>
                  <PartnerBadge formatMessage={formatMessage} className="product-list-item__right--partner-badge" />
                  <Button className="btn--gray product-list-item__right--invoice" label="Faktura" iconType={SVG_TYPE.DOWNLOAD} />
                </>
              )}
              {!del && !watched && !settings && !orders && (
                <>
                  <PartnerBadge formatMessage={formatMessage} className="product-list-item__right--partner-badge" />
                  <p className="product-list-item__right--pick-up">Odbiór w punkcie</p>
                  <Button className="product-list-item__right--button" label="Obserwuj" iconType={SVG_TYPE.FULL_HEART} />
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="product-list-item-mobile__wrapper">
          <div
            className={`"product-list-item__photography product-list-item-mobile__photography" ${
              settings ? 'product-list-item-mobile__photography--settings' : ''
            }`}
          >
            {item.files &&
              (item.files[0] !== null ? (
                <img
                  className="product-list-item__photography--image"
                  src={item.files[0]?.path}
                  alt={item && item[LANGUAGE[translation]]}
                />
              ) : (
                <SVG className="photo-slider__image" type={SVG_TYPE.DEFAULT_PHOTO} />
              ))}
          </div>
          <div className="product-list-item-mobile__right">
            {isSettingsOpened && <Settings formatMessage={formatMessage} onClose={() => closeSettings()} />}
            <div className="product-list-item-mobile--name--wrapper">
              <p className="product-list-item__mid--name product-list-item-mobile--name">{item?.title}</p>
              {settings ? (
                <div onClick={clickDots}>
                  <SVG className="product-list-item-mobile__icon--watched" type={SVG_TYPE.DOTS} />
                </div>
              ) : (
                <div onClick={() => handleDeleteItem(item?.uuid)}>
                  <SVG
                    className={`product-list-item-mobile--watched ${watched ? 'product-list-item-mobile__icon--watched' : ''}`}
                    type={SVG_TYPE.FULL_HEART}
                  />
                </div>
              )}
            </div>
            <div className="product-list-item-mobile--price">
              {/* <Price formatMessage={formatMessage} isMobile={isMobile} price={item?.price} /> */}
              <Price formatMessage={formatMessage} item={item} />
            </div>
            <div className="product-list-item-mobile__right--bottom">
              {!settings ? (
                <>
                  <PartnerBadge formatMessage={formatMessage} />
                  <StatePart formatMessage={formatMessage} data={item} type="used" state="magazine" />
                </>
              ) : (
                <ProductMagazineAdress className="product-list-item__mid--adress" />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

ProductListItem.propTypes = {
  cancelAdvertisement: PropTypes.func.isRequired,
  className: PropTypes.string,
  data: PropTypes.array,
  del: PropTypes.bool,
  deleteAdvertisements: PropTypes.func.isRequired,
  deleteFollowedProduct: PropTypes.func.isRequired,
  formatMessage: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  item: PropTypes.array,
  orders: PropTypes.bool,
  settings: PropTypes.bool,
  translation: PropTypes.string.isRequired,
  watched: PropTypes.bool,
};
ProductListItem.defaultProps = {
  className: '',
  data: {},
  del: false,
  item: { title: 'Domyślny', category_id: 1, brand: 'Domyślna', model: 'Domyślny', location: 'Domyślna', price: 100 },
  orders: false,
  settings: false,
  watched: false,
};

export default ProductListItem;

import React, { useState } from 'react';
import { SVG, Modal } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { SearchByVehicle } from 'screens/products-lists/components';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const Filters = ({
  isMobile,
  formatMessage,
  categoryByLink,
  authorizedUser,
  carBrands,
  carModels,
  getCarModels,
  getCarBrands,
  deliveryCountries,
  cleaningAllProducts,
}) => {
  const [isFiltersOpened, setIsFiltersOpened] = useState(false);
  const handleFilterClick = () => {
    setIsFiltersOpened(!isFiltersOpened);
  };

  return (
    <div className="filters">
      <div className="filters__icon--wrapper">
        <div className="filters__icon" onClick={handleFilterClick}>
          <SVG className="filters__icon--image" type={SVG_TYPE.FILTER} />
        </div>
      </div>
      <TransitionGroup component={null}>
        {isFiltersOpened && (
          <CSSTransition classNames="modal__transition" timeout={isMobile ? 300 : 0}>
            <Modal className='filters' onClose={() => setIsFiltersOpened(false)} opened={isFiltersOpened}>
              <div className="filters__content">
                <div onClick={() => setIsFiltersOpened(false)}>
                  <SVG className="filters--close" type={SVG_TYPE.CLOSE} />
                </div>
                <SearchByVehicle
                  isMobile={isMobile}
                  formatMessage={formatMessage}
                  categoryByLink={categoryByLink}
                  authorizedUser={authorizedUser}
                  carBrands={carBrands}
                  carModels={carModels}
                  getCarModels={getCarModels}
                  getCarBrands={getCarBrands}
                  deliveryCountries={deliveryCountries}
                  cleaningAllProducts={cleaningAllProducts}
                  setIsFiltersOpened={setIsFiltersOpened}
                />
              </div>
            </Modal>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

export default Filters;

Filters.propTypes = {
  authorizedUser: PropTypes.object.isRequired,
  carBrands: PropTypes.object.isRequired,
  carModels: PropTypes.object.isRequired,
  categoryByLink: PropTypes.object.isRequired,
  cleaningAllProducts: PropTypes.func.isRequired,
  deliveryCountries: PropTypes.object.isRequired,
  formatMessage: PropTypes.func.isRequired,
  getCarBrands: PropTypes.func.isRequired,
  getCarModels: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

const carBrandsOptions = brands => {
  const arr = [];
  brands &&
    brands.length > 0 &&
    brands.forEach(({ label, value }) =>
      arr.push({
        label: value,
        searchableLabel: value,
        value: label,
      }),
    );
  return arr;
};

export default carBrandsOptions;

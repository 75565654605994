/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Table, Badge } from 'shared/components';
import { BADGE_TYPES } from 'shared/consts';
import { func, object } from 'prop-types';
import { ConclusionsSettings, AssignPartner, CompanyData, RemoveApplication } from './components';

const Conclusions = ({
  isMobile,
  usersForApplication,
  getPartnerRequests,
  partnerRequests,
  managingPartnerApplication,
  formatMessage,
  users,
}) => {
  const [settingsType, setSettingsType] = useState({ active: '', id: '' });
  const defaultOpenModalState = { isOpened: false, data: {} };
  const [openModal, setOpenModal] = useState(defaultOpenModalState);

  useEffect(async () => {
    await getPartnerRequests();
  }, []);

  const getColumns = () => [
    {
      width: isMobile ? '45' : '145',
      Cell: ({ original: { user } }) =>
        user && <Badge type={BADGE_TYPES.CLIENT} label={formatMessage({ id: 'global.client' })} />,
    },
    {
      id: 'owner_first_name',
      accessor: 'owner_first_name',
      width: !isMobile ? '136' : 'auto',
    },
    {
      id: 'owner_last_name',
      accessor: 'owner_last_name',
      width: !isMobile ? '168' : 'auto',
    },
    {
      width: '240',
      className: 'user-management__table__content--desktop',
      Cell: ({ original: { user } }) => (user && user.email) || '',
    },
    {
      id: 'company_name',
      accessor: 'company_name',
      width: '200',
      className: 'user-management__table__content--desktop',
    },
    {
      width: '100',
      Cell: ({ original }) => (
        <ConclusionsSettings
          setOpenModal={setOpenModal}
          managingPartnerApplication={managingPartnerApplication}
          data={original}
          id={original.id}
          settingsType={settingsType}
          setSettingsType={setSettingsType}
          formatMessage={formatMessage}
        />
      ),
      className: 'user-management__table__content--marginAuto',
    },
  ];

  return (
    <>
      <div className="user-management__table--wrapper">
        <div className="user-management__table__heading">
          <p className="user-management__table__heading--conclusionName">{formatMessage({ id: 'inputs.name' })}</p>
          <p className="user-management__table__heading--coordinatorSurname">{formatMessage({ id: 'inputs.last_name' })}</p>
          <p className="user-management__table__heading--coordinatorEmail">{formatMessage({ id: 'inputs.email' })}</p>
          <p className="user-management__table__heading--coordinatorCountry">{formatMessage({ id: 'inputs.company_name' })}</p>
        </div>
        <div className="user-management__table__content">
          {partnerRequests.length > 0 && <Table data={partnerRequests} columns={getColumns()} />}
        </div>
      </div>
      <AssignPartner
        managingPartnerApplication={managingPartnerApplication}
        users={usersForApplication}
        formatMessage={formatMessage}
        onClose={() => setOpenModal(defaultOpenModalState)}
        openModal={openModal}
      />
      <CompanyData
        managingPartnerApplication={managingPartnerApplication}
        users={users}
        formatMessage={formatMessage}
        onClose={() => setOpenModal(defaultOpenModalState)}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <RemoveApplication
        managingPartnerApplication={managingPartnerApplication}
        formatMessage={formatMessage}
        onClose={() => setOpenModal(defaultOpenModalState)}
        openModal={openModal}
      />
    </>
  );
};

export default Conclusions;

Conclusions.propTypes = {
  formatMessage: func.isRequired,
  getPartnerRequests: func.isRequired,
  managingPartnerApplication: func.isRequired,
  partnerRequests: object.isRequired,
  usersForApplication: object.isRequired,
};

import moment from 'moment';
import { bool, func, number, object } from 'prop-types';
import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { isMobile } from 'shared/utils';
import ChatForm from '../chat-form';

const Chatbox = ({
  page,
  setPage,
  authorizedUser,
  updateRoomStatus,
  formatMessage,
  showChat,
  setShowChat,
  chatArchived,
  archivedFilter,
  roomMessages,
  sendMessage,
  rooms,
  setChatArchived,
}) => {
  const handleOnSubmit = () => {};
  const handleScroll = e => {
    if (
      showChat &&
      e.currentTarget.scrollTop === 0 &&
      rooms.rooms.filter(item => item.uuid === showChat)[0]?.page_amount !== page
    ) {
      setPage(page * 1 + 1);
    }
  };
  const usedDates = [];
  const currentRoom = showChat && rooms.rooms && rooms.rooms.filter(item => item.uuid === showChat)[0];
  return (
    <div id="chat__chat__wrapper" className={`help-chat__chat__wrapper ${showChat ? 'show' : ''}`}>
      {isMobile() && showChat && rooms.rooms && (
        <div className="help-chat__chat__top">
          <span className="help-chat__chat__top--arrow__wrapper" onClick={() => setShowChat(false)}>
            <SVG className="help-chat__chat__top--arrow " type={SVG_TYPE.CHEVRONE} />
          </span>
          <span
            className={`help-chat__user__icon help-chat__user__icon--${
              rooms.rooms.filter(item => item.uuid === showChat)[0].owner_first_name ? 'blue' : 'grey'
            } help-chat__user__icon--chat`}
          >
            {rooms.rooms.filter(item => item.uuid === showChat)[0].owner_first_name
              ? rooms.rooms.filter(item => item.uuid === showChat)[0].owner_first_name.slice(0, 1)
              : 'N'}
            {rooms.rooms.filter(item => item.uuid === showChat)[0].owner_last_name
              ? rooms.rooms.filter(item => item.uuid === showChat)[0].owner_last_name.slice(0, 1)
              : ''}
          </span>
          <span className="help-chat__user--name">
            {rooms.rooms.filter(item => item.uuid === showChat)[0].owner_first_name
              ? rooms.rooms.filter(item => item.uuid === showChat)[0].owner_first_name
              : 'Niezalogowany'}{' '}
            {rooms.rooms.filter(item => item.uuid === showChat)[0].owner_last_name
              ? rooms.rooms.filter(item => item.uuid === showChat)[0].owner_last_name
              : ''}
          </span>
          {(authorizedUser.role === 'admin' || authorizedUser.role === 'coordinator') &&
            (archivedFilter ? (
              <div
                onClick={() => updateRoomStatus({ room_uuid: showChat, is_closed: !chatArchived })}
                className={`help-chat__top__menu--right help-chat__top__menu--mobile ${
                  currentRoom.owner_role ? '' : 'help-chat__archive--disabled'
                }`}
              >
                {formatMessage({ id: 'global.restore' })}
              </div>
            ) : (
              <div
                onClick={() => updateRoomStatus({ room_uuid: showChat, is_closed: !chatArchived })}
                className="help-chat__top__menu--right help-chat__top__menu--mobile"
              >
                {formatMessage({ id: 'global.archive' })}
              </div>
            ))}
        </div>
      )}
      {showChat &&
        rooms.rooms &&
        (authorizedUser.role === 'admin' || authorizedUser.role === 'coordinator') &&
        (rooms.rooms.filter(item => item.uuid === showChat)[0].is_closed ? (
          <div
            onClick={() => {
              updateRoomStatus({
                room_uuid: showChat,
                is_closed: !rooms.rooms.filter(item => item.uuid === showChat)[0].is_closed,
              });
              setChatArchived(rooms.rooms.filter(item => item.uuid === showChat)[0].uuid);
            }}
            className={`help-chat__archive ${currentRoom.owner_role ? '' : 'help-chat__archive--disabled'}`}
          >
            {formatMessage({ id: 'global.restore' })}
          </div>
        ) : (
          <div
            onClick={() => {
              updateRoomStatus({
                room_uuid: showChat,
                is_closed: !rooms.rooms.filter(item => item.uuid === showChat)[0].is_closed,
              });
              setChatArchived(rooms.rooms.filter(item => item.uuid === showChat)[0].uuid);
            }}
            className="help-chat__archive"
          >
            {formatMessage({ id: 'global.archive' })}
          </div>
        ))}
      <div id="chat__messages__list" className="chat--box__content help-chat__chat__content" onScroll={handleScroll}>
        {showChat &&
          roomMessages &&
          roomMessages.length > 0 &&
          roomMessages.map(item => (
            <div
              key={item.uuid}
              className={`help-chat__message help-chat__message--${
                item.owner === 'admin' || item.owner === 'coordinator' ? 'right' : 'left'
              }`}
            >
              <div
                className={`help-chat__message__info help-chat__message__info--${
                  item.owner === 'admin' || item.owner === 'coordinator' ? 'right' : 'left'
                }`}
              >
                <span
                  className={`help-chat__user__icon help-chat__user__icon--${
                    showChat && item.owner === 'client' && !rooms?.rooms?.filter(el => el.uuid === showChat)[0].owner_first_name
                      ? 'grey'
                      : item.owner
                  } help-chat__user__icon--chat`}
                >
                  {item.owner === 'admin' && 'AA'}
                  {item.owner !== 'admin' && (item.first_name ? item.first_name.slice(0, 1) : 'N')}
                  {item.owner !== 'admin' && item.last_name ? item.last_name.slice(0, 1) : ''}
                </span>
                <div className="time">{moment(item.created_at).format('HH:mm')}</div>
              </div>
              {new Date().getTime() - new Date(item.created_at).getTime() > 86400000 &&
                !usedDates.find(el => el === Math.floor(new Date(item.created_at).getTime() / 86400000)) &&
                usedDates.push(Math.floor(new Date(item.created_at).getTime() / 86400000)) && (
                  <div className="help-chat__message__info--date">
                    {`${new Date(item.created_at).getDate() < 10 ? '0' : ''}${new Date(item.created_at).getDate()}.${
                      new Date(item.created_at).getMonth() < 10 ? '0' : ''
                    }${new Date(item.created_at).getMonth()}.${new Date(item.created_at).getFullYear()}`}
                  </div>
                )}
              <div className={`text text--${item.owner}`}>{item.content}</div>
            </div>
          ))}
      </div>
      <ChatForm room={showChat} sendMessage={sendMessage} formatMessage={formatMessage} handleOnSubmit={handleOnSubmit} />
    </div>
  );
};
export default Chatbox;

Chatbox.propTypes = {
  archivedFilter: number.isRequired,
  authorizedUser: object.isRequired,
  chatArchived: bool.isRequired,
  formatMessage: func.isRequired,
  page: number.isRequired,
  roomMessages: object.isRequired,
  rooms: object.isRequired,
  sendMessage: func.isRequired,
  setChatArchive: func.isRequired,
  setChatArchived: func.isRequired,
  setPage: func.isRequired,
  setShowChat: func.isRequired,
  showChat: bool.isRequired,
  updateRoomStatus: func.isRequired,
};

/* eslint react/prop-types: 0 */
import React from 'react';

const LocalMargin = ({ data }) => {
  if (data.price_margin) {
    const {
      currency,
      price_margin: { value, isPercentageLocal },
    } = data;

    const UTIL = {
      eur: 'EUR',
      pln: 'PLN',
    };

    return (
      <span>
        {value} {isPercentageLocal ? '%' : UTIL[currency]}
      </span>
    );
  }
  return null;
};

export default LocalMargin;

import React, { useState } from 'react';
import PartnerImg from 'assets/files/partner.jpg';
import PartnerMobile from 'assets/files/partner_mobile.jpg';
import { bool, func, shape, object, string } from 'prop-types';

import { BecomePartner, Header, PartnerBenefits, HowItWorks, PartnershipApplication } from './components';

const PartnerProgram = ({ isMobile, intl: { formatMessage }, actions: { partnerRequest }, authorizedUser, translation }) => {
  const [selectedSubscription, setSelectedSubscription] = useState({ isOpened: false, selected: false });
  return (
    <div className="partnerProgram__wrapper">
      <div className="header">
        <img className="partnerProgram__header--img" src={!isMobile ? PartnerImg : PartnerMobile} alt="partnerzy zdjęcie" />
        <Header formatMessage={formatMessage} />
      </div>
      <main className="partnerProgram__main">
        <BecomePartner
          authorizedUser={authorizedUser}
          translation={translation}
          formatMessage={formatMessage}
          setSelectedSubscription={setSelectedSubscription}
        />
        <PartnerBenefits formatMessage={formatMessage} />
        <HowItWorks formatMessage={formatMessage} isMobile={isMobile} />
        <PartnershipApplication
          authorizedUser={authorizedUser}
          partnerRequest={partnerRequest}
          formatMessage={formatMessage}
          selectedSubscription={selectedSubscription}
          setSelectedSubscription={setSelectedSubscription}
          translation={translation}
        />
      </main>
    </div>
  );
};

export default PartnerProgram;

PartnerProgram.propTypes = {
  actions: shape({
    formatMessage: func.isRequired,
    partnerRequest: func.isRequired,
  }).isRequired,
  authorizedUser: object.isRequired,
  isMobile: bool.isRequired,
  translation: string.isRequired,
};

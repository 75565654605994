/* eslint-disable no-return-assign */
import React, { useState } from 'react';
import { RegularField, PhotoField, Button, Translate } from 'shared/components';
import { Field } from 'formik';
import PropTypes from 'prop-types';

const Photos = ({
  errors,
  formatMessage,
  setPhotoInputValue,
  setFieldValue,
  translation,
  customTranslations,
  productPreview,
  setFileArr,
  setBase64,
}) => {
  const [characterCount, setCharacterCount] = useState(0);
  const [characterCountTranslate, setCharacterCountTranslate] = useState(0);
  const [descriptionTranslate, setDescriptionTranslate] = useState('');
  const isLangPl = translation === 'pl';
  const descriptLength = e => {
    setDescriptionTranslate(e.target.value);
    const descriptField = document.getElementsByClassName('add-advertisement__field--textarea');
    const textarea = descriptField[0].getElementsByTagName('textarea');
    setCharacterCount(textarea[0].textLength);
  };

  const descriptLengthTranslate = () => {
    const descriptField = document.getElementsByClassName('add-advertisement__field--textarea-translate');
    const textarea = descriptField[0].getElementsByTagName('textarea');
    setCharacterCountTranslate(textarea[0].textLength);
  };

  const handleTranslateTitle = async () => {
    let textToTranslate = descriptionTranslate;
    const matchWords = [];
    const wordsWithIndex = [];
    let translateTo = '';
    if (isLangPl) {
      translateTo = 'de';
    } else {
      translateTo = 'pl';
    }
    const translate = async text => {
      const respond = await Translate(text, translateTo);
      return respond;
    };

    // find match word and push it to matchWords
    customTranslations.translations.map(
      item =>
        descriptionTranslate.match(new RegExp(item.word, 'g')) &&
        matchWords.push({ word: item.word, translation: item.translation }),
    );
    // add || characters before and after every custom translation word
    const getWholeWord = data => `\\b${data}\\b`;
    matchWords.map(item =>
      item.word.split(' ').length === 1
        ? (textToTranslate = textToTranslate.replaceAll(new RegExp(getWholeWord(item.word), 'g'), `||${item.word}||`))
        : (textToTranslate = textToTranslate.replaceAll(new RegExp(getWholeWord(item.word), 'g'), `~~${item.word}~~`)),
    );
    // remove nested matches
    const regex = /(?<=~~).*(?=~~)/gi;
    textToTranslate.includes('~~') &&
      (textToTranslate = textToTranslate
        .match(regex)
        .map(item => textToTranslate.replaceAll(item, item.replaceAll('||', '')))
        .join('||')
        .replaceAll('~~', '||'));
    // split text on matches
    textToTranslate = textToTranslate.split('||').filter(el => el !== '');
    // find indexes of matches and Translations
    textToTranslate.map((word, index) =>
      wordsWithIndex.push({
        word: word.trim(),
        index,
        isCustomTranslation:
          matchWords.filter(item => item.word.trim() === word.trim()).length > 0
            ? matchWords.filter(item => item.word.trim() === word.trim())[0].translation
            : false,
      }),
    );
    // replace custom words and translate default words
    const textAfterTranslate = wordsWithIndex.map(item =>
      item.isCustomTranslation ? item.isCustomTranslation : translate(item.word, translateTo),
    );
    const result = data => {
      if (data.includes('||error||')) {
        translateTo === 'de'
          ? setFieldValue('description_ger', formatMessage({ id: 'global.can_not_translate' }))
          : setFieldValue('description', formatMessage({ id: 'global.can_not_translate' }));
      } else {
        translateTo === 'de' ? setFieldValue('description_ger', data.join(' ')) : setFieldValue('description', data.join(' '));
        setCharacterCountTranslate(data.join(' ').length);
      }
    };
    Promise.all(textAfterTranslate).then(data => result(data));
  };

  return (
    <section>
      <h3 className="heading">{formatMessage({ id: 'global.photos' })}</h3>
      <p className="descript">{formatMessage({ id: 'global.max_16_photos' })}</p>
      <div className="add-advertisement__photos">
        <Field
          formatMessage={formatMessage}
          name="photos"
          photoSend={setPhotoInputValue}
          className="add-advertisement__field"
          multiple
          component={PhotoField}
          data={productPreview}
          setFileArr={setFileArr}
          setBase64={setBase64}
        />
      </div>
      <div className="add-advertisement--line" />
      <div id="validate--description" className="add-advertisement__product-descript">
        <Field
          className="add-advertisement__field add-advertisement__field--textarea"
          component={RegularField}
          inputOnChange={e => descriptLength(e)}
          label={formatMessage({ id: 'global.descript_product' })}
          errors={errors}
          name={translation === 'pl' ? 'description' : 'description_ger'}
          type="textarea"
        />
        <div className="add-advertisement__product-descript--bottom">
          <p className={`${errors.description === '' ? 'add-advertisement__field--textarea--error' : ''}`}>
            {formatMessage({ id: 'global.min_80_characters' })}
          </p>
          <p>{characterCount}/6000</p>
        </div>
        <Button
          className="btn--orange btn--orange--sharp"
          onClick={() => handleTranslateTitle()}
          label={formatMessage({ id: 'global.translate' })}
        />
        <Field
          className="add-advertisement__field add-advertisement__field--textarea-translate"
          component={RegularField}
          inputOnChange={() => descriptLengthTranslate()}
          label={formatMessage({ id: 'global.descript_product_version' })}
          errors={errors}
          name={translation === 'pl' ? 'description_ger' : 'description'}
          type="textarea"
        />
        <div className="add-advertisement__product-descript--bottom">
          <p className={`${errors.description_ger === '' ? 'add-advertisement__field--textarea--error' : ''}`}>
            {formatMessage({ id: 'global.min_80_characters' })}
          </p>
          <p>{characterCountTranslate}/6000</p>
        </div>
      </div>
    </section>
  );
};

export default Photos;

Photos.propTypes = {
  customTranslations: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  formatMessage: PropTypes.func.isRequired,
  productPreview: PropTypes.object.isRequired,
  setBase64: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFileArr: PropTypes.func.isRequired,
  setPhotoInputValue: PropTypes.func.isRequired,
  translation: PropTypes.object.isRequired,
};

import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';

import { bool, func, string, object } from 'prop-types';
import { Modal, ModalActions, RegularField, PhotoCategory } from 'shared/components';
import { MODAL_TYPE, VALIDATION_FIELD } from 'shared/consts';
import { Field, Formik } from 'formik';

const EditCatModal = ({ isOpened, close, title, subcategory, data, edit, formatMessage, deleteFiles }) => {
  const [fileInputValue, setFileInputValue] = useState((data?.files && data.files) || '');
  const [isSetCategoryPhoto, setIsSetCategoryPhoto] = useState(data?.files && !!data.files.length);
  const [photoUuidDelete, setpPhotoUuidDelete] = useState('');
  const formRef = useRef(null);

  useEffect(() => {
    setFileInputValue(data?.files && data.files);
  }, [data]);

  useEffect(() => {
    setIsSetCategoryPhoto(!!fileInputValue?.length);
  }, [fileInputValue]);

  const onClose = () => {
    close(false);
    setIsSetCategoryPhoto(true);
    setFileInputValue((data?.files && data.files) || '');
    console.log(isOpened)
  };

  const isLinkTaken = response =>
    response.data?.message === 'This link is actually taken' ||
    response.data?.message === 'Link is already taken' ||
    response.data?.message === 'Link is already taken';

  const wrongPhotoFile = response => response === 'cannot add this photo';
  const onSubmit = async values => {
    if (!subcategory) {
      if (fileInputValue.length > 0 && isSetCategoryPhoto !== 'empty') {
        if (data.files !== fileInputValue) {
          deleteFiles(photoUuidDelete);
          const response = await edit({ ...values, files: fileInputValue });
          console.log(wrongPhotoFile(response));
          if (isLinkTaken(response) || wrongPhotoFile(response)) {
            isLinkTaken(response) &&
              formRef.current.setFieldError('link', formatMessage({ id: 'validation.this_link_is_actually_taken' }));
            wrongPhotoFile(response) &&
              formRef.current.setFieldError('files', formatMessage({ id: 'validation.wrong_file_type' }));
          } else {
            close(false);
            setIsSetCategoryPhoto(true);
          }
        } else {
          const response = await edit({ ...values });
          if (isLinkTaken(response) || wrongPhotoFile(response)) {
            isLinkTaken(response) &&
              formRef.current.setFieldError('link', formatMessage({ id: 'validation.this_link_is_actually_taken' }));
            wrongPhotoFile(response) &&
              formRef.current.setFieldError('files', formatMessage({ id: 'validation.wrong_file_type' }));
          } else {
            close(false);
            setIsSetCategoryPhoto(true);
          }
        }
      } else {
        console.log(fileInputValue)
        setIsSetCategoryPhoto('empty');
      }
    } else {
      const response = await edit({ ...values, files: [] });
      if (isLinkTaken(response) || wrongPhotoFile(response)) {
        isLinkTaken(response) &&
          formRef.current.setFieldError('link', formatMessage({ id: 'validation.this_link_is_actually_taken' }));
        wrongPhotoFile(response) && formRef.current.setFieldError('files', formatMessage({ id: 'validation.wrong_file_type' }));
      }
    }
  };

  const validation = () =>
    Yup.object().shape({
      link: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254)
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE),
      name: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254)
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE),
      name_ger: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254)
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE),
    });

  const handleFileAdd = e => {
    setFileInputValue(e.target.files);
  };

  return (
    <div>
      {isOpened && (
        <Modal className={`${MODAL_TYPE.CANCEL} categories`} onClose={onClose} opened={isOpened}>
          <div>
            <div className="modal__heading modal__heading--center">{title}</div>
            <Formik
              innerRef={formRef}
              validationSchema={validation()}
              initialValues={{
                name: data?.name || '',
                link: data?.link || '',
                name_ger: data?.name_ger || '',
                uuid: data?.uuid || '',
              }}
              onSubmit={onSubmit}
            >
              {({ handleSubmit, errors }) => (
                <form className="categoryManagement__addCategory__wrapper" onSubmit={handleSubmit}>
                  <div className="categoryManagement__addCategory__content">
                    <Field
                      className="categoryManagement__subcategory"
                      type="text"
                      label={`${
                        subcategory
                          ? formatMessage({ id: 'global.subcategory_name_pl' })
                          : formatMessage({ id: 'global.category_name_pl' })
                      }`}
                      name="name"
                      component={RegularField}
                      errors={errors}
                    />
                    <Field
                      type="text"
                      className="categoryManagement__subcategory"
                      label={`${
                        subcategory
                          ? formatMessage({ id: 'global.subcategory_name_de' })
                          : formatMessage({ id: 'global.category_name_de' })
                      }`}
                      name="name_ger"
                      component={RegularField}
                      errors={errors}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: 'global.link' })}
                      name="link"
                      component={RegularField}
                      errors={errors}
                    />
                    {!subcategory && (
                      <>
                        <Field
                          name="files"
                          handleChange={e => handleFileAdd(e)}
                          className="add-advertisement__field add-advertisement__field--textarea"
                          component={PhotoCategory}
                          fileInputValue={fileInputValue}
                          isSetCategoryPhoto={isSetCategoryPhoto}
                          setIsSetCategoryPhoto={setIsSetCategoryPhoto}
                          setFileInputValue={setFileInputValue}
                          deleteFiles={deleteFiles}
                          setpPhotoUuidDelete={setpPhotoUuidDelete}
                          formatMessage={formatMessage}
                        />
                        {(isSetCategoryPhoto === 'empty' || errors?.files )&& (
                          <p className="add__photos--error add__photos--error2">
                            {isSetCategoryPhoto === 'empty' &&  formatMessage({ id: VALIDATION_FIELD(formatMessage).REQUIRED })}{' '}
                            {errors?.files && errors?.files}
                          </p>
                        )}
                      </>
                    )}
                    <ModalActions
                      formatMessage={formatMessage}
                      type={MODAL_TYPE.EDIT_CATEGORY}
                      onClose={onClose}
                      onSubmit={() => handleSubmit()}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default EditCatModal;

EditCatModal.defaultProps = {
  subcategory: bool.false,
};
EditCatModal.propTypes = {
  close: func.isRequired,
  data: object.isRequired,
  deleteFiles: func.isRequired,
  edit: func.isRequired,
  formatMessage: func.isRequired,
  isOpened: bool.isRequired,
  subcategory: bool,
  title: string.isRequired,
};

const CATEGORY = {
  ADD_CATEGORY_INIT: 'ADD_CATEGORY_INIT',
  ADD_CATEGORY_SUCCESS: 'ADD_CATEGORY_SUCCESS',
  ADD_CATEGORY_FAILURE: 'ADD_CATEGORY_FAILURE',
  ADD_SUB_CATEGORY_INIT: 'ADD_SUB_CATEGORY_INIT',
  ADD_SUB_CATEGORY_SUCCESS: 'ADD_SUB_CATEGORY_SUCCESS',
  ADD_SUB_CATEGORY_FAILURE: 'ADD_SUB_CATEGORY_FAILURE',
  GET_ALL_CATEGORY_INIT: 'GET_ALL_CATEGORY_INIT',
  GET_ALL_CATEGORY_SUCCESS: 'GET_ALL_CATEGORY_SUCCESS',
  GET_ALL_CATEGORY_FAILURE: 'GET_ALL_CATEGORY_FAILURE',
  GET_ALL_CATEGORY_AND_SUBCATEGORY_INIT: 'GET_ALL_CATEGORY_AND_SUBCATEGORY_INIT',
  GET_ALL_CATEGORY_AND_SUBCATEGORY_SUCCESS: 'GET_ALL_CATEGORY_AND_SUBCATEGORY_SUCCESS',
  GET_ALL_CATEGORY_AND_SUBCATEGORY_FAILURE: 'GET_ALL_CATEGORY_AND_SUBCATEGORY_FAILURE',
  GET_SUBCATEGORY_FOR_CATEGORY_INIT: 'GET_SUBCATEGORY_FOR_CATEGORY_INIT',
  GET_SUBCATEGORY_FOR_CATEGORY_SUCCESS: 'GET_SUBCATEGORY_FOR_CATEGORY_SUCCESS',
  GET_SUBCATEGORY_FOR_CATEGORY_FAILURE: 'GET_SUBCATEGORY_FOR_CATEGORY_FAILURE',
  DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
  DELETE_SUBCATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
  EDIT_CATEGORY_SUCCESS: 'EDIT_CATEGORY_SUCCESS',
  EDIT_SUBCATEGORY_SUCCESS: 'EDIT_SUBCATEGORY_SUCCESS',
  GET_CATEGORY_BY_LINK_INIT: 'GET_CATEGORY_BY_LINK_INIT',
  GET_CATEGORY_BY_LINK_SUCCESS: 'GET_CATEGORY_BY_LINK_SUCCESS',
  GET_CATEGORY_BY_LINK_FAILURE: 'GET_CATEGORY_BY_LINK_FAILURE',
  GET_POPULAR_CATEGORIES_INIT: 'GET_POPULAR_CATEGORIES_INIT',
  GET_POPULAR_CATEGORIES_SUCCESS: 'GET_POPULAR_CATEGORIES_SUCCESS',
  GET_POPULAR_CATEGORIES_FAILURE: 'GET_POPULAR_CATEGORIES_FAILURE',
};

export default CATEGORY;

import { string, func, array, bool } from 'prop-types';
import React from 'react';
import { Countries, Partners, Products } from 'screens/price-management/components';
import { MENU_TYPES } from '../const';

const Content = ({
  menuType,
  formatMessage,
  data,
  isMobile,
  partnersList,
  productMargin,
  editPriceMargin,
  createPriceMargin,
  deletePriceMargin,
}) => (
  <>
    {menuType.label === MENU_TYPES(formatMessage).COUNTRY.label && (
      <Countries
        isMobile={isMobile}
        deliveryCountries={data.countries}
        formatMessage={formatMessage}
        editPriceMargin={editPriceMargin}
        createPriceMargin={createPriceMargin}
      />
    )}
    {menuType.label === MENU_TYPES(formatMessage).PARTNERS.label && (
      <Partners
        isMobile={isMobile}
        partners={data.partner}
        formatMessage={formatMessage}
        editPriceMargin={editPriceMargin}
        createPriceMargin={createPriceMargin}
        deletePriceMargin={deletePriceMargin}
      />
    )}
    {menuType.label === MENU_TYPES(formatMessage).PRODUCTS.label && (
      <Products
        partnersList={partnersList}
        isMobile={isMobile}
        products={data.products}
        formatMessage={formatMessage}
        productMargin={productMargin}
        editPriceMargin={editPriceMargin}
        createPriceMargin={createPriceMargin}
        deletePriceMargin={deletePriceMargin}
      />
    )}
  </>
);

export default Content;

Content.propTypes = {
  createPriceMargin: func.isRequired,
  data: array.isRequired,
  deletePriceMargin: func.isRequired,
  editPriceMargin: func.isRequired,
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
  menuType: string.isRequired,
  partnersList: array.isRequired,
  productMargin: func.isRequired,
};

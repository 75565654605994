import axios from 'axios';
import { string } from 'prop-types';

const Translate = (text, language) => {
  const fetchData = async () => {
    const result = await axios
      .post(
        'https://translation.googleapis.com/language/translate/v2',
        {},
        {
          params: {
            q: text,
            target: language,
            source: language ==='de'? 'pl':'de',
            // key: 'AIzaSyALb_xMtub2Y4_b1Ewg-d8Jyxi7HwuNXZA' // produkcyjny klucz
            key:process.env.REACT_APP_GOOGLE_API_KEY 
          },
        },
      )
      .then(async data => data.data.data.translations[0].translatedText)
      .catch(() => '||error||');
    return result;
  };
  return fetchData();
};

export default Translate;

Translate.propTypes = {
  language: string.isRequired,
  text: string.isRequired,
};

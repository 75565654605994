import React from 'react';
import PropTypes from 'prop-types';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

const SearchField = ({ field: { name, value }, form: { setFieldValue }, onChange, className, placeholder }) => {
  const handleChange = (e) => {
    onChange && onChange(e);
    setFieldValue(name,e.target.value)
  }
  return (
    <div className={`search-field ${className}`}>
      <input
        className="search-field--input"
        name={name}
        onChange={e => {
          handleChange(e);
        }}
        placeholder={placeholder}
        type="text"
        value={value}
      />
      <SVG className="search-field--icon" type={SVG_TYPE.LOUPE} />
    </div>
  );
};

SearchField.defaultProps = {
  className: '',
};
SearchField.propTypes = {
  className: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  form: PropTypes.object.isRequired,
  onChange:PropTypes.func.isRequired,
  placeholder: PropTypes.isRequired,
};

export default SearchField;

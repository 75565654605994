/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Table, Badge } from 'shared/components';
import { BADGE_TYPES, USER_ROLES } from 'shared/consts';
import { ClientSettings } from 'screens/user-management/components';
import { func, object } from 'prop-types';
import { deliveryCountryLabelShort } from 'shared/utils';
import EditUserModal from '../edit-user-modal';

const Clients = ({ isMobile, users, formatMessage, userSuspension, userResendLink, editUser }) => {
  const [settingsType, setSettingsType] = useState({ active: '', id: '' });
  const [openModal, setOpenModal] = useState({ isOpened: false });

  const getColumns = () => [
    {
      width: isMobile ? '45' : '162',
      Cell: () => <Badge type={BADGE_TYPES.CLIENT} label={formatMessage({ id: 'global.client' })} />,
    },
    {
      id: 'first_name',
      accessor: 'first_name',
      width: !isMobile ? '176' : 'auto',
    },
    {
      id: 'last_name',
      accessor: 'last_name',
      width: !isMobile ? '170' : 'auto',
    },
    {
      id: 'email',
      accessor: 'email',
      width: '190',
      className: 'user-management__table__content--desktop',
    },
    {
      id: 'country_of_delivery_label.value',
      accessor: 'country_of_delivery_label.value',
      width: 'fit-content' || '50',
      className: 'user-management__table__content--lang',
      Cell: ({
        original: {
          country_of_delivery_label: { value },
          is_suspended,
          is_activate,
        },
      }) => (isMobile ? !is_suspended && is_activate && deliveryCountryLabelShort(value) : deliveryCountryLabelShort(value)),
    },
    {
      width: !isMobile ? '140' : 'auto',
      Cell: ({ original: { is_activate, is_suspended } }) =>
        !isMobile ? (
          <>
            {is_suspended ? (
              <Badge type={BADGE_TYPES.SUSPENDED} label={formatMessage({ id: 'global.suspended' })} />
            ) : (
              <> {!is_activate && <Badge type={BADGE_TYPES.UNACTIVE} label={formatMessage({ id: 'global.unactive' })} />} </>
            )}
          </>
        ) : (
          <>
            {is_suspended ? (
              <p>{formatMessage({ id: 'global.suspended' })} </p>
            ) : (
              <> {!is_activate && <p>{formatMessage({ id: 'global.unactive' })} </p>} </>
            )}
          </>
        ),
    },
    {
      width: '100',
      Cell: ({ original: { is_activate, is_suspended, role, email, uuid, first_name, last_name, was_partner }, index }) => (
        <>
          <ClientSettings
            role={role}
            was_partner={was_partner}
            first_name={first_name}
            last_name={last_name}
            formatMessage={formatMessage}
            openModal={openModal}
            setOpenModal={setOpenModal}
            userResendLink={userResendLink}
            uuid={uuid}
            id={index}
            settingsType={settingsType}
            setSettingsType={setSettingsType}
            active={is_activate}
            suspended={is_suspended}
            email={email}
            userSuspension={userSuspension}
            type={USER_ROLES.CLIENT}
          />
        </>
      ),
    },
  ];
  return (
    <>
      <div className="user-management__table--wrapper">
        <div className="user-management__table__heading">
          <p className="user-management__table__heading--name">{formatMessage({ id: 'inputs.name' })}</p>
          <p className="user-management__table__heading--surname">{formatMessage({ id: 'inputs.last_name' })}</p>
          <p className="user-management__table__heading--email">{formatMessage({ id: 'inputs.email' })}</p>
          <p className="user-management__table__heading--lang">{formatMessage({ id: 'global.country' })}</p>
        </div>
        <div className="user-management__table__content">{users && <Table data={users} columns={getColumns()} />}</div>
      </div>
      <EditUserModal
        type={USER_ROLES.CLIENT}
        editUser={editUser}
        formatMessage={formatMessage}
        openModal={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};

export default Clients;

Clients.propTypes = {
  editUser: func.isRequired,
  formatMessage: func.isRequired,
  userResendLink: func.isRequired,
  users: object.isRequired,
  userSuspension: func.isRequired,
};

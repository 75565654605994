/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Table, Badge } from 'shared/components';
import { arrayOf, shape, func, object, bool } from 'prop-types';
import { MODAL_TYPE } from 'shared/consts';
import { categoriesOptions } from 'shared/utils';
import { ProductTitle, Country, SellStatus, Settings } from './table';
import { BADGE_TYPE, BADGE_LABEL, PRODUCTS_OPTIONS } from '../utils/consts';
import BackOffModal from './BackOffModal';
import EditModal from './EditModal';
import DeleteModal from './DeleteModal';
import AddTransferModal from './AddTransferModal';
import ResumeModal from './ResumeModal';

const Products = ({
  data,
  formatMessage,
  isMobile,
  addTransfer,
  deliveryCountries,
  categoryAndSubcategory,
  editAdvertisements,
  deleteProduct,
  recallTheProduct,
  resumeTheProduct,
  translation,
  edit,
  setEdit,
  getSubcategoryForCategory,
  subcategoryForCategory,
}) => {
  const [settingsType, setSettingsType] = useState({ active: '', id: '' });
  const defaultOpenModalState = { isOpened: false, data: {} };
  const [openModal, setOpenModal] = useState(defaultOpenModalState);
  const isBackOffModalOpened = openModal.isOpened === MODAL_TYPE.BACK_OFF;
  const isEditModalOpened = openModal.isOpened === MODAL_TYPE.EDIT_WAREHOUSE;
  const isDeleteModalOpened = openModal.isOpened === MODAL_TYPE.DELETE_WAREHOUSER;
  const isTransferModalOpened = openModal.isOpened === MODAL_TYPE.ADD_TRANSFER;
  const isResumeModalOpened = openModal.isOpened === MODAL_TYPE.RESUME;

  const expandDataByStock = () => {
    const newData = [];
    const getInStock = el => el.stock?.in_stock;
    // const getRecalled = el => el.stock?.in_stock;
    const getPaid = el => el.stock?.paid;
    const getInTransfer = el => el.stock?.in_transfer;
    const getUnpaid = el => el.stock?.unpaid;
    // const getCancelled = el => el.status === 'canceled';
    const getAllegroSold = el => el.stock?.allegro_sold;
    const getAllegro = el => el.stock?.allegro;
    const isAllegro = el => !!el.stock?.allegro;
    const isCanceled = el => !!el.stock?.canceled;
    const isAllegroSold = el => !!el.stock?.allegro_sold;
    const handleStatus = el => {
      if (isCanceled(el)) {
        return { sale_status: formatMessage({ id: 'global.recalled2' }), sale_status_link: 'canceled' };
      }
      if (isAllegro(el)) {
        return { sale_status: formatMessage({ id: 'global.allegro' }), sale_status_link: 'allegro' };
      }
      if (isAllegroSold(el)) {
        return { sale_status: formatMessage({ id: 'global.allegro_sold' }), sale_status_link: 'allegro' };
      }
      return { sale_status: formatMessage({ id: 'global.in_magazine' }), sale_status_link: 'in_stock' };
    };
    const handleStock = el => {
      getInStock(el) &&
        newData.push({
          ...el,
          amount: getInStock(el),
          ...handleStatus(el),
        });
      getPaid(el) && newData.push({ ...el, amount: getPaid(el), sale_status: formatMessage({ id: 'global.sold' }) });
      getInTransfer(el) &&
        newData.push({
          ...el,
          amount: getInTransfer(el),
          sale_status: formatMessage({ id: 'global.in_transfer' }),
          sale_status_link: 'in_transfer',
        });
      getUnpaid(el) &&
        newData.push({
          ...el,
          amount: getUnpaid(el),
          sale_status: formatMessage({ id: 'global.unpaid' }),
          sale_status_link: 'unpaid',
        });
      getAllegroSold(el) &&
        newData.push({
          ...el,
          amount: getAllegroSold(el),
          sale_status: formatMessage({ id: 'global.allegro_sold' }),
          sale_status_link: 'allegro_sold',
        });
      getAllegro(el) &&
        newData.push({
          ...el,
          amount: getAllegro(el),
          sale_status: formatMessage({ id: 'global.allegro' }),
          sale_status_link: 'allegro',
        });
    };
    data.map((item, i) => handleStock(item, i));
    return newData;
  };

  expandDataByStock();
  const getColumnsMobile = () => [
    {
      width: '150',
      Cell: ({ original }) => (
        <>
          <ProductTitle data={original} />
        </>
      ),
    },
    {
      width: '70',
      Cell: ({ original }) => (
        <>
          <Badge type={BADGE_TYPE[original.owner.role]} label={BADGE_LABEL[original.owner.role]} />
        </>
      ),
    },
    {
      width: '20',
      Cell: ({ original }) => (
        <>
          <Country data={original} />
        </>
      ),
    },
    {
      width: '50',
      Cell: ({ original }) => (
        <Settings
          setOpenModal={setOpenModal}
          id={original.id}
          sale_status={original.sale_status}
          settingsType={settingsType}
          setSettingsType={setSettingsType}
          formatMessage={formatMessage}
          data={original}
          options={PRODUCTS_OPTIONS}
        />
      ),
      className: 'user-management__table__content--marginAuto',
    },
  ];

  const getColumns = () => [
    {
      width: '230',
      Cell: ({ original }) => (
        <>
          <ProductTitle data={original} />
        </>
      ),
    },
    {
      width: '140',
      id: 'subcategory.category.name',
      accessor: translation === 'pl' ? 'categories.name' : 'categories.name_ger',
    },
    {
      width: '210',
      Cell: ({ original }) => (
        <>
          <Badge type={BADGE_TYPE[original.owner.role]} label={BADGE_LABEL[original.owner.role]} />
        </>
      ),
    },
    {
      width: '130',
      Cell: ({ original }) => (
        <>
          <Country data={original} />
        </>
      ),
    },
    {
      width: '100',
      accessor: 'amount',
      id: 'amount',
    },
    {
      width: '100',
      Cell: ({ original }) => (
        <>
          <SellStatus data={original} />
        </>
      ),
    },
    {
      width: '100',
      Cell: ({ original }) => (
        <Settings
          setOpenModal={setOpenModal}
          id={original.id}
          settingsType={settingsType}
          setSettingsType={setSettingsType}
          formatMessage={formatMessage}
          data={original}
          options={PRODUCTS_OPTIONS}
        />
      ),
      className: 'user-management__table__content--marginAuto',
    },
  ];
  return (
    <>
      <div className="warehouse-management__table--wrapper">
        {!isMobile && (
          <div className="warehouse-management__table__heading">
            <p className="warehouse-management__table__heading--product">{formatMessage({ id: 'global.product' })}</p>
            <p className="warehouse-management__table__heading--category">{formatMessage({ id: 'global.category' })}</p>
            <p className="warehouse-management__table__heading--role">{formatMessage({ id: 'global.added_by' })}</p>
            <p className="warehouse-management__table__heading--country">{formatMessage({ id: 'global.country' })}</p>
            <p className="warehouse-management__table__heading--amount">{formatMessage({ id: 'global.amount' })}</p>
            <p className="warehouse-management__table__heading--sellStatus">{formatMessage({ id: 'global.sell_status' })}</p>
          </div>
        )}
        <div className="user-management__table__content">
          {data && data.length > 0 && (
            <Table data={expandDataByStock()} columns={!isMobile ? getColumns() : getColumnsMobile()} />
          )}
        </div>
      </div>
      <BackOffModal
        opened={isBackOffModalOpened}
        onClose={() => setOpenModal({ isOpened: null, data: {} })}
        formatMessage={formatMessage}
        onSubmit={recallTheProduct}
        data={openModal}
      />
      <ResumeModal
        opened={isResumeModalOpened}
        onClose={() => setOpenModal({ isOpened: null, data: {} })}
        formatMessage={formatMessage}
        onSubmit={resumeTheProduct}
        data={openModal}
      />
      <EditModal
        opened={isEditModalOpened}
        onClose={() => setOpenModal({ isOpened: null, data: {} })}
        formatMessage={formatMessage}
        categories={categoryAndSubcategory?.category && categoriesOptions(categoryAndSubcategory.category)}
        onSubmit={editAdvertisements}
        data={openModal}
        edit={edit}
        setEdit={setEdit}
        subcategoryForCategory={subcategoryForCategory}
        getSubcategoryForCategory={getSubcategoryForCategory}
        translation={translation}
      />
      <DeleteModal
        opened={isDeleteModalOpened}
        onClose={() => setOpenModal({ isOpened: null, data: {} })}
        formatMessage={formatMessage}
        onSubmit={deleteProduct}
        data={openModal}
      />
      <AddTransferModal
        opened={isTransferModalOpened}
        onClose={() => setOpenModal({ isOpened: null, data: {} })}
        formatMessage={formatMessage}
        data={openModal}
        addTransfer={addTransfer}
        deliveryCountries={deliveryCountries}
      />
    </>
  );
};

export default Products;

Products.propTypes = {
  addTransfer: func.isRequired,
  data: arrayOf(shape({})).isRequired,
  deleteProduct: func.isRequired,
  deliveryCountries: func.isRequired,
  edit: bool.isRequired,
  editUser: func.isRequired,
  formatMessage: func.isRequired,
  getUserDetails: func.isRequired,
  recallTheProduct: func.isRequired,
  resumeTheProduct: func.isRequired,
  setEdit: func.isRequired,
  userDetails: object.isRequired,
  userSuspension: func.isRequired,
};

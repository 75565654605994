import React from 'react';
import { Modal, ModalActions } from 'shared/components';
import { MODAL_TYPE } from 'shared/consts';
import PropTypes, { func } from 'prop-types';

const DeleteMargin = ({ setSettingsType, settingsType, formatMessage, deletePriceMargin, type }) => {
  const onClose = () => setSettingsType({});
  const handleSubmit = () => {
    deletePriceMargin(settingsType.data.price_margin.label, type, settingsType.data.uuid);
    onClose();
  };
  return (
    <div>
      {settingsType.type === 'delete' && (
        <div>
          <Modal className={MODAL_TYPE.BASIC} onClose={onClose} opened={settingsType.type === 'delete'}>
            <div className="modal__heading modal__heading--center">{formatMessage({ id: 'inputs.delete' })}</div>
            <div className="modal__content--deleteMargin">{formatMessage({ id: 'inputs.delete_margin' })}</div>
            <ModalActions
              type={MODAL_TYPE.DELETE_MODAL}
              onSubmit={handleSubmit}
              onClose={onClose}
              formatMessage={formatMessage}
            />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default DeleteMargin;

DeleteMargin.propTypes = {
  deletePriceMargin: func.isRequired,
  formatMessage: PropTypes.func.isRequired,
  setSettingsType: PropTypes.func.isRequired,
  settingsType: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

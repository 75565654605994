import React, {useEffect } from 'react';
import { Formik, Field } from 'formik';
import { func, object } from 'prop-types';
import { SVG_TYPE, VALIDATION_FIELD } from 'shared/consts';
import { SVG, RegularField } from 'shared/components';
import * as Yup from 'yup';

const ChatForm = ({ formatMessage, rooms, sendMessage, roomMessages, setNewMessage,authorizedUser,setPage,
  createRoom,getRooms }) => {
  const chatContent = document.getElementById('chat-content');
  const scrollBottomChat = () => chatContent && chatContent.scrollTo(0, chatContent.scrollHeight);
  // const [currentRoomUuid,setCurrentRoomUuid]= useState(false)
  useEffect(() => {
    scrollBottomChat();
  }, [roomMessages <= 20]);

  const handleOnSubmit = async (values, { resetForm }) => {
    const currentChat = localStorage.getItem('chatOpenUuid'); 
    let currentRoomUuid=false
    // console.log(currentChat === null)
    // console.log(currentChat)
    // console.log(localStorage.getItem('chatOpenUuid'))
    // console.log(' ')
    if (currentChat === null) {
      const resp =  await createRoom(authorizedUser);
      await getRooms(resp?.room?.uuid);
      currentRoomUuid = resp?.room?.uuid
      setPage(1)
      localStorage.setItem('chatOpenUuid',resp?.room?.uuid)
    }
    await sendMessage({ room_uuid: currentRoomUuid||rooms?.room?.uuid, content: values.content });
    resetForm();
    setNewMessage(false);
    setTimeout(() => {
      scrollBottomChat();
    }, 400);
  };

  const validation = () =>
    Yup.object().shape({
      content: Yup.string()
        .max(500, VALIDATION_FIELD(formatMessage).MAX_500)
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE),
    });

  return (
    <div className="chat__form--wrapper">
      <Formik validationSchema={validation()} initialValues={{ content: '' }} onSubmit={handleOnSubmit}>
        {({ handleSubmit, errors }) => (
          <form className="chat__form" onSubmit={handleSubmit}>
            <Field type="text" name="content" component={RegularField} errors={errors} />
            <button className="chat__form--btn" type="submit">
              <SVG type={SVG_TYPE.SENDER} />
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ChatForm;

ChatForm.propTypes = {
  authorizedUser: object.isRequired,
  createRoom: func.isRequired,
  formatMessage: func.isRequired,
  getRooms: func.isRequired,
  roomMessages: object.isRequired,
  rooms: object.isRequired,
  sendMessage: func.isRequired,
  setNewMessage: func.isRequired,
  setPage: func.isRequired,
};

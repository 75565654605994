import * as Connector from 'shared/connectors/roomsConnector';
import { translationToCountryLabel } from 'shared/utils';
import store from 'store/store';
import { ROOM } from 'store/types';
// const init = () => ({
//   type: ROOM.GET_ROOM_INIT,
// });

export const success = data => ({
  type: ROOM.GET_ROOM_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: ROOM.GET_ROOM_FAILURE,
});

const getRooms = uuid => async (dispatch) => {
  const translation = localStorage.getItem("translation")
  const deliveryCountry = await store.getState().deliveryStore.deliveryCountries.data
  const country_uuid = deliveryCountry && deliveryCountry.find(item => item.label === translationToCountryLabel(translation)).value

  try {
    const { data: room } = await Connector.getRooms({uuid, country_uuid});
    dispatch(success(room));
    return room;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default getRooms;

import API from 'shared/connectors/config';

export function modifyShoppingCart(data) {
  return API.put(`shopping-cart`, data);
}

export function getShoppingCart(uuid, countryUuid) {
  return API.get(`shopping-cart/?uuid=${uuid}&country_uuid=${countryUuid}`);
}

export function getShoppingCartOwner(uuid) {
  return API.get(`shopping-cart/show-owners-products/${uuid ? `?&shopping_cart_uuid=${uuid}` : ''}`);
}

const removeParamsFromUrl = parameter => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  parameter.forEach(item => {
    params.delete(item);
  });
  return params.toString();
};

export default removeParamsFromUrl;

import * as Connector from 'shared/connectors/productsConnector';
import { PRODUCTS } from 'store/types';
import { ROUTES } from 'shared/consts';

const init = () => ({
  type: PRODUCTS.GET_SINGLE_PRODUCT_INIT,
});

export const success = data => ({
  type: PRODUCTS.GET_SINGLE_PRODUCT_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: PRODUCTS.GET_SINGLE_PRODUCT_FAILURE,
});

const getSingleProduct = (uuid, countryUuid, userUuid) => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getSingleProduct(uuid, countryUuid, userUuid);
    dispatch(success(data.product));
    return data;
  } catch (err) {
    dispatch(failure());
    if (err.response.data.message === 'Product does not exist') {
      window.location.pathname = ROUTES.PAGE_404;
    }
    return err.response;
  }
};

export default getSingleProduct;

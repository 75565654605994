import { ROUTES, SVG_TYPE } from 'shared/consts';

export const NAVIGATION_CATEGORY = {
  MAIN: 'main',
  HELP: 'help',
};

export const NAVIGATION_ITEMS = formatMessage => [
  {
    label: formatMessage({ id: 'global.about_us' }),
    route: ROUTES.ABOUT_US,
    icon: SVG_TYPE.ABOUT_US,
    category_class: 'help',
  },
  {
    label: formatMessage({ id: 'menu.category' }),
    icon: SVG_TYPE.CATEGORY,
    category_class: 'main',
    category: [
      {
        category_label: formatMessage({ id: 'menu.main_category' }),
        category_class: 'main',
        sub_category: [],
      },
    ],
  },
  {
    route: ROUTES.WATCHED_PRODUCTS,
    label: formatMessage({ id: 'menu.watched' }),
    icon: SVG_TYPE.HEART,
  },
  {
    label: formatMessage({ id: 'menu.contact' }),
    route: ROUTES.CONTACT,
    icon: SVG_TYPE.TEL,
    category_class: 'help',
  },
  {
    label: formatMessage({ id: 'menu.my_account' }),
    icon: SVG_TYPE.USER,
    category_class: 'myAccount',
    category: [
      {
        sub_category: [
          {
            sub_category_route: ROUTES.SETTINGS,
            sub_category_label: formatMessage({ id: 'menu.settings' }),
          },
          {
            sub_category_route: ROUTES.CATEGORY_MANAGEMENT,
            sub_category_label: formatMessage({ id: 'menu.category_management' }),
          },
          {
            sub_category_route: ROUTES.USER_MANAGEMENT,
            sub_category_label: formatMessage({ id: 'global.user_management' }),
          },
          {
            sub_category_route: ROUTES.PRICE_MANAGEMENT,
            sub_category_label: formatMessage({ id: 'global.price_management' }),
          },

          {
            sub_category_route: ROUTES.ADVERTISEMENTS,
            sub_category_label: formatMessage({ id: 'menu.advertisements' }),
          },
          {
            sub_category_route: ROUTES.INVOICES,
            sub_category_label: formatMessage({ id: 'menu.invoices' }),
          },
          {
            sub_category_route: ROUTES.DELIVERY_OPTIONS,
            sub_category_label: formatMessage({ id: 'menu.delivery_options' }),
          },
          {
            sub_category_route: ROUTES.MAGAZINE,
            sub_category_label: formatMessage({ id: 'global.magazine' }),
          },
          {
            sub_category_route: ROUTES.ORDERS,
            sub_category_label: formatMessage({ id: 'menu.order' }),
          },
          {
            sub_category_route: ROUTES.DICTIONARY,
            sub_category_label: formatMessage({ id: 'global.dictionary' }),
          },
          {
            sub_category_route: ROUTES.CHAT,
            sub_category_label: formatMessage({ id: 'global.chat' }),
          },
          {
            sub_category_route: ROUTES.ADD_ADVERTISEMENT,
            sub_category_label: formatMessage({ id: 'menu.add_advertisment' }),
          },
          {
            sub_category_route: ROUTES.LOGOUT,
            sub_category_label: formatMessage({ id: 'menu.logout' }),
          },
        ],
      },
    ],
  },
  {
    route: ROUTES.LOGIN,
    label: formatMessage({ id: 'menu.login2' }),
  },
];

/* eslint-disable react/prop-types */
import React from 'react';
import { Table } from 'shared/components';
import { arrayOf, shape, func, object } from 'prop-types';
import { bool } from 'yup';
import { OrderItem } from '../table';


const Orders = ({ data, isMobile, formatMessage,getInvoice }) => {
  const getColumns = () => [
    {
      maxWidth: 1038,
      Cell: ({ original }) => (
        <>
          <OrderItem key={data.uuid} formatMessage={formatMessage} data={original} isMobile={isMobile} getInvoice={getInvoice} />
        </>
      ),
    }
  ]
  return (
    <>
      <div className="warehouse-management__table--wrapper warehouse-management__table--wrapper--order-item">
        <div className="user-management__table__content user-management__table__content--order-item">
          {data && data.length > 0 && <Table showPaginationTop rows={3} data={data} columns={getColumns()} />}
        </div>
      </div>
    </>
  );
};

export default Orders;

Orders.propTypes = {
  data: arrayOf(shape({})).isRequired,
  deliveryCountries: func.isRequired,
  editUser: func.isRequired,
  formatMessage: func.isRequired,
  getUserDetails: func.isRequired,
  isMobile: bool.isRequired,
  userDetails: object.isRequired,
  userSuspension: func.isRequired,
};

import React, { useEffect, useState } from 'react';
import { SingleCategory, CategoryBarLists, MainCategory } from 'shared/components';
import { SVG_TYPE, ROUTES } from 'shared/consts';
import { object, func, bool, string } from 'prop-types';
import { getValueUrlParameter, removeParamsFromUrl } from 'shared/utils';
import { useHistory } from 'react-router-dom';
import qetQueryFromUrl from './utils/getQueryFromUrl';
import SearchQueryBar from './components';

const SidebarProduct = ({
  products,
  categories,
  translation,
  categoryByLink,
  formatMessage,
  urlChange,
  setUrlChange,
  cleaningAllProducts,
  authorizedUser,
  deliveryCountries,
}) => {
  const { search } = window.location;
  const isSearchingCategory = search.search('main_cat=') > 0;
  const history = useHistory();
  const isNotSearchQueryEmpty = qetQueryFromUrl() !== null && qetQueryFromUrl() !== '';

  const getSearchSubcategoryName = () => {
    if (getValueUrlParameter('main_cat')) {
      // console.log(categories.category)
      const findCategory = categories?.category?.filter(item => item.link === getValueUrlParameter('main_cat'));
      return findCategory[0];
    }
    return false;
  };
  const deliveryCountryUuid = authorizedUser ? authorizedUser.country.label : localStorage.getItem('deliveryCountry');
  const countryLabel =
    !!deliveryCountries.length &&
    !!deliveryCountryUuid &&
    deliveryCountries?.find(item => item.value === deliveryCountryUuid)?.label;
  const countryShort = () => {
    if (countryLabel === 'Poland') {
      return 'pl';
    }
    if (countryLabel === 'Germany') {
      return 'de';
    }
    return translation;
  };
  const [country, setCountry] = useState(countryShort());
  useEffect(() => {
    // eslint-disable-next-line no-nested-ternary
    setCountry(countryShort());
  }, [localStorage.getItem('deliveryCountry'), authorizedUser, translation]);
  return (
    <div className="product-sidebar">
      <>
        {isNotSearchQueryEmpty && <SearchQueryBar />}
        {isSearchingCategory ? (
          <>
            <SingleCategory
              changeCategory
              cleaningAllProducts={cleaningAllProducts}
              back={async () => {
                history.push(`${ROUTES.CATEGORY}?${removeParamsFromUrl(['cat', 'main_cat', 'empty'])}`);
                setUrlChange({ trigger: false, value: '', isActive: false, isBack: true });
              }}
              type={SVG_TYPE.BODY}
              label={translation === 'pl' ? categoryByLink?.category?.name : categoryByLink?.category?.name_ger}
              className="product-sidebar__change-category"
              files={categoryByLink?.category?.files}
            />
            <CategoryBarLists
              translation={translation}
              formatMessage={formatMessage}
              urlChange={urlChange}
              setUrlChange={setUrlChange}
              categoryByLink={categories?.category && getSearchSubcategoryName()}
              authorizedUser={authorizedUser}
              deliveryCountries={deliveryCountries}
            />
          </>
        ) : (
          <>
            <MainCategory
              isNotSearchQueryEmpty={isNotSearchQueryEmpty}
              products={products}
              urlChange={urlChange}
              setUrlChange={setUrlChange}
              translation={translation}
              formatMessage={formatMessage}
              categories={categories.category}
              authorizedUser={authorizedUser}
              country={country}
            />
          </>
        )}
      </>
    </div>
  );
};

export default SidebarProduct;

SidebarProduct.propTypes = {
  authorizedUser: object.isRequired,
  categories: object.isRequired,
  categoryByLink: object.isRequired,
  cleaningAllProducts: func.isRequired,
  deliveryCountries: object.isRequired,
  formatMessage: func.isRequired,
  isFromEngine: bool.isRequired,
  products: bool.isRequired,
  setUrlChange: func.isRequired,
  singleProduct: bool.isRequired,
  translation: string.isRequired,
  urlChange: bool.isRequired,
};

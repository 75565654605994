/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { func, object, shape } from 'prop-types';
import { Title, SearchEngine, Table, SVG, Button } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { LanguageMenu, TableHeading } from './components';
import Translation from './components/translation';
import { MENU_TYPES } from './components/const';
import Word from './components/word/word';
import Settings from './components/settings';
import DeleteDictionaryModal from './components/delete-dictionary-modal';

const Dictionary = ({
  intl: { formatMessage },
  actions: {
    addCustomTranslations,
    getCustomTranslations,
    editCustomTranstlaions,
    deleteCustomTranslations,
    searchCustomTranslations,
  },
  customTranslations,
  authorizedUser,
  isMobile,
  deliveryCountries,
}) => {
  const [menuType, setMenuType] = useState(MENU_TYPES(deliveryCountries)[authorizedUser.country.short]);
  const [settingsType, setSettingsType] = useState({ active: '', id: '' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  useEffect(async () => {
    await getCustomTranslations({ country_uuid: menuType.options });
  }, [menuType]);

  useEffect(() => {
    deliveryCountries.length && setMenuType(MENU_TYPES(deliveryCountries)[authorizedUser.country.short])
  }, [!!deliveryCountries.length]);


  const restricted_word = customTranslations?.translations?.map(item => item.word);
  const getColumns = () => [
    {
      width: !isMobile ? '300' : '70',
      Cell: ({ original }) => <Word data={original} isMobile={isMobile} />,
    },
    {
      width: !isMobile ? '100' : '40',
      Cell: () => <SVG type={SVG_TYPE.EXCHANGE} />,
    },
    {
      width: !isMobile ? '400' : '150',
      Cell: ({ original }) => (
        <div className="priceManagement_productName">
          <Translation data={original} />
        </div>
      ),
    },
    {
      width: !isMobile ? '100' : '40',
      Cell: ({ original }) => (
        <Settings
          id={original.word_uuid}
          settingsType={settingsType}
          setSettingsType={setSettingsType}
          formatMessage={formatMessage}
          data={original}
          remove
        />
      ),
      className: 'user-management__table__content--marginAuto',
    },
  ];

  const handleInputChange = e => {
    const {
      target: { value },
    } = e;
    value === ''
      ? getCustomTranslations({ country_uuid: menuType.options })
      : searchCustomTranslations({ search: value, country_id: menuType.options });
  };

  const deleteElement = async () => {
    console.log(settingsType.data);
    await deleteCustomTranslations(settingsType.data.translation_uuid);
    getCustomTranslations({ country_uuid: menuType.options });
  };

  const addElement = async values => {
    await addCustomTranslations(values);
    getCustomTranslations({ country_uuid: menuType.options });
  };

  const editElement = async values => {
    await editCustomTranstlaions(values);
    getCustomTranslations({ country_uuid: menuType.options });
  };

  return (
    <main className="dictionary">
      <div className="dictionary__top">
        <Title title={formatMessage({ id: 'global.dictionary' })} className="heading" fontStyle="default" />
        {authorizedUser.role === 'admin' && (
          <LanguageMenu menuType={menuType} setMenuType={setMenuType} formatMessage={formatMessage} />
        )}
        <SearchEngine
          placeholder={formatMessage({ id: 'global.search_word' })}
          className="search-field--advertisements filters__search-field"
          handleChange={handleInputChange}
        />
      </div>
      <div className="dictionary__content">
        <TableHeading
          menuType={menuType}
          addElement={addElement}
          formatMessage={formatMessage}
          settingsType={settingsType}
          setSettingsType={setSettingsType}
          editElement={editElement}
          restricted_word={restricted_word}
          isMobile={isMobile}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
        {isMobile && (
          <Button
            onClick={() => setIsModalOpen(true)}
            label={formatMessage({ id: 'inputs.add_plus' })}
            className="btn--gray categoryManagement__header--button btn--dictionary"
          />
        )}
        <div className="user-management__table__content dictionary__table__contntet">
          {customTranslations.translations && (
            <Table data={customTranslations?.translations} formatMessage={formatMessage} columns={getColumns()} rows={8} />
          )}
        </div>
        <DeleteDictionaryModal
          isOpened={settingsType.type === 'delete'}
          formatMessage={formatMessage}
          close={() => setSettingsType({ active: '', id: '' })}
          deleteElement={() => deleteElement()}
        />
      </div>
    </main>
  );
};

export default Dictionary;

Dictionary.propTypes = {
  actions: shape({
    addCustomTranslations: func.isRequired,
    getCustomTranslations: func.isRequired,
  }).isRequired,
  authorizedUser: object.isRequired,
  customTranslations: object.isRequired,
  formatMessage: func.isRequired,
  original: object.isRequired,
};

import * as Connector from 'shared/connectors/userConnector';
import { USER } from 'store/types';

const init = () => ({
  type: USER.SET_PASSWORD_INIT,
});

export const success = () => ({
  type: USER.SET_PASSWORD_SUCCESS,
});

const failure = () => ({
  type: USER.SET_PASSWORD_FAILURE,
});

const setPassword = dataForm => async dispatch => {
  dispatch(init());
  try {
    const data = await Connector.setPassword(dataForm);
    dispatch(success());
    sessionStorage.removeItem('pass');
    return data;
  } catch (err) {
    dispatch(failure());
    return false;
  }
};

export default setPassword;

/* eslint-disable react/no-access-state-in-setstate */
import store from 'store/store';
import * as React from 'react';
import { string, bool, number, object, func } from 'prop-types';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

class PhotoField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fileArr: [],
      base64Arr: [],
      empty: 8,
      isError: false,
      toSend: [],
    };
  }

  componentDidMount() {
    // get state from preview
    store.getState().productsStore.productPreview.data?.files &&
      this.setState({ fileArr: store.getState().productsStore.productPreview.data.fileArr });
    store.getState().productsStore.productPreview.data?.files &&
      this.setState({ base64Arr: store.getState().productsStore.productPreview.data.base64 });
    store.getState().productsStore.productPreview.data?.files &&
      this.setState({ toSend: store.getState().productsStore.productPreview.data.files });

    if (this.props.data) {
      const { files } = this.props.data;
      const fileArrClone = [];
      files &&
        files[0]?.id &&
        files.forEach(file => {
          const obj = {
            id: file.id,
            image: file.path,
          };
          fileArrClone.push(obj);
        });
      files && files[0]?.id && this.setState({ fileArr: fileArrClone });

      if (fileArrClone.length >= 8 && fileArrClone.length < 16) {
        this.setState({ empty: this.state.empty });
      } else if (fileArrClone.length < 8) {
        const emptyViews = 8 - fileArrClone.length;
        this.setState({ empty: emptyViews });
      } else if (fileArrClone.length >= 16) {
        this.setState({ empty: 0 });
      }
      files &&
        files[0]?.id &&
        this.setState({
          fileArr: fileArrClone.length <= 16 ? fileArrClone : fileArrClone.splice(0, 16),
        });
    }
    // get state from data
    const { data } = this.props;
    data?.fileArr && this.setState({ fileArr: data?.fileArr });
    data?.base64 && this.setState({ base64Arr: data?.base64 });
    data?.photoInputValue && this.setState({ toSend: data?.photoInputValue });
  }

  render() {
    const { photoSend, setBase64, setFileArr, handlePhotoRemove } = this.props;
    const fileInputKey = this.props.field.name ? this.props.field.name : +new Date();
    const formClasses = 'add__photos ';
    const { fileArr, toSend } = this.state;
    const onChange = e => {
      // const { setFieldValue } = this.props.form; // unused statement
      const { files } = e.target;
      for (let idx = 0; idx < files.length; idx++) {
        if (
          files[idx].size > 10485760 ||
          (files[idx].type !== 'image/jpeg' && files[idx].type !== 'image/jpg' && files[idx].type !== 'image/png')
        ) {
          setTimeout(() => this.setState({ isError: true }), 1000);
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(files[idx]);
          reader.onloadend = () => {
            this.setState({ toSend: [...this.state.toSend, files[idx]] });
            const fileArrClone = [...this.state.fileArr, { image: URL.createObjectURL(files[idx]), id: files[idx].lastModified }];
            const base64ArrClone = [...this.state.base64Arr, reader.result];
            // setFieldValue(this.props.field.name, base64Arr);
            if (fileArrClone.length >= 8 && fileArrClone.length < 16) {
              this.setState({ empty: this.state.empty });
            } else if (fileArrClone.length < 8) {
              const emptyViews = 8 - fileArrClone.length;
              this.setState({ empty: emptyViews });
            } else if (fileArrClone.length >= 16) {
              this.setState({ empty: 0 });
            }
            this.setState({
              fileArr: fileArrClone.length <= 16 ? fileArrClone : fileArrClone.splice(0, 16),
              base64Arr: fileArrClone.length <= 16 ? base64ArrClone : fileArrClone.splice(0, 16),
            });
          };
          this.setState({ isError: false });
        }
      }
    };
    const renderPhotoTile = () => {
      const arr = [];
      for (let i = 0; i < this.state.empty; i++) {
        arr.push(
          <div className="add__photo">
            <SVG type={SVG_TYPE.ADD_PHOTO} />
          </div>,
        );
      }
      return arr;
    };

    const removePhoto = event => {
      const input = document.getElementById('photos');
      input.value = null;
      const selectedItem = event.target;
      const closestParent = selectedItem.parentNode.parentNode;
      const closestPhoto = closestParent.querySelector('.add__photo--img');
      const hrefAtribute = closestPhoto.getAttribute('src');
      const idAtribute = closestPhoto.getAttribute('id');
      const newArray = fileArr.filter(item => item.image !== hrefAtribute);
      handlePhotoRemove && handlePhotoRemove(idAtribute);
      this.setState({ fileArr: newArray });
      if (fileArr.length > 8) {
        this.setState({ empty: 1 });
      } else if (fileArr.length <= 8) {
        const emptyViews = 8 - fileArr.length;
        this.setState({ empty: emptyViews + 1 });
      }
      const afterRemove = toSend.filter(item => item.lastModified !== parseInt(idAtribute, 10));
      this.setState({ toSend: afterRemove });
    };
    photoSend(this.state.toSend);
    setBase64(this.state.base64Arr);
    setFileArr(this.state.fileArr);
    return (
      <div className={formClasses}>
        <div className="add__photos">
          {this.state.fileArr.map(e => {
            if (e) {
              return (
                <>
                  <div className="add__photo">
                    <div className="add__photo--remove__wrapper">
                      <div className="add__photo--remove" onClick={event => removePhoto(event)} />
                      <SVG type={SVG_TYPE.CLOSE} />
                    </div>
                    <img id={e.id} className="add__photo--img" src={e.image} alt="" />
                  </div>
                </>
              );
            }
            return null;
          })}
          {renderPhotoTile()}
          <input
            id={this.props.field.name}
            name={this.props.field.name}
            key={fileInputKey}
            type="file"
            accept=".jpg, .png, .jpeg"
            onChange={event => {
              onChange(event);
            }}
            // eslint-disable-next-line no-return-assign
            ref={ref => (this.fileInput = ref)}
            multiple={this.props.multiple}
            className="add__photo--input"
          />
        </div>
        {this.state.isError && (
          <p className="add__photos--error">{this.props.formatMessage({ id: 'validation.error_photo_addvertisement_input' })}</p>
        )}
      </div>
    );
  }
}
PhotoField.defaultProps = {
  data: null,
  max: undefined,
  min: undefined,
  multiple: false,
  wrapperClassName: undefined,
};
PhotoField.propTypes = {
  data: object,
  field: object.isRequired,
  // disabled: bool.isRequired,
  form: object.isRequired,
  formatMessage: func.isRequired,
  handlePhotoRemove: func.isRequired,
  label: string.isRequired,
  max: number,
  min: number,
  multiple: bool,
  photoSend: func.isRequired,
  photosToDelete: func.isRequired,
  setBase64: func.isRequired,
  setFileArr: func.isRequired,
  type: string.isRequired,
  wrapperClassName: string,
};
export default PhotoField;

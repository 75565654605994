import React, { useState } from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { string, object, func } from 'prop-types';
import { number } from 'yup/lib/locale';
import OutsideClickHandler from 'react-outside-click-handler';
import { bool } from 'yup';

const CourierItem = ({ id, setModalManagement, item, formatMessage, isMobile }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const { name, price, currency, files, is_worldwide,used} = item;
  const isUsed = used > 0
  return (
    <>
      <div className="courier__item" id={`courier-${id}`}>
        <div className="courier__item--logo">{files[0] && <img src={files[0].path} alt={files[0].file_name} />}</div>
        <p className="courier__item--name">{name}</p>
        <p className="courier__item--price">
          {price} <span>{currency}</span>
        </p>
        {!isMobile && (
          <p className="courier__item--name">
            {is_worldwide ? <SVG type={SVG_TYPE.CORRECT_CIRCLE} /> : <SVG type={SVG_TYPE.INCORRECT_CIRCLE} />}
          </p>
        )}
        <div
          className={`courier__item--settings ${isModalOpened ? 'courier__item--settings-disabled' : ''}`}
          onClick={() => setIsModalOpened(true)}
        >
          <SVG type={SVG_TYPE.DOTS} />
        </div>
        {isModalOpened && (
          <OutsideClickHandler display="flex" onOutsideClick={() => setIsModalOpened(false)}>
            <div className="courier__settings">
              <div
                onClick={() => {
                  setModalManagement({ which: 'edit', item });
                  setIsModalOpened(false);
                }}
              >
                {formatMessage({ id: 'global.edit' })}
              </div>
              <div
                className={!isUsed ? '' : 'courier__settings--disabled'}
                onClick={() => {
                  
                  !isUsed && setModalManagement({ which: 'delete', item });
                  !isUsed && setIsModalOpened(false);
                }}
              >
                {formatMessage({ id: 'inputs.delete' })}
                {!!isUsed && <article className='courier__settings--tooltip'>{formatMessage({ id: 'global.cannot_remove_delivery_option' })}</article>}
              </div>
            </div>
          </OutsideClickHandler>
        )}
      </div>
    </>
  );
};

export default CourierItem;

CourierItem.propTypes = {
  currency: string.isRequired,
  formatMessage: func.isRequired,
  id: number.isRequired,
  image: object.isRequired,
  isMobile: bool.isRequired,
  item: object.isRequired,
  name: string.isRequired,
  price: string.isRequired,
  setModalManagement: func.isRequired,
};

import React from 'react';
import { Modal, RegularField, ModalActions } from 'shared/components';
import { bool, func, object } from 'prop-types';
import * as Yup from 'yup';
import { MODAL_TYPE, VALIDATION_FIELD } from 'shared/consts';
import { Field, Formik } from 'formik';
import { isValidNip } from 'shared/utils';
import { string } from 'yup/lib/locale';

const BillingModal = ({ isOpened, onClose, formatMessage, addInvoiceData, country, getCountryUuidFromShort, data }) => {
  const onSubmit = async values => {
    onClose();
    await addInvoiceData({ ...values, country_uuid: getCountryUuidFromShort(country) });
  };
  const validation = () =>
    Yup.object().shape({
      city: Yup.string(VALIDATION_FIELD(formatMessage).REQUIRED)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .min(2, VALIDATION_FIELD(formatMessage).MIN_2)
        .max(50, VALIDATION_FIELD(formatMessage).MAX_50),
      company_name: Yup.string(VALIDATION_FIELD(formatMessage).REQUIRED)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .min(2, VALIDATION_FIELD(formatMessage).MIN_2)
        .max(50, VALIDATION_FIELD(formatMessage).MAX_50),
      email: Yup.string().email(VALIDATION_FIELD(formatMessage).EMAIL).required(VALIDATION_FIELD(formatMessage).REQUIRED),
      flat_number: Yup.string(VALIDATION_FIELD(formatMessage).REQUIRED).max(3, VALIDATION_FIELD(formatMessage).MAX_3),
      house_number: Yup.string(VALIDATION_FIELD(formatMessage).REQUIRED)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .max(3, VALIDATION_FIELD(formatMessage).MAX_3),
      nip: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .test({ message: VALIDATION_FIELD(formatMessage).WRONG_NIP, test: value => isValidNip(value) }),
      postcode: Yup.string(VALIDATION_FIELD(formatMessage).REQUIRED)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .test(
          'post-code',
          country === 'DE' ? VALIDATION_FIELD(formatMessage).POST_CODE_DE : VALIDATION_FIELD(formatMessage).POST_CODE,
          val => (val && country === 'DE' ? !!/^[0-9]{5}$/i.test(val) : /^[0-9]{2}-[0-9]{3}$/i.test(val)),
        )
        .test('germany-postcode', VALIDATION_FIELD(formatMessage).BAD_VALUE, val =>
          val && country === 'DE'
            ? !!/\b((?:0[1-46-9]\d{3})|(?:[1-357-9]\d{4})|(?:[4][0-24-9]\d{3})|(?:[6][013-9]\d{3}))\b/.test(val)
            : true,
        ),
      street: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .min(2, VALIDATION_FIELD(formatMessage).MIN_2)
        .max(50, VALIDATION_FIELD(formatMessage).MAX_50),
    });
  return (
    <>
      {isOpened && (
        <Modal
          className={`${MODAL_TYPE.BASIC} editUser`}
          onClose={() => {
            onClose();
            // setFileInputValue(undefined);
          }}
          opened={isOpened}
        >
          <div>
            {!data ? (
              <div className="modal__heading modal__heading--center">{formatMessage({ id: 'global.adding' })}</div>
            ) : (
              <div className="modal__heading modal__heading--center">{formatMessage({ id: 'global.editing' })}</div>
            )}
            <Formik
              validationSchema={validation()}
              initialValues={{
                company_name: data?.company_name || '',
                email: data?.email || '',
                nip: data?.nip || '',
                street: data?.street || '',
                house_number: data?.house_number || '',
                flat_number: data?.flat_number || '',
                postcode: data?.postcode || '',
                city: data?.city || '',
              }}
              onSubmit={onSubmit}
            >
              {({ handleSubmit, errors, handleBlur }) => (
                <form className="categoryManagement__addCategory__wrapper biling__modal" onSubmit={handleSubmit}>
                  <div className="courier__add">
                    <Field
                      type="text"
                      label={formatMessage({ id: 'inputs.company_name' })}
                      name="company_name"
                      component={RegularField}
                      errors={errors}
                      onBlur={handleBlur}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: 'inputs.email' })}
                      name="email"
                      component={RegularField}
                      errors={errors}
                      onBlur={handleBlur}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: 'global.nip' })}
                      name="nip"
                      component={RegularField}
                      errors={errors}
                      onBlur={handleBlur}
                    />
                    <Field
                      type="text"
                      label={`${formatMessage({ id: 'inputs.street' })}`}
                      name="street"
                      component={RegularField}
                      errors={errors}
                      onBlur={handleBlur}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: 'inputs.build_number' })}
                      name="house_number"
                      component={RegularField}
                      errors={errors}
                      onBlur={handleBlur}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: 'inputs.apartement_number' })}
                      name="flat_number"
                      component={RegularField}
                      errors={errors}
                      onBlur={handleBlur}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: 'inputs.post_code' })}
                      name="postcode"
                      component={RegularField}
                      errors={errors}
                      onBlur={handleBlur}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: 'inputs.city' })}
                      name="city"
                      component={RegularField}
                      errors={errors}
                      onBlur={handleBlur}
                    />

                    <ModalActions
                      formatMessage={formatMessage}
                      type={!data ? MODAL_TYPE.ADD_CATEGORY : MODAL_TYPE.EDIT_CATEGORY}
                      onClose={() => {
                        onClose();
                      }}
                      onSubmit={() => {
                        handleSubmit();
                      }}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
    </>
  );
};

export default BillingModal;

BillingModal.defaultProps = {
  edit: false,
  setMenuType: () => console.log(),
};

BillingModal.propTypes = {
  addInvoiceData: func.isRequired,
  authorizedUser: object.isRequired,
  country: string.isRequired,
  data: object.isRequired,
  deleteFiles: object.isRequired,
  edit: bool,
  editUser: func.isRequired,
  formatMessage: func.isRequired,
  getCountryUuidFromShort: func.isRequired,
  info: object.isRequired,
  isOpened: bool.isRequired,
  mobile: bool.isRequired,
  onClose: func.isRequired,
  setMenuType: func,
  user: object.isRequired,
};

/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Table, SVG } from 'shared/components';
import { MODAL_TYPE, SVG_TYPE } from 'shared/consts';
import { arrayOf, shape, func, object } from 'prop-types';
import { bool } from 'yup';
import { TransferTitle, TransferCountry, Settings, TransferStatus } from './table';
// import { deliveryCountryLabelShort } from 'shared/utils';
import { TRANSFER_OPTIONS } from '../utils/consts';
import BackOffModal from './BackOffModal';
import EditTransferModal from './EditTransferModal';
import DeleteModal from './DeleteModal';

const Transfers = ({ data, isMobile, formatMessage, editTransfer }) => {
  const [settingsType, setSettingsType] = useState({ active: '', id: '' });
  const defaultOpenModalState = { isOpened: false, data: {} };
  const [openModal, setOpenModal] = useState(defaultOpenModalState);

  const isBackOffModalOpened = openModal.isOpened === MODAL_TYPE.BACK_OFF;
  const isEditModalOpened = openModal.isOpened === MODAL_TYPE.EDIT_WAREHOUSE;
  const isDeleteModalOpened = openModal.isOpened === MODAL_TYPE.DELETE_WAREHOUSER;

  const getColumnsMobile = () => [
    {
      width: '150',
      Cell: ({ original }) => (
        <>
          <TransferTitle data={original} />
        </>
      ),
    },
    {
      width: '20',
      Cell: ({ original }) => (
        <>
          <TransferCountry data={original} />
        </>
      ),
    },
    {
      width: '20',
      Cell: () => (
        <>
          <SVG type={SVG_TYPE.ARROW_RIGHT_BLACK} />
        </>
      ),
    },
    {
      width: '40',
      Cell: ({ original }) => (
        <>
          <TransferCountry data={original} foreignCountry />
        </>
      ),
    },
    {
      width: '30',
      Cell: ({ original }) => <TransferStatus status={original.status} isMobile={isMobile} formatMessage={formatMessage} />,
    },
    {
      width: '50',
      Cell: ({ original }) => (
        <Settings
          id={original.id}
          settingsType={settingsType}
          setSettingsType={setSettingsType}
          setOpenModal={setOpenModal}
          formatMessage={formatMessage}
          data={original}
          options={TRANSFER_OPTIONS}
        />
      ),
      className: 'user-management__table__content--marginAuto',
    },
  ];

  const getColumns = () => [
    {
      width: '300',
      Cell: ({ original }) => (
        <>
          <TransferTitle data={original} />
        </>
      ),
    },
    {
      width: '190',
      Cell: ({ original }) => (
        <>
          <TransferCountry data={original} foreignCountry />
        </>
      ),
    },
    {
      width: '180',
      Cell: ({ original }) => (
        <>
          <TransferCountry data={original} />
        </>
      ),
    },
    {
      width: '120',
      accessor: 'amount',
      id: 'amount',
    },
    {
      width: '50',
      Cell: ({ original }) => (
        <>
          <TransferStatus status={original.status} date={original.updated_at} formatMessage={formatMessage} />
        </>
      ),
    },
    {
      width: '100',
      Cell: ({ original }) => (
        <>
          {original.status !== 'arrived' && (
            <Settings
              setOpenModal={setOpenModal}
              id={original.id}
              settingsType={settingsType}
              setSettingsType={setSettingsType}
              formatMessage={formatMessage}
              data={original}
              options={TRANSFER_OPTIONS}
            />
          )}
        </>
      ),
      className: 'user-management__table__content--marginAuto',
    },
  ];
  return (
    <>
      <div className="warehouse-management__table--wrapper">
        {!isMobile && (
          <div className="warehouse-management__table__heading">
            <p className="warehouse-management__table__heading--transfersProduct">{formatMessage({ id: 'global.product' })}</p>
            <p className="warehouse-management__table__heading--exportCountry">
              {formatMessage({ id: 'global.export_country' })}
            </p>
            <p className="warehouse-management__table__heading--importCountry">
              {formatMessage({ id: 'global.import_country' })}
            </p>
            <p className="warehouse-management__table__heading--importCountry">{formatMessage({ id: 'global.amount' })}</p>
            <p className="warehouse-management__table__heading--transferStatus">
              {formatMessage({ id: 'global.transfer_status' })}
            </p>
          </div>
        )}
        <div className="user-management__table__content">
          {data && data.length > 0 && <Table data={data} columns={!isMobile ? getColumns() : getColumnsMobile()} />}
        </div>
      </div>
      <BackOffModal
        opened={isBackOffModalOpened}
        onClose={() => setOpenModal({ isOpened: null, data: {} })}
        formatMessage={formatMessage}
      />
      <EditTransferModal
        opened={isEditModalOpened}
        onClose={() => setOpenModal({ isOpened: null, data: {} })}
        formatMessage={formatMessage}
        data={openModal}
        onSubmit={editTransfer}
      />
      <DeleteModal
        opened={isDeleteModalOpened}
        onClose={() => setOpenModal({ isOpened: null, data: {} })}
        formatMessage={formatMessage}
      />
    </>
  );
};

export default Transfers;

Transfers.propTypes = {
  data: arrayOf(shape({})).isRequired,
  deliveryCountries: func.isRequired,
  editUser: func.isRequired,
  formatMessage: func.isRequired,
  getUserDetails: func.isRequired,
  isMobile: bool.isRequired,
  userDetails: object.isRequired,
  userSuspension: func.isRequired,
};

import * as Connector from 'shared/connectors/roomsConnector';
import { ROOM } from 'store/types';
import getRoomMessages from './getRoomMessages';

const init = () => ({
  type: ROOM.SEND_MESSAGE_INIT,
});

export const success = data => ({
  type: ROOM.SEND_MESSAGE_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: ROOM.SEND_MESSAGE_FAILURE,
});

const sendMessage = data => async dispatch => {
  dispatch(init());
  try {
    await Connector.sendMessage(data);
    const {room_uuid} = data
    // dispatch(success(response.data.created_message));
    dispatch(getRoomMessages({ room_uuid, page: 1},true))
    document.getElementById('chat__messages__list').scroll({ top: 10000, behavior: 'smooth' });
    return true;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default sendMessage;

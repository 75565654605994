import { connect } from 'react-redux';
import { isMobile as checkIsMobile } from 'shared/utils';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { getSingleProduct, markSingleProductFavorite, unmarkSingleProductFavorite } from 'store/actions/products';
import { getCategoryByLink } from 'store/actions/category';
import { getShoppingCart as getShoppingCartSelector } from 'store/selectors/shoppingCartSelector';
import { getSingleProduct as getSingleProductSelector } from 'store/selectors/productsSelector';
import { getAuthorizedUser } from 'store/selectors/userSelector';
import { modifyShoppingCart } from 'store/actions/shopping-cart';
import { getDeliveryCountries } from 'store/actions/delivery';
import * as ViewManagementSelector from 'store/selectors/viewManagementSelector';
import { getDeliveryCountries as getDeliveryCountriesSelector } from 'store/selectors/deliverySelector';
import {
  getCategoryByLink as getCategoryByLinkSelector,
  getCategoryAndSubcategory as getCategoryAndSubcategorySelector,
} from 'store/selectors/categorySelector';
import SingleProduct from './SingleProduct.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      modifyShoppingCart: uuid => modifyShoppingCart(uuid),
      getSingleProduct: (uuid, countryUuid, userUuid) => getSingleProduct(uuid, countryUuid, userUuid),
      getCategoryByLink: uuid => getCategoryByLink(uuid),
      markSingleProductFavorite: uuid => markSingleProductFavorite(uuid),
      unmarkSingleProductFavorite: uuid => unmarkSingleProductFavorite(uuid),
      getDeliveryCountries: () => getDeliveryCountries(),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  authorizedUser: getAuthorizedUser(state),
  isMobile: checkIsMobile(),
  shoppingCart: getShoppingCartSelector(state),
  singleProduct: getSingleProductSelector(state),
  categoryByLink: getCategoryByLinkSelector(state),
  categories: getCategoryAndSubcategorySelector(state),
  translation: ViewManagementSelector.getTranslation(state),
  deliveryCountries: getDeliveryCountriesSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SingleProduct));

import { connect } from 'react-redux';
import { isMobile as checkIsMobile } from 'shared/utils';
import { bindActionCreators } from 'redux';
import { getFavoriteProducts, deleteFollowedProduct } from 'store/actions/products';
import { getFavoriteProducts as getFavoriteProductsSelector } from 'store/selectors/productsSelector';
import { getAuthorizedUser } from 'store/selectors/userSelector';
import { getTranslation } from 'store/selectors/viewManagementSelector';
import { injectIntl } from 'react-intl';
import WatchedProducts from './WatchedProducts.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getFavoriteProducts: () => getFavoriteProducts(),
      deleteFollowedProduct: uuid => deleteFollowedProduct(uuid),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  isMobile: checkIsMobile(),
  favoriteProducts: getFavoriteProductsSelector(state),
  authorizedUser: getAuthorizedUser(state),
  translation: getTranslation(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WatchedProducts));

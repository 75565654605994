const CUSTOM_TRANSLATIONS = {
  ADD_CUSTOM_TRANSLATIONS_SUCCESS: 'ADD_CUSTOM_TRANSLATIONS_SUCCESS',
  ADD_CUSTOM_TRANSLATIONS_FAILURE: 'ADD_CUSTOM_TRANSLATIONS_FAILURE',
  GET_CUSTOM_TRANSLATIONS_SUCCESS: 'GET_CUSTOM_TRANSLATIONS_SUCCESS',
  GET_CUSTOM_TRANSLATIONS_FAILURE: 'GET_CUSTOM_TRANSLATIONS_FAILURE',
  EDIT_CUSTOM_TRANSLATIONS_SUCCESS: 'EDIT_CUSTOM_TRANSLATIONS_SUCCESS',
  EDIT_CUSTOM_TRANSLATIONS_FAILURE: 'EDIT_CUSTOM_TRANSLATIONS_FAILURE',
  SEARCH_TRANSLATIONS_SUCCESS: 'SEARCH_TRANSLATIONS_SUCCESS',
  SEARCH_TRANSLATIONS_FAILURE: 'SEARCH_TRANSLATIONS_FAILURE',
};

export default CUSTOM_TRANSLATIONS;

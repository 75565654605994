/* eslint react/prop-types: 0 */

import React from 'react';

const SellStatus = ({ data }) => (
  <div>
    <span>{data?.sale_status}</span>
  </div>
);

export default SellStatus;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect } from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

const Breadcrumbs = ({ back, className, categories, formatMessage, translation, data, preview,isEdit }) => {
  const { pathname, search } = window.location;
  const getMainCategoryNameFromSearching = () => {
    const urlParams = new URLSearchParams(search);
    const mainCat = urlParams.get('main_cat');
    return mainCat || '';
  };
  const getSubCategoryNameFromSearching = () => {
    const urlParams = new URLSearchParams(search);
    const cat = urlParams.get('cat');
    return cat || '';
  };
  const history = useHistory();
  const category = (pathname.split('/')[2] && decodeURIComponent(pathname.split('/')[2])) || getMainCategoryNameFromSearching();
  const product = decodeURIComponent(pathname.split('/')[3]);
  const currentCategory = categories?.category?.filter(
    item => item?.subcategories.filter(el => el.link === category || el.link === category).length === 1 || item.link === category,
  )[0];
  const currentSubCategory = currentCategory?.subcategories.filter(
    el => el.link === category || el.link === getSubCategoryNameFromSearching(),
  )[0];
  const currentCategoryName = category && translation === 'pl' ? currentCategory?.name : currentCategory?.name_ger;
  const currentSubCategoryName = category && translation === 'pl' ? currentSubCategory?.name : currentSubCategory?.name_ger;
  const currentProductName = data && translation === 'pl' ? data?.title : data?.title_ger;
  const handleMoveto = link => {
    history.push(link);
  };
  useEffect(() => { }, [categories]);
  
  const handleBackInPreview = () => {
    isEdit? history.push({pathname:'/edit-advertisement',state:{data}}):history.push('/add-advertisement')
}

  return (
    <div className={`breadcrumbs ${className}`}>
      {back && (
        <div
          className="breadcrumbs__item breadcrumbs__item--first"
          onClick={() => preview? handleBackInPreview() :history.push(`/category?s=&main_cat=${currentCategory.link}&cat=${currentSubCategory.link}`)}
        >
          <SVG className="breadcrumbs--icon" type={SVG_TYPE.CHEVRONE} />
          <FormattedMessage id="global.go_back" />
        </div>
      )}
      {!preview && (
        <p className="breadcrumbs__item" onClick={() => handleMoveto('/')}>
          {formatMessage({ id: 'global.home_page2' })} &gt;
        </p>
      )}
      {!preview && currentCategoryName && (
        <p
          className="breadcrumbs__item"
          onClick={() => currentSubCategoryName && handleMoveto(`/category?s=&main_cat=${currentCategory.link}`)}
        >
          {currentCategoryName} &gt;
        </p>
      )}
      {/* {currentSubCategoryName && <p className="breadcrumbs__item" onClick={() => product && handleMoveto(`/category?s=&main_cat=${currentCategory.link}&cat=${currentSubCategory.link}`)}>{currentSubCategoryName} &gt;</p>} */}
      {!preview && product && <p className="breadcrumbs__item">{currentProductName}</p>}
    </div>
  );
};

export default Breadcrumbs;

Breadcrumbs.defaultProps = {
  back: false,
  categories: {},
  className: '',
  data: {},
  formatMessage: () => {},
  isEdit: false,
  preview: false,
  translation: 'pl',
};

Breadcrumbs.propTypes = {
  back: PropTypes.bool,
  categories: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
  formatMessage: PropTypes.func,
  isEdit: PropTypes.bool,
  preview: PropTypes.bool,
  translation: PropTypes.string,
};

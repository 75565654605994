import React, { useState } from 'react';
import { string, object, func, bool } from 'prop-types';
import { LANGUAGE_TYPE } from 'shared/consts';
import { DeleteDeliveryMethod, AddCourierModal } from 'screens/delivery-options/components';
import CourierItem from '../courier-item';

const Couriers = ({
  menuType,
  delivery,
  formatMessage,
  editDeliveryMethod,
  deleteDeliveryMethod,
  deleteFiles,
  deliveryCountries,
  isMobile,
}) => {
  const [modalManagement, setModalManagement] = useState({ which: '' });
  return (
    <>
      <div className="courier__list">
        {delivery  &&
          delivery.map((item, idx) => (
            <CourierItem
              isMobile={isMobile}
              formatMessage={formatMessage}
              setModalManagement={setModalManagement}
              id={idx}
              item={item}
            />
          ))}
        {menuType === LANGUAGE_TYPE[1].label &&
          delivery.map((item, idx) => (
            <CourierItem
              isMobile={isMobile}
              formatMessage={formatMessage}
              setModalManagement={setModalManagement}
              id={idx}
              item={item}
            />
          ))}
      </div>
      <DeleteDeliveryMethod
        deleteDeliveryMethod={deleteDeliveryMethod}
        formatMessage={formatMessage}
        info={modalManagement}
        onClose={() => setModalManagement({ which: false })}
      />
      <AddCourierModal
        editCourier
        edit={editDeliveryMethod}
        deleteFiles={deleteFiles}
        info={modalManagement}
        userInfo={modalManagement}
        deliveryCountries={deliveryCountries}
        // addDeliveryMethod={addDeliveryMethod}
        formatMessage={formatMessage}
        isOpened={modalManagement.which === 'edit'}
        onClose={() => setModalManagement({ which: '' })}
        mobile={isMobile}
      />
    </>
  );
};

export default Couriers;

Couriers.propTypes = {
  deleteDeliveryMethod: func.isRequired,
  deleteFiles: func.isRequired,
  delivery: object.isRequired,
  deliveryCountries: object.isRequired,
  editDeliveryMethod: object.isRequired,
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
  menuType: string.isRequired,
  userInfo: object.isRequired,
};

import React from 'react';
// import { SVG } from 'shared/components';
// import { SVG_TYPE } from 'shared/consts';
import PropTypes, { string,object } from 'prop-types';
import { history } from 'store/store';

const SingleCategoryBar = ({ quantity, label, list, value, all, data }) => {
  const handleOnClick = (e) => {
    value ? list(e, value) : list && list(e)
    data.link && history.push(`/category/?s=&main_cat=${data.category.link}&cat=${data.link}`)
  }
  return (
    <div
      className={`single-category-bar ${list ? 'single-category-bar__list' : ''} ${value ? `category-value--${value}` : ''}`}
      // onClick={e => { (value ? list(e, value) : list && list(e)) }}
      onClick={e =>handleOnClick(e)}
    >
      <p className={`single-category-bar--name ${all ? 'single-category-bar--name--all' : ''} `}>
        {/* <SVG
          className={`single-category-bar--chevrone ${list && 'single-category-bar--chevrone--down'}`}
          type={SVG_TYPE.CHEVRONE}
        /> */}
        <span className="none">{value}</span>
        {label}
      </p>
      <p className="single-category-bar--quantity"> {quantity}</p>
    </div>
  );
}

export default SingleCategoryBar;

SingleCategoryBar.defaultProps = {
  all: false,
  data:{},
  list: false,
  value: '',
};
SingleCategoryBar.propTypes = {
  all: PropTypes.bool,
  data: object,
  label: PropTypes.string.isRequired,
  list: PropTypes.bool,
  quantity: PropTypes.number.isRequired,
  value: string,
};

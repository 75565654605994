import React from 'react';
import { MODAL_TYPE, ROUTES } from 'shared/consts';
import { string, func } from 'prop-types';
import { Button } from 'shared/components';
import { useHistory } from 'react-router-dom';

const ModalActions = ({ type, onClose, onSubmit, formatMessage, className }) => {
  const history = useHistory();

  return (
    <div className="modal-actions">
      {type === MODAL_TYPE.CANCEL && (
        <div className="modal-actions__content">
          <Button
            label={formatMessage({ id: 'inputs.cancel' })}
            className="btn--orange-border"
            onClick={() => history.push(ROUTES.ADVERTISEMENTS)}
          />
          <Button label={formatMessage({ id: 'inputs.back' })} className="btn--orange" onClick={onClose} />
        </div>
      )}
      {type === MODAL_TYPE.BACK_OFF && (
        <div className="modal-actions__content">
          <Button label={formatMessage({ id: 'inputs.cancel' })} className="btn--orange-border" onClick={onClose} />
          <Button label={formatMessage({ id: 'inputs.back_off' })} className="btn--orange" onClick={onSubmit} />
        </div>
      )}
      {type === MODAL_TYPE.RESUME && (
        <div className="modal-actions__content">
          <Button label={formatMessage({ id: 'inputs.cancel' })} className="btn--orange-border" onClick={onClose} />
          <Button label={formatMessage({ id: 'inputs.resume' })} className="btn--orange" onClick={onSubmit} />
        </div>
      )}
      {type === MODAL_TYPE.ADD_CATEGORY && (
        <div className={`modal-actions__content modal-actions__content--${MODAL_TYPE.ADD_CATEGORY} ${className}`}>
          <Button label={formatMessage({ id: 'inputs.cancel' })} className="btn--orange-border" onClick={onClose} />
          <Button label={formatMessage({ id: 'inputs.add_plus' })} className="btn--orange" onClick={onSubmit} />
        </div>
      )}
      {type === MODAL_TYPE.EDIT_COURIER && (
        <div className={`modal-actions__content modal-actions__content--${MODAL_TYPE.ADD_CATEGORY} ${className}`}>
          <Button label={formatMessage({ id: 'inputs.cancel' })} className="btn--orange-border" onClick={onClose} />
          <Button label={formatMessage({ id: 'inputs.save' })} className="btn--orange" onClick={onSubmit} />
        </div>
      )}
      {type === MODAL_TYPE.EDIT_WAREHOUSE && (
        <div className={`modal-actions__content modal-actions__content--${MODAL_TYPE.EDIT_WAREHOUSE} ${className}`}>
          <Button label={formatMessage({ id: 'inputs.cancel' })} className="btn--orange-border" onClick={onClose} />
          <Button label={formatMessage({ id: 'inputs.save' })} className="btn--orange" onClick={onSubmit} />
        </div>
      )}
      {type === MODAL_TYPE.EDIT_CATEGORY && (
        <div className={`modal-actions__content modal-actions__content--${MODAL_TYPE.ADD_CATEGORY}`}>
          <Button label={formatMessage({ id: 'inputs.cancel' })} className="btn--orange-border" onClick={onClose} />
          <Button label={formatMessage({ id: 'inputs.edit' })} className="btn--orange" onClick={onSubmit} />
        </div>
      )}
      {type === MODAL_TYPE.DELETE_MODAL && (
        <div className="modal-actions__content">
          <Button label={formatMessage({ id: 'inputs.cancel' })} className="btn--orange-border" onClick={onClose} />
          <Button label={formatMessage({ id: 'inputs.delete' })} className="btn--orange" onClick={onSubmit} />
        </div>
      )}
      {type === MODAL_TYPE.EDIT_PRICE && (
        <div className="modal-actions__content">
          <Button label={formatMessage({ id: 'inputs.cancel' })} className="btn--orange-border" onClick={onClose} />
          <Button label={formatMessage({ id: 'inputs.save' })} className="btn--orange" onClick={onSubmit} />
        </div>
      )}
      {type === MODAL_TYPE.ASSIGN && (
        <div className={`modal-actions__assign-client ${className}`}>
          <Button label={formatMessage({ id: 'global.discard' })} className="btn--orange-border" onClick={onClose} />
          <Button label={formatMessage({ id: 'global.accept' })} className="btn--orange" onClick={onSubmit} />
        </div>
      )}
      {type === MODAL_TYPE.ASSIGN_PARTNER && (
        <div className={`modal-actions__assign-client ${className}`}>
          <Button label={formatMessage({ id: 'inputs.cancel' })} className="btn--orange-border" onClick={onClose} />
          <Button label={formatMessage({ id: 'global.accept' })} className="btn--orange" onClick={onSubmit} />
        </div>
      )}
      {type === MODAL_TYPE.PARTNER && (
        <div className="modal-actions__partner">
          <Button label={formatMessage({ id: 'inputs.send_application' })} className="btn--orange" onClick={onSubmit} />
        </div>
      )}
      {type === MODAL_TYPE.EDIT_PARTNER && (
        <div className="modal-actions__partner">
          <Button label={formatMessage({ id: 'inputs.cancel' })} className="btn--orange-border" onClick={onClose} />
          <Button label={formatMessage({ id: 'inputs.save' })} className="btn--orange" onClick={onSubmit} />
        </div>
      )}
      {type === MODAL_TYPE.ADVERTISEMENTS && (
        <div className="modal-actions__partner">
          <Button label={formatMessage({ id: 'inputs.back' })} className="btn--orange-border" onClick={onClose} />
        </div>
      )}
      {type === MODAL_TYPE.COOKIE_ACCEPT && (
        <div className="modal-actions__partner">
          <Button
            label={`${formatMessage({ id: 'inputs.save' })} ${formatMessage({ id: 'menu.settings' })}`}
            className="btn--orange"
            onClick={onSubmit}
          />
        </div>
      )}
    </div>
  );
};

export default ModalActions;

ModalActions.defaultProps = {
  className: '',
  onSubmit: () => console.log('test'),
};

ModalActions.propTypes = {
  className: string,
  formatMessage: func.isRequired,
  onClose: func.isRequired,
  onSubmit: func,
  type: string.isRequired,
};

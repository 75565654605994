import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as ViewManagementSelector from 'store/selectors/viewManagementSelector';
import { bindActionCreators } from 'redux';
import { getAuthorizedUser } from 'store/selectors/userSelector';
import { getShoppingCart as getShoppingCartSelector } from 'store/selectors/shoppingCartSelector';
import { getShoppingCart, modifyShoppingCart } from 'store/actions/shopping-cart';
import { addToFavoritesCheckout, removeFromFavoritesCheckout } from 'store/actions/products';
import getCheckoutAddress from 'store/actions/auth/getCheckoutAddress';
import Basket from './Basket.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getShoppingCart: () => getShoppingCart(),
      modifyShoppingCart: uuid => modifyShoppingCart(uuid),
      addToFavoritesCheckout: uuid => addToFavoritesCheckout(uuid),
      removeFromFavoritesCheckout: (uuid, categoryId, subcategoryId) =>
        removeFromFavoritesCheckout(uuid, categoryId, subcategoryId),
      getCheckoutAddress: () => getCheckoutAddress(),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  authorizedUser: getAuthorizedUser(state),
  translation: ViewManagementSelector.getTranslation(state),
  shoppingCart: getShoppingCartSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Basket));

/* eslint-disable no-await-in-loop */
import React from 'react';
import * as Connector from 'shared/connectors/productsConnector';
import * as ConnectorUtils from 'shared/connectors/utilsConnector';
import { PRODUCTS } from 'store/types';
import { NOTIFY_TYPE } from 'shared/consts';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
// import { addFiles } from 'shared/connectors/utilsConnector';

const init = () => ({
  type: PRODUCTS.EDIT_ADVERTISEMENT_INIT,
});

export const success = data => ({
  type: PRODUCTS.EDIT_ADVERTISEMENT_SUCCESS,
  payload: data,
});

const failure = () => ({
  type: PRODUCTS.EDIT_ADVERTISEMENT_FAILURE,
});

const editAdvertisements = ({ uuid, form, photos, photoToDeleteUuid }) => async dispatch => {
  dispatch(init());
  try {
    await Connector.editAdvertisement(uuid, form);
    if (uuid && photos && photos.length > 0) {
      for (let i = 0; i < photos.length; i++) {
        const fileForm = new FormData();
        fileForm.append('file', photos[i]);

        await ConnectorUtils.addFiles(fileForm, uuid, 'product_uuid=');
      }
    }
    if (uuid && photoToDeleteUuid && photoToDeleteUuid.length > 0) {
      for (let i = 0; i < photoToDeleteUuid.length; i++) {
        await ConnectorUtils.deleteFiles(photoToDeleteUuid[i]);
      }
      // eslint-disable-next-line no-return-await
      photoToDeleteUuid.forEach(async item => await ConnectorUtils.deleteFiles(item));
    }
    dispatch(success(form));
    toast(<Notify label="global.successful_edit_product" type={NOTIFY_TYPE.CHECKED} />);
    return 'success';
  } catch (err) {
    dispatch(failure());
    console.log(err.response);
    return err.response;
  }
};

export default editAdvertisements;

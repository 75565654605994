import React from 'react';
import * as Connector from 'shared/connectors/productsConnector';
import { PRODUCTS } from 'store/types';
import { NOTIFY_TYPE } from 'shared/consts';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';

export const success = uuid => ({
  type: PRODUCTS.RESUME_THE_PRODUCTS_SUCCESS,
  payload: {
    uuid,
  },
});

const resumeTheProduct = uuid => async dispatch => {
  try {
    await Connector.resumeTheProduct(uuid);
    toast(<Notify label="global.product_resumed" type={NOTIFY_TYPE.CHECKED} />);
    dispatch(success(uuid));
    return true;
  } catch (err) {
    return err.response;
  }
};

export default resumeTheProduct;

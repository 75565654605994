/* eslint-disable react/prop-types */
import React from 'react';
import { array, bool, func } from 'prop-types';
import { OrderItem, EmptyView } from 'screens/orders/components';
import { Table } from 'shared/components';

const OrdersList = ({ data, formatMessage, isMobile, createCheckoutSession }) => {
  const getColumns = () => [
    {
      maxWidth: 1038,
      Cell: ({ original }) => (
        <>
          <OrderItem
            createCheckoutSession={createCheckoutSession}
            formatMessage={formatMessage}
            data={original}
            isMobile={isMobile}
          />
        </>
      ),
    },
  ];
  return (
    <>
      <div className="my-orders--table--wrapper warehouse-management__table--wrapper warehouse-management__table--wrapper--order-item">
        <div className="user-management__table__content my-orders--table">
          {data && data.length > 0 ? (
            <Table rows={3} data={data} columns={getColumns()} />
          ) : (
            <EmptyView formatMessage={formatMessage} />
          )}
        </div>
      </div>
    </>
  );
};

export default OrdersList;

OrdersList.propTypes = {
  data: array.isRequired,
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
};



const orderByOptions = (formatMessage) => {
    const arr = [
        {
            label: formatMessage({ id: 'global.desc' }),
            searchableLabel: formatMessage({ id: 'global.desc' }),
            value: 'desc',
        },
        {
            label: formatMessage({ id: 'global.asc' }),
            searchableLabel: formatMessage({ id: 'global.asc' }),
            value: 'asc',
        }
    ];

    return arr;
  };
  
  export default orderByOptions;
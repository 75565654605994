import { connect } from 'react-redux';
import { isMobile as checkIsMobile } from 'shared/utils';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import {
  addProductPreview,
  addProducts,
  editAdvertisements,
  getSingleProduct,
  markSingleProductFavorite,
  unmarkSingleProductFavorite,
} from 'store/actions/products';
import { getCategoryByLink } from 'store/actions/category';
import { getSingleProduct as getSingleProductSelector, productPreviewSelector } from 'store/selectors/productsSelector';
import { getAuthorizedUser } from 'store/selectors/userSelector';
import { getCarBrands as getCarBrandsSelector, getCarModels as getCarModelsSelector } from 'store/selectors/carSelector';
import { getDelivery as getDeliverySelector } from 'store/selectors/deliverySelector';
import * as ViewManagementSelector from 'store/selectors/viewManagementSelector';
import {
  getCategoryByLink as getCategoryByLinkSelector,
  getCategoryAndSubcategory as getCategoryAndSubcategorySelector,
} from 'store/selectors/categorySelector';
import { modifyShoppingCart } from 'store/actions/shopping-cart';
import SingleProduct from './ProductPreview';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getSingleProduct: (uuid, countryUuid, userUuid) => getSingleProduct(uuid, countryUuid, userUuid),
      getCategoryByLink: uuid => getCategoryByLink(uuid),
      modifyShoppingCart: uuid => modifyShoppingCart(uuid),
      markSingleProductFavorite: uuid => markSingleProductFavorite(uuid),
      unmarkSingleProductFavorite: uuid => unmarkSingleProductFavorite(uuid),
      addProducts: (data, photos) => addProducts(data, photos),
      addProductPreview: data => addProductPreview(data),
      editAdvertisements: (uuid, data, photos) => editAdvertisements(uuid, data, photos),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  authorizedUser: getAuthorizedUser(state),
  isMobile: checkIsMobile(),
  singleProduct: getSingleProductSelector(state),
  categoryByLink: getCategoryByLinkSelector(state),
  categories: getCategoryAndSubcategorySelector(state),
  translation: ViewManagementSelector.getTranslation(state),
  productPreview: productPreviewSelector(state),
  delivery: getDeliverySelector(state),
  carBrands: getCarBrandsSelector(state),
  carModels: getCarModelsSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SingleProduct));

/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import Label from './components/label';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

const RegularField = ({
  type,
  field: { name, onChange, value },
  field,
  form,
  errors,
  placeholder,
  label,
  fullWidth,
  className,
  onBlur,
  maxLength,
  inputOnChange,
  noEye,
  search,
}) => {
  const isError = errors && !!(form.touched[name] && errors[name]);
  const [isEyeVisible, setIsEyeVisible] = useState(false);
  const [isClearSearch, setIsClearSearch] = useState(false);
  if (isClearSearch) {
    value = '';
  }
  const fieldClasses = `regular-field__input ${isError ? 'regular-field__input--error' : ''} ${className}`;
  return (
    <div className="regular-field">
      <div className={`regular-field__container ${fullWidth ? 'regular-field__container--full-width' : ''}`}>
        {/* <Label errors={errors} field={field} isError={isError} label={label} /> */}
        <div className={fieldClasses}>
          {type && type === 'textarea' ? (
            <>
              {label && (
                <label className="regular-field__label" htmlFor={field.name}>
                  {label}
                </label>
              )}
              <textarea
                id={`validate--${name}`}
                name={name}
                placeholder={placeholder}
                onBlur={onBlur}
                value={value}
                onChange={e => {
                  onChange(e);
                  inputOnChange && inputOnChange(e);
                }}
              />
              {errors && form.touched && (
                <div className="regular-field__label-error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>
              )}
            </>
          ) : type && type === 'password' ? (
            <>
              {label && (
                <label className="regular-field__label" htmlFor={field.name}>
                  {label}
                </label>
              )}
              <input
                id={`validate--${name}`}
                autoComplete={name === 'password' ? 'on' : 'new-password'}
                name={name}
                // onChange={onChange}
                onChange={e => {
                  onChange(e);
                  inputOnChange && inputOnChange(e);
                }}
                placeholder={placeholder}
                type={isEyeVisible ? 'text' : type}
                value={value}
                onBlur={onBlur}
                maxLength={maxLength}
              />
              {!noEye && (
                <div className={`eye ${isEyeVisible && 'eye--close'}`} onClick={() => setIsEyeVisible(!isEyeVisible)}>
                  {isEyeVisible ? <SVG type={SVG_TYPE.EYE_CLOSE} /> : <SVG type={SVG_TYPE.EYE} />}
                </div>
              )}
              {errors && form.touched && (
                <div className="regular-field__label-error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>
              )}
            </>
          ) : (
            <>
              {label && (
                <label className="regular-field__label" htmlFor={field.name}>
                  {label}
                </label>
              )}
              <input
                id={`validate--${name}`}
                autoComplete={name === 'password' ? 'on' : 'new-password'}
                name={name}
                // onChange={onChange}
                onChange={e => {
                  onChange(e);
                  inputOnChange && inputOnChange(e);
                  search && setIsClearSearch(false);
                }}
                placeholder={placeholder}
                type={type}
                value={value}
                onBlur={onBlur}
                maxLength={maxLength}
              />
              {search && value.length > 0 && (
                <div onClick={() => setIsClearSearch(true)}>
                  <SVG type={SVG_TYPE.CLOSE} className="svg--close-orange" />
                </div>
              )}
              {errors && form.touched && (
                <div className="regular-field__label-error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
RegularField.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.object.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  form: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  inputOnChange: PropTypes.func,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  noEye: PropTypes.bool,
  onBlur: PropTypes.string,
  placeholder: PropTypes.string,
  search: PropTypes.bool,
  type: PropTypes.string,
};

RegularField.defaultProps = {
  className: '',
  fullWidth: false,
  inputOnChange: null,
  label: '',
  maxLength: null,
  noEye: false,
  onBlur: null,
  placeholder: '',
  search: false,
  type: 'text',
};

export default RegularField;

import { SHOPPING_CART, PRODUCTS } from 'store/types';
import { REQUEST_STATUS } from 'shared/consts';

const INITIAL_STATE = {
  shoppingCart: {
    data: [],
  },
  shoppingCartOwner: {
    data: [],
  },
  singleProduct: {
    data: {},
  },
};

const shoppingCartReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case SHOPPING_CART.ADD_FAVORITES: {
      const shoppingCart = state.shoppingCart.data;
      const element = shoppingCart.shopping_cart.products.find(item => item.uuid === payload.uuid);
      element.is_favorite = true;
      return {
        ...state,
        shoppingCart: {
          data: { ...shoppingCart },
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case SHOPPING_CART.REMOVE_FAVORITES: {
      const shoppingCart = state.shoppingCart.data;
      const element = shoppingCart.shopping_cart.products.find(item => item.uuid === payload.uuid);
      element.is_favorite = false;
      return {
        ...state,
        shoppingCart: {
          data: { ...shoppingCart },
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }

    case SHOPPING_CART.GET_SHOPPING_CART_INIT: {
      return {
        ...state,
        shoppingCart: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case PRODUCTS.GET_SINGLE_PRODUCT_INIT: {
      return {
        ...state,
        singleProduct: {
          data: undefined,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PRODUCTS.GET_SINGLE_PRODUCT_SUCCESS: {
      return {
        ...state,
        singleProduct: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case SHOPPING_CART.MODIFY_BASKET_SUCCESS: {
      if (state.shoppingCartOwner.data.length > 0 && payload.dataForm.update_option !== 'reset') {
        if (payload.data.products_amount > 0) {
          const newStateShoppingCartOwner = state.shoppingCartOwner?.data;
          const seller = newStateShoppingCartOwner.find(({ id }) => id === payload.dataForm.user_id);
          const product = seller.products[payload.dataForm.product_uuid];
          if (payload.dataForm.update_option === 'add') {
            product && (product.pivot_shopping_cart_amount += payload.dataForm.amount);
          } else if (payload.dataForm.update_option === 'remove') {
            product && (product.pivot_shopping_cart_amount -= payload.dataForm.amount);
            if (product.pivot_shopping_cart_amount === 0) {
              const { products } = seller;
              const allowed = Object.keys(products).filter(item => products[item].uuid !== product.uuid);
              const filtered = Object.keys(products)
                .filter(key => allowed.includes(key))
                .reduce((obj, key) => {
                  obj[key] = products[key];
                  return obj;
                }, {});
              seller.products = filtered;
            }
          } else if (payload.dataForm.update_option === 'delete') {
            if (Object.keys(seller.products).length === 1) {
              seller.products = [];
            } else if (Object.keys(seller.products).length > 1) {
              const { products } = seller;
              const otherProducts = Object.keys(products).filter(item => products[item].uuid !== product.uuid);
              const filtered = Object.keys(products)
                .filter(key => otherProducts.includes(key))
                .reduce((obj, key) => {
                  obj[key] = products[key];
                  return obj;
                }, {});
              seller.products = filtered;
            }
          }
        } else {
          console.log('');
        }
      } else {
        state.shoppingCartOwner.data = [];
      }

      return {
        ...state,
        shoppingCart: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case SHOPPING_CART.GET_SHOPPING_CART_SUCCESS: {
      return {
        ...state,
        shoppingCart: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case SHOPPING_CART.GET_SHOPPING_CART_FAILURE: {
      return {
        ...state,
        shoppingCartOwner: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }

    case SHOPPING_CART.GET_SHOPPING_CART_OWNER_INT: {
      return {
        ...state,
        shoppingCartOwner: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case SHOPPING_CART.GET_SHOPPING_CART_OWNER_SUCCESS: {
      return {
        ...state,
        shoppingCartOwner: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case SHOPPING_CART.GET_SHOPPING_CART_OWNER_FAILURE: {
      return {
        ...state,
        shoppingCart: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }

    default:
      return state;
  }
};

export default shoppingCartReducer;

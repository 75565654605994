import React from 'react';
import { SearchEngine } from 'shared/components';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

const OrdersFilters = ({ formatMessage, filters, setFilters }) => {
  const handleInputChange = debounce(e => {
    const {
      target: { value },
    } = e;
    setFilters({ ...filters, title: value });
  }, 1000);

  return (
    <>
      <div className="advertisements__filters__wrapper advertisements__filters__wrapper--orders">
        <SearchEngine
          handleChange={handleInputChange}
          placeholder={formatMessage({ id: 'inputs.title' })}
          className="search-field--advertisements"
        />
      </div>
    </>
  );
};

export default OrdersFilters;

OrdersFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  formatMessage: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  setFilters: PropTypes.object.isRequired,
};

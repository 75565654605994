import * as Connector from 'shared/connectors/utilsConnector';
import { UTILS } from 'store/types';

const init = () => ({
  type: UTILS.ADD_FILES_INIT,
});

export const success = data => ({
  type: UTILS.ADD_FILES_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: UTILS.ADD_FILES_FAILURE,
});

const addFiles = (file, uuid, params) => async dispatch => {
  dispatch(init());
  try {
    await Connector.addFiles(file, uuid, params);
    return true;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default addFiles;

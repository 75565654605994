import { func, object } from 'prop-types';
import React from 'react';

const MyData = ({ authorizedUser, formatMessage }) => (
  <div className="settings__content">
    <div className="settings__content__item">
      <p>{formatMessage({ id: 'inputs.name' })}</p>
      <p>{authorizedUser.first_name}</p>
    </div>
    <div className="settings__content__item">
      <p>{formatMessage({ id: 'inputs.last_name' })}</p>
      <p>{authorizedUser.last_name}</p>
    </div>
    <div className="settings__content__item">
      <p>{formatMessage({ id: 'inputs.email' })}</p>
      <p>{authorizedUser.email}</p>
    </div>

    {authorizedUser.role === 'partner' && (
      <div className="settings__content__item">
        <p>{formatMessage({ id: 'global.nip' })}*</p>
        <p>{authorizedUser.nip ? authorizedUser.nip : '-'}</p>
      </div>
    )}
  </div>
);

export default MyData;

MyData.propTypes = {
  authorizedUser: object.isRequired,
  editUser: func.isRequired,
  formatMessage: func.isRequired,
  setModalManagement: func.isRequired,
};

import React, { useState } from 'react';
import { Breadcrumbs, Button, Notify, PhotoSlider, Preloader } from 'shared/components';
import { ProductName, ProductDescript, SellerComment, Seller } from 'screens/product-preview/components';
import { bool, func, object, string } from 'prop-types';
import { history } from 'store/store';
import { generatePath } from 'react-router-dom';
import { NOTIFY_TYPE, ROUTES } from 'shared/consts';
import { toast } from 'react-toastify';
import InputCounter from '../inputCounter';

const Content = ({
  isMobile,
  formatMessage,
  data,
  translation,
  modifyShoppingCart,
  addToFavorites,
  removeFromFavorites,
  authorizedUser,
  categories,
  delivery,
  carBrands,
  carModels,
  addProducts,
  addProductPreview,
  isEdit,
  editAdvertisements,
  priceMargin,
}) => {
  const currentCategory = categories?.category?.find(item => item.uuid === data.category_uuid);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
console.log
  const addAdvertisment = async () => {
    setIsFormSubmitted(true);
    const uuid = await addProducts(data, data.files);
    uuid &&
      history.push(
        generatePath(ROUTES.SINGLE_PRODUCT, {
          category: currentCategory.link,
          product: uuid,
        }),
      );
    addProductPreview();
  };

  const editAdvertisment = async () => {
    setIsFormSubmitted(true);
    const response = await editAdvertisements({
      uuid: data.uuid,
      form: { ...data, price: data.priceWithoutMargin || data.price },
      photos: data.photoInputValue,
      photoToDeleteUuid: data.photoToDeleteUuid,
    });
    response === 'success'
      ? (history.push(
          generatePath(ROUTES.SINGLE_PRODUCT, {
            category: currentCategory.link,
            product: data.uuid,
          }),
        ),
        addProductPreview())
      : setIsFormSubmitted(false);
  };

  const handleOnSubmit = async () => {
    try {
      if (!isFormSubmitted) {
        console.log(isEdit);
        if (isEdit) {
          editAdvertisment();
        } else {
          console.log('add');
          addAdvertisment();
        }
      }
    } catch {
      toast(<Notify label="global.unsuccessful_add_product" type={NOTIFY_TYPE.CHECKED} />);
    }
  };
  return (
    <div>
      {isFormSubmitted && <Preloader />}
      <Breadcrumbs
        preview
        data={data}
        translation={translation}
        categories={categories}
        formatMessage={formatMessage}
        back
        isEdit={isEdit}
      />
      <div className="single-product__content">
        <div>
          <div
            className={`single-product__slider  ${data?.files && data?.files?.length < 2 ? 'single-product__slider--small' : ''}`}
          >
            <PhotoSlider data={data} />
          </div>
          {!isMobile && (
            <div className="single-product__descript">
              <ProductDescript
                carModels={carModels}
                carBrands={carBrands}
                translation={translation}
                categories={categories}
                data={data}
                formatMessage={formatMessage}
              />{' '}
              <SellerComment translation={translation} data={data} />
            </div>
          )}
        </div>
        <div>
          <ProductName
            authorizedUser={authorizedUser}
            translation={translation}
            data={data}
            formatMessage={formatMessage}
            addToFavorites={addToFavorites}
            removeFromFavorites={removeFromFavorites}
            modifyShoppingCart={modifyShoppingCart}
            InputCounter={InputCounter}
            delivery={delivery}
            priceMargin={priceMargin}
            isEdit={isEdit}
          />
          <Seller
            delivery={delivery}
            formatMessage={formatMessage}
            data={data}
            translation={translation}
            authorizedUser={authorizedUser}
          />
          <div className="single-product__btn--wrapper">
            <Button
              className="btn--orange-border"
              onClick={() =>
                !isFormSubmitted && isEdit
                  ? history.push({ pathname: '/edit-advertisement', state: { data } })
                  : history.push('/add-advertisement')
              }
              type="button"
              label={formatMessage({ id: 'global.go_back' })}
            />
            <Button
              className="btn--orange"
              onClick={() => !isFormSubmitted && handleOnSubmit()}
              type="submit"
              label={isEdit ? formatMessage({ id: 'inputs.save' }) : formatMessage({ id: 'inputs.add_advertisement' })}
            />
          </div>
        </div>
        {isMobile && (
          <div className="single-product__descript">
            <ProductDescript
              translation={translation}
              categories={categories}
              data={data}
              formatMessage={formatMessage}
              carModels={carModels}
              carBrands={carBrands}
            />{' '}
            <SellerComment translation={translation} data={data} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Content;

Content.propTypes = {
  addProductPreview: func.isRequired,
  addProducts: func.isRequired,
  addToFavorites: func.isRequired,
  authorizedUser: object.isRequired,
  carBrands: object.isRequired,
  carModels: object.isRequired,
  categories: object.isRequired,
  data: object.isRequired,
  delivery: object.isRequired,
  editAdvertisements: func.isRequired,
  formatMessage: func.isRequired,
  isEdit: bool.isRequired,
  isMobile: bool.isRequired,
  modifyShoppingCart: func.isRequired,
  priceMargin: object.isRequired,
  removeFromFavorites: func.isRequired,
  translation: string.isRequired,
};

import * as Connector from 'shared/connectors/userConnector';
import { USER } from 'store/types';
import { getUsersByRole } from 'store/actions/user';

const init = () => ({
  type: USER.USER_SUSPENSION_INIT,
});

export const success = () => ({
  type: USER.USER_SUSPENSION_SUCCESS,
});

const failure = () => ({
  type: USER.USER_SUSPENSION_FAILURE,
});

const userSuspension = (uuid, group) => async dispatch => {
  dispatch(init());
  try {
    const data = await Connector.userSuspension(uuid);
    dispatch(getUsersByRole(group));
    return data;
  } catch (err) {
    dispatch(failure());
    return false;
  }
};

export default userSuspension;

import { func } from 'prop-types';
import React from 'react';
import { Button } from 'shared/components';

const UnpaidOrder = ({ createCheckoutSession, cancelCheckout, closeModal, formatMessage }) => {
  const CheckoutUuid = localStorage.getItem('checkoutUuid');
  const cancelCheckoutFunc = async () => {
    const response = await cancelCheckout(CheckoutUuid);
    if (response.message === 'Checkout cancelled successfully') {
      localStorage.removeItem('checkoutUuid');
      localStorage.removeItem('orderEndTime');
      closeModal();
      window.location.reload(false);
    }
  };
  return (
    <div className="unpaid-order">
      <p className="heading">{formatMessage({ id: 'global.last_order_unpaid' })}</p>
      <Button
        label={formatMessage({ id: 'global.retry_payments' })}
        className="btn--orange"
        onClick={() => createCheckoutSession({ uuid: CheckoutUuid, isUserLogged: false })}
      />
      <span className="descript">lub</span>
      <Button
        label={formatMessage({ id: 'global.order_delete' })}
        className="btn--red-border"
        onClick={() => cancelCheckoutFunc()}
      />
    </div>
  );
};

export default UnpaidOrder;

UnpaidOrder.propTypes = {
  cancelCheckout: func.isRequired,
  closeModal: func.isRequired,
  createCheckoutSession: func.isRequired,
  formatMessage: func.isRequired,
};

/* eslint-disable react/prop-types */
import React from 'react';
import { ListsProductsItem } from 'screens/products-lists/components';

const getColumns = (isMobile, addToFavorites, removeFromFavorites, translation, formatMessage, authorizedUser) => [
  {
    width: '200px',
    Cell: ({ original }) => (
      <>
        <ListsProductsItem
          authorizedUser={authorizedUser}
          removeFromFavorites={removeFromFavorites}
          addToFavorites={addToFavorites}
          data={original}
          isMobile={isMobile}
          translation={translation}
          formatMessage={formatMessage}
          advertisement={false}
        />
      </>
    ),
  },
];

export { getColumns };

import { connect } from 'react-redux';
import { isMobile as checkIsMobile } from 'shared/utils';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { partnerRequest } from 'store/actions/partner';
import * as ViewManagementSelector from 'store/selectors/viewManagementSelector';
import { getAuthorizedUser } from 'store/selectors/userSelector';
import PartnerProgram from './PartnerProgram.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      partnerRequest: data => partnerRequest(data),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  isMobile: checkIsMobile(),
  authorizedUser: getAuthorizedUser(state),
  translation: ViewManagementSelector.getTranslation(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PartnerProgram));

import React from 'react';
import { Modal, RegularField, ModalActions, SelectField } from 'shared/components';
import { bool, func, object } from 'prop-types';
import * as Yup from 'yup';
import { MODAL_TYPE, VALIDATION_FIELD } from 'shared/consts';
import { Field, Formik } from 'formik';
import { phonePrefixOptions } from 'shared/utils';

const AddressModal = ({ isOpened, onClose, user, formatMessage, editUser, authorizedUser }) => {
  // const [fileInputValue, setFileInputValue] = useState('');

  const onSubmit = async values => {
    onClose();
    await editUser({ ...values });
    // const address = values.address.split('/');
    // await editUser({ ...values, house_number: address[0], apartment_number:address[1] });
  };
  const validation = () =>
    Yup.object().shape({
      apartment_number: Yup.string(VALIDATION_FIELD(formatMessage).REQUIRED).max(3, VALIDATION_FIELD(formatMessage).MAX_3),
      city: Yup.string(VALIDATION_FIELD(formatMessage).REQUIRED)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .min(2, VALIDATION_FIELD(formatMessage).MIN_2)
        .max(50, VALIDATION_FIELD(formatMessage).MAX_50),
      email: Yup.string().email(VALIDATION_FIELD(formatMessage).EMAIL).required(VALIDATION_FIELD(formatMessage).REQUIRED),
      first_name: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .min(2, VALIDATION_FIELD(formatMessage).MIN_2)
        .max(20, VALIDATION_FIELD(formatMessage).MAX_20),
      house_number: Yup.string(VALIDATION_FIELD(formatMessage).REQUIRED)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .max(3, VALIDATION_FIELD(formatMessage).MAX_3),
      last_name: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .min(2, VALIDATION_FIELD(formatMessage).MIN_2)
        .max(50, VALIDATION_FIELD(formatMessage).MAX_50),
      phone_number: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .min(9, VALIDATION_FIELD(formatMessage).DIGITS_9)
        .max(9, VALIDATION_FIELD(formatMessage).DIGITS_9),
      // .nullable(),
      phone_prefix: Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED),
      post_code: Yup.string(VALIDATION_FIELD(formatMessage).REQUIRED)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .test(
          'post-code',
          authorizedUser.country_id === 2
            ? VALIDATION_FIELD(formatMessage).POST_CODE_DE
            : VALIDATION_FIELD(formatMessage).POST_CODE,
          val => (val && authorizedUser.country_id === 2 ? !!/^[0-9]{5}$/i.test(val) : /^[0-9]{2}-[0-9]{3}$/i.test(val)),
        )
        .test('germany-postcode', VALIDATION_FIELD(formatMessage).BAD_VALUE, val =>
          val && authorizedUser.country_id === 2
            ? !!/\b((?:0[1-46-9]\d{3})|(?:[1-357-9]\d{4})|(?:[4][0-24-9]\d{3})|(?:[6][013-9]\d{3}))\b/.test(val)
            : true,
        ),
      street: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .min(2, VALIDATION_FIELD(formatMessage).MIN_2)
        .max(50, VALIDATION_FIELD(formatMessage).MAX_50),
    });
  return (
    <>
      {isOpened && (
        <Modal
          className={`${MODAL_TYPE.BASIC} editUser`}
          onClose={() => {
            onClose();
            // setFileInputValue(undefined);
          }}
          opened={isOpened}
        >
          <div>
            {!user ? (
              <div className="modal__heading modal__heading--center">{formatMessage({ id: 'global.adding' })}</div>
            ) : (
              <div className="modal__heading modal__heading--center">{formatMessage({ id: 'global.editing' })}</div>
            )}
            <Formik
              validationSchema={validation()}
              initialValues={{
                first_name: user?.first_name || '',
                last_name: user?.last_name || '',
                email: user?.email || '',
                house_number: (user?.house_number && user?.house_number) || '',
                apartment_number: (user?.apartment_number && user?.apartment_number) || '',
                post_code: user?.post_code || '',
                city: user?.city || '',
                phone_number: user?.phone_number || '',
                phone_prefix: user?.phone_prefix || '',
                street: user?.street || '',
              }}
              onSubmit={onSubmit}
            >
              {({ handleSubmit, errors, handleBlur }) => (
                <form className="categoryManagement__addCategory__wrapper" onSubmit={handleSubmit}>
                  <div className="courier__add">
                    <Field
                      type="text"
                      label={formatMessage({ id: 'inputs.name' })}
                      name="first_name"
                      component={RegularField}
                      errors={errors}
                      onBlur={handleBlur}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: 'inputs.last_name' })}
                      name="last_name"
                      component={RegularField}
                      errors={errors}
                      onBlur={handleBlur}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: 'inputs.email' })}
                      name="email"
                      component={RegularField}
                      errors={errors}
                      onBlur={handleBlur}
                    />
                    <div className="settings__phone-number">
                      <Field
                        name="phone_prefix"
                        // className="advertisements__filters--sort"
                        placeholder={formatMessage({ id: 'inputs.choose' })}
                        label={`${formatMessage({ id: 'global.telephone' })}`}
                        options={phonePrefixOptions()}
                        component={SelectField}
                        errors={errors}
                        isCustomLabel
                        onBlur={handleBlur}
                      />
                      <Field type="text" name="phone_number" onBlur={handleBlur} component={RegularField} errors={errors} />
                    </div>
                    <Field
                      type="text"
                      label={`${formatMessage({ id: 'inputs.street' })}`}
                      name="street"
                      component={RegularField}
                      errors={errors}
                      onBlur={handleBlur}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: 'inputs.build_number' })}
                      name="house_number"
                      component={RegularField}
                      errors={errors}
                      onBlur={handleBlur}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: 'inputs.apartement_number' })}
                      name="apartment_number"
                      component={RegularField}
                      errors={errors}
                      onBlur={handleBlur}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: 'inputs.post_code' })}
                      name="post_code"
                      component={RegularField}
                      errors={errors}
                      onBlur={handleBlur}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: 'inputs.city' })}
                      name="city"
                      component={RegularField}
                      errors={errors}
                      onBlur={handleBlur}
                    />

                    <ModalActions
                      formatMessage={formatMessage}
                      className="courier__add--buttons"
                      type={!user ? MODAL_TYPE.ADD_CATEGORY : MODAL_TYPE.EDIT_COURIER}
                      onClose={() => {
                        onClose();
                      }}
                      onSubmit={() => {
                        handleSubmit();
                      }}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
    </>
  );
};

export default AddressModal;

AddressModal.defaultProps = {
  edit: false,
  setMenuType: () => console.log(),
};

AddressModal.propTypes = {
  authorizedUser: object.isRequired,
  deleteFiles: object.isRequired,
  deliveryCountries: object.isRequired,
  edit: bool,
  editUser: func.isRequired,
  formatMessage: func.isRequired,
  info: object.isRequired,
  isOpened: bool.isRequired,
  mobile: bool.isRequired,
  onClose: func.isRequired,
  setMenuType: func,
  user: object.isRequired,
};

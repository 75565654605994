import React from 'react';
import PropTypes from 'prop-types';
import { Products, Transfers,Orders } from 'screens/magazine/components';
import { MENU_TYPES } from '../utils/menuUtils';

const MagazineContent = ({
  menuType,
  formatMessage,
  isMobile,
  products,
  transfers,
  addTransfer,
  editTransfer,
  deliveryCountries,
  categoryAndSubcategory,
  editAdvertisements,
  deleteProduct,
  recallTheProduct,
  resumeTheProduct,
  translation,
  edit,
  setEdit,
  subcategoryForCategory,
  getSubcategoryForCategory,
  checkout,
  getInvoice,
}) => {
  switch (menuType) {
    case MENU_TYPES(formatMessage).PRODUCTS: {
      return (
        <Products
          formatMessage={formatMessage}
          isMobile={isMobile}
          data={products}
          addTransfer={addTransfer}
          deliveryCountries={deliveryCountries}
          categoryAndSubcategory={categoryAndSubcategory}
          editAdvertisements={editAdvertisements}
          deleteProduct={deleteProduct}
          recallTheProduct={recallTheProduct}
          resumeTheProduct={resumeTheProduct}
          translation={translation}
          edit={edit}
          setEdit={setEdit}
          subcategoryForCategory={subcategoryForCategory}
          getSubcategoryForCategory={getSubcategoryForCategory}
        />
      );
    }
    case MENU_TYPES(formatMessage).TRANSFERS: {
      return <Transfers formatMessage={formatMessage} isMobile={isMobile} data={transfers} editTransfer={editTransfer} />;
    }
    case MENU_TYPES(formatMessage).ORDERS: {
      return <Orders formatMessage={formatMessage} isMobile={isMobile} data={checkout} getInvoice={getInvoice} />;
    }
    default: {
      return null;
    }
  }
};
export default MagazineContent;

MagazineContent.propTypes = {
  addTransfer: PropTypes.func.isRequired,
  deleteProduct: PropTypes.func.isRequired,
  deliveryCountries: PropTypes.array.isRequired,
  edit: PropTypes.bool.isRequired,
  formatMessage: PropTypes.func.isRequired,
  getAllTrasnfers: PropTypes.func.isRequired,
  isMobile: PropTypes.object.isRequired,
  menuType: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  recallTheProduct: PropTypes.func.isRequired,
  resumeTheProduct: PropTypes.func.isRequired,
  setEdit: PropTypes.func.isRequired,
  transfers: PropTypes.array.isRequired,
};

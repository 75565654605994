import API from 'shared/connectors/config';

export function addCategory(data) {
  return API.post('/categories', data);
}

export function addSubcategory(data) {
  return API.post('/subcategories', data);
}

export function getAllCategory() {
  return API.get('/categories');
}

export function getAllCategoryAndSubcategory() {
  return API.get('/categories/all');
}

export function getSubcategoryForCategory(uuid) {
  return API.get(`/categories/subcategories/${uuid}`);
}

export function deleteCategory(uuid) {
  return API.delete(`/categories/${uuid}`);
}

export function deleteSubcategory(uuid) {
  return API.delete(`/subcategories/${uuid}`);
}

export function editCategory(uuid, data) {
  return API.put(`/categories/${uuid}`, data);
}

export function editSubcategory(uuid, data) {
  return API.put(`/subcategories/${uuid}`, data);
}

export function getCategoryByLink(uuid) {
  return API.get(`/categories/link/${uuid}`);
}
export function getPopularCategories() {
  return API.get('/categories/popular-subcategories');
}

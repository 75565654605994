const MENU_TYPES = formatMessage => ({
  COUNTRY: {
    label: formatMessage({ id: 'global.countries' }),
    options: 'country',
  },
  PARTNERS: {
    label: formatMessage({ id: 'global.partners' }),
    options: 'partner',
  },
  PRODUCTS: {
    label: formatMessage({ id: 'global.products' }),
    options: 'product',
  },
});

export { MENU_TYPES };

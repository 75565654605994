import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { SUBPAGES } from '../const';

const isSmallViewPort = window.innerWidth < 480;
const settings = {
  dots: false,
  arrows: false,
  slidesToShow: isSmallViewPort ? 2 : 3,
};

const AdvertisementsMenu = ({ menuType, setMenuType, setAdvertisementTypes, isMobile, formatMessage, count }) => {
  const setSubMenu = (type, requestType) => {
    setMenuType(type);
    setAdvertisementTypes(requestType);
  };

  return (
    <>
      {!isMobile ? (
        <div className="advertisements__menu">
          {SUBPAGES(formatMessage, count).map(({ type, amount, requestType }) => (
            <div
              className={`advertisements__menu--item ${menuType === type ? 'advertisements__menu--active' : ''}`}
              onClick={() => setSubMenu(type, requestType)}
            >
              <div className="label">{type}</div>
              <div className={`counter ${typeof(amount) !=='number' ? '' : 'counter--fill'}`}>{amount}</div>
            </div>
          ))}
        </div>
      ) : (
        <div className="advertisements__menu">
          <Slider {...settings}>
            {SUBPAGES(formatMessage, count).map(({ type, amount, requestType }) => (
              <div
                className={`advertisements__menu--item ${menuType === type ? 'advertisements__menu--active' : ''}`}
                onClick={() => setSubMenu(type, requestType)}
              >
                <div className="label">{type}</div>
                <div className={`counter ${!amount ? '' : 'counter--fill'}`}>{amount}</div>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </>
  );
};

export default AdvertisementsMenu;

AdvertisementsMenu.propTypes = {
  count: PropTypes.number.isRequired,
  formatMessage: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  menuType: PropTypes.bool.isRequired,
  setAdvertisementTypes: PropTypes.func.isRequired,
  setMenuType: PropTypes.func.isRequired,
};

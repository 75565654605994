import { array, bool, func } from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { Modal, SelectField, Button } from 'shared/components';
import { VALIDATION_FIELD } from 'shared/consts';
import { deliveryCountryOptions } from 'shared/utils';

const CountryModal = ({ formatMessage, deliveryCountries, setIsCountryHasBeenSelected, isCountryHasBeenSelected }) => {
  const handleOnSubmit = ({ country }) => {
    localStorage.setItem('deliveryCountry', country);
    setIsCountryHasBeenSelected(true);
  };

  const validation = () =>
    Yup.object().shape({
      country: Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED).min(2, VALIDATION_FIELD(formatMessage).MIN_2),
    });

  return (
    <>
      <Modal className="country" opened closeModal={isCountryHasBeenSelected}>
        <div className="modal__heading modal__heading--center single-product__country-popup--heading">
          Aby przejść do widoku produktu należy wybrać kraj
        </div>
        <Formik
          validationSchema={validation()}
          onSubmit={handleOnSubmit}
          initialValues={{
            country: '',
          }}
        >
          {({ handleSubmit, errors }) => (
            <form onSubmit={handleSubmit}>
              <Field
                className="add-advertisement__select select"
                component={SelectField}
                placeholder={formatMessage({ id: 'inputs.choose' })}
                name="country"
                errors={errors}
                options={deliveryCountryOptions(deliveryCountries, formatMessage)}
                isCustomLabel
              />
              <div className="btn--wrapper">
                <Button className="btn--orange" label={formatMessage({ id: 'inputs.save' })} type="submit" />
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default CountryModal;

CountryModal.propTypes = {
  deliveryCountries: array.isRequired,
  formatMessage: func.isRequired,
  isCountryHasBeenSelected: bool.isRequired,
  setIsCountryHasBeenSelected: func.isRequired,
};

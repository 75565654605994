import React from 'react';
import { func, string, bool } from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import { FormattedMessage } from 'react-intl';

const CategorySettings = ({ isOpened, setIsSettingsOpened, setWhichModalIsOpened, uuid, isDeletedDisabled }) => {
  const handleDelete = () => {
    setWhichModalIsOpened({ name: 'delete', uuid });
    setIsSettingsOpened(false);
  };
  const handleEdit = () => {
    setWhichModalIsOpened({ name: 'edit', uuid });
    setIsSettingsOpened(false);
  };

  return (
    <OutsideClickHandler display="flex" onOutsideClick={() => setIsSettingsOpened(false)}>
      <div className={`categoryManagement__settings categoryManagement__settings--${isOpened ? 'visible' : 'hidden'}`}>
        <div className="categoryManagement__settings--item" onClick={handleEdit}>
          <FormattedMessage id="inputs.edit" />
        </div>
        <div
          className={`categoryManagement__settings--item ${
            isDeletedDisabled ? 'categoryManagement__settings--item--disabled' : ''
          }`}
          onClick={handleDelete}
        >
          <FormattedMessage id="inputs.delete" />
        </div>
      </div>
    </OutsideClickHandler>
  );
};
export default CategorySettings;

CategorySettings.propTypes = {
  isDeletedDisabled: bool.isRequired,
  isOpened: bool.isRequired,
  setIsSettingsOpened: func.isRequired,
  setWhichModalIsOpened: func.isRequired,
  uuid: string.isRequired,
};

import React, { useState, useEffect } from 'react';
import { Button, SVG } from 'shared/components';
import { SingleSubcategory, MainCategory, AddCategoryModal } from 'screens/category-management/components';
import { SVG_TYPE } from 'shared/consts';
import { string, bool, func, object, number } from 'prop-types';

const SingleCategory = ({
  id,
  isMobile,
  data,
  addSubcategory,
  deleteCategory,
  deleteFiles,
  editCategory,
  editSubcategory,
  deleteSubcategory,
  formatMessage,
  key,
  translation,
}) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isExpand, setIsExpand] = useState(false);

  const hiddenSubcategory = () => {
    const parent = document.getElementById(`category-0${id}`);
    const list = parent.getElementsByClassName('categoryManagement__singleSubcat');
    const expander = parent.getElementsByClassName('categoryManagement__singleCategory--expand');
    const hiddenElement = parent.getElementsByClassName('categoryManagement__singleSubcat--none');
    const count = list.length + 1;
    for (let i = 0; i < count; i++) {
      const item = list[list.length - i];
      parent.offsetHeight > 130 && item && item.classList.add('categoryManagement__singleSubcat--none');
    }
    hiddenElement.length === 0 &&
      expander.length > 0 &&
      expander[0].classList.add('categoryManagement__singleCategory--expand--hide');
  };

  const visibilityCategory = () => {
    const parent = document.getElementById(`category-0${id}`);
    const list = parent.getElementsByClassName('categoryManagement__singleSubcat--none');
    Array.from(list).forEach(el => {
      el.classList.remove('categoryManagement__singleSubcat--none');
    });
  };

  const triggerExpand = () => {
    setIsExpand(!isExpand);
    !isExpand ? visibilityCategory() : hiddenSubcategory();
  };
  const openModal = () => {
    setIsModalOpened(true);
  };

  useEffect(() => {
    !isMobile && hiddenSubcategory();
  }, [data.category]);

  const handleAddSubcategory = () => {
    visibilityCategory();
    setIsExpand(true);
  };

  return (
    <div
      key={key}
      className={`categoryManagement__singleCategory ${isMobile && isExpand ? 'categoryManagement__singleCategory--active' : ''}`}
    >
      <AddCategoryModal
        handleAddSubcategory={handleAddSubcategory}
        formatMessage={formatMessage}
        categoryUuid={data.uuid}
        addSubcategory={addSubcategory}
        title={formatMessage({ id: 'global.add_subcategory' })}
        isOpened={isModalOpened}
        setIsModalOpened={setIsModalOpened}
        subcategory
      />
      <MainCategory
        translation={translation}
        deleteFiles={deleteFiles}
        formatMessage={formatMessage}
        editCategory={editCategory}
        deleteCategory={deleteCategory}
        data={data}
        isExpand={isExpand}
        setIsExpand={setIsExpand}
        isMobile={isMobile}
      />
      <div
        id={`category-0${id}`}
        className={`categoryManagement__singleCategory__right ${
          !isMobile && isExpand ? 'categoryManagement__singleCategory__right--expand' : ''
        } ${isMobile && isExpand ? 'categoryManagement__singleCategory__right--mobileExpand' : ''}`}
      >
        {data.subcategories.map(item => (
          <SingleSubcategory
            formatMessage={formatMessage}
            editSubcategory={editSubcategory}
            data={item}
            deleteSubcategory={deleteSubcategory}
            label={translation === 'pl' ? item.name : item.name_ger}
          />
        ))}

        {!isMobile && (
          <div className="categoryManagement__singleCategory--expand" onClick={() => triggerExpand()}>
            {!isExpand ? formatMessage({ id: 'inputs.expand' }) : formatMessage({ id: 'inputs.collapse' })}
            <SVG
              className={`categoryManagement__singleCategory--expand--icon ${
                isExpand ? 'categoryManagement__singleCategory--expand--true' : ''
              }`}
              type={SVG_TYPE.CHEVRONE}
            />
          </div>
        )}
        <div className="categoryManagement__singleCategory--addSubCat">
          <Button
            label={formatMessage({ id: 'inputs.add_plus' })}
            className="btn--gray categoryManagement__header--button"
            onClick={openModal}
          />
        </div>
      </div>
    </div>
  );
};

export default SingleCategory;

SingleCategory.propTypes = {
  addSubcategory: func.isRequired,
  data: object.isRequired,
  deleteCategory: func.isRequired,
  deleteFiles: func.isRequired,
  deleteSubcategory: func.isRequired,
  editCategory: func.isRequired,
  editSubcategory: func.isRequired,
  formatMessage: func.isRequired,
  id: string.isRequired,
  isMobile: bool.isRequired,
  key: number.isRequired,
  translation: string.isRequired,
};

/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { func, array, bool } from 'prop-types';
import { Table, Badge } from 'shared/components';
import { BADGE_TYPES } from 'shared/consts';
import { Settings, EditForm, LocalMargin, DeleteMargin } from 'screens/price-management/components';
// import { deliveryCountryLabelShort } from 'shared/utils';

const Partners = ({ formatMessage, partners, isMobile, editPriceMargin, createPriceMargin, deletePriceMargin }) => {
  const [settingsType, setSettingsType] = useState({ active: '', id: '' });

  const getColumns = () => [
    {
      width: isMobile ? '45' : '135',
      Cell: () => <Badge type={BADGE_TYPES.PARTNER} label={formatMessage({ id: 'global.partner' })} />,
    },
    {
      id: 'first_name',
      width: !isMobile ? '178' : '100',
      Cell: ({ original }) => (
        <>
          <p className='user-management__table__content--desktop user-management__table__content--name'>{original.first_name} {isMobile && original.last_name}</p>
        </>
      ),
    },
    {
      id: 'last_name',
      width: !isMobile ? '178' : '0',
      accessor:'last_name',
    },
    {
      id: 'language',
      accessor: 'language',
      width: !isMobile ? '100' : '30',
      className: 'user-management__table__content--desktop user-management__table__content--uppercase',
    },
    {
      width: !isMobile ? '168' : '60',
      className: 'user-management__table__content--desktop user-management__table__content',
      Cell: ({ original }) => (
        <>
          <LocalMargin data={original} />
        </>
      ),
    },

    {
      width: !isMobile ? '100' : '70',
      Cell: ({ original }) => (
        <Settings
          id={original.id}
          settingsType={settingsType}
          setSettingsType={setSettingsType}
          formatMessage={formatMessage}
          data={original}
          remove={!!original.price_margin}
        />
      ),
      className: 'user-management__table__content--marginAuto',
    },
  ];

  return (
    <>
      <div className="user-management__table--wrapper partner-managment">
        <div className="user-management__table__heading">
          {!isMobile && (
            <>
              <p className="user-management__table__heading--conclusionName">{formatMessage({ id: 'inputs.name' })}</p>
              <p className="user-management__table__heading--conclusionName">{formatMessage({ id: 'inputs.last_name' })}</p>
            </>
          )}
          <p className="user-management__table__heading--conclusionName user-management__table__heading--country">{formatMessage({ id: 'global.country' })}</p>
          <p className="user-management__table__heading--conclusionName user-management__table__heading--earings">{formatMessage({ id: 'global.earnings' })}</p>
        </div>
        <div className="user-management__table__content">
          {partners && partners.length > 0 && <Table data={partners} columns={getColumns()} />}
        </div>
      </div>
      <EditForm
        type="partner"
        formatMessage={formatMessage}
        settingsType={settingsType}
        setSettingsType={setSettingsType}
        editPriceMargin={editPriceMargin}
        createPriceMargin={createPriceMargin}
      />
      <DeleteMargin
        settingsType={settingsType}
        setSettingsType={setSettingsType}
        formatMessage={formatMessage}
        deletePriceMargin={deletePriceMargin}
        type="partner"
      />
    </>
  );
};

export default Partners;

Partners.propTypes = {
  createPriceMargin: func.isRequired,
  deletePriceMargin: func.isRequired,
  editPriceMargin: func.isRequired,
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
  partners: array.isRequired,
};

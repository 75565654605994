import React from 'react';
import * as Connector from 'shared/connectors/priceConnector';
import { PRICE } from 'store/types';
import { NOTIFY_TYPE } from 'shared/consts';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';

export const success = (itemUuid, type, data) => ({
  type: PRICE.EDIT_PRICE_MARGIN_SUCCESS,
  payload: {
    itemUuid,
    type,
    data,
  },
});

const editPriceMargin = values => async dispatch => {
  try {
    const { value, isPercentageLocal, foreign_margin, isPercentageForeign, uuid, itemUuid, type } = values;
    let body = {};
    if (type === 'countries') {
      body = {
        value,
        isPercentageLocal,
        foreign_margin,
        isPercentageForeign,
      };
    } else {
      body = {
        value,
        isPercentageLocal,
      };
    }
    const { data } = await Connector.editPriceMargin(uuid, body);
    const priceMargin = {
      label: uuid,
      value,
      foreign_margin,
      isPercentageLocal,
      isPercentageForeign,
    };
    toast(<Notify label="global.successfully_edited_margin" type={NOTIFY_TYPE.CHECKED} />);
    dispatch(success(itemUuid, type, priceMargin));
    return data;
  } catch (err) {
    return err.response;
  }
};

export default editPriceMargin;

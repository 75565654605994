import React from 'react';
import AboutUsIlustration from 'assets/files/about_us_ilustration.png';
import AboutUsSecondIlustration from 'assets/files/about_us_second_ilustration.png';
import AboutUsIlustrationMobile from 'assets/files/about_us_ilustration_mobile.png';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'shared/utils';

const AboutUs = () => (
  <div className="about-us">
    <div className="about-us__header">
      {!isMobile() ? (
        <img className="about-us__header--ilustration" src={AboutUsIlustration} alt="about us ilustration" />
      ) : (
        <img className="about-us__header--ilustration" src={AboutUsIlustrationMobile} alt="about us ilustration mobile" />
      )}
      <div className="about-us__header--content">
        <div className="about-us__header--left">
          <div className="content">
            <p className="orange">X</p>
            <p className="year">
              <FormattedMessage id="global.years" />
            </p>
          </div>
          <div className="content">
            <p className="market">
              <FormattedMessage id="global.on_market" />
            </p>
          </div>
        </div>
        <div className="about-us__header--right">
          <FormattedMessage id="global.descript_header_about_us" />
        </div>
      </div>
    </div>
    <div className="about-us--content">
      <div className="left">
        <div className="header">
          <FormattedMessage id="global.about_us" />
        </div>
        <div className="content">
          <FormattedMessage id="global.descript_content_about_us" />
        </div>
      </div>
      <div className="right">
        <img className="" src={AboutUsSecondIlustration} alt="about us second ilustration" />
      </div>
    </div>
    <div className="about-us__counters">
      <div className="about-us__counters--item">
        <SVG type={SVG_TYPE.PEOPLE} />
        <p className="counters"> 120 000+ </p>
        <p className="paragraph">
          <FormattedMessage id="global.clients_2" />
        </p>
      </div>
      <div className="about-us__counters--item">
        <SVG type={SVG_TYPE.PRODUCTS} />
        <p className="counters"> 2 MLN </p>
        <p className="paragraph">
          <FormattedMessage id="global.products_2" />
        </p>
      </div>
      <div className="about-us__counters--item">
        <SVG type={SVG_TYPE.BRAND} />
        <p className="counters"> 2 MLN </p>
        <p className="paragraph">
          <FormattedMessage id="global.products_brand" />
        </p>
      </div>
    </div>
    <div className="about-us__map--container">
      {!isMobile() ? <SVG type={SVG_TYPE.MAP} /> : <SVG type={SVG_TYPE.MAP_MOBILE} />}
      <div className="about-us__map--rectangle">
        <p className="paragraph">
          <FormattedMessage id="global.we_curently_work" /> <br /> <FormattedMessage id="global.in" /> <b>2</b>{' '}
          <FormattedMessage id="global.european_country" />
        </p>
      </div>
    </div>
  </div>
);

export default AboutUs;

import { PRODUCTS } from 'store/types';

export const success = (categoryId, name) => ({
  type: PRODUCTS.SUBTRUCT_PRODUCTS_BY_SUB_CATEGORY_SUCCESS,
  payload: {
    categoryId,
    name,
  },
});

const subtructProductsByCategory = (categoryId, name) => async dispatch => {
  // console.log('test', categoryId, name);
  try {
    dispatch(success(categoryId, name));
    return true;
  } catch (err) {
    return err.response;
  }
};

export default subtructProductsByCategory;

import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { number } from 'prop-types';

const SkeletonProduct = ({ count }) => {
  const skeletonCounts = () => {
    const row = [];
    for (let i = 0; i < count; i++) {
      row.push(
        <div className="skeleton-product--wrapper">
          <div className="skeleton-product--left">
            {' '}
            <Skeleton height={133} />{' '}
          </div>
          <div className="skeleton-product__mid">
            <div className="skeleton-product__mid--top">
              <Skeleton height={33} />
            </div>
            <div className="skeleton-product__mid--mid">
              <Skeleton height={15} />
              <Skeleton height={15} />
            </div>
            <div className="skeleton-product__mid--bottom">
              <Skeleton height={15} />
            </div>
          </div>
          <div className="skeleton-product__right">
            <div className="skeleton-product__right--top">
              <Skeleton height={30} />
            </div>
            <div className="skeleton-product__right--bottom">
              <Skeleton height={24} />
            </div>
          </div>
        </div>,
      );
    }
    return row;
  };

  return <>{skeletonCounts()}</>;
};

export default SkeletonProduct;

SkeletonProduct.propTypes = {
  count: number.isRequired,
};

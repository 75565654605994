const StopIcon = () => {
  const chatIcon = document.getElementById('chat-icon');
  const chat = document.getElementById('chat');
  const footer = document.getElementById('footer');
  function checkOffset() {
    function getRectTop(el) {
      const rect = el && el.getBoundingClientRect();
      return el && rect.top;
    }

    if (chatIcon) {
      if (
        getRectTop(chatIcon) + document.body.scrollTop + chatIcon.offsetHeight >=
        getRectTop(footer) + document.body.scrollTop - 44
      )
        chat.classList.add('chat--footer');
      if (document.body.scrollTop + window.innerHeight < getRectTop(footer) + document.body.scrollTop)
        chat.classList.remove('chat--footer');
    }
  }
  document.addEventListener('scroll', () => {
    checkOffset();
  });
  return true;
};

export default StopIcon;

import React from 'react';
import { func, object } from 'prop-types';
import { number } from 'yup/lib/locale';

const InputCounter = ({ data, amount, setAmount, formatMessage }) => {
  const increaseAmount = () => {
    setAmount(amount + 1);
  };

  const decreaseAmount = () => {
    amount > 1 && setAmount(amount - 1);
  };

  // console.log(amount > data?.stock?.in_stock - amountInBasket && (data?.stock?.in_stock - amount));

  return (
    <div className="input-counter">
      <div className={`decrease button ${amount === 1 ? 'button-disabled' : ''}`} onClick={decreaseAmount}>
        -
      </div>
      <input className="input" type="text" value={amount} />
      <div className={`increase button ${data?.stock?.in_stock <=amount ?  'button-disabled':''} `} onClick={increaseAmount}>
        {/* <div className={`increase button ${data?.stock?.in_stock === amount ? 'button-disabled' : ''}`} onClick={increaseAmount}> */}
        +
      </div>
      <p className="in_stock">
        {formatMessage({ id: 'global.of' })} {data?.stock?.in_stock} {formatMessage({ id: 'global.pieces' })}
      </p>
    </div>
  );
};
export default InputCounter;

InputCounter.propTypes = {
  amount: number,
  data: object.isRequired,
  formatMessage: func,
  modifyShoppingCart: object.isRequired,
  productsInTheBasket: object,
  setAmount: func,
  setProductsInTheBasket: func,
};
InputCounter.defaultProps = {
  amount: 1,
  formatMessage: null,
  productsInTheBasket: {},
  setAmount: null,
  setProductsInTheBasket: null,
};

import * as Connector from 'shared/connectors/productsConnector';
import { PRODUCTS } from 'store/types';

export const success = uuid => ({
  type: PRODUCTS.REMOVE_ADVERTISEMENT_SUCCESS,
  payload: {
    uuid,
  },
});

const deleteAdvertisement = uuid => async dispatch => {
  try {
    const { data } = await Connector.deleteAdvertisement(uuid);
    dispatch(success(uuid));
    return data;
  } catch (err) {
    return err.response;
  }
};

export default deleteAdvertisement;

import React from 'react';
import { func, object, bool, array } from 'prop-types';
import { Modal, RegularField, Button } from 'shared/components';
import * as Yup from 'yup';
import { MODAL_TYPE, VALIDATION_FIELD } from 'shared/consts';
import { MENU_TYPES } from 'screens/dictionary/components/const';
import { Field, Formik } from 'formik';

const AddDictionary = ({
  isOpened,
  formatMessage,
  setIsModalOpen,
  addElement,
  menuType,
  edit,
  setSettingsType,
  settingsType,
  editElement,
  restricted_word,
}) => {
  const onSubmit = async values => {
    edit
      ? await editElement({
          word_uuid: settingsType.data.word_uuid,
          new_word: values.word,
          translation_uuid: settingsType.data.translation_uuid,
          new_translation: values.translation,
        })
      : await addElement(values);
    setIsModalOpen(false);
  };
  const validation = () =>
    Yup.object().shape({
      translation: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254)
        .min(3, VALIDATION_FIELD(formatMessage).MIN_3),
      word: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254)
        .min(3, VALIDATION_FIELD(formatMessage).MIN_3)
        .test('Unique', VALIDATION_FIELD(formatMessage).UNIQUE, value =>
        settingsType.data?.word === value ? true : restricted_word.find(el => el === value) === undefined,
      ),
      
      
    });

  const translation_language_id = () => {
    if (menuType.label === 'PL') {
      return MENU_TYPES(formatMessage).DE.options;
    }
    return MENU_TYPES(formatMessage).PL.options;
  };
  return (
    <>
      {isOpened && (
        <Modal
          className={`${MODAL_TYPE.BASIC} editUser`}
          onClose={() => {
            setIsModalOpen(false);
            setSettingsType({ active: '', id: '' });
          }}
          opened={isOpened}
        >
          <div>
            <div className="modal__heading modal__heading--center">
              {formatMessage({ id: `${edit ? 'inputs.edit' : 'inputs.add'}` })}
            </div>
            <Formik
              validationSchema={validation()}
              initialValues={{
                word: `${edit ? settingsType.data.word : ''}`,
                translation: `${edit ? settingsType.data.translation : ''}`,
                word_language_uuid: menuType.options,
                translation_language_uuid: translation_language_id()
              }}
              onSubmit={onSubmit}
            >
              {({ handleSubmit, errors }) => (
                <form className="categoryManagement__addCategory__wrapper" onSubmit={handleSubmit}>
                  <div className="courier__add">
                    <Field
                      type="text"
                      label={formatMessage({ id: `${menuType.label === 'PL' ? 'inputs.polish' : 'inputs.germany'}` })}
                      name="word"
                      component={RegularField}
                      errors={errors}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: `${menuType.label !== 'PL' ? 'inputs.polish' : 'inputs.germany'}` })}
                      name="translation"
                      component={RegularField}
                      errors={errors}
                    />
                    <div className="buttons-add-dictionary">
                      <Button
                        label={formatMessage({ id: 'inputs.cancel' })}
                        className="btn--orange-border btn"
                        onClick={() => setIsModalOpen(false)}
                      />
                      <Button label={formatMessage({ id: 'inputs.save' })} className="btn--orange" type="submit" />
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
    </>
  );
};

export default AddDictionary;

AddDictionary.propTypes = {
  addElement: func.isRequired,
  edit: bool.isRequired,
  editElement: func.isRequired,
  formatMessage: func.isRequired,
  isOpened: func.isRequired,
  menuType: object.isRequired,
  restricted_word: array.isRequired,
  setIsModalOpen: func.isRequired,
  setSettingsType: func.isRequired,
  settingsType: object.isRequired,
};

import React from 'react';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import * as Connector from 'shared/connectors/customTranslationsConnector';
import { NOTIFY_TYPE } from 'shared/consts';
import { CUSTOM_TRANSLATIONS } from 'store/types';

export const success = data => ({
  type: CUSTOM_TRANSLATIONS.DELETE_CUSTOM_WORD_SUCCESS,
  payload: {
    data,
  },
});

const deleteCustomWord = uuid => async () => {
  try {
    const { data } = await Connector.deleteCustomWord(uuid);
    toast(<Notify label="global.successful_delete_word" type={NOTIFY_TYPE.CHECKED} />);
    return data;
  } catch (err) {
    return err.response;
  }
};

export default deleteCustomWord;

import * as Connector from 'shared/connectors/checkoutConnector';
import { CHECKOUT } from 'store/types';

const init = () => ({
  type: CHECKOUT.GET_COUNT_CHECKOUT_STATUS_INIT,
});

export const success = data => ({
  type: CHECKOUT.GET_COUNT_CHECKOUT_STATUS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: CHECKOUT.GET_COUNT_CHECKOUT_STATUS_FAILURE,
});

const getCountCheckoutStatus = () => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getCountCheckoutStatus();
    dispatch(success(data.statuses));
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default getCountCheckoutStatus;

import { bool, func, object } from 'prop-types';
import React from 'react';

const User = ({ active, setShowChat, data, authorizedUser }) => {
  const {
    uuid,
    owner_first_name,
    owner_last_name,
    coordinator_first_name,
    coordinator_last_name,
    last_message,
    owner_role,
  } = data;
  const date = last_message && new Date(last_message.created_at);
  return (
    <div onClick={() => setShowChat(uuid)} className={`help-chat__user ${active ? 'active' : ''}`}>
      <div className="help-chat__user__icons">
        <span className={`help-chat__user__icon help-chat__user__icon--${owner_role || 'grey'}`}>
          {' '}
          {owner_first_name ? owner_first_name.slice(0, 1) : 'N'}
          {owner_last_name ? owner_last_name.slice(0, 1) : ''}
        </span>
        {authorizedUser && coordinator_first_name && (
          <span className="help-chat__user__icon help-chat__user__icon--green">
            {coordinator_first_name ? coordinator_first_name.slice(0, 1) : 'N'}
            {coordinator_last_name ? coordinator_last_name.slice(0, 1) : ''}
          </span>
        )}
      </div>
      <div className="help-chat__user__content">
        <div className="help-chat__user__name">
          {' '}
          {owner_first_name || 'Niezalogowany'} {owner_last_name && owner_last_name}{' '}
          {last_message && last_message.owner_role === owner_role && <span className="help-chat__user__dot" />}
        </div>
        <p className="help-chat__user__message">
          {last_message && (last_message.content.length > 20 ? `${last_message.content.substr(0, 19)}...` : last_message.content)}
        </p>
      </div>
      {last_message && (
        <div className="help-chat__user__time">
          {
            new Date().getTime() - date.getTime() > 86400000
            ? `${date.getDay() < 10 ? '0' : ''}${date.getDay()}.${
                date.getMonth() < 10 ? '0' : ''
              }${date.getMonth()}.${date.getFullYear()}`
            : `${date.getHours()<10 ? '0': ''}${date.getHours()}:${date.getMinutes()<10 ? '0': ''}${date.getMinutes()}`}
        </div>
      )}
    </div>
  );
};

export default User;

User.propTypes = {
  active: bool.isRequired,
  authorizedUser: object.isRequired,
  data: object.isRequired,
  setShowChat: func.isRequired,
};

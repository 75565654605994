import React from 'react';
import { func, bool, object } from 'prop-types';
import { Modal, ModalActions } from 'shared/components';
import { MODAL_TYPE } from 'shared/consts';

const CompanyData = ({ openModal, formatMessage, onClose, managingPartnerApplication, setOpenModal }) => {
  const { city, post_code, company_name, house_number, street, phone_prefix, phone_number, nip, type } = openModal.data;

  return (
    <div>
      {openModal.isOpened === 'company_data' && (
        <Modal className={MODAL_TYPE.BASIC} onClose={onClose} opened={openModal.isOpened} openModal={openModal}>
          <div>
            <div className="modal__heading modal__heading--center">{formatMessage({ id: 'global.company_data' })}</div>

            <div>
              <div className="categoryManagement__form--constant">
                <p className="top">{formatMessage({ id: 'inputs.company_name' })}</p>
                <p className="bottom">{company_name}</p>
              </div>
              <div className="categoryManagement__form--constant">
                <p className="top">{formatMessage({ id: 'global.telephone' })}</p>
                <p className="bottom">
                  {phone_prefix} {phone_number}
                </p>
              </div>
              <div className="categoryManagement__form--constant">
                <p className="top">{formatMessage({ id: 'global.nip' })}</p>
                <p className="bottom">{nip}</p>
              </div>
              <div className="categoryManagement__form--constant">
                <p className="top">{formatMessage({ id: 'global.company_address' })}</p>
                <p className="bottom">
                  {street} {house_number}
                </p>
              </div>
              <div className="categoryManagement__form--constant">
                <p className="top">{formatMessage({ id: 'inputs.post_code' })}</p>
                <p className="bottom">{post_code}</p>
              </div>
              <div className="categoryManagement__form--constant">
                <p className="top">{formatMessage({ id: 'inputs.city' })}</p>
                <p className="bottom">{city}</p>
              </div>
              <div className="categoryManagement__form--constant">
                <p className="top">{formatMessage({ id: 'inputs.subscription_type' })}</p>
                <p className="bottom">{type}</p>
              </div>
              <ModalActions
                className="modal-actions__company-data"
                formatMessage={formatMessage}
                type={MODAL_TYPE.ASSIGN}
                onClose={() => {
                  setOpenModal({ isOpened: 'remove-application', data: { uuid: openModal.data.uuid } });
                }}
                onSubmit={() => {
                  managingPartnerApplication({ uuid: openModal.data.uuid, acceptance: true });
                  onClose();
                }}
              />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
export default CompanyData;

CompanyData.propTypes = {
  editUser: func.isRequired,
  formatMessage: func.isRequired,
  managingPartnerApplication: func.isRequired,
  onClose: func.isRequired,
  openModal: bool.isRequired,
  setOpenModal: object.isRequired,
  users: object.isRequired,
};

import { func } from 'prop-types';
import React from 'react';
import { Modal, RegularField, Button, SelectField } from 'shared/components';
import { phonePrefixOptions } from 'shared/utils';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { VALIDATION_FIELD, MODAL_TYPE } from 'shared/consts';

const DifferentDeliveryAddressModal = ({ newDeliveryAddress, setNewDeliveryAddress, formatMessage, setDeliverySelection }) => {
  const { isModalOpen } = newDeliveryAddress;

  const handleOnSubmit = async values => {
    setNewDeliveryAddress({ values, isModalOpen: false });
    setDeliverySelection('custom');
    localStorage.setItem('customDeliveryAddress', JSON.stringify(values));
  };

  const validation = () =>
    Yup.object().shape({
      city: Yup.string(VALIDATION_FIELD(formatMessage).REQUIRED)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .min(2, VALIDATION_FIELD(formatMessage).MIN_2)
        .max(50, VALIDATION_FIELD(formatMessage).MAX_50),
      email: Yup.string().email(VALIDATION_FIELD(formatMessage).EMAIL).required(VALIDATION_FIELD(formatMessage).REQUIRED),
      first_name: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .min(2, VALIDATION_FIELD(formatMessage).MIN_2)
        .max(20, VALIDATION_FIELD(formatMessage).MAX_20),
      house_number: Yup.string(VALIDATION_FIELD(formatMessage).REQUIRED)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .max(30, VALIDATION_FIELD(formatMessage).MAX_30),
      last_name: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .min(2, VALIDATION_FIELD(formatMessage).MIN_2)
        .max(50, VALIDATION_FIELD(formatMessage).MAX_50),
      phone_number: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .min(9, VALIDATION_FIELD(formatMessage).DIGITS_9)
        .max(9, VALIDATION_FIELD(formatMessage).DIGITS_9),
      phone_prefix: Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED),
      post_code: Yup.string(VALIDATION_FIELD(formatMessage).REQUIRED)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .test('post-code', VALIDATION_FIELD(formatMessage).POST_CODE, val => val && !!/^[0-9]{2}-[0-9]{3}$/i.test(val)),
      street: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .min(2, VALIDATION_FIELD(formatMessage).MIN_2)
        .max(50, VALIDATION_FIELD(formatMessage).MAX_50),
    });

  return (
    <Modal
      className={MODAL_TYPE.BASIC}
      onClose={() => setNewDeliveryAddress({ isModalOpen: false, values: newDeliveryAddress.values })}
      opened={isModalOpen}
    >
      <div className="modal__heading modal__heading--center">Adres dostawy</div>
      <div>
        <Formik
          validationSchema={validation()}
          initialValues={{
            city: newDeliveryAddress?.values?.city || '',
            email: newDeliveryAddress?.values?.email || '',
            first_name: newDeliveryAddress?.values?.first_name || '',
            house_number: newDeliveryAddress?.values?.house_number || '',
            last_name: newDeliveryAddress?.values?.last_name || '',
            phone_number: newDeliveryAddress?.values?.phone_number || '',
            phone_prefix: newDeliveryAddress?.values?.phone_prefix || '+48',
            post_code: newDeliveryAddress?.values?.post_code || '',
            street: newDeliveryAddress?.values?.street || '',
          }}
          onSubmit={handleOnSubmit}
        >
          {({ handleSubmit, errors }) => (
            <form className="address-form address-form--modal" onSubmit={handleSubmit}>
              <Field
                type="text"
                label={formatMessage({ id: 'inputs.name' })}
                name="first_name"
                component={RegularField}
                errors={errors}
              />
              <Field
                type="text"
                label={formatMessage({ id: 'inputs.last_name' })}
                name="last_name"
                component={RegularField}
                errors={errors}
              />
              <Field
                type="text"
                label={formatMessage({ id: 'inputs.email' })}
                name="email"
                component={RegularField}
                errors={errors}
              />
              <div className="one-line">
                <Field
                  name="phone_prefix"
                  className="advertisements__filters--sort"
                  placeholder={formatMessage({ id: 'inputs.choose' })}
                  label={`${formatMessage({ id: 'global.telephone' })}`}
                  options={phonePrefixOptions()}
                  component={SelectField}
                  errors={errors}
                  isCustomLabel
                />
                <Field type="text" name="phone_number" component={RegularField} errors={errors} />
              </div>
              <Field
                type="text"
                label={formatMessage({ id: 'inputs.street' })}
                name="street"
                component={RegularField}
                errors={errors}
              />
              <Field
                type="text"
                label={formatMessage({ id: 'inputs.build_number' })}
                name="house_number"
                component={RegularField}
                errors={errors}
              />
              <Field
                type="text"
                label={formatMessage({ id: 'inputs.apartement_number' })}
                name="apartment_number"
                component={RegularField}
                errors={errors}
              />
              <Field
                type="text"
                label={formatMessage({ id: 'inputs.post_code' })}
                name="post_code"
                component={RegularField}
                errors={errors}
              />
              <Field
                type="text"
                label={formatMessage({ id: 'inputs.city' })}
                name="city"
                component={RegularField}
                errors={errors}
              />
              <div className="modal-actions__partner buttons">
                <Button
                  label={formatMessage({ id: 'inputs.cancel' })}
                  className="btn--orange-border"
                  onClick={() => setNewDeliveryAddress({ isModalOpen: false, values: newDeliveryAddress.values })}
                />
                <Button
                  label={formatMessage({ id: 'inputs.save' })}
                  className="btn--orange"
                  type="submit"
                  onClick={() => handleSubmit()}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default DifferentDeliveryAddressModal;

DifferentDeliveryAddressModal.propTypes = {
  formatMessage: func.isRequired,
  newDeliveryAddress: func.isRequired,
  setDeliverySelection: func.isRequired,
  setNewDeliveryAddress: func.isRequired,
};

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login } from 'store/actions/auth';
import { activateAccount } from 'store/actions/user';
import { isMobile as checkIsMobile } from 'shared/utils';
import { injectIntl } from 'react-intl';
import Login from './Login.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      login: data => login(data),
      activateAccount: data => activateAccount(data),
    },
    dispatch,
  ),
});

const mapStateToProps = () => ({
  isMobile: checkIsMobile(),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Login));

import React from 'react';
import EmptyViewImg from 'assets/files/empty_view.png';
import { Button } from 'shared/components';
import { func } from 'prop-types';
import { SVG_TYPE } from 'shared/consts';
import { history } from 'store/store';

const EmptyView = ({ formatMessage }) => (
  <div className="advertisements__empty">
    <img className="advertisements__empty--image" src={EmptyViewImg} alt="brak obserwowanych ogłoszeń" />
    <p className="advertisements__empty--header">{formatMessage({ id: 'global.dont_have_active_addvertisement' })}</p>
    <p className="advertisements__empty--descript">{formatMessage({ id: 'global.addvertisement_here' })}</p>
    <Button
      label={formatMessage({ id: 'inputs.add_advertisement' })}
      iconType={SVG_TYPE.PLUS}
      className="advertisements__empty--button btn--orange"
      onClick={()=> history.push('/add-advertisement')}
    />
  </div>
);

export default EmptyView;

EmptyView.propTypes = {
  formatMessage: func.isRequired,
};

import React from 'react';
import { SVG, PartnerBadge } from 'shared/components';
import { SVG_TYPE, USER_ROLES } from 'shared/consts';
import { func, object } from 'prop-types';

const Seller = ({ formatMessage, data, authorizedUser,deliveryCountries }) => {
  const cheapestDeliveryPrice = data?.delivery?.map(item => item.price).sort()[0];
  const deliveryCountryUuid = authorizedUser ? authorizedUser.country.label : localStorage.getItem('deliveryCountry');
  const name =
    data?.country?.uuid === deliveryCountryUuid ? `${data.owner.first_name} ${data.owner.last_name}` : 'Get Motor Parts';
  const locationForNotAuthorizedUser =
    deliveryCountryUuid !== deliveryCountries?.find(item=> item.label==='Poland')?.value ? 'Küstriner Vorland' : 'Dormowo 45';
  const locaton = data?.country?.uuid === deliveryCountryUuid && !data.is_allegro ? data?.location : locationForNotAuthorizedUser;
  return (
    <div className="seller">
      <div className="seller--top">
        {formatMessage({ id: 'global.seller' })}:
        <div className="seller__label">
          <p className="seller__label--name">{data.is_allegro ? 'GETMOTOR' : name}</p>
          {data?.owner?.role === USER_ROLES.PARTNER && <PartnerBadge formatMessage={formatMessage} />}
        </div>
      </div>
      <div className="seller__location">
        <h3 className="seller__location__header">{formatMessage({ id: 'global.localization' })}</h3>
        <p className="seller__location__label">
          <SVG className="seller__pin" type={SVG_TYPE.PIN} />
          {locaton}
        </p>
      </div>
      <div className="seller__delivery">
        <h3 className="seller__delivery__header">{formatMessage({ id: 'global.delivery_from' })}:</h3>
        <p className="seller__delivery__label">
          {cheapestDeliveryPrice} <span className="currency">{data.currency}</span>
        </p>
      </div>
    </div>
  );
};

export default Seller;

Seller.propTypes = {
  authorizedUser: object.isRequired,
  data: object.isRequired,
  deliveryCountries: object.isRequired,
  formatMessage: func.isRequired,
};

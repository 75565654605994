import API from 'shared/connectors/config';

export function activateAccount(code) {
  return API.put(`users/activate/?code=${code.replace(/['"]+/g, '')}`);
}

export function getUserDetails(uuid) {
  return API.get(`users/show/${uuid}`);
}

export function resetPassword(email, lang) {
  return API.get(`users/password/reset/?email=${email}&language=${lang.toUpperCase()}`);
}

export function setPassword(data) {
  return API.put(`users/new-password`, data);
}

export function setNewPassword(data) {
  return API.put('users/password/reset', data);
}

export function setUserPassword(data) {
  return API.put('users/password', data);
}

export function userRegister(data) {
  return API.post('users', data);
}

export function getAuthorizedUser() {
  return API.get('users/me');
}

export function checkToken(token, which) {
  return API.get(`users/password/reset/check/?${which}=${token}`);
}

export function getUsersByRole(role) {
  return API.get(`users/list/?role=${role}`);
}

export function getUsers() {
  return API.get(`users/list`);
}

export function userSuspension(uuid) {
  return API.put(`users/suspended/${uuid}`);
}

export function editUser(uuid, form) {
  return API.put(`users/admin/${uuid}`, form);
}

export function userResendLink(email) {
  return API.get(`users/resend-link/?email=${email}`);
}

export function addUserByAdmin(data) {
  return API.post(`users/admin`, data);
}

export function editUserByAdmin(uuid, form) {
  return API.put(`users/admin/${uuid}`, form);
}

export function getUsersForApplication() {
  return API.get(`clients/label`);
}

export function userByAdminResendLink(uuid) {
  return API.put(`users/new-password/link`, { uuid });
}

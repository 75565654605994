import { connect } from 'react-redux';
import { isMobile as checkIsMobile } from 'shared/utils';
import { bindActionCreators } from 'redux';
import {
  addCategory,
  addSubcategory,
  deleteCategory,
  editCategory,
  deleteSubcategory,
  editSubcategory,
  getAllCategoryAndSubcategory,
} from 'store/actions/category';
import { deleteFiles } from 'store/actions/utils';
import { getCategoryAndSubcategory } from 'store/selectors/categorySelector';
import { injectIntl } from 'react-intl';
import * as ViewManagementSelector from 'store/selectors/viewManagementSelector';
import CategoryManagement from './CategoryManagement.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      addCategory: data => addCategory(data),
      addSubcategory: data => addSubcategory(data),
      deleteCategory: uuid => deleteCategory(uuid),
      deleteSubcategory: uuid => deleteSubcategory(uuid),
      editCategory: (uuid, data) => editCategory(uuid, data),
      editSubcategory: (uuid, data) => editSubcategory(uuid, data),
      deleteFiles: uuid => deleteFiles(uuid),
      getAllCategoryAndSubcategory: () => getAllCategoryAndSubcategory(),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  isMobile: checkIsMobile(),
  categoriesAndSubcategories: getCategoryAndSubcategory(state),
  translation: ViewManagementSelector.getTranslation(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CategoryManagement));

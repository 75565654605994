import { USER_ROLES } from 'shared/consts';

const userRoleOptions = formatMessage => [
  {
    label: formatMessage({ id: 'global.storeman' }),
    searchableLabel: formatMessage({ id: 'global.storeman' }),
    value: USER_ROLES.STOREMAN,
  },
  {
    label: formatMessage({ id: 'global.coordinator' }),
    searchableLabel: formatMessage({ id: 'global.coordinator' }),
    value: USER_ROLES.COORDINATOR,
  },
];

export default userRoleOptions;

import React from 'react';
import { string } from 'prop-types';
import { SVG } from 'shared/components';
import { SVG_TYPE, NOTIFY_TYPE } from 'shared/consts';
import { FormattedMessage } from 'react-intl';

const Notify = ({ label, type }) => (
  <div className="notify">
    {type === NOTIFY_TYPE.CHECKED && <SVG className="notify__icon" type={SVG_TYPE.CIRCLE_CHECKED} />}
    {type === NOTIFY_TYPE.ADD_TO_FAVORITE && <SVG className="notify__icon" type={SVG_TYPE.FULL_FILL_HEART} />}
    {type === NOTIFY_TYPE.REMOVE_FROM_FAVORITE && <SVG className="notify__icon" type={SVG_TYPE.FULL_HEART} />}
    <p className="notify__label">
      <FormattedMessage id={label} />
    </p>
  </div>
);

export default Notify;

Notify.propTypes = {
  label: string.isRequired,
  type: string.isRequired,
};

const ALLEGRO = {
  GET_URL_INIT: 'GET_URL_INIT',
  GET_URL_SUCCESS: 'GET_URL_SUCCESS',
  GET_URL_FAILURE: 'GET_URL_FAILURE',
  GET_ALLEGRO_USER_INIT: 'GET_ALLEGRO_USER_INIT',
  GET_ALLEGRO_USER_SUCCESS: 'GET_ALLEGRO_USER_SUCCESS',
  GET_ALLEGRO_USER_FAILURE: 'GET_ALLEGRO_USER_FAILURE',
};

export default ALLEGRO;

import React, { useEffect } from 'react';
import { bool, func, shape, object, array, string } from 'prop-types';
import { Header, CategoryList } from './components';

const CategoryManagement = ({
  isMobile,
  categoriesAndSubcategories,
  translation,
  actions: {
    addCategory,
    addSubcategory,
    deleteCategory,
    editCategory,
    editSubcategory,
    deleteSubcategory,
    deleteFiles,
    getAllCategoryAndSubcategory,
  },
  intl: { formatMessage },
}) => {
  useEffect(() => {
    getAllCategoryAndSubcategory()
  }, [])
  
  return (
    <>
      <div className="categoryManagement__wrapper">
        <div className="categoryManagement__content">
          <h1 className="categoryManagement--heading">{formatMessage({ id: 'global.category_subcategory' })}</h1>
          <Header formatMessage={formatMessage} isMobile={isMobile} addCategory={addCategory} />
          <CategoryList
            translation={translation}
            formatMessage={formatMessage}
            deleteSubcategory={deleteSubcategory}
            editCategory={editCategory}
            editSubcategory={editSubcategory}
            deleteCategory={deleteCategory}
            addSubcategory={addSubcategory}
            categories={categoriesAndSubcategories}
            isMobile={isMobile}
            deleteFiles={deleteFiles}
          />
        </div>
      </div>
      {/* {console.log(categoriesAndSubcategories.category)} */}
    </>
  );
};

export default CategoryManagement;

CategoryManagement.defaultProps = {
  actions: shape({
    getAllCategory: () => console.log(''),
  }),
  categories: {},
  formatMessage: () => console.log(''),
};
CategoryManagement.propTypes = {
  actions: shape({
    addCategory: func.isRequired,
    addSubcategory: func.isRequired,
    deleteCategory: func.isRequired,
    deleteFiles: func.isRequired,
    deleteSubcategory: func.isRequired,
    editCategory: func.isRequired,
    editSubcategory: func.isRequired,
    getAllCategory: func,
  }),
  categories: object,
  categoriesAndSubcategories: array.isRequired,
  formatMessage: func,
  isMobile: bool.isRequired,
  translation: string.isRequired,
};

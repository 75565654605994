import React, { useEffect, useState } from 'react';
import { Button, ProductBadge } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { func, object, string, bool } from 'prop-types';
import moment from 'moment';
import { ProductButtons } from 'screens/single-product/components';

const ProductName = ({
  formatMessage,
  data,
  translation,
  addToFavorites,
  removeFromFavorites,
  authorizedUser,
  modifyShoppingCart,
  InputCounter,
  shoppingCart,
  isMobile,
}) => {
  const handleRemoveFromFavorites = () => removeFromFavorites(data.uuid);
  const handleAddToFavorites = () => addToFavorites(data.uuid);
  const basketElement = data.uuid && shoppingCart?.shopping_cart?.products.find(item => item.uuid === data.uuid);
  const [amount, setAmount] = useState(1);
  const productUuid = window.location.pathname.split('/')[3];
  const productInBasket = shoppingCart?.shopping_cart?.products.find(item => item.uuid === productUuid);
  const amountInBasket = productInBasket?.pivot_shopping_cart_amount;

  useEffect(() => {
    setAmount(basketElement?.pivot_shopping_cart_amount || 1);
  }, [basketElement]);

  return (
    <div className="product-name">
      {!isMobile && <h1 className="product-name--label">{translation === 'pl' ? data.title : data.title_ger}</h1>}
      <p className="product-name--price">
        {data.price}
        <span className="currency">{data.currency}</span>
      </p>
      <p className="product-name--price-brutto">{formatMessage({ id: 'global.gross_price' })}</p>
      <div className="product-name--bottom">
        <p className="product-name--date">
          {formatMessage({ id: 'global.added' })} {moment(data.created_at).format('DD.MM.YYYY')}
        </p>
        <div className="product-name--watching">
          <Button
            className={`${authorizedUser ? 'btn--gray' : 'btn--watching--disabled'}`}
            label={formatMessage({ id: 'inputs.watch' })}
            iconType={data.is_favorite ? SVG_TYPE.FULL_FILL_HEART : SVG_TYPE.FULL_HEART}
            onClick={data.is_favorite ? handleRemoveFromFavorites : handleAddToFavorites}
          />
          {!authorizedUser && (
            <article className="product-name--watching--tooltip">{formatMessage({ id: 'global.watchlist_tooltip' })}</article>
          )}
        </div>
      </div>
      {!data.is_allegro && (
        <ProductBadge
          type="green"
          label={formatMessage({ id: 'global.in_stock' })}
          descript={formatMessage({ id: 'global.in_stock_descript' })}
        />
      )}
      <p className={`product-name--count ${!data.is_allegro ? '' : 'product-name--count--margin-top'}`}>
        {formatMessage({ id: 'global.count' })}
      </p>
      <InputCounter
        amountInBasket={amountInBasket}
        data={data}
        setAmount={setAmount}
        amount={amount}
        formatMessage={formatMessage}
      />
      <ProductButtons
        shoppingCart={shoppingCart}
        amount={amount}
        authorizedUser={authorizedUser}
        data={data}
        modifyShoppingCart={modifyShoppingCart}
        formatMessage={formatMessage}
      />
    </div>
  );
};

export default ProductName;

ProductName.propTypes = {
  addToFavorites: func.isRequired,
  authorizedUser: object.isRequired,
  data: object.isRequired,
  formatMessage: func.isRequired,
  InputCounter: func.isRequired,
  isMobile: bool.isRequired,
  modifyShoppingCart: func.isRequired,
  removeFromFavorites: func.isRequired,
  shoppingCart: object.isRequired,
  translation: string.isRequired,
};

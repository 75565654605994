import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { func, bool, string } from 'prop-types';
import { Modal, ModalActions, RegularField, PhotoCategory } from 'shared/components';
import { MODAL_TYPE, VALIDATION_FIELD } from 'shared/consts';
import { Field, Formik } from 'formik';

const AddCategoryModal = ({
  isOpened,
  setIsModalOpened,
  addCategory,
  title,
  subcategory,
  addSubcategory,
  categoryUuid,
  formatMessage,
  handleAddSubcategory,
}) => {
  const [fileInputValue, setFileInputValue] = useState('');
  const [isSetCategoryPhoto, setIsSetCategoryPhoto] = useState(false);
  const formRef = useRef(null);

  const onClose = () => {
    setIsModalOpened(false);
    setIsSetCategoryPhoto(false);
  };
  const isLinkTaken = response =>
    response.data?.message === 'This link is actually taken' ||
    response.data?.message === 'Link is already taken' ||
    response.data?.message === 'Link is already taken';

  const wrongPhotoFile = response => response === 'cannot add this photo';

  const onSubmit = async values => {
    if (!subcategory) {
      if (fileInputValue.length > 0 && isSetCategoryPhoto !== 'empty') {
        const response = await addCategory({ ...values, files: fileInputValue });

        if (isLinkTaken(response) || wrongPhotoFile(response)) {
          isLinkTaken(response) &&
            formRef.current.setFieldError('link', formatMessage({ id: 'validation.this_link_is_actually_taken' }));
          wrongPhotoFile(response) && formRef.current.setFieldError('files', formatMessage({ id: 'validation.wrong_file_type' }));
        } else {
          setIsModalOpened(false);
          setIsSetCategoryPhoto(null);
          setFileInputValue(null);
        }
      } else {
        setIsSetCategoryPhoto('empty');
      }
    } else if (subcategory) {
      const response = await addSubcategory({ ...values, uuid: categoryUuid });
      if (isLinkTaken(response) || wrongPhotoFile(response)) {
        isLinkTaken(response) &&
          formRef.current.setFieldError('link', formatMessage({ id: 'validation.this_link_is_actually_taken' }));
        wrongPhotoFile(response) && formRef.current.setFieldError('files', formatMessage({ id: 'validation.wrong_file_type' }));
      } else {
        setIsModalOpened(false);
      }
      handleAddSubcategory();
    }
  };

  const handleFileAdd = e => {
    setFileInputValue(e.target.files);
  };
  const validation = () =>
    Yup.object().shape({
      link: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254)
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE),
      name: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254)
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE),
      name_ger: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254)
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE),
    });
  return (
    <div>
      {isOpened && (
        <Modal className={`${MODAL_TYPE.CANCEL} categories`} onClose={onClose} opened={isOpened}>
          <div>
            <div className="modal__heading modal__heading--center">{title}</div>
            <Formik
              innerRef={formRef}
              validationSchema={validation()}
              initialValues={{ name: '', link: '', name_ger: '' }}
              onSubmit={onSubmit}
            >
              {({ handleSubmit, errors }) => (
                <form className="categoryManagement__addCategory__wrapper" onSubmit={handleSubmit}>
                  <div className="categoryManagement__addCategory__content">
                    <Field
                      type="text"
                      label={
                        !subcategory
                          ? formatMessage({ id: 'global.category_name_pl' })
                          : formatMessage({ id: 'global.subcategory_name_pl' })
                      }
                      name="name"
                      component={RegularField}
                      errors={errors}
                    />
                    <Field
                      type="text"
                      label={
                        !subcategory
                          ? formatMessage({ id: 'global.category_name_de' })
                          : formatMessage({ id: 'global.subcategory_name_de' })
                      }
                      name="name_ger"
                      component={RegularField}
                      errors={errors}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: 'global.link' })}
                      name="link"
                      component={RegularField}
                      errors={errors}
                    />
                    {!subcategory && (
                      <>
                        <Field
                          formatMessage={formatMessage}
                          name="files"
                          handleChange={e => handleFileAdd(e)}
                          className="add-advertisement__field add-advertisement__field--textarea"
                          component={PhotoCategory}
                          isSetCategoryPhoto={isSetCategoryPhoto}
                          setIsSetCategoryPhoto={setIsSetCategoryPhoto}
                          setFileInputValue={setFileInputValue}
                        />
                        {(isSetCategoryPhoto === 'empty' || errors?.files) && (
                          <p className="add__photos--error add__photos--error2">
                            {isSetCategoryPhoto === 'empty' && formatMessage({ id: VALIDATION_FIELD(formatMessage).REQUIRED })}{' '}
                            {errors?.files && errors?.files}
                          </p>
                        )}
                      </>
                    )}
                    {/* {!subcategory && <Button label="Dodaj zdjęcie +" className="btn--gray categoryManagement__header--button" />} */}
                    <ModalActions
                      formatMessage={formatMessage}
                      type={MODAL_TYPE.ADD_CATEGORY}
                      onClose={onClose}
                      onSubmit={() => {
                        handleSubmit();
                        !fileInputValue && setIsSetCategoryPhoto('empty');
                      }}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
    </div>
  );
};
export default AddCategoryModal;

AddCategoryModal.defaultProps = {
  addCategory: () => console.log(''),
  addFiles: () => console.log(''),
  addSubcategory: () => console.log(''),
  categoryUuid: '',
  subcategory: false,
};
AddCategoryModal.propTypes = {
  addCategory: func,
  addFiles: func,
  addSubcategory: func,
  categoryUuid: string,
  formatMessage: func.isRequired,
  handleAddSubcategory: func.isRequired,
  hiddenSubcategory: func.isRequired,
  isOpened: bool.isRequired,
  setIsModalOpened: func.isRequired,
  subcategory: bool,
  title: string.isRequired,
};

/* eslint-disable react/prop-types */
import { func, array, bool } from 'prop-types';
import React, { useState } from 'react';
import { Table } from 'shared/components';
import { Settings, EditForm, LocalMargin, ForeignMargin, Currency } from 'screens/price-management/components';
import { deliveryCountryLabelShort } from 'shared/utils';

const Countries = ({ formatMessage, isMobile, deliveryCountries, editPriceMargin, createPriceMargin }) => {
  const [settingsType, setSettingsType] = useState({ active: '', id: '' });
  // const {
  //   priceMargin: { foreignMargin, value },
  // } = deliveryCountries;

  const getColumns = () => [
    {
      id: 'country',
      width: !isMobile ? '100' : '30',
      Cell: ({
        original: {
          country,
        },
      }) => deliveryCountryLabelShort(country),
    },
    {
      width: !isMobile ? '190' : '70',
      Cell: ({ original }) => (
        <>
          <LocalMargin data={original} />
        </>
      ),
    },
    {
      width: !isMobile ? '310' : '100',
      Cell: ({ original }) => (
        <>
          <ForeignMargin data={original} />
        </>
      ),
    },
    {
      width: !isMobile ? '190' : '50',
      Cell: ({ original }) => (
        <>
          <Currency data={original} />
        </>
      ),
    },
    {
      width: !isMobile ? '100' : '50',
      Cell: ({ original }) => (
        <>
          <Settings
            id={original.id}
            settingsType={settingsType}
            setSettingsType={setSettingsType}
            formatMessage={formatMessage}
            data={original}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <div className="price-management__countries--wrapper">
        {!isMobile && (
          <div className="price-management__countries--heading">
            <p className="price-management__countries--heading--country">{formatMessage({ id: 'global.country' })}</p>
            <p className="price-management__countries--heading--earnings">{formatMessage({ id: 'global.earnings' })}</p>
            <p className="price-management__countries--heading--earnings-foreign">
              {formatMessage({ id: 'global.foreign_earnings' })}
            </p>
            <p className="price-management__countries--heading--currency">{formatMessage({ id: 'global.currency_name' })}</p>
          </div>
        )}
        {isMobile && (
          <div className="price-management__countries--heading-mobile">
            <p className="price-management__countries--heading-mobile--earnings">{formatMessage({ id: 'global.earnings' })}</p>
            <p className="price-management__countries--heading-mobile--earnings-foreign">
              {formatMessage({ id: 'global.foreign_earnings' })}
            </p>
          </div>
        )}
        <div className="user-management__table__content price-management__table countries">
          {deliveryCountries && <Table data={deliveryCountries} columns={getColumns()} />}
        </div>
      </div>
      <EditForm
        countries
        type="countries"
        formatMessage={formatMessage}
        settingsType={settingsType}
        setSettingsType={setSettingsType}
        editPriceMargin={editPriceMargin}
        createPriceMargin={createPriceMargin}
      />
    </>
  );
};

export default Countries;

Countries.propTypes = {
  createPriceMargin: func.isRequired,
  deliveryCountries: array.isRequired,
  editPriceMargin: func.isRequired,
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
};

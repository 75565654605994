const thisSameDeliveryMethod = (array, signleForeignDelivery) => {
  if (array.length > 1) {
    const duplicateIds = array
      .map(e => e.id)
      .map((e, i, final) => final.indexOf(e) !== i && i)
      .filter(obj => array[obj])
      .map(e => array[e].id);

    const duplicate = array.filter(obj => duplicateIds.includes(obj.id));

    const unique = duplicate
      .map(e => e.id)
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(obj => duplicate[obj])
      .map(e => duplicate[e]);

    if (signleForeignDelivery) {
      return array;
    }
    return unique;
  }
  return array;
};

export default thisSameDeliveryMethod;

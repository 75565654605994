import React from 'react';
import * as Connector from 'shared/connectors/productsConnector';
import { PRODUCTS } from 'store/types';
import { NOTIFY_TYPE } from 'shared/consts';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';

export const success = uuid => ({
  type: PRODUCTS.DELETE_PRODUCT_WAREHOUSE_SUCCESS,
  payload: {
    uuid,
  },
});

const deleteProduct = uuid => async dispatch => {
  try {
    const { data } = await Connector.deleteAdvertisement(uuid);
    toast(<Notify label="global.product_deleted" type={NOTIFY_TYPE.CHECKED} />);
    dispatch(success(uuid));
    return data;
  } catch (err) {
    return err.response;
  }
};

export default deleteProduct;

import API from 'shared/connectors/config';

export function getCheckout({ status, title }) {
  return API.get(`checkout`, { params: { status: (status === 'all' ? null : status), title } });
}

export function getCountCheckoutStatus() {
  return API.get(`count-checkout-status`);
}

export function setCheckout(data) {
  return API.post(`checkout`, data);
}

export function simulateCheckout(data) {
  return API.post(`simulate-checkout`, data);
}

export function cancelCheckout(checkout_uuid) {
  return API.post(`cancel-checkout`, { checkout_uuid });
}

import React from 'react';
import { ProductBadge } from 'shared/components';
import { func, object, string } from 'prop-types';
import moment from 'moment';

const ProductName = ({ formatMessage, data, translation, authorizedUser }) => {
  const currency = authorizedUser.country_id === 1 ? 'PLN' : '€';
  return (
    <div className="product-name">
      <h1 className="product-name--label">{translation === 'pl' ? data.title : data.title_ger}</h1>
      <p className="product-name--price">
        {data.price} {currency}
      </p>
      <p className="product-name--price-brutto">{formatMessage({ id: 'global.gross_price' })}</p>
      <div className="product-name--bottom">
        <p className="product-name--date product-name--date--margin-top-20px">
          {formatMessage({ id: 'global.added' })} {moment(data.created_at).format('DD.MM.YYYY')}
        </p>
      </div>
      <ProductBadge
        type="green"
        label={formatMessage({ id: 'global.in_stock' })}
        descript={formatMessage({ id: 'global.in_stock_descript' })}
      />
    </div>
  );
};

export default ProductName;

ProductName.propTypes = {
  addToFavorites: func.isRequired,
  authorizedUser: object.isRequired,
  data: object.isRequired,
  formatMessage: func.isRequired,
  InputCounter: func.isRequired,
  modifyShoppingCart: func.isRequired,
  removeFromFavorites: func.isRequired,
  translation: string.isRequired,
};

import React from 'react';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import * as Connector from 'shared/connectors/customTranslationsConnector';
import { NOTIFY_TYPE } from 'shared/consts';
import { CUSTOM_TRANSLATIONS } from 'store/types';

export const success = () => ({
  type: CUSTOM_TRANSLATIONS.ADD_CUSTOM_TRANSLATIONS_SUCCESS,
});

const failure = () => ({
  type: CUSTOM_TRANSLATIONS.ADD_CUSTOM_TRANSLATIONS_FAILURE,
});

const addCustomTranslations = dataForm => async dispatch => {
  try {
    const { data } = await Connector.addCustomTranslations(dataForm);
    toast(<Notify label="global.successful_add_word" type={NOTIFY_TYPE.CHECKED} />);
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default addCustomTranslations;

import React, { useState } from 'react';
import { func, bool, string, array } from 'prop-types';
import { Button } from 'shared/components';
import UsersMenu from '../users-menu';
import { AddUser } from './components';

const Heading = ({ formatMessage, deliveryCountries, menuType, setMenuType, isMobile, translation, addUserByAdmin }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="user-management__top">
      <div className="user-management__heading">
        <p className="user-management--heading">{formatMessage({ id: 'global.user_management' })}</p>
        <Button
          onClick={() => setOpenModal(true)}
          label={formatMessage({ id: 'inputs.add_plus' })}
          className="btn--gray categoryManagement__header--button"
        />
      </div>
      <AddUser
        setMenuType={setMenuType}
        addUserByAdmin={addUserByAdmin}
        deliveryCountries={deliveryCountries}
        translation={translation}
        onClose={() => setOpenModal(false)}
        formatMessage={formatMessage}
        isOpened={openModal}
      />
      <UsersMenu formatMessage={formatMessage} menuType={menuType} setMenuType={setMenuType} isMobile={isMobile} />
    </div>
  );
};

export default Heading;

Heading.propTypes = {
  addUserByAdmin: func.isRequired,
  deliveryCountries: array.isRequired,
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
  menuType: string.isRequired,
  setMenuType: func.isRequired,
  translation: string.isRequired,
};

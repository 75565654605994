import * as Connector from 'shared/connectors/authConnector';
import { AUTH } from 'store/types';


export const init = () => ({
    type: AUTH.GET_CHECKOUT_ADDRESS_INIT,
  });

export const success = (data) => ({
  type: AUTH.GET_CHECKOUT_ADDRESS_SUCCESS,
  payload: {
    data
}
});
const failure = () => ({
    type: AUTH.GET_CHECKOUT_ADDRESS_FAILURE,
});
  
const getCheckoutAddress = () => async dispatch => {
    dispatch(init)
  try {
    const data = await Connector.getCheckoutAddress();
   dispatch(success(data))
    return data;
  } catch (err) {
      dispatch(failure() )
    return err.response.status;
  }
};

export default getCheckoutAddress;

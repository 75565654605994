import React from 'react';
import { Button, SelectField, SVG } from 'shared/components';
import { ROUTES, SVG_TYPE } from 'shared/consts';
import PropTypes, { func, object } from 'prop-types';
import orderByOptions from 'shared/utils/orderByOptions';
import { categoriesOptions, hideProductsOptions } from 'shared/utils';
import { history } from 'store/store';

const AdvertisementsFilters = ({
  isMobile,
  setFilterData,
  filterData,
  formatMessage,
  getCategoryAndSubcategory,
  authorizedUser,
}) => {
  const isAllegroPartner = authorizedUser.role === "partner" && authorizedUser.is_allegro
  return(
    <>
      {!isMobile ? (
        <div className="advertisements__filters__wrapper">
          <div className="search-field search-field--advertisements">
            <input
              type="text"
              value={filterData.title}
              placeholder={formatMessage({ id: 'inputs.title' })}
              className="search-field--input"
              setFilterData={setFilterData}
              filterData={filterData}
              onKeyDown={e => e.key === 'Enter' && setFilterData({ ...filterData, title: e.target.value })}
              onChange={e => setFilterData({ ...filterData, title: e.target.value })}
            />
            <SVG className="search-field--icon" type={SVG_TYPE.LOUPE} />
          </div>
          <SelectField
            isCustomLabel
            className="advertisements__filters--category"
            placeholder={formatMessage({ id: 'inputs.choose' })}
            value={filterData.category_uuid}
            field={{ value: filterData.category_uuid }}
            // form={OPTIONS}
            label={formatMessage({ id: 'inputs.category' })}
            options={!!getCategoryAndSubcategory.category && categoriesOptions(getCategoryAndSubcategory.category)}
            outsideFormChange={e => setFilterData({ ...filterData, category_uuid: e.value })}
          />
          {!isAllegroPartner ? (
            <SelectField
              isCustomLabel
              className="advertisements__filters--sort"
              placeholder={formatMessage({ id: 'inputs.choose' })}
              value={filterData.orderBy}
              field={{ value: filterData.orderBy }}
              label={formatMessage({ id: 'inputs.sorting' })}
              options={orderByOptions(formatMessage)}
              outsideFormChange={e => setFilterData({ ...filterData, orderBy: e.value })}
            />
          ) : (
            <SelectField
              isCustomLabel
              className="advertisements__filters--sort"
              placeholder={formatMessage({ id: 'inputs.choose' })}
              value={filterData.hide_products}
              field={{ value: filterData.hide_products }}
              label={formatMessage({ id: 'inputs.show' })}
              options={hideProductsOptions(formatMessage)}
              outsideFormChange={e => setFilterData({ ...filterData, hide_products: e.value })}
            />
          )}
          <Button
            onClick={() => history.push(ROUTES.ADD_ADVERTISEMENT)}
            label={formatMessage({ id: 'inputs.add' })}
            iconType={SVG_TYPE.PLUS}
            className="btn--orange"
          />
        </div>
      ) : (
        <div className="advertisements__filters__wrapper">
          <SelectField
            isCustomLabel
            className="advertisements__filters--category"
            placeholder={formatMessage({ id: 'inputs.choose' })}
            onKeyDown={e => e.key === 'Enter' && setFilterData({ ...filterData, title: e.target.value })}
            field={{ value: filterData.category }}
            label="KATEGORIA"
            options={!!getCategoryAndSubcategory.category && categoriesOptions(getCategoryAndSubcategory.category)}
            outsideFormChange={e => setFilterData({ ...filterData, category_uuid: e.value })}
          />
          <SelectField
            isCustomLabel
            className="advertisements__filters--sort"
            placeholder={formatMessage({ id: 'inputs.choose' })}
            label="SORTOWANIE"
            field={{ value: filterData.orderBy }}
            options={orderByOptions(formatMessage)}
            outsideFormChange={e => setFilterData({ ...filterData, orderBy: e.value })}
          />
          {/* <Button
              label="Filtry"
              onClick={() => setIsModalOpened(true)}
              iconType={SVG_TYPE.FILTERING}
              className="btn--black-border"
            /> */}
          {/* <Button
              onClick={() => history.push(ROUTES.ADD_ADVERTISEMENT)}
              label="Dodaj"
              iconType={SVG_TYPE.PLUS}
              className="btn--orange"
            /> */}
        </div>
      )}
    </>
  );
}

export default AdvertisementsFilters;

AdvertisementsFilters.propTypes = {
  authorizedUser: object.isRequired,
  filterData: object.isRequired,
  formatMessage: func.isRequired,
  getCategoryAndSubcategory: object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  setFilterData: func.isRequired,
};

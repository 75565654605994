const CHECKOUT = {
  GET_CHECKOUT_INIT: 'GET_CHECKOUT_INIT',
  GET_CHECKOUT_SUCCESS: 'GET_CHECKOUT_SUCCESS',
  GET_CHECKOUT_FAILURE: 'GET_CHECKOUT_FAILURE',

  GET_COUNT_CHECKOUT_STATUS_INIT: 'GET_COUNT_CHECKOUT_STATUS_INIT',
  GET_COUNT_CHECKOUT_STATUS_SUCCESS: 'GET_COUNT_CHECKOUT_STATUS_SUCCESS',
  GET_COUNT_CHECKOUT_STATUS_FAILURE: 'GET_CHECKOUT_FAILURE',

  CANCEL_CHECKOUT_INIT: 'CANCEL_CHECKOUT_INIT',
  CANCEL_CHECKOUT_SUCCESS: 'CANCEL_CHECKOUT_SUCCESS',
  CANCEL_CHECKOUT_FAILURE: 'CANCEL_CHECKOUT_FAILURE',

  SET_CHECKOUT_SUCCESS: 'GET_CHECKOUT_SUCCESS',
};

export default CHECKOUT;

import * as Connector from 'shared/connectors/checkoutConnector';
import { CHECKOUT } from 'store/types';

const init = () => ({
  type: CHECKOUT.GET_CHECKOUT_INIT,
});

export const success = data => ({
  type: CHECKOUT.GET_CHECKOUT_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: CHECKOUT.GET_CHECKOUT_FAILURE,
});

const getCheckout = (values) => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getCheckout(values);
    // console.log(data);
    dispatch(success(data.checkout));
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default getCheckout;

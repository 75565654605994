import React, { useEffect, useState } from 'react';
import { func, object, shape } from 'prop-types';
import { Button, SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import MyData from './components/my-data';
import Adress from './components/address';
import AddressModal from './components/address-modal';
import Billing from './components/billing';
import BillingModal from './components/billing-moda';

const Settings = ({
  actions: { editUser, getUrl, getCheckoutAddress, addInvoiceData, getInvoiceData },
  intl: { formatMessage },
  authorizedUser,
  checkoutAddress,
  deliveryCountries,
  invoiceData,
}) => {
  const [modalManagement, setModalManagement] = useState({ which: '' });
  const isAdmin = authorizedUser.role === 'admin';
  const customCheckoutAddress = checkoutAddress?.data?.checkout_custom_address;
  const initialCountry = authorizedUser?.country;
  const [country, setCountry] = useState(initialCountry?.short);
  const adminInvoiceData = invoiceData?.data?.data?.invoice_data;
  const getCountryUuidFromShort = short =>
    deliveryCountries.length &&
    (short === 'PL'
      ? deliveryCountries.find(item => item.label === 'Poland').value
      : deliveryCountries.find(item => item.label === 'Germany').value);

  useEffect(() => {
    getCheckoutAddress();
    isAdmin && getInvoiceData(initialCountry.label);
  }, []);

  useEffect(() => {
    deliveryCountries.length && isAdmin && getInvoiceData(getCountryUuidFromShort(country));
  }, [country]);

  return (
    <main id="settings" className="categoryManagement__wrapper">
      <div className="categoryManagement__content">
        <h1 className="settings__header">{formatMessage({ id: 'menu.settings' })}</h1>
        {authorizedUser.role === 'partner' && (
          <>
            <div
              className={`settings__allegro ${authorizedUser.is_allegro ? 'settings__allegro--active' : ''}`}
              onClick={async () => {
                !authorizedUser.is_allegro ? await getUrl() : '';
              }}
            >
              {formatMessage({ id: `${authorizedUser.is_allegro ? 'global.connected_with' : 'global.connect_to'}` })}
              <span className="settings__allegro--allegro">
                <SVG type={SVG_TYPE.LOGO_ALLEGRO} />{' '}
              </span>
              {authorizedUser.is_allegro ? (' ', (<SVG type={SVG_TYPE.SUCCESS_TRUMPET} />)) : ''}
              {!authorizedUser.is_allegro ? (
                <>
                  <p className="settings__allegro--tooltip">{formatMessage({ id: 'global.allegro_tooltip' })};</p>
                  <div className="settings__allegro--tooltip--icon">
                    <SVG type={SVG_TYPE.TOOLTIP} />
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          </>
        )}
      </div>
      <div className="settings__wrapper">
        <div className="settings__my-data__wrapper">
          <p className="settings__my-data__wrapper--top">{formatMessage({ id: 'global.my_data' })}</p>
          <MyData setModalManagement={setModalManagement} authorizedUser={authorizedUser} formatMessage={formatMessage} />
        </div>
        <div className="settings__my-data__wrapper">
          <div className="settings__my-data__wrapper--top">
            <p>
              {isAdmin ? formatMessage({ id: 'global.billing_data' }) : formatMessage({ id: 'global.address_data' })}{' '}
              {isAdmin ? '' : '*'}
            </p>

            <Button
              onClick={() => setModalManagement({ which: 'address' })}
              className="btn--orange settings__submit"
              label={`${
                (isAdmin ? adminInvoiceData : customCheckoutAddress)
                  ? formatMessage({ id: 'global.edit' })
                  : formatMessage({ id: 'inputs.add' })
              } ${(isAdmin ? adminInvoiceData : customCheckoutAddress) ? '' : '+'}`}
              type="submit"
            />
          </div>
          {isAdmin ? (
            <>
              <Billing
                setModalManagement={setModalManagement}
                customCheckoutAddress={customCheckoutAddress}
                formatMessage={formatMessage}
                setCountry={setCountry}
                country={country}
                data={adminInvoiceData}
              />
              <BillingModal
                country={country}
                isOpened={modalManagement.which === 'address'}
                onClose={() => setModalManagement({ which: '' })}
                formatMessage={formatMessage}
                getCountryUuidFromShort={getCountryUuidFromShort}
                edit={!!authorizedUser.adress}
                editUser={editUser}
                addInvoiceData={addInvoiceData}
                deliveryCountries={deliveryCountries}
                data={adminInvoiceData}
              />
            </>
          ) : (
            <>
              <AddressModal
                user={customCheckoutAddress}
                isOpened={modalManagement.which === 'address'}
                onClose={() => setModalManagement({ which: '' })}
                formatMessage={formatMessage}
                edit={!!authorizedUser.adress}
                editUser={editUser}
                authorizedUser={authorizedUser}
              />
              <Adress
                setModalManagement={setModalManagement}
                customCheckoutAddress={customCheckoutAddress}
                formatMessage={formatMessage}
              />
            </>
          )}
        </div>
      </div>
    </main>
  );
};

export default Settings;

Settings.propTypes = {
  actions: shape({
    activateAccount: func.isRequired,
    registerUser: func.isRequired,
  }).isRequired,
  authorizedUser: object.isRequired,
  checkoutAddress: object.isRequired,
  deliveryCountries: object.isRequired,
  formatMessage: func.isRequired,
  invoiceData: object.isRequired,
};

import React from 'react';
import { object, func, string } from 'prop-types';

const Billing = ({ formatMessage, country, setCountry,data }) => (
  <>
    <div className="settings__content__country-menu">
      <div className={`label${country === 'PL' ? ' active' : ''} `} onClick={() => setCountry('PL')}>
        PL
      </div>
      <div className={`label${country === 'DE' ? ' active' : ''} `} onClick={() => setCountry('DE')}>
        DE
      </div>
    </div>
    <div className="settings__content settings__content--billing">
      <div className="settings__content__item">
        <p>{formatMessage({ id: 'inputs.company_name' })}</p>
        <p>{data?.company_name ? data.company_name : '-'}</p>
      </div>
      <div className="settings__content__item">
        <p>{formatMessage({ id: 'inputs.email' })}</p>
        <p>{data?.email}</p>
      </div>
      <div className="settings__content__item">
        <p>{formatMessage({ id: 'global.nip' })}</p>
        <p>{data?.nip ? data.nip : '-'}</p>
      </div>
      <div className="settings__content__item settings__content__item--empty"> </div>
      <div className="settings__content__item settings__content__item--empty"> </div>
      <div className="settings__content__item settings__content__item--last-of-col">
        <p>{formatMessage({ id: 'inputs.street' })}</p>
        <p>{data?.street ? data.street : '-'}</p>
      </div>
      <div className="settings__content__item">
        <p>{formatMessage({ id: 'inputs.build_number' })}</p>
        <p>{data?.house_number ? data.house_number : '-'}</p>
      </div>
      <div className="settings__content__item">
        <p>{formatMessage({ id: 'inputs.apartement_number' })}</p>
        <p>{data?.flat_number ? data.flat_number : '-'}</p>
      </div>
      <div className="settings__content__item">
        <p>{formatMessage({ id: 'inputs.post_code' })}</p>
        <p>{data?.postcode ? data.postcode : '-'}</p>
      </div>
      <div className="settings__content__item">
        <p>{formatMessage({ id: 'inputs.city' })}</p>
        <p>{data?.city ? data.city : '-'}</p>
      </div>
    </div>
  </>
);

export default Billing;
Billing.propTypes = {
    authorizedUser: object.isRequired,
    country: string.isRequired,
    data: object.isRequired,
    editUser: func.isRequired,
    formatMessage: func.isRequired,
    setCountry: func.isRequired,
    setModalManagement: func.isRequired,
};

import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import PropTypes from 'prop-types';
import { ROUTES } from 'shared/consts';
import { Item } from './components';

const Settings = ({ onClose, history, data, deleteAdvertisements, resumeMyProduct, formatMessage, recallMyProduct }) => (
  <div className="product-list-item__settings--wrapper">
    <OutsideClickHandler onOutsideClick={onClose}>
      <div className="product-list-item__settings">
        {/* <Item label="Podgląd" onClick={() => console.log('podgląd')} /> */}
        {/* <Item label="Statystyki" onClick={() => console.log('statystyki')} /> */}
        <Item
          label={formatMessage({ id: 'inputs.edit_advertisment' })}
          onClick={() => history.push(ROUTES.EDIT_ADVERTISEMENTS, { data })}
        />
        {data?.status === 'canceled' && (
          <Item label={formatMessage({ id: 'global.restore' })} onClick={() => resumeMyProduct(data.uuid)} />
        )}
        {data?.status === 'active' && (
          <Item label={formatMessage({ id: 'inputs.back_off' })} onClick={() => recallMyProduct(data.uuid)} />
        )}
        <Item label={formatMessage({ id: 'inputs.delete' })} onClick={() => deleteAdvertisements(data.uuid)} />
      </div>
    </OutsideClickHandler>
  </div>
);

export default Settings;

Settings.defaultProps = {
  onClose: null,
};

Settings.propTypes = {
  cancelAdvertisement: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  deleteAdvertisements: PropTypes.func.isRequired,
  formatMessage: PropTypes.func.isRequired,
  history: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  recallMyProduct: PropTypes.func.isRequired,
  resumeMyProduct: PropTypes.func.isRequired,
};

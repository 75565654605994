/* eslint react/prop-types: 0 */
import React from 'react';

const Price = ({ data }) => {
  const {currency,price} = data
    const UTIL = {
      eur: 'EUR',
      pln: 'PLN',
    };

    return (
      <span>
        {price} { UTIL[currency]}
      </span>
    );
  
};

export default Price;

import React from 'react';
// import PropTypes from 'prop-types';
// import { func, shape } from 'prop-types';
import { SVG, Button } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
// import { useHistory } from 'react-router-dom';

const InactiveAccount = ({ intl: { formatMessage } }) => (
  // const history = useHistory();

  <main className="login__wrapper">
    <div className="login__logo">
      <SVG type={SVG_TYPE.LOGO} />
    </div>

    <div className="login__form">
      <div className="login__item">
        <h1 className="login--header login--header--small">{formatMessage({ id: 'global.link_expired' })}</h1>
        <p className="login__paragraph login__paragraph--black">{formatMessage({ id: 'global.new_link_contact' })}</p>
        <Button className="btn--orange capitalize" label={formatMessage({ id: 'inputs.contact' })} type="submit" />
      </div>
    </div>
  </main>
);
// Login.propTypes = {
//   isMobile: PropTypes.bool.isRequired,
// };

export default InactiveAccount;

// InactiveAccount.propTypes = {
//   actions: shape({
//     resetPassword: func.isRequired,
//   }).isRequired,
// };

import React from 'react';
import { string } from 'prop-types';
import { Redirect, useHistory } from 'react-router-dom';
import { ROUTES, SVG_TYPE } from 'shared/consts';
import { SVG, SearchField } from 'shared/components';
import { Field, Formik } from 'formik';
import { injectIntl } from 'react-intl';

const Page404 = ({ location, intl }) => {
  const { formatMessage } = intl;
  const history = useHistory();
  const handleOnSubmit = () => {
    console.log('test');
  };
  return (
    <>
      {location === ROUTES.PAGE_404 ? (
        <div className="page404__wrapper">
          <div className="page404__content">
            <h1 className="page404--heading">404</h1>
            <h2 className="page404--descript">{formatMessage({ id: 'global.site_not_found' })}</h2>
            <div className="page404__image--wrapper">
              <SVG type={SVG_TYPE.PAGE_404} />
            </div>
            <div className="page404__searching">
              <div className="page404__searching--content">
                <Formik initialValues={{ search: '' }} onSubmit={handleOnSubmit}>
                  {({ handleSubmit, errors }) => (
                    <>
                      <form className="page404__searching--input" onSubmit={handleSubmit}>
                        <Field
                          placeholder={formatMessage({ id: 'inputs.search_item' })}
                          component={SearchField}
                          errors={errors}
                          label="email"
                          name="email"
                          type="text"
                        />
                      </form>
                    </>
                  )}
                </Formik>
                <p className="page404__searching--paragraph">
                  {formatMessage({ id: 'global.or_go_to' })}
                  <div onClick={() => history.push(ROUTES.HOME_PAGE)} className="page404__searching--bold">
                    {formatMessage({ id: 'global.home_page' })}
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Redirect to={ROUTES.PAGE_404} />
      )}
    </>
  );
};

export default injectIntl(Page404);

Page404.propTypes = {
  location: string.isRequired,
};

import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { func, bool } from 'prop-types';
import { Modal, RegularField, ModalActions, SelectField } from 'shared/components';
import { MODAL_TYPE, USER_ROLES, VALIDATION_FIELD } from 'shared/consts';
import { Field, Formik } from 'formik';
import { userRoleOptions, deliveryCountryOptions } from 'shared/utils';

const EditUserModal = ({ openModal, formatMessage, onClose, editUser, type, deliveryCountries }) => {
  const [isEmailOccupied, setIsEmailOccupied] = useState(false);

  const onSubmit = async values => {
    const response = await editUser(openModal.uuid, values, type);
    response === 422 ? setIsEmailOccupied(true) : (setIsEmailOccupied(false), onClose());
  };

  const coordinator = type === USER_ROLES.COORDINATOR;
  const storeman = type === USER_ROLES.STOREMAN;
  const client = type === USER_ROLES.CLIENT;
  const coordinatorOrStoreman = coordinator || storeman;
  const validation = () =>
    Yup.object().shape({
      country_of_delivery_uuid: coordinatorOrStoreman && Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED),
      email: Yup.string()
        .email(VALIDATION_FIELD(formatMessage).EMAIL)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .test('email-occupied', VALIDATION_FIELD(formatMessage).EMAIL_OCCUPIED, () => !isEmailOccupied),
      firstName: Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED),
      lastName: Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED),
      role: coordinatorOrStoreman && Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED),
    });

  const userRole = [
    {
      label: formatMessage({ id: 'global.partner' }),
      searchableLabel: formatMessage({ id: 'global.partner' }),
      value: USER_ROLES.PARTNER,
    },
    {
      label: formatMessage({ id: 'global.client' }),
      searchableLabel: formatMessage({ id: 'global.client' }),
      value: USER_ROLES.CLIENT,
    },
  ];

  return (
    <div>
      {openModal.isOpened && (
        <Modal
          className={`${MODAL_TYPE.CANCEL} editUser`}
          onClose={() => {
            onClose();
            setIsEmailOccupied(false);
          }}
          opened={openModal.isOpened}
        >
          <div>
            <div className="modal__heading modal__heading--center">
              {client && formatMessage({ id: 'global.edit_user_data' })}
              {coordinator && formatMessage({ id: 'global.edit_user_coordiator' })}
              {storeman && formatMessage({ id: 'global.edit_user_storeman' })}
            </div>
            <Formik
              validationSchema={validation()}
              initialValues={{
                firstName: openModal.first_name || '',
                lastName: openModal.last_name || '',
                email: openModal.email || '',
                role: (coordinatorOrStoreman && openModal.role) || (openModal.was_partner && openModal.role) || 'client',
                country_of_delivery_uuid: (coordinatorOrStoreman && openModal.countryDelivery.label) || '',
              }}
              onSubmit={onSubmit}
            >
              {({ handleSubmit, errors, setFieldTouched }) => (
                <form className="categoryManagement__addCategory__wrapper" onSubmit={handleSubmit}>
                  {useEffect(() => {
                    setFieldTouched('email', isEmailOccupied);
                  }, [isEmailOccupied])}
                  <div className="categoryManagement__addCategory__content edit--partner">
                    <Field
                      type="text"
                      label={formatMessage({ id: 'global.name' })}
                      name="firstName"
                      component={RegularField}
                      errors={errors}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: 'global.last_name' })}
                      name="lastName"
                      component={RegularField}
                      errors={errors}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: 'global.email' })}
                      inputOnChange={() => setIsEmailOccupied(false)}
                      name="email"
                      component={RegularField}
                      errors={errors}
                    />
                    {openModal.was_partner && (
                      <div className="regular-field__input">
                        <Field
                          component={SelectField}
                          placeholder={formatMessage({ id: 'inputs.choose' })}
                          label={formatMessage({ id: 'global.role' })}
                          errors={errors}
                          name="role"
                          options={userRole}
                          isCustomLabel
                        />
                      </div>
                    )}
                    {coordinatorOrStoreman && (
                      <>
                        <Field
                          className="add-advertisement__select"
                          component={SelectField}
                          placeholder={formatMessage({ id: 'inputs.choose' })}
                          label={formatMessage({ id: 'global.delivery_country' })}
                          errors={errors}
                          name="country_of_delivery_uuid"
                          options={deliveryCountryOptions(deliveryCountries, formatMessage)}
                          isCustomLabel
                        />
                        <Field
                          className="add-advertisement__select"
                          component={SelectField}
                          placeholder={formatMessage({ id: 'inputs.choose' })}
                          label={formatMessage({ id: 'global.role' })}
                          errors={errors}
                          name="role"
                          options={userRoleOptions(formatMessage)}
                          isCustomLabel
                        />
                      </>
                    )}
                    {/* {!subcategory && <Button label="Dodaj zdjęcie +" className="btn--gray categoryManagement__header--button" />} */}
                    <ModalActions
                      formatMessage={formatMessage}
                      type={MODAL_TYPE.EDIT_CATEGORY}
                      onClose={onClose}
                      onSubmit={handleSubmit}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
    </div>
  );
};
export default EditUserModal;

EditUserModal.propTypes = {
  deliveryCountries: func.isRequired,
  editUser: func.isRequired,
  formatMessage: func.isRequired,
  onClose: func.isRequired,
  openModal: bool.isRequired,
  type: bool.isRequired,
};

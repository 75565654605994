import React, { useEffect, useState } from 'react';
import { Modal, ModalActions, RegularField, SelectField, Title } from 'shared/components';
import { MODAL_TYPE, VALIDATION_FIELD } from 'shared/consts';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

const EditModal = ({
  opened,
  onClose,
  formatMessage,
  categories,
  onSubmit,
  data,
  edit,
  setEdit,
  subcategoryForCategory,
  getSubcategoryForCategory,
  translation,
}) => {
  const [subcategory, setSubcategory] = useState([]);
  const isLangPl = translation === 'pl';
  useEffect(() => {
    const subcategories = subcategoryForCategory?.subcategories;
    const arr = [];
    subcategories &&
      subcategories.forEach(({ name, name_ger, uuid }) =>
        arr.push({
          label: isLangPl ? name : name_ger,
          searchableLabel: isLangPl ? name : name_ger,
          value: uuid,
          uuid,
        }),
      );
    setSubcategory(arr);
  }, [subcategoryForCategory]);

  const getSubcategory = async (item, setFieldValue) => {
    console.log(item);
    setFieldValue('subcategory_uuid', '');
    item && (await getSubcategoryForCategory(item.value));
  };
  useEffect(async () => {
    data?.data?.categories?.uuid && (await getSubcategoryForCategory(data?.data?.categories?.uuid));
  }, [data?.data?.categories?.uuid]);
  
  const validation = () =>
    Yup.object().shape({
      amount: Yup.number(VALIDATION_FIELD(formatMessage).REQUIRED)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .test(
          'no-leading-zero',
          VALIDATION_FIELD(formatMessage).NO_ZERO,
          (value, context) => context.originalValue && !context.originalValue.toString().startsWith('0'),
        ),
        category_uuid: Yup.string(VALIDATION_FIELD(formatMessage).REQUIRED)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED),
        subcategory_uuid: Yup.string(VALIDATION_FIELD(formatMessage).REQUIRED)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED),
    });
  return (
    <>
      <Modal opened={opened} onClose={onClose} className="edit">
        <div>
          <Title title={formatMessage({ id: 'global.edit' })} className="modals" />
        </div>
        <div>
          <Formik
            validationSchema={validation()}
            initialValues={{
              amount: data?.data.amount,
              category_uuid: data?.data?.categories?.uuid,
              subcategory_uuid: data?.data?.categories?.subcategories.find(item => data?.data?.subcategory_id === item.id)?.uuid,
            }}
            onSubmit={async values => {
              await onSubmit(data?.data.uuid, values);
              onClose();
              setEdit(!edit);
            }}
          >
            {({ errors, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <div className="edit__fields">
                  <Field
                    name="amount"
                    component={RegularField}
                    errors={errors}
                    type="number"
                    label={formatMessage({ id: 'global.amount' })}
                    className="add-advertisement__field"
                  />
                  <Field
                    name="category_uuid"
                    component={SelectField}
                    errors={errors}
                    label={formatMessage({ id: 'global.category' })}
                    className="add-advertisement__select"
                    options={categories}
                    onChange={e => getSubcategory(e, setFieldValue)}
                  />
                  <Field
                    name="subcategory_uuid"
                    component={SelectField}
                    errors={errors}
                    label={formatMessage({ id: 'global.subcategory' })}
                    className="add-advertisement__select"
                    options={subcategory}
                  />
                </div>
                <ModalActions
                  type={MODAL_TYPE.EDIT_WAREHOUSE}
                  formatMessage={formatMessage}
                  onClose={onClose}
                  onSubmit={handleSubmit}
                />
              </form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default EditModal;

EditModal.propTypes = {
  categories: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  edit: PropTypes.bool.isRequired,
  formatMessage: PropTypes.func.isRequired,
  getSubcategoryForCategory: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  setEdit: PropTypes.func.isRequired,
  subcategoryForCategory: PropTypes.object.isRequired,
  translation: PropTypes.string.isRequired,
};

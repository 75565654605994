import { PRICE } from 'store/types';
import { REQUEST_STATUS } from 'shared/consts';

const INITIAL_STATE = {
  priceMargin: {
    data: [],
  },
  currencyExchangeList: {
    data: [],
  },
  invoiceData: {
    data: [],
  },
};

const priceReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case PRICE.GET_CURRENCY_EXCHANGE_LIST_INIT: {
      return {
        ...state,
        currencyExchangeList: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case PRICE.GET_CURRENCY_EXCHANGE_LIST_SUCCESS: {
      return {
        ...state,
        currencyExchangeList: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PRICE.GET_CURRENCY_EXCHANGE_LIST_FAILURE: {
      return {
        ...state,
        currencyExchangeList: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case PRICE.GET_PRICE_MARGIN_INIT: {
      return {
        ...state,
        priceMargin: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case PRICE.GET_PRICE_MARGIN_SUCCESS: {
      return {
        ...state,
        priceMargin: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PRICE.GET_PRICE_MARGIN_FAILURE: {
      return {
        ...state,
        priceMargin: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case PRICE.EDIT_PRICE_MARGIN_SUCCESS: {
      const { itemUuid, type, data } = payload;
      const properItem = state.priceMargin.data[type].find(item => item.uuid === itemUuid);
      properItem.price_margin = data;
      return {
        ...state,
        priceMargin: {
          data: [...state.data],
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PRICE.DELETE_PRICE_MARGIN_SUCCESS: {
      const { itemUuid, type } = payload;
      const properItem = state.priceMargin.data[type].find(item => item.uuid === itemUuid);
      properItem.price_margin = null;
      state.priceMargin.data[type] = [...state.priceMargin.data[type]];
      return {
        ...state,
        priceMargin: {
          data: { ...state.priceMargin.data },
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PRICE.ADD_INVOICE_DATA: {
      return {
        ...state,
        invoiceData: {
          data: payload,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PRICE.GET_INVOICE_DATA_INIT: {
      return {
        ...state,
        invoiceData: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case PRICE.GET_INVOICE_DATA_SUCCESS: {
      return {
        ...state,
        invoiceData: {
          data: payload,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PRICE.GET_INVOICE_DATA_FAILURE: {
      return {
        ...state,
        invoiceData: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }

    default:
      return state;
  }
};

export default priceReducer;

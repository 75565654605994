import React, { useState, useEffect } from 'react';
import { func, bool, shape, array } from 'prop-types';
import { MENU_TYPES } from './components/const';
import { Heading, Content } from './components';

const PriceManagement = ({
  intl: { formatMessage },
  isMobile,
  actions: {
    getPriceMargin,
    getUsersByRole,
    getCurrencyExchangeList,
    editCurrencyExchange,
    editPriceMargin,
    createPriceMargin,
    deletePriceMargin,
  },
  priceMargin,
  currencyExchangeList,
  users,
}) => {
  const [menuType, setMenuType] = useState(MENU_TYPES(formatMessage).COUNTRY);

  useEffect(async () => {
    await getCurrencyExchangeList();
  }, []);

  useEffect(async () => {
    menuType.label === MENU_TYPES(formatMessage).PRODUCTS.label
      ? await getUsersByRole('partner')
      : await getPriceMargin(menuType.options);
  }, [menuType]);

  // useEffect(() => {
  //   console.log(priceMargin);
  // }, [priceMargin]);

  const handleGetProductMargin = async partner_uuid => {
    await getPriceMargin('product', partner_uuid);
  };

  return (
    <>
      <div className="categoryManagement__wrapper">
        <div className="categoryManagement__content">
          <Heading
            editCurrencyExchange={editCurrencyExchange}
            currencyExchangeList={currencyExchangeList}
            menuType={menuType}
            setMenuType={setMenuType}
            isMobile={isMobile}
            formatMessage={formatMessage}
          />
        </div>
        <div className="price-management__content">
          <Content
            partnersList={users.users}
            menuType={menuType}
            isMobile={isMobile}
            data={priceMargin}
            formatMessage={formatMessage}
            productMargin={handleGetProductMargin}
            editPriceMargin={editPriceMargin}
            createPriceMargin={createPriceMargin}
            deletePriceMargin={deletePriceMargin}
          />
        </div>
      </div>
    </>
  );
};

export default PriceManagement;

PriceManagement.propTypes = {
  actions: shape({
    createPriceMargin: func.isRequired,
    deletePriceMargin: func.isRequired,
    editPriceMargin: func.isRequired,
    getCurrencyExchangeList: func.isRequired,
    getPriceMargin: func.isRequired,
  }).isRequired,
  currencyExchangeList: array.isRequired,
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
  priceMargin: func.isRequired,
  users: array.isRequired,
};

import * as Connector from 'shared/connectors/priceConnector';
import { PRICE } from 'store/types';

const init = () => ({
  type: PRICE.GET_CURRENCY_EXCHANGE_LIST_INIT,
});

export const success = data => ({
  type: PRICE.GET_CURRENCY_EXCHANGE_LIST_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: PRICE.GET_CURRENCY_EXCHANGE_LIST_FAILURE,
});

const getCurrencyExchangeList = () => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getCurrencyExchangeList();
    dispatch(success(data.exchange_data));
    return true;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default getCurrencyExchangeList;

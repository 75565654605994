import React from 'react';
import PropTypes from 'prop-types';

const Currency = ({ data }) => {
  const UTIL = {
    eur: 'EUR',
    pln: 'PLN',
  };
  return <div>{UTIL[data?.currency]}</div>;
};

export default Currency;

Currency.defaultProps = {
  data: {},
};

Currency.propTypes = {
  data: PropTypes.object,
};

import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getAuthorizedUser } from 'store/selectors/userSelector';
import { createCheckoutSession } from 'store/actions/payments';
import { bindActionCreators } from 'redux';
import PaymentCanceled from './PaymentCanceled.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createCheckoutSession: uuid => createCheckoutSession(uuid),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  authorizedUser: getAuthorizedUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PaymentCanceled));

import React from 'react';
import { bool, func, object } from 'prop-types';
import { Button } from 'shared/components';
import { ROUTES } from 'shared/consts';
import { useHistory } from 'react-router-dom';

const BasketSummary = ({ formatMessage, mobile, onClose, data }) => {
  const history = useHistory();
  return (
    <div className="basket-modal__summary">
      <div className="basket-modal__summary--descript">
        <p className="basket-modal__summary--descript--delivery">{formatMessage({ id: 'global.value_products' })}</p>
        <p className="basket-modal__summary--descript--delivery">
          {data.all_price} <span>{data.currency}</span>{' '}
        </p>
      </div>
      {/* <div className="basket-modal__summary--descript">
        <p className="basket-modal__summary--descript--delivery">{formatMessage({ id: 'global.delivery' })}</p>
        <p className="basket-modal__summary--descript--delivery">
          0,00 <span>{data.currency}</span>
        </p>
      </div> */}
      <div className="basket-modal__summary--descript">
        <p className="basket-modal__summary--descript--payment">{formatMessage({ id: 'global.to_pay' })}</p>
        <p className="basket-modal__summary--descript--payment">
          {data.all_price} <span>{data.currency}</span>{' '}
        </p>
      </div>
      <Button
        className="btn--orange"
        label={mobile ? formatMessage({ id: 'global.buy_and_pay' }) : formatMessage({ id: 'inputs.basket' })}
        type="submit"
        onClick={() => {
          history.push(ROUTES.BASKET);
          onClose();
        }}
      />
      {mobile && (
        <Button
          className="btn btn--orange-border"
          label={formatMessage({ id: 'global.continue_shopping' })}
          type="submit"
          onClick={() => {
            history.push(ROUTES.BASKET);
            onClose();
          }}
        />
      )}
    </div>
  );
};

export default BasketSummary;

BasketSummary.propTypes = {
  data: object.isRequired,
  formatMessage: func.isRequired,
  mobile: bool.isRequired,
  onClose: func.isRequired,
};

import { func } from 'prop-types';
import { Button } from 'shared/components';
import { ROUTES } from 'shared/consts';
import { useHistory } from 'react-router-dom';
import React from 'react';

const EmptyBasket = ({ formatMessage }) => {
  const history = useHistory();

  return (
    <div className="empty-basket__wrapper">
      <div className="empty-basket__content">
        <div className="head">{formatMessage({ id: 'global.basket_empty' })}</div>

        <div className="empty-basket__actions">
          <Button
            label={formatMessage({ id: 'inputs.login' })}
            className="btn--orange-border"
            onClick={() => history.push(ROUTES.LOGIN)}
          />
          <Button
            label={formatMessage({ id: 'global.continue_shopping' })}
            className="btn--orange"
            onClick={() => history.push(`${ROUTES.CATEGORY}?s=`)}
          />
        </div>
      </div>
    </div>
  );
};

export default EmptyBasket;

EmptyBasket.propTypes = {
  formatMessage: func.isRequired,
};

import React from 'react';
import * as Connector from 'shared/connectors/productsConnector';
import { PRODUCTS } from 'store/types';
import { NOTIFY_TYPE } from 'shared/consts';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';

export const success = (uuid, values) => ({
  type: PRODUCTS.EDIT_PRODUCT_WAREHOUSE_SUCCESS,
  payload: {
    uuid,
    values,
  },
});

const editProduct = (uuid, values) => async dispatch => {
  try {
    dispatch(success(uuid, values));
    await Connector.editProduct(uuid, values);
    toast(<Notify label="global.successful_edit_product" type={NOTIFY_TYPE.CHECKED} />);
    return true;
  } catch (err) {
    return err.response;
  }
};

export default editProduct;

import React from 'react';
import { SVG, PartnerBadge } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { func, object } from 'prop-types';

const Seller = ({ formatMessage, data, authorizedUser, delivery }) => {
  const deliveryArr = [];
  data.delivery
    ? data?.delivery_uuid.map(item => deliveryArr.push(data.delivery?.find(el => el.uuid === item)))
    : data?.delivery_uuid.map(item => deliveryArr.push(delivery?.find(el => el.uuid === item)));
  const cheapestDeliveryPrice = deliveryArr?.map(item => item?.price).sort()[0];
  const currency = authorizedUser.country_id === 1 ? 'PLN' : '€';
  return (
    <div className="seller">
      <div className="seller--top">
        {formatMessage({ id: 'global.seller' })}:
        <div className="seller__label">
          <p className="seller__label--name">
            {authorizedUser.first_name} {authorizedUser.last_name}
          </p>
          {authorizedUser.role === 'partner' && <PartnerBadge formatMessage={formatMessage} />}
        </div>
      </div>
      <div className="seller__location">
        <h3 className="seller__location__header">{formatMessage({ id: 'global.localization' })}</h3>
        <p className="seller__location__label">
          <SVG className="seller__pin" type={SVG_TYPE.PIN} />
          {data.location}
        </p>
      </div>
      <div className="seller__delivery">
        <h3 className="seller__delivery__header">{formatMessage({ id: 'global.delivery_from' })}:</h3>
        <p className="seller__delivery__label">
          {cheapestDeliveryPrice} <span className="currency">{currency}</span>
        </p>
      </div>
    </div>
  );
};

export default Seller;

Seller.propTypes = {
  authorizedUser: object.isRequired,
  data: object.isRequired,
  delivery: object.isRequired,
  formatMessage: func.isRequired,
};

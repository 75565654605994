import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import PropTypes from 'prop-types';

const ProductMagazineAdress = ({ className, location }) => (
  <p className={`product-delivery--adress ${className}`}>
    <SVG type={SVG_TYPE.PIN} className="svg--pin--black" />
    {location}
  </p>
);

export default ProductMagazineAdress;

ProductMagazineAdress.defaultProps = {
  className: '',
};

ProductMagazineAdress.propTypes = {
  className: PropTypes.string,
  location: PropTypes.string.isRequired,
};

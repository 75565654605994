import React from 'react';
import * as Connector from 'shared/connectors/categoryConnector';
import { CATEGORY } from 'store/types';
import { getAllCategoryAndSubcategory } from 'store/actions/category';
import { NOTIFY_TYPE } from 'shared/consts';
import { Notify } from 'shared/components';
import { toast } from 'react-toastify';

export const success = () => ({
  type: CATEGORY.EDIT_SUBCATEGORY_SUCCESS,
});

const editSubcategory = (uuid, data) => async dispatch => {
  try {
    await Connector.editSubcategory(uuid, data);
    await dispatch(getAllCategoryAndSubcategory());
    dispatch(success(uuid));
    toast(<Notify label="global.successful_edit_sub_category" type={NOTIFY_TYPE.CHECKED} />);
    return true;
  } catch (err) {
    return err.response;
  }
};

export default editSubcategory;

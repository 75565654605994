import React from 'react';
import PropTypes from 'prop-types';
import { USER_ROLES } from 'shared/consts';

// const STATE_PART_TYPES = {
//     NEW: 'NEW',
//     USED: 'USED',
// }

const StatePart = ({ data, formatMessage }) => {
  const isCoordinatorOrAdmin = data?.owner?.role === USER_ROLES.ADMIN || data?.owner?.role === USER_ROLES.COORDINATOR;
  const itemCondition = data?.condition;
  return (
    <div className="state-part">
      <p className="state-part__item state-part__item--used">{formatMessage({ id: `global.${itemCondition}_condition` })}</p>
      {/* <p className={`state-part__item state-part__item--${type}`}>{formatMessage({ id: 'global.used' })}</p> */}
      {isCoordinatorOrAdmin && (
        <p className="state-part__item state-part__item--magazine">{formatMessage({ id: 'global.in_stock' })}</p>
      )}
    </div>
  );
};

export default StatePart;

StatePart.propTypes = {
  data: PropTypes.object.isRequired,
  formatMessage: PropTypes.func.isRequired,
};

const BADGE_TYPE = {
  client: 'CLIENT',
  coordinator: 'COORDINATOR',
  partner: 'PARTNER',
  storeman: 'STOREMAN',
};

const BADGE_LABEL = {
  client: 'Klient',
  coordinator: 'Koordynator',
  partner: 'Partner',
  storeman: 'Magazynier',
};

const PRODUCTS_OPTIONS = [
  {
    type: 'edit',
    message: 'global.edit',
    modalType: 'edit-warehouse',
  },
  {
    type: 'undo',
    message: 'global.undo',
    modalType: 'back-off',
  },
  {
    type: 'delete',
    message: 'global.remove',
    modalType: 'delete-warehouse',
  },
  {
    type: 'resume',
    message: 'global.restore',
    modalType: 'resume',
  },
];

const TRANSFER_OPTIONS = [
  {
    type: 'edit',
    message: 'global.edit',
    modalType: 'edit-warehouse',
  },
];

const TRANSFER_STATUSES = {
  send: 'IN_PROGRESS',
  arrived: 'ARRIVED',
};

const TRANSFER_STATUSES_TRANSLATION = {
  send: 'global.send',
  arrived: 'global.arrived',
};

const COUNTRY = {
  Poland: 'PL',
  Germany: 'DE',
};

const STATUSES = [
  {
    label: 'Wysłano',
    value: 'send',
  },
  {
    label: 'Dostarczone',
    value: 'arrived',
  },
];

export {
  BADGE_TYPE,
  BADGE_LABEL,
  PRODUCTS_OPTIONS,
  TRANSFER_STATUSES,
  TRANSFER_STATUSES_TRANSLATION,
  COUNTRY,
  STATUSES,
  TRANSFER_OPTIONS,
};

const querySearching = () => {
  const { search } = window.location;
  const isSearching = search.slice(0, 2) === '?s';
  if (isSearching) {
    const query = {};
    const pairs = (search[0] === '?' ? search.substr(1) : search).split('&');
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  }
  return false;
};

export default querySearching;

const DELIVERY = {
  GET_DELIVERY_INIT: 'GET_DELIVERY_INIT',
  GET_DELIVERY_SUCCESS: 'GET_DELIVERY_SUCCESS',
  GET_DELIVERY_FAILURE: 'GET_DELIVERY_FAILURE',
  GET_DELIVERY_COUNTRIES_INIT: 'GET_DELIVERY_COUNTRIES_INIT',
  GET_DELIVERY_COUNTRIES_SUCCESS: 'GET_DELIVERY_COUNTRIES_SUCCESS',
  GET_DELIVERY_COUNTRIES_FAILURE: 'GET_DELIVERY_COUNTRIES_FAILURE',
  DELETE_DELIVERY_METHOD_INIT: 'DELETE_DELIVERY_METHOD_INIT',
  DELETE_DELIVERY_METHOD_SUCCESS: 'DELETE_DELIVERY_METHOD_SUCCESS',
  DELETE_DELIVERY_METHOD_FAILURE: 'DELETE_DELIVERY_METHOD_FAILURE',
  EDIT_DELIVERY_INIT: 'EDIT_DELIVERY_INIT',
  EDIT_DELIVERY_SUCCESS: 'EDIT_DELIVERY_SUCCESS',
  EDIT_DELIVERY_FAILURE: 'EDIT_DELIVERY_FAILURE',
};

export default DELIVERY;

import React, { useEffect, useState } from 'react';
import { func, bool, object, shape, string, array } from 'prop-types';
import { RESPONSE_STATUSES } from 'shared/consts';
import { Content, CountryModal, SkeletonContent } from './components';

const SingleProduct = ({
  isMobile,
  shoppingCart,
  singleProduct,
  categories,
  translation,
  authorizedUser,
  deliveryCountries,
  intl: { formatMessage },
  actions: { getSingleProduct, modifyShoppingCart, markSingleProductFavorite, unmarkSingleProductFavorite, getDeliveryCountries },
}) => {
  const [isCountryHasBeenSelected, setIsCountryHasBeenSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const productUuid = window.location.pathname.split('/')[3];
  const countryUuid = (authorizedUser && authorizedUser.country.label) || localStorage.getItem('deliveryCountry');
  
  useEffect(async () => {
    const response = await getSingleProduct(productUuid, countryUuid, authorizedUser && authorizedUser.uuid);
    setTimeout(() => {
      response.status === RESPONSE_STATUSES.SUCCESS ? setIsLoading(false) : setIsLoading(true);
    }, 300);
  }, [isCountryHasBeenSelected]);

  useEffect(async () => {
    !countryUuid && (await getDeliveryCountries());
  }, []);

  return (
    <div className="single-product">
      {isLoading ? (
        <SkeletonContent authorizedUser={authorizedUser} formatMessage={formatMessage} isMobile={isMobile} />
      ) : (
        <Content
          shoppingCart={shoppingCart}
          authorizedUser={authorizedUser}
          data={singleProduct}
          modifyShoppingCart={modifyShoppingCart}
          translation={translation}
          formatMessage={formatMessage}
          isMobile={isMobile}
          categories={categories}
          addToFavorites={markSingleProductFavorite}
          removeFromFavorites={unmarkSingleProductFavorite}
          deliveryCountries={deliveryCountries}
        />
      )}
      {!countryUuid && (
        <CountryModal
          formatMessage={formatMessage}
          deliveryCountries={deliveryCountries}
          isCountryHasBeenSelected={isCountryHasBeenSelected}
          setIsCountryHasBeenSelected={setIsCountryHasBeenSelected}
        />
      )}
    </div>
  );
};

export default SingleProduct;

SingleProduct.propTypes = {
  actions: shape({
    modifyShoppingCart: func.isRequired,
    getDeliveryCountries: func.isRequired,
    addToFavorites: func.isRequired,
    getSingleProduct: func.isRequired,
  }).isRequired,
  authorizedUser: object.isRequired,
  categories: object.isRequired,
  categoryByLink: object.isRequired,
  deliveryCountries: array.isRequired,
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
  removeFromFavorites: func.isRequired,
  shoppingCart: object.isRequired,
  singleProduct: object.isRequired,
  translation: string.isRequired,
};

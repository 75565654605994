import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { func, bool, string } from 'prop-types';
import { Modal, ModalActions, RegularField, SelectField } from 'shared/components';
import { MODAL_TYPE, USER_ROLES, VALIDATION_FIELD } from 'shared/consts';
import { userRoleOptions, deliveryCountryOptions } from 'shared/utils';
import { Field, Formik } from 'formik';
import { MENU_TYPES } from '../../../const';

const AddUser = ({ isOpened, addUserByAdmin, formatMessage, onClose, translation, deliveryCountries, setMenuType }) => {
  const [isEmailOccupied, setIsEmailOccupied] = useState(false);

  const onSubmit = async values => {
    const { data } = await addUserByAdmin(values);
    if (!data && values.role === USER_ROLES.COORDINATOR) {
      onClose();
      setMenuType(MENU_TYPES(formatMessage).COORDINATORS);
    } else if (!data && values.role === USER_ROLES.STOREMAN) {
      onClose();
      setMenuType(MENU_TYPES(formatMessage).STOREMANS);
    } else {
      setIsEmailOccupied(true);
    }
  };

  const validation = () =>
    Yup.object().shape({
      country_of_delivery_uuid: Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED),
      email: Yup.string()
        .test('email-occupied', VALIDATION_FIELD(formatMessage).EMAIL_OCCUPIED, () => !isEmailOccupied)
        .email(VALIDATION_FIELD(formatMessage).EMAIL)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED),
      firstName: Yup.string().max(30, VALIDATION_FIELD(formatMessage).MAX_30).required(VALIDATION_FIELD(formatMessage).REQUIRED),
      lastName: Yup.string().max(50, VALIDATION_FIELD(formatMessage).MAX_50).required(VALIDATION_FIELD(formatMessage).REQUIRED),
      role: Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED),
    });

  return (
    <div>
      {isOpened && (
        <Modal
          className={`${MODAL_TYPE.BASIC} adding`}
          onClose={() => {
            onClose();
            setIsEmailOccupied(false);
          }}
          opened={isOpened}
        >
          <div>
            <div className="modal__heading modal__heading--center">{formatMessage({ id: 'global.adding' })}</div>
            <Formik
              validationSchema={validation()}
              initialValues={{
                email: '',
                firstName: '',
                lastName: '',
                language: translation.toUpperCase(),
                role: '',
                country_of_delivery_uuid: '',
              }}
              onSubmit={onSubmit}
            >
              {({ handleSubmit, errors, setFieldTouched }) => (
                <form className="categoryManagement__addCategory__wrapper" onSubmit={handleSubmit}>
                  {useEffect(() => {
                    setFieldTouched('email', isEmailOccupied);
                  }, [isEmailOccupied])}
                  <div className="categoryManagement__assign-client coordinator">
                    <Field
                      type="text"
                      label={formatMessage({ id: 'inputs.name' })}
                      name="firstName"
                      component={RegularField}
                      errors={errors}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: 'inputs.last_name' })}
                      name="lastName"
                      component={RegularField}
                      errors={errors}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: 'inputs.email' })}
                      name="email"
                      inputOnChange={() => setIsEmailOccupied(false)}
                      component={RegularField}
                      errors={errors}
                    />
                    <Field
                      className="add-advertisement__select"
                      component={SelectField}
                      placeholder={formatMessage({ id: 'inputs.choose' })}
                      label={formatMessage({ id: 'global.delivery_country' })}
                      errors={errors}
                      name="country_of_delivery_uuid"
                      options={deliveryCountryOptions(deliveryCountries, formatMessage)}
                      isCustomLabel
                    />
                    <Field
                      className="add-advertisement__select"
                      component={SelectField}
                      placeholder={formatMessage({ id: 'inputs.choose' })}
                      label={formatMessage({ id: 'global.role' })}
                      errors={errors}
                      name="role"
                      options={userRoleOptions(formatMessage)}
                      isCustomLabel
                    />
                    <ModalActions
                      formatMessage={formatMessage}
                      type={MODAL_TYPE.ADD_CATEGORY}
                      onClose={onClose}
                      onSubmit={handleSubmit}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
    </div>
  );
};
export default AddUser;

AddUser.propTypes = {
  addUserByAdmin: func.isRequired,
  deliveryCountries: func.isRequired,
  formatMessage: func.isRequired,
  isOpened: bool.isRequired,
  onClose: func.isRequired,
  setMenuType: func.isRequired,
  translation: string.isRequired,
};

import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { func } from 'prop-types';

const BENEFITS = formatMessage => [
  {
    icon: SVG_TYPE.TEST,
    label: formatMessage({ id: 'global.partner_benefits_allegro' }),
  },
  {
    icon: SVG_TYPE.TEST,
    label: formatMessage({ id: 'global.partner_benefits_europe' }),
  },
  {
    icon: SVG_TYPE.TEST,
    label: formatMessage({ id: 'global.partner_benefits_rotation' }),
  },
  {
    icon: SVG_TYPE.TEST,
    label: formatMessage({ id: 'global.partner_benefits_no_limit' }),
  },
  {
    icon: SVG_TYPE.TEST,
    label: formatMessage({ id: 'global.partner_benefits_commission' }),
  },
  {
    icon: SVG_TYPE.TEST,
    label: formatMessage({ id: 'global.partner_benefits_last' }),
  },
];

const PartnerBenefits = ({ formatMessage }) => (
  <div className="partnerProgram__benefits">
    <h3 className="partnerProgram__benefits--heading">{formatMessage({ id: 'global.partner_benefits' })}</h3>
    <div className="partnerProgram__benefits--lists">
      {BENEFITS(formatMessage).map(({ icon, label }) => (
        <div className="partnerProgram__benefits__single">
          <SVG className="partnerProgram__benefits--image" type={icon} />
          <p className="partnerProgram__benefits--paragraph">{label}</p>
        </div>
      ))}
    </div>
  </div>
);

export default PartnerBenefits;

PartnerBenefits.propTypes = {
  formatMessage: func.isRequired,
};

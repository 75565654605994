import React from 'react';
import { func, object, string } from 'prop-types';
import { SUBSCRIPTION } from 'screens/partner-program/const';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

const SubscriptionTiles = ({ selectedItem, setSelectedSubscription, addressLang, formatMessage }) => {
  const { selected } = selectedItem;
  const { STANDARD, PREMIUM } = SUBSCRIPTION;

  return (
    <div className="partnerProgram__subsciptions--wrapper">
      <div
        className={`partnerProgram__subsciptions__tile ${
          selected === STANDARD ? 'partnerProgram__subsciptions__tile--active' : ''
        }`}
        onClick={() => setSelectedSubscription({ isOpened: true, selected: STANDARD })}
      >
        <p className="partnerProgram__subsciptions--heading">{formatMessage({ id: 'global.standard' })}</p>
        <p className="partnerProgram__subsciptions--price">
          {addressLang === 'pl' ? `500 zł` : '125 eur'} {addressLang === 'pl' ? <span className="netto">netto</span> : ''}
        </p>
        <p className="partnerProgram__subsciptions--paragraph">
          {formatMessage({ id: 'global.partner_modal_standard_descript' })}
        </p>
      </div>
      <div
        className={`partnerProgram__subsciptions__tile partnerProgram__subsciptions__tile--pr ${
          selected === PREMIUM ? 'partnerProgram__subsciptions__tile--active' : ''
        }`}
        onClick={() => setSelectedSubscription({ isOpened: true, selected: PREMIUM })}
      >
        <div className="partnerProgram__subsciptions--heading ">
          {formatMessage({ id: 'global.premium' })} <SVG type={SVG_TYPE.STAR_CIRCLE} />
        </div>
        <p className="partnerProgram__subsciptions--price partnerProgram__subsciptions--price--pr">
          {formatMessage({ id: 'global.individual_conditions' })}
        </p>
        <p className="partnerProgram__subsciptions--paragraph ">
          {formatMessage({ id: 'global.partner_modal_premium_descript' })}
        </p>
      </div>
    </div>
  );
};

export default SubscriptionTiles;

SubscriptionTiles.propTypes = {
  addressLang: string.isRequired,
  formatMessage: func.isRequired,
  selectedItem: object.isRequired,
  setSelectedSubscription: func.isRequired,
};

import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { object } from 'prop-types';

const PhotoSlider = ({ data }) => {
  const iconsImage = [];
  const [countIconImage, setCountIconImage] = useState('');
  const photoInputValue = data?.photoInputValue?.filter(item => !item?.path);
  const groupByPath = items => {
    items[0] &&
      items.forEach(item =>
        item?.path
          ? iconsImage.push({ index: iconsImage.length, path: item.path })
          : iconsImage.push({ index: iconsImage.length, path: URL.createObjectURL(item) }),
      );
  };
  useEffect(() => {
    if (data?.files) {
      setTimeout(() => {
        const slick = document.getElementsByClassName('slick-dots');
        const elements = slick && slick.length > 0 && slick[0].getElementsByTagName('li');
        const iconsArray = Object.entries(elements);
        setCountIconImage(iconsArray.length - 4);
      }, 100);
    }
  }, [data?.files]);
  data?.files && data.files.length > 0 && groupByPath(data.files);
  photoInputValue && groupByPath(photoInputValue);
  const settings = {
    customPaging(i) {
      return (
        <div>
          <img className="photo-slider__icon-image" src={iconsImage[i].path} alt="test" />{' '}
        </div>
      );
    },
    initialSlide: 0,
    dots: true,
    infinite: true,
    nextArrow: (
      <p>
        <SVG type={SVG_TYPE.CHEVRONE} className="svg--chevrone__orange svg--chevrone__white--right" />
      </p>
    ),
    prevArrow: (
      <p>
        <SVG type={SVG_TYPE.CHEVRONE} className="svg--chevrone__orange svg--chevrone__white--left" />
      </p>
    ),
  };

  return (
    <>
      <div className="photo-slider">
        {iconsImage && iconsImage.length > 0 ? (
          <>
            <Slider {...settings}>
              {iconsImage &&
                iconsImage.length > 0 &&
                iconsImage.map((item, idx) => (
                  <div className="photo-slider__item">
                    <img className="photo-slider__image" src={iconsImage[idx].path} alt={item?.name} />
                    <div className="photo-slider__image--number">
                      {idx + 1}z{iconsImage.length}
                    </div>
                  </div>
                ))}
            </Slider>
            {countIconImage > 0 && <div className="photo-slider--count">+ {countIconImage}</div>}
          </>
        ) : (
          <SVG className="photo-slider__image" type={SVG_TYPE.DEFAULT_PHOTO} />
        )}
      </div>
    </>
  );
};

export default PhotoSlider;

PhotoSlider.propTypes = {
  data: object.isRequired,
};

import React, { useState } from 'react';
import { func, object, bool, string } from 'prop-types';
import { motion } from 'framer-motion';
import { InputCounter, SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

const BasketItem = ({
  authorizedUser,
  formatMessage,
  isNotFirst,
  data,
  modifyShoppingCart,
  lastItem,
  setAmount,
  amounts,
  translation,
}) => {
  const countryUuid = localStorage.getItem('deliveryCountry') || authorizedUser?.country?.label;
  const [isDelete, setIsDelete] = useState(false);
  const userId = data.country.uuid !== countryUuid ? -1 : data.user_id;

  return (
    <>
      {data.pivot_shopping_cart_amount > 0 && (
        <motion.div animate={{ x: isDelete ? 100 : 0, opacity: isDelete ? 0 : 1 }} className="basket-modal__item--wrapper">
          {isNotFirst && (
            <div
              className="before"
              onClick={() => {
                setAmount({ start: amounts.start - 1, end: amounts.end - 1 });
              }}
            >
              <SVG type={SVG_TYPE.CHEVRONE} />
            </div>
          )}
          <div className={`basket-modal__item ${lastItem ? 'basket-modal__item--last' : ''}`}>
            <div className="basket-modal__item--left">
              {data?.files && data?.files.length > 0 && data.files[0] ? (
                <img className="basket-modal__item--image" src={data.files[0].path} alt="" />
              ) : (
                <SVG className="basket-modal__item--image" type={SVG_TYPE.DEFAULT_PHOTO} />
              )}
              <div className="basket-modal__item--info">
                <p className="basket-modal__item--name">{translation === 'de' ? data.title_ger : data.title}</p>
                <div className="basket-modal__item--descript">
                  <p>
                    {formatMessage({ id: 'global.brand' })}: {data.brand && data.brand.length > 0 ? data.brand[0]?.brand : ''}
                  </p>
                  <p>
                    {formatMessage({ id: 'global.model' })}: {data.model && data.model.length > 0 ? data.model[0].model : ''}
                  </p>
                  <p>
                    {formatMessage({ id: 'global.year_production' })}: {data.year_of_production}
                  </p>
                </div>
              </div>
            </div>
            <div className="basket-modal__item--right">
              <p className="basket-modal__item--price">
                {data.price} {data.currency}
              </p>
              <InputCounter
                setIsDelete={setIsDelete}
                authorizedUser={authorizedUser}
                data={data}
                modifyShoppingCart={modifyShoppingCart}
              />
              <div
                className="basket-modal__item--delete"
                onClick={async () => {
                  setIsDelete(true);
                  await modifyShoppingCart({
                    country_uuid: countryUuid,
                    product_uuid: data.uuid,
                    update_option: 'delete',
                    uuid: data.uuid,
                    user_id: userId,
                  });
                }}
              >
                {formatMessage({ id: 'inputs.delete' })}
              </div>
            </div>
          </div>
          {lastItem && (
            <div
              className="next"
              onClick={() => {
                setAmount({ start: amounts.start + 1, end: amounts.end + 1 });
              }}
            >
              <SVG type={SVG_TYPE.CHEVRONE} />
            </div>
          )}
        </motion.div>
      )}
    </>
  );
};

export default BasketItem;

BasketItem.defaultProps = {
  lastItem: null,
};

BasketItem.propTypes = {
  amounts: object.isRequired,
  authorizedUser: object.isRequired,
  data: object.isRequired,
  formatMessage: func.isRequired,
  isNotFirst: bool.isRequired,
  lastItem: bool,
  modifyShoppingCart: func.isRequired,
  setAmount: func.isRequired,
  translation: string.isRequired,
};

import * as Connector from 'shared/connectors/partnerConnector';
import { PARTNER } from 'store/types';

const init = () => ({
  type: PARTNER.GET_PARTNER_REQUESTS_INIT,
});

export const success = data => ({
  type: PARTNER.GET_PARTNER_REQUESTS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: PARTNER.GET_PARTNER_REQUESTS_FAILURE,
});

const getPartnerRequests = () => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getPartnerRequests();
    dispatch(success(data.partnerRequest));
    return true;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default getPartnerRequests;

import React from 'react';
import PropTypes from 'prop-types';

// Props:
// className - your custom className

const Title = ({ title, className, fontStyle }) => (
  <div className="title">
    <div className={`title__${className}`}>
      <p className={fontStyle && fontStyle}>{title}</p>
    </div>
  </div>
);

export default Title;

Title.propTypes = {
  className: PropTypes.string.isRequired,
  fontStyle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

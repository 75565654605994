import React, { useEffect, useState } from 'react';
import { SVG, Button, LanguageSwitch } from 'shared/components';
import { SVG_TYPE, ROUTES, NAVIGATION_CATEGORY, NAVIGATION_ITEMS, USER_ROLES } from 'shared/consts';
import PropTypes from 'prop-types';
import { Link, useHistory, NavLink } from 'react-router-dom';
import { generatePath } from 'react-router';

const Content = ({
  isMenuSlide,
  closeMenu,
  isMenuOpened,
  authorizedUser,
  mainCategory,
  setIsMenuOpened,
  setAppTranslation,
  translation,
  formatMessage,
  logout,
  country,
}) => {
  const history = useHistory();
  const [mainCategories, setMainCategories] = useState([]);

  const handleClickMenuItem = e => {
    const categoryWrapper = document.getElementsByClassName('menu-mobile__subpage');
    const categoryItems = categoryWrapper && categoryWrapper[0].getElementsByClassName('menu-mobile__list-item');
    categoryItems &&
      Array.from(categoryItems).forEach((el, idx) => {
        idx >= 6 && el.classList.add('subMenu__list--item--hidden');
      });
    const parent = e.target.closest('.menu-mobile__list-item');
    const activeMenu = parent.classList.contains('menu-mobile__list-item--active');
    const button = document.getElementsByClassName('btn');
    activeMenu
      ? setTimeout(() => {
          const seeMore = 'menu-mobile__subpage--all';
          const seeMoreDisabled = 'subMenu__more--hidden';
          const seeMoreItem = document.getElementsByClassName(seeMore);
          seeMoreItem.length > 0 && seeMoreItem[0].classList.remove(seeMoreDisabled);
          const activeSubMenu = document.getElementsByClassName('menu-mobile__subpage--active');
          parent.classList.remove('menu-mobile__list-item--active');
          activeSubMenu[0] && activeSubMenu[0].classList.remove('menu-mobile__subpage--active');
          const allElementMenu = document.getElementsByClassName('menu-mobile__list--wrapper');
          Array.prototype.forEach.call(allElementMenu, el => {
            el.classList.remove('menu-mobile__list--wrapper--active');
            el.classList.remove('menu-mobile__list--wrapper--disabled');
          });
          button.length > 0 && button[0].classList.remove('btn--disabled');
        }, 50)
      : parent.classList.add('menu-mobile__list-item--active');

    const sub = e.target.closest('.menu-mobile__list--wrapper');
    const subMenu = sub.querySelector('.menu-mobile__subpage');
    subMenu && subMenu.classList.add('menu-mobile__subpage--active');
    const wrapperElement = e.target.closest('.menu-mobile__list--wrapper');
    wrapperElement.classList.add('menu-mobile__list--wrapper--active');
    const allElementMenu = document.getElementsByClassName('menu-mobile__list--wrapper');
    Array.prototype.forEach.call(allElementMenu, el => {
      const hasActive = el.classList.contains('menu-mobile__list--wrapper--active');
      !hasActive && el.classList.add('menu-mobile__list--wrapper--disabled');
    });
    button.length > 0 && button[0].classList.add('btn--disabled');
  };

  const handleClickCategory = (e, idx) => {
    const activeCategoryClassName = 'menu-mobile__subcategory-item--active';
    const activeSubcategoryClass = 'menu-mobile__subcategories__wrapper--active';
    const seeMore = 'menu-mobile__subpage--all';
    const seeMoreDisabled = 'menu-mobile__subpage--all--disabled';
    const seeMoreItem = document.getElementsByClassName(seeMore);
    const parent = e.target.closest('.menu-mobile__list-item');
    const findActiveSubcategoryClass = document.getElementsByClassName(activeSubcategoryClass);
    const findActiveCategoryClass = document.getElementsByClassName(activeCategoryClassName);
    const disactiveCategoryClass = 'menu-mobile__subcategory-item--disabled';
    const disactiveMainCategoryClass = 'menu-mobile__list-item--disabled';

    if (parent.classList.contains(activeCategoryClassName)) {
      const disabledItems = document.getElementsByClassName('menu-mobile__list-item');
      const disabledMainCategoryItems = document.getElementsByClassName(disactiveMainCategoryClass);
      disabledMainCategoryItems.length > 0 && disabledMainCategoryItems[0].classList.remove(disactiveMainCategoryClass);
      findActiveSubcategoryClass.length > 0 && findActiveSubcategoryClass[0].classList.remove(activeSubcategoryClass);
      findActiveCategoryClass.length > 0 && findActiveCategoryClass[0].classList.remove(activeCategoryClassName);
      seeMoreItem.length > 0 && seeMoreItem[0].classList.remove(seeMoreDisabled);
      Array.prototype.forEach.call(disabledItems, el => {
        el.classList.remove(disactiveCategoryClass);
      });
    } else {
      const allSubcategoryItemsClass = 'menu-mobile__list-item';
      const activeMainCategoryClass = 'menu-mobile__list-item--active';
      const disabledMainCategoryClass = 'menu-mobile__list-item--disabled';
      const allSubcategoryItems = document.getElementsByClassName(allSubcategoryItemsClass);
      const FindActiveMainCategoryClass = document.getElementsByClassName(activeMainCategoryClass);
      const subcategory = document.getElementById(`subcategory--${idx}`);
      const category = document.getElementById(`category--${idx}`);
      findActiveSubcategoryClass.length > 0 && findActiveSubcategoryClass[0].classList.remove(activeSubcategoryClass);
      findActiveCategoryClass.length > 0 && findActiveCategoryClass[0].classList.remove(activeCategoryClassName);
      FindActiveMainCategoryClass.length > 0 && FindActiveMainCategoryClass[0].classList.add(disabledMainCategoryClass);
      seeMoreItem.length > 0 && seeMoreItem[0].classList.add(seeMoreDisabled);
      subcategory.classList.add(activeSubcategoryClass);
      category.classList.add(activeCategoryClassName);
      Array.prototype.forEach.call(allSubcategoryItems, el => {
        const hasActive = el.classList.contains(activeCategoryClassName);
        !hasActive && el.classList.add(disactiveCategoryClass);
      });
    }
  };

  const seeMoreCategory = e => {
    const hiddenElements = document.getElementsByClassName('subMenu__list--item--hidden');
    e.target.classList.add('subMenu__more--hidden');
    hiddenElements &&
      Array.from(hiddenElements).forEach(el => {
        el.classList.remove('subMenu__list--item--hidden');
      });
  };

  useEffect(() => {
    const categories = mainCategory?.category;
    const subCategories = NAVIGATION_ITEMS(formatMessage)[1].category[0].sub_category;
    categories &&
      subCategories.length === 0 &&
      categories.forEach(({ name, link, subcategories, products_count_de, products_count_pl }) =>
        subCategories.push({
          sub_category_route: link,
          sub_category_label: name,
          sub_category_icon: subcategories.length > 0 && SVG_TYPE.CHEVRONE,
          products_count_de,
          products_count_pl,
          subcategories,
        }),
      );
    subCategories.length > 0 && setMainCategories(subCategories);
  }, [mainCategory.category]);

  const isUserLogged = authorizedUser;

  return (
    <>
      {isMenuOpened && (
        <div className="menu-mobile__wrapper">
          <div className={`menu-mobile__content ${isMenuSlide ? 'menu-mobile__content--active' : ''} `}>
            <div className="menu-mobile--header">
              <Link to={ROUTES.HOME_PAGE}>
                <SVG type={SVG_TYPE.LOGO_WHITE} />
              </Link>
              <div onClick={() => closeMenu()}>
                <SVG className="menu-mobile--close" type={SVG_TYPE.CLOSE} />
              </div>
            </div>
            <nav className="menu-mobile__list">
              {NAVIGATION_ITEMS(formatMessage).map(({ label, category, category_class, route }) => {
                const isCategory = category_class === NAVIGATION_CATEGORY.MAIN;
                return (
                  <>
                    {label !== 'Moje konto' || (label === 'Moje konto' && isUserLogged) ? (
                      <>
                        {!route ? (
                          <>
                            <div key={label} className="menu-mobile__list--wrapper">
                              <div
                                className="menu-mobile__list-item"
                                onClick={e => (category_class !== 'help' ? handleClickMenuItem(e) : history.push(route))}
                              >
                                <p className="menu-mobile__list-item--paragraph">{label} </p>
                                {category_class !== 'help' && (
                                  <SVG className="menu-mobile__list-item--icon" type={SVG_TYPE.CHEVRONE} />
                                )}
                              </div>
                              {category && (
                                <div className="menu-mobile__subpage">
                                  <>
                                    {category.map(({ sub_category }) => {
                                      const submenu =
                                        category_class === NAVIGATION_CATEGORY.MAIN
                                          ? mainCategories.filter(
                                              ({ products_count_pl, products_count_de }) =>
                                                (country === 'pl' ? products_count_pl : products_count_de) > 0,
                                            )
                                          : sub_category;
                                      return (
                                        <>
                                          {submenu.map(
                                            (
                                              { sub_category_route, sub_category_label, subcategories, sub_category_icon },
                                              idx,
                                            ) => (
                                              <>
                                                {sub_category_route === ROUTES.CATEGORY_MANAGEMENT ||
                                                sub_category_route === ROUTES.USER_MANAGEMENT ? (
                                                  <>
                                                    {authorizedUser?.role === USER_ROLES.ADMIN ? (
                                                      <div className='"menu-mobile__list-item"'>
                                                        <div className="menu-mobile__list-item--paragraph menu-mobile__list-item--paragraph--short">
                                                          <NavLink
                                                            key={sub_category_label}
                                                            className="menu-mobile__list-item--paragraph"
                                                            to={sub_category_route}
                                                            onClick={() => setIsMenuOpened(false)}
                                                          >
                                                            {sub_category_label}
                                                            {sub_category_icon && (
                                                              <SVG
                                                                className="subMenu__list--item-icon"
                                                                type={sub_category_icon}
                                                              />
                                                            )}
                                                          </NavLink>
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    {sub_category_route && (
                                                      <>
                                                        <div
                                                          key={sub_category_route}
                                                          id={`category--${idx}`}
                                                          className={`menu-mobile__list-item ${
                                                            sub_category_route === ROUTES.LOGOUT
                                                              ? 'menu-mobile__list-item--disabled'
                                                              : ''
                                                          }`}
                                                          onClick={e => {
                                                            !isCategory || (isCategory && subcategories.length === 0)
                                                              ? setIsMenuOpened(false)
                                                              : handleClickCategory(e, idx);
                                                          }}
                                                        >
                                                          <div
                                                            className="menu-mobile__list-item--paragraph menu-mobile__list-item--paragraph--short"
                                                            onClick={() => {
                                                              subcategories &&
                                                                subcategories.length === 0 &&
                                                                history.push(
                                                                  generatePath(ROUTES.CATEGORY_NAME, {
                                                                    category: sub_category_route,
                                                                  }),
                                                                );
                                                              !subcategories && history.push(sub_category_route);
                                                            }}
                                                          >
                                                            {sub_category_label}
                                                          </div>
                                                          {isCategory && subcategories.length > 0 && (
                                                            <SVG
                                                              className="menu-mobile__list-item--icon menu-mobile__list-item--icon--short"
                                                              type={SVG_TYPE.CHEVRONE}
                                                            />
                                                          )}
                                                        </div>

                                                        {isCategory && (
                                                          <div
                                                            id={`subcategory--${idx}`}
                                                            className="menu-mobile__subcategories__wrapper"
                                                          >
                                                            <div
                                                              className="menu-mobile__subcategories--show-all"
                                                              onClick={() => {
                                                                history.push(
                                                                  generatePath(ROUTES.CATEGORY_NAME, {
                                                                    category: sub_category_route,
                                                                  }),
                                                                );
                                                                setIsMenuOpened(false);
                                                              }}
                                                            >
                                                              <div className="menu-mobile__subcategories--show-all--paragraph">
                                                                Pokaż wszystkie
                                                              </div>
                                                            </div>
                                                            {subcategories
                                                              .filter(
                                                                ({ products_count_pl, products_count_de }) =>
                                                                  (country === 'pl' ? products_count_pl : products_count_de) > 0,
                                                              )
                                                              .map(item => (
                                                                <div
                                                                  className="menu-mobile__subcategories--item"
                                                                  onClick={() => {
                                                                    history.push(
                                                                      generatePath(ROUTES.SUBCATEGORY_NAME, {
                                                                        category: sub_category_route,
                                                                        subcategory: item.link,
                                                                      }),
                                                                    );
                                                                    setIsMenuOpened(false);
                                                                  }}
                                                                >
                                                                  {item.name}
                                                                </div>
                                                              ))}
                                                          </div>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            ),
                                          )}
                                        </>
                                      );
                                    })}
                                  </>
                                  {category[0]?.category_class === NAVIGATION_CATEGORY.MAIN && (
                                    <div className="menu-mobile__subpage--all" onClick={e => seeMoreCategory(e)}>
                                      Więcej...
                                    </div>
                                  )}

                                  {/* {category_class === NAVIGATION_CATEGORY.HELP && (
                                    <div className="menu-mobile--contact">
                                      <div className="subMenu__help--icon">
                                        <SVG type={SVG_TYPE.PHONE} />
                                        <a href="tel:+48382283281" data-rel="external">
                                          +48 382 283 281
                                        </a>
                                      </div>
                                      <div className="subMenu__help--icon">
                                        <SVG type={SVG_TYPE.MAIL} />
                                        <p>kontakt@getmotor.pl</p>
                                      </div>
                                    </div>
                                  )} */}
                                </div>
                              )}
                            </div>
                          </>
                        ) : (
                          <div key={label} className="menu-mobile__list--wrapper">
                            {route === ROUTES.ABOUT_US && (
                              <div
                                className="menu-mobile__list-item"
                                onClick={() => {
                                  history.push(route);
                                  closeMenu();
                                }}
                              >
                                <p className="menu-mobile__list-item--paragraph">{label} </p>
                              </div>
                            )}
                            {route === ROUTES.CONTACT && (
                              <div
                                className="menu-mobile__list-item"
                                onClick={() => {
                                  history.push(route);
                                  closeMenu();
                                }}
                              >
                                <p className="menu-mobile__list-item--paragraph">{label} </p>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </>
                );
              })}
              {!isUserLogged ? (
                <div className="menu-mobile__bottom">
                  <Button
                    label={formatMessage({ id: 'inputs.login' })}
                    onClick={() => {
                      history.push(ROUTES.LOGIN);
                      closeMenu();
                    }}
                    className="btn--orange menu-mobile--login"
                  />
                  <LanguageSwitch translation={translation} setAppTranslation={setAppTranslation} />
                </div>
              ) : (
                <div className="menu-mobile__bottom">
                  <Button
                    onClick={() => {
                      logout();
                      closeMenu();
                    }}
                    label={formatMessage({ id: 'menu.logout' })}
                    className=" menu-mobile--login menu-mobile--logOut"
                  />
                  <LanguageSwitch translation={translation} setAppTranslation={setAppTranslation} />
                </div>
              )}
            </nav>
          </div>
        </div>
      )}
    </>
  );
};

Content.propTypes = {
  authorizedUser: PropTypes.object.isRequired,
  closeMenu: PropTypes.func.isRequired,
  country: PropTypes.string.isRequired,
  deliveryCountries: PropTypes.object.isRequired,
  formatMessage: PropTypes.func.isRequired,
  isMenuOpened: PropTypes.bool.isRequired,
  isMenuSlide: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  mainCategory: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  setAppTranslation: PropTypes.func.isRequired,
  setIsMenuOpened: PropTypes.func.isRequired,
  translation: PropTypes.string.isRequired,
};

export default Content;

import React from 'react';
import PropTypes, { string } from 'prop-types';
import { history } from 'store/store';

const SingleBrandBar = ({ quantity, label, value, first }) => (
  <div
    className="single-brand-bar"
    onClick={() => {
      history.push(`/category/?s&brand=${label}`);
    }}
  >
    {!!first && <p className="single-brand-bar--first">{label[0]}</p>}
    <p className="single-brand-bar--name">
      <span className="none">{value}</span>
      {label}
    </p>
    <p className="single-brand-bar--quantity"> &#40;{quantity}&#41;</p>
  </div>
);

export default SingleBrandBar;

SingleBrandBar.defaultProps = {
  value: '',
};
SingleBrandBar.propTypes = {
  first: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  value: string,
};

import React from 'react';
import PropTypes from 'prop-types';

const CheckBoxField = ({ field: { name, value }, label, form }) => {
  const handleClick = () => {
    value === 1 ? form && form.setFieldValue(name, 0) : form && form.setFieldValue(name, 1);
  };
  return (
    <div className="checkbox">
      <div className={`checkbox__label ${value === 1 && 'checkbox__label--active'}`} onClick={handleClick}>
        {label}
      </div>
    </div>
  );
};

export default CheckBoxField;

CheckBoxField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

import * as Connector from 'shared/connectors/userConnector';
import { AUTH } from 'store/types';
import { getUsersByRole } from 'store/actions/user';

const init = () => ({
  type: AUTH.REGISTER_USER_BY_ADMIN_INIT,
});

export const success = data => ({
  type: AUTH.REGISTER_USER_BY_ADMIN_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: AUTH.REGISTER_USER_BY_ADMIN_FAILURE,
});

const addUserByAdmin = dataRegister => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.addUserByAdmin(dataRegister);
    dispatch(getUsersByRole(dataRegister.role));
    dispatch(success(data));
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default addUserByAdmin;

const PARTNERS = {
  PARTNER_REQUEST_INIT: 'PARTNER_REQUEST_INIT',
  PARTNER_REQUEST_SUCCESS: 'PARTNER_REQUEST_SUCCESS',
  PARTNER_REQUEST_FAILURE: 'PARTNER_REQUEST_FAILURE',
  GET_PARTNER_REQUESTS_INIT: 'GET_PARTNER_REQUESTS_INIT',
  GET_PARTNER_REQUESTS_SUCCESS: 'GET_PARTNER_REQUESTS_SUCCESS',
  GET_PARTNER_REQUESTS_FAILURE: 'GET_PARTNER_REQUESTS_FAILURE',
  MANAGING_PARTNER_APPLICATION_SUCCESS: 'MANAGING_PARTNER_APPLICATION_SUCCESS',
};

export default PARTNERS;


import API from 'shared/connectors/config';

export function addProduct(data) {
  return API.post('products', data);
}

export function getAllProducts() {
  return API.get(`products/all?status=all`);
}

export function getAllProductsByFilter(status, title, category_uuid) {
  return API.get(`products/all`, { params: { status, title, category_uuid } });
}

export function getAllTransfers() {
  return API.get(`products/transfer`);
}

export function addTransfer(data) {
  return API.post(`products/transfer`, data);
}

export function editTransfer(data) {
  return API.put(`products/transfer/update`, data);
}

export function getProductsByCategory(uuid, lang, user_uuid) {
  return API.get(`products/list/?category_uuid=${uuid}&country_uuid=${lang}&user_uuid=${user_uuid}`);
}

export function getProductsBySubCategory(uuid, lang, user_uuid) {
  return API.get(`products/list/?subcategory_uuid=${uuid}&country_uuid=${lang}&user_uuid=${user_uuid}`);
}

export function getCategoriesOptions() {
  return API.get(`categories/label`);
}

export function getSingleProduct(uuid, countryUuid, userUuid) {
  return API.get(`products/show/${uuid}?&country_uuid=${countryUuid}&user_uuid=${userUuid}`);
}

export function addToFavorites(uuid) {
  return API.post(`products/favorite/${uuid}`);
}

export function removeFromFavorites(uuid) {
  return API.delete(`products/favorite/${uuid}`);
}

export function getFavoriteProducts() {
  return API.get(`products/favorite`);
}

export function getMyAdvertisements(status) {
  return API.get(`products/list/own?status=${status}`);
}

export function editAdvertisement(uuid, data) {
  return API.put(`products/${uuid}`, data);
}

export function cancelAdvertisement(uuid) {
  return API.put(`products/canceled/${uuid}`);
}

export function deleteAdvertisement(uuid) {
  return API.delete(`products/${uuid}`);
}

export function getAdvertisementsFiltred(status, title, orderBy, category_uuid,hide_products) {
  return API.get(
    `products/list/own/search`,{ params: { status, title, orderBy, category_uuid,hide_products } },
  );
}

export function getPopularBrands() {
  return API.get('car/brands/popular/list');
}

export function getInvoiceFromUuid(checkout_uuid) {
  return API.get('payments/get-invoice', { params: { checkout_uuid } });
}

export function getProductsByFilter({
  country_uuid,
  year_of_production_start,
  year_of_production_end,
  brand_uuid,
  model_uuid,
  search,
  category_uuid,
  subcategory_uuid,
  user_uuid,
  language,
}) {
  return API.get(
    `products/list${country_uuid ? `?country_uuid=${country_uuid}` : ''}${
      year_of_production_start ? `&year_of_production_start=${year_of_production_start}` : ''
    }${year_of_production_end ? `&year_of_production_end=${year_of_production_end}` : ''}${
      brand_uuid ? `&brand_uuid=${brand_uuid}` : ''
    }${search ? `&search=${search}` : ''}${category_uuid ? `&category_uuid[0]=${category_uuid}` : ''}${
      model_uuid ? `&model_uuid=${model_uuid}` : ''
    }${subcategory_uuid ? `${`&subcategory_uuid[0]=${subcategory_uuid}`}` : ''}${
      user_uuid ? `${`&user_uuid=${user_uuid}`}` : ''
    }${language ? `${`&language=${language}`}` : ''}`,
  );
}

export function getAdvertisementsCount() {
  return API.get(`products/list/own/count?status=all`);
}

export function editProduct(uuid, data) {
  return API.put(`products/warehouse/${uuid}`, data);
}

export function recallTheProduct(uuid) {
  return API.put(`products/canceled/${uuid}`);
}

export function resumeTheProduct(uuid) {
  return API.put(`products/active/${uuid}`);
}

const SHOPPING_CART = {
  MODIFY_BASKET_INIT: 'MODIFY_BASKET_INIT',
  MODIFY_BASKET_FAILURE: 'MODIFY_BASKET_FAILURE',
  MODIFY_BASKET_SUCCESS: 'ADD_TO_BASKET_SUCCESS',

  GET_SHOPPING_CART_INIT: 'GET_SHOPPING_CART_INIT',
  GET_SHOPPING_CART_FAILURE: 'GET_SHOPPING_CART_FAILURE',
  GET_SHOPPING_CART_SUCCESS: 'GET_SHOPPING_CART_SUCCESS',

  GET_SHOPPING_CART_OWNER_INIT: 'GET_SHOPPING_CART_OWNER_INIT',
  GET_SHOPPING_CART_OWNER_FAILURE: 'GET_SHOPPING_CART_OWNER_FAILURE',
  GET_SHOPPING_CART_OWNER_SUCCESS: 'GET_SHOPPING_CART_OWNER_SUCCESS',

  CLEAR_SHOPPING_CART_SUCCESS: 'CLEAR_SHOPPING_CART_SUCCESS',

  ADD_FAVORITES: 'ADD_FAVORITES',
  REMOVE_FAVORITES: 'REMOVE_FAVORITES',
};

export default SHOPPING_CART;

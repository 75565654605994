import React from 'react';
import * as Connector from 'shared/connectors/productsConnector';
import { PRODUCTS } from 'store/types';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import { NOTIFY_TYPE } from 'shared/consts';

export const success = (uuid, categoryId, subcategoryId) => ({
  type: PRODUCTS.ADD_TO_FAVORITE_SUCCESS,
  payload: {
    uuid,
    categoryId,
    subcategoryId,
  },
});

const addToFavoritesProductLists = (uuid, categoryId, subcategoryId) => async dispatch => {
  try {
    const { data } = await Connector.addToFavorites(uuid);
    dispatch(success(uuid, categoryId, subcategoryId));
    toast(<Notify label="global.added_to_watchlist" type={NOTIFY_TYPE.ADD_TO_FAVORITE} />);
    return data;
  } catch (err) {
    return err.response;
  }
};

export default addToFavoritesProductLists;

import React, { useState, useEffect } from 'react';
import { object } from 'prop-types';

const InputCounter = ({ authorizedUser, data, modifyShoppingCart }) => {
  const [amount, setAmount] = useState(data.pivot_shopping_cart_amount > 0 && data.pivot_shopping_cart_amount);
  const countryUuid = localStorage.getItem('deliveryCountry') || authorizedUser?.country?.label;
  // const userId = data.id === -1 ? -1 : data.user_id;
  const userId = data.country.uuid !== countryUuid ? -1 : data.user_id;

  useEffect(() => {
    amount !== data.pivot_shopping_cart_amount && setAmount(data.pivot_shopping_cart_amount);
  }, [data.pivot_shopping_cart_amount]);

  const increaseAmount = async () => {
    await modifyShoppingCart({
      amount: 1,
      product_uuid: data.uuid,
      user_id: userId,
      uuid: data.uuid,
      country_uuid: countryUuid,
      update_option: 'add',
    });
    setAmount(amount + 1);
  };

  const decreaseAmount = async () => {
    await modifyShoppingCart({
      amount: 1,
      product_uuid: data.uuid,
      user_id: userId,
      uuid: data.uuid,
      country_uuid: countryUuid,
      update_option: 'remove',
    });
    setAmount(amount - 1);
  };
  return (
    <div className="input-counter">
      <div className="decrease button" onClick={decreaseAmount}>
        -
      </div>
      <input className="input" type="text" value={data.pivot_shopping_cart_amount} />
      <div className={`increase button  ${data?.stock?.in_stock <= amount ? 'button-disabled' : ''} `} onClick={increaseAmount}>
        {/* <div className={`increase button ${data?.stock?.in_stock > amount ? '' : 'button-disabled'}`} onClick={increaseAmount}> */}
        +
      </div>
    </div>
  );
};
export default InputCounter;

InputCounter.propTypes = {
  authorizedUser: object.isRequired,
  data: object.isRequired,
  modifyShoppingCart: object.isRequired,
};

const hideProductsOptions = formatMessage => {
  const arr = [
    {
      label: formatMessage({ id: 'global.allegro' }),
      searchableLabel: formatMessage({ id: 'global.allegro' }),
      value: 'gm',
    },
    {
      label: formatMessage({ id: 'global.getmotor' }),
      searchableLabel: formatMessage({ id: 'global.getmotor' }),
      value: 'allegro',
    },
    {
      label: `${formatMessage({ id: 'global.getmotor' })} + ${formatMessage({ id: 'global.allegro' })}`,
      searchableLabel: `${formatMessage({ id: 'global.getmotor' })} + ${formatMessage({ id: 'global.allegro' })}`,
      value: null,
    },
  ];

  return arr;
};

export default hideProductsOptions;

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isMobile as checkIsMobile } from 'shared/utils';
import { injectIntl } from 'react-intl';
import { getCategoryByLink } from 'store/actions/category';
import {
  getProductsByCategory,
  getProductsBySubCategory,
  getProductsByFilter,
  subtructProductsByCategory,
  addToFavoritesProductLists,
  removeFromFavoritesProductList,
  cleaningAllProducts,
} from 'store/actions/products';
import { getCarBrands, getCarModels } from 'store/actions/car';
import { getDeliveryCountries } from 'store/actions/delivery';
import { getAuthorizedUser } from 'store/selectors/userSelector';
import * as ViewManagementSelector from 'store/selectors/viewManagementSelector';
import { getProducts as getProductsSelector } from 'store/selectors/productsSelector';
import {
  getCategoryAndSubcategory as getCategoryAndSubcategorySelector,
  getCategoryByLink as getCategoryByLinkSelector,
} from 'store/selectors/categorySelector';
import { getCarBrands as getCarBrandsSelector, getCarModels as getCarModelsSelector } from 'store/selectors/carSelector';
import { getDeliveryCountries as getDeliveryCountriesSelector } from 'store/selectors/deliverySelector';
import ProductsLists from './ProductsLists.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCategoryByLink: uuid => getCategoryByLink(uuid),
      getProductsByCategory: (uuid, lang, user_uuid) => getProductsByCategory(uuid, lang, user_uuid),
      getProductsBySubCategory: (uuid, lang, user_uuid, subCategoryId, categoryId) =>
        getProductsBySubCategory(uuid, lang, user_uuid, subCategoryId, categoryId),
      getCarBrands: () => getCarBrands(),
      getCarModels: brandName => getCarModels(brandName),
      getDeliveryCountries: () => getDeliveryCountries(),
      cleaningAllProducts: () => cleaningAllProducts(),
      getProductsByFilter: form => getProductsByFilter(form),
      subtructProductsByCategory: (categoryId, name) => subtructProductsByCategory(categoryId, name),
      addToFavoritesProductLists: (uuid, categoryId, subcategoryId) =>
        addToFavoritesProductLists(uuid, categoryId, subcategoryId),
      removeFromFavoritesProductList: (uuid, categoryId, subcategoryId) =>
        removeFromFavoritesProductList(uuid, categoryId, subcategoryId),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  authorizedUser: getAuthorizedUser(state),
  isMobile: checkIsMobile(),
  categories: getCategoryAndSubcategorySelector(state),
  categoryByLink: getCategoryByLinkSelector(state),
  products: getProductsSelector(state),
  translation: ViewManagementSelector.getTranslation(state),
  carBrands: getCarBrandsSelector(state),
  carModels: getCarModelsSelector(state),
  deliveryCountries: getDeliveryCountriesSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProductsLists));

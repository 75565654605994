import React from 'react';
import * as Connector from 'shared/connectors/contactConnector';
import { CONTACT } from 'store/types';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import { NOTIFY_TYPE } from 'shared/consts';

export const success = () => ({
  type: CONTACT.SEND_MESSAGE_CONTACT_FORM_SUCCESS,
});

const failure = () => ({
  type: CONTACT.SEND_MESSAGE_CONTACT_FORM_FAILURE,
});

const sendMessageContactForm = formData => async dispatch => {
  try {
    const { data } = await Connector.sendMessageContactForm(formData);
    dispatch(success(data));
    data.status === 'success'
      ? toast(<Notify label="global.success_send_message" type={NOTIFY_TYPE.CHECKED} />)
      : toast(<Notify label="global.failure_send_message" type={NOTIFY_TYPE.CHECKED} />);
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default sendMessageContactForm;

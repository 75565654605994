import * as Connector from 'shared/connectors/userConnector';
import { USER } from 'store/types';

const init = () => ({
  type: USER.USER_BY_ADMIN_RESEND_LINK_INIT,
});

export const success = data => ({
  type: USER.USER_BY_ADMIN_RESEND_LINK_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: USER.USER_BY_ADMIN_RESEND_LINK_FAILURE,
});

const userByAdminResendLink = uuid => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.userByAdminResendLink(uuid);
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
  }
};

export default userByAdminResendLink;

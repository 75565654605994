import { bool, func, object, string } from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button, SVG } from 'shared/components';
import { SVG_TYPE, ROUTES } from 'shared/consts';
import { useHistory } from 'react-router-dom';
import stockErrors from './stockErrors';

const Right = ({
  formatMessage,
  data,
  basket,
  setIsSubmitting,
  deliveryPrice,
  simulateCheckout,
  transactionData,
  isSubmitting,
  translation,
  // setProductValidation,
}) => {
  const [amounts, setAmount] = useState({ start: 0, end: 3 });
  const history = useHistory();
  const nonDelivery = basket.products.filter(item => item.delivery_uuid === null);
  // const deliveryProducts = JSON.parse(localStorage.getItem('deliveryProducts'));

  useEffect(() => {
    const deliveryProducts = JSON.parse(localStorage.getItem('deliveryProducts'));
    if (deliveryProducts && deliveryProducts.length > 0) {
      deliveryProducts.forEach(item => {
        const product = document.getElementById(`product--${item.product_uuid}`);
        const delivery = product?.getElementsByClassName(`delivery__item--${item.delivery_uuid}`);
        setTimeout(() => {
          if (delivery && delivery.length > 0) {
            delivery[0].click();
          }
        }, 300);
      });
    }
  }, []);

  const onClickSummary = async () => {
    localStorage.setItem('deliveryProducts', JSON.stringify(basket.products));
    if (nonDelivery.length >= 1) {
      basket.products.forEach(({ delivery_uuid, product_uuid }) => {
        if (!delivery_uuid) {
          const product = document.getElementById(`product--${product_uuid}`);
          if (product) {
            const arrow = product.getElementsByClassName('delivery__arrow');
            const isActiveArrow = arrow.length > 0 && arrow[0].classList.contains('delivery__arrow--active');
            !isActiveArrow && arrow.length > 0 && arrow[0].click();
            const deliveriesItems = product.getElementsByClassName('delivery__with--error');
            for (let i = 0; i < deliveriesItems.length; i++) {
              const el = deliveriesItems[i];
              if (i >= 0) {
                el.classList.add('delivery__item--error');
              }
            }
            const errorMessage = product.getElementsByClassName('delivery__list--error');
            const productWrapper = product.closest('.seller-box--wrapper');
            const deliveryGroupSingleElement = productWrapper.getElementsByClassName('delivery__item--group');
            for (let i = 0; i < deliveryGroupSingleElement.length; i++) {
              const el = deliveryGroupSingleElement[i];
              if (i >= 0) {
                el.classList.add('delivery__item--error');
              }
            }
            const deliveryGroup = productWrapper.getElementsByClassName('delivery__list--error');
            deliveryGroup[0]?.classList.add('delivery__list--error--active', 'group--error');
            errorMessage.length > 0 && errorMessage[0].classList.add('delivery__list--error--active');
          }
        }
      });
      setTimeout(() => {
        const formErrors = document.getElementsByClassName('regular-field__input--error');
        if (formErrors.length > 0) {
          formErrors[0].scrollIntoView({ behavior: 'smooth', block: 'end' });
        } else {
          const deliveryErrors = document.getElementsByClassName('delivery__list--error--active');
          if (deliveryErrors.length > 0) {
            deliveryErrors[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
      }, 100);
    } else if (transactionData) {
      const simulate = await simulateCheckout({
        shopping_cart_uuid: data.shopping_cart.uuid || localStorage.getItem('notLoggedUserBasketUuid'),
        products: [...basket.products],
        transaction_data: basket.transaction_data,
      });
      if (simulate?.status === 'success') {
        history.push(ROUTES.SUMMARY);
      } else if (simulate?.status === 'error') {
        stockErrors(simulate.stockErrors, translation);
      }
    } else {
      const summary = document.getElementsByClassName('summary-btn');
      const errors = document.getElementsByClassName('regular-field__input--error');
      if (errors.length >= 1) {
        setIsSubmitting(false);
      } else {
        setTimeout(() => {
          summary[0]?.click();
        }, 100);
      }
    }
  };

  useEffect(async () => {
    if (transactionData && nonDelivery.length === 0 && basket.products.length > 0 && isSubmitting) {
      const simulate = await simulateCheckout({
        shopping_cart_uuid: data.shopping_cart.uuid || localStorage.getItem('notLoggedUserBasketUuid'),
        products: [...basket.products],
        transaction_data: basket.transaction_data,
      });
      simulate?.status === 'success' ? history.push(ROUTES.SUMMARY) : console.log(simulate);
    }
  }, [transactionData]);

  return (
    <div id="right-section--wrapper" className="right__wrapper">
      <div id="right-section" className="right right--second">
        <div className="heading heading--capitalize">{formatMessage({ id: 'global.preview' })}</div>

        {data.shopping_cart?.products.map((item, index, elements) => {
          const lastItem = index === amounts.end && index !== elements.length - 1;
          const isNotFirst = amounts.start !== 0 && amounts.start === index;

          return (
            <>
              {index <= amounts.end && index >= amounts.start && (
                <div className="item__wrapper">
                  <div
                    className={`first ${isNotFirst ? 'before before--on' : ''}`}
                    onClick={() => {
                      setAmount({ start: amounts.start - 1, end: amounts.end - 1 });
                    }}
                  >
                    {isNotFirst && <SVG type={SVG_TYPE.CHEVRONE} />}
                  </div>
                  <div className="item">
                    {item.files.length > 0 && item.files[0] ? (
                      <img className="image" src={item.files.length > 0 && item.files[0] && item.files[0].path} alt="" />
                    ) : (
                      <SVG className="image" type={SVG_TYPE.DEFAULT_PHOTO} />
                    )}
                    <div>
                      <p className="paragraph">{item.title}</p>
                      <p className="price">
                        {item.price} <span className="currency">{item.currency}</span>{' '}
                        <span className="amount"> x{item.pivot_shopping_cart_amount}</span>
                      </p>
                    </div>
                    <p className="price-all">
                      {item.products_all_price} <span className="currency">{item.currency}</span>
                    </p>
                  </div>

                  <div
                    className={`last ${lastItem ? 'next next--on' : ''}`}
                    onClick={() => {
                      setAmount({ start: amounts.start + 1, end: amounts.end + 1 });
                    }}
                  >
                    {lastItem && <SVG type={SVG_TYPE.CHEVRONE} />}
                  </div>
                </div>
              )}
            </>
          );
        })}
        <div className="line">
          <p className="paragraph">{formatMessage({ id: 'global.value_products' })}</p>
          <p className="paragraph">
            {data.all_price} <span>{data.currency}</span>
          </p>
        </div>
        <div className="line">
          <p className="paragraph">{formatMessage({ id: 'global.shipment' })}</p>
          <p className="paragraph">
            {parseFloat(deliveryPrice.price).toFixed(2)} <span>{data.currency}</span>
          </p>
        </div>

        <div className="border-bottom" />
        <div className="line line--bold">
          <p className="paragraph">{formatMessage({ id: 'global.together' })}</p>
          <p className="paragraph">
            {(
              parseFloat(typeof data.all_price === 'string' ? data.all_price.replaceAll(',', '.') : data.all_price) +
              parseFloat(deliveryPrice.price)
            ).toFixed(2)}
            <span> {data.currency}</span>
          </p>
        </div>

        <div className="buttons">
          <Button
            className="btn--orange summary-btn"
            label={formatMessage({ id: 'global.go_to_summary' })}
            type="submit"
            onClick={() => {
              onClickSummary();
              setIsSubmitting(true);
            }}
          />
        </div>
        <div id="amount--error" className="error--amount">
          <span className="error--amount--first">
            {formatMessage({ id: 'global.you_have_exceeded_the_available_quantity' })}{' '}
          </span>
          <span className="error--amount--mid" />
          <span className="error--amount--last">{formatMessage({ id: 'global.reduce_the_amount' })}</span>
        </div>
        <div id="unavailables--button--error" className="error--amount">
          <span className="error--amount--first">{formatMessage({ id: 'global.product' })}</span>
          <span className="error--unavailable--mid" />
          <span className="error--amount--last">{formatMessage({ id: 'global.product_unavailable_delete' })}</span>
        </div>
      </div>
    </div>
  );
};

export default Right;

Right.propTypes = {
  basket: object.isRequired,
  data: object.isRequired,
  deliveryPrice: object.isRequired,
  formatMessage: func.isRequired,
  isSubmitting: bool.isRequired,
  setCheckout: func.isRequired,
  setIsSubmitting: func.isRequired,
  setProductValidation: func.isRequired,
  simulateCheckout: func.isRequired,
  transactionData: object.isRequired,
  translation: string.isRequired,
};

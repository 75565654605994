import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { settings, menuItems } from '../utils/menuUtils';

const MagazineMenu = ({ menuType, setMenuType, isMobile, formatMessage }) => (
  <>
    {!isMobile ? (
      <div className="user-management__menu">
        {menuItems(formatMessage).map(({ type }) => (
          <div
            className={`user-management__menu--item ${menuType === type ? 'user-management__menu--active' : ''}`}
            onClick={() => setMenuType(type)}
          >
            <div className="label">{type}</div>
          </div>
        ))}
      </div>
    ) : (
      <div className="user-management__menu">
        <Slider {...settings}>
          {menuItems(formatMessage).map(({ type }) => (
            <div
              className={`user-management__menu--item ${menuType === type ? 'user-management__menu--active' : ''}`}
              onClick={() => setMenuType(type)}
            >
              <div className="label">{type}</div>
            </div>
          ))}
        </Slider>
      </div>
    )}
  </>
);

export default MagazineMenu;

MagazineMenu.propTypes = {
  formatMessage: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  menuType: PropTypes.bool.isRequired,
  setMenuType: PropTypes.func.isRequired,
};

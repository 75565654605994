const SVG_TYPE = {
  USER: 'USER',
  HEART: 'HEART',
  HEADPHONES: 'HEADPHONES',
  CATEGORY: 'CATEGORY',
  CLOSE: 'CLOSE',
  MENU: 'MENU',
  EYE: 'EYE',
  EYE_CLOSE: 'EYE_CLOSE',
  TEL: 'TEL',
  LOGO: 'LOGO',
  LOGO2: 'LOGO2',
  LOGO_WHITE: 'LOGO_WHITE',
  LOGO_TEXT: 'LOGO_TEXT',
  PHONE: 'PHONE',
  MAIL: 'MAIL',
  MAP: 'MAP',
  CHEVRONE: 'CHEVRONE',
  DOUBLE_CHEVRONE: 'DOUBLE_CHEVRONE',
  FIT_CHEVRONE: 'FIT_CHEVRON',
  FACEBOOK: 'FACEBOOK',
  INSTAGRAM: 'INSTAGRAM',
  MESSENGER: 'MESSENGER',
  BASKET: 'BASKET',
  LOUPE: 'LOUPE',
  PIN: 'PIN',
  FUEL_SUPPLY: 'FUEL_SUPPLY',
  ENGINE: 'ENGINE',
  BRAKE_SYSTEM: 'BRAKE_SYSTEM',
  SUSPENION_ARMS: 'SUSPENION_ARMS',
  TRANSMISSION: 'TRANSMISSION',
  FILTERS: 'FILTERS',
  BODY: 'BODY',
  INTERIOR_COMFORT: 'INTERIOR_COMFORT',
  TYRES: 'TYRES',
  IGNITION_GLOWPLUG: 'IGNITION_GLOWPLUG',
  STEERING: 'STEERING',
  ENGINE_COOLING: 'ENGINE_COOLING',
  SHIELD: 'SHIELD',
  FULL_HEART: 'FULL_HEART',
  FULL_FILL_HEART: 'FULL_FILL_HEART',
  STAR: 'STAR',
  BIN: 'BIN',
  FILTER: 'FILTER',
  PLUS: 'PLUS',
  FILTERING: 'FILTERING',
  DOTS: 'DOTS',
  ADD_PHOTO: 'ADD_PHOTO',
  ARROW_DOWN: 'ARROW_DOWN',
  ARROW_RIGHT: 'ARROW_RIGHT',
  TEST: 'TEST',
  PAGE_404: 'PAGE_404',
  LONG_ARROW: 'LONG_ARROW',
  DOWNLOAD: 'DOWNLOAD',
  CIRCLE_CHECKED: 'CIRCLE_CHECKED',
  SETTINGS: 'SETTINGS',
  EXCHANGE: 'EXCHANGE',
  ABOUT_US: 'ABOUT_US',
  ABOUT_US_ILUSTRATION: 'ABOUT_US_ILUSTRATION',
  PEOPLE: 'PEOPLE',
  PRODUCTS: 'PRODUCTS',
  BRAND: 'BRAND',
  MAP_MOBILE: 'MAP_MOBILE',
  STAR_CIRCLE: 'STAR_CIRCLE',
  CHAT: 'CHAT',
  CHAT_CONSULTANT: 'CHAT_CONSULTANT',
  DASH: 'DASH',
  SENDER: 'SENDER',
  LOGO_ALLEGRO: 'LOGO_ALLEGRO',
  SUCCESS_TRUMPET: 'SUCCESS_TRUMPET',
  PRZELEWY_24: 'PRZELEWY_24',
  CORRECT_CIRCLE: 'CORRECT_CIRCLE',
  INCORRECT_CIRCLE: 'INCORRECT_CIRCLE',
  IN_PROGRESS: 'IN_PROGRESS',
  ARRIVED: 'ARRIVED',
  ARROW_RIGHT_BLACK: 'ARROW_RIGHT_BLACK',
  LOGO_EBAY: 'LOGO_EBAY',
  SUCCESS_ALLEGRO_CONNECTION: 'SUCCESS_ALLEGRO_CONNECTION',
  COOKIE_BUTTON: 'COOKIE_BUTTON',
  TOOLTIP: 'TOOLTIP',
  ERROR_ALLEGRO: 'ERROR_ALLEGRO',
  CIRCLE_RED_CLOSE: 'CIRCLE_RED_CLOSE',
  ERROR: 'ERROR',
  CIRCLE_GREEN_CHECKED: 'CIRCLE_GREEN_CHECKED',
  CHECKED_FULL: 'CHECKED_FULL',
  DEFAULT_PHOTO: 'DEFAULT_PHOTO',
};

export default SVG_TYPE;

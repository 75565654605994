import React from 'react';
import { Button } from 'shared/components';
import { func, shape } from 'prop-types';

const Invoices = ({ intl: { formatMessage } }) => {
  const mockInvoices = [
    {
      brand: 'Nissan',
      model: 'X-TRAIL',
      element: 'błotnik przedni lewy',
    },
    {
      brand: 'Nissan',
      model: 'X-TRAIL',
      element: 'błotnik przedni lewy',
    },
    {
      brand: 'Nissan',
      model: 'X-TRAIL',
      element: 'błotnik przedni lewy',
    },
    {
      brand: 'Nissan',
      model: 'X-TRAIL',
      element: 'błotnik przedni lewy',
    },
    {
      brand: 'Nissan',
      model: 'X-TRAIL',
      element: 'błotnik przedni lewy',
    },
    {
      brand: 'Nissan',
      model: 'X-TRAIL',
      element: 'błotnik przedni lewy',
    },
    {
      brand: 'Nissan',
      model: 'X-TRAIL',
      element: 'błotnik przedni lewy',
    },
    {
      brand: 'Nissan',
      model: 'X-TRAIL',
      element: 'błotnik przedni lewy',
    },
    {
      brand: 'Nissan',
      model: 'X-TRAIL',
      element: 'błotnik przedni lewy',
    },
    {
      brand: 'Nissan',
      model: 'X-TRAIL',
      element: 'błotnik przedni lewy',
    },
    {
      brand: 'Nissan',
      model: 'X-TRAIL',
      element: 'błotnik przedni lewy',
    },
    {
      brand: 'Nissan',
      model: 'X-TRAIL',
      element: 'błotnik przedni lewy',
    },
    {
      brand: 'Nissan',
      model: 'X-TRAIL',
      element: 'błotnik przedni lewy',
    },
    {
      brand: 'Nissan',
      model: 'X-TRAIL',
      element: 'błotnik przedni lewy',
    },
    {
      brand: 'Nissan',
      model: 'X-TRAIL',
      element: 'błotnik przedni lewy',
    },
  ];
  return (
    <div className="invoices">
      {mockInvoices.length > 0 ? (
        <div className="list">
          <div className="title">{formatMessage({ id: 'global.invoices_list' })}</div>
          {mockInvoices.map(invoiceInfo => (
            <div className="listItem">
              <div className="listItem__left">
                <span>{invoiceInfo.brand}</span>
                <span>{invoiceInfo.model}</span>
                <span>{invoiceInfo.element}</span>
              </div>
              <div className="listItem__right">
                <Button label={formatMessage({ id: 'inputs.open' })} className="button-open" />
                <Button className="button-download" />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <div>{formatMessage({ id: 'global.invoices_list' })}</div>
          <div>{formatMessage({ id: 'global.empty_invoices' })}</div>
        </div>
      )}
    </div>
  );
};

export default Invoices;

Invoices.propTypes = {
  intl: shape({
    formatMessage: func.isRequired,
  }).isRequired,
};

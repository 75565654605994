import React from 'react';
import { func, bool, object, shape, string } from 'prop-types';

import { history } from 'store/store';
import { Content } from './components';

const ProductPreview = ({
  isMobile,
  categories,
  translation,
  authorizedUser,
  productPreview,
  delivery,
  carBrands,
  carModels,
  priceMargin,
  intl: { formatMessage },
  actions: {
    editAdvertisements,
    modifyShoppingCart,
    markSingleProductFavorite,
    unmarkSingleProductFavorite,
    addProducts,
    addProductPreview,
  },
}) => {
  !productPreview.title && history.push('/404');
  const isEdit = history?.location?.state?.edit;

  return (
    <div className="single-product product-preview">
      {productPreview.files && (
        <>
          <Content
            authorizedUser={authorizedUser}
            data={productPreview}
            modifyShoppingCart={modifyShoppingCart}
            translation={translation}
            formatMessage={formatMessage}
            isMobile={isMobile}
            categories={categories}
            addToFavorites={markSingleProductFavorite}
            removeFromFavorites={unmarkSingleProductFavorite}
            carModels={carModels}
            carBrands={carBrands}
            delivery={delivery}
            addProducts={addProducts}
            addProductPreview={addProductPreview}
            isEdit={isEdit}
            priceMargin={priceMargin}
            editAdvertisements={editAdvertisements}
          />
        </>
      )}
    </div>
  );
};

export default ProductPreview;

ProductPreview.propTypes = {
  actions: shape({
    modifyShoppingCart: func.isRequired,
    addToFavorites: func.isRequired,
    getSingleProduct: func.isRequired,
  }).isRequired,
  authorizedUser: object.isRequired,
  carBrands: object.isRequired,
  carModels: object.isRequired,
  categories: object.isRequired,
  categoryByLink: object.isRequired,
  delivery: object.isRequired,
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
  priceMargin: object.isRequired,
  productPreview: object.isRequired,
  removeFromFavorites: func.isRequired,
  singleProduct: object.isRequired,
  translation: string.isRequired,
};

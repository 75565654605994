import * as Connector from 'shared/connectors/deliveryConnector';
import { DELIVERY } from 'store/types';

const init = () => ({
  type: DELIVERY.GET_DELIVERY_COUNTRIES_INIT,
});

export const success = data => ({
  type: DELIVERY.GET_DELIVERY_COUNTRIES_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: DELIVERY.GET_DELIVERY_COUNTRIES_FAILURE,
});

const getDeliveryCountries = () => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getDeliveryCountries();
    dispatch(success(data.label));
    return true;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default getDeliveryCountries;

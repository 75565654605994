const encodeModel = (name, models) => {
  if (name && models) {
    const nameWithSpace = name.replaceAll('_', ' ');
    const model = models.filter(item => item.value === nameWithSpace);
    const modelCode = model.length > 0 && model[0].label;
    return modelCode;
  }
  return null;
};

export default encodeModel;

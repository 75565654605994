import * as Connector from 'shared/connectors/priceConnector';
import { PRICE } from 'store/types';

const init = () => ({
  type: PRICE.GET_PRICE_MARGIN_INIT,
});

export const success = data => ({
  type: PRICE.GET_PRICE_MARGIN_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: PRICE.GET_PRICE_MARGIN_FAILURE,
});

const getPriceMargin = (option, uuid) => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getPriceMargin(option, uuid);
    dispatch(success(data));
    return true;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default getPriceMargin;

/* eslint react/prop-types: 0 */

import moment from 'moment';
import React from 'react';
import { Button, SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import Product from './Product';

const OrderItem = ({ data, formatMessage, isMobile, getInvoice }) => {
  const { user_first_name, user_last_name, checkouts_products, final_price, status, created_at, currency, uuid, address } = data;
  const getSellerId = product => product.owner.id;
  const getDeliveryId = product => product.delivery.id;
  const styledCurrenct = <span className="order-item__product__currency">{currency}</span>;

  const groupBySeller = checkouts_products.reduce((sellersSoFar, item) => {
    if (!sellersSoFar[getSellerId(item)]) sellersSoFar[getSellerId(item)] = [];
    sellersSoFar[getSellerId(item)].push(item);
    return sellersSoFar;
  }, {});
  const groupByDelivery = productsList =>
    productsList.reduce((deliverySoFar, item) => {
      if (!deliverySoFar[getDeliveryId(item)]) deliverySoFar[getDeliveryId(item)] = [];
      deliverySoFar[getDeliveryId(item)].push(item);
      return deliverySoFar;
    }, {});

  const handleActiveChange = id => {
    const { classList } = document.getElementById(id);
    const activeClass = 'order-item__delivery-address--active';
    if (classList.contains(activeClass)) {
      classList.remove(activeClass);
    } else {
      classList.add(activeClass);
    }
  };

  const handleDownloadInvoice = async () => {
    const result = await getInvoice(uuid);
    if (result.data.invoice_url) {
      const a = document.createElement('a');
      a.setAttribute('href', result.data.invoice_url);
      a.setAttribute('target', '_blank');
      a.click();
    }
  };

  return (
    <div className={`order-item__wrapper order-item__wrapper--${status}`}>
      <div className="order-item__top">
        {status === 'paid' && (
          <Button
            label={formatMessage({ id: 'global.invoice' })}
            className="btn--invoice btn--submitted-gray btn--submitted-gray--sharp"
            onClick={() => handleDownloadInvoice()}
          />
        )}

        <span className="order-item__status">
          {formatMessage({ id: `global.${status}` })}
          {status === 'paid' ? <SVG type={SVG_TYPE.CORRECT_CIRCLE} /> : <SVG type={SVG_TYPE.INCORRECT_CIRCLE} />}
        </span>
        <span>
          {moment(created_at).format('DD.MM.YYYY')} {formatMessage({ id: 'global.at' })} {moment(created_at).format('HH:mm')}{' '}
        </span>
      </div>
      <p className="order-item__person">
        {formatMessage({ id: 'global.buyer' })}:
        <span className="order-item__name">
          {' '}
          {user_first_name && user_first_name} {user_last_name && user_last_name}
        </span>
      </p>
      {Object.values(groupBySeller).map(products => (
        <>
          <p className="order-item__person">
            {formatMessage({ id: 'global.seller' })}:{' '}
            <span className="order-item__name">
              {products[0].owner.first_name} {products[0].owner.last_name}{' '}
            </span>{' '}
          </p>
          <div className="order-item__products">
            {Object.values(groupByDelivery(products)).map(delivery => (
              <>
                {delivery.map(product => (
                  <Product
                    key={product.uuid}
                    data={product}
                    currency={styledCurrenct}
                    isMobile={isMobile}
                    formatMessage={formatMessage}
                  />
                ))}
                <div className="order-item__delivery">
                  <span>{formatMessage({ id: 'global.shipment' })}:</span>
                  <div>
                    <span>{delivery[0].delivery.name}</span>
                    <div
                      id={uuid + delivery[0].delivery.id}
                      className="order-item__delivery-address"
                      onClick={() => handleActiveChange(uuid + delivery[0].delivery.id)}
                    >
                      <p className="order-item__delivery-address__label">
                        {formatMessage({ id: 'global.delivery_address' })} <SVG type={SVG_TYPE.CHEVRONE} />
                      </p>

                      <address className="order-item__delivery-address__content">
                        <span>
                          {address?.first_name} {address?.last_name}
                        </span>
                        <span>{address?.email}</span>
                        <span>{address?.address}</span>
                        <span>
                          {address?.post_code} {address?.city}
                        </span>
                        <span>
                          {address?.phone_prefix} {address?.phone_number}
                        </span>
                      </address>
                    </div>
                  </div>
                  <span className="order-item--price">
                    {delivery[0].delivery.price} {styledCurrenct}
                  </span>
                </div>
              </>
            ))}
          </div>
        </>
      ))}
      <div className="order-item__total">
        <span className="order-item__total__left">{formatMessage({ id: 'global.total' })}:</span>
        <span className="order-item__total__right">
          {' '}
          {final_price} {styledCurrenct}{' '}
        </span>
      </div>
    </div>
  );
};

export default OrderItem;

import * as Connector from 'shared/connectors/deliveryConnector';
import { DELIVERY } from 'store/types';

const init = () => ({
  type: DELIVERY.GET_DELIVERY_INIT,
});

export const success = data => ({
  type: DELIVERY.GET_DELIVERY_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: DELIVERY.GET_DELIVERY_FAILURE,
});

const getDelivery = country_uuid => async dispatch => {
  dispatch(init());


  try {
    const {
      data: { delivery },
    } = await Connector.getDelivery(country_uuid);
    dispatch(success(delivery));
    return true;
  } catch (err) {
    dispatch(failure());
    console.log(err);
    return err.response;
  }
};

export default getDelivery;

import React from 'react';
import PropTypes from 'prop-types';
import { SVG } from 'shared/components';

function Button({ label, type, className, onClick, iconType, iconPlacement = 'right' }) {
  // const buttonClasses = `btn ${classNames} ${iconType ? 'btn--with-icon' : ''}`;
  const buttonClasses = `btn ${className}`;
  return (
    // eslint-disable-next-line react/button-has-type
    <button className={buttonClasses} onClick={onClick} type={type}>
      {iconPlacement === 'left' ? (
        <>
          <SVG type={iconType} className="btn__icon--left" /> <p>{label}</p>
        </>
      ) : (
        <>
          <p>{label}</p> <SVG className={`${label ? 'btn__icon--right' : ''}`} type={iconType} />
        </>
      )}
    </button>
  );
}

Button.propTypes = {
  className: PropTypes.string.isRequired,
  iconPlacement: PropTypes.string,
  iconType: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
};

Button.defaultProps = {
  iconPlacement: 'right',
  iconType: '',
  label: '',
  onClick: null,
  type: 'button',
};

export default Button;

import API from 'shared/connectors/config';

export function login(data) {
  return API.post('auth/login', data);
}

export function logout() {
  return API.put('auth/logout');
}

export function editSelf(data) {
  return API.put(`checkout-custom-address`, data);
}
export function getCheckoutAddress() {
  return API.get(`checkout-custom-address`);
}

/* eslint-disable react/prop-types */
import React from 'react';
import { Field, Formik } from 'formik';
import { SearchField } from 'shared/components';
import PropTypes from 'prop-types';

const SearchEngine = ({ className, placeholder, handleChange, innerRef }) => {
  const handleOnSubmit = values => {
    console.log(values);
  };
  return (
    <Formik innerRef={innerRef} initialValues={{ search: '' }} onSubmit={handleOnSubmit}>
      {({ handleSubmit, errors }) => (
        <>
          <form className={`searchEngine ${className || ''}`} onSubmit={handleSubmit}>
            <Field
              placeholder={placeholder}
              component={SearchField}
              errors={errors}
              label="email"
              name="search"
              type="text"
              onChange={handleChange}
            />
          </form>
        </>
      )}
    </Formik>
  );
};

export default SearchEngine;

SearchEngine.propTypes = {
  className: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
};

SearchEngine.defaultProps = {
  className: '',
  handleChange: false,
  placeholder: '',
};

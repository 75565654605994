import React from 'react';
import * as Connector from 'shared/connectors/deliveryConnector';
import { DELIVERY } from 'store/types';
import { addFiles } from 'store/actions/utils';
import { getDelivery } from 'store/actions/delivery';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import { NOTIFY_TYPE } from 'shared/consts';

const init = () => ({
  type: DELIVERY.GET_DELIVERY_INIT,
});

export const success = () => ({
  type: DELIVERY.GET_DELIVERY_SUCCESS,
});

const failure = () => ({
  type: DELIVERY.GET_DELIVERY_FAILURE,
});

const addDeliveryMethod = form => async dispatch => {
  dispatch(init());
  try {
    const { files } = form;
    const { data } = await Connector.addDeliveryMethod(form);
    if (files) {
      const fileForm = new FormData();
      fileForm.append('file', files[0]);
      await dispatch(addFiles(fileForm, data.delivery.uuid, 'delivery_uuid='));
    }
    toast(<Notify label="global.successful_add_delivery_option" type={NOTIFY_TYPE.CHECKED} />);
    dispatch(getDelivery(form.country_uuid));
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default addDeliveryMethod;

import API from 'shared/connectors/config';

export function createCheckoutSession(uuid) {
  return API.post(`payments/create-checkout-session`, { checkout_uuid: uuid });
}
export function addInvoiceData(data) {
  return API.post(`payments/manage-invoice-data`, data);
}
export function getInvoiceData(country_uuid) {
  return API.get(`payments/get-invoice-data`,{ params: { country_uuid } });
}
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { object, string } from 'prop-types';

const SellerComment = ({ data, translation }) => (
  <div className="seller-comment">
    <p className="seller-comment--heading">
      <FormattedMessage id="global.seller_comment" />
    </p>
    <p className="seller-comment--paragraph">{translation === 'pl' ? data.description : data.description_ger}</p>
  </div>
);

export default SellerComment;

SellerComment.propTypes = {
  data: object.isRequired,
  translation: string.isRequired,
};

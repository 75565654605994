import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { func } from 'prop-types';
import { string } from 'yup';

const CompanyInfo = ({ formatMessage, addressLang }) => (
  <section className="main-footer__column">
    <h4 className="main-footer--name">
      <SVG type={SVG_TYPE.LOGO_TEXT} />
    </h4>
    <div className="main-footer__data">
      <SVG type={SVG_TYPE.PHONE} className="main-footer__data--icon" />
      <a className="main-footer__data--phone" href="tel:+48382283281" data-rel="external">
        {formatMessage({ id: 'menu.telephone' })}
      </a>
    </div>
    <div className="main-footer__data">
      <SVG type={SVG_TYPE.MAIL} className="main-footer__data--icon" />
      <p>{formatMessage({ id: 'menu.mail' })}</p>
    </div>
    <div className="main-footer__data">
      <SVG type={SVG_TYPE.PIN} className="main-footer__data--icon" />
      <p>
        {addressLang === 'de' ? (
          <>
            GET MOTOR <br />
            Kleine Friendenstraße 24 <br />
            15328 Küstriner Vorland
          </>
        ) : (
          <>
            GET MOTOR Sp. z o.o <br />
            Dormowo 45 <br />
            64-426 Dormowo
          </>
        )}
      </p>
    </div>
  </section>
);

export default CompanyInfo;

CompanyInfo.propTypes = {
  addressLang: string.isRequired,
  formatMessage: func.isRequired,
};

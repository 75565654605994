import { useState, useEffect } from 'react';

const isBack = () => {
  const [backDetect, setBackDetect] = useState(false);

  useEffect(
    () => async () => {
      setBackDetect(false);
    },
    [],
  );
  window.onpopstate = () => {
    setBackDetect(true);
  };
  return backDetect;
};

export default isBack;

const stockErrors = (errors, translation) => {
  if (errors) {
    const buttonSummary = document.getElementsByClassName('summary-btn');
    buttonSummary[0].classList.add('summary-btn--gray');

    // check is amount error
    const isAmount = errors.filter(item => item.in_stock > 0);
    if (isAmount.length > 0) {
      const errorWrapper = document.getElementById('amount--error');
      errorWrapper.classList.add('error--amount--display');
      const errorBox = document.getElementsByClassName('error--amount--mid');
      isAmount.forEach(item => {
        const errorItem = document.createElement('span');
        errorItem.setAttribute('id', `product-error--${item.product_uuid}`);
        errorItem.classList.add(`product-error`, 'product-amount-error');
        if (translation === 'pl') {
          errorItem.append(item.product_title);
        } else {
          errorItem.append(item.product_title_ger);
        }
        errorBox[0].append(errorItem);
      });
    }
    // end check is amount error
    // is unavailable product error
    const isUnavailable = errors.filter(item => item.in_stock === 0);
    if (isUnavailable.length > 0) {
      const errorWrapper = document.getElementById('unavailables--button--error');
      errorWrapper.classList.add('error--amount--display');
      const errorBox = document.getElementsByClassName('error--unavailable--mid');
      isUnavailable.forEach(item => {
        const errorItem = document.createElement('span');
        errorItem.setAttribute('id', `product-error--${item.product_uuid}`);
        errorItem.classList.add(`product-error`, 'product-unavailable-error');
        if (translation === 'pl') {
          errorItem.append(item.product_title);
        } else {
          errorItem.append(item.product_title_ger);
        }
        errorBox[0].append(errorItem);
      });
    }
    // end is unavailable product error

    errors.forEach(item => {
      const product = document.getElementById(`product--${item.product_uuid}`);
      product.scrollIntoView({ behavior: 'smooth', block: 'center' });
      // product.classList.add('seller-box__product--error');

      if (item.in_stock > 0) {
        // Error Amount > 0
        product.classList.add('seller-box__product--error', 'seller-box__product--error--more-than-zero');
        const buttonDecrease = product.getElementsByClassName('decrease');
        const errorBox = document.createElement('span');
        errorBox.classList.add('errorBox');
        errorBox.append(item.in_stock);
        const isBugExist = product.getElementsByClassName('errorBox');
        if (isBugExist.length <= 0) {
          const errorText = product.getElementsByClassName('seller-box__product--error-amount');
          errorText[0].append(errorBox);
        }
        // END Error Amount
        buttonDecrease[0].addEventListener('click', () => {
          const input = product.getElementsByClassName('input');
          if (input[0].value - 1 === item.in_stock) {
            product.classList.remove('seller-box__product--error', 'seller-box__product--error--more-than-zero');
            const errorItem = document.getElementById(`product-error--${item.product_uuid}`);
            errorItem?.remove();
            const productsError = document.getElementsByClassName('product-amount-error');
            const productsUnavailableError = document.getElementsByClassName('product-unavailable-error');
            if (productsError.length === 0) {
              const boxDescript = document.getElementById('amount--error');
              boxDescript.classList.remove('error--amount--display');
            }

            if (productsError.length === 0 && productsUnavailableError.length === 0) {
              buttonSummary[0].classList.remove('summary-btn--gray');
            }
          }
        });
      } else if (item.in_stock === 0) {
        product.classList.add('seller-box__product--error', 'seller-box__product--error-unavailable');
        const deleteButton = product.getElementsByClassName('delete');
        deleteButton[0].addEventListener('click', () => {
          product.classList.remove('seller-box__product--error', 'seller-box__product--error-unavailable');
          const errorItem = document.getElementById(`product-error--${item.product_uuid}`);
          errorItem?.remove();
          const productsError = document.getElementsByClassName('product-amount-error');
          const productsUnavailableError = document.getElementsByClassName('product-unavailable-error');
          if (productsUnavailableError.length === 0) {
            const boxDescript = document.getElementById('unavailables--button--error');
            boxDescript.classList.remove('error--amount--display');
          }

          if (productsError.length === 0 && productsUnavailableError.length === 0) {
            buttonSummary[0].classList.remove('summary-btn--gray');
          }
        });
      }
    });
  }
};

export default stockErrors;

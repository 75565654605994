import API from 'shared/connectors/config';

export function getDelivery(country_uuid) {
  return API.get(`delivery?country_uuid=${country_uuid}`);
}

export function getDeliveryCountries() {
  return API.get('countries-of-delivery');
}

export function addDeliveryMethod(data) {
  return API.post('delivery', data);
}

export function deleteDeliveryMethod(uuid) {
  return API.delete(`delivery/${uuid}`);
}

export function editDeliveryMethod(uuid, data) {
  return API.put(`delivery/${uuid}`, data);
}

import React from 'react';
import { Modal, ModalActions, Title } from 'shared/components';
import { MODAL_TYPE } from 'shared/consts';
import PropTypes from 'prop-types';

const ResumeModal = ({ opened, onClose, formatMessage, onSubmit, data }) => {
  const handleSubmit = () => {
    onSubmit(data?.data?.uuid);
    onClose();
  };
  return (
    <>
      <Modal opened={opened} onClose={onClose} className="backOff">
        <div className="backOff__content">
          <Title title={formatMessage({id: 'global.restore'})} className="modals" />
          <p>{formatMessage({ id: 'global.restore_product_confirmation'})}</p>
        </div>
        <ModalActions type={MODAL_TYPE.RESUME} formatMessage={formatMessage} onClose={onClose} onSubmit={handleSubmit} />
      </Modal>
    </>
  );
};

export default ResumeModal;

ResumeModal.propTypes = {
  data: PropTypes.object.isRequired,
  formatMessage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
};

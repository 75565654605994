import * as Connector from 'shared/connectors/customTranslationsConnector';
import store from 'store/store';
import { CUSTOM_TRANSLATIONS } from 'store/types';

export const success = data => ({
  type: CUSTOM_TRANSLATIONS.SEARCH_TRANSLATIONS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: CUSTOM_TRANSLATIONS.SEARCH_TRANSLATIONS_FAILURE,
});

const searchCustomTranslations = (params) => async dispatch => {
    const { search, country_id } = params
    const { role } = store.getState().authStore.authorizedUser.data
    const country = role === 'coordinator'? null : country_id
  try {
    const { data } = await Connector.searchCustomTranslations(search,country);
    dispatch(success({...data,role}));
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default searchCustomTranslations;

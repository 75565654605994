import React from 'react';
import { func, array, bool, string, object } from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

const Settings = ({ formatMessage, id, data, settingsType, setSettingsType, remove }) => {
  const handleClickSettings = () => {
    setSettingsType({ type: 'settings', id });
  };

  return (
    <div className="user-management__table__settings">
      <div
        className={`user-management__table__settings--item ${
          settingsType.id === id ? 'user-management__table__settings--item--disabled' : ''
        } `}
        onClick={() => handleClickSettings()}
      >
        <SVG type={SVG_TYPE.SETTINGS} />
      </div>
      {settingsType.type === 'settings' && settingsType.id === id && (
        <OutsideClickHandler display="flex" onOutsideClick={() => setSettingsType({ type: '' })}>
          <div className="user-management__table__settings__list price-management__settings" id={id}>
            <div
              className="user-management__table__settings__list--item"
              onClick={() => {
                setSettingsType({ type: 'edit', data });
              }}
            >
              {formatMessage({ id: 'global.edit' })}
            </div>
            {remove && (
              <div
                className="user-management__table__settings__list--item"
                onClick={() => {
                  setSettingsType({ type: 'delete', data });
                }}
              >
                {formatMessage({ id: 'inputs.delete' })}
              </div>
            )}
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default Settings;

Settings.propTypes = {
  data: object.isRequired,
  deliveryCountries: array.isRequired,
  formatMessage: func.isRequired,
  id: string.isRequired,
  isMobile: bool.isRequired,
  remove: bool.isRequired,
  setSettingsType: func.isRequired,
  settingsType: string.isRequired,
};

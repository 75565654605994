import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { func, object, shape, string } from 'prop-types';
import { Field, Formik } from 'formik';
import { RegularField, SVG, Button, SelectField } from 'shared/components';
import { SVG_TYPE, VALIDATION_FIELD, ROUTES, DELIVERY_COUNTRIES } from 'shared/consts';
import { Link, useHistory } from 'react-router-dom';

const Register = ({
  actions: { registerUser, getDeliveryCountries },
  intl: { formatMessage },
  translation,
  deliveryCountries,
}) => {
  useEffect(() => {
    getDeliveryCountries();
  }, []);

  const deliveryCountryOptions = () => {
    const arr = [];
    deliveryCountries.forEach(({ label, value }) =>
      arr.push({
        label: DELIVERY_COUNTRIES(formatMessage)[label],
        searchableLabel: DELIVERY_COUNTRIES(formatMessage)[label],
        value,
      }),
    );
    console.log(arr, 'tablica');
    return arr;
  };

  const history = useHistory();
  const [isEmailOccupied, setIsEmailOccupied] = useState(false);
  const handleOnSubmit = async values => {
    const { data } = await registerUser(values);
    data ? setIsEmailOccupied(true) : history.push(ROUTES.SEND_ACTIVATE_MAIL);
  };

  const validation = () =>
    Yup.object().shape({
      country_of_delivery_uuid: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .min(2, VALIDATION_FIELD(formatMessage).MIN_2),
      email: Yup.string()
        .test('email-occupied', VALIDATION_FIELD(formatMessage).EMAIL_OCCUPIED, () => !isEmailOccupied)
        .email(VALIDATION_FIELD(formatMessage).EMAIL)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED),
      firstName: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .min(2, VALIDATION_FIELD(formatMessage).MIN_2)
        .max(30, VALIDATION_FIELD(formatMessage).MAX_30),
      lastName: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .min(2, VALIDATION_FIELD(formatMessage).MIN_2)
        .max(50, VALIDATION_FIELD(formatMessage).MAX_50),
      password: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .min(8, VALIDATION_FIELD(formatMessage).MIN_8_PASSWORD)
        .max(50, VALIDATION_FIELD(formatMessage).MAX_50),
    });
  return (
    <main className="login__wrapper">
      <div className="login__logo">
        <SVG type={SVG_TYPE.LOGO} />
      </div>
      <Formik
        validationSchema={validation()}
        initialValues={{
          email: '',
          password: '',
          firstName: '',
          lastName: '',
          language: translation.toUpperCase(),
          country_of_delivery_uuid: '',
        }}
        onSubmit={handleOnSubmit}
      >
        {({ handleSubmit, errors, setFieldTouched }) => (
          <form className="login__form" onSubmit={handleSubmit}>
            {useEffect(() => {
              setFieldTouched('email', isEmailOccupied);
            }, [isEmailOccupied])}
            <div className="login__item">
              <h1 className="login--header">{formatMessage({ id: 'global.register' })}</h1>
              <Field
                type="text"
                label={formatMessage({ id: 'inputs.name' })}
                name="firstName"
                component={RegularField}
                errors={errors}
              />
              <Field
                type="text"
                label={formatMessage({ id: 'inputs.last_name' })}
                name="lastName"
                component={RegularField}
                errors={errors}
              />
              <Field
                type="text"
                inputOnChange={() => setIsEmailOccupied(false)}
                label={formatMessage({ id: 'inputs.email' })}
                name="email"
                component={RegularField}
                errors={errors}
              />
              <Field
                type="password"
                label={formatMessage({ id: 'inputs.password' })}
                name="password"
                component={RegularField}
                errors={errors}
              />
              <Field
                className="add-advertisement__select"
                component={SelectField}
                placeholder={formatMessage({ id: 'inputs.choose' })}
                label={formatMessage({ id: 'global.delivery_country' })}
                errors={errors}
                name="country_of_delivery_uuid"
                options={deliveryCountryOptions()}
                isCustomLabel
              />
              <Button className="btn--orange" label={formatMessage({ id: 'inputs.create_account' })} type="submit" />
              <p className="login__paragraph login__paragraph--bottom">
                {formatMessage({ id: 'global.you_have_account' })}
                <Link to={ROUTES.LOGIN}> {formatMessage({ id: 'inputs.login' })}</Link>
              </p>
            </div>
          </form>
        )}
      </Formik>
    </main>
  );
};

// Login.propTypes = {
//   isMobile: PropTypes.bool.isRequired,
// };

export default Register;

Register.propTypes = {
  actions: shape({
    registerUser: func.isRequired,
  }).isRequired,
  deliveryCountries: object.isRequired,
  formatMessage: func.isRequired,
  translation: string.isRequired,
};

import React from 'react';
import { logout } from 'shared/connectors/authConnector';
import { AUTH } from 'store/types';
import { NOTIFY_TYPE } from 'shared/consts';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import { clearShoppingCart } from 'store/actions/shopping-cart';

export const success = () => ({
  type: AUTH.LOGOUT_SUCCESS,
});

const logoutUser = () => async dispatch => {
  try {
    localStorage.removeItem('isUserLogged');
    await logout();
    dispatch(success());
    dispatch(clearShoppingCart());
    toast(<Notify label="Wylogowano." type={NOTIFY_TYPE.CHECKED} />);
  } catch (err) {
    console.log(err);
  }
};

export default logoutUser;

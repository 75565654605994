import { PRODUCTS } from 'store/types';

const init = () => ({
  type: PRODUCTS.ADD_PRODUCT_PREVIEW_INIT,
});

export const success = data => ({
  type: PRODUCTS.ADD_PRODUCT_PREVIEW_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: PRODUCTS.ADD_PRODUCT_PREVIEW_FAILURE,
});

const addProductPreview = (data) => async dispatch => {
  dispatch(init());
  try {
    data && dispatch(success(data));
    return data;
  } catch (err) {
    dispatch(failure());
    console.log(err.response)
      return err.response;
  }
};

export default addProductPreview;

import * as Connector from 'shared/connectors/productsConnector';
import { PRODUCTS } from 'store/types';

const init = () => ({
  type: PRODUCTS.GET_PRODUCTS_BY_SUB_CATEGORY_INIT,
});

export const success = (data, subCategoryId, categoryId) => ({
  type: PRODUCTS.GET_PRODUCTS_BY_SUB_CATEGORY_SUCCESS,
  payload: {
    data,
    subCategoryId,
    categoryId,
  },
});

const failure = () => ({
  type: PRODUCTS.GET_PRODUCTS_BY_SUB_CATEGORY_FAILURE,
});

const getProductsBySubCategory = (uuid, lang, user_uuid, subCategoryId, categoryId) => async dispatch => {
  uuid ? '' : dispatch(init);
  try {
    const { data } = uuid && (await Connector.getProductsBySubCategory(uuid, lang, user_uuid));
    dispatch(success(data.category, subCategoryId, categoryId));
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default getProductsBySubCategory;

import { connect } from 'react-redux';
import { isMobile as checkIsMobile } from 'shared/utils';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { getUsersByRole } from 'store/actions/user';
import { getDeliveryCountries } from 'store/actions/delivery';
import {
  getPriceMargin,
  getCurrencyExchangeList,
  editPriceMargin,
  editCurrencyExchange,
  createPriceMargin,
  deletePriceMargin,
} from 'store/actions/price';
import {
  getPriceMargin as getPriceMarginSelector,
  getCurrencyExchangeList as getCurrencyExchangeListSelector,
} from 'store/selectors/priceSelector';
import { getUsersByRole as getUsersByRoleSelector } from 'store/selectors/userSelector';
import PriceManagement from './PriceManagement.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getDeliveryCountries: () => getDeliveryCountries(),
      getPriceMargin: (option, uuid) => getPriceMargin(option, uuid),
      getCurrencyExchangeList: () => getCurrencyExchangeList(),
      getUsersByRole: role => getUsersByRole(role),
      editCurrencyExchange: (uuid, value) => editCurrencyExchange(uuid, value),
      editPriceMargin: values => editPriceMargin(values),
      createPriceMargin: values => createPriceMargin(values),
      deletePriceMargin: (uuid, type, itemUuid) => deletePriceMargin(uuid, type, itemUuid),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  isMobile: checkIsMobile(),
  priceMargin: getPriceMarginSelector(state),
  currencyExchangeList: getCurrencyExchangeListSelector(state),
  users: getUsersByRoleSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PriceManagement));

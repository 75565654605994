import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import PropTypes from 'prop-types';

const PartnerBadge = ({ className, formatMessage }) => (
  <div className={`partner-badge ${className}`}>
    <p className="partner-badge--label">{formatMessage({ id: 'global.partner' })}</p>
    <SVG type={SVG_TYPE.LOGO} className="partner-badge--logo" />
  </div>
);

PartnerBadge.defaultProps = {
  className: '',
};

PartnerBadge.propTypes = {
  className: PropTypes.string,
  formatMessage: PropTypes.func.isRequired,
};

export default PartnerBadge;

/* eslint-disable react/prop-types */

import React, { useState } from 'react';
import { func, array, bool } from 'prop-types';
import { Table, SelectField } from 'shared/components';
import { Settings, EditForm, ProductName, LocalMargin, DeleteMargin,Price } from 'screens/price-management/components';

const Products = ({
  formatMessage,
  partnersList,
  isMobile,
  productMargin,
  products,
  editPriceMargin,
  createPriceMargin,
  deletePriceMargin,
}) => {
  const [settingsType, setSettingsType] = useState({ active: '', id: '' });

  const partners = [];
  partnersList &&
    partnersList.forEach(({ first_name, last_name, uuid }) =>
      partners.push({
        label: `${first_name} ${last_name}`,
        searchableLabel: `${first_name} ${last_name}`,
        value: uuid,
      }),
    );
console.log(products)
  const getColumns = () => [
    {
      width: !isMobile ? '400' : '150',
      Cell: ({ original }) => (
        <div className="priceManagement_productName">
          <ProductName data={original} isMobile={isMobile} />
        </div>
      ),
      className: 'user-management__table__content--marginAuto',
    },
    {
      width: !isMobile ? '200' : '70',
      id: 'price',
      accessor: 'price',
      Cell: ({ original }) => <Price data={original} />,
    },
    {
      width: !isMobile ? '300' : '70',
      Cell: ({ original }) => <LocalMargin data={original} />,
    },
    {
      width: !isMobile ? '100' : '40',
      Cell: ({ original }) => (
        <Settings
          id={original.id}
          settingsType={settingsType}
          setSettingsType={setSettingsType}
          formatMessage={formatMessage}
          data={original}
          remove={!!original.price_margin}
        />
      ),
      className: 'user-management__table__content--marginAuto',
    },
  ];

  return (
    <>
      <div className="user-management__table--wrapper product-managment">
        <div className="price-management--partner-list">
          <SelectField
            isCustomLabel
            className="advertisements__filters--category"
            placeholder={formatMessage({ id: 'inputs.choose' })}
            label={formatMessage({ id: 'global.partners' })}
            options={partners}
            outsideFormChange={e => productMargin(e.value)}
          />
        </div>
        <div className="user-management__table__heading price-management__table--heading">
          <p className="">{formatMessage({ id: 'global.product' })}</p>
          <p className="user-management__table__heading--price">{formatMessage({ id: 'global.price' })}</p>
          <p className="">{formatMessage({ id: 'global.earnings' })}</p>
        </div>
        <div className="user-management__table__content">
          {products && products.length > 0 && <Table data={products} columns={getColumns()} />}
        </div>
      </div>
      <EditForm
        type="products"
        formatMessage={formatMessage}
        settingsType={settingsType}
        setSettingsType={setSettingsType}
        editPriceMargin={editPriceMargin}
        createPriceMargin={createPriceMargin}
      />
      <DeleteMargin
        settingsType={settingsType}
        setSettingsType={setSettingsType}
        formatMessage={formatMessage}
        deletePriceMargin={deletePriceMargin}
        type="products"
      />
    </>
  );
};

export default Products;

Products.propTypes = {
  createPriceMargin: func.isRequired,
  deletePriceMargin: func.isRequired,
  editPriceMargin: func.isRequired,
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
  partnersList: array.isRequired,
  products: array.isRequired,
};

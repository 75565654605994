import React, { useState, useEffect } from 'react';
import { func, shape, object } from 'prop-types';
import { NOTIFY_TYPE, SVG_TYPE, USER_ROLES } from 'shared/consts';
import { SVG, Notify } from 'shared/components';
import { toast } from 'react-toastify';
import { StopIcon } from './utils';
import { ChatBox } from './components';

const Chat = ({
  intl: { formatMessage },
  actions: { createRoom, getRooms, sendMessage, getRoomMessages },
  rooms,
  authorizedUser,
  deliveryCountries,
  roomMessages,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [newMessage, setNewMessage] = useState(false);

  const showChat =
    !authorizedUser ||
    authorizedUser?.role === USER_ROLES.CLIENT ||
    authorizedUser?.role === USER_ROLES.PARTNER ||
    !authorizedUser?.role === USER_ROLES.ADMIN;
  const notLoggedInUserActiveChat = localStorage.getItem('chatOpenUuid');

  useEffect(() => {
    deliveryCountries && getRooms();
  }, [authorizedUser,deliveryCountries]);

  useEffect(async () => {
    rooms?.room?.uuid && (await getRoomMessages({ room_uuid: rooms.room.uuid, page }, true));
  }, [page]);

  useEffect(async () => {
    const interval = setInterval(async () => {
      if (localStorage.getItem('chatOpenUuid') && rooms?.room?.uuid !== localStorage.getItem('chatOpenUuid')) {
        clearInterval(interval);
      } else {
        const response = rooms?.room?.uuid && (await getRoomMessages({ room_uuid: rooms.room.uuid, page }, true));
        if (response?.status === 200 && !localStorage.getItem('chatOpenUuid')) {
          localStorage.setItem('chatOpenUuid', rooms?.room?.uuid);
        }
        const lastMessage = response && response?.status !== 422 && response?.data?.messages[response.data.messages.length - 1];
        const lastMessageOwner = lastMessage && (lastMessage.owner === 'admin' || lastMessage.owner === 'coordinator');
        lastMessageOwner && setNewMessage(true);
        if (response?.status === 422) {
          lastMessageOwner && setNewMessage(false);
          !!localStorage.getItem('chatOpenUuid') &&
            toast(<Notify label="global.chat_archived" type={NOTIFY_TYPE.CHECKED} />, { toastId: 'chat archived' });
          localStorage.removeItem('chatOpenUuid');
          setPage(1);
        }
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [rooms]);

  const handleTriggerChat = async () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      if (notLoggedInUserActiveChat && !authorizedUser) {
        if (notLoggedInUserActiveChat && !rooms?.room) {
          const response = await getRooms(notLoggedInUserActiveChat);
          await getRoomMessages({ room_uuid: response?.room?.uuid, page });
        }
      } else {
        const response =
          !!rooms?.room?.is_closed || notLoggedInUserActiveChat || (!authorizedUser && (await createRoom(authorizedUser)));
        const item = !rooms?.status && (await getRooms(response?.room?.uuid));
        await getRoomMessages({ room_uuid: !rooms?.status ? item?.room?.uuid : rooms.room.uuid, page });
      }
    }
  };

  return (
    <>
      {showChat && (
        <div id="chat" className="chat">
          <StopIcon />

          <div className="chat__content">
            <div id="chat-icon" className="chat__icon" onClick={handleTriggerChat}>
              <div className="icon">
                <SVG type={SVG_TYPE.CHAT} />
                {newMessage && <div className="dot" />}
              </div>
            </div>
            {isOpen && (
              <ChatBox
                sendMessage={sendMessage}
                roomMessages={roomMessages}
                rooms={rooms}
                formatMessage={formatMessage}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                page={page}
                setPage={setPage}
                setNewMessage={setNewMessage}
                authorizedUser={authorizedUser}
                createRoom={createRoom}
                getRooms={getRooms}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Chat;

Chat.propTypes = {
  actions: shape({
    createRoom: func.isRequired,
    getRooms: func.isRequired,
    getRoomMessages: func.isRequired,
    sendMessage: func.isRequired,
  }).isRequired,
  authorizedUser: object.isRequired,
  deliveryCountries: object.isRequired,
  intl: shape({
    formatMessage: func.isRequired,
  }).isRequired,
  roomMessages: object.isRequired,
  rooms: object.isRequired,
};

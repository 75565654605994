import { connect } from 'react-redux';
import { isMobile as checkIsMobile } from 'shared/utils';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import * as ViewManagementSelector from 'store/selectors/viewManagementSelector';
import { getAuthorizedUser } from 'store/selectors/userSelector';
import { sendMessageContactForm } from 'store/actions/contact';
import ContactPage from './ContactPage.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      sendMessageContactForm: data => sendMessageContactForm(data),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  authorizedUser: getAuthorizedUser(state),
  isMobile: checkIsMobile(),
  translation: ViewManagementSelector.getTranslation(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ContactPage));

import React from 'react';
import animationData from 'assets/files/preloader.json';
import Lottie from 'react-lottie';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export default function Preloader() {
  return (
    <div className="home-page-preloader">
      <Lottie height={70} options={defaultOptions} width={70} />
    </div>
  );
}

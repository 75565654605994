import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { func, shape } from 'prop-types';
import { Field, Formik } from 'formik';
import { RegularField, SVG, Button } from 'shared/components';
import { SVG_TYPE, VALIDATION_FIELD, ROUTES } from 'shared/consts';
import { Link, useHistory } from 'react-router-dom';

const Login = ({ actions: { login, activateAccount }, intl: { formatMessage } }) => {
  const [isError, setIsError] = useState(false);
  const [errorLabel, setErrorLabel] = useState(' ');
  const history = useHistory();

  useEffect(() => {
    const activateToken = sessionStorage.getItem('activateToken');
    activateToken && activateAccount(activateToken);
  });

  const validation = () =>
    Yup.object().shape({
      email: Yup.string()
        .test('error user', ' ', () => !isError)
        .email(VALIDATION_FIELD(formatMessage).EMAIL)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED),
      password: Yup.string()
        .test('error user email', errorLabel, () => !isError)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .min(8, VALIDATION_FIELD(formatMessage).MIN_8_PASSWORD),
    });

  const handleOnSubmit = async values => {
    const response = await login(values);
    response.token && history.push(ROUTES.HOME_PAGE);
    const {
      data,
      data: { message },
    } = response;
    if (message === 'User is inactive') {
      setErrorLabel(VALIDATION_FIELD(formatMessage).USER_INACTIVE);
    } else if (message !== 'User is inactive') {
      setErrorLabel(VALIDATION_FIELD(formatMessage).LOGIN_ERROR);
    }
    data && setIsError(true);
  };
  return (
    <main className="login__wrapper">
      <div className="login__logo">
        <SVG type={SVG_TYPE.LOGO} />
      </div>
      <Formik validationSchema={validation()} initialValues={{ email: '', password: '' }} onSubmit={handleOnSubmit}>
        {({ handleSubmit, errors, setFieldTouched }) => (
          <form className="login__form" onSubmit={handleSubmit}>
            {useEffect(() => {
              setFieldTouched('email', isError);
            }, [isError])}
            <div className="login__item">
              <h1 className="login--header">{formatMessage({ id: 'global.login' })}</h1>
              <Field
                inputOnChange={() => setIsError(false)}
                type="text"
                label={formatMessage({ id: 'inputs.email' })}
                name="email"
                component={RegularField}
                errors={errors}
              />
              <Field
                inputOnChange={() => setIsError(false)}
                type="password"
                label={formatMessage({ id: 'inputs.password' })}
                name="password"
                component={RegularField}
                errors={errors}
              />
              <Link to={ROUTES.REMIND_PASSWORD}>
                <p className="login__paragraph">{formatMessage({ id: 'global.dont_remember_password' })}</p>
              </Link>

              <Button className="btn--orange" label={formatMessage({ id: 'inputs.login' })} type="submit" />
              <p className="login__paragraph login__paragraph--bottom">
                {formatMessage({ id: 'global.dont_have_account' })}{' '}
                <Link to={ROUTES.REGISTER}>{formatMessage({ id: 'inputs.create_account' })}</Link>
              </p>
            </div>
          </form>
        )}
      </Formik>
    </main>
  );
};

export default Login;

Login.propTypes = {
  actions: shape({
    activateAccount: func.isRequired,
    registerUser: func.isRequired,
  }).isRequired,
  formatMessage: func.isRequired,
};

import React, { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, SelectField, RegularField, SVG } from 'shared/components';
import { SVG_TYPE, VALIDATION_FIELD, ROUTES } from 'shared/consts';
import { ProductsFilters } from 'screens/products-lists/components';
import PropTypes, { array, func, object } from 'prop-types';
import {
  carBrandsOptions,
  carModelsOptions,
  deliveryCountryOptions,
  querySearching,
  encodeBrand,
  encodeModel,
} from 'shared/utils';
import { useHistory } from 'react-router-dom';

const SearchByVehicle = ({
  authorizedUser,
  isMobile,
  getCarBrands,
  getCarModels,
  modal,
  deliveryCountries,
  carBrands,
  carModels,
  formatMessage,
  setIsModalOpened,
  categoryByLink,
  cleaningAllProducts,
  setIsFiltersOpened
}) => {
  const [isModelOn, setIsModelOn] = useState(false);
  const { search } = window.location;
  const userDeliveryCountry = (authorizedUser && authorizedUser?.country.label) || localStorage.getItem('deliveryCountry');
  const searchQuery = JSON.parse(localStorage.getItem('searchEngine')) || '';
  const history = useHistory();

  const getMainCategoryNameFromSearching = () => {
    const urlParams = new URLSearchParams(search);
    const mainCat = urlParams.get('main_cat');
    return mainCat || '';
  };

  const categoryName = window.location.pathname.split('/')[2];
  const categoryUuid = categoryByLink && categoryByLink?.category?.uuid;
  const categoryLink = categoryByLink && categoryByLink?.category?.link;
  const category_uuid = (getMainCategoryNameFromSearching() && categoryUuid) || (categoryName && categoryUuid);
  const category_name = (getMainCategoryNameFromSearching() && categoryLink) || (categoryName && categoryLink);

  const getModelName = model_uuid => {
    const model = carModels.filter(item => item.label === model_uuid);
    return model[0].value.split(' ').join('_');
  };

  const handleOnSubmit = async values => {
    setIsFiltersOpened && setIsFiltersOpened(false)
    const { country_uuid, year_of_production_start, year_of_production_end, brand_uuid, model_uuid } = values;
    !userDeliveryCountry && localStorage.setItem('deliveryCountry', country_uuid);
    modal && localStorage.setItem('searchEngine', JSON.stringify(values));
    const carBrandName = carBrands.filter(item => item.label === brand_uuid);
    localStorage.setItem('runSearchEngine', true);
    history.push(
      `${ROUTES.CATEGORY}/?s${values.search ? `=${values.search.replace(/\s/g, '-')}` : ''}${
        category_uuid ? `&main_cat=${category_name}` : ''
      }${year_of_production_start ? `&ys=${year_of_production_start}` : ''}${
        year_of_production_end ? `&ye=${year_of_production_end}` : ''
      }${
        brand_uuid
          ? `&brand=${carBrandName[0].value.replace(/\s/g, '-')}${model_uuid ? `&model=${getModelName(model_uuid)}` : ''}`
          : ``
      }`,
    );
    setIsModalOpened(false);
  };

  useEffect(
    () => () => {
      searchQuery && localStorage.removeItem('searchEngine');
    },
    [],
  );
  useEffect(() => {
    carBrands && encodeBrand(querySearching().brand, carBrands);
  }, [querySearching, carBrands]);

  useEffect(async () => {
    if (querySearching().brand) {
      setIsModelOn(true);
      await getCarModels(querySearching().brand);
    }
  }, []);

  useEffect(async () => {
    await getCarBrands();
  }, []);

  const getModelsByBrand = (e, setFieldValue) => {
    if (e) {
      setFieldValue('model_uuid', '');
      getCarModels(e.label);
      setIsModelOn(true);
    } else {
      setIsModelOn(false);
      setFieldValue('model_uuid', '');
    }
  };

  const validation = () =>
    Yup.object().shape({
      country_uuid: Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED).min(2, VALIDATION_FIELD(formatMessage).MIN_2),
      search: Yup.string().min(2, VALIDATION_FIELD(formatMessage).MIN_2),
      year_of_production_end: Yup.string()
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .min(4, VALIDATION_FIELD(formatMessage).DATA)
        .max(4, VALIDATION_FIELD(formatMessage).DATA)
        .matches(/^(19|20)\d{2}$/, VALIDATION_FIELD(formatMessage).DATA),
      year_of_production_start: Yup.string()
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .min(4, VALIDATION_FIELD(formatMessage).DATA)
        .max(4, VALIDATION_FIELD(formatMessage).DATA)
        .matches(/^(19|20)\d{2}$/, VALIDATION_FIELD(formatMessage).DATA),
    });

  const resetFormik = setFieldValue => {
    setFieldValue('search', '');
    setFieldValue('brand_uuid', '');
    setFieldValue('model_uuid', '');
    setFieldValue('year_of_production_start', '');
    setFieldValue('year_of_production_end', '');
    Object.values(querySearching()).length>1&&querySearching().s!=='' && cleaningAllProducts()
    setIsModelOn(false);
    localStorage.setItem('showAll', true);
    history.push(
      `${ROUTES.CATEGORY}/?s=${getMainCategoryNameFromSearching() && `&main_cat=${getMainCategoryNameFromSearching()}`}`,
      );
  };

  const subCategories = () => {
    const isSubCategories = search.slice(0, 4) === '?cat';
    const category = categoryByLink?.subcategories;
    if (isSubCategories && category) {
      const query = [];
      const pairs = (search[0] === '?' ? search.substr(5) : search).split(',');
      for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split(',');
        const subcategory = category.filter(item => item.link === pair[0]);
        const subcategory_uuid = subcategory && subcategory.length > 0 && subcategory[0].uuid;
        query.push(`&subcategory_uuid=${subcategory_uuid}`);
      }
      return query;
    }
    return false;
  };

  return (
    <div className={`search-by-vehicle ${modal ? 'search-by-vehicle--modal' : ''}`}>
      {!modal ? (
        <div className="search-by-vehicle--heading">
          <h1 className="search-by-vehicle--name">{formatMessage({ id: 'global.search_by_vehicle' })}</h1>
        </div>
      ) : (
        <div className="search-by-vehicle--modal--left">
          <div className="search-by-vehicle--modal--heading">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fringilla.
          </div>
          <div className="search-by-vehicle--modal--descript">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </div>
        </div>
      )}

      <div className="search-by-vehicle__form">
        <Formik
          validationSchema={validation()}
          initialValues={{
            country_uuid: userDeliveryCountry || searchQuery.country_uuid || '',
            search: (querySearching().s && querySearching().s.replace(/-/g, ' ')) || '',
            brand_uuid: (carBrands && encodeBrand(querySearching().brand, carBrands)) || '',
            model_uuid: encodeModel(querySearching().model, carModels) || '',
            year_of_production_start: querySearching().ys || '',
            year_of_production_end: querySearching().ye || '',
            category_uuid: categoryByLink?.category?.uuid || '',
            subcategory_uuid: subCategories() || '',
          }}
          enableReinitialize
          onSubmit={handleOnSubmit}
        >
          {({ handleSubmit, errors, setFieldValue }) => (
            <>
              <form onSubmit={handleSubmit}>
                <div className="search-parts__form--wrapper search-parts--top">
                  <div className="regular-field__icon--wrapper search-parts__country">
                    <Field
                      className={`add-advertisement__select ${userDeliveryCountry ? 'country-select--disabled' : ''}`}
                      component={SelectField}
                      placeholder={formatMessage({ id: 'inputs.choose' })}
                      label={formatMessage({ id: 'global.delivery_country' })}
                      errors={errors}
                      name="country_uuid"
                      options={deliveryCountryOptions(deliveryCountries, formatMessage)}
                      isCustomLabel
                    />
                  </div>
                  <div className="regular-field__icon--wrapper">
                    <Field className="regular-field__padding--left" component={RegularField} errors={errors} name="search" />
                    <SVG className="regular-field__icon--left svg--loupe--orange" type={SVG_TYPE.LOUPE} />
                  </div>
                </div>
                <div className="search-parts__form--wrapper search-parts--mid">
                  <Field
                    component={SelectField}
                    placeholder={formatMessage({ id: 'inputs.choose' })}
                    label="MARKA"
                    errors={errors}
                    options={carBrandsOptions(carBrands)}
                    onChange={e => getModelsByBrand(e, setFieldValue)}
                    name="brand_uuid"
                    isCustomLabel
                  />
                  <Field
                    className={`${!isModelOn ? 'select--disabled' : ''}`}
                    component={SelectField}
                    placeholder={formatMessage({ id: 'inputs.choose' })}
                    label={formatMessage({ id: 'inputs.model' })}
                    options={carModelsOptions(carModels)}
                    errors={errors}
                    name="model_uuid"
                  />
                </div>
                <div className="search-parts__form--wrapper search-parts--bot">
                  <div className="search-parts__form--wrapper search-parts__form--half">
                    <Field
                      component={RegularField}
                      label={formatMessage({ id: 'global.year_production' })}
                      errors={errors}
                      name="year_of_production_start"
                    />
                    <Field component={RegularField} errors={errors} name="year_of_production_end" />
                  </div>
                  {isMobile && <ProductsFilters formatMessage={formatMessage} />}
                  <div className={`${!isMobile ? 'search-parts--buttons' : ''} search-by-vehicle--buttons`}>
                    <Button
                      className="btn--orange-border"
                      onClick={() => resetFormik(setFieldValue)}
                      label={formatMessage({ id: 'inputs.clear_filters' })}
                    />
                    <Button className="btn--orange" label={formatMessage({ id: 'inputs.search' })} type="submit" />
                  </div>
                </div>
              </form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SearchByVehicle;

SearchByVehicle.defaultProps = {
  authorizedUser: null,
  isMobile: false,
  modal: false,
  setIsModalOpened: null,
};
SearchByVehicle.propTypes = {
  authorizedUser: object,
  carBrands: array.isRequired,
  carModels: array.isRequired,
  categories: array.isRequired,
  categoryByLink: object.isRequired,
  cleaningAllProducts: func.isRequired,
  deliveryCountries: array.isRequired,
  formatMessage: func.isRequired,
  getCarBrands: func.isRequired,
  getCarModels: func.isRequired,
  isMobile: PropTypes.bool,
  modal: PropTypes.bool,
  setIsFiltersOpened: func.isRequired,
  setIsModalOpened: func.bool,
};

import { PARTNER } from 'store/types';
import { REQUEST_STATUS } from 'shared/consts';

const INITIAL_STATE = {
  partnerRequests: {
    data: [],
  },
};

const partnerReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case PARTNER.GET_PARTNER_REQUESTS_INIT: {
      return {
        ...state,
        partnerRequests: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case PARTNER.GET_PARTNER_REQUESTS_SUCCESS: {
      return {
        ...state,
        partnerRequests: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PARTNER.GET_PARTNER_REQUESTS_FAILURE: {
      return {
        ...state,
        partnerRequests: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    default:
      return state;
  }
};

export default partnerReducer;

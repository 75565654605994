import React, { useState } from 'react';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { RegularField, SVG, Button } from 'shared/components';
import { SVG_TYPE, VALIDATION_FIELD, ROUTES } from 'shared/consts';
import { useHistory } from 'react-router-dom';
import { shape, func } from 'prop-types';

const NewPassword = ({ actions: { setNewPassword }, intl: { formatMessage } }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const history = useHistory();
  const handleOnSubmit = async values => {
    const resetToken = sessionStorage.getItem('resetToken');
    const data = { ...values, reset_token: resetToken.replace(/['"]+/g, '') };
    const response = await setNewPassword(data);
    console.log(response);
    setIsSuccess(true);
  };

  const validation = () =>
    Yup.object().shape({
      confirm_password: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .oneOf([Yup.ref('new_password'), null], VALIDATION_FIELD(formatMessage).PASSWORD_THIS_SAME)
        .min(8, VALIDATION_FIELD(formatMessage).MIN_8_PASSWORD),
      new_password: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .min(8, VALIDATION_FIELD(formatMessage).MIN_8_PASSWORD),
    });

  return (
    <main className="login__wrapper">
      <div className="login__logo">
        <SVG type={SVG_TYPE.LOGO} />
      </div>
      {!isSuccess ? (
        <Formik
          validationSchema={validation()}
          initialValues={{ new_password: '', confirm_password: '' }}
          onSubmit={handleOnSubmit}
        >
          {({ handleSubmit, errors }) => (
            <form className="login__form" onSubmit={handleSubmit}>
              <div className="login__item">
                <h1 className="login--header login--header--small">{formatMessage({ id: 'global.create_new_password' })}</h1>
                <Field
                  type="password"
                  noEye
                  label={formatMessage({ id: 'inputs.new_password' })}
                  name="new_password"
                  component={RegularField}
                  errors={errors}
                />
                <Field
                  type="password"
                  noEye
                  label={formatMessage({ id: 'inputs.repeat_new_password' })}
                  name="confirm_password"
                  component={RegularField}
                  errors={errors}
                />
                <Button className="btn--orange" label={formatMessage({ id: 'inputs.save_new_password' })} type="submit" />
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <div className="login__form">
          <div className="login__item">
            <h1 className="login--header login--header--small">{formatMessage({ id: 'global.password_well_change' })}</h1>
            <p className="login__paragraph login__paragraph--black">{formatMessage({ id: 'global.can_go_to_login' })}</p>
            <Button
              className="btn--orange"
              label={formatMessage({ id: 'inputs.login' })}
              type="submit"
              onClick={() => history.push(ROUTES.LOGIN)}
            />
          </div>
        </div>
      )}
    </main>
  );
};

export default NewPassword;

NewPassword.propTypes = {
  actions: shape({
    setNewPassword: func.isRequired,
  }).isRequired,
  formatMessage: func.isRequired,
};

import { func, object, shape } from 'prop-types';
import React, { useEffect, useState } from 'react';
import Content from './components/content';
import Menu from './components/menu';

const HelpChat = ({
  intl: { formatMessage },
  actions: { getRooms, getRoomMessages, sendMessage, updateRoomStatus },
  rooms,
  authorizedUser,
  roomMessages,
}) => {
  const [chatArchived, setChatArchived] = useState(false);
  const [archivedFilter, setArchivedFilter] = useState(0);
  const [countryFilter, setCountryFilter] = useState('all');
  roomMessages && roomMessages.sort((a,b)=> a.id - b.id)
  useEffect(async () => {
    await getRooms();
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      getRooms();
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="help-chat__wrapper">
      <div className="help-chat__top">
        <h1 className="help-chat__heading">Chat</h1>
        {authorizedUser.role === 'admin' && (
          <Menu countryFilter={countryFilter} setCountryFilter={setCountryFilter} formatMessage={formatMessage} />
        )}
        <div className="help-chat__top__menu">
          {(authorizedUser.role === 'admin' || authorizedUser.role === 'coordinator') && (
            <div className="help-chat__top__menu--left">
              <button type="button" onClick={() => setArchivedFilter(0)} className={`${archivedFilter ? '' : 'active'}`}>
                {formatMessage({ id: 'global.active' })}
              </button>
              <button type="button" onClick={() => setArchivedFilter(1)} className={`${archivedFilter ? 'active' : ''}`}>
                {formatMessage({ id: 'global.archived' })}
              </button>
            </div>
          )}
        </div>
      </div>
      <Content
        updateRoomStatus={updateRoomStatus}
        archivedFilter={archivedFilter}
        countryFilter={countryFilter}
        roomMessages={roomMessages}
        getRoomMessages={getRoomMessages}
        rooms={rooms}
        formatMessage={formatMessage}
        setChatArchived={setChatArchived}
        chatArchived={chatArchived}
        sendMessage={sendMessage}
        authorizedUser={authorizedUser}
      />
    </div>
  );
};

export default HelpChat;

HelpChat.propTypes = {
  actions: shape({
    getRooms: func.isRequired,
  }).isRequired,
  authorizedUser: object.isRequired,
  roomMessages: object.isRequired,
  rooms: object.isRequired,
};

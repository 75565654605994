import { VIEW_MANAGEMENT } from 'store/types';

const success = translation => ({
  type: VIEW_MANAGEMENT.SET_APP_TRANSLATION_SUCCESS,
  payload: {
    translation,
  },
});

const setAppTranslation = lang => async dispatch => {
  try {
    const script = document.createElement('script');
    script.src = `${process.env.REACT_APP_BASE_URL}translations/${lang}.js`;
    script.onload = () => {
      localStorage.setItem('translation', lang);
      dispatch(success(lang));
    };
    document.head.appendChild(script);
  } catch (err) {
    console.log(err.response);
  }
};

export default setAppTranslation;

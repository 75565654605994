import React, { useState } from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { CategorySettings, EditCatModal, DeleteCatModal } from 'screens/category-management/components';
import { bool, object, func, string } from 'prop-types';

const MainCategory = ({
  isMobile,
  translation,
  isExpand,
  setIsExpand,
  data,
  deleteCategory,
  editCategory,
  formatMessage,
  deleteFiles,
}) => {
  const [isSettingsOpened, setIsSettingsOpened] = useState(false);
  const [whichModalIsOpened, setWhichModalIsOpened] = useState({ name: '', uuid: '' });
  const editModalOpened = whichModalIsOpened.name === 'edit';
  const deleteModalOpened = whichModalIsOpened.name === 'delete';
  const isDeleteDisabled = data.products_count === 0;
  const triggerSettings = () => {
    setIsSettingsOpened(!isSettingsOpened);
  };

  const handleDelete = () => {
    deleteCategory(whichModalIsOpened.uuid);
    setWhichModalIsOpened({ name: '', uuid: '' });
  };
  const handleEdit = async values => {
    const response = await editCategory(whichModalIsOpened.uuid, values);
    const isLinkTaken = response.data?.message === 'This link is actually taken';
    const wrongPhotoFile = response === 'cannot add this photo';
    !isLinkTaken && !wrongPhotoFile && setWhichModalIsOpened({ name: '', uuid: '' });
    return response;
  };

  return (
    <div className="categoryManagement__singleCategory__left">
      <div
        className={`categoryManagement__singleCategory--category ${
          isMobile && isExpand ? 'categoryManagement__singleCategory--category--active' : ''
        }`}
      >
        <div className="categoryManagement__singleCategory--category--label" onClick={() => isMobile && setIsExpand(!isExpand)}>
          {isMobile && (
            <SVG
              className={`categoryManagement__singleCategory--category--icon ${
                isExpand ? 'categoryManagement__singleCategory--category--icon--rotate' : ''
              }`}
              type={SVG_TYPE.CHEVRONE}
            />
          )}
          {translation === 'pl' ? data.name : data.name_ger}
        </div>
        <div
          className={`categoryManagement__singleCategory--dots categoryManagement__singleCategory--dots--${
            isSettingsOpened ? 'none' : ''
          }`}
          onClick={triggerSettings}
        >
          <SVG type={SVG_TYPE.DOTS} />
        </div>
      </div>
      <CategorySettings
        formatMessage={formatMessage}
        isOpened={isSettingsOpened}
        setIsSettingsOpened={setIsSettingsOpened}
        setWhichModalIsOpened={setWhichModalIsOpened}
        uuid={data.uuid}
        isDeletedDisabled={!isDeleteDisabled}
      />
      <EditCatModal
        deleteFiles={deleteFiles}
        formatMessage={formatMessage}
        edit={handleEdit}
        data={data}
        title={formatMessage({ id: 'global.edit_category' })}
        isOpened={editModalOpened}
        close={setWhichModalIsOpened}
      />
      <DeleteCatModal
        formatMessage={formatMessage}
        title={formatMessage({ id: 'global.delete_category' })}
        isOpened={deleteModalOpened}
        close={setWhichModalIsOpened}
        deleteElement={handleDelete}
        setWhichModalIsOpened={setWhichModalIsOpened}
      />
    </div>
  );
};

export default MainCategory;

MainCategory.propTypes = {
  data: object.isRequired,
  deleteCategory: func.isRequired,
  deleteFiles: func.isRequired,
  editCategory: func.isRequired,
  formatMessage: func.isRequired,
  isExpand: bool.isRequired,
  isMobile: bool.isRequired,
  setIsExpand: bool.isRequired,
  translation: string.isRequired,
};

import React, { useState } from 'react';
import { object, func, string, array } from 'prop-types';
import { Icons, Content } from './components';

const MenuMobile = ({
  authorizedUser,
  mainCategory,
  logout,
  setAppTranslation,
  translation,
  formatMessage,
  shoppingCart,
  createCheckoutSession,
  cancelCheckout,
  country,
}) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isMenuSlide, setIsMenuSlide] = useState(false);

  const openMenu = () => {
    setIsMenuOpened(true);
    setTimeout(() => {
      setIsMenuSlide(true);
    }, 10);
  };
  const closeMenu = () => {
    setIsMenuSlide(false);
    setTimeout(() => {
      setIsMenuOpened(false);
    }, 100);
  };

  return (
    <>
      <Icons
        createCheckoutSession={createCheckoutSession}
        cancelCheckout={cancelCheckout}
        authorizedUser={authorizedUser}
        formatMessage={formatMessage}
        openMenu={openMenu}
        shoppingCart={shoppingCart}
      />
      <Content
        logout={logout}
        formatMessage={formatMessage}
        translation={translation}
        setAppTranslation={setAppTranslation}
        mainCategory={mainCategory}
        authorizedUser={authorizedUser}
        closeMenu={closeMenu}
        isMenuOpened={isMenuOpened}
        setIsMenuOpened={setIsMenuOpened}
        isMenuSlide={isMenuSlide}
        country={country}
      />
    </>
  );
};

MenuMobile.propTypes = {
  authorizedUser: object.isRequired,
  cancelCheckout: func.isRequired,
  country: string.isRequired,
  createCheckoutSession: func.isRequired,
  formatMessage: object.isRequired,
  logout: func.isRequired,
  mainCategory: array.isRequired,
  setAppTranslation: func.isRequired,
  shoppingCart: object.isRequired,
  translation: string.isRequired,
};

export default MenuMobile;

import { connect } from 'react-redux';
import { resetPassword } from 'store/actions/user';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import SendActiveMail from './SendActiveMail';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      resetPassword: email => resetPassword(email),
    },
    dispatch,
  ),
});

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SendActiveMail));

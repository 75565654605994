import React from 'react';
import PropTypes from 'prop-types';

const ProductBadge = ({ type, label, descript }) => (
  <div className={`product-badge product-badge--${type}`}>
    <p>{label}</p>
    <span>{descript}</span>
  </div>
);

export default ProductBadge;

ProductBadge.propTypes = {
  descript: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

const UTILS = {
  ADD_FILES_INIT: 'ADD_FILES_INIT',
  ADD_FILES_SUCCESS: 'ADD_FILES_SUCCESS',
  ADD_FILES_FAILURE: 'ADD_FILES_FAILURE',
  DELETE_FILES_INIT: 'DELETE_FILES_INIT',
  DELETE_FILES_SUCCESS: 'DELETE_FILES_SUCCESS',
  DELETE_FILES_FAILURE: 'DELETE_FILES_FAILURE',
};

export default UTILS;

import React from 'react';
import PropTypes from 'prop-types';
import { USER_ROLES } from 'shared/consts';


const DeliveryMenu = ({ menuType, setMenuType, deliveryCountry,deliveryCountries }) => (
  <>
    <div className="delivery-options__menu">
      <>
        {deliveryCountries.map(({ label, value }) => {
          const isAvailable = value === deliveryCountry.country.value;
          const isAdmin = deliveryCountry.role === USER_ROLES.ADMIN;
          const shortLabel = label === 'Poland' ? 'PL': 'DE'
          return (
            <>
              {isAvailable && !isAdmin && (
                <div
                  className={`delivery-options__menu--item ${menuType === value ? 'delivery-options__menu--active' : ''}`}
                  onClick={() => setMenuType(value)}
                >
                  <div className="label">{shortLabel}</div>
                </div>
              )}
              {isAdmin && (
                <div
                  className={`delivery-options__menu--item ${menuType === value ? 'delivery-options__menu--active' : ''}`}
                  onClick={() => setMenuType(value)}
                >
                  <div className="label">{shortLabel}</div>
                </div>
              )}
            </>
          );
        })}
      </>
    </div>
  </>
);

export default DeliveryMenu;

DeliveryMenu.propTypes = {
  deliveryCountries: PropTypes.object.isRequired,
  deliveryCountry: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  menuType: PropTypes.bool.isRequired,
  setMenuType: PropTypes.func.isRequired,
};

import React from 'react';
import { Modal, ModalActions, Title } from 'shared/components';
import { MODAL_TYPE } from 'shared/consts';
import PropTypes from 'prop-types';

const DeleteModal = ({ opened, onClose, formatMessage, onSubmit, data }) => {
  const handleSubmit = () => {
    onSubmit(data?.data?.uuid);
    onClose();
  };
  return (
    <>
      <Modal opened={opened} onClose={onClose} className="delete">
        <div>
          <Title title="Usuń" className="modals" />
          <p>Czy jesteś pewny, że chcesz usunąć ten produkt?</p>
        </div>
        <ModalActions type={MODAL_TYPE.DELETE_MODAL} formatMessage={formatMessage} onSubmit={handleSubmit} />
      </Modal>
    </>
  );
};

export default DeleteModal;

DeleteModal.propTypes = {
  data: PropTypes.object.isRequired,
  formatMessage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
};

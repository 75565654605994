import { Field, Formik } from 'formik';
import { bool, func, object } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { CheckBoxField, Modal, ModalActions, RegularField, SelectField } from 'shared/components';
import { LANGUAGE_TYPE, MODAL_TYPE, PHONE_PREFIX, USER_ROLES, VALIDATION_FIELD } from 'shared/consts';
import { isValidNip } from 'shared/utils';
import * as Yup from 'yup';

const EditPartner = ({ openModal, deliveryCountries, formatMessage, onClose, editUser, userDetails, getUserDetails }) => {
  const [isEmailOccupied, setIsEmailOccupied] = useState(false);
  const { uuid, country_of_delivery_label } = openModal.data;
  const formRef = useRef(null);
  const [selectedUserCounrtyUuid, setSelectedUserCounrtyUuid] = useState(country_of_delivery_label?.label || '');
  const getCountryUuid = country => deliveryCountries.length && deliveryCountries.find(item => item.label === country);
  const germanyUuid = getCountryUuid('Germany')?.value;
  useEffect(() => {
    country_of_delivery_label && setSelectedUserCounrtyUuid(country_of_delivery_label?.label);
  }, [openModal.data]);

  useEffect(async () => {
    openModal.isOpened && (await getUserDetails(uuid));
  }, [uuid]);

  const onSubmit = async values => {
    const response = await editUser(openModal.data.uuid, values, 'partner');
    response === 422 ? setIsEmailOccupied(true) : (setIsEmailOccupied(false), onClose());
  };

  const validation = () =>
    Yup.object().shape({
      city: Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED),
      company_name: Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED),
      country_of_delivery_uuid: Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED),
      email: Yup.string()
        .email(VALIDATION_FIELD(formatMessage).EMAIL)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .test('email-occupied', VALIDATION_FIELD(formatMessage).EMAIL_OCCUPIED, () => !isEmailOccupied),
      firstName: Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED),
      house_number: Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED),
      language: Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED),
      lastName: Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED),
      nip: Yup.string()
        .nullable()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .test({ message: VALIDATION_FIELD(formatMessage).WRONG_NIP, test: value => isValidNip(value) }),
      phone_number: Yup.string()
        .min(9, VALIDATION_FIELD(formatMessage).REQUIRED_9)
        .max(9, VALIDATION_FIELD(formatMessage).REQUIRED_9)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED),
      phone_prefix: Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED),
      post_code: Yup.string(VALIDATION_FIELD(formatMessage).REQUIRED)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .test(
          'post-code',
          selectedUserCounrtyUuid === germanyUuid
            ? VALIDATION_FIELD(formatMessage).POST_CODE_DE
            : VALIDATION_FIELD(formatMessage).POST_CODE,
          val => (val && selectedUserCounrtyUuid === germanyUuid ? !!/^[0-9]{5}$/i.test(val) : /^[0-9]{2}-[0-9]{3}$/i.test(val)),
        )
        .test('germany-postcode', VALIDATION_FIELD(formatMessage).BAD_VALUE, val =>
          val && selectedUserCounrtyUuid === germanyUuid
            ? !!/\b((?:0[1-46-9]\d{3})|(?:[1-357-9]\d{4})|(?:[4][0-24-9]\d{3})|(?:[6][013-9]\d{3}))\b/.test(val)
            : true,
        ),
      role: Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED),
      street: Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED),
    });

  const userRole = [
    {
      label: formatMessage({ id: 'global.partner' }),
      searchableLabel: formatMessage({ id: 'global.partner' }),
      value: USER_ROLES.PARTNER,
    },
    {
      label: formatMessage({ id: 'global.client' }),
      searchableLabel: formatMessage({ id: 'global.client' }),
      value: USER_ROLES.CLIENT,
    },
  ];

  return (
    <div>
      {openModal.isOpened && (
        <Modal
          className={MODAL_TYPE.BASIC}
          onClose={() => {
            onClose();
            setIsEmailOccupied(false);
          }}
          opened={openModal.isOpened}
        >
          <div>
            <div className="modal__heading modal__heading--center">{formatMessage({ id: 'global.edit_partner' })}</div>
            {userDetails.id && (
              <Formik
                innerRef={formRef}
                validationSchema={validation()}
                initialValues={{
                  firstName: userDetails.first_name || '',
                  lastName: userDetails.last_name || '',
                  email: userDetails.email || '',
                  company_name: userDetails.partner?.company_name || '',
                  phone_number: userDetails.partner?.phone_number || '',
                  phone_prefix: userDetails.partner?.phone_prefix || '',
                  street: userDetails.partner?.street || '',
                  house_number: userDetails.partner?.house_number || '',
                  post_code: userDetails.partner?.post_code || '',
                  city: userDetails.partner?.city || '',
                  nip: userDetails.partner?.nip || '',
                  language: userDetails?.language.toLowerCase() || '',
                  role: userDetails?.role || '',
                  country_of_delivery_uuid: userDetails?.country_of_delivery_label?.label || '',
                  is_ebay: userDetails?.is_ebay || 0,
                }}
                onSubmit={onSubmit}
              >
                {({ handleSubmit, errors, setFieldTouched }) => (
                  <form className="categoryManagement__addCategory__wrapper" onSubmit={handleSubmit}>
                    {useEffect(() => {
                      setFieldTouched('email', isEmailOccupied);
                    }, [isEmailOccupied])}
                    <div className="categoryManagement__addCategory__content edit--partner">
                      <Field
                        type="text"
                        label={formatMessage({ id: 'global.name' })}
                        name="firstName"
                        component={RegularField}
                        errors={errors}
                      />
                      <Field
                        type="text"
                        label={formatMessage({ id: 'global.last_name' })}
                        name="lastName"
                        component={RegularField}
                        errors={errors}
                      />
                      <Field
                        type="text"
                        label={formatMessage({ id: 'global.email' })}
                        inputOnChange={() => setIsEmailOccupied(false)}
                        name="email"
                        component={RegularField}
                        errors={errors}
                      />
                      <div className="regular-field__input">
                        <Field
                          type="text"
                          label={formatMessage({ id: 'global.language' })}
                          name="language"
                          component={SelectField}
                          errors={errors}
                          options={LANGUAGE_TYPE}
                        />
                      </div>
                      <div className="regular-field__input">
                        <Field
                          component={SelectField}
                          placeholder={formatMessage({ id: 'inputs.choose' })}
                          label={formatMessage({ id: 'global.role' })}
                          errors={errors}
                          name="role"
                          options={userRole}
                          isCustomLabel
                        />
                      </div>
                      <Field
                        type="text"
                        label={formatMessage({ id: 'inputs.company_name' })}
                        name="company_name"
                        component={RegularField}
                        errors={errors}
                      />
                      <Field
                        type="text"
                        label={formatMessage({ id: 'global.nip' })}
                        name="nip"
                        component={RegularField}
                        errors={errors}
                      />
                      <div className="regular-field__phone-wrap">
                        <Field
                          type="text"
                          label={formatMessage({ id: 'global.directional' })}
                          name="phone_prefix"
                          component={SelectField}
                          errors={errors}
                          options={PHONE_PREFIX}
                        />
                        <Field
                          type="text"
                          label={formatMessage({ id: 'global.telephone' })}
                          name="phone_number"
                          component={RegularField}
                          errors={errors}
                        />
                      </div>
                      <Field
                        type="text"
                        label={formatMessage({ id: 'inputs.street' })}
                        name="street"
                        component={RegularField}
                        errors={errors}
                      />
                      <Field
                        type="text"
                        label={formatMessage({ id: 'inputs.house_number' })}
                        name="house_number"
                        component={RegularField}
                        errors={errors}
                      />
                      <Field
                        type="text"
                        label={formatMessage({ id: 'inputs.post_code' })}
                        name="post_code"
                        component={RegularField}
                        errors={errors}
                      />
                      <Field
                        type="text"
                        label={formatMessage({ id: 'inputs.city' })}
                        name="city"
                        component={RegularField}
                        errors={errors}
                      />
                      <Field
                        type="checkbox"
                        label={formatMessage({ id: 'inputs.ebay_integration' })}
                        name="is_ebay"
                        component={CheckBoxField}
                      />
                      {/* {!subcategory && <Button label="Dodaj zdjęcie +" className="btn--gray categoryManagement__header--button" />} */}
                      <ModalActions
                        formatMessage={formatMessage}
                        type={MODAL_TYPE.EDIT_PARTNER}
                        onClose={onClose}
                        onSubmit={handleSubmit}
                      />
                    </div>
                  </form>
                )}
              </Formik>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};
export default EditPartner;

EditPartner.propTypes = {
  deliveryCountries: func.isRequired,
  editUser: func.isRequired,
  formatMessage: func.isRequired,
  getUserDetails: func.isRequired,
  onClose: func.isRequired,
  openModal: bool.isRequired,
  userDetails: object.isRequired,
};

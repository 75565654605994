import { Route, Switch } from 'react-router-dom';
import * as React from 'react';
import { object, func, bool, shape, string } from 'prop-types';
import { ROUTES } from 'shared/consts';
import { NavigationHeader, MainFooter, CookiesAgreement, AuthRoute, RoleRoute, Chat } from 'shared/components';
import HomePage from 'screens/home-page/HomePage.container';
import Login from 'screens/login/Login.container';
import Register from 'screens/register/Register.container';
import SendActivateMail from 'screens/send-activate-mail/SendActiveMail.container';
import RemindPassword from 'screens/remind-password/RemindPassword.container';
import ResetPassword from 'screens/reset-password/ResetPassword.container';
import NewPassword from 'screens/new-password/NewPassword.container';
import SingleProduct from 'screens/single-product/SingleProduct.container';
import ProductsLists from 'screens/products-lists/ProductsLists.container';
import Invoices from 'screens/invoices/Invoices.container';
import WatchedProducts from 'screens/watched-products/WatchedProducts.container';
import Advertisements from 'screens/advertisements/Advertisements.container';
import Orders from 'screens/orders/Orders.container';
import AddAdvertisement from 'screens/add-advertisement/AddAdvertisement.container';
import EditAdvertisemenets from 'screens/edit-advertisements/EditAdvertisements.container';
import DeliveryOptions from 'screens/delivery-options/DeliveryOptions.container';
import PartnerProgram from 'screens/partner-program/PartnerProgram.container';
import CategoryManagement from 'screens/category-management/CategoryManagement.container';
import PriceManagement from 'screens/price-management/PriceManagement.container';
import AboutUs from 'screens/about-us';
import UserManagement from 'screens/user-management/UserManagement.container';
import ContactPage from 'screens/contact-page/ContactPage.container';
import InactiveAccount from 'screens/inactive-account/InactiveAccount.container';
import SetPassword from 'screens/set-password/SetPassword.container';
import Basket from 'screens/basket/Basket.container';
import Checkout from 'screens/checkout/Checkout.container';
import Summary from 'screens/summary/Summary.container';
import Dictionary from 'screens/dictionary/Dictionary.container';
import PaymentPayLater from 'screens/payment-pay-later/PaymentPayLater.container';
import PaymentAccepted from 'screens/payment-accepted/PaymentAccepted.container';
import PaymentCanceled from 'screens/payment-canceled/PaymentCanceled.container';
import Page404 from 'screens/page-404/';
import TokenExpired from 'screens/token-expired/TokenExpired.container';
import UserByAdminTokenExpired from 'screens/user-by-admin-token-expired/UserByAdminTokenExpired.container';
import Magazine from 'screens/magazine/Magazine.container';
import HelpChat from 'screens/help-chat/Help-chat.container';
import { ToastContainer } from 'react-toastify';
import { IntlProvider } from 'react-intl';
import { flattenMessages, browserLanguage } from 'shared/utils';
import Settings from 'screens/settings/Settings.container';
import AllegroConnection from 'screens/allegro-connection/AllegroConnection.container';
import ProductPreview from 'screens/product-preview/ProductPreview.container';

const {
  LOGIN,
  HOME_PAGE,
  REGISTER,
  RESET_PASSWORD,
  REMIND_PASSWORD,
  NEW_PASSWORD,
  SINGLE_PRODUCT,
  INVOICES,
  WATCHED_PRODUCTS,
  SEND_ACTIVATE_MAIL,
  ADVERTISEMENTS,
  ADD_ADVERTISEMENT,
  EDIT_ADVERTISEMENTS,
  PARTNER_PROGRAM,
  CATEGORY_MANAGEMENT,
  TOKEN_EXPIRED,
  ORDERS,
  CATEGORY,
  INACTIVE_ACCOUNT,
  USER_MANAGEMENT,
  DELIVERY_OPTIONS,
  SET_PASSWORD,
  USER_BY_ADMIN_TOKEN_EXPIRED,
  PRICE_MANAGEMENT,
  CONTACT,
  ABOUT_US,
  MAGAZINE,
  BASKET,
  DICTIONARY,
  CHAT,
  ALLEGRO_CONNECTION,
  SETTINGS,
  CHECKOUT,
  SUMMARY,
  PAY_LATER,
  PAYMENT_ACCEPTED,
  PAYMENT_CANCELED,
  PRODUCT_PREVIEW,
} = ROUTES;

class MainRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCookiesAgreement: localStorage.getItem('cookiesNessesaryAgreement') || false,
    };
  }

  async componentDidMount() {
    const {
      authorizedUser,
      location: { search },
      actions: {
        getAllCategory,
        getAuthUser,
        checkToken,
        getAllCategoryAndSubcategory,
        setAppTranslation,
        getShoppingCart,
        getDeliveryCountries,
      },
      history,
      translation,
    } = this.props;
    const notLoggedUserBasketUuid = localStorage.getItem('notLoggedUserBasketUuid');
    if (!authorizedUser && notLoggedUserBasketUuid) {
      await getShoppingCart(notLoggedUserBasketUuid);
    }
    if (!authorizedUser && localStorage.getItem('isUserLogged')) {
      await getAuthUser();
      await getShoppingCart();
    }
    setAppTranslation(localStorage.getItem('translation') || translation || browserLanguage(setAppTranslation));
    getAllCategory();
    getAllCategoryAndSubcategory();
    getDeliveryCountries();
    if (search.split('=')[0] === '?token') {
      const activateToken = JSON.stringify(search.split('=')[1]);
      const sendToken = activateToken.replace(/['"]+/g, '');
      const { token } = await checkToken(sendToken, 'token');
      token
        ? (sessionStorage.setItem('activateToken', JSON.stringify(search.split('=')[1])), history.push(ROUTES.LOGIN))
        : history.push(ROUTES.INACTIVE_ACCOUNT);
    } else if (search.split('=')[0] === '?reset_token') {
      const resetToken = JSON.stringify(search.split('=')[1]);
      const sendToken = resetToken.replace(/['"]+/g, '');
      const { token } = await checkToken(sendToken, 'reset_token');
      token
        ? (sessionStorage.setItem('resetToken', JSON.stringify(search.split('=')[1])), history.push(ROUTES.NEW_PASSWORD))
        : history.push(ROUTES.TOKEN_EXPIRED);
    } else if (search.split('=')[0] === '?pass') {
      const pass = JSON.stringify(search.split('=')[1]);
      const sendToken = pass.replace(/['"]+/g, '');
      const { token } = await checkToken(sendToken, 'pass');
      token
        ? (sessionStorage.setItem('pass', JSON.stringify(search.split('=')[1])), history.push(ROUTES.SET_PASSWORD))
        : history.push(ROUTES.USER_BY_ADMIN_TOKEN_EXPIRED);
    }
  }

  componentDidUpdate(prevProps) {
    const { pathname } = this.props.location;
    const authRefresh =
      pathname === ADVERTISEMENTS ||
      pathname === ORDERS ||
      pathname === USER_MANAGEMENT ||
      pathname === DELIVERY_OPTIONS ||
      pathname === CATEGORY_MANAGEMENT ||
      pathname === WATCHED_PRODUCTS ||
      pathname === CHAT ||
      pathname === SETTINGS ||
      pathname === ADD_ADVERTISEMENT;
    if (this.props.location !== prevProps.location && authRefresh) {
      this.props.actions.getAuthUser();
    }
    const disableScroll = pathname === BASKET || pathname === CHECKOUT;
    console.log(disableScroll);
    setTimeout(
      () =>
        !disableScroll &&
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        }),
      50,
    );
  }

  handleCookiesAccept = () => {
    this.setState({ isCookiesAgreement: true });
  };

  render() {
    const {
      isMobile,
      authorizedUser,
      categoryAndSubcategory,
      actions: { logout, setAppTranslation, modifyShoppingCart, createCheckoutSession, cancelCheckout },
      location: { pathname },
      translation,
      deliveryCountries,
    } = this.props;
    const { isCookiesAgreement } = this.state;
    const isShowFooter = PAY_LATER === pathname;

    // localStorage.removeItem('notLoggedUserBasketUuid');
    return (
      <>
        {translation ? (
          <IntlProvider
            messages={flattenMessages(window?.translations?.[translation])}
            locale={translation}
            key={translation}
            defaultLocale={translation}
          >
            <div className={`page__inner page__inner--${pathname.substring(1)} `}>
              <ToastContainer hideProgressBar autoClose={5000} position="bottom-right" />
              {/* <ToastContainer autoClose={5000} closeOnClick={false} draggablePercent={40} hideProgressBar position="bottom-right" /> */}
              <NavigationHeader
                createCheckoutSession={createCheckoutSession}
                modifyShoppingCart={modifyShoppingCart}
                authorizedUser={authorizedUser}
                mainCategory={categoryAndSubcategory}
                pathname={pathname}
                isMobile={isMobile}
                logout={logout}
                setAppTranslation={setAppTranslation}
                translation={translation}
                shoppingCart={this.props.shoppingCart}
                cancelCheckout={cancelCheckout}
                deliveryCountries={deliveryCountries}
              />
              <Switch>
                <Route component={HomePage} exact path={HOME_PAGE} />
                <Route component={SingleProduct} exact path={SINGLE_PRODUCT} />
                <Route component={ProductPreview} exact path={PRODUCT_PREVIEW} />
                <Route component={PartnerProgram} exact path={PARTNER_PROGRAM} />
                <Route component={ProductsLists} path={`${CATEGORY}/:category`} />
                <Route component={ProductsLists} path={CATEGORY} />
                <Route component={Basket} path={BASKET} />
                <Route component={Checkout} path={CHECKOUT} />
                <Route component={Summary} path={SUMMARY} />
                <Route component={PaymentPayLater} path={PAY_LATER} />
                <Route component={PaymentAccepted} path={PAYMENT_ACCEPTED} />
                <Route component={PaymentCanceled} path={PAYMENT_CANCELED} />
                <Route component={AllegroConnection} path={ALLEGRO_CONNECTION} />
                {/* AdminRoute */}
                <RoleRoute
                  authorizedUserRole={authorizedUser?.role}
                  component={CategoryManagement}
                  exact
                  path={CATEGORY_MANAGEMENT}
                />
                <RoleRoute authorizedUserRole={authorizedUser?.role} component={Magazine} exact path={MAGAZINE} />
                <RoleRoute authorizedUserRole={authorizedUser?.role} component={DeliveryOptions} exact path={DELIVERY_OPTIONS} />
                <RoleRoute authorizedUserRole={authorizedUser?.role} component={UserManagement} exact path={USER_MANAGEMENT} />
                <RoleRoute authorizedUserRole={authorizedUser?.role} component={HelpChat} exact path={CHAT} />
                <RoleRoute authorizedUserRole={authorizedUser?.role} component={PriceManagement} exact path={PRICE_MANAGEMENT} />
                <RoleRoute authorizedUserRole={authorizedUser?.role} component={Dictionary} exact path={DICTIONARY} />
                {/* AuthRoute */}
                <AuthRoute component={WatchedProducts} exact path={WATCHED_PRODUCTS} />
                <AuthRoute component={AddAdvertisement} exact path={ADD_ADVERTISEMENT} />
                <AuthRoute component={EditAdvertisemenets} exact path={EDIT_ADVERTISEMENTS} />
                <AuthRoute component={Orders} exact path={ORDERS} />
                <AuthRoute component={Advertisements} exact path={ADVERTISEMENTS} />
                <AuthRoute component={Invoices} exact path={INVOICES} />
                <AuthRoute component={Settings} exact path={SETTINGS} />
                {/* LOGIN / REGISTER */}
                <Route component={Register} exact path={REGISTER} />
                <Route component={SendActivateMail} exact path={SEND_ACTIVATE_MAIL} />
                <Route component={Login} exact path={LOGIN} />
                <Route component={RemindPassword} exact path={REMIND_PASSWORD} />
                <Route component={ResetPassword} exact path={RESET_PASSWORD} />
                <Route component={NewPassword} exact path={NEW_PASSWORD} />
                <Route component={TokenExpired} exact path={TOKEN_EXPIRED} />
                <Route component={InactiveAccount} exact path={INACTIVE_ACCOUNT} />
                <Route component={SetPassword} exact path={SET_PASSWORD} />
                <Route component={UserByAdminTokenExpired} exact path={USER_BY_ADMIN_TOKEN_EXPIRED} />
                {/* LOGIN / REGISTER END */}
                <Route component={ContactPage} exact path={CONTACT} />
                <Route component={AboutUs} exact path={ABOUT_US} />
                <Route render={() => <Page404 location={pathname} />} />
              </Switch>
              <Chat />
            </div>
            {!isShowFooter && <MainFooter translation={translation} authorizedUser={authorizedUser} isMobile={isMobile} />}
            {!isCookiesAgreement && <CookiesAgreement onSubmit={this.handleCookiesAccept} />}
          </IntlProvider>
        ) : null}
      </>
    );
  }
}

MainRoutes.defaultProps = {
  authorizedUser: null,
};

MainRoutes.propTypes = {
  actions: shape({
    modifyShoppingCart: func.isRequired,
    getShoppingCart: func.isRequired,
    getAllCategory: func.isRequired,
    getAuthUser: func.isRequired,
    createCheckoutSession: func.isRequired,
    cancelCheckout: func.isRequired,
    logout: func.isRequired,
  }).isRequired,
  authorizedUser: object,
  categoryAndSubcategory: object.isRequired,
  deliveryCountries: object.isRequired,
  isMobile: bool.isRequired,
  location: object.isRequired,
  shoppingCart: object.isRequired,
  translation: string.isRequired,
};

export default MainRoutes;

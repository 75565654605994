import { DELIVERY } from 'store/types';
import { REQUEST_STATUS } from 'shared/consts';

const INITIAL_STATE = {
  delivery: {
    data: undefined,
  },
  deliveryCountries: {
    data: undefined,
  },
};

const categoryReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case DELIVERY.GET_DELIVERY_COUNTRIES_INIT: {
      return {
        ...state,
        deliveryCountries: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case DELIVERY.GET_DELIVERY_COUNTRIES_SUCCESS: {
      return {
        ...state,
        deliveryCountries: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case DELIVERY.GET_DELIVERY_COUNTRIES_FAILURE: {
      return {
        ...state,
        deliveryCountries: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case DELIVERY.GET_DELIVERY_INIT: {
      return {
        ...state,
        delivery: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case DELIVERY.GET_DELIVERY_SUCCESS: {
      return {
        ...state,
        delivery: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case DELIVERY.GET_DELIVERY_FAILURE: {
      return {
        ...state,
        delivery: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    default:
      return state;
  }
};

export default categoryReducer;

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import * as ViewManagementSelector from 'store/selectors/viewManagementSelector';
import { addCustomTranslations, getCustomTranslations, searchCustomTranslations } from 'store/actions/custom-translations';
import { getCustomTranslations as getCustomTranslationsSelector } from 'store/selectors/customTranslationsSelector';
import editCustomTranslation from 'store/actions/custom-translations/editCustomTranslation';
import deleteCustomWord from 'store/actions/custom-translations/deleteCustomWord';
import { getAuthorizedUser } from 'store/selectors/userSelector';
import { isMobile as checkIsMobile } from 'shared/utils';
import { getDeliveryCountries as getDeliveryCountriesSelector } from 'store/selectors/deliverySelector';
import Dictionary from './Dictionary.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      addCustomTranslations: data => addCustomTranslations(data),
      getCustomTranslations: data => getCustomTranslations(data),
      editCustomTranstlaions: data => editCustomTranslation(data),
      deleteCustomTranslations: data => deleteCustomWord(data),
      searchCustomTranslations: data => searchCustomTranslations(data),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  translation: ViewManagementSelector.getTranslation(state),
  customTranslations: getCustomTranslationsSelector(state),
  authorizedUser: getAuthorizedUser(state),
  isMobile: checkIsMobile(),
  deliveryCountries: getDeliveryCountriesSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Dictionary));

import React from 'react';
import { SingleCategory } from 'screens/category-management/components';
import { bool, func, array, string } from 'prop-types';

const CategoryList = ({
  isMobile,
  categories,
  addSubcategory,
  deleteCategory,
  editCategory,
  editSubcategory,
  deleteSubcategory,
  formatMessage,
  deleteFiles,
  translation,
}) => (
  <div className="categoryManagement__list">
    {categories.category &&
      categories.category.map((item, idx, id) => (
        <>
          <SingleCategory
            translation={translation}
            formatMessage={formatMessage}
            editCategory={editCategory}
            editSubcategory={editSubcategory}
            deleteCategory={deleteCategory}
            deleteFiles={deleteFiles}
            deleteSubcategory={deleteSubcategory}
            addSubcategory={addSubcategory}
            data={item}
            isMobile={isMobile}
            id={idx}
            key={id}
          />
        </>
      ))}
  </div>
);
export default CategoryList;

CategoryList.propTypes = {
  addSubcategory: func.isRequired,
  categories: array.isRequired,
  deleteCategory: func.isRequired,
  deleteFiles: func.isRequired,
  deleteSubcategory: func.isRequired,
  editCategory: func.isRequired,
  editSubcategory: func.isRequired,
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
  translation: string.isRequired,
};

import React from 'react';
import * as Connector from 'shared/connectors/userConnector';
import { USER } from 'store/types';
import { getUsersByRole } from 'store/actions/user';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import { NOTIFY_TYPE } from 'shared/consts';

export const success = () => ({
  type: USER.EDIT_USER_BY_ADMIN_SUCCESS,
});

const editUserByAdmin = (uuid, form, group) => async dispatch => {
  try {
    const data = await Connector.editUserByAdmin(uuid, form);
    dispatch(getUsersByRole(group));
    toast(<Notify label="global.user_editted" type={NOTIFY_TYPE.CHECKED} />);
    return data;
  } catch (err) {
    return err.response.status;
  }
};

export default editUserByAdmin;

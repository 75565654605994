import React, { useEffect, useState } from 'react';
import { SingleCategory } from 'shared/components';
import { querySearching } from 'shared/utils';
import { object, func, string, array, bool } from 'prop-types';

const MainCategory = ({
  categories,
  formatMessage,
  urlChange,
  setUrlChange,
  country,
  translation,
  products,
  isNotSearchQueryEmpty,
}) => {
  const { search } = window.location;
  const isSearching = search.slice(0, 2) === '?s';

  const [filteredCategories, setFilteredCategories] = useState(
    categories?.filter(
      ({ products_count_pl, products_count_de }) => (country === 'pl' ? products_count_pl : products_count_de) > 0,
    ),
  );

  useEffect(() => {
    categories &&
      setFilteredCategories(
        categories.filter(
          ({ products_count_pl, products_count_de }) => (country === 'pl' ? products_count_pl : products_count_de) > 0,
        ),
      );
  }, [categories, country]);

  useEffect(() => {
    if (
      (isNotSearchQueryEmpty && products) ||
      querySearching().brand ||
      querySearching.main_cat ||
      querySearching().ys ||
      querySearching().ye ||
      querySearching().model
    ) {
      const allHiddenProduct = document.getElementsByClassName('main-categories--item--disabled');
      for (let i = 0; i < allHiddenProduct.length; i) {
        const el = allHiddenProduct[i];
        el.classList.remove('main-categories--item--disabled');
      }
      const categoriesWithoutProducts = [];
      products.filter(item => !item.products_count && categoriesWithoutProducts.push(item));
      categoriesWithoutProducts.forEach(item => {
        const category = document.getElementById(`main-category--${item.link}`);
        category && category.classList.add('main-categories--item--disabled');
      });
    } else {
      const allHiddenProduct = document.getElementsByClassName('main-categories--item--disabled');
      for (let i = 0; i < allHiddenProduct.length; i) {
        const el = allHiddenProduct[i];
        el.classList.remove('main-categories--item--disabled');
      }
    }
  }, [products, window.location.pathname, isNotSearchQueryEmpty]);

  return (
    <div className="main-categories">
      <h2 className="main-categories--name">{formatMessage({ id: 'menu.main_category' })}</h2>
      <div className="main-categories--list">
        {filteredCategories &&
          filteredCategories.map(({ name, subcategories, link, name_ger, id, files, products_count_pl, products_count_de }) => {
            const subCategoriesCountInSelectedLanguage = subcategories.filter(item =>
              country === 'pl' ? item.products_count_pl : item.products_count_de,
            ).length;
            return (
              <div
                onClick={() => {
                  urlChange && setUrlChange({ trigger: !urlChange.trigger });
                  isSearching && setUrlChange({ trigger: !urlChange.trigger, main: true });
                  localStorage.setItem('showAll', true);
                }}
                key={id}
                id={`main-category--${link}`}
                className="main-categories--item"
              >
                <SingleCategory
                  files={files}
                  products_count={country === 'pl' ? products_count_pl : products_count_de}
                  label={translation === 'pl' ? name : name_ger}
                  key={country === 'pl' ? name : name_ger}
                  count={subCategoriesCountInSelectedLanguage}
                  link={link}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MainCategory;

MainCategory.propTypes = {
  authorizedUser: object.isRequired,
  categories: object.isRequired,
  country: string.isRequired,
  formatMessage: func.isRequired,
  isNotSearchQueryEmpty: bool.isRequired,
  products: array.isRequired,
  setUrlChange: func.isRequired,
  translation: string.isRequired,
  urlChange: object.isRequired,
};

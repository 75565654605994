import * as Connector from 'shared/connectors/userConnector';
import { USER } from 'store/types';

const init = () => ({
  type: USER.GET_USERS_BY_ROLE_INIT,
});

export const success = data => ({
  type: USER.GET_USERS_BY_ROLE_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: USER.GET_USERS_BY_ROLE_FAILURE,
});

const getUsersByRole = role => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getUsersByRole(role);
    dispatch(success(data));
  } catch (err) {
    window.location.reload();
    dispatch(failure());
  }
};

export default getUsersByRole;

import React from 'react';
import EmptyViewImg from 'assets/files/empty_view.png';
import { func } from 'prop-types';

const EmptyView = ({ formatMessage }) => (
  <div className="advertisements__empty">
    <img className="advertisements__empty--image" src={EmptyViewImg} alt="brak obserwowanych ogłoszeń" />
    <p className="advertisements__empty--header">{formatMessage({ id: 'global.dont_have_orders' })}</p>
    <p className="advertisements__empty--descript">{formatMessage({ id: 'global.orders_here' })}</p>
  </div>
);

export default EmptyView;

EmptyView.propTypes = {
  formatMessage: func.isRequired,
};

import * as Connector from 'shared/connectors/categoryConnector';
import { CATEGORY } from 'store/types';

const init = () => ({
  type: CATEGORY.GET_SUBCATEGORY_FOR_CATEGORY_INIT,
});

export const success = data => ({
  type: CATEGORY.GET_SUBCATEGORY_FOR_CATEGORY_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: CATEGORY.GET_SUBCATEGORY_FOR_CATEGORY_FAILURE,
});

const getSubcategoryForCategory = uuid => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getSubcategoryForCategory(uuid);
    dispatch(success(data));
    return true;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default getSubcategoryForCategory;

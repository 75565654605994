import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import Shield from 'assets/files/shield.png';
import Mirror from 'assets/files/mirror.png';
import Engine from 'assets/files/engine.png';
import Guaranted from 'assets/files/guaranted.png';
import Getmotorman from 'assets/files/getmotorman.png';

const SingleTile = ({
  color,
  shield,
  header,
  descript,
  secondDescript,
  double,
  engine,
  button,
  join,
  buttonTitle,
  guaranted,
  buttonOnClick,
}) => (
  <section className={`single-tile single-tile--${color} single-tile--${double && 'double'}`}>
    <div className="single-tile--descript">
      <h2 className={`single-tile__header single-tile__header--${double && 'double'}`}>{header}</h2>
      <p className={`single-tile__paragraph single-tile__paragraph--${double && 'double'}`}>{descript}</p>
      <p className="single-tile__paragraph">{secondDescript}</p>
      {join.length > 0 && <Button label={join} className="btn--orange-border" onClick={buttonOnClick} />}
      {guaranted && (
        <div className="single-tile--guaranted__wrapper">
          <img className="single-tile--guaranted" src={Guaranted} alt="engine" />{' '}
        </div>
      )}
      {join && !double && <img className="single-tile--mirror" src={Mirror} alt="engine" />}
      {join && double && <img className="single-tile--motorman" src={Getmotorman} alt="engine" />}
    </div>
    {button && (
      <Button
        className={`btn__fit-chevrone btn__fit-chevrone--${color} btn--${button} btn--single-title`}
        iconType={buttonTitle ? '' : SVG_TYPE.FIT_CHEVRONE}
        label={buttonTitle}
        onClick={buttonOnClick}
      />
    )}
    {shield && <img className="single-tile--shield" src={Shield} alt="shield" />}
    {engine && <img className="single-tile--engine" src={Engine} alt="engine" />}
  </section>
);

SingleTile.defaultProps = {
  button: null,
  buttonOnClick: () => console.log('.'),
  buttonTitle: null,
  double: false,
  engine: false,
  guaranted: false,
  join: false,
  secondDescript: '',
  shield: false,
};

SingleTile.propTypes = {
  button: PropTypes.string,
  buttonOnClick: PropTypes.func,
  buttonTitle: PropTypes.string,
  color: PropTypes.string.isRequired,
  descript: PropTypes.string.isRequired,
  double: PropTypes.bool,
  engine: PropTypes.bool,
  guaranted: PropTypes.bool,
  header: PropTypes.string.isRequired,
  join: PropTypes.bool,
  secondDescript: PropTypes.string,
  shield: PropTypes.bool,
};

export default SingleTile;

import React from 'react';
import * as Connector from 'shared/connectors/categoryConnector';
import { CATEGORY } from 'store/types';
import { getAllCategoryAndSubcategory } from 'store/actions/category';
import { NOTIFY_TYPE } from 'shared/consts';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';

const init = () => ({
  type: CATEGORY.ADD_SUB_CATEGORY_INIT,
});

export const success = data => ({
  type: CATEGORY.ADD_SUB_CATEGORY_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: CATEGORY.ADD_SUB_CATEGORY_FAILURE,
});

const addCategory = data => async dispatch => {
  dispatch(init());
  const linkModified = data.link.replace(/\s/g, '-').replace('/', '');
  try {
    await Connector.addSubcategory({ name: data.name, name_ger: data.name_ger, link: linkModified, uuid: data.uuid });
    dispatch(success(data));
    dispatch(getAllCategoryAndSubcategory());
    toast(<Notify label="global.successful_add_subcategory" type={NOTIFY_TYPE.CHECKED} />);

    return true;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default addCategory;

import axios from 'axios';
import store, { history } from 'store/store';
import { setRefreshToken } from 'store/actions/auth';
import { success } from 'store/actions/auth/logout';
import { ROUTES } from 'shared/consts';

const axiosPlugin = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axiosPlugin.defaults.headers.post['Content-Type'] = 'application/json';

axiosPlugin.interceptors.request.use(
  config => {
    const copyConfig = config;
    if (store.getState().authStore.token) {
      copyConfig.headers.Authorization = `Bearer ${store.getState().authStore.token}`;
    }
    return copyConfig;
  },
  error => {
    Promise.reject(error);
  },
);

axiosPlugin.interceptors.response.use(
  response => Promise.resolve(response),
  error => {
    const originalRequest = error.config;
    const { url } = originalRequest;

    if (typeof error.response !== 'undefined' && error.response.status === 401 && url !== '/login' && url === '/reset-password') {
      if (url === `${originalRequest.baseURL}auth/refresh`) {
        store.dispatch(success());
        history.push(ROUTES.LOGIN);
        return Promise.reject(error);
      }
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axios(originalRequest);
          })
          .catch(err => Promise.reject(err));
      }

      isRefreshing = true;
      originalRequest.retry = true;

      return new Promise((resolve, reject) => {
        axiosPlugin
          .post(`${process.env.REACT_APP_API_URL}auth/refresh`, null, { retry: true })
          .then(({ data }) => {
            store.dispatch(setRefreshToken(data));
            originalRequest.headers.Authorization = `Bearer ${data.token}`;
            processQueue(null, data.token);
            resolve(axios(originalRequest).then(response => response));
          })
          .catch(err => {
            processQueue(err, null);
            reject(err);
            if (err.response && err.response.status === 404) {
              store.dispatch(success());
              history.push(ROUTES.LOGIN);
            }
            // console.log(err.response);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  },
);

export const config = (params, headers) => {
  const configRq = {};
  if (params) {
    configRq.params = params;
  }
  if (headers) {
    configRq.headers = headers;
  }
  return configRq;
};

export default axiosPlugin;

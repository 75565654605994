import React, { useEffect } from 'react';
import PropTypes, { shape } from 'prop-types';
import { ProductListItem } from 'shared/components';
import EmptyView from 'assets/files/empty_view.png';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const WatchedProducts = ({
  isMobile,
  actions: { getFavoriteProducts, deleteFollowedProduct },
  favoriteProducts,
  translation,
  intl: { formatMessage },
}) => {
  useEffect(() => {
    getFavoriteProducts();
  }, []);

  const favoriteProductsCount = favoriteProducts?.length

  return (
    <div className="watched-products">
      <div className="list">
        <div className="title">
          {formatMessage({ id: 'global.watched_addvertisements' })} <div className={`counter ${favoriteProductsCount ? 'counter--fill' : ''}`}>{favoriteProductsCount}</div>
        </div>
        <div className="list__content">
          <TransitionGroup className="list">
            {favoriteProductsCount > 0 &&
              favoriteProducts.map(product => (
                <CSSTransition key={product.id} timeout={500} classNames="item">
                  <div className="listItem">
                    <ProductListItem
                      isMobile={isMobile}
                      className="watched-products--item"
                      watched
                      formatMessage={formatMessage}
                      item={product}
                      deleteFollowedProduct={deleteFollowedProduct}
                      translation={translation}
                    />
                  </div>
                </CSSTransition>
              ))}
          </TransitionGroup>
          {favoriteProductsCount === 0 && (
            <div className="empty">
              <img className="empty--image" src={EmptyView} alt="brak obserwowanych ogłoszeń" />
              <p className="empty--header">{formatMessage({ id: 'global.empty_watched' })}</p>
              <p className="empty--descript">{formatMessage({ id: 'global.empty_watched_descript' })}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WatchedProducts;

WatchedProducts.defaultProps = {
  formatMessage: () => console.log(''),
};
WatchedProducts.propTypes = {
  actions: shape({
    getFavoriteProducts: PropTypes.func.isRequired,
  }).isRequired,
  authorizedUser: PropTypes.object.isRequired,
  favoriteProducts: PropTypes.array.isRequired,
  formatMessage: PropTypes.func,
  isMobile: PropTypes.bool.isRequired,
  translation: PropTypes.string.isRequired,
};

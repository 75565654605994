const PRICE = {
  GET_PRICE_MARGIN_INIT: 'GET_PRICE_MARGIN_INIT',
  GET_PRICE_MARGIN_SUCCESS: 'GET_PRICE_MARGIN_SUCCESS',
  GET_PRICE_MARGIN_FAILURE: 'GET_PRICE_MARGIN_FAILURE',
  GET_CURRENCY_EXCHANGE_LIST_INIT: 'GET_CURRENCY_EXCHANGE_LIST_INIT',
  GET_CURRENCY_EXCHANGE_LIST_SUCCESS: 'GET_CURRENCY_EXCHANGE_LIST_SUCCESS',
  GET_CURRENCY_EXCHANGE_LIST_FAILURE: 'GET_CURRENCY_EXCHANGE_LIST_FAILURE',
  EDIT_CURRENCY_EXCHANGE_INIT: 'EDIT_CURRENCY_EXCHANGE_INIT',
  EDIT_CURRENCY_EXCHANGE_SUCCESS: 'EDIT_CURRENCY_EXCHANGE_SUCCESS',
  EDIT_CURRENCY_EXCHANGE_FAILURE: 'EDIT_CURRENCY_EXCHANGE_FAILURE',
  EDIT_PRICE_MARGIN_SUCCESS: 'EDIT_PRICE_MARGIN_SUCCESS',
  DELETE_PRICE_MARGIN_SUCCESS: 'DELETE_PRICE_MARGIN_SUCCESS',
  ADD_INVOICE_DATA: 'ADD_INVOICE_DATA',
  GET_INVOICE_DATA_INIT: 'GET_INVOICE_DATA_INIT',
  GET_INVOICE_DATA_SUCCESS: 'GET_INVOICE_DATA_SUCCESS',
  GET_INVOICE_DATA_FAILURE: 'GET_INVOICE_DATA_FAILURE',
};

export default PRICE;

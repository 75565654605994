import React from 'react';
import { shape, func, object } from 'prop-types';
import PaymentCanceledPhoto from 'assets/files/payment_failure.png';
import { Button, SVG } from 'shared/components';
import { SVG_TYPE, ROUTES } from 'shared/consts';
import { useHistory } from 'react-router-dom';

const PaymentCanceled = ({ intl: { formatMessage }, authorizedUser, actions: { createCheckoutSession } }) => {
  const history = useHistory();
  const CheckoutUuid = localStorage.getItem('checkoutUuid');
  return (
    <div className="payments">
      <div className="payments__content">
        <p className="descript">
          {formatMessage({ id: 'global.payment_failure' })} <SVG type={SVG_TYPE.ERROR} />
        </p>
        {!authorizedUser && (
          <p className="descript descript--failure">{formatMessage({ id: 'global.payment_failure_descript' })}</p>
        )}
        <div className="payments__image--wrapper">
          <div className="payments__image--content">
            <img src={PaymentCanceledPhoto} className="failure" alt="payment success" />
            {authorizedUser ? (
              <Button
                className="btn--orange"
                label={formatMessage({ id: 'global.my_orders' })}
                iconType={SVG_TYPE.ARROW_RIGHT}
                onClick={() => history.push(ROUTES.ORDERS)}
              />
            ) : (
              <div className="payments--canceled__buttons">
                <Button
                  className="btn--orange"
                  label={formatMessage({ id: 'global.retry_payments' })}
                  onClick={async () => createCheckoutSession({ uuid: CheckoutUuid })}
                />
                <p className="or">lub</p>
                <Button
                  className="btn--orange-border"
                  label={formatMessage({ id: 'global.return_to_home_page' })}
                  onClick={() => history.push(ROUTES.HOME_PAGE)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentCanceled;

PaymentCanceled.propTypes = {
  actions: shape({
    createCheckoutSession: func.isRequired,
  }).isRequired,
  authorizedUser: object.isRequired,
  intl: shape({
    formatMessage: func.isRequired,
  }).isRequired,
};

import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { getAuthorizedUser } from 'store/selectors/userSelector';
import { getShoppingCartOwner as getShoppingCartOwnerSelector } from 'store/selectors/shoppingCartSelector';
import { clearShoppingCart, getShoppingCartOwner } from 'store/actions/shopping-cart';
import { setCheckout } from 'store/actions/checkout';
import { createCheckoutSession } from 'store/actions/payments';
import Summary from './Summary.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getShoppingCartOwner: () => getShoppingCartOwner(),
      setCheckout: payLater => setCheckout(payLater),
      clearShoppingCart: () => clearShoppingCart(),
      createCheckoutSession: (uuid) => createCheckoutSession(uuid),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  authorizedUser: getAuthorizedUser(state),
  shoppingCartOwner: getShoppingCartOwnerSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Summary));

/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { string, func, bool, object, number } from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';

const ConclusionsSettings = ({ settingsType, formatMessage, setSettingsType, setOpenModal, data, id }) => {
  const { user } = data;

  const settingsTypes = {
    client: 'client',
    notLoggged: 'notlogged',
  };

  const handleClickSettings = () => {
    user ? setSettingsType({ type: settingsTypes.client, id }) : setSettingsType({ type: settingsTypes.notLoggged, id });
  };

  const closeSettings = () => {
    setSettingsType({ type: '', id: '' });
  };

  return (
    <div className="user-management__table__settings">
      <div className="user-management__table__settings--item">
        {data.user && (
          <a href={`mailto:${user?.email}`}>
            <SVG type={SVG_TYPE.MAIL} className="svg--mail--gray" />
          </a>
        )}
      </div>
      <div
        className={`user-management__table__settings--item ${
          settingsType.id === id ? 'user-management__table__settings--item--disabled' : ''
        } `}
        onClick={() => handleClickSettings()}
      >
        <SVG type={SVG_TYPE.SETTINGS} />
      </div>
      {settingsType.id === id && settingsType.type === settingsTypes.client && (
        <OutsideClickHandler display="flex" onOutsideClick={() => setSettingsType({ type: '', id: '' }, 1)}>
          <div className="user-management__table__settings__list" id={id}>
            <div
              className="user-management__table__settings__list--item"
              onClick={() => {
                closeSettings();
                setOpenModal({ isOpened: 'company_data', data });
              }}
            >
              {formatMessage({ id: 'global.company_data' })}
            </div>
          </div>
        </OutsideClickHandler>
      )}
      {settingsType.id === id && settingsType.type === settingsTypes.notLoggged && (
        <OutsideClickHandler display="flex" onOutsideClick={() => setSettingsType({ type: '', id: '' }, 10)}>
          <div className="user-management__table__settings__list" id={id}>
            <div
              className="user-management__table__settings__list--item"
              onClick={() => {
                closeSettings();
                setOpenModal({ isOpened: 'assign', data });
              }}
            >
              {formatMessage({ id: 'global.preview' })}
            </div>
            <div
              className="user-management__table__settings__list--item user-management__table__settings__list--item--red"
              onClick={() => {
                closeSettings();
                setOpenModal({ isOpened: 'remove-application', data });
              }}
            >
              {formatMessage({ id: 'global.discard' })}
            </div>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default ConclusionsSettings;

ConclusionsSettings.propTypes = {
  data: object.isRequired,
  formatMessage: func.isRequired,
  id: number.isRequired,
  openModal: bool.isRequired,
  setOpenModal: func.isRequired,
  setSettingsType: func.isRequired,
  settingsType: string.isRequired,
};

import React, { useState } from 'react';
import { bool, func, object } from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import { string } from 'yup/lib/locale';
import { BasketItem, BasketSummary, UnpaidOrder } from './components';

const BasketModal = ({
  authorizedUser,
  isOpened,
  onClose,
  formatMessage,
  mobile,
  data,
  modifyShoppingCart,
  translation,
  isUnpaidOrder,
  createCheckoutSession,
  cancelCheckout,
}) => {
  const [amounts, setAmount] = useState({ start: 0, end: 3 });

  const closeModal = () => {
    onClose();
    setAmount({ start: 0, end: 3 });
  };

  return (
    <>
      {isOpened && (
        <OutsideClickHandler display="flex" onOutsideClick={() => closeModal()}>
          <div className={`basket-modal ${mobile ? 'basket-modal--mobile' : ''}`}>
            {isUnpaidOrder ? (
              <UnpaidOrder
                closeModal={() => closeModal()}
                cancelCheckout={cancelCheckout}
                createCheckoutSession={createCheckoutSession}
                formatMessage={formatMessage}
              />
            ) : (
              <>
                {data?.shopping_cart?.products?.length > 0 ? (
                  <>
                    {data?.shopping_cart?.products.map((item, index, elements) => (
                      <>
                        {index <= amounts.end && index >= amounts.start && (
                          <>
                            <BasketItem
                              key={item.name + elements}
                              authorizedUser={authorizedUser}
                              modifyShoppingCart={modifyShoppingCart}
                              data={item}
                              formatMessage={formatMessage}
                              setAmount={setAmount}
                              amounts={amounts}
                              lastItem={index === amounts.end && index !== elements.length - 1}
                              isNotFirst={amounts.start !== 0 && amounts.start === index}
                              translation={translation}
                            />
                          </>
                        )}
                      </>
                    ))}
                    <BasketSummary data={data} onClose={closeModal} mobile={mobile} formatMessage={formatMessage} />
                  </>
                ) : (
                  <p className="basket-modal--empty">{formatMessage({ id: 'global.basket_empty' })}</p>
                )}
              </>
            )}
          </div>
        </OutsideClickHandler>
      )}
    </>
  );
};

export default BasketModal;

BasketModal.propTypes = {
  authorizedUser: object.isRequired,
  cancelCheckout: func.isRequired,
  createCheckoutSession: func.isRequired,
  data: object.isRequired,
  formatMessage: func.isRequired,
  isOpened: bool.isRequired,
  isUnpaidOrder: bool.isRequired,
  mobile: bool.isRequired,
  modifyShoppingCart: func.isRequired,
  onClose: func.isRequired,
  translation: string.isRequired,
};

const categoriesOptions = categories => {
  const arr = [];
  categories.forEach(({ name, uuid }) =>
    arr.push({
      label: name,
      searchableLabel: name,
      value: uuid,
    }),
  );
  return arr;
};

export default categoriesOptions;

import React from 'react';
import * as Connector from 'shared/connectors/categoryConnector';
import { CATEGORY } from 'store/types';
import { getAllCategoryAndSubcategory } from 'store/actions/category';
import { NOTIFY_TYPE } from 'shared/consts';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import { addFiles } from 'store/actions/utils';
// import { addFiles } from 'shared/connectors/utilsConnector';

const init = () => ({
  type: CATEGORY.ADD_CATEGORY_INIT,
});

export const success = data => ({
  type: CATEGORY.ADD_CATEGORY_SUCCESS,
  payload: data,
});

const failure = () => ({
  type: CATEGORY.ADD_CATEGORY_FAILURE,
});

const addCategory = form => async dispatch => {
  dispatch(init());
  try {
    const linkModified = form.link.replace(/\s/g, '-').replace('/', '');
    const { files } = form;
    const { data } = await Connector.addCategory({ name: form.name, name_ger: form.name_ger, link: linkModified });
    if (files) {
      const fileForm = new FormData();
      fileForm.append('file', files[0]);
     const result = await dispatch(addFiles(fileForm, data.category.uuid, 'category_uuid='));
      if (result !== true) {
        // eslint-disable-next-line no-throw-literal
        throw{response: "cannot add this photo"}
      }
    }
    dispatch(success(form));
    dispatch(getAllCategoryAndSubcategory());
    toast(<Notify label="global.successful_add_category" type={NOTIFY_TYPE.CHECKED} />);
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default addCategory;

import React, { useState, useEffect } from 'react';
import { array, bool, func } from 'prop-types';
import { Title } from 'shared/components';
import { OrdersMenu, OrdersFilters, OrdersList } from './components';
import { MENU_TYPES, STATUSES } from './components/const';

const Orders = ({
  isMobile,
  intl: { formatMessage },
  createCheckoutSession,
  getCheckout,
  getCountCheckoutStatus,
  checkout,
  countCheckoutStatus,
}) => {
  const [menuType, setMenuType] = useState(MENU_TYPES(formatMessage).ALL);
  const [filters, setFilters] = useState({ title: '' });

  useEffect(async () => {
    await getCountCheckoutStatus();
  }, []);

  useEffect(async () => {
    const { statuses } = STATUSES(formatMessage).find(item => item.label === menuType);
    const { title } = filters;
    await getCheckout({ status: statuses, title });
  }, [menuType, filters]);

  return (
    <div className="my-orders__wrapper">
      <Title title={formatMessage({ id: 'global.my_orders' })} className="magazine" fontStyle="default" />
      <OrdersMenu
        countCheckoutStatus={countCheckoutStatus}
        formatMessage={formatMessage}
        menuType={menuType}
        setMenuType={setMenuType}
        isMobile={isMobile}
      />
      <OrdersFilters filters={filters} setFilters={setFilters} formatMessage={formatMessage} isMobile={isMobile} />
      <OrdersList
        createCheckoutSession={createCheckoutSession}
        data={checkout}
        formatMessage={formatMessage}
        isMobile={isMobile}
      />
    </div>
  );
};

export default Orders;

Orders.propTypes = {
  checkout: array.isRequired,
  countCheckoutStatus: array.isRequired,
  createCheckoutSession: func.isRequired,
  formatMessage: func.isRequired,
  getCheckout: func.isRequired,
  getCountCheckoutStatus: func.isRequired,
  isMobile: bool.isRequired,
};

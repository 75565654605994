import React from 'react';
import { array, bool, func, number, object } from 'prop-types';
import { thisSameDeliveryMethod } from 'shared/utils';

const DeliveryGroup = ({ sameDelivery, products, index, seller, formatMessage, foreign_country }) => {
  const productsAmount = Object.keys(products).length;
  const signleForeignDelivery = seller.foreign_country && Object.keys(products).length === 1;

  const clickGroupDelivery = (e, deliveryUuid) => {
    if (foreign_country) {
      const deliveryItemGroup = document.getElementsByClassName('delivery__item--group');
      for (let i = 0; i < deliveryItemGroup.length; i++) {
        const el = deliveryItemGroup[i];
        const isActive = el.classList.contains('delivery__item--active');
        isActive && el.classList.remove('delivery__item--active');
      }
    }

    const sellerBox = document.getElementsByClassName(`seller-box--${seller.id}`);
    const isActive = e.target.closest('.delivery__item').classList.contains('delivery__item--active');
    if (!isActive) {
      e.target.classList.add('delivery__item--active');
      const thisSameDeliveryElement = sellerBox[0].getElementsByClassName(`delivery__item--${deliveryUuid}`);
      for (let i = 0; i < thisSameDeliveryElement.length; i++) {
        const el = thisSameDeliveryElement[i];
        const deliveryBox = el.closest('.delivery');
        const arrow = deliveryBox.getElementsByClassName('delivery__arrow');
        if (arrow && arrow.length > 0 && !arrow[0].classList.contains('delivery__arrow--active')) {
          arrow[0].click();
        }
        setTimeout(() => {
          el.click();
        }, 110 * i);
      }
    }
    const deliveryList = e.target.closest('.delivery__list');
    const groupError = deliveryList.getElementsByClassName('group--error');
    const deliveryGroupSingleElement = deliveryList.getElementsByClassName('delivery__item--error');
    for (let i = 0; i < deliveryGroupSingleElement.length; i) {
      const el = deliveryGroupSingleElement[i];
      if (i >= 0) {
        el.classList.remove('delivery__item--error');
      }
    }
    groupError.length > 0 &&
      groupError[0].classList.remove('group--error', 'delivery__item--error--active', 'delivery__list--error--active');
  };

  return (
    <>
      {productsAmount === index + 1 && (
        <>
          {thisSameDeliveryMethod(sameDelivery, signleForeignDelivery).length > 0 && (
            <div className="delivery delivery--group">
              <div className="delivery__list delivery__list--group">
                {thisSameDeliveryMethod(sameDelivery, signleForeignDelivery).length > 0 &&
                  thisSameDeliveryMethod(sameDelivery, signleForeignDelivery).map(element => (
                    <div
                      className={`delivery__item ${seller.foreign_country ? 'delivery__item--group' : ''} delivery__group--${
                        element.uuid
                      }`}
                      onClick={e => clickGroupDelivery(e, element.uuid)}
                    >
                      <div className="delivery__item__image--wrapper">
                        <img className="delivery__item__image" src={element.files?.length > 0 && element.files[0].path} alt="" />
                      </div>
                      <div className="delivery__item--label">
                        {element.name}
                        <p className="delivery__item--description">{formatMessage({ id: 'global.all_products_one_package' })}</p>
                      </div>
                      <div className="delivery__item--price-wrapper delivery__item--price-wrapper--description">
                        {element.price} {element.currency && element.currency.currency.toUpperCase()}
                      </div>
                    </div>
                  ))}
                {seller.foreign_country && (
                  <p className="delivery__list--error group--error">{formatMessage({ id: 'global.choose_a_delivery_method' })}</p>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DeliveryGroup;

DeliveryGroup.propTypes = {
  foreign_country: bool.isRequired,
  formatMessage: func.isRequired,
  index: number.isRequired,
  products: array.isRequired,
  sameDelivery: array.isRequired,
  seller: object.isRequired,
};

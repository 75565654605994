/* eslint-disable react/no-access-state-in-setstate */
import * as React from 'react';
import { string, bool, number, object, func } from 'prop-types';
import { SVG, Button } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

class PhotoCategory extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fileArr: (this.props.fileInputValue && this.props.fileInputValue) || [],
      base64Arr: [],
      fileName: (this.props.fileInputValue && this.props.fileInputValue[0]?.file_name) || '',
    };
  }

  componentWillUnmount() {
    this.setState({ fileArr: [] });
    this.props.setIsSetCategoryPhoto(null);
  }

  render() {
    const { handleChange } = this.props;
    const fileInputKey = this.props.field.name ? this.props.field.name : +new Date();
    const formClasses = 'add__photos';
    const { base64Arr } = this.state;

    const isValidFileUploaded=(file)=>{
      const validExtensions = ['png','jpeg','jpg','svg','svg+xml']
      const fileExtension = file.type && file.type.split('/')[1]
      return validExtensions.includes(fileExtension)
    }

    const onChange = e => {
      const { setFieldValue } = this.props.form;
      const { files } = e.target;
      this.props.setIsSetCategoryPhoto(false);
      for (let idx = 0; idx < files.length; idx++) {
        const reader = new FileReader();
        reader.readAsDataURL(files[idx]);
        reader.onloadend = () => {
          const fileArrClone = [URL.createObjectURL(files[idx])];
          const base64ArrClone = [reader.result];
          setFieldValue(this.props.field.name, base64Arr);
          this.setState({
            fileArr: fileArrClone,
            base64Arr: base64ArrClone,
            fileName: files[0].name,
          });
        };
      }
    };

    const removePhoto = async () => {
      this.props.setpPhotoUuidDelete && this.props.setpPhotoUuidDelete(this.state.fileArr[0].uuid);
      this.setState({ fileArr: [] });
      this.props.setIsSetCategoryPhoto('empty');
      this.props.setFileInputValue([])
    };


    return (
      <div className={formClasses}>
        <div className="add__photos--categoryManag">
          <div className="add__photo--catManag">
            <Button
              label={this.props.formatMessage({ id: 'inputs.add_photo_plus' })}
              className={`btn--gray categoryManagement__header--button ${
                this.props.isSetCategoryPhoto === true ? 'btn--not-allowed' : ''
              }`}
            />
            {/* <Button label="Dodaj zdjęcie +" className="btn--gray categoryManagement__header--button" /> */}
            <input
              id={this.props.field.name}
              name={this.props.field.name}
              key={fileInputKey}
              type="file"
              accept="image/*"
              onChange={event => {
                if (isValidFileUploaded(event.target.files[0])) {
                  onChange(event)
                  handleChange && handleChange(event);
                }
              }}
              // eslint-disable-next-line no-return-assign
              ref={ref => (this.fileInput = ref)}
              className={`add__photo--input ${this.props.isSetCategoryPhoto === true ? 'btn--not-allowed' : ''}`}
            />
          </div>
          {/* {console.log(this.state.fileArr)} */}
          {this.state.fileArr &&
            this.state.fileArr.length > 0 &&
            this.state.fileArr.map(e => {
              if (e) {
                return (
                  <>
                    <div key={e} className="add__photo--catManage">
                      <div className="add__photo--remove__wrapper-catManag" onClick={event => removePhoto(event)}>
                        <div className="add__photo--remove-catManag" />
                        <SVG type={SVG_TYPE.CLOSE} />
                      </div>
                      {/* <img className="add__photo--img" src={e} alt="zdjęcie produktu" /> */}
                      <div className="add__photo--catManag--name">
                        <p>{this.state.fileName}</p>
                      </div>
                    </div>
                  </>
                );
              }
              return null;
            })}
        </div>
      </div>
    );
  }
}
PhotoCategory.defaultProps = {
  deleteFiles: undefined,
  fileInputValue: null,
  formatMessage: null,
  max: undefined,
  min: undefined,
  multiple: false,
  setFileInputValue: false,
  setpPhotoUuidDelete: false,
  wrapperClassName: undefined,
};
PhotoCategory.propTypes = {
  deleteFiles: func,
  field: object.isRequired,
  // disabled: bool.isRequired,
  fileInputValue: string,
  form: object.isRequired,
  formatMessage: func,
  handleChange: func.isRequired,
  isSetCategoryPhoto: string.isRequired,
  label: string.isRequired,
  max: number,
  min: number,
  multiple: bool,
  setFileInputValue: func,
  setIsSetCategoryPhoto: func.isRequired,
  setpPhotoUuidDelete: func,
  type: string.isRequired,
  wrapperClassName: string,
};
export default PhotoCategory;

import API from 'shared/connectors/config';


export function addCustomTranslations(data) {
  return API.post('custom-translations/add',data);
}

export function getCustomTranslations({country_uuid}) {
  return API.get('custom-translations/show/',{params: {country_uuid}});
}

export function deleteCustomWord(uuid) {
  return API.delete(`custom-translations/delete/${uuid}`);
}

export function editCustomWord(data) {
  return API.put(`custom-translations/edit/`, data);
}

export function searchCustomTranslations(search,country_uuid) {
  return API.get('custom-translations/search/',{ params: { search, country_uuid } });
}
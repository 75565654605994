import React from 'react';
import { string } from 'prop-types';

const Badge = ({ type, label }) => (
  <div className="badge__wrapper">
    <div className={`badge badge--${type}`}><span className='badge--label'>{label}</span></div>
  </div>
);

export default Badge;

Badge.propTypes = {
  label: string.isRequired,
  type: string.isRequired,
};

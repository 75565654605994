import { connect } from 'react-redux';
import { isMobile as checkIsMobile } from 'shared/utils';
import { getCategoryAndSubcategory, getPopularCategoriesSelector } from 'store/selectors/categorySelector';
import { injectIntl } from 'react-intl';
import * as ViewManagementSelector from 'store/selectors/viewManagementSelector';
import { bindActionCreators } from 'redux';
import { getCarBrands, getCarModels } from 'store/actions/car';
import { getCarBrands as getCarBrandsSelector, getCarModels as getCarModelsSelector } from 'store/selectors/carSelector';
import { getDeliveryCountries } from 'store/actions/delivery';
import { getDeliveryCountries as getDeliveryCountriesSelector } from 'store/selectors/deliverySelector';
import { getAuthorizedUser } from 'store/selectors/userSelector';
import { getPopularBrands, getProductsByFilter } from 'store/actions/products';
import { getPopularBrandsSelector } from 'store/selectors/productsSelector';
import { getPopularCategories } from 'store/actions/category';
import HomePage from './HomePage.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCarBrands: () => getCarBrands(),
      getCarModels: brandName => getCarModels(brandName),
      getDeliveryCountries: () => getDeliveryCountries(),
      getProductsByFilter: form => getProductsByFilter(form),
      getPopularBrands: () => getPopularBrands(),
      getPopularCategories: ()=> getPopularCategories()
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  isMobile: checkIsMobile(),
  categories: getCategoryAndSubcategory(state),
  translation: ViewManagementSelector.getTranslation(state),
  carBrands: getCarBrandsSelector(state),
  carModels: getCarModelsSelector(state),
  deliveryCountries: getDeliveryCountriesSelector(state),
  authorizedUser: getAuthorizedUser(state),
  popularBrands: getPopularBrandsSelector(state),
  popularCategories: getPopularCategoriesSelector(state),
  
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(HomePage));

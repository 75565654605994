import React, { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { RegularField, SVG, Button, SelectField } from 'shared/components';
import { SVG_TYPE, VALIDATION_FIELD, ROUTES } from 'shared/consts';
import { func, array, object, string } from 'prop-types';
import { carBrandsOptions, carModelsOptions, deliveryCountryOptions } from 'shared/utils';
import { useHistory } from 'react-router-dom';

const SearchParts = ({
  authorizedUser,
  formatMessage,
  getCarModels,
  carBrands,
  carModels,
  deliveryCountries,
  categories,
  country
}) => {
  const [isModelOn, setIsModelOn] = useState(false);
  const userDeliveryCountry = (authorizedUser && authorizedUser?.country.label) || localStorage.getItem('deliveryCountry');
  const history = useHistory();

  const getModelName = model_uuid => {
    const model = carModels.filter(item => item.label === model_uuid);
    return model[0].value.split(' ').join('_');
  };

  const handleOnSubmit = async values => {
    const {
      country_uuid,
      search,
      year_of_production_start,
      year_of_production_end,
      brand_uuid,
      category_uuid,
      model_uuid,
    } = values;
    if (!userDeliveryCountry) {
      localStorage.setItem('deliveryCountry', country_uuid);
    }
    localStorage.setItem('runSearchEngine', true);
    const carBrandName = carBrands.filter(item => item.label === brand_uuid);
    const categoryName = categories.category.filter(item => item.uuid === category_uuid);
    history.push(
      `${ROUTES.CATEGORY}/?s${search ? `=${search.replace(/\s/g, '-')}` : ''}${
        year_of_production_start ? `&ys=${year_of_production_start}` : ''
      }${year_of_production_end ? `&ye=${year_of_production_end}` : ''}${
        brand_uuid ? `&brand=${carBrandName[0].value.replace(/\s/g, '-')}` : ``
      }${model_uuid ? `&model=${getModelName(model_uuid)}` : ''}${category_uuid ? `&main_cat=${categoryName[0].link}` : ``}`,
    );
  };

  const categoryOptions = () => {
  
    const [filteredCategories, setFilteredCategories] = useState(
      categories?.category && categories?.category?.filter(
        ({ products_count_pl, products_count_de }) =>
          (country === 'pl' ? products_count_pl : products_count_de) > 0,
      ),
    );
    useEffect(() => {
      categories?.category && setFilteredCategories(categories?.category.filter(
        ({ products_count_pl, products_count_de }) =>
          (country === 'pl' ? products_count_pl : products_count_de) > 0,
      ))
    }, [categories?.category, country])
  
    const arr = [];
  
    filteredCategories?.forEach(({ name, uuid }) => arr.push({ label: name, searchableLabel: name, value: uuid, uuid }));
    
    return arr;
};
  

  const getModelsByBrand = (e, setFieldValue)  => {
    if (e) {
      getCarModels(e.label);
      setFieldValue('model_uuid', '');
      setIsModelOn(true);
    } else {
      setIsModelOn(false);
      setFieldValue('model_uuid', '');
    }
  };


  const validation = () =>
    Yup.object().shape({
      country_uuid: Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED).min(2, VALIDATION_FIELD(formatMessage).MIN_2),
      search: Yup.string().min(2, VALIDATION_FIELD(formatMessage).MIN_2),
      year_of_production_end: Yup.string()
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .min(4, VALIDATION_FIELD(formatMessage).DATA)
        .max(4, VALIDATION_FIELD(formatMessage).DATA)
        .matches(/^(19|20)\d{2}$/, VALIDATION_FIELD(formatMessage).DATA),
      year_of_production_start: Yup.string()
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .min(4, VALIDATION_FIELD(formatMessage).DATA)
        .max(4, VALIDATION_FIELD(formatMessage).DATA)
        .matches(/^(19|20)\d{2}$/, VALIDATION_FIELD(formatMessage).DATA),
    });

  const resetFormik = setFieldValue => {
    setFieldValue('search', '');
    setFieldValue('brand_uuid', '');
    setFieldValue('model_uuid', '');
    setFieldValue('year_of_production_start', '');
    setFieldValue('year_of_production_end', '');
    setIsModelOn(false);
  };

  return (
    <div className="search-parts">
      <div className="search-parts--heading">
        <h1 className="title title--orange">
          {formatMessage({ id: 'global.here_find' })} <br /> {formatMessage({ id: 'global.above' })}{' '}
          <strong>{formatMessage({ id: 'global.counter_parts' })} </strong>
          {formatMessage({ id: 'global.new_and_used' })}
        </h1>
        <p className="descript descript--orange">{formatMessage({ id: 'global.buy_here' })}</p>
      </div>
      <div className="search-parts__form">
        <Formik
          validationSchema={validation()}
          initialValues={{
            country_uuid: userDeliveryCountry || '',
            search: '',
            brand_uuid: '',
            model_uuid: '',
            year_of_production_start: '',
            year_of_production_end: '',
            category_uuid: '',
          }}
          onSubmit={handleOnSubmit}
        >
          {({ handleSubmit, errors, setFieldValue }) => (
            <>
              <form onSubmit={handleSubmit}>
                <div className="search-parts__form--wrapper search-parts--top">
                  <div className="regular-field__icon--wrapper search-parts__country">
                    <Field
                      className={`add-advertisement__select ${userDeliveryCountry ? 'country-select--disabled' : ''}`}
                      component={SelectField}
                      placeholder={formatMessage({ id: 'inputs.choose' })}
                      label={formatMessage({ id: 'global.delivery_country' })}
                      errors={errors}
                      name="country_uuid"
                      options={deliveryCountryOptions(deliveryCountries, formatMessage)}
                      isCustomLabel
                    />
                  </div>
                  <div className="search-parts__loupCategory">
                    <div className="regular-field__icon--wrapper">
                      <Field
                        className="regular-field__padding--left"
                        component={RegularField}
                        errors={errors}
                        name="search"
                        search
                      />
                      <SVG className="regular-field__icon--left svg--loupe--orange" type={SVG_TYPE.LOUPE} />
                    </div>
                    <Field
                      component={SelectField}
                      placeholder={formatMessage({ id: 'inputs.choose' })}
                      errors={errors}
                      name="category_uuid"
                      options={categoryOptions()}
                      isCustomLabel
                    />
                  </div>
                </div>
                <div className="search-parts__form--wrapper search-parts--mid">
                  <Field
                    component={SelectField}
                    placeholder={formatMessage({ id: 'inputs.choose' })}
                    label={formatMessage({ id: 'global.brand' })}
                    errors={errors}
                    name="brand_uuid"
                    options={carBrandsOptions(carBrands)}
                    onChange={e => getModelsByBrand(e, setFieldValue)}
                    isCustomLabel
                  />
                  <Field
                    className={`${!isModelOn ? 'select--disabled' : ''}`}
                    component={SelectField}
                    placeholder={formatMessage({ id: 'inputs.choose' })}
                    label={formatMessage({ id: 'inputs.model' })}
                    options={carModelsOptions(carModels)}
                    errors={errors}
                    name="model_uuid"
                  />
                </div>
                <div className="search-parts__form--wrapper search-parts--bot">
                  <div className='search-parts__form--wrapper search-parts__form--half'>
                    <Field
                      component={RegularField}
                      label={formatMessage({ id: 'global.year_production' })}
                      placeholder={formatMessage({ id: 'inputs.from' })}
                      errors={errors}
                      name="year_of_production_start"
                    />
                    <Field
                      component={RegularField}
                      errors={errors}
                      name="year_of_production_end"
                      placeholder={formatMessage({ id: 'inputs.to' })}
                    />
                  </div>
                  <div className="search-parts--buttons">
                    <Button
                      className="btn--orange-border"
                      onClick={() => resetFormik(setFieldValue)}
                      label={`${formatMessage({ id: 'inputs.clear_filters' })}`}
                    />
                    <Button className="btn--orange" label={formatMessage({ id: 'inputs.search' })} type="submit" />
                  </div>
                </div>
              </form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SearchParts;

SearchParts.propTypes = {
  authorizedUser: object.isRequired,
  carBrands: array.isRequired,
  carModels: array.isRequired,
  categories: object.isRequired,
  country: string.isRequired,
  deliveryCountries: array.isRequired,
  formatMessage: func.isRequired,
  getCarModels: func.isRequired,
  translation: string.isRequired,
  // getProductsByFilter: func.isRequired,
};

import * as Connector from 'shared/connectors/roomsConnector';
import { translationToCountryLabel } from 'shared/utils';
import store from 'store/store';
import { ROOM } from 'store/types';

const init = () => ({
  type: ROOM.CREATE_ROOM_INIT,
});

export const success = data => ({
  type: ROOM.CREATE_ROOM_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: ROOM.CREATE_ROOM_FAILURE,
});

const createRoom = user => async dispatch => {
  const translation = localStorage.getItem('translation');
  const notLoggedInUserActiveChat = localStorage.getItem('chatOpenUuid');
  const deliveryCountry = await store.getState().deliveryStore.deliveryCountries.data
  const country_uuid = deliveryCountry && deliveryCountry.find(item => item.label === translationToCountryLabel(translation)).value
  try {
    dispatch(init());
    const { data } = await Connector.createRoom({
      country_of_delivery_uuid: country_uuid
    });
    if (!notLoggedInUserActiveChat) {
      !user && localStorage.setItem('chatOpenUuid', data.room.uuid);
    }
    dispatch(success(data));
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default createRoom;

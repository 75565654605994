import * as Connector from 'shared/connectors/productsConnector';
import { PRODUCTS } from 'store/types';

const init = () => ({
  type: PRODUCTS.GET_PRODUCTS_BY_CATEGORY_INIT,
});

export const success = data => ({
  type: PRODUCTS.GET_PRODUCTS_BY_CATEGORY_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: PRODUCTS.GET_PRODUCTS_BY_CATEGORY_FAILURE,
});

const getProductsByCategory = (uuid, lang, user_uuid) => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getProductsByCategory(uuid, lang, user_uuid);
    dispatch(success(data.category));
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default getProductsByCategory;

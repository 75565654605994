import React, { useState } from 'react';
import { func, bool, string, object } from 'prop-types';
import { Button } from 'shared/components';
import { DeliveryMenu, AddCourierModal } from 'screens/delivery-options/components';

const Header = ({ menuType, setMenuType, isMobile, formatMessage, addDeliveryMethod, deliveryCountries, userInfo }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };
  return (
    <>
      <h1 className="heading">{formatMessage({ id: 'global.delivery_options' })}</h1>
      <div className="delivery-options__languages">
        <DeliveryMenu
          deliveryCountries={deliveryCountries}
          deliveryCountry={userInfo}
          menuType={menuType}
          setMenuType={setMenuType}
          isMobile={isMobile}
        />
      </div>
      {!isMobile && (
        <>
          <div className="content-top">
            <div className="empty" />
            <p className="paragraph paragraph--label">{formatMessage({ id: 'global.label' })}</p>
            <p className="paragraph paragraph--price">{formatMessage({ id: 'global.price' })}</p>
            <p className="paragraph paragraph--label">{formatMessage({ id: 'global.is_worldwide' })}</p>
            <p className="add">
              <Button
                onClick={() => openModal()}
                label={formatMessage({ id: 'inputs.add_plus' })}
                className="btn--gray categoryManagement__header--button"
              />
            </p>
          </div>
        </>
      )}
      {isMobile && (
        <div className="delivery-options__btn">
          <Button
            onClick={() => openModal()}
            label={formatMessage({ id: 'inputs.add_plus' })}
            className="btn--gray categoryManagement__header--button"
          />
        </div>
      )}
      <AddCourierModal
        setMenuType={setMenuType}
        userInfo={userInfo}
        deliveryCountries={deliveryCountries}
        addDeliveryMethod={addDeliveryMethod}
        formatMessage={formatMessage}
        isOpened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        mobile={isMobile}
      />
    </>
  );
};

export default Header;

Header.propTypes = {
  addDeliveryMethod: func.isRequired,
  deliveryCountries: object.isRequired,
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
  menuType: string.isRequired,
  setMenuType: func.isRequired,
  userInfo: object.isRequired,
};

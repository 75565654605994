import React from 'react';
import { func, array, bool, string, object } from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

const Settings = ({ formatMessage, id, data, settingsType, setSettingsType, options, setOpenModal }) => {
  const handleClickSettings = () => {
    setSettingsType({ type: 'settings', id, sale_status: data.sale_status });
  };
  const isSellStatusCanceled = data.sale_status === formatMessage({ id: 'global.recalled2' });
  const isSellStatusAllegro = data.sale_status === formatMessage({ id: 'global.allegro' });
  const isStoreManOrCoordinator = data.owner?.role === 'storeman' || data.owner?.role === 'coordinator';
  const isTransfer = !!data.export_country_id
  const checkMethod = option => {
    if (isSellStatusCanceled) {
      return option.type === 'resume';
    }
    return option.type !== 'resume';
  };

  return (
    <div className="user-management__table__settings">
      <div
        className={`user-management__table__settings--item ${
          settingsType.id === id && settingsType.sale_status === data.sale_status
            ? 'user-management__table__settings--item--disabled'
            : ''
        } `}
        onClick={() => handleClickSettings()}
      >
        {((data.sale_status_link && !isSellStatusAllegro) || isTransfer) && <SVG type={SVG_TYPE.SETTINGS} />}
      </div>
      {settingsType.type === 'settings' && settingsType.id === id && settingsType.sale_status === data.sale_status && (
        <OutsideClickHandler display="flex" onOutsideClick={() => setSettingsType({ type: '' })}>
          <div className="user-management__table__settings__list price-management__settings" id={id}>
            {options
              .filter(option => checkMethod(option))
              .map(({ type, message, modalType }) => (
                <div
                  className="user-management__table__settings__list--item"
                  onClick={() => {
                    setSettingsType({ type, data });
                    setOpenModal({ isOpened: modalType, data });
                  }}
                >
                  {formatMessage({ id: message })}
                </div>
              ))}
            {isStoreManOrCoordinator && (
              <div
                className="user-management__table__settings__list--item"
                onClick={() => {
                  setSettingsType({ type: 'transfer', data });
                  setOpenModal({ isOpened: 'add-transfer', data });
                }}
              >
                {formatMessage({ id: 'global.transfer' })}
              </div>
            )}
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default Settings;

Settings.propTypes = {
  data: object.isRequired,
  deliveryCountries: array.isRequired,
  formatMessage: func.isRequired,
  id: string.isRequired,
  isMobile: bool.isRequired,
  options: array.isRequired,
  remove: bool.isRequired,
  setOpenModal: func.isRequired,
  setSettingsType: func.isRequired,
  settingsType: string.isRequired,
};

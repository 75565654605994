import React, { useState } from 'react';
import { SVG, BasketModal } from 'shared/components';
import { SVG_TYPE, ROUTES } from 'shared/consts';
import { func, bool, object } from 'prop-types';
import { string } from 'yup/lib/locale';
import moment from 'moment';

const Basket = ({
  authorizedUser,
  formatMessage,
  mobile,
  shoppingCart,
  modifyShoppingCart,
  translation,
  createCheckoutSession,
  cancelCheckout,
}) => {
  const [isBasketOpen, setIsBasketOpen] = useState(false);
  const CheckoutUuid = localStorage.getItem('checkoutUuid');
  const unpaidOrderEndTime = localStorage.getItem('orderEndTime');
  const isPaymentCanceled = window.location.pathname === ROUTES.PAYMENT_CANCELED;
  const isUnpaidOrder = CheckoutUuid && unpaidOrderEndTime && !isPaymentCanceled;
  const currentTime = moment().format('HH:mm');

  if (currentTime > unpaidOrderEndTime) {
    localStorage.removeItem('checkoutUuid');
    localStorage.removeItem('orderEndTime');
  }

  const openBasket = () => {
    setIsBasketOpen(!isBasketOpen);
  };
  return (
    <div className={`basket__wrapper ${mobile ? 'basket__wrapper--mobile' : ''}`}>
      <div
        id="basket"
        className={`basket ${isBasketOpen ? 'basket--disable' : ''} ${isUnpaidOrder ? 'basket--unpaid' : ''}`}
        onClick={() => openBasket()}
      >
        {shoppingCart && shoppingCart?.products_amount > 0 && (
          <div className="basket__count--wrapper">
            <p className="basket__count">{shoppingCart.products_amount}</p>
          </div>
        )}
        <SVG
          className={`basket--img ${isBasketOpen ? 'basket--img--active' : ''} ${isUnpaidOrder ? 'basket--img--unpaid' : ''}`}
          type={SVG_TYPE.BASKET}
        />
      </div>
      <BasketModal
        createCheckoutSession={createCheckoutSession}
        isUnpaidOrder={isUnpaidOrder}
        authorizedUser={authorizedUser}
        modifyShoppingCart={modifyShoppingCart}
        data={shoppingCart}
        mobile={mobile}
        formatMessage={formatMessage}
        isOpened={isBasketOpen}
        onClose={setIsBasketOpen}
        translation={translation}
        cancelCheckout={cancelCheckout}
      />
    </div>
  );
};

export default Basket;

Basket.defaultProps = {
  mobile: false,
};
Basket.propTypes = {
  authorizedUser: object.isRequired,
  cancelCheckout: func.isRequired,
  createCheckoutSession: func.isRequired,
  formatMessage: func.isRequired,
  mobile: bool,
  modifyShoppingCart: func.isRequired,
  shoppingCart: object.isRequired,
  translation: string.isRequired,
};

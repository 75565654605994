import { array, func, string } from 'prop-types';
import React from 'react';
import { SVG_TYPE } from 'shared/consts';
import { SVG } from 'shared/components';

const Product = ({ data, currency, formatMessage }) => {
  const { files, title, quantity, price } = data;
  const countTotalPrice = () => (quantity * parseInt(price, 10)).toFixed(2).toString().replace('.', ',');
  return (
    <div className="order-item__product__wrapper">
      {data?.files && data?.files.length > 0 && data.files[0] ? (
        <img className="order-item__product__img" src={files[0]?.path} alt={title} />
      ) : (
        <SVG className="order-item__product__img" type={SVG_TYPE.DEFAULT_PHOTO} />
      )}
      <p className="order-item__product__title">{title}</p>
      <p className="order-item__product__count">
        {quantity} x {price} <span className="currency">{currency}</span>
      </p>
      <p className="order-item__product__total order-item--price">
        {countTotalPrice()} <span className="currency">{currency}</span>{' '}
        <span className="order-item__product__total--brutto">{formatMessage({ id: 'global.brutto' })}</span>
      </p>
    </div>
  );
};

export default Product;
Product.propTypes = {
  currency: string.isRequired,
  data: array.isRequired,
  formatMessage: func.isRequired,
};

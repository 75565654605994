const SummaryFixed = () => {
  function checkOffset() {
    const summary = document.getElementById('summary');
    const summaryBox = document.getElementById('summary-box');
    const footer = document.getElementById('footer');
    function getRectTop(el) {
      const rect = el && el.getBoundingClientRect();
      return el && rect.top;
    }
    if (summaryBox) {
      if (
        getRectTop(summaryBox) + document.body.scrollTop + summaryBox.offsetHeight >=
        getRectTop(footer) + document.body.scrollTop - 10
      ) {
        if (summaryBox.classList.contains('right--second')) {
          summary.classList.add('right__wrapper--checkout-footer');
        } else {
          summary.classList.add('right__wrapper--footer');
        }
      }
      if (document.body.scrollTop + window.innerHeight < getRectTop(footer) + document.body.scrollTop) {
        if (summaryBox.classList.contains('right--second')) {
          summary.classList.remove('right__wrapper--checkout-footer');
        } else {
          summary.classList.remove('right__wrapper--footer');
        }
      }
    }
  }
  document.addEventListener('scroll', () => {
    checkOffset();
  });
  return true;
};

export default SummaryFixed;

import * as Connector from 'shared/connectors/productsConnector';



const getInvoiceFromUuid = (checkout_uuid) => async () => {
  try {
      const data = await Connector.getInvoiceFromUuid(checkout_uuid);
    return data;
  } catch (err) {
    return err.response;
  }
};

export default getInvoiceFromUuid;

import * as Connector from 'shared/connectors/customTranslationsConnector';
import { CUSTOM_TRANSLATIONS } from 'store/types';

export const success = data => ({
  type: CUSTOM_TRANSLATIONS.GET_CUSTOM_TRANSLATIONS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: CUSTOM_TRANSLATIONS.GET_CUSTOM_TRANSLATIONS_FAILURE,
});

const getCustomTranslations = params => async dispatch => {
  const { country_uuid } = params;
  try {
    const { data } = await Connector.getCustomTranslations({ country_uuid});
    dispatch(success(data));
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default getCustomTranslations;

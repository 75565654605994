import React from 'react';
import { func, object, string } from 'prop-types';

const ProductDescript = ({ formatMessage, data, categories, translation, carBrands, carModels }) => {
  const currentCategory = categories?.category?.find(item => item.uuid === data.category_uuid);
  const currentSubCategory = currentCategory?.subcategories?.find(item => item.uuid === data.subcategory_uuid);
  const currentCarBrand = carBrands&& carBrands.find(item => item.label === data.brand_uuid);
  const currentCarModel = carModels && carModels.find(item => item.label === data.model_uuid);
  const { year_of_production, brand_uuid, model_uuid, color, condition } = data;
  return (
    <div className="product-descript">
      <h2 className="product-descript--heading">{formatMessage({ id: 'global.details' })}</h2>
      <p className="product-descript--nrKat" />
      <div className="product-descript--lists">
        <ul className="product-descript__list product-descript__list--left">
          {currentCategory && <li>{formatMessage({ id: 'global.category' })}</li>}
          {currentSubCategory && <li>{formatMessage({ id: 'global.subcategory' })}</li>}
          {brand_uuid && <li>{formatMessage({ id: 'global.brand' })}</li>}
          {model_uuid && <li>{formatMessage({ id: 'global.model' })}</li>}
          {year_of_production && <li>{formatMessage({ id: 'global.year_production' })}</li>}
          {color && <li>{formatMessage({ id: 'global.color' })}</li>}
          {condition && <li>{formatMessage({ id: 'global.condition' })}</li>}
        </ul>
        <ul className="product-descript__list product-descript__list--right">
          {currentCategory && <li>{translation === 'pl' ? currentCategory?.name : currentCategory?.name_ger}</li>}
          {currentSubCategory && <li>{translation === 'pl' ? currentSubCategory?.name : currentSubCategory?.name_ger}</li>}
          {brand_uuid && <li>{currentCarBrand.value}</li>}
          {model_uuid && <li> {currentCarModel.value}</li>}
          {year_of_production && <li>{year_of_production}</li>}
          {color && <li>{formatMessage({ id: `global.${data.color}` })}</li>}
          {condition && <li>{formatMessage({ id: `global.${data.condition}_condition` })}</li>}
        </ul>
      </div>
    </div>
  );
};

export default ProductDescript;

ProductDescript.propTypes = {
  carBrands: object.isRequired,
  carModels: object.isRequired,
  categories: object.isRequired,
  data: object.isRequired,
  formatMessage: func.isRequired,
  translation: string.isRequired,
};

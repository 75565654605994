const LANGUAGE_TYPE = [
  {
    value: 'pl',
    name: 'pl',
    label: 'PL',
  },
  {
    value: 'de',
    name: 'de',
    label: 'DE',
  },
];

export default LANGUAGE_TYPE;

import React from 'react';
import { bool, func } from 'prop-types';
// import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Modal, ModalActions } from 'shared/components';
import { MODAL_TYPE } from 'shared/consts';

const CancelAdvertisement = ({ isModalOpened, setIsModalOpened, formatMessage }) => {
  const close = () => {
    setIsModalOpened(false);
  };
  return (
    <div>
      {isModalOpened && (
        <Modal className={MODAL_TYPE.CANCEL} onClose={close} opened={isModalOpened}>
          <div>
            <div className="modal__heading modal__heading--center">
              {formatMessage({ id: 'global.cancel_edit_addvertisement' })}
            </div>
            <div className="modal__description modal__description--center modal__description--cancel">
              {formatMessage({ id: 'global.you_sure_cancel_edit_addvertisement' })}
            </div>
            <ModalActions formatMessage={formatMessage} type={MODAL_TYPE.CANCEL} onClose={close} />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default CancelAdvertisement;

CancelAdvertisement.propTypes = {
  formatMessage: func.isRequired,
  isModalOpened: bool.isRequired,
  setIsModalOpened: bool.isRequired,
};

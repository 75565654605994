const MENU_TYPES = formatMessage => ({
  PRODUCTS: formatMessage({ id: 'global.products_list' }),
  TRANSFERS: formatMessage({ id: 'global.transfers_list' }),
  ORDERS: formatMessage({ id: 'global.orders_list' }),
});

const menuItems = formatMessage => [
  {
    type: MENU_TYPES(formatMessage).PRODUCTS,
  },
  {
    type: MENU_TYPES(formatMessage).TRANSFERS,
  },
  {
    type: MENU_TYPES(formatMessage).ORDERS,
  },
];
const STATUS_OPTIONS =(formatMessage)=> [
  {
    value: 'unpaid',
    label: formatMessage({id: 'global.unpaid'}),
  },
  {
    value: 'paid',
    label: formatMessage({id: 'global.paid'}),
  },
  {
    value: 'return',
    label: formatMessage({id: 'global.return'}),
  },
  {
    value: 'canceled',
    label: formatMessage({id: 'global.canceled'}),
  },
];

const isSmallViewPort = window.innerWidth < 480; // needed for settings

const settings = {
  dots: false,
  arrows: false,
  slidesToShow: isSmallViewPort ? 2 : 3,
};

export { menuItems, isSmallViewPort, settings, MENU_TYPES ,STATUS_OPTIONS};

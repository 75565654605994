import { PRODUCTS } from 'store/types';
import { REQUEST_STATUS } from 'shared/consts';

const INITIAL_STATE = {
  products: {
    data: [],
  },
  productsByFilter: {
    data: [],
  },
  singleProduct: {
    data: {},
  },
  favoriteProducts: {
    data: [],
  },
  myAdvertisements: {
    data: [],
  },
  advertisementsCount: {
    data: {},
  },
  popularBrands: {
    data: {},
  },
  transfers: {
    data: [],
  },
  categoriesOptions: {
    data: [],
  },
  productPreview: {
    data: {},
  },
};

const productsReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case PRODUCTS.GET_POPULAR_BRANDS_INIT: {
      return {
        ...state,
        popularBrands: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case PRODUCTS.GET_POPULAR_BRANDS_SUCCESS: {
      return {
        ...state,
        popularBrands: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PRODUCTS.GET_POPULAR_BRANDS_FAILURE: {
      return {
        ...state,
        popularBrands: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case PRODUCTS.GET_ALL_PRODUCTS_INIT: {
      return {
        ...state,
        products: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case PRODUCTS.GET_ALL_PRODUCTS_SUCCESS: {
      return {
        ...state,
        products: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PRODUCTS.GET_ALL_PRODUCTS_FAILURE: {
      return {
        ...state,
        products: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case PRODUCTS.CLEANING_ALL_PRODUCTS: {
      state.products.data.forEach(item => {
        item.subcategories.forEach(element => {
          element.products = [];
        });
      });
      return {
        ...state,
        products: {
          data: [...state.products.data],
        },
      };
    }
    case PRODUCTS.REMOVE_FROM_FAVORITE_SUCCESS: {
      const { uuid, categoryId, subcategoryId } = payload;
      const category = state.products.data.find(item => item.id === categoryId);
      const subCategory = category.subcategories.find(item => item.id === subcategoryId);
      const product = subCategory.products.find(item => item.uuid === uuid);
      product.is_favorite = false;
      return {
        ...state,
        products: {
          data: [...state.products.data],
        },
      };
    }

    case PRODUCTS.DELETE_FOLLOWED_PRODUCT_SUCCESS: {
      const { uuid } = payload;
      return {
        ...state,
        favoriteProducts: {
          data: [...state.favoriteProducts.data.filter(item => item.uuid !== uuid)],
        },
      };
    }

    case PRODUCTS.ADD_TO_FAVORITE_SUCCESS: {
      const { uuid, categoryId, subcategoryId } = payload;
      const category = state.products.data.find(item => item.id === categoryId);
      console.log(category);
      const subCategory = category.subcategories.find(item => item.id === subcategoryId);
      const product = subCategory.products.find(item => item.uuid === uuid);
      product.is_favorite = true;
      return {
        ...state,
        products: {
          data: [...state.products.data],
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PRODUCTS.GET_PRODUCTS_BY_SUB_CATEGORY_INIT: {
      return {
        ...state,
        products: {
          data: [],
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case PRODUCTS.GET_PRODUCTS_BY_SUB_CATEGORY_SUCCESS: {
      const { data, subCategoryId, categoryId } = payload;
      const category = data.find(item => item.id === categoryId);
      const subCategories = category.subcategories.find(item => item.id === subCategoryId);
      const newProducts = subCategories.products;
      if (state.products.data.length > 0) {
        const newState = state.products.data;
        const newStateCategory = newState.find(item => item.id === categoryId);
        const newStateSubcategory = newStateCategory.subcategories.find(item => item.id === subCategoryId);
        newStateSubcategory.products.push(...newProducts);
        return {
          ...state,
          products: {
            data: [...state.products.data],
            status: REQUEST_STATUS.SUCCESS,
          },
        };
      }
      return {
        ...state,
        products: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }

    case PRODUCTS.GET_PRODUCTS_BY_FILTER_SUCCESS: {
      const { data, subcategory_uuid, category_uuid } = payload;
      const category = category_uuid && data.find(item => item.uuid === category_uuid);
      if (subcategory_uuid) {
        if (state.products.data.length === 0) {
          return {
            ...state,
            products: {
              data: payload.data,
              status: REQUEST_STATUS.SUCCESS,
            },
          };
        }
        const subCategories = category.subcategories.find(item => item.uuid === subcategory_uuid);
        const subCategoriesProductCount = subCategories.products_count;
        const newProducts = subCategories.products;
        if (state.products.data.length > 0) {
          const newState = state.products.data;
          const newStateCategory = newState.find(item => item.uuid === category_uuid);
          const newStateSubcategory = newStateCategory.subcategories.find(item => item.uuid === subcategory_uuid);
          newStateSubcategory.products_count = subCategoriesProductCount;
          newStateSubcategory.products.push(...newProducts);
          return {
            ...state,
            products: {
              data: [...state.products.data],
              status: REQUEST_STATUS.SUCCESS,
            },
          };
        }
      }
      return {
        ...state,
        products: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }

    case PRODUCTS.GET_PRODUCTS_BY_SUB_CATEGORY_FAILURE: {
      return {
        ...state,
        products: {
          data: [],
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case PRODUCTS.GET_PRODUCTS_BY_CATEGORY_INIT: {
      return {
        ...state,
        products: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case PRODUCTS.GET_PRODUCTS_BY_CATEGORY_SUCCESS: {
      return {
        ...state,
        products: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PRODUCTS.GET_PRODUCTS_BY_CATEGORY_FAILURE: {
      return {
        ...state,
        products: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case PRODUCTS.SUBTRUCT_PRODUCTS_BY_SUB_CATEGORY_SUCCESS: {
      // console.log('usuwamy')
      const { categoryId, name } = payload;
      const newState = state.products.data;
      const category = newState.filter(item => item.id === categoryId);
      const newSubcategories = category[0].subcategories.filter(item => item.link === name);
      newSubcategories[0].products = [];
      return {
        ...state,
        products: {
          data: [...newState],
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PRODUCTS.GET_SINGLE_PRODUCT_INIT: {
      return {
        ...state,
        singleProduct: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case PRODUCTS.GET_SINGLE_PRODUCT_SUCCESS: {
      return {
        ...state,
        singleProduct: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PRODUCTS.MARK_SINGLE_PRODUCT_FAVORITE_SUCCESS: {
      console.log(state.singleProduct.data.is_favorite);
      state.singleProduct.data.is_favorite = true;
      return {
        ...state,
        singleProduct: {
          data: { ...state.singleProduct.data },
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PRODUCTS.UNMARK_SINGLE_PRODUCT_FAVORITE_SUCCESS: {
      state.singleProduct.data.is_favorite = false;
      return {
        ...state,
        singleProduct: {
          data: { ...state.singleProduct.data },
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PRODUCTS.GET_SINGLE_PRODUCT_FAILURE: {
      return {
        ...state,
        singleProduct: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case PRODUCTS.GET_PRODUCTS_BY_FILTER_INIT: {
      return {
        ...state,
        productsByFilter: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case PRODUCTS.GET_PRODUCTS_BY_FILTER_FAILURE: {
      return {
        ...state,
        productsByFilter: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case PRODUCTS.GET_FAVORITE_PRODUCTS_INIT: {
      return {
        ...state,
        favoriteProducts: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case PRODUCTS.GET_FAVORITE_PRODUCTS_SUCCESS: {
      return {
        ...state,
        favoriteProducts: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PRODUCTS.GET_FAVORITE_PRODUCTS_FAILURE: {
      return {
        ...state,
        favoriteProducts: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case PRODUCTS.GET_MY_ADVERTISEMENTS_INIT: {
      return {
        ...state,
        myAdvertisements: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case PRODUCTS.GET_MY_ADVERTISEMENTS_SUCCESS: {
      return {
        ...state,
        myAdvertisements: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PRODUCTS.GET_MY_ADVERTISEMENTS_FAILURE: {
      return {
        ...state,
        myAdvertisements: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case PRODUCTS.GET_ADVERTISEMENTS_COUNT_INIT: {
      return {
        ...state,
        advertisementsCount: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case PRODUCTS.GET_ADVERTISEMENTS_COUNT_SUCCESS: {
      return {
        ...state,
        advertisementsCount: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PRODUCTS.GET_ADVERTISEMENTS_COUNT_FAILURE: {
      return {
        ...state,
        advertisementsCount: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case PRODUCTS.REMOVE_ADVERTISEMENT_SUCCESS: {
      const { uuid } = payload;
      const filtered = state.myAdvertisements.data.own_products.filter(advertisement => advertisement.uuid !== uuid);
      state.myAdvertisements.data.own_products = filtered;
      return {
        ...state,
        myAdvertisements: {
          data: { ...state.myAdvertisements.data },
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PRODUCTS.GET_TRANSFERS_INIT: {
      return {
        ...state,
        transfers: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case PRODUCTS.GET_TRANSFERS_SUCCESS: {
      return {
        ...state,
        transfers: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PRODUCTS.GET_TRANSFERS_FAILURE: {
      return {
        ...state,
        transfers: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case PRODUCTS.GET_CATEGORIES_OPTIONS_INIT: {
      return {
        ...state,
        categoriesOptions: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case PRODUCTS.GET_CATEGORIES_OPTIONS_SUCCESS: {
      return {
        ...state,
        categoriesOptions: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PRODUCTS.GET_CATEGORIES_OPTIONS_FAILURE: {
      return {
        ...state,
        categoriesOptions: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case PRODUCTS.EDIT_TRANSFER_SUCCESS: {
      const {
        data: { uuid, status },
      } = payload;
      const properItem = state.transfers.data.find(transfers => transfers.uuid === uuid);
      properItem.status = status;
      const updatedState = state.transfers.data;
      state.transfers.data = updatedState;
      return {
        ...state,
        transfers: {
          data: [...state.transfers.data],
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PRODUCTS.DELETE_PRODUCT_WAREHOUSE_SUCCESS: {
      const { uuid } = payload;
      return {
        ...state,
        products: {
          data: [...state.products.data.filter(product => product.uuid !== uuid)],
        },
      };
    }
    case PRODUCTS.RECALL_THE_PRODUCT_SUCCESS: {
      const { uuid } = payload;
      const edittedProduct = state.products.data.find(product => product.uuid === uuid);
      edittedProduct.stock.canceled = 1;
      const updatedState = state.products.data;
      state.products.data = updatedState;
      return {
        ...state,
        products: {
          data: [...state.products.data],
        },
      };
    }
    case PRODUCTS.EDIT_PRODUCT_WAREHOUSE_SUCCESS: {
      const {
        uuid,
        values: { amount, category_uuid },
      } = payload;
      const updatedItems = state.products.data.map(product => {
        if (product.uuid !== uuid) {
          return product;
        }
        const updatedItem = { ...product, amount, category_uuid };
        return updatedItem;
      });
      return {
        ...state,
        products: {
          data: [...updatedItems],
        },
      };
    }
    case PRODUCTS.RESUME_THE_PRODUCTS_SUCCESS: {
      const { uuid } = payload;
      const updatedItems = state.products.data.map(product => {
        if (product.uuid !== uuid) {
          return product;
        }
        const updatedItem = { ...product, stock: { ...product.stock, canceled: 0 } };
        return updatedItem;
      });
      return {
        ...state,
        products: {
          data: [...updatedItems],
        },
      };
    }
    case PRODUCTS.ADD_PRODUCT_PREVIEW_INIT: {
      return {
        ...state,
        productPreview: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case PRODUCTS.ADD_PRODUCT_PREVIEW_SUCCESS: {
      return {
        ...state,
        productPreview: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PRODUCTS.ADD_PRODUCT_PREVIEW_FAILURE: {
      return {
        ...state,
        productPreview: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case PRODUCTS.RESUME_MY_PRODUCT_SUCCESS: {
      const { uuid } = payload;
      const updatedItems = state.myAdvertisements.data.map(product => {
        if (product.uuid !== uuid) {
          return product;
        }
        const updatedItem = { ...product, status: 'active' };
        return updatedItem;
      });
      return {
        ...state,
        myAdvertisements: {
          data: [...updatedItems],
        },
      };
    }
    case PRODUCTS.ADD_TRANSFER_SUCCESS: {
      const { product_uuid, amount } = payload.data;
      const updatedItems = state.products.data.map(product => {
        if (product.uuid !== product_uuid) {
          return product;
        }
        const updatedItem = { ...product, stock: { ...product.stock, in_stock: product.stock.in_stock - amount } };
        return updatedItem;
      });
      return {
        ...state,
        products: {
          data: [...updatedItems],
        },
      };
    }
    case PRODUCTS.RECALL_MY_PRODUCT_SUCCESS: {
      const { uuid } = payload;
      const updatedItems = state.myAdvertisements.data.map(product => {
        if (product.uuid !== uuid) {
          return product;
        }
        const updatedItem = { ...product, status: 'canceled' };
        return updatedItem;
      });
      return {
        ...state,
        myAdvertisements: {
          data: [...updatedItems],
        },
      };
    }
    default:
      return state;
  }
};

export default productsReducer;

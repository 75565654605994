import { USER } from 'store/types';
import { REQUEST_STATUS } from 'shared/consts';

const INITIAL_STATE = {
  usersByRole: {
    data: {},
    status: REQUEST_STATUS.IDLE,
  },
  users: {
    data: {},
    status: REQUEST_STATUS.IDLE,
  },
  usersForApplication: {
    data: {},
    status: REQUEST_STATUS.IDLE,
  },
  userDetails: {
    data: {},
    status: REQUEST_STATUS.IDLE,
  },
};

const userReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case USER.GET_USER_DETAILS_INIT: {
      return {
        ...state,
        userDetails: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case USER.GET_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        userDetails: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case USER.GET_USER_DETAILS_FAILURE: {
      return {
        ...state,
        userDetails: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case USER.GET_USERS_FOR_APPLICATION_INIT: {
      return {
        ...state,
        usersForApplication: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case USER.GET_USERS_FOR_APPLICATION_SUCCESS: {
      return {
        ...state,
        usersForApplication: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case USER.GET_USERS_FOR_APPLICATION_FAILURE: {
      return {
        ...state,
        usersForApplication: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case USER.GET_USERS_INIT: {
      return {
        ...state,
        users: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case USER.GET_USERS_SUCCESS: {
      return {
        ...state,
        users: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case USER.GET_USERS_FAILURE: {
      return {
        ...state,
        users: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case USER.GET_USERS_BY_ROLE_INIT: {
      return {
        ...state,
        usersByRole: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case USER.GET_USERS_BY_ROLE_SUCCESS: {
      return {
        ...state,
        usersByRole: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case USER.GET_USERS_BY_ROLE_FAILURE: {
      return {
        ...state,
        usersByRole: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    default:
      return state;
  }
};

export default userReducer;

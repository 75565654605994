import React, { useEffect } from 'react';
import { func, array, object, bool, string } from 'prop-types';
import { Address, DeliveryGroup } from 'screens/checkout/components';
import { SVG_TYPE } from 'shared/consts';
import { InputCounter, SVG } from 'shared/components';
import { deliveryPriceCounting } from 'screens/checkout/utils';

const Left = ({
  formatMessage,
  customCheckoutAddress,
  shoppingCartOwner,
  authorizedUser,
  setTransactionData,
  setIsSubmitting,
  productsInTheBasket,
  modifyShoppingCart,
  isSubmitting,
  setDeliveryPrice,
  deliveryPrice,
  basket,
  simulateCheckout,
  setProductsInTheBasket,
  deliveryCountries,
  isMobile,
  translation,
}) => {
  const countryUuid = localStorage.getItem('deliveryCountry') || authorizedUser.country.label;

  const clickSingleDelivery = (e, product, deliveryUuid, sameDelivery, sellerProducts, delivery, foreign_country) => {
    setIsSubmitting(false);
    if (!e.target.classList.contains('delivery__item--active')) {
      const clone = e.target.cloneNode(true);
      const deliveryElements = e.target.parentNode.getElementsByClassName('delivery__item');
      for (let i = 0; i < deliveryElements.length; i++) {
        const el = deliveryElements[i];
        el.classList.remove('delivery__item--active', 'delivery__item--error');
      }
      e.target.classList.add('delivery__item--active');
      const findElement = productsInTheBasket.find(item => item.product_uuid === product.uuid);
      findElement.delivery_uuid = deliveryUuid;
      clone.classList.remove(`delivery__item--${deliveryUuid}`);
      clone.classList.add(`delivery__item--active`, 'delivery__item--deleted');
      e.target.parentNode.parentNode.prepend(clone.cloneNode(true));
      const productItem = document.getElementById(`product--${product.uuid}`);
      const error = productItem.getElementsByClassName('delivery__list--error--active');
      error.length > 0 && error[0].classList.remove('delivery__list--error--active');
      const deli = e.target.closest('.delivery');
      const list = deli.getElementsByClassName('delivery__list');
      const list_arrow = deli.getElementsByClassName('delivery__arrow');
      list[0].classList.remove('delivery__list--active');
      list_arrow[0].classList.remove('delivery__arrow--active');
      list_arrow[0].classList.add('delivery__arrow--show');
      list[0].classList.add('delivery__list--hidden-all');
      const sellerBox = document.getElementsByClassName(`seller-box--${product.user_id}`);
      setTimeout(() => {
        const uniqueDeliveryArray = [...new Map(sameDelivery.map(item => [item.id, item])).values()];
        uniqueDeliveryArray.forEach(item => {
          const elements = sellerBox.length > 0 && sellerBox[0].getElementsByClassName(`delivery__item--${item.uuid}`);
          const isActive = [];
          for (let i = 0; i < elements.length; i++) {
            const group = sellerBox[0].getElementsByClassName(`delivery__group--${item.uuid}`);
            const el = elements[i].classList.contains('delivery__item--active');
            if (!el) {
              group.length > 0 && group[0].classList.remove('delivery__item--active');
            } else {
              isActive.push(el);
            }
            if (elements.length === isActive.length) {
              group.length > 0 && group[0].classList.add('delivery__item--active');
            }
          }
        });
      }, 10);
      deliveryPriceCounting(setDeliveryPrice, deliveryPrice, delivery, product, foreign_country);
    }
  };

  useEffect(() => {
    deliveryPriceCounting(setDeliveryPrice, null);
    basket.products.forEach(item => {
      const product = document.getElementById(`product--${item.product_uuid}`);
      const oldDelivery = product?.getElementsByClassName('delivery__item--deleted');
      oldDelivery?.length >= 1 && oldDelivery[0]?.classList.add('delivery__item--hidden');
      setTimeout(() => {
        const delivery = product?.getElementsByClassName(`delivery__item--${item.delivery_uuid}`);
        if (delivery && delivery.length > 0 && delivery[0]) {
          delivery[0]?.classList.remove('delivery__item--active');
          delivery[0]?.click();
        }
      }, 100);
    });
  }, [basket.products.length]);

  return (
    <div className="left-side">
      <Address
        setIsSubmitting={setIsSubmitting}
        isSubmitting={isSubmitting}
        authorizedUser={authorizedUser}
        setTransactionData={setTransactionData}
        formatMessage={formatMessage}
        customCheckoutAddress={customCheckoutAddress || {}}
        basket={basket}
        simulateCheckout={simulateCheckout}
        deliveryCountries={deliveryCountries}
        isMobile={isMobile}
      />
      <div className="content">
        <div className="heading">{formatMessage({ id: 'global.offers_and_delivery_method' })}</div>
        {shoppingCartOwner?.map(item => {
          const { products, first_name, last_name, id, foreign_country } = item;
          const sameDelivery = [];
          const hasProduct = Object.keys(products, item).map(el => products[el].pivot_shopping_cart_amount > 0);
          const isItemInBasket = hasProduct.find(el => el === true);

          return (
            <>
              {isItemInBasket && (
                <div className={`seller-box seller-box--${id}`}>
                  <div className="seller-box--wrapper">
                    <div className="seller-box--name">
                      <p>{formatMessage({ id: 'global.seller' })}:</p>
                      <span>
                        {!foreign_country && first_name} {!foreign_country && last_name} {foreign_country && 'GETMOTOR'}{' '}
                      </span>
                    </div>
                    {Object.keys(products).map((key, index) => {
                      const { files, price, currency, title, delivery, title_ger } = products[key];
                      const triggerDeliveryMethod = e => {
                        if (!e.target.classList.contains('delivery__arrow--active')) {
                          const deliveryBox = e.target.closest('.delivery');
                          const checkDeliveryItem = deliveryBox.getElementsByClassName('delivery__item--active');
                          checkDeliveryItem.length > 0 && checkDeliveryItem[0].remove();
                          e.target.classList.add('delivery__arrow--active');
                          const deli = e.target.closest('.delivery');
                          const list = deli.getElementsByClassName('delivery__list');
                          list[0].classList.add('delivery__list--active');
                        } else {
                          const deliveryBox = e.target.closest('.delivery');
                          const checkDeliveryItem = deliveryBox.getElementsByClassName(
                            'delivery__item--active',
                            'delivery__item--deleted',
                          );
                          checkDeliveryItem.length > 0 && e.target.parentNode.prepend(checkDeliveryItem[0].cloneNode(true));
                          e.target.classList.remove('delivery__arrow--active');
                          const deli = e.target.closest('.delivery');
                          const list = deli.getElementsByClassName('delivery__list');
                          list[0].classList.remove('delivery__list--active');
                        }
                      };
                      return (
                        <>
                          {products[key].pivot_shopping_cart_amount > 0 && (
                            <>
                              <div
                                id={`product--${products[key].uuid}`}
                                className={`seller-box__product--wrapper  ${
                                  foreign_country ? 'seller-box__product--wrapper--foreign' : ''
                                }`}
                              >
                                <div className="seller-box__product--item">
                                  <div className="seller-box__product--image-box">
                                    {files.length > 0 && files[0] ? (
                                      <img
                                        className="seller-box__product--image"
                                        src={files.length > 0 && files[0] && files[0].path}
                                        alt=""
                                      />
                                    ) : (
                                      <SVG className="seller-box__product--image" type={SVG_TYPE.DEFAULT_PHOTO} />
                                    )}
                                  </div>
                                  <div className="seller-box__product--mid">
                                    <div className="seller-box__product--name">{translation === 'pl' ? title : title_ger}</div>
                                    <div className="seller-box__product--error-descript seller-box__product--error-amount">
                                      {formatMessage({ id: 'global.quantity_exceeded' })}:
                                    </div>
                                    <div className="seller-box__product--error-descript seller-box__product--error-unavailable">
                                      {formatMessage({ id: 'global.product_unavailable' })}
                                    </div>
                                  </div>
                                  <div className="seller-box__product__price--wrapper">
                                    <div className="seller-box__product__price">
                                      {price} {currency}
                                    </div>
                                    <InputCounter
                                      authorizedUser={authorizedUser}
                                      data={products[key]}
                                      modifyShoppingCart={modifyShoppingCart}
                                      productsInTheBasket={productsInTheBasket}
                                      setProductsInTheBasket={setProductsInTheBasket}
                                    />
                                    <div
                                      onClick={async () => {
                                        await modifyShoppingCart({
                                          country_uuid: countryUuid,
                                          product_uuid: products[key].uuid,
                                          update_option: 'delete',
                                          uuid: item.uuid,
                                          user_id: products[key].user_id,
                                        });
                                      }}
                                      className="delete"
                                    >
                                      {formatMessage({ id: 'inputs.delete' })}
                                    </div>
                                  </div>
                                </div>
                                {delivery.length > 0 && (
                                  <div className={`delivery ${foreign_country ? 'delivery--hidden' : ''}`}>
                                    <div className="delivery__list delivery__list--items">
                                      {delivery.map(element => {
                                        sameDelivery.push(element);
                                        return (
                                          <div
                                            title={element.uuid}
                                            className={`delivery__item ${
                                              foreign_country ? '' : 'delivery__with--error'
                                            } delivery__item--element ${
                                              delivery.length > 1 ? 'delivery__item--margin' : ''
                                            } delivery__item--${element.uuid}`}
                                            onClick={e => {
                                              clickSingleDelivery(
                                                e,
                                                products[key],
                                                element.uuid,
                                                sameDelivery,
                                                products,
                                                element,
                                                foreign_country,
                                              );
                                            }}
                                          >
                                            <div className="delivery__item__image--wrapper">
                                              <img
                                                className="delivery__item__image"
                                                src={element.files && element.files.length > 0 && element.files[0].path}
                                                alt=""
                                              />
                                            </div>
                                            <div className="delivery__item--label">{element.name}</div>
                                            <div className="delivery__item--price-wrapper">
                                              {element.price} {element.currency && element.currency.currency.toUpperCase()}
                                            </div>
                                          </div>
                                        );
                                      })}
                                      {!foreign_country && (
                                        <p className="delivery__list--error">
                                          {formatMessage({ id: 'global.choose_a_delivery_method' })}
                                        </p>
                                      )}
                                    </div>
                                    <div
                                      className={`delivery__arrow ${delivery.length <= 3 ? 'delivery__arrow--hidden' : ''}`}
                                      onClick={e => triggerDeliveryMethod(e)}
                                    >
                                      {delivery.length > 1 && <SVG type={SVG_TYPE.CHEVRONE} className="svg--mail--gray" />}
                                    </div>
                                  </div>
                                )}
                              </div>
                              {(foreign_country || Object.keys(products).length > 1) && (
                                <DeliveryGroup
                                  foreign_country={foreign_country}
                                  formatMessage={formatMessage}
                                  seller={item}
                                  index={index}
                                  products={products}
                                  sameDelivery={sameDelivery}
                                />
                              )}
                            </>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              )}
            </>
          );
        })}
      </div>
      <div className="right right--full">
        <div className="heading">{formatMessage({ id: 'global.payment_method' })}</div>
        <SVG type={SVG_TYPE.PRZELEWY_24} />
        <p className="text">{formatMessage({ id: 'global.to_complete_the_payment' })}</p>
      </div>
    </div>
  );
};

export default Left;

Left.propTypes = {
  authorizedUser: object.isRequired,
  basket: object.isRequired,
  customCheckoutAddress: object.isRequired,
  deliveryCountries: object.isRequired,
  deliveryPrice: object.isRequired,
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
  isSubmitting: bool.isRequired,
  modifyShoppingCart: func.isRequired,
  productsInTheBasket: array.isRequired,
  setDeliveryPrice: func.isRequired,
  setIsSubmitting: func.isRequired,
  setProductsInTheBasket: func.isRequired,
  setTransactionData: func.isRequired,
  shoppingCartOwner: array.isRequired,
  simulateCheckout: func.isRequired,
  transactionData: object.isRequired,
  translation: string.isRequired,
};

import React from 'react';
import * as Connector from 'shared/connectors/productsConnector';
import * as ConnectorUtils from 'shared/connectors/utilsConnector';
import { PRODUCTS } from 'store/types';
import { NOTIFY_TYPE } from 'shared/consts';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
// import { addFiles } from 'shared/connectors/utilsConnector';

const init = () => ({
  type: PRODUCTS.ADD_PRODUCTS_INIT,
});

export const success = data => ({
  type: PRODUCTS.ADD_PRODUCTS_SUCCESS,
  payload: data,
});

const failure = () => ({
  type: PRODUCTS.ADD_PRODUCTS_FAILURE,
});

const addProducts = (form, photos) => async dispatch => {
  dispatch(init());

  try {
    const {
      data: {
        product: { uuid },
      },
    } = await Connector.addProduct(form);
    if (uuid && photos.length > 0) {
      for (let i = 0; i < photos.length; i++) {
        const fileForm = new FormData();
        fileForm.append('file', photos[i]);
        // eslint-disable-next-line no-await-in-loop
        const sendFile = await ConnectorUtils.addFiles(fileForm, uuid, 'product_uuid=');
        console.log(sendFile);
        // dispatch(sendFile);
      }
    }
    dispatch(success(form));
    toast(<Notify label="global.successful_add_product" type={NOTIFY_TYPE.CHECKED} />);
    return uuid;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default addProducts;

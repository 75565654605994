import React from 'react';
import { func, object, string } from 'prop-types';

const ProductDescript = ({ formatMessage, data, categories, translation }) => {
  const currentCategory = categories?.category?.find(item => item.id === data.category_id);
  const currentSubCategory = currentCategory?.subcategories?.find(item => item.id === data.subcategory_id);
  return (
    <div className="product-descript">
      <h2 className="product-descript--heading">{formatMessage({ id: 'global.details' })}</h2>
      <p className="product-descript--nrKat" />
      <div className="product-descript--lists">
        <ul className="product-descript__list product-descript__list--left">
          {currentCategory?.name && currentCategory?.name_ger && <li>{formatMessage({ id: 'global.category' })}</li>}
          {currentSubCategory?.name && currentSubCategory?.name_ger && <li>{formatMessage({ id: 'global.subcategory' })}</li>}
          {data.brand && <li>{formatMessage({ id: 'global.brand' })}</li>}
          {data.model && <li>{formatMessage({ id: 'global.model' })}</li>}
          {data.year_of_production && <li>{formatMessage({ id: 'global.year_production' })}</li>}
          {data.color && <li>{formatMessage({ id: 'global.color' })}</li>}
          {data.condition && <li>{formatMessage({ id: 'global.condition' })}</li>}
        </ul>
        <ul className="product-descript__list product-descript__list--right">
          {currentCategory?.name && currentCategory?.name_ger && (
            <li>{translation === 'pl' ? currentCategory?.name : currentCategory?.name_ger}</li>
          )}
          {currentSubCategory?.name && currentSubCategory?.name_ger && (
            <li>{translation === 'pl' ? currentSubCategory?.name : currentSubCategory?.name_ger}</li>
          )}
          {data.brand && <li>{data.brand.length > 0 && data.brand[0].brand}</li>}
          {data.model && <li>{data.model.length > 0 && data.model[0].model}</li>}
          {data.year_of_production && <li>{data.year_of_production}</li>}
          {data.color && <li>{formatMessage({ id: `global.${data.color}` })}</li>}
          {data.condition && <li>{formatMessage({ id: `global.${data.condition}_condition` })}</li>}
        </ul>
      </div>
    </div>
  );
};

export default ProductDescript;

ProductDescript.propTypes = {
  categories: object.isRequired,
  data: object.isRequired,
  formatMessage: func.isRequired,
  translation: string.isRequired,
};

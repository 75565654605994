import * as Connector from 'shared/connectors/categoryConnector';
import { CATEGORY } from 'store/types';

// const init = () => ({
//   type: CATEGORY.GET_CATEGORY_BY_LINK_INIT,
// });

export const success = data => ({
  type: CATEGORY.GET_CATEGORY_BY_LINK_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: CATEGORY.GET_CATEGORY_BY_LINK_FAILURE,
});

const getCategoryByLink = slug => async dispatch => {
  // dispatch(init());
  try {
    if (slug) {
      const { data } = await Connector.getCategoryByLink(slug);
      dispatch(success(data));
      return data;
    }
    return false;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default getCategoryByLink;

import { connect } from 'react-redux';
import { isMobile as checkIsMobile } from 'shared/utils';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import {
  getDelivery,
  addDeliveryMethod,
  getDeliveryCountries,
  deleteDeliveryMethod,
  editDeliveryMethod,
} from 'store/actions/delivery';
import {
  getDelivery as getDeliverySelector,
  getDeliveryCountries as getDeliveryCountriesSelector,
} from 'store/selectors/deliverySelector';
import { getAuthorizedUser } from 'store/selectors/userSelector';
import { deleteFiles } from 'store/actions/utils';
import DeliveryOptions from './DeliveryOptions.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getDelivery: lang => getDelivery(lang),
      addDeliveryMethod: data => addDeliveryMethod(data),
      getDeliveryCountries: () => getDeliveryCountries(),
      editDeliveryMethod: data => editDeliveryMethod(data),
      deleteDeliveryMethod: uuid => deleteDeliveryMethod(uuid),
      deleteFiles: uuid => deleteFiles(uuid),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  isMobile: checkIsMobile(),
  delivery: getDeliverySelector(state),
  authorizedUser: getAuthorizedUser(state),
  deliveryCountries: getDeliveryCountriesSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DeliveryOptions));

import { createSelector } from 'reselect';

const getCategoryStore = state => state.categoryStore;
const getCategory = createSelector(getCategoryStore, ({ categories: { data = [] } }) => data);
const getCategoryAndSubcategory = createSelector(getCategoryStore, ({ categoriesAndSubcategories: { data = [] } }) => data);
const getSubcategoryForCategory = createSelector(getCategoryStore, ({ subcategoryForCategory: { data = [] } }) => data);
const getCategoryByLink = createSelector(getCategoryStore, ({ categoryByLink: { data = {} } }) => data);
const getPopularCategoriesSelector = createSelector(getCategoryStore, ({ popularCategories: { data = [] } }) => data);
// const getPopularCategoriesSelector = createSelector(getCategoryStore, ({ popularCategories: { data = [] } }) => data);
export { getPopularCategoriesSelector,getCategory, getSubcategoryForCategory, getCategoryAndSubcategory, getCategoryByLink };

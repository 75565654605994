import { func, object } from 'prop-types';
import React from 'react';
import { Button } from 'shared/components';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'shared/consts';

const Right = ({ formatMessage, data }) => {
  const history = useHistory();

  return (
    <div id="right-section--wrapper" className="right__wrapper">
      <div id="right-section" className="right">
        <div className="heading">{formatMessage({ id: 'global.to_pay' })}</div>
        <div className="line">
          <p className="paragraph">{formatMessage({ id: 'global.value_products' })}:</p>
          <p className="paragraph">
            {data.all_price} <span>{data.currency}</span>
          </p>
        </div>
        <div className="border-bottom" />
        <div className="line line--bold">
          <p className="paragraph">{formatMessage({ id: 'global.to_pay' })}</p>
          <p className="paragraph">
            {data.all_price} <span>{data.currency}</span>
          </p>
        </div>
        <div className="buttons">
          <Button
            className="btn--orange"
            label={formatMessage({ id: 'global.address_and_delivery_method' })}
            type="submit"
            onClick={() => history.push(ROUTES.CHECKOUT)}
          />
          <Button
            onClick={() => history.push(`${ROUTES.CATEGORY}?s=`)}
            className="btn--orange-border"
            label={formatMessage({ id: 'global.continue_shopping' })}
            type="submit"
          />
        </div>
      </div>
    </div>
  );
};

export default Right;

Right.propTypes = {
  data: object.isRequired,
  formatMessage: func.isRequired,
};

import * as Connector from 'shared/connectors/paymentsConnector';
import { PRICE } from 'store/types';

export const success = (data) => ({
  type: PRICE.ADD_INVOICE_DATA,
  payload: {
    data: {
      data
    },
  },
});

const addInvoiceData = form => async dispatch => {
  try {
    const { data } = await Connector.addInvoiceData(form);
    dispatch(success(data));
    return data;
  } catch (err) {
    return err.response;
  }
};

export default addInvoiceData;

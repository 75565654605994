import React from 'react';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import * as Connector from 'shared/connectors/productsConnector';
import { NOTIFY_TYPE } from 'shared/consts';
import { PRODUCTS } from 'store/types';

const init = () => ({
  type: PRODUCTS.ADD_TRANSFER_INIT,
});

export const success = data => ({
  type: PRODUCTS.ADD_TRANSFER_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: PRODUCTS.ADD_PRODUCTS_FAILURE,
});

const addTransfer = values => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.addTransfer(values);
    dispatch(success(values))
    toast(<Notify label="global.successful_add_transfer" type={NOTIFY_TYPE.CHECKED} />);
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default addTransfer;

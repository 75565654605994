import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetPassword } from 'store/actions/user';
import { injectIntl } from 'react-intl';
import * as ViewManagementSelector from 'store/selectors/viewManagementSelector';
import RemindPassword from './RemindPassword.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      resetPassword: (email, translation) => resetPassword(email, translation),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  translation: ViewManagementSelector.getTranslation(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RemindPassword));

const MENU_TYPES = formatMessage => ({
  ALL: formatMessage({ id: 'global.all' }),
  UNPAID: formatMessage({ id: 'global.unpaid' }),
  PAID: formatMessage({ id: 'global.paid' }),
  RETURNS: formatMessage({ id: 'global.returns' }),
  CANCELED: formatMessage({ id: 'global.canceled' }),
});

const STATUSES = formatMessage => [
  {
    label: MENU_TYPES(formatMessage).ALL,
    statuses: '',
  },
  {
    label: MENU_TYPES(formatMessage).UNPAID,
    statuses: 'unpaid',
  },
  {
    label: MENU_TYPES(formatMessage).PAID,
    statuses: 'paid',
  },
  {
    label: MENU_TYPES(formatMessage).RETURNS,
    statuses: 'returns',
  },
  {
    label: MENU_TYPES(formatMessage).CANCELED,
    statuses: 'canceled',
  },
];

export { MENU_TYPES, STATUSES };

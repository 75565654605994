import React from 'react';
import { func, object } from 'prop-types';
import { InputCounter, SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

const Left = ({ authorizedUser, shoppingCart, modifyShoppingCart, addToFavorites, removeFromFavorites, formatMessage }) => (
  <div className="left">
    {shoppingCart &&
      shoppingCart?.shopping_cart?.products?.map(item => {
        const addProductToFavorites = async () => {
          await addToFavorites(item.uuid);
        };

        const removeProductFromFavorites = async () => {
          await removeFromFavorites(item.uuid, item.category_id, item.subcategory_id);
        };
        const countryUuid = localStorage.getItem('deliveryCountry') || authorizedUser.country.label;

        return (
          <div className="product-item">
            <div className="product-item--left">
              {item.files && item.files.length > 0 && item.files[0] ? (
                <img
                  className="product-item--image"
                  src={item.files && item.files.length > 0 && item.files[0] && item.files[0].path}
                  alt=""
                />
              ) : (
                <SVG className="product-item--image" type={SVG_TYPE.DEFAULT_PHOTO} />
              )}
              <div className="text">
                <div className="head">{item.title}</div>
                <div className="description">
                  {formatMessage({ id: 'global.brand' })}: {item?.brand[0]?.brand}
                </div>
                <div className="description">
                  {formatMessage({ id: 'global.model' })}: {item?.model[0]?.model}
                </div>
              </div>
            </div>
            <div className="product-item--right">
              <div className="price">
                {item.price}
                <span className="currency">{item.currency}</span>
              </div>
              <InputCounter authorizedUser={authorizedUser} data={item} modifyShoppingCart={modifyShoppingCart} />
              <div className="actions">
                <div className="product-name--watching">
                  <div
                    className={`watch ${!authorizedUser ? 'watch--disabled' : ''}`}
                    onClick={item.is_favorite ? removeProductFromFavorites : addProductToFavorites}
                  >
                    {formatMessage({ id: 'inputs.watch' })}
                    <SVG className="icon" type={item.is_favorite ? SVG_TYPE.FULL_FILL_HEART : SVG_TYPE.FULL_HEART} />
                  </div>
                  {!authorizedUser && (
                    <article className="product-name--watching--tooltip">
                      {formatMessage({ id: 'global.watchlist_tooltip' })}
                    </article>
                  )}
                </div>
                <div
                  onClick={() => {
                    modifyShoppingCart({
                      country_uuid: countryUuid,
                      product_uuid: item.uuid,
                      update_option: 'delete',
                      uuid: item.uuid,
                      user_id: item.user_id,
                    });
                  }}
                  className="delete"
                >
                  {formatMessage({ id: 'inputs.delete' })}
                </div>
              </div>
            </div>
          </div>
        );
      })}
  </div>
);

export default Left;

Left.propTypes = {
  addToFavorites: func.isRequired,
  authorizedUser: object.isRequired,
  formatMessage: func.isRequired,
  modifyShoppingCart: func.isRequired,
  removeFromFavorites: func.isRequired,
  shoppingCart: object.isRequired,
};

import * as Connector from 'shared/connectors/productsConnector';
import { PRODUCTS } from 'store/types';

const init = () => ({
  type: PRODUCTS.GET_TRANSFERS_INIT,
});

export const success = data => ({
  type: PRODUCTS.GET_TRANSFERS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: PRODUCTS.GET_TRANSFERS_FAILURE,
});

const getAllTransfers = () => async dispatch => {
  dispatch(init());
  try {
    const {
      data: { transfers },
    } = await Connector.getAllTransfers();
    dispatch(success(transfers));
    return transfers;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default getAllTransfers;

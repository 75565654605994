import { connect } from 'react-redux';
import { resetPassword } from 'store/actions/user';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import * as ViewManagementSelector from 'store/selectors/viewManagementSelector';
import ResetPassword from './ResetPassword.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      resetPassword: (email, lang) => resetPassword(email, lang),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  translation: ViewManagementSelector.getTranslation(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ResetPassword));

import { CHECKOUT } from 'store/types';
import { REQUEST_STATUS } from 'shared/consts';

const INITIAL_STATE = {
  checkout: {
    data: [],
  },
  countCheckoutStatus: {
    data: [],
  },
};

const checkoutReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case CHECKOUT.GET_CHECKOUT_INIT: {
      return {
        ...state,
        checkout: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case CHECKOUT.GET_CHECKOUT_SUCCESS: {
      return {
        ...state,
        checkout: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case CHECKOUT.GET_CHECKOUT_FAILURE: {
      return {
        ...state,
        checkout: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case CHECKOUT.GET_COUNT_CHECKOUT_STATUS_INIT: {
      return {
        ...state,
        countCheckoutStatus: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case CHECKOUT.GET_COUNT_CHECKOUT_STATUS_SUCCESS: {
      return {
        ...state,
        countCheckoutStatus: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case CHECKOUT.GET_COUNT_CHECKOUT_STATUS_FAILURE: {
      return {
        ...state,
        countCheckoutStatus: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    default:
      return state;
  }
};

export default checkoutReducer;

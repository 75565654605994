import * as Connector from 'shared/connectors/shoppingCartConnector';
import { SHOPPING_CART } from 'store/types';

const init = () => ({
  type: SHOPPING_CART.GET_SHOPPING_CART_INIT,
});

export const success = data => ({
  type: SHOPPING_CART.GET_SHOPPING_CART_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: SHOPPING_CART.GET_SHOPPING_CART_FAILURE,
});

const getShoppingCart = uuid => async dispatch => {
  dispatch(init());
  const countryUuid = localStorage.getItem('deliveryCountry');
  try {
    const { data } = await Connector.getShoppingCart(uuid, countryUuid);
    dispatch(success(data));
    return true;
  } catch (err) {
    dispatch(failure());
    localStorage.removeItem('notLoggedUserBasketUuid');
    return err.response;
  }
};

export default getShoppingCart;

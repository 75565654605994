/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import { Field } from 'formik';
import { RegularField, SelectField, Translate, Button } from 'shared/components';
import { object, func, string } from 'prop-types';
import { carBrandsOptions, carModelsOptions } from 'shared/utils';
// import translate from 'translate-google-api';
const BasicInfo = ({
  errors,
  carBrands,
  getCarModels,
  carModels,
  categories,
  getSubcategoryForCategory,
  subcategoryForCategory,
  formatMessage,
  setFieldValue,
  translation,
  customTranslations,
  setCategoryLink,
}) => {
  const [subcategory, setSubcategory] = useState([]);
  const [titleAdd, setTitleAdd] = useState('');
  const isLangPl = translation === 'pl';

  const categoryOptions = () => {
    const arr = [];
    categories?.category?.forEach(({ name, name_ger, uuid, link }) =>
      arr.push({
        label: isLangPl ? name : name_ger,
        searchableLabel: isLangPl ? name : name_ger,
        link,
        value: uuid,
        uuid,
      }),
    );
    return arr;
  };

  useEffect(() => {
    const subcategories = subcategoryForCategory?.subcategories;
    const arr = [];
    subcategories &&
      subcategories.forEach(({ name, name_ger, uuid }) =>
        arr.push({
          label: isLangPl ? name : name_ger,
          searchableLabel: isLangPl ? name : name_ger,
          value: uuid,
          uuid,
        }),
      );
    setSubcategory(arr);
  }, [subcategoryForCategory]);

  const getSubcategory = async item => {
    item && setCategoryLink(item.link);
    setFieldValue('subcategory_uuid', '');
    item && (await getSubcategoryForCategory(item.uuid));
  };

  const getModelsByBrand = e => {
    setFieldValue('model_uuid', '');
    getCarModels(e.label);
  };

  const handleTranslateTitle = async () => {
    let textToTranslate = titleAdd;
    const matchWords = [];
    const wordsWithIndex = [];
    let translateTo = '';
    if (isLangPl) {
      translateTo = 'de';
    } else {
      translateTo = 'pl';
    }
    const translate = async text => {
      const respond = await Translate(text, translateTo);
      return respond;
    };

    // find match word and push it to matchWords
    customTranslations.translations
      .sort((a, b) => b.word.length - a.word.length)
      .map(
        item => titleAdd.match(new RegExp(item.word, 'g')) && matchWords.push({ word: item.word, translation: item.translation }),
      );
    // add || characters before and after every custom translation word and ~~ for every longer sentence
    const getWholeWord =(data)=> `\\b${data}\\b`;
    matchWords.map(item =>
      item.word.split(' ').length === 1
        ? (textToTranslate = textToTranslate.replaceAll(new RegExp(getWholeWord(item.word),'g'), `||${item.word}||`))
        : (textToTranslate = textToTranslate.replaceAll(new RegExp(getWholeWord(item.word),'g'), `~~${item.word}~~`)),
    );
    // remove nested matches
    const regex = /(?<=~~).*(?=~~)/gi;
    textToTranslate.includes('~~') && (
      textToTranslate = textToTranslate
        .match(regex)
        .map(item => textToTranslate.replaceAll(item, item.replaceAll('||', '')))
        .join('||')
        .replaceAll('~~', '||')
    )
    // split text on matches
    textToTranslate = textToTranslate.split('||').filter(el => el !== '');
    // find indexes of matches and Translations
    textToTranslate.map((word, index) =>
      wordsWithIndex.push({
        word: word.trim(),
        index,
        isCustomTranslation:
          matchWords.filter(item => item.word.trim() === word.trim()).length > 0
            ? matchWords.filter(item => item.word.trim() === word.trim())[0].translation
            : false,
      }),
    );
    // replace custom words and translate default words
    const textAfterTranslate = wordsWithIndex.map(item =>
      item.isCustomTranslation ? item.isCustomTranslation : translate(item.word, translateTo),
    );
    // console.log('          ');
    // console.log(customTranslations.translations, 'translations');
    const result = data => {
      if (data.includes('||error||')) {
        translateTo === 'de'
          ? setFieldValue('title_ger', formatMessage({ id: 'global.can_not_translate' }))
          : setFieldValue('title', formatMessage({ id: 'global.can_not_translate' }));
      } else {
        translateTo === 'de' ? setFieldValue('title_ger', data.join(' ')) : setFieldValue('title', data.join(' '));
      }
    };
    Promise.all(textAfterTranslate).then(data => result(data));
  };

  return (
    <section>
      <div className="add-advertisement__title-input">
        <Field
          className="add-advertisement__field"
          component={RegularField}
          label={formatMessage({ id: 'global.addvertisement_title' })}
          errors={errors}
          name={isLangPl ? 'title' : 'title_ger'}
          inputOnChange={e => setTitleAdd(e.target.value)}
        />
        <Button
          className="btn--orange btn--orange--sharp"
          onClick={titleAdd && handleTranslateTitle}
          label={formatMessage({ id: 'global.translate' })}
        />
      </div>
      <Field
        className="add-advertisement__field"
        component={RegularField}
        label={formatMessage({ id: 'global.addvertisement_title_version' })}
        errors={errors}
        name={isLangPl ? 'title_ger' : 'title'}
      />
      <Field
        className="add-advertisement__select"
        component={SelectField}
        placeholder={formatMessage({ id: 'global.search' })}
        label={formatMessage({ id: 'global.category' })}
        errors={errors}
        name="category_uuid"
        onChange={e => getSubcategory(e)}
        options={categoryOptions()}
        isCustomLabel
      />
      <Field
        className="add-advertisement__select"
        component={SelectField}
        placeholder={formatMessage({ id: 'global.search' })}
        label={formatMessage({ id: 'global.subcategory' })}
        options={subcategory}
        errors={errors}
        name="subcategory_uuid"
        isCustomLabel
      />
      <Field
        className="add-advertisement__select"
        component={SelectField}
        placeholder={formatMessage({ id: 'global.search' })}
        label={formatMessage({ id: 'global.brand' })}
        errors={errors}
        options={carBrandsOptions(carBrands)}
        name="brand_uuid"
        onChange={e => getModelsByBrand(e)}
        isCustomLabel
      />
      <Field
        className="add-advertisement__select"
        component={SelectField}
        placeholder={formatMessage({ id: 'global.search' })}
        label={formatMessage({ id: 'global.model' })}
        errors={errors}
        options={carModelsOptions(carModels)}
        isCustomLabel
        name="model_uuid"
      />
      <Field
        className="add-advertisement__field"
        component={RegularField}
        label={formatMessage({ id: 'global.year_production' })}
        errors={errors}
        name="year_of_production"
      />
    </section>
  );
};

export default BasicInfo;

BasicInfo.propTypes = {
  authorizedUser: object.isRequired,
  carBrands: object.isRequired,
  carModels: object.isRequired,
  categories: object.isRequired,
  customTranslations: object.isRequired,
  errors: object.isRequired,
  form: object.isRequired,
  formatMessage: func.isRequired,
  getCarModels: object.isRequired,
  getSubcategoryForCategory: func.isRequired,
  setCategoryLink: func.isRequired,
  setFieldValue: object.isRequired,
  subcategoryForCategory: object.isRequired,
  translation: string.isRequired,
};

import { LANGUAGE_TYPE } from 'shared/consts';
import store from 'store/store';

const MENU_TYPES = () => {
  const deliveryCountries = store.getState().deliveryStore.deliveryCountries.data;
  return  {
    PL: {
      label: LANGUAGE_TYPE[0].label,
      options: deliveryCountries && deliveryCountries.find(item => item.label === 'Poland').value,
    },
    DE: {
      label: LANGUAGE_TYPE[1].label,
      options: deliveryCountries && deliveryCountries.find(item => item.label === 'Germany').value,
    },
  }
};

export { MENU_TYPES };

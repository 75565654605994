import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { MENU_TYPES } from '../const';

const items = formatMessage => [
  {
    type: MENU_TYPES(formatMessage).COUNTRY.label,
    options: 'country',
  },
  {
    type: MENU_TYPES(formatMessage).PARTNERS.label,
    options: 'partner',
  },
  {
    type: MENU_TYPES(formatMessage).PRODUCTS.label,
    options: 'product',
  },
];

const settings = {
  dots: false,
  arrows: false,
  slidesToShow: 3,
};

const PriceMenu = ({ menuType, setMenuType, isMobile, formatMessage }) => (
  <>
    {!isMobile ? (
      <div className="user-management__menu">
        {items(formatMessage).map(({ type, options }) => (
          <div
            className={`user-management__menu--item ${menuType.label === type ? 'user-management__menu--active' : ''}`}
            onClick={() => setMenuType({ label: type, options })}
          >
            <div className="label">{type}</div>
          </div>
        ))}
      </div>
    ) : (
      <div className="user-management__menu">
        <Slider {...settings}>
          {items(formatMessage).map(({ type, options }) => (
            <div
              className={`user-management__menu--item ${menuType.label === type ? 'user-management__menu--active' : ''}`}
              onClick={() => setMenuType({ label: type, options })}
            >
              <div className="label">{type}</div>
            </div>
          ))}
        </Slider>
      </div>
    )}
  </>
);

export default PriceMenu;

PriceMenu.propTypes = {
  formatMessage: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  menuType: PropTypes.bool.isRequired,
  setMenuType: PropTypes.func.isRequired,
};

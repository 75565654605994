import * as Connector from 'shared/connectors/priceConnector';
import { PRICE } from 'store/types';

export const success = (itemUuid, type, data) => ({
  type: PRICE.EDIT_PRICE_MARGIN_SUCCESS, // using same action reducer as in editPriceMargin.js
  payload: {
    itemUuid,
    type,
    data,
  },
});

const UTIL = {
  countries: 'country',
  partner: 'partner',
  products: 'product',
};

const createPriceMargin = values => async dispatch => {
  try {
    const { value, isPercentageLocal, foreign_margin, isPercentageForeign, itemUuid, type } = values;
    let body = {};
    if (type === 'countries') {
      body = {
        option: UTIL[type],
        uuid: itemUuid,
        value,
        isPercentageLocal,
        foreign_margin,
        isPercentageForeign,
      };
    } else {
      body = {
        option: UTIL[type],
        uuid: itemUuid,
        value,
        isPercentageLocal,
      };
    }
    const { data } = await Connector.createPriceMargin(body);
    const priceMargin = {
      label: data.price_margin.uuid,
      value,
      foreign_margin,
      isPercentageLocal,
      isPercentageForeign,
    };
    dispatch(success(itemUuid, type, priceMargin));
    return data;
  } catch (err) {
    return err.response;
  }
};

export default createPriceMargin;

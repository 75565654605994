const MENU_TYPES = formatMessage => ({
  ALL: formatMessage({ id: 'global.all' }),
  ACTIVE: formatMessage({ id: 'global.active' }),
  SOLD: formatMessage({ id: 'global.sold' }),
  ENDED: formatMessage({ id: 'global.ended' }),
  RECALLED: formatMessage({ id: 'global.recalled2' }),
});

const ADVERTISEMENT_TYPES = {
  ALL: 'all',
  ACTIVE: 'active',
  SOLD: 'sold',
  ENDED: 'completed',
  RECALLED: 'canceled',
};

const SUBPAGES = (formatMessage, count) => [
  {
    requestType: ADVERTISEMENT_TYPES.ALL,
    type: MENU_TYPES(formatMessage).ALL,
    amount: count?.products_count?.all,
  },
  {
    requestType: ADVERTISEMENT_TYPES.ACTIVE,
    type: MENU_TYPES(formatMessage).ACTIVE,
    amount: count?.products_count?.active,
  },
  {
    requestType: ADVERTISEMENT_TYPES.SOLD,
    type: MENU_TYPES(formatMessage).SOLD,
    amount: count?.products_count?.sold,
  },
  {
    requestType: ADVERTISEMENT_TYPES.ENDED,
    type: MENU_TYPES(formatMessage).ENDED,
    amount: count?.products_count?.completed,
  },
  {
    requestType: ADVERTISEMENT_TYPES.RECALLED,
    type: MENU_TYPES(formatMessage).RECALLED,
    amount: count?.products_count?.canceled,
  },
];

export { MENU_TYPES, ADVERTISEMENT_TYPES, SUBPAGES };

import React, { useEffect } from 'react';
import { shape, func, object } from 'prop-types';
import PaymentSuccess from 'assets/files/payment_success.png';
import { Button, SVG } from 'shared/components';
import { SVG_TYPE, ROUTES } from 'shared/consts';
import { useHistory } from 'react-router-dom';

const PaymentAccepted = ({ intl: { formatMessage }, authorizedUser, actions: { getAuthUser } }) => {
  const history = useHistory();

  useEffect(() => {
    authorizedUser && getAuthUser();
  }, []);

  localStorage.removeItem('checkoutUuid');
  localStorage.removeItem('orderEndTime');
  localStorage.removeItem('notLoggedUserBasketUuid');

  return (
    <div className="payments">
      <div className="payments__content">
        <p className="descript">
          {formatMessage({ id: 'global.payment_accepted' })} <SVG type={SVG_TYPE.CHECKED_FULL} />
        </p>
        <div className="payments__image--wrapper">
          <div className="payments__image--content">
            <img src={PaymentSuccess} alt="payment success" />
            {authorizedUser ? (
              <Button
                className="btn--orange"
                label={formatMessage({ id: 'global.my_orders' })}
                iconType={SVG_TYPE.ARROW_RIGHT}
                onClick={() => history.push(ROUTES.ORDERS)}
              />
            ) : (
              <Button
                label={formatMessage({ id: 'global.continue_shopping' })}
                onClick={() => history.push(`${ROUTES.CATEGORY}?s=`)}
                className="btn--orange"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentAccepted;

PaymentAccepted.propTypes = {
  actions: shape({
    getAuthUser: func.isRequired,
  }).isRequired,
  authorizedUser: object.isRequired,
  intl: shape({
    formatMessage: func.isRequired,
  }).isRequired,
};

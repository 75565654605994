import React from 'react';
import * as Connector from 'shared/connectors/shoppingCartConnector';
import { SHOPPING_CART } from 'store/types';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import { NOTIFY_TYPE } from 'shared/consts';

export const success = (data, dataForm) => ({
  type: SHOPPING_CART.MODIFY_BASKET_SUCCESS,
  payload: {
    data,
    dataForm,
  },
});

const failure = () => ({
  type: SHOPPING_CART.MODIFY_BASKET_FAILURE,
});

const modifyShoppingCart = dataForm => async dispatch => {
  const { update_option } = dataForm;
  const notLoggedUserBasketUuid = localStorage.getItem('notLoggedUserBasketUuid');
  const isUserLogged = localStorage.getItem('isUserLogged');
  try {
    if (notLoggedUserBasketUuid && !isUserLogged) {
      dataForm.uuid = notLoggedUserBasketUuid;
    }
    const { data } = await Connector.modifyShoppingCart(dataForm);
    (update_option === 'add' || update_option === 'reset') &&
      toast(<Notify label="global.add_to_basket" type={NOTIFY_TYPE.CHECKED} />);
    if (data.shopping_cart.user_id === null && !notLoggedUserBasketUuid) {
      localStorage.setItem('notLoggedUserBasketUuid', data.shopping_cart.uuid);
    }
    dispatch(success(data, dataForm));
    return true;
  } catch (err) {
    dispatch(failure());
    return false;
  }
};

export default modifyShoppingCart;

/* eslint react/prop-types: 0 */

import React from 'react';

const ProductName = ({ data, isMobile }) => (
  <div className="productNameWrapper">
    {!isMobile && data?.files[0]&& (
      <img
        src={data?.files[0].miniature_path}
        alt="product_photo"
        className="productPhoto"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.className= 'productPhoto productPhoto--hidden';
        }}
      />
    )}
    <div className="productName">{data?.title}</div>
  </div>
);

export default ProductName;

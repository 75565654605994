import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as ViewManagementSelector from 'store/selectors/viewManagementSelector';
import { bindActionCreators } from 'redux';
import { getAuthorizedUser, getCustomCheckoutAddress } from 'store/selectors/userSelector';
import {
  getShoppingCart as getShoppingCartSelector,
  getShoppingCartOwner as getShoppingCartOwnerSelector,
} from 'store/selectors/shoppingCartSelector';
import { getShoppingCart, modifyShoppingCart, getShoppingCartOwner } from 'store/actions/shopping-cart';
import { simulateCheckout } from 'store/actions/checkout';
import { addToFavoritesCheckout, removeFromFavoritesCheckout } from 'store/actions/products';
import { getDeliveryCountries as getDeliveryCountriesSelector } from 'store/selectors/deliverySelector';
import getCheckoutAddress from 'store/actions/auth/getCheckoutAddress';
import { getDeliveryCountries } from 'store/actions/delivery';
import { isMobile as checkIsMobile } from 'shared/utils';
import Checkout from './Checkout.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getDeliveryCountries: () => getDeliveryCountries(),
      getShoppingCart: () => getShoppingCart(),
      getShoppingCartOwner: () => getShoppingCartOwner(),
      modifyShoppingCart: uuid => modifyShoppingCart(uuid),
      addToFavoritesCheckout: uuid => addToFavoritesCheckout(uuid),
      removeFromFavoritesCheckout: uuid => removeFromFavoritesCheckout(uuid),
      getCheckoutAddress: () => getCheckoutAddress(),
      simulateCheckout: form => simulateCheckout(form),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  authorizedUser: getAuthorizedUser(state),
  translation: ViewManagementSelector.getTranslation(state),
  shoppingCart: getShoppingCartSelector(state),
  shoppingCartOwner: getShoppingCartOwnerSelector(state),
  checkoutAddress: getCustomCheckoutAddress(state),
  deliveryCountries: getDeliveryCountriesSelector(state),
  isMobile: checkIsMobile(),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Checkout));

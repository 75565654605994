/* eslint-disable react/sort-prop-types */
import React, { useState, useEffect, useRef } from 'react';
// import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { object, string, array } from 'yup';
import PropTypes, { func, shape } from 'prop-types';
import { VALIDATION_FIELD, ROUTES } from 'shared/consts';
import { FormikOnError } from 'shared/utils';
import { useHistory } from 'react-router-dom';
import { generatePath } from 'react-router';
import { MENU_TYPES } from 'screens/dictionary/components/const';
import { Preloader } from 'shared/components';
import {
  Colors,
  ItemCondition,
  PriceAndCount,
  Localization,
  BasicInfo,
  Photos,
  ButtonActions,
  CancelAdvertisement,
} from './components';

const AddAdvertisement = ({
  actions: {
    getAllCategory,
    getCarModels,
    getCarBrands,
    getSubcategoryForCategory,
    addProducts,
    getDelivery,
    getCustomTranslations,
    addProductPreview,
  },
  intl: { formatMessage },
  categories,
  authorizedUser,
  subcategoryForCategory,
  delivery,
  carBrands,
  carModels,
  translation,
  customTranslations,
  productPreview,
}) => {
  const history = useHistory();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [photoInputValue, setPhotoInputValue] = useState(productPreview.files);
  const [fileArr, setFileArr] = useState([]);
  const [base64, setBase64] = useState([]);
  const currentCategory = categories?.category?.find(item => item.uuid === productPreview.category_uuid);
  const [categoryLink, setCategoryLink] = useState(currentCategory && currentCategory.link);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const country_uuid = MENU_TYPES(formatMessage)[translation === 'pl' ? 'PL' : 'DE'].options;
  const ref = useRef();

  useEffect(() => {
    getAllCategory();
    getCarBrands();
    getCustomTranslations({ country_uuid });
  }, []);

  useEffect(async () => {
    const lang = authorizedUser.country.label;
    await getDelivery(lang);
  }, [authorizedUser]);

  useEffect(async () => {}, [photoInputValue]);

  const [isPreview, setIsPreview] = useState(false);
  const handleOnSubmit = async values => {
    if (!isPreview) {
      setIsFormSubmitted(true);
      const uuid = await addProducts(values, photoInputValue);
      history.push(
        generatePath(ROUTES.SINGLE_PRODUCT, {
          category: categoryLink,
          product: uuid,
        }),
      );
      addProductPreview();
    } else {
      addProductPreview({ ...values, files: photoInputValue, fileArr, base64 });
      history.push('/product-preview');
    }
  };

  const validationSchema = () =>
    object().shape({
      title: string()
        .required(' ')
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254),
      title_ger: string()
        .required(' ')
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .matches(/^(?!.*(Translator.*nie.*jest.*w.*stanie.*przetłumaczyć)).*$/, VALIDATION_FIELD(formatMessage).REQUIRED)
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254),
      category_uuid: string().required(' '),
      subcategory_uuid: string().required(' '),
      brand_uuid: string().required(' '),
      year_of_production: string()
        .required(' ')
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .min(4, VALIDATION_FIELD(formatMessage).DATA)
        .max(4, VALIDATION_FIELD(formatMessage).DATA)
        .matches(/^(19|20)\d{2}$/, VALIDATION_FIELD(formatMessage).DATA),
      description: string()
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .min(80, '')
        .max(6000, VALIDATION_FIELD(formatMessage).MAX_6000)
        .required(' '),
      description_ger: string()
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .min(80, '')
        .max(6000, VALIDATION_FIELD(formatMessage).MAX_6000)
        .required(' '),
      price: string()
        .required(' ')
        .matches(/^\d{0,100}(\.\d{0,99}||,\d{0,99})?$/, VALIDATION_FIELD(formatMessage).ONLY_NUMBER)
        .matches(/^\d{0,6}(\.\d{0,99}||,\d{0,99})?$/, VALIDATION_FIELD(formatMessage).MAX_6)
        .matches(/^[0-9][0-9]*[(.|,)]?[0-9]{0,2}$/, VALIDATION_FIELD(formatMessage).DECIMAL_2)
        .matches(/^[0-9,.]*$/, ' ')
        .min(1, '')
        .test(
          'no-leading-zero',
          VALIDATION_FIELD(formatMessage).FIRST_NO_ZERO,
          (value, context) => context.originalValue && !context.originalValue.startsWith('0'),
        ),
      condition: string().required(VALIDATION_FIELD(formatMessage).REQUIRED),
      location: string()
        .required(' ')
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254),
      amount: string()
        .required(' ')
        .matches(/^\d{0,100}?$/, VALIDATION_FIELD(formatMessage).ONLY_NUMBER)
        .matches(/^\d{0,6}?$/, VALIDATION_FIELD(formatMessage).MAX_6)
        .min(1, '')
        .test(
          'no-leading-zero',
          VALIDATION_FIELD(formatMessage).FIRST_NO_ZERO,
          (value, context) => context?.originalValue && !context?.originalValue?.startsWith('0'),
        )
        .matches(/^\d+$/, ' '),
      delivery_uuid: array().min(1, VALIDATION_FIELD(formatMessage).DELIVERY).required(VALIDATION_FIELD(formatMessage).DELIVERY),
    });

  const handlePreview = (values, validateField) => {
    validateField('title');
    addProductPreview({ ...values, files: photoInputValue, fileArr, base64 });
    // history.push('/product-preview');
  };

  const initialValues = {
    title: productPreview.title || '',
    title_ger: productPreview.title_ger || '',
    category_uuid: productPreview.category_uuid || '',
    subcategory_uuid: productPreview.subcategory_uuid || '',
    brand_uuid: productPreview.brand_uuid || '',
    model_uuid: productPreview.model_uuid || '',
    year_of_production: productPreview.year_of_production || '',
    description: productPreview.description || '',
    description_ger: productPreview.description_ger || '',
    price: productPreview.price || '',
    condition: productPreview.condition || '',
    location: productPreview.location || '',
    delivery_uuid: productPreview.delivery_uuid || [],
    color: productPreview.color || '',
    amount: productPreview.amount || '',
  };

  return (
    <div className="add-advertisement">
      {isFormSubmitted && <Preloader />}
      <h1 className="add-advertisement--heading">{formatMessage({ id: 'global.add_addvertisement' })}</h1>
      <Formik innerRef={ref} initialValues={initialValues} onSubmit={handleOnSubmit} validationSchema={validationSchema()}>
        {({ errors, setFieldValue, values, handleSubmit }) => (
          <>
            <form onSubmit={handleSubmit}>
              <FormikOnError>
                <BasicInfo
                  setCategoryLink={setCategoryLink}
                  setFieldValue={setFieldValue}
                  carModels={carModels}
                  getCarModels={getCarModels}
                  carBrands={carBrands}
                  formatMessage={formatMessage}
                  getSubcategoryForCategory={getSubcategoryForCategory}
                  getAllCategory={getAllCategory}
                  categories={categories}
                  errors={errors}
                  subcategoryForCategory={subcategoryForCategory}
                  translation={translation}
                  customTranslations={customTranslations}
                />
                <Photos
                  translation={translation}
                  setFieldValue={setFieldValue}
                  setPhotoInputValue={setPhotoInputValue}
                  formatMessage={formatMessage}
                  errors={errors}
                  customTranslations={customTranslations}
                  productPreview={productPreview}
                  setFileArr={setFileArr}
                  setBase64={setBase64}
                />
                <Colors formatMessage={formatMessage} setFieldValue={setFieldValue} productPreview={productPreview} />
                <Field
                  formatMessage={formatMessage}
                  component={ItemCondition}
                  errors={errors}
                  name="condition"
                  setFieldValue={setFieldValue}
                  productPreview={productPreview}
                />
                <PriceAndCount currency={delivery} formatMessage={formatMessage} errors={errors} />
                <Field
                  formatMessage={formatMessage}
                  component={Localization}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  delivery={delivery}
                  values={values}
                />
                {/* <Localization delivery={delivery} formatMessage={formatMessage} errors={errors} values={values} setFieldValue={setFieldValue} /> */}
                <ButtonActions
                  formatMessage={formatMessage}
                  openModal={setIsModalOpened}
                  handleSubmit={handleSubmit}
                  isSubmitted={isFormSubmitted}
                  handlePreview={handlePreview}
                  values={values}
                  setIsPreview={setIsPreview}
                />
                <CancelAdvertisement
                  isModalOpened={isModalOpened}
                  formatMessage={formatMessage}
                  setIsModalOpened={setIsModalOpened}
                />
              </FormikOnError>
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default AddAdvertisement;

AddAdvertisement.propTypes = {
  actions: shape({
    addProducts: func.isRequired,
    getAllCategory: func.isRequired,
    getSubCategoryForCategory: func.isRequired,
    getDelivery: func.isRequired,
    getCarBrands: func.isRequired,
    getCarModels: func.isRequired,
  }).isRequired,
  authorizedUser: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  carBrands: PropTypes.array.isRequired,
  carModels: PropTypes.array.isRequired,
  delivery: PropTypes.object.isRequired,
  customTranslations: PropTypes.object.isRequired,
  formatMessage: PropTypes.func.isRequired,
  subcategoryForCategory: PropTypes.object.isRequired,
  translation: PropTypes.string.isRequired,
  productPreview: PropTypes.object.isRequired,
};

import API from 'shared/connectors/config';

export function createRoom(option) {
  return API.post(`rooms`, option);
}

export function getRoomMessages(data) {
  return API.get(`rooms/messages?room_uuid=${data.room_uuid}&page=${data.page}`);
}

// export function getRooms({uuid,country_uuid}) {
//   return API.get(`rooms${uuid ? `?room_uuid=${uuid}` : ''}${country_uuid? `&?country_uuid=${country_uuid}`:''}`);
// }
export function getRooms({uuid}) {
  return API.get('rooms',{ params: { room_uuid:uuid } });
}
export function sendMessage(data) {
  return API.post(`rooms/messages`, data);
}
export function updateChatStatus(data) {
  return API.put(`rooms`, data);
}

import React, { useState } from 'react';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { MODAL_TYPE, ROUTES, VALIDATION_FIELD, PHONE_PREFIX } from 'shared/consts';
import { Modal, ModalActions, RegularField, Button, SelectField } from 'shared/components';
import { object, func, string } from 'prop-types';
import { SubscriptionTiles } from 'screens/partner-program/components';
import { useHistory } from 'react-router-dom';
import { isValidNip } from 'shared/utils';

const PartnershipApplication = ({
  selectedSubscription,
  setSelectedSubscription,
  formatMessage,
  partnerRequest,
  authorizedUser,
  translation,
}) => {
  const [isAgreementCheckd, setIsAgreementCheckd] = useState(false);
  const [isAgreementError, setIsAgreementError] = useState(false);
  const [isFormSend, setIsFormSend] = useState(false);
  const { isOpened } = selectedSubscription;
  const addressLang = authorizedUser ? authorizedUser?.country?.short.toLowerCase() : translation;
  const history = useHistory();
  const isGermany = authorizedUser?.country_id === 2;
  const onClose = () => {
    setSelectedSubscription({ isOpened: false, selected: false });
  };

  const validation = () =>
    Yup.object().shape({
      city: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254),
      company_name: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254),
      house_number: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254),
      nip: Yup.string()
        .nullable()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .test('required', VALIDATION_FIELD(formatMessage).REQUIRED, val => (isGermany ? true : !!val))
        .test({ message: VALIDATION_FIELD(formatMessage).WRONG_NIP, test: value => isGermany ?true : isValidNip(value) }),
      owner_first_name: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254),
      owner_last_name: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254),
      phone_number: Yup.string()
        .min(9, VALIDATION_FIELD(formatMessage).REQUIRED_9)
        .max(9, VALIDATION_FIELD(formatMessage).REQUIRED_9)
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED),
      phone_prefix: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254),
      post_code: Yup.string(VALIDATION_FIELD(formatMessage).REQUIRED)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .test(
          'post-code',
          isGermany ? VALIDATION_FIELD(formatMessage).POST_CODE_DE : VALIDATION_FIELD(formatMessage).POST_CODE,
          val => (val && isGermany ? !!/^[0-9]{5}$/i.test(val) : /^[0-9]{2}-[0-9]{3}$/i.test(val)),
      )
      .test('germany-postcode', VALIDATION_FIELD(formatMessage).BAD_VALUE, val =>
      val && isGermany
        ? !!/\b((?:0[1-46-9]\d{3})|(?:[1-357-9]\d{4})|(?:[4][0-24-9]\d{3})|(?:[6][013-9]\d{3}))\b/.test(val)
        : true,
    ),
      street: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254),
    });

  const onSubmit = async values => {
    if (isAgreementCheckd) {
      const response = await partnerRequest(values);
      response.status === 'success' && setIsFormSend(true);
    } else {
      setIsAgreementError(true);
    }
  };

  return (
    <>
      {isOpened && (
        <Modal className={MODAL_TYPE.PARTNER} onClose={onClose} opened={isOpened}>
          {!isFormSend ? (
            <div>
              <div className="modal__heading modal__heading--center">
                {formatMessage({ id: 'global.partnership_application' })}
              </div>
              <div className="partnerProgram__modal--descript--wrapper">
                <p className="login__paragraph login__paragraph--black partnerProgram__modal--descript">
                  {formatMessage({ id: 'global.we_will_contact_the_partner' })}
                </p>
              </div>
              <Formik
                validationSchema={validation()}
                initialValues={{
                  owner_first_name: authorizedUser?.first_name ? authorizedUser?.first_name : '',
                  owner_last_name: authorizedUser?.last_name ? authorizedUser?.last_name : '',
                  company_name: '',
                  street: '',
                  house_number: '',
                  post_code: '',
                  phone_number: '',
                  phone_prefix: '+48',
                  nip: '',
                  city: '',
                  type: selectedSubscription.selected,
                  auth: !!authorizedUser?.uuid,
                  user_uuid: authorizedUser?.uuid && authorizedUser?.uuid,

                  // owner_first_name: authorizedUser?.first_name ? authorizedUser?.first_name : 'jerzy',
                  // owner_last_name: authorizedUser?.last_name ? authorizedUser?.last_name : 'owsiak',
                  // company_name: 'Kowalski company',
                  // street: 'Kowalski street',
                  // house_number: '10',
                  // post_code: '20-190',
                  // city: 'Kowalski Town',
                  // phone_prefix: '+48',
                  // phone_number: '505234123',
                  // nip: '9324167051',
                  // type: selectedSubscription.selected,
                  // auth: !!authorizedUser?.uuid,
                  // user_uuid: authorizedUser?.uuid && authorizedUser?.uuid,
                }}
                onSubmit={onSubmit}
              >
                {({ handleSubmit, errors }) => (
                  <form onSubmit={handleSubmit}>
                    <SubscriptionTiles
                      formatMessage={formatMessage}
                      addressLang={addressLang}
                      setSelectedSubscription={setSelectedSubscription}
                      selectedItem={selectedSubscription}
                    />
                    <div className="partnerProgram__modal--form">
                      <div className="partnerProgram__modal--content">
                        {!authorizedUser?.first_name && (
                          <Field
                            type="text"
                            label={formatMessage({ id: 'inputs.name' })}
                            name="owner_first_name"
                            component={RegularField}
                            errors={errors}
                          />
                        )}
                        {!authorizedUser?.first_name && (
                          <Field
                            type="text"
                            label={formatMessage({ id: 'inputs.last_name' })}
                            name="owner_last_name"
                            component={RegularField}
                            errors={errors}
                          />
                        )}
                        <Field
                          type="text"
                          label={formatMessage({ id: 'inputs.company_name' })}
                          name="company_name"
                          component={RegularField}
                          errors={errors}
                        />
                        <Field
                          type="text"
                          label={formatMessage({ id: 'inputs.street' })}
                          name="street"
                          component={RegularField}
                          errors={errors}
                        />
                        <Field
                          type="text"
                          label={formatMessage({ id: 'inputs.house_number' })}
                          name="house_number"
                          component={RegularField}
                          errors={errors}
                        />
                        {!isGermany && (
                          <Field
                            type="text"
                            label={formatMessage({ id: 'global.nip' })}
                            name="nip"
                            component={RegularField}
                            errors={errors}
                          />
                        )}
                        <div className="regular-field__phone-wrap">
                          <Field
                            type="text"
                            label={formatMessage({ id: 'global.directional' })}
                            name="phone_prefix"
                            component={SelectField}
                            errors={errors}
                            options={PHONE_PREFIX}
                          />
                          <Field
                            type="text"
                            label={formatMessage({ id: 'global.telephone' })}
                            name="phone_number"
                            component={RegularField}
                            errors={errors}
                          />
                        </div>
                        <Field
                          type="text"
                          label={formatMessage({ id: 'inputs.post_code' })}
                          name="post_code"
                          component={RegularField}
                          errors={errors}
                        />
                        <Field
                          type="text"
                          label={formatMessage({ id: 'inputs.city' })}
                          name="city"
                          component={RegularField}
                          errors={errors}
                        />
                        <div
                          className={`partnerProgram__modal__consent ${
                            isAgreementCheckd ? 'partnerProgram__modal__consent--active' : ''
                          }`}
                          onClick={() => {
                            setIsAgreementCheckd(!isAgreementCheckd);
                            setIsAgreementError(!isAgreementError);
                          }}
                        >
                          {formatMessage({ id: 'global.got_acquainted' })}&nbsp;
                          <div> {formatMessage({ id: 'global.terms_of_service' })} </div> {formatMessage({ id: 'global.and' })}
                          <div> {formatMessage({ id: 'global.privacy_policy' })}</div>
                        </div>
                        {isAgreementError && !isAgreementCheckd && (
                          <div className="regular-field__label-error partnerProgram__error">
                            {formatMessage({ id: 'validation.required' })}
                          </div>
                        )}
                        <ModalActions
                          formatMessage={formatMessage}
                          type={MODAL_TYPE.PARTNER}
                          onClose={onClose}
                          onSubmit={handleSubmit}
                        />
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          ) : (
            <div>
              <div className="modal__heading modal__heading--center">{formatMessage({ id: 'global.application_send' })}</div>
              <div className="partnerProgram__modal--descript--wrapper">
                {formatMessage({ id: 'global.thanks_for_posting_application' })}
              </div>
              <div className="partnerProgram__modal--button-hp">
                <Button
                  label={formatMessage({ id: 'inputs.go_to_home_page' })}
                  className="btn--orange"
                  onClick={() => history.push(ROUTES.HOME_PAGE)}
                />
              </div>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default PartnershipApplication;

PartnershipApplication.propTypes = {
  authorizedUser: object.isRequired,
  formatMessage: object.isRequired,
  partnerRequest: func.isRequired,
  selectedSubscription: object.isRequired,
  setSelectedSubscription: func.isRequired,
  translation: string.isRequired,
};

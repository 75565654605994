import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Title } from 'shared/components';
import { MagazineFilters, MagazineMenu, MagazineContent } from './components';
import { MENU_TYPES, STATUS_OPTIONS } from './utils/menuUtils';
import useQuery from './utils/useQuery';

const Magazine = ({
  getAllProducts,
  getAllTransfers,
  addTransfer,
  getDeliveryCountries,
  getAllCategoryAndSubcategory,
  editProduct,
  editTransfer,
  isMobile,
  intl: { formatMessage },
  products,
  transfers,
  deliveryCountries,
  categoryAndSubcategory,
  getAllProductsByFilter,
  getCategoriesOptions,
  categoriesOptions,
  deleteProduct,
  recallTheProduct,
  resumeTheProduct,
  translation,
  subcategoryForCategory,
  getSubcategoryForCategory,
  getCheckout,
  checkout,
  getInvoice,
}) => {
  const [menuType, setMenuType] = useState(MENU_TYPES(formatMessage).PRODUCTS);
  const [filters, setFilters] = useState({ status: 'all', title: '', category_uuid: '' });
  const [edit, setEdit] = useState(false);
  useEffect(async () => {
    const { status, title, category_uuid } = filters;
    if (menuType === MENU_TYPES(formatMessage).PRODUCTS) {
      title !== '' || category_uuid || status !== 'all'
        ? await getAllProductsByFilter(status, title, category_uuid)
        : await getAllProducts();
    }
    if (menuType === MENU_TYPES(formatMessage).ORDERS) {
      await getCheckout(filters);
    }
    // await getAllProductsByFilter(status, title, category_uuid);
  }, [filters, edit, menuType]);

  useEffect(async () => {
    switch (menuType) {
      case MENU_TYPES(formatMessage).PRODUCTS:
        await getAllProducts();
        break;
      case MENU_TYPES(formatMessage).TRANSFERS:
        await getAllTransfers();
        break;
      case MENU_TYPES(formatMessage).ORDERS:
        await getCheckout();
        break;
      default:
        break;
    }
  }, [menuType]);
  useEffect(() => {
    useQuery(getDeliveryCountries, getAllCategoryAndSubcategory, getCategoriesOptions);
  }, []);

  return (
    <main className="magazine-wrapper">
      <div className="magazine-wrapper__header">
        <Title title="Magazyn" className="magazine" fontStyle="default" />
        <MagazineMenu menuType={menuType} setMenuType={setMenuType} isMobile={isMobile} formatMessage={formatMessage} />
        <MagazineFilters
          isMobile={isMobile}
          formatMessage={formatMessage}
          setFilters={setFilters}
          filters={filters}
          categoriesOptions={categoriesOptions}
          menuType={menuType}
          STATUS_OPTIONS={STATUS_OPTIONS}
        />
      </div>
      <div className="magazine-wrapper__content">
        <MagazineContent
          subcategoryForCategory={subcategoryForCategory}
          getSubcategoryForCategory={getSubcategoryForCategory}
          menuType={menuType}
          formatMessage={formatMessage}
          isMobile={isMobile}
          products={products}
          transfers={transfers}
          addTransfer={addTransfer}
          deliveryCountries={deliveryCountries}
          categoryAndSubcategory={categoryAndSubcategory}
          editAdvertisements={editProduct}
          editTransfer={editTransfer}
          deleteProduct={deleteProduct}
          recallTheProduct={recallTheProduct}
          resumeTheProduct={resumeTheProduct}
          translation={translation}
          edit={edit}
          setEdit={setEdit}
          checkout={checkout}
          getInvoice={getInvoice}
        />
      </div>
    </main>
  );
};

export default Magazine;

Magazine.propTypes = {
  addTransfer: PropTypes.func.isRequired,
  categoriesOptions: PropTypes.array.isRequired,
  categoryAndSubcategory: PropTypes.array.isRequired,
  checkout: PropTypes.array.isRequired,
  deleteProduct: PropTypes.func.isRequired,
  deliveryCountries: PropTypes.array.isRequired,
  editProduct: PropTypes.func.isRequired,
  editTransfer: PropTypes.func.isRequired,
  getAllCategoryAndSubcategory: PropTypes.func.isRequired,
  getAllProducts: PropTypes.func.isRequired,
  getAllProductsByFilter: PropTypes.func.isRequired,
  getAllTransfers: PropTypes.func.isRequired,
  getCategoriesOptions: PropTypes.func.isRequired,
  getCheckout: PropTypes.func.isRequired,
  getDeliveryCountries: PropTypes.func.isRequired,
  getInvoice: PropTypes.func.isRequired,
  getSubcategoryForCategory: PropTypes.func.isRequired,
  isMobile: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  recallTheProduct: PropTypes.func.isRequired,
  resumeTheProduct: PropTypes.func.isRequired,
  subcategoryForCategory: PropTypes.object.isRequired,
  transfers: PropTypes.array.isRequired,
  translation: PropTypes.string.isRequired,
};

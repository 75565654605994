import React from 'react';
import { bool, func, object, string, shape } from 'prop-types';
import ContactIlustration from 'assets/files/kontakt_ilu.png';
import { ContactForm, GoogleMaps } from './components';

const ContactPage = ({ isMobile, authorizedUser, intl: { formatMessage }, translation, actions: { sendMessageContactForm } }) => {
  const addressLang = authorizedUser ? authorizedUser?.country?.short.toLowerCase() : translation;

  return (
    <main className="contact">
      <div className="contact--top">
        <div className="contact--left">
          <hgroup>
            <h1>{formatMessage({ id: 'global.contact_with_us' })}</h1>
            <h2>{formatMessage({ id: 'global.we_are_at_your_disposal' })}</h2>
          </hgroup>
          <p className="paragraph">{formatMessage({ id: 'global.pn_pt' })}</p>
          <p className="paragraph--container">
            <span className="orange">{formatMessage({ id: 'global.telephone' })}:</span>
            <a href='tel:+48 563-921-630' className="bold tel">+48 563 921 630</a>
          </p>
          <p className="paragraph--container">
            <span className="orange">{formatMessage({ id: 'global.email' })}:</span>
            <a href='mailto:kontakt@getmotor.eu' className="bold">kontakt@getmotor.eu</a>
          </p>
          <div className="contact--ilustration--wrapper">
            <img className="contact--ilustration" src={ContactIlustration} alt="contact ilustration" />
          </div>
        </div>
        <div className="contact--right">
          <ContactForm
            sendMessageContactForm={sendMessageContactForm}
            isMobile={isMobile}
            authorizedUser={authorizedUser}
            formatMessage={formatMessage}
          />
        </div>
      </div>
      <div className="contact--bottom">
        <div className="contact--left">
          {addressLang === 'de' ? (
            <div className="contact--address">
              <p className="mid paragraph">GET MOTOR</p>
              <p className="paragraph">Kleine Friendenstraße 24</p>
              <p className="paragraph">15328 Küstriner Vorland</p>
            </div>
          ) : (
            <div className="contact--address">
              <p className="mid paragraph">GET MOTOR Sp. z o.o</p>
              <p className="paragraph">Dormowo 45</p>
              <p className="paragraph">64-426 Dormowo</p>
            </div>
          )}
          <div className="contact--address contact--nip">
            <p className="paragraph">NIP: 1836405820</p>
            <p className="paragraph">REGON: 047385610</p>
            <p className="paragraph">KRS: 003926178</p>
          </div>
          <p className="paragraph--container">
            <span>{formatMessage({ id: 'global.telephone' })}:</span>
            <a href='tel:+48 563 921 630' className="bold tel">+48 563 921 630</a>
          </p>
          <p className="paragraph--container">
            <span>{formatMessage({ id: 'global.email' })}:</span>
            <a href='mailto:kontakt@getmotor.eu' className="bold">kontakt@getmotor.eu</a>
          </p>
        </div>
        <div className="contact--right">
          <GoogleMaps />
        </div>
      </div>
    </main>
  );
};

export default ContactPage;

ContactPage.defaultProps = {
  authorizedUser: {},
};

ContactPage.propTypes = {
  actions: shape({
    sendMessageContactForm: func.isRequired,
  }).isRequired,
  authorizedUser: object,
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
  translation: string.isRequired,
};

import React, { useEffect, useState } from 'react';
import { SVG, RegularField, Button } from 'shared/components';
import { SVG_TYPE, VALIDATION_FIELD, ROUTES } from 'shared/consts';
import { shape, func } from 'prop-types';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

const SetPassword = ({ actions: { setPassword }, intl: { formatMessage } }) => {
  const [token, setToken] = useState(sessionStorage.getItem('pass'));
  const [isError, setIsError] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setToken(token.replace(/['"]+/g, ''));
  }, []);

  const handleOnSubmit = async values => {
    const response = await setPassword(values);
    response ? (history.push(ROUTES.LOGIN), setIsError(false)) : setIsError(true);
  };

  const validation = () =>
    Yup.object().shape({
      confirm_password: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .oneOf([Yup.ref('new_password'), null], VALIDATION_FIELD(formatMessage).PASSWORD_THIS_SAME)
        .min(8, VALIDATION_FIELD(formatMessage).MIN_8_PASSWORD),
      new_password: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .min(8, VALIDATION_FIELD(formatMessage).MIN_8_PASSWORD),
    });
  return (
    <main className="login__wrapper">
      <div className="login__logo">
        <SVG type={SVG_TYPE.LOGO} />
      </div>
      <div className="login__form">
        <Formik
          validationSchema={validation()}
          initialValues={{ new_password: '', confirm_password: '', pass: token.replace(/['"]+/g, '') }}
          onSubmit={handleOnSubmit}
        >
          {({ handleSubmit, errors }) => (
            <form className="login__form" onSubmit={handleSubmit}>
              <div className="login__item">
                <h1 className="login--header login--header--small">{formatMessage({ id: 'global.set_password' })}</h1>
                <Field
                  type="password"
                  noEye
                  label={formatMessage({ id: 'global.set_password' })}
                  name="new_password"
                  component={RegularField}
                  errors={errors}
                />
                <Field
                  type="password"
                  noEye
                  label={formatMessage({ id: 'global.confirm_password' })}
                  name="confirm_password"
                  component={RegularField}
                  errors={errors}
                />
                {isError && <p className="add__photos--error">{formatMessage({ id: 'global.contact_admin' })}</p>}
                <Button className="btn--orange" label={formatMessage({ id: 'global.save_password' })} type="submit" />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </main>
  );
};

export default SetPassword;

SetPassword.propTypes = {
  actions: shape({
    setPassword: func.isRequired,
  }).isRequired,
};

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Button } from 'shared/components';
import PropTypes from 'prop-types';

const ButtonActions = ({ handleSubmit, openModal, formatMessage, isSubmitted,setIsPreview }) => (
  <div className="add-advertisement__buttons">
    <div className="add-advertisement__buttons--right">
      <p onClick={async() => { await setIsPreview(true); handleSubmit()}} className="add-advertisement__buttons--preview">
        {formatMessage({ id: 'global.preview_addvertisement' })}
      </p>
      <div className="add-advertisement__buttons--actions">
        {!isSubmitted ? (
          <>
            <Button
              className="btn--orange-border"
              label={formatMessage({ id: 'inputs.cancel' })}
              onClick={() => openModal(true)}
            />
            <Button className="btn--orange" label={formatMessage({ id: 'inputs.add_advertisement' })} onClick={handleSubmit} />
          </>
        ) : (
          <>
            <Button className="btn--disabled-border" label={formatMessage({ id: 'inputs.cancel' })} />
            <Button className="btn--submitted-gray" label={formatMessage({ id: 'inputs.add_advertisement' })} />
          </>
        )}
      </div>
    </div>
  </div>
);

export default ButtonActions;

ButtonActions.propTypes = {
  formatMessage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  setIsPreview: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

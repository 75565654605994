import React from 'react';
import * as Connector from 'shared/connectors/categoryConnector';
import { CATEGORY } from 'store/types';
import { getAllCategoryAndSubcategory } from 'store/actions/category';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import { NOTIFY_TYPE } from 'shared/consts';
import { addFiles } from 'store/actions/utils';

export const success = () => ({
  type: CATEGORY.EDIT_CATEGORY_SUCCESS,
});

const editCategory = (uuid, form) => async dispatch => {
  try {
    const linkModified = form.link.replace(/\s/g, '-').replace('/', '');
    const { files } = form;
    const data = await Connector.editCategory(uuid, { name: form.name, name_ger: form.name_ger, link: linkModified });
    if (files) {
      const fileForm = new FormData();
      fileForm.append('file', files[0]);
      const result = await dispatch(addFiles(fileForm, form.uuid, 'category_uuid='));
      if (result !== true) {
        // eslint-disable-next-line no-throw-literal
        throw{response: "cannot add this photo"}
      }
    }
    dispatch(success(form));
    dispatch(getAllCategoryAndSubcategory());
    // await Connector.editCategory(uuid, data);
    // await dispatch(getAllCategoryAndSubcategory());
    dispatch(success(uuid));
    toast(<Notify label="global.successful_edit_category" type={NOTIFY_TYPE.CHECKED} />);
    return data;
  } catch (err) {
    return err.response;
  }
};

export default editCategory;

import * as Connector from 'shared/connectors/userConnector';
import { USER } from 'store/types';

const init = () => ({
  type: USER.GET_USERS_FOR_APPLICATION_INIT,
});

export const success = data => ({
  type: USER.GET_USERS_FOR_APPLICATION_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: USER.GET_USERS_FOR_APPLICATION_FAILURE,
});

const getUsersForApplication = () => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getUsersForApplication();
    dispatch(success(data.clients));
  } catch (err) {
    window.location.reload();
    dispatch(failure());
  }
};

export default getUsersForApplication;

/* eslint react/prop-types: 0 */

import React from 'react';

const TransferTitle = ({ data }) => (
  <div>
    <div className="productTitle">{data?.product?.title.toUpperCase()}</div>
  </div>
);

export default TransferTitle;

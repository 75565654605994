const MODAL_TYPE = {
  CANCEL: 'cancel',
  ADD_CATEGORY: 'add-category',
  EDIT_CATEGORY: 'edit-category',
  DELETE_MODAL: 'delete-modal',
  PARTNER: 'partner',
  ASSIGN: 'assign',
  ASSIGN_PARTNER: 'assign-partner',
  BASIC: 'basic',
  EDIT_PARTNER: 'edit-partner',
  EDIT_COURIER: 'edit-courier',
  SEARCHING: 'searching',
  EDIT_PRICE: 'edit-price',
  ADVERTISEMENTS: 'advertisements',
  BACK_OFF: 'back-off',
  EDIT_WAREHOUSE: 'edit-warehouse',
  DELETE_WAREHOUSER: 'delete-warehouse',
  ADD_TRANSFER: 'add-transfer',
  DELIVERY: 'delivery',
  RESUME: 'resume',
  COOKIE_ACCEPT: 'cookie_accept'
};

export default MODAL_TYPE;

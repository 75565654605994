import { Field, Formik } from 'formik';
import { func, object } from 'prop-types';
import React from 'react';
import { RegularField, SVG } from 'shared/components';
import { SVG_TYPE, VALIDATION_FIELD } from 'shared/consts';
import * as Yup from 'yup';

const ChatForm = ({ formatMessage, sendMessage, room }) => {
  const handleOnSubmit = (values, { resetForm }) => {
    sendMessage({ room_uuid: room, content: values.content });
    resetForm();
  };
  const validation = () =>
    Yup.object().shape({
      content: Yup.string()
        .max(500, VALIDATION_FIELD(formatMessage).MAX_500)
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE),
    });
  return (
    <div className="help-chat__chat__send__wrapper">
      <Formik validationSchema={validation()} initialValues={{ content: '' }} onSubmit={handleOnSubmit}>
        {({ handleSubmit, errors }) => (
          <form className="help-chat__chat__send" onSubmit={handleSubmit}>
            <Field
              type="text"
              name="content"
              component={RegularField}
              errors={errors}
              placeholder={formatMessage({ id: 'global.your_message' })}
            />
            <button type="submit" className="chat__form--btn">
              <SVG type={SVG_TYPE.SENDER} />
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};
export default ChatForm;

ChatForm.propTypes = {
  formatMessage: func.isRequired,
  room: object.isRequired,
  sendMessage: object.isRequired,
};

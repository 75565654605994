/* eslint react/prop-types: 0 */

import React from 'react';
import { COUNTRY } from 'screens/magazine/utils/consts';

const TransferCountry = ({ data, foreignCountry }) => (
  <div>
    <span>{!foreignCountry ? COUNTRY[data?.import_country.country] : COUNTRY[data?.export_country.country]}</span>
  </div>
);

export default TransferCountry;

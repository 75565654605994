import detectBrowserLanguage from 'detect-browser-language';

function browserLanguage(setAppTranslation) {
  if (detectBrowserLanguage().slice(-2).toLowerCase() === 'de') {
    setAppTranslation('de');
    return 'de';
  }
  setAppTranslation('pl');
  return 'pl';

  // console.log(detectBrowserLanguage().slice(-2).toLowerCase());
}

export default browserLanguage;

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from 'shared/consts';
import MainRoutes from 'screens/main-routes/MainRoutes.container';

const Routes = () => (
  <div className="main-wrapper">
    <Switch>
      <Route component={MainRoutes} path={ROUTES.HOME_PAGE} />
    </Switch>
  </div>
);

export default Routes;

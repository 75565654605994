import React from 'react';
import { Breadcrumbs, PhotoSlider, SingleTile } from 'shared/components';
import {
  ProductName,
  ProductDescript,
  SellerComment,
  Seller,
  // ProductDelivery,
} from 'screens/single-product/components';
import { bool, func, object, string } from 'prop-types';
import InputCounter from '../inputCounter';

const Content = ({
  isMobile,
  formatMessage,
  data,
  translation,
  modifyShoppingCart,
  addToFavorites,
  removeFromFavorites,
  authorizedUser,
  categories,
  shoppingCart,
  deliveryCountries,
}) => (
  <div>
    <Breadcrumbs data={data} translation={translation} categories={categories} formatMessage={formatMessage} back />
    <div className="single-product__content">
      <div>
        <div
          className={`single-product__slider  ${data?.files && data?.files?.length < 2 ? 'single-product__slider--small' : ''}`}
        >
          {isMobile && <h1 className="product-name--label">{translation === 'pl' ? data.title : data.title_ger}</h1>}
          <PhotoSlider data={data} />
        </div>
        {!isMobile && (
          <div className="single-product__descript">
            <ProductDescript translation={translation} categories={categories} data={data} formatMessage={formatMessage} />{' '}
            <SellerComment translation={translation} data={data} />
          </div>
        )}
      </div>
      <div>
        <ProductName
          shoppingCart={shoppingCart}
          authorizedUser={authorizedUser}
          translation={translation}
          data={data}
          formatMessage={formatMessage}
          addToFavorites={addToFavorites}
          removeFromFavorites={removeFromFavorites}
          modifyShoppingCart={modifyShoppingCart}
          InputCounter={InputCounter}
          isMobile={isMobile}
        />
        <Seller
          deliveryCountries={deliveryCountries}
          formatMessage={formatMessage}
          data={data}
          translation={translation}
          authorizedUser={authorizedUser}
        />
        <SingleTile
          header={formatMessage({ id: 'global.guaranteed_30_days' })}
          descript={formatMessage({ id: 'global.guaranteed_30' })}
          secondDescript={formatMessage({ id: 'global.full_refund' })}
          color="orange"
          button="arrow-right"
          guaranted
        />
      </div>

      {!isMobile && !authorizedUser && (
        <SingleTile
          header={formatMessage({ id: 'global.plan_your_business_join' })}
          descript={formatMessage({ id: 'global.here_buy_parts' })}
          color="light-orange"
          double
          join={formatMessage({ id: 'inputs.join_now' })}
        />
      )}
      {isMobile && (
        <div className="single-product__descript">
          <ProductDescript translation={translation} categories={categories} data={data} formatMessage={formatMessage} />{' '}
          <SellerComment translation={translation} data={data} />
        </div>
      )}
      {isMobile && !authorizedUser && (
        <SingleTile
          header={formatMessage({ id: 'global.plan_your_business_join' })}
          descript={formatMessage({ id: 'global.here_buy_parts' })}
          color="light-orange"
          double
          join={formatMessage({ id: 'inputs.join_now' })}
        />
      )}
    </div>
  </div>
);

export default Content;

Content.propTypes = {
  addToFavorites: func.isRequired,
  authorizedUser: object.isRequired,
  categories: object.isRequired,
  data: object.isRequired,
  deliveryCountries: object.isRequired,
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
  modifyShoppingCart: func.isRequired,
  removeFromFavorites: func.isRequired,
  shoppingCart: object.isRequired,
  translation: string.isRequired,
};

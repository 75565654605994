import React, { useEffect } from 'react';
import { string, shape, func } from 'prop-types';
import { SVG } from 'shared/components';
import { ROUTES, SVG_TYPE } from 'shared/consts';
import { history } from 'store/store';
import { injectIntl } from 'react-intl';

const AllegroConnection = ({ intl, actions: { getAllegroUser } }) => {
  const { formatMessage } = intl;
  const isSuccessfull = window.location.search.substring(0, 5) === '?code';

  useEffect(async () => {
    if (isSuccessfull) {
      const code = window.location.search.split('=');
      await getAllegroUser(code[1]);
    }
  }, [isSuccessfull]);

  return (
    <div className="allegro-successful-connection__wrapper">
      {isSuccessfull ? (
        <div className="allegro-successful-connection__content">
          <p className="allegro-successful-connection__content--text">
            {formatMessage({ id: 'global.successful_connect_to_allegro' })} <SVG type={SVG_TYPE.LOGO_ALLEGRO} />
          </p>
          <SVG type={SVG_TYPE.SUCCESS_ALLEGRO_CONNECTION} />
          <div onClick={() => history.push(ROUTES.HOME_PAGE)} className="btn btn--orange">
            {formatMessage({ id: 'global.home_page' })}
          </div>
        </div>
      ) : (
        <div className="allegro-successful-connection__content">
          <p className="allegro-successful-connection__content--text">
            {formatMessage({ id: 'global.error_connection' })} <SVG type={SVG_TYPE.LOGO_ALLEGRO} />{' '}
            <SVG type={SVG_TYPE.CIRCLE_RED_CLOSE} />
          </p>
          <span>{formatMessage({ id: 'global.error_connection_allegro_text' })}</span>
          <div className="photo">
            <SVG type={SVG_TYPE.ERROR_ALLEGRO} />
            <div onClick={() => history.push(ROUTES.HOME_PAGE)} className="btn btn--orange">
              {formatMessage({ id: 'inputs.settings' })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default injectIntl(AllegroConnection);

AllegroConnection.propTypes = {
  actions: shape({
    getAllegroUser: func.isRequired,
  }).isRequired,
  location: string.isRequired,
};

/* eslint react/prop-types: 0 */
import React from 'react';

const ForeignMargin = ({ data }) => {
  if (data.price_margin) {
    const {
      currency,
      price_margin: { foreign_margin, isPercentageForeign },
    } = data;

    const UTIL = {
      eur: 'EUR',
      pln: 'PLN',
    };

    return (
      <span>
        {foreign_margin} {isPercentageForeign ? '%' : UTIL[currency]}
      </span>
    );
  }
  return null;
};

export default ForeignMargin;

import * as Connector from 'shared/connectors/paymentsConnector';
import { PRICE } from 'store/types';
import moment from 'moment';

export const success = () => ({
  type: PRICE.GET_CURRENCY_EXCHANGE_LIST_SUCCESS,
});

const createCheckoutSession = form => async () => {
  try {
    const { data } = await Connector.createCheckoutSession(form.uuid);
    const endTime = moment().add(30, 'minutes').format('HH:mm');
    !form.isUserLogged && localStorage.setItem('orderEndTime', endTime);
    localStorage.removeItem('deliveryProducts');
    window.open(data.session_url, '_self');
    return data;
  } catch (err) {
    return err.response;
  }
};

export default createCheckoutSession;

import React from 'react';
import RTable from 'react-table-v6';
import { arrayOf, shape, string, bool, func, number } from 'prop-types';
import { Pagination } from './components';


const Table = ({
  className = '',
  columns,
  data,
  expanded = {},
  SubComponent,
  filterable,
  activeRowId,
  onActiveUpdate,
  isMobile,
  formatMessage,
  rows,
  showPaginationTop,
  ...rest
}) => {
  const pageSize = () => {
    if (rows) {
      return rows;
    }
    if (data?.length > 10) {
      return 10;
    }
    if (data === undefined) {
      return 0;
    }
    return data.length;
  };
  


  return (
    <div>
      <RTable
        className={className}
        columns={columns}
        data={data}
        defaultFilterMethod={({ id, value }, row) => row[id].toLowerCase().indexOf(value.toLowerCase()) > -1}
        expanded={expanded}
        defaultPageSize={pageSize()}
        showPaginationTop={showPaginationTop}
        minRows={1}
        filterable={filterable}
        loadingText=""
        sortable
        noDataText={formatMessage ? formatMessage({ id: 'global.no_data' }) : 'no data'}
        SubComponent={SubComponent}
        PaginationComponent={Pagination}
        TheadComponent={() => null}
        getTrProps={(state, rowInfo) => {
          if (rowInfo && rowInfo.original.is_suspended) {
            return {
              className: 'row-user-suspended',
              // style: {
              //   color: '#929292',
              // }
            };
          }
          return {};
        }}
        {...rest}
      />
    </div>
  );
};

Table.propTypes = {
  activeRowId: number,
  className: string,
  columns: arrayOf(shape({})).isRequired,
  data: arrayOf(shape({})).isRequired,
  expanded: shape({}),
  filterable: bool,
  formatMessage: func,
  isMobile: bool,
  onActiveUpdate: func,
  rows: string,
  showPaginationTop:bool,
  SubComponent: shape({}),
};
Table.defaultProps = {
  activeRowId: null,
  className: '',
  expanded: {},
  filterable: false,
  formatMessage: undefined,
  isMobile: false,
  onActiveUpdate: undefined,
  rows: null,
  showPaginationTop: false,
  SubComponent: undefined,
};
export default Table;

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { registerUser } from 'store/actions/user';
import { injectIntl } from 'react-intl';
import * as ViewManagementSelector from 'store/selectors/viewManagementSelector';
import { getDeliveryCountries } from 'store/actions/delivery';
import { getDeliveryCountries as getDeliveryCountriesSelector } from 'store/selectors/deliverySelector';
import Register from './Register.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      registerUser: data => registerUser(data),
      getDeliveryCountries: () => getDeliveryCountries(),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  translation: ViewManagementSelector.getTranslation(state),
  deliveryCountries: getDeliveryCountriesSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Register));

import { SHOPPING_CART } from 'store/types';

export const success = () => ({
  type: SHOPPING_CART.GET_SHOPPING_CART_INIT,
});

const clearShoppingCart = () => (dispatch) => {
  try {
    dispatch(success());
    return true;
  } catch (err) {
    return err.response;
  }
};

export default clearShoppingCart;

import React, { useEffect, useRef } from 'react';
import { SearchEngine, SelectField } from 'shared/components';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { MENU_TYPES } from '../utils/menuUtils';

const MagazineFilters = ({ isMobile, setFilters, filters, categoriesOptions, menuType, formatMessage, STATUS_OPTIONS }) => {
  const searchRef = useRef(null);

  const productStatuses = [
    { label: formatMessage({ id: 'global.all' }), value: 'all' },
    { label: formatMessage({ id: 'global.active' }), value: 'active' },
    { label: formatMessage({ id: 'global.recalled2' }), value: 'cancelled' },
    { label: formatMessage({ id: 'global.allegro' }), value: 'allegro' },
    { label: formatMessage({ id: 'global.sold' }), value: 'sold' },
    { label: formatMessage({ id: 'global.allegro_sold' }), value: 'allegro_sold' },
  ];

  const handleStatusChange = e => {
    setFilters({ status: e.value || 'all', title: filters.title, category_uuid: filters.category_uuid });
  };

  const handleInputChange = debounce(e => {
    const {
      target: { value },
    } = e;
    setFilters({ ...filters, title: value });
  }, 1000);

  const handleSelectChange = e => {
    const { value } = e;
    setFilters({ ...filters, category_uuid: value });
  };

  const handleStatusSelectChange = e => {
    const { value } = e;
    setFilters({ ...filters, status: value });
  };

  useEffect(() => {
    setFilters({ status: 'all', title: '', category_uuid: '' });
    searchRef.current?.setFieldValue('search', '');
  }, [menuType]);

  return (
    <>
      {!isMobile ? (
        <div
          className={`advertisements__filters__wrapper filters ${
            menuType === MENU_TYPES(formatMessage).ORDERS ? 'filters--orders' : ''
          }`}
        >
          <SearchEngine
            className="search-field--advertisements filters__search-field"
            handleChange={handleInputChange}
            innerRef={searchRef}
            placeholder={formatMessage({ id: 'inputs.title' })}
          />

          {menuType === MENU_TYPES(formatMessage).PRODUCTS && (
            <>
              <SelectField
                isCustomLabel
                className="advertisements__filters--category filters__category"
                placeholder={formatMessage({ id: 'inputs.choose' })}
                label={formatMessage({ id: 'global.category' })}
                outsideFormChange={handleSelectChange}
                options={categoriesOptions.categories}
              />
              <SelectField
                isCustomLabel
                className="advertisements__filters--category filters__category"
                placeholder={formatMessage({ id: 'inputs.choose' })}
                label={formatMessage({ id: 'global.status' })}
                outsideFormChange={handleStatusChange}
                options={productStatuses}
              />
            </>
          )}
          {menuType === MENU_TYPES(formatMessage).ORDERS && (
            <SelectField
              isCustomLabel
              className="advertisements__filters--category filters__category"
              placeholder={formatMessage({ id: 'inputs.choose' })}
              label={formatMessage({ id: 'global.status' })}
              outsideFormChange={handleStatusSelectChange}
              options={STATUS_OPTIONS(formatMessage)}
            />
          )}
        </div>
      ) : (
        <div className="advertisements__filters__wrapper">
          {menuType !== MENU_TYPES(formatMessage).ORDERS && (
            <SearchEngine
              innerRef={searchRef}
              placeholder={formatMessage({ id: 'inputs.title' })}
              className="search-field--advertisements"
              handleChange={handleInputChange}
            />
          )}

          {menuType === MENU_TYPES(formatMessage).ORDERS && (
            <SelectField
              isCustomLabel
              className="advertisements__filters--category filters__category"
              placeholder={formatMessage({ id: 'inputs.choose' })}
              label={formatMessage({ id: 'global.status' })}
              outsideFormChange={handleStatusSelectChange}
              options={STATUS_OPTIONS(formatMessage)}
            />
          )}
          {menuType === MENU_TYPES(formatMessage).PRODUCTS && (
            <>
              <SelectField
                isCustomLabel
                className="advertisements__filters--category filters__category"
                placeholder={formatMessage({ id: 'inputs.choose' })}
                label={formatMessage({ id: 'global.category' })}
                outsideFormChange={handleSelectChange}
                options={categoriesOptions.categories}
              />
              <SelectField
                isCustomLabel
                className="advertisements__filters--category filters__category"
                placeholder={formatMessage({ id: 'inputs.choose' })}
                label={formatMessage({ id: 'global.status' })}
                outsideFormChange={handleStatusChange}
                options={productStatuses}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default MagazineFilters;

MagazineFilters.propTypes = {
  categoriesOptions: PropTypes.array.isRequired,
  fetchMethod: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  formatMessage: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  menuType: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  STATUS_OPTIONS: PropTypes.func.isRequired,
};

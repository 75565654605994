import React, { useState } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { VALIDATION_FIELD } from 'shared/consts';
import { func, object, bool } from 'prop-types';
import { Button, RegularField } from 'shared/components';

const ContactForm = ({ formatMessage, authorizedUser, isMobile, sendMessageContactForm }) => {
  const [isAgreementCheckd, setIsAgreementCheckd] = useState(false);
  const [isAgreementError, setIsAgreementError] = useState(false);
  const validation = () =>
    Yup.object().shape({
      email: Yup.string().email(VALIDATION_FIELD(formatMessage).EMAIL).required(VALIDATION_FIELD(formatMessage).REQUIRED),
      first_name: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254)
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE),
      phone: Yup.string()
        .min(9, VALIDATION_FIELD(formatMessage).DIGITS_9)
        .max(9, VALIDATION_FIELD(formatMessage).DIGITS_9)
        .matches(/^\d{9}$/, VALIDATION_FIELD(formatMessage).DIGITS_9),
      text: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE),
    });

  const onSubmit = async (values, { resetForm }) => {
    if (isAgreementCheckd) {
      // authorizedUser && delete values.first_name;
      const { status } = await sendMessageContactForm(values);
      status === 'success' && resetForm();
    } else {
      setIsAgreementError(true);
    }
  };

  return (
    <div className="contact__form--wrapper">
      <p className="contact__form--heading">{formatMessage({ id: 'global.contact_form' })}</p>
      <Formik
        validationSchema={validation()}
        initialValues={{
          user_uuid: authorizedUser ? authorizedUser.uuid : '',
          isAuth: !!authorizedUser,
          first_name: authorizedUser ? `${authorizedUser.first_name} ${authorizedUser.last_name}` : '',
          email: authorizedUser ? authorizedUser.email : '',
          phone: '',
          text: '',
        }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, errors }) => (
          <form className="contact__form" onSubmit={handleSubmit}>
            {!authorizedUser ? (
              <>
                <Field
                  type="text"
                  label={`${formatMessage({ id: 'inputs.name' })}*`}
                  name="first_name"
                  component={RegularField}
                  errors={errors}
                />
                {console.log(authorizedUser)}

                {isMobile ? (
                  <>
                    <Field
                      type="text"
                      label={`${formatMessage({ id: 'inputs.email' })}*`}
                      name="email"
                      component={RegularField}
                      errors={errors}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: 'global.telephone' })}
                      name="phone"
                      component={RegularField}
                      errors={errors}
                    />
                  </>
                ) : (
                  <div className="contact__form--half">
                    <Field
                      type="text"
                      label={`${formatMessage({ id: 'inputs.email' })}*`}
                      name="email"
                      component={RegularField}
                      errors={errors}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: 'global.telephone' })}
                      name="phone"
                      component={RegularField}
                      errors={errors}
                    />
                  </div>
                )}
              </>
            ) : (
              <Field
                type="text"
                label={formatMessage({ id: 'global.telephone' })}
                name="phone"
                component={RegularField}
                errors={errors}
              />
            )}
            <Field
              type="textarea"
              label={`${formatMessage({ id: 'global.your_message' })}*`}
              name="text"
              className={authorizedUser ? 'contact__form--textarea' : ''}
              component={RegularField}
              errors={errors}
            />
            <div
              className={`partnerProgram__modal__consent ${isAgreementCheckd ? 'partnerProgram__modal__consent--active' : ''}`}
              onClick={() => {
                setIsAgreementCheckd(!isAgreementCheckd);
                setIsAgreementError(!isAgreementError);
              }}
            >
              <p className="agreement">
                {formatMessage({ id: 'global.i_agree' })} <div className="underline">{formatMessage({ id: 'global.rule' })},</div>{' '}
                <div className="underline">{formatMessage({ id: 'global.privacy_policy2' })}</div>
              </p>
            </div>
            {isAgreementError && !isAgreementCheckd && (
              <div className="regular-field__label-error partnerProgram__error">
                {formatMessage({ id: 'validation.required' })}
              </div>
            )}
            <div className="contact__form--btn">
              <Button label={formatMessage({ id: 'inputs.send' })} type="submit" className="btn--orange" />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;

ContactForm.defaultProps = {
  authorizedUser: {},
};

ContactForm.propTypes = {
  authorizedUser: object,
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
  sendMessageContactForm: func.isRequired,
};

import React from 'react';
import * as Connector from 'shared/connectors/priceConnector';
import { PRICE } from 'store/types';
import { NOTIFY_TYPE } from 'shared/consts';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';

export const success = (itemUuid, type) => ({
  type: PRICE.DELETE_PRICE_MARGIN_SUCCESS,
  payload: {
    itemUuid,
    type,
  },
});

const deletePriceMargin = (uuid, type, itemUuid) => async dispatch => {
  try {
    const { data } = await Connector.deletePriceMargin(uuid);
    toast(<Notify label="global.successfully_edited_margin" type={NOTIFY_TYPE.CHECKED} />);
    dispatch(success(itemUuid, type));
    return data;
  } catch (err) {
    return err.response;
  }
};

export default deletePriceMargin;

import React from 'react';
import { bool, func, array, object } from 'prop-types';
import { Modal } from 'shared/components';
import { MODAL_TYPE } from 'shared/consts';
import { SearchByVehicle } from 'screens/products-lists/components';

const EditCatModal = ({
  isOpened,
  getProductsByFilter,
  formatMessage,
  carBrands,
  carModels,
  categories,
  getCarModels,
  getCarBrands,
  deliveryCountries,
  setIsModalOpened,
  categoryByLink,
  getCategoryByLink,
}) => (
  <div>
    {isOpened && (
      <Modal className={MODAL_TYPE.SEARCHING} opened={isOpened}>
        <SearchByVehicle
          modal
          categories={categories}
          getCategoryByLink={getCategoryByLink}
          categoryByLink={categoryByLink}
          setIsModalOpened={setIsModalOpened}
          getProductsByFilter={getProductsByFilter}
          formatMessage={formatMessage}
          carBrands={carBrands}
          carModels={carModels}
          getCarModels={getCarModels}
          getCarBrands={getCarBrands}
          deliveryCountries={deliveryCountries}
        />
      </Modal>
    )}
  </div>
);

export default EditCatModal;

EditCatModal.propTypes = {
  carBrands: array.isRequired,
  carModels: array.isRequired,
  categories: array.isRequired,
  categoryByLink: object.isRequired,
  close: func.isRequired,
  deliveryCountries: array.isRequired,
  formatMessage: func.isRequired,
  getCarBrands: func.isRequired,
  getCarModels: func.isRequired,
  getCategoryByLink: func.isRequired,
  getProductsByFilter: func.isRequired,
  isOpened: bool.isRequired,
  setIsModalOpened: func.isRequired,
};

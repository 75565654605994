import * as Connector from 'shared/connectors/carConnector';
import { CAR } from 'store/types';

const init = () => ({
  type: CAR.GET_CAR_BRANDS_INIT,
});

export const success = data => ({
  type: CAR.GET_CAR_BRANDS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: CAR.GET_CAR_BRANDS_FAILURE,
});

const getCarBrands = () => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getCarBrands();
    dispatch(success(data.brands));
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default getCarBrands;

/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { string, func, bool, object } from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';

const CoordinatorSettings = ({
  active,
  countryDelivery,
  role,
  email,
  settingsType,
  setSettingsType,
  id,
  suspended,
  userSuspension,
  uuid,
  userResendLink,
  setOpenModal,
  openModal,
  first_name,
  last_name,
  formatMessage,
  type,
}) => {
  const settingsTypes = {
    unactive: 'UNACTIVE',
    suspended: 'SUSPENDED',
    active: 'ACTIVE',
  };
  const showMail = active && !suspended;

  const handleClickSettings = () => {
    suspended ? (
      setSettingsType({ type: settingsTypes.suspended, id })
    ) : (
      <>{active ? setSettingsType({ type: settingsTypes.active, id }) : setSettingsType({ type: settingsTypes.unactive, id })} </>
    );
  };

  const closeSettings = () => {
    setSettingsType({ type: '', id: '' });
  };

  return (
    <div className="user-management__table__settings">
      <div className="user-management__table__settings--item">
        {showMail && (
          <a href={`mailto:${email}`}>
            <SVG type={SVG_TYPE.MAIL} className="svg--mail--gray" />
          </a>
        )}
      </div>
      <div
        className={`user-management__table__settings--item ${
          settingsType.id === id ? 'user-management__table__settings--item--disabled' : ''
        } `}
        onClick={() => handleClickSettings()}
      >
        <SVG type={SVG_TYPE.SETTINGS} />
      </div>
      {settingsType.type === settingsTypes.active && settingsType.id === id && (
        <OutsideClickHandler display="flex" onOutsideClick={() => setSettingsType({ type: '', id: '' }, 1)}>
          <div className="user-management__table__settings__list" id={id}>
            <div
              className="user-management__table__settings__list--item"
              onClick={() => {
                setOpenModal({ first_name, countryDelivery, last_name, role, email, uuid, isOpened: !openModal.isOpened });
                closeSettings();
              }}
            >
              {formatMessage({ id: 'global.edit' })}
            </div>
            <div
              className="user-management__table__settings__list--item"
              onClick={() => {
                userSuspension(uuid, type);
                closeSettings();
              }}
            >
              {formatMessage({ id: 'global.hang' })}
            </div>
          </div>
        </OutsideClickHandler>
      )}
      {settingsType.type === settingsTypes.unactive && settingsType.id === id && (
        <OutsideClickHandler display="flex" onOutsideClick={() => setSettingsType({ type: '', id: '' }, 10)}>
          <div className="user-management__table__settings__list" id={id}>
            <div
              className="user-management__table__settings__list--item"
              onClick={() => {
                setOpenModal({ first_name, last_name, email, role, countryDelivery, uuid, isOpened: !openModal.isOpened });
                closeSettings();
              }}
            >
              {formatMessage({ id: 'global.edit' })}
            </div>
            <div
              className="user-management__table__settings__list--item"
              onClick={() => {
                userSuspension(uuid, type);
                closeSettings();
              }}
            >
              {formatMessage({ id: 'global.hang' })}
            </div>
            <div
              className="user-management__table__settings__list--item"
              onClick={() => {
                userResendLink(uuid);
                closeSettings();
              }}
            >
              {formatMessage({ id: 'global.send_activate_link' })}
            </div>
          </div>
        </OutsideClickHandler>
      )}
      {settingsType.type === settingsTypes.suspended && settingsType.id === id && (
        <OutsideClickHandler display="flex" onOutsideClick={() => setSettingsType({ type: '', id: '' }, 10)}>
          <div className="user-management__table__settings__list" id={id}>
            <div
              className="user-management__table__settings__list--item"
              onClick={() => {
                userSuspension(uuid, type);
                closeSettings();
              }}
            >
              {formatMessage({ id: 'global.restore' })}
            </div>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default CoordinatorSettings;

CoordinatorSettings.defaultProps = {
  active: 'ACTIVE',
};

CoordinatorSettings.propTypes = {
  active: string,
  countryDelivery: object.isRequired,
  email: string.isRequired,
  first_name: string.isRequired,
  formatMessage: func.isRequired,
  id: string.isRequired,
  last_name: string.isRequired,
  openModal: bool.isRequired,
  role: string.isRequired,
  setOpenModal: func.isRequired,
  setSettingsType: func.isRequired,
  settingsType: string.isRequired,
  suspended: bool.isRequired,
  type: string.isRequired,
  userResendLink: func.isRequired,
  userSuspension: func.isRequired,
  uuid: string.isRequired,
};



const phonePrefixOptions = () => {
    const arr = [
        {
            label: '+48',
            searchableLabel: '+48',
            value: '+48',
        },
        {
            label: '+49',
            searchableLabel: '+49',
            value: '+49',
        }
    ];

    return arr;
  };
  
  export default phonePrefixOptions;
import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
// import { resetPassword } from 'store/actions/user';
import { injectIntl } from 'react-intl';
import InactiveAccount from './InactiveAccount.main';

const mapDispatchToProps = () => ({
  // actions: bindActionCreators(
  //   {
  //     resetPassword: email => resetPassword(email),
  //   },
  //   dispatch,
  // ),
});

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(InactiveAccount));

/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { ProductMagazineAdress, StatePart, PartnerBadge, Button, SVG } from 'shared/components';
import { SVG_TYPE, ROUTES, USER_ROLES } from 'shared/consts';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { generatePath } from 'react-router';
import { Settings } from 'shared/components/product-list-item/components';

const ProductListItem = ({
  className,
  isMobile,
  formatMessage,
  data,
  addToFavorites,
  removeFromFavorites,
  translation,
  authorizedUser,
  advertisement,
  resumeMyProduct,
  deleteAdvertisement,
  cancelAdvertisement,
  setCurrentlyOpenSettingsUuid,
  currentlyOpenSettingsUuid,
  recallMyProduct,
  updateData,
}) => {
  const history = useHistory();
  const isUserRolePartner = data?.owner?.role === USER_ROLES.PARTNER;
  const isUserLogged = authorizedUser;
  const detailedProductLink = () =>
    history.push(
      generatePath(ROUTES.SINGLE_PRODUCT, {
        category: data.subcategory.link,
        product: data.uuid,
      }),
    );

  const addProductToFavorites = () => {
    addToFavorites(data.uuid, data.category_id, data.subcategory_id);
  };

  const removeProductFromFavorites = () => {
    removeFromFavorites(data.uuid, data.category_id, data.subcategory_id);
  };

  const resumeProduct = async uuid => {
    await resumeMyProduct(uuid);
    updateData();
  };
  const recallProduct = async uuid => {
    await recallMyProduct(uuid);
    updateData();
  };

  const { is_favorite, currency, price, title, files, title_ger, location, brand, model, uuid, status } = data;
  return (
    <>
      {!isMobile ? (
        <div className="product-list-item--wrapper">
          <div className={`product-list-item ${className}`}>
            <div className="product-list-item__photography" onClick={() => detailedProductLink()}>
              {files && files.length > 0 && files[0] ? (
                <img
                  className="product-list-item__photography--image"
                  src={files[0].miniature_path}
                  alt={files && files.length > 0 && files[0] && files[0].file_name}
                />
              ) : (
                <SVG className="product-list-item__photography--image" type={SVG_TYPE.DEFAULT_PHOTO} />
              )}
            </div>
            <div className="product-list-item__mid">
              <div className="product-list-item__mid--name" onClick={() => detailedProductLink()}>
                {translation === 'pl' ? title : title_ger}
              </div>
              <StatePart formatMessage={formatMessage} data={data} />
              <div className="product-list-item__mid--katalog">Marka: {brand && brand.length > 0 ? brand[0]?.brand : ''}</div>
              <div className="product-list-item__mid--katalog">Model: {model && model.length > 0 ? model[0].model : ''}</div>
              <ProductMagazineAdress location={location} className="product-list-item__mid--adress" />
            </div>
            <div className="product-list-item__right">
              <p className="product-list-item__right--price">
                {price} <span className="currency">{currency}</span>
              </p>
              <p className="product-list-item__right--brutto">{formatMessage({ id: 'global.brutto' })}</p>
              {isUserRolePartner && (
                <PartnerBadge formatMessage={formatMessage} className="product-list-item__right--partner-badge" />
              )}
              {/* <p className="product-list-item__right--pick-up">{formatMessage({ id: 'global.pickup_at_the_point' })}</p> */}
              <div className="product-name--watching product-list-item__right--button">
                {!advertisement && (
                  <Button
                    className={`product-list-item__right--button ${
                      !isUserLogged ? 'product-list-item__right--button--disabled' : ''
                    }`}
                    onClick={is_favorite ? removeProductFromFavorites : addProductToFavorites}
                    label={formatMessage({ id: 'inputs.watch' })}
                    iconType={is_favorite ? SVG_TYPE.FULL_FILL_HEART : SVG_TYPE.FULL_HEART}
                  />
                )}

                {!authorizedUser && (
                  <article className="product-name--watching--tooltip product-list-item--tooltip">
                    {formatMessage({ id: 'global.watchlist_tooltip' })}
                  </article>
                )}
              </div>
              {advertisement && (
                <div className="product-list-item__right__buttons product-list-item__right__buttons--bars">
                  {status === 'canceled' && (
                    <Button
                      type="button"
                      className="product-list-item__right--button product-list-item__right--bars"
                      onClick={() => resumeProduct(uuid)}
                      label={formatMessage({ id: 'global.restore' })}
                    />
                  )}
                  {status === 'active' && (
                    <Button
                      type="button"
                      className="product-list-item__right--button product-list-item__right--bars"
                      onClick={() => recallProduct(uuid)}
                      label={formatMessage({ id: 'inputs.back_off' })}
                    />
                  )}
                  <Button
                    type="button"
                    className="product-list-item__right--button product-list-item__right--bars"
                    onClick={() => history.push({ pathname: '/edit-advertisement', state: { data } })}
                    label={formatMessage({ id: 'global.edit_addvertisement' })}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="product-list-item-mobile__wrapper ">
          <div
            className={`"product-list-item__photography product-list-item-mobile__photography"`}
            onClick={() => detailedProductLink()}
          >
            <img
              className={`product-list-item__photography--image `}
              src={files.length > 0 && files[0] && files[0].miniature_path}
              alt={files.length > 0 && files[0] && files[0].name_name}
            />
          </div>
          <div className="product-list-item-mobile__right">
            <div className="product-list-item-mobile--name--wrapper">
              <div className="product-list-item__mid--name product-list-item-mobile--name" onClick={() => detailedProductLink()}>
                {translation === 'pl' ? title : title_ger}
              </div>
              {advertisement ? (
                <div className={`user-management__table__settings--item `} onClick={() => setCurrentlyOpenSettingsUuid(uuid)}>
                  <SVG type={SVG_TYPE.SETTINGS} />
                </div>
              ) : (
                <SVG
                  className={`product-list-item-mobile--watched ${
                    !isUserLogged ? 'product-list-item-mobile--watched--disabled' : ''
                  }`}
                  type={SVG_TYPE.FULL_HEART}
                />
              )}
            </div>
            <div className="product-list-item-mobile--price">
              <p className="product-list-item__right--price product-list-item-mobile--price--label">
                {price} <span className="currency">{currency}</span>
              </p>
              <p className="product-list-item__right--brutto product-list-item-mobile__right--brutto">
                {formatMessage({ id: 'global.brutto' })}
              </p>
            </div>
            <div className="product-list-item-mobile__right--bottom">
              {isUserRolePartner && <PartnerBadge formatMessage={formatMessage} />}
              <StatePart formatMessage={formatMessage} data={data} />
              {advertisement && currentlyOpenSettingsUuid === uuid && (
                <Settings
                  onClose={() => setCurrentlyOpenSettingsUuid(null)}
                  history={history}
                  data={data}
                  deleteAdvertisements={deleteAdvertisement}
                  resumeMyProduct={resumeProduct}
                  cancelAdvertisement={cancelAdvertisement}
                  formatMessage={formatMessage}
                  recallMyProduct={recallProduct}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

ProductListItem.propTypes = {
  addToFavorites: PropTypes.func.isRequired,
  advertisement: PropTypes.object,
  authorizedUser: PropTypes.object.isRequired,
  cancelAdvertisement: PropTypes.func.isRequired,
  className: PropTypes.string,
  currentlyOpenSettingsUuid: PropTypes.string,
  data: PropTypes.array,
  del: PropTypes.bool,
  deleteAdvertisement: PropTypes.func.isRequired,
  formatMessage: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  orders: PropTypes.bool,
  recallMyProduct: PropTypes.func.isRequired,
  removeFromFavorites: PropTypes.func.isRequired,
  resumeMyProduct: PropTypes.func.isRequired,
  setCurrentlyOpenSettingsUuid: PropTypes.func,
  settings: PropTypes.bool,
  translation: PropTypes.string.isRequired,
  updateData: PropTypes.func.isRequired,
  watched: PropTypes.bool,
};
ProductListItem.defaultProps = {
  advertisement: {},
  className: '',
  currentlyOpenSettingsUuid: null,
  data: {},
  del: false,
  orders: false,
  setCurrentlyOpenSettingsUuid: () => null,
  settings: false,
  watched: false,
};

export default ProductListItem;

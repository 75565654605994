import { func, object } from 'prop-types';
import React from 'react';
import { Button } from 'shared/components';
import { ROUTES } from 'shared/consts';
import { useHistory } from 'react-router-dom';

const Right = ({ formatMessage, summary, setCheckout, clearShoppingCart, createCheckoutSession, authorizedUser }) => {
  const history = useHistory();

  const payNow = async () => {
    const response = await setCheckout({ pay_later: false, loggedUser: authorizedUser });
    if (response?.uuid) {
      await createCheckoutSession({ uuid: response.uuid, isUserLogged: authorizedUser });
    }
  };

  const payLater = async () => {
    const response = await setCheckout({ pay_later: true });
    if (response?.pay_later) {
      history.push(ROUTES.PAY_LATER);
      clearShoppingCart();
    }
  };

  return (
    <div id="right-section--wrapper" className="right__wrapper">
      <div id="right-section" className="right right--second">
        <div className="heading">{formatMessage({ id: 'global.to_pay' })}</div>

        <div className="line">
          <p className="paragraph">{formatMessage({ id: 'global.value_products' })}</p>
          <p className="paragraph">
            {summary.data.products_price} <span>{summary.data.currency}</span>
          </p>
        </div>
        <div className="line">
          <p className="paragraph">{formatMessage({ id: 'global.shipment' })}</p>
          <p className="paragraph">
            {summary.data.delivery_price} <span>{summary.data.currency}</span>
          </p>
        </div>

        <div className="border-bottom" />
        <div className="line line--bold">
          <p className="paragraph">{formatMessage({ id: 'global.together' })}</p>
          <p className="paragraph">
            {summary.data.final_price} <span>{summary.data.currency}</span>
          </p>
        </div>

        <div className="buttons">
          <Button className="btn--orange" label={formatMessage({ id: 'global.i_order_and_pay' })} onClick={() => payNow()} />
          {authorizedUser && (
            <Button
              className="btn--orange-border"
              label={formatMessage({ id: 'global.order_and_pay_later' })}
              onClick={() => payLater()}
            />
          )}
          <p className="descript">{formatMessage({ id: 'global.confirm_the_purchase' })}</p>
        </div>
      </div>
    </div>
  );
};

export default Right;

Right.propTypes = {
  authorizedUser: object.isRequired,
  clearShoppingCart: func.isRequired,
  createCheckoutSession: func.isRequired,
  formatMessage: func.isRequired,
  setCheckout: func.isRequired,
  summary: object.isRequired,
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { isMobile } from 'shared/utils';

const Pagination = ({ canNext, canPrevious, page, pages, onPageChange, data, defaultPageSize }) => {
  const [visiblePages, setVisiblePage] = useState(pages === 2 ? [0, 1] : [0, 1, 2]);
  const [selectedPage, SetSelectedPage] = useState(1);
  const [prevData, setPrevData] = useState(data.length);
  const getVisiblePages = singlePage => {
    // desktop
    if (!isMobile() && pages < 7) {
      const temp = [];
      for (let i = 0; i < pages; i++) {
        temp.push(i);
      }
      setVisiblePage(temp);
    } else if (!isMobile() && pages >= 7 && singlePage < 4) {
      const temp = [];
      for (let i = 0; i < pages - 2; i++) {
        temp.push(i);
      }
      pages - singlePage <= 3 && temp.push(pages - 2);
      pages - singlePage <= 2 && temp.push(pages - 1);
      temp.splice(5, pages - 3);
      setVisiblePage(temp);
    } else if (!isMobile() && pages > 5 && singlePage >= 4 && pages - singlePage > 4) {
      const temp = [];
      temp.push(singlePage - 1, singlePage, singlePage + 1);
      setVisiblePage(temp);
    } else if (!isMobile() && pages > 5 && singlePage >= 4 && pages - singlePage === 4) {
      const temp = [];
      temp.push(singlePage - 1, singlePage, singlePage + 1,singlePage+2);
      setVisiblePage(temp);
    }
    else if (!isMobile() && pages > 5 && singlePage > 4 && pages - singlePage === 3) {
      const temp = [];
      temp.push(singlePage - 2, singlePage - 1, singlePage, singlePage + 1);
      setVisiblePage(temp);
    } else if (!isMobile() && pages > 5 && singlePage > 4 && pages - singlePage === 2) {
      const temp = [];
      temp.push(singlePage - 3, singlePage - 2, singlePage - 1, singlePage, singlePage + 1);
      setVisiblePage(temp);
    } else if (!isMobile() && pages > 5 && singlePage > 4 && pages - singlePage === 1) {
      const temp = [];
      temp.push(singlePage - 4, singlePage - 3, singlePage - 2, singlePage - 1, singlePage);
      setVisiblePage(temp);
    }

    // mobile
    if (isMobile() && pages <= 5) {
      const temp = [];
      for (let i = 0; i < pages; i++) {
        temp.push(i);
      }
      setVisiblePage(temp);
    } else if (isMobile() && pages > 5 && singlePage < 2) {
      const temp = [];
      for (let i = 0; i < pages - 2; i++) {
        temp.push(i);
      }
      pages - singlePage <= 3 && temp.push(pages - 2);
      pages - singlePage <= 2 && temp.push(pages - 1);
      temp.splice(3, pages - 3);
      setVisiblePage(temp);
    } else if (isMobile() && pages > 5 && singlePage === 2) {
      setVisiblePage([0, 1, 2]);
    } else if (isMobile() && pages > 5 && singlePage > 2 && pages - singlePage > 3) {
      setVisiblePage([singlePage - 1, singlePage, singlePage + 1]);
    } else if (isMobile() && pages > 5 && singlePage >= 2 && pages - singlePage === 3) {
      const temp = [];
      temp.push(singlePage, singlePage + 1, singlePage + 2);
      setVisiblePage(temp);
    } else if (isMobile() && pages > 5 && singlePage >= 4 && pages - singlePage === 2) {
      const temp = [];
      temp.push(singlePage - 1, singlePage, singlePage + 1);
      setVisiblePage(temp);
    } else if (isMobile() && pages > 5 && singlePage >= 4 && pages - singlePage === 1) {
      const temp = [];
      temp.push(singlePage - 2, singlePage - 1, singlePage);
      setVisiblePage(temp);
    }
  };
  useEffect(() => {
    const newPage = Math.floor(data.length / defaultPageSize);
    data.length - prevData === 1 && (getVisiblePages(newPage), onPageChange(newPage), SetSelectedPage(newPage));
    setPrevData(data.length);
  }, [data]);

  useEffect(() => {
    getVisiblePages(1);
  }, [pages]);
  return (
    <>
      {pages <= 1 ? null : (
        <div className="pagination__wrapper">
          <div className="pagination__content">
            <div
              className={`pagination__item pagination__previous ${!canPrevious ? 'pagination__item--disabled' : ''}`}
              onClick={() => {
                getVisiblePages(page - 1);
                onPageChange(page - 1);
                SetSelectedPage(page - 1);
              }}
            >
              <SVG className="pagination__previous--icon" type={SVG_TYPE.CHEVRONE} />
            </div>
            <div className="pagination__numbers">
              {pages > (isMobile() ? 5 : 7) &&
                selectedPage >= (isMobile() ? 2 : 4) &&
                (isMobile() ? pages - selectedPage <= 3 : true) && (
                  <div
                    className="pagination__item"
                    onClick={() => {
                      onPageChange(0);
                      getVisiblePages(1);
                      SetSelectedPage(0);
                    }}
                  >
                    {1}
                  </div>
                )}
              {pages > (isMobile() ? 5 : 7) && selectedPage >= (isMobile() ? 3 : 4) && (
                <div className="pagination__item pagination--ellipsis">...</div>
              )}
              {visiblePages.map((singlePage, index, array) => (
                <div
                  className={`pagination__item ${singlePage === page ? 'pagination__item--active' : ''}`}
                  onClick={() => {
                    getVisiblePages(singlePage);
                    onPageChange(singlePage);
                    SetSelectedPage(singlePage);
                  }}
                >
                  {array[index] + 1}
                </div>
              ))}
            </div>
            {pages > (isMobile() ? 5 : 7) && pages - selectedPage > (isMobile() ? 3 : 4) && page !== pages - 1 && page !== pages - 2 && (
              <div className="pagination__item pagination--ellipsis">...</div>
            )}

            {pages > (isMobile() ? 5 : 7) && page !== pages - 1 && page !== pages - 2 && (isMobile() ? selectedPage < 3 : true) && (
              <div
                className="pagination__item"
                onClick={() => {
                  onPageChange(pages);
                  getVisiblePages(pages - 1);
                  SetSelectedPage(pages);
                }}
              >
                {pages}
              </div>
            )}
            <div
              className={`pagination__item pagination__next ${!canNext ? 'pagination__item--disabled' : ''}`}
              onClick={() => {
                getVisiblePages(page + 1);
                onPageChange(page + 1);
                SetSelectedPage(page + 1);
              }}
            >
              <SVG className="pagination__previous--icon" type={SVG_TYPE.CHEVRONE} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Pagination;

Pagination.propTypes = {
  canNext: PropTypes.bool.isRequired,
  canPrevious: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  defaultPageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
};

import * as Connector from 'shared/connectors/roomsConnector';
import { ROOM } from 'store/types';

const init = () => ({
  type: ROOM.GET_ROOM_MESSAGES_INIT,
});

export const success = data => ({
  type: ROOM.GET_ROOM_MESSAGES_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: ROOM.GET_ROOM_MESSAGES_FAILURE,
});

const getRoomMessages = (data, noinit) => async dispatch => {
  !noinit && dispatch(init());
  try {
    const response = await Connector.getRoomMessages(data);
    dispatch(success(response.data.messages));
    return response;
  } catch (err) {
    dispatch(failure());
    console.log(err);
    return err.response;
  }
};

export default getRoomMessages;

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setNewPassword } from 'store/actions/user';
import { injectIntl } from 'react-intl';
import NewPassword from './NewPassword.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setNewPassword: data => setNewPassword(data),
    },
    dispatch,
  ),
});

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(NewPassword));

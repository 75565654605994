import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
// import PropTypes from 'prop-types';
import { func, shape, string } from 'prop-types';
import { Field, Formik } from 'formik';
import { RegularField, SVG, Button } from 'shared/components';
import { SVG_TYPE, VALIDATION_FIELD, ROUTES } from 'shared/consts';
import { useHistory } from 'react-router-dom';

const RemindPassword = ({ actions: { resetPassword }, intl: { formatMessage }, translation }) => {
  const [isError, setIsError] = useState(false);
  const history = useHistory();
  const handleOnSubmit = async ({ email }) => {
    sessionStorage.setItem('emailReset', JSON.stringify(email.replace(/['"]+/g, '')));
    const response = await resetPassword(email, translation);
    response ? history.push(ROUTES.RESET_PASSWORD) : setIsError(true);
  };

  const validation = () =>
    Yup.object().shape({
      email: Yup.string()
        .email(VALIDATION_FIELD(formatMessage).EMAIL)
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .test('email-occupied', VALIDATION_FIELD(formatMessage).BAD_EMAIL, () => !isError),
    });

  return (
    <main className="login__wrapper">
      <div className="login__logo">
        <SVG type={SVG_TYPE.LOGO} />
      </div>
      <Formik validationSchema={validation()} initialValues={{ email: '' }} onSubmit={handleOnSubmit}>
        {({ handleSubmit, errors, setFieldTouched }) => (
          <form className="login__form" onSubmit={handleSubmit}>
            {useEffect(() => {
              setFieldTouched('email', isError);
            }, [isError])}
            <div className="login__item">
              <h1 className="login--header login--header--small">{formatMessage({ id: 'global.dont_remember_password' })}</h1>
              <p className="login__paragraph login__paragraph--black">
                {formatMessage({ id: 'global.enter_your_email_address' })}
              </p>
              <Field
                inputOnChange={() => {
                  setIsError(false);
                }}
                type="text"
                label={formatMessage({ id: 'inputs.email' })}
                name="email"
                component={RegularField}
                errors={errors}
              />
              <Button className="btn--orange" label={formatMessage({ id: 'inputs.send' })} type="submit" />
            </div>
          </form>
        )}
      </Formik>
    </main>
  );
};

export default RemindPassword;

RemindPassword.propTypes = {
  actions: shape({
    resetPassword: func.isRequired,
  }).isRequired,
  formatMessage: func.isRequired,
  translation: string.isRequired,
};

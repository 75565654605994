import * as Connector from 'shared/connectors/productsConnector';
import { PRODUCTS } from 'store/types';

const init = () => ({
  type: PRODUCTS.GET_PRODUCTS_BY_FILTER_INIT,
});
export const success = (data, subcategory_uuid, category_uuid) => ({
  type: PRODUCTS.GET_PRODUCTS_BY_FILTER_SUCCESS,
  payload: {
    data,
    subcategory_uuid,
    category_uuid,
  },
});

const failure = () => ({
  type: PRODUCTS.GET_PRODUCTS_BY_FILTER_FAILURE,
});

const getProductsByFilter = form => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getProductsByFilter(form);
    dispatch(success(data.category, form.subcategory_uuid, form.category_uuid));
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default getProductsByFilter;

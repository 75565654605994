import React, { useEffect, useState } from 'react';
import { array, bool, func, object, shape, string } from 'prop-types';
import { SidebarProduct } from 'shared/components';
import { SearchingModal } from 'screens/products-lists/components';
import { packagingProductsArray, isAllElementChecked } from 'screens/products-lists/utils';
import { querySearching, encodeBrand, subcategoriesFromUrl, encodeModel } from 'shared/utils';
import { Content } from './components';

const ProductsLists = ({
  isMobile,
  categories,
  categoryByLink,
  translation,
  products,
  authorizedUser,
  carBrands,
  deliveryCountries,
  carModels,
  actions: {
    getCategoryByLink,
    getProductsByFilter,
    getCarModels,
    getDeliveryCountries,
    getCarBrands,
    subtructProductsByCategory,
    addToFavoritesProductLists,
    removeFromFavoritesProductList,
    cleaningAllProducts,
  },
  intl: { formatMessage },
}) => {
  const isClickMenuSubcategory = localStorage.getItem('isSetCategory');
  const isSearchEngine = localStorage.getItem('runSearchEngine');
  const removeSearch = localStorage.getItem('removeSearch');
  const clickLanguageSwitch = localStorage.getItem('languageSwitch');
  const searchQuery = JSON.parse(localStorage.getItem('searchEngine')) || '';
  const [urlChange, setUrlChange] = useState({ trigger: false, value: '', isActive: false });
  const [isEmptyView, setIsEmptyView] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isFromEngine, setIsFromEngine] = useState(false);
  const { pathname, search } = window.location;
  const category = categoryByLink;
  const isSearching = search.slice(0, 2) === '?s';
  const userDeliveryCountry = (authorizedUser && authorizedUser?.country.label) || localStorage.getItem('deliveryCountry');
  const showAllProductsFromCategory = localStorage.getItem('showAll');
  const cat =
    categories?.category &&
    categories?.category.length > 0 &&
    categories.category.filter(item => item.link === querySearching().main_cat);
  const categoryid = cat && cat.length > 0 && cat[0].uuid;
  const fromUrl = !clickLanguageSwitch && !urlChange.value && !showAllProductsFromCategory;
  const isEmpty = search.substring(search.lastIndexOf('&') + 1) === 'empty';
  const arraySubCategoryFromUrl = subcategoriesFromUrl(search);
  const clickSubcategoryFromSidebar = urlChange.trigger;
  const isChangeCategorySearching = category && category?.category?.link !== querySearching().main_cat;

  if (!isFromEngine) {
    setIsFromEngine(true);
  }

  useEffect(() => {
    !userDeliveryCountry && setIsModalOpened(true);
  }, []);

  useEffect(async () => {
    if (isEmpty) {
      await cleaningAllProducts();
      setIsEmptyView(true);
    } else {
      setIsEmptyView(false);
    }
  }, [isEmpty]);

  localStorage.removeItem('languageSwitch');

  useEffect(async () => {
    await getDeliveryCountries();
  }, []);

  // useEffect(async () => {
  //   if ((!querySearching().main_cat && search === '?s') || fromUrl) {
  //     console.log('2');
  //     await getProductsByFilter({
  //       country_uuid: userDeliveryCountry || searchQuery.country_uuid,
  //       search: querySearching().s,
  //       brand_uuid: encodeBrand(querySearching().brand, carBrands),
  //       model_uuid: encodeModel(querySearching().model, carModels),
  //       year_of_production_start: querySearching().ys,
  //       year_of_production_end: querySearching().ye,
  //       language: translation,
  //       user_uuid: authorizedUser && authorizedUser.uuid,
  //     });
  //   }
  // }, []);

  useEffect(async () => {
    isChangeCategorySearching && isSearching && (await getCategoryByLink(querySearching().main_cat));
  }, [pathname, search]);

  useEffect(async () => {
    search === '?s' &&
      fromUrl &&
      (await getProductsByFilter({
        country_uuid: userDeliveryCountry || searchQuery.country_uuid,
        search: querySearching().s,
        language: translation,
        user_uuid: authorizedUser && authorizedUser.uuid,
      }));

    search === '?s' && fromUrl && console.log('to właśmie 1');
  }, [pathname, search]);

  useEffect(async () => {
    if (isAllElementChecked() && !clickLanguageSwitch && !showAllProductsFromCategory && urlChange.value) {
      // await cleaningAllProducts();
      console.log('4');
      await getProductsByFilter({
        country_uuid: userDeliveryCountry || searchQuery.country_uuid,
        category_uuid: categoryid,
        search: querySearching().s,
        brand_uuid: encodeBrand(querySearching().brand, carBrands),
        year_of_production_start: querySearching().ys,
        year_of_production_end: querySearching().ye,
        language: translation,
        user_uuid: authorizedUser && authorizedUser.uuid,
      });
      setIsEmptyView(false);
    }
  }, [isAllElementChecked(), search]);

  useEffect(async () => {
    const subCategoryLink = arraySubCategoryFromUrl && arraySubCategoryFromUrl.find(o => o === urlChange.value);
    const isSubCategories = arraySubCategoryFromUrl.length > 0;
    const isUnclick = !(categoryByLink.subcategories_count === arraySubCategoryFromUrl.length);
    const isAllSubCategoriesMoreThanZero = categoryByLink?.subcategories && categoryByLink?.subcategories?.length > 0;
    const allElements = document.getElementsByClassName('single-category-bar__list').length;
    const allElementsChecked = document
      .getElementById('sidebar--products')
      ?.getElementsByClassName('single-category-bar__list--active').length;
    if (!fromUrl) {
      if (!subCategoryLink && isSubCategories && isUnclick && isAllSubCategoriesMoreThanZero) {
        console.log('5');
        categoryByLink?.category?.id && (await subtructProductsByCategory(categoryByLink?.category?.id, urlChange.value));
      } else if (!isEmpty && !(allElements === allElementsChecked) && !isClickMenuSubcategory && !querySearching().s) {
        const subCategoryInfo = categoryByLink?.subcategories?.find(item => item.link === urlChange.value);
        console.log('5.5');
        setIsEmptyView(false);
        localStorage.removeItem('isSetCategory');
        await getProductsByFilter({
          country_uuid: userDeliveryCountry || searchQuery.country_uuid,
          search: querySearching().s,
          brand_uuid: encodeBrand(querySearching().brand, carBrands),
          model_uuid: encodeModel(querySearching().model, carModels),
          year_of_production_start: querySearching().ys,
          year_of_production_end: querySearching().ye,
          category_uuid: categoryid,
          subcategory_uuid: subCategoryInfo && subCategoryInfo.uuid,
          language: translation,
          user_uuid: authorizedUser && authorizedUser.uuid,
        });
      }
    }
  }, [clickSubcategoryFromSidebar, search, categoryByLink]);

  useEffect(async () => {
    if (
      (categoryid && showAllProductsFromCategory && !querySearching().s) ||
      (fromUrl && categoryid && !querySearching().cat && !isEmpty && !querySearching().s)
    ) {
      // await cleaningAllProducts();
      console.log('3');
      await getProductsByFilter({
        country_uuid: userDeliveryCountry || searchQuery.country_uuid,
        category_uuid: categoryid,
        search: querySearching().s,
        brand_uuid: encodeBrand(querySearching().brand, carBrands),
        model_uuid: encodeModel(querySearching().model, carModels),
        year_of_production_start: querySearching().ys,
        year_of_production_end: querySearching().ye,
        language: translation,
        user_uuid: authorizedUser && authorizedUser.uuid,
      });
      setIsEmptyView(false);
      localStorage.removeItem('showAll');
    }
  }, [categoryid, showAllProductsFromCategory]);

  useEffect(async () => {
    if (fromUrl || isClickMenuSubcategory) {
      await cleaningAllProducts();
      categoryByLink?.category?.link === querySearching().main_cat &&
        arraySubCategoryFromUrl.map(async element => {
          localStorage.removeItem('isSetCategory');
          const subCategoryInfo2 = categoryByLink?.subcategories?.find(item => item.link === element);
          console.log('10');
          await getProductsByFilter({
            country_uuid: userDeliveryCountry || searchQuery.country_uuid,
            search: querySearching().s,
            brand_uuid: encodeBrand(querySearching().brand, carBrands),
            model_uuid: encodeModel(querySearching().model, carModels),
            year_of_production_start: querySearching().ys,
            year_of_production_end: querySearching().ye,
            category_uuid: categoryid,
            subcategory_uuid: subCategoryInfo2 && subCategoryInfo2.uuid,
            language: translation,
            user_uuid: authorizedUser && authorizedUser.uuid,
          });
          setIsEmptyView(false);
        });
    }
  }, [categoryByLink, search]);

  let productsArray = [];
  useEffect(async () => {
    setIsEmptyView(false);
    if (isSearchEngine || removeSearch) {
      localStorage.removeItem('runSearchEngine');
      localStorage.removeItem('removeSearch');
      await cleaningAllProducts();
      console.log('6');
      productsArray = [];
      await getProductsByFilter({
        country_uuid: userDeliveryCountry || searchQuery.country_uuid,
        search: querySearching().s,
        brand_uuid: encodeBrand(querySearching().brand, carBrands),
        model_uuid: encodeModel(querySearching().model, carModels),
        year_of_production_start: querySearching().ys,
        year_of_production_end: querySearching().ye,
        category_uuid: categoryid,
        language: translation,
        user_uuid: authorizedUser && authorizedUser.uuid,
      });
    }
  }, [search, isSearchEngine]);

  useEffect(async () => {
    urlChange?.isBack &&
      (await getProductsByFilter({
        country_uuid: userDeliveryCountry || searchQuery.country_uuid,
        search: querySearching().s,
        brand_uuid: encodeBrand(querySearching().brand, carBrands),
        model_uuid: encodeModel(querySearching().model, carModels),
        year_of_production_start: querySearching().ys,
        year_of_production_end: querySearching().ye,
        language: translation,
        user_uuid: authorizedUser && authorizedUser.uuid,
      }));
    urlChange?.isBack && setIsEmptyView(false);
  }, [urlChange]);

  useEffect(async () => {
    if ((fromUrl && querySearching().s && categoryid) || (querySearching().s && categoryid) || querySearching().s) {
      if (categoryByLink?.category?.link === querySearching().main_cat) {
        if (arraySubCategoryFromUrl.length > 0) {
          arraySubCategoryFromUrl.map(async element => {
            localStorage.removeItem('isSetCategory');
            setIsEmptyView(false);
            const subCategoryInfo2 = categoryByLink?.subcategories?.find(item => item.link === element);
            console.log('8');
            await getProductsByFilter({
              country_uuid: userDeliveryCountry || searchQuery.country_uuid,
              search: querySearching().s,
              brand_uuid: encodeBrand(querySearching().brand, carBrands),
              model_uuid: encodeModel(querySearching().model, carModels),
              year_of_production_start: querySearching().ys,
              year_of_production_end: querySearching().ye,
              category_uuid: categoryid,
              subcategory_uuid: subCategoryInfo2 && subCategoryInfo2.uuid,
              language: translation,
              user_uuid: authorizedUser && authorizedUser.uuid,
            });
          });
        } else {
          console.log('9');
          await getProductsByFilter({
            country_uuid: userDeliveryCountry || searchQuery.country_uuid,
            search: querySearching().s,
            brand_uuid: encodeBrand(querySearching().brand, carBrands),
            model_uuid: encodeModel(querySearching().model, carModels),
            year_of_production_start: querySearching().ys,
            year_of_production_end: querySearching().ye,
            category_uuid: categoryid,
            language: translation,
            user_uuid: authorizedUser && authorizedUser.uuid,
          });
        }
      }
    }
  }, [categoryByLink, categoryid, querySearching().s]);

  useEffect(async () => {
    if (fromUrl) {
      search === '?s=' &&
        (await getProductsByFilter({
          country_uuid: userDeliveryCountry || searchQuery.country_uuid,
        }));
      search === '?s=' && setIsEmptyView(false);
    }
  }, [categoryByLink]);

  packagingProductsArray(products, productsArray);
  return (
    <div className="product-lists">
      <SidebarProduct
        isFromEngine={isFromEngine}
        urlChange={urlChange}
        setUrlChange={setUrlChange}
        formatMessage={formatMessage}
        products={products}
        categoryByLink={categoryByLink}
        translation={translation}
        productsArray={productsArray}
        categories={categories}
        cleaningAllProducts={cleaningAllProducts}
        authorizedUser={authorizedUser}
        deliveryCountries={deliveryCountries}
      />
      <Content
        addToFavorites={addToFavoritesProductLists}
        removeFromFavorites={removeFromFavoritesProductList}
        authorizedUser={authorizedUser}
        deliveryCountries={deliveryCountries}
        carModels={carModels}
        carBrands={carBrands}
        getCarModels={getCarModels}
        getCarBrands={getCarBrands}
        formatMessage={formatMessage}
        isEmptyView={isEmptyView}
        products={productsArray}
        isMobile={isMobile}
        translation={translation}
        categoryByLink={categoryByLink}
        setIsEmptyView={setIsEmptyView}
        isModalOpened={isModalOpened}
        cleaningAllProducts={cleaningAllProducts}
        getCategoryByLink={getCategoryByLink}
        categories={categories}
      />
      <SearchingModal
        categories={categories}
        setIsModalOpened={setIsModalOpened}
        getProductsByFilter={getProductsByFilter}
        isOpened={isModalOpened}
        formatMessage={formatMessage}
        carBrands={carBrands}
        carModels={carModels}
        getCarModels={getCarModels}
        getCarBrands={getCarBrands}
        deliveryCountries={deliveryCountries}
        getCategoryByLink={getCategoryByLink}
        categoryByLink={categoryByLink}
      />
    </div>
  );
};

export default ProductsLists;

ProductsLists.propTypes = {
  actions: shape({
    addToFavorites: func.isRequired,
    getProductsByFilter: func.isRequired,
    getCategoryByLink: func.isRequired,
    subtructProductsByCategory: func.isRequired,
    removeFromFavoritesProductList: func.isRequired,
  }).isRequired,
  authorizedUser: object.isRequired,
  carBrands: array.isRequired,
  carModels: array.isRequired,
  categories: object.isRequired,
  categoryByLink: object.isRequired,
  deliveryCountries: array.isRequired,
  formatMessage: object.isRequired,
  isMobile: bool.isRequired,
  products: array.isRequired,
  translation: string.isRequired,
};

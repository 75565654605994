import * as Connector from 'shared/connectors/productsConnector';
import { PRODUCTS } from 'store/types';

export const success = data => ({
  type: PRODUCTS.GET_MY_ADVERTISEMENTS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: PRODUCTS.GET_MY_ADVERTISEMENTS_FAILURE,
});

const getMyAdvertisementsFiltered = (status,{ title = null, orderBy = null, category_uuid = null, hide_products = null }) => async dispatch => {
  
  try {
    const {data} = await Connector.getAdvertisementsFiltred(status, title, orderBy, category_uuid,hide_products);
    dispatch(success(data.own_products));
    return data;
  } catch (err) {
    console.log(err)
    dispatch(failure());
    return err.response;
  }
};

export default getMyAdvertisementsFiltered;

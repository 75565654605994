import React, { useEffect } from 'react';
import { ListsProducts, SearchByVehicle, Filters, EmptyView } from 'screens/products-lists/components';
import { Breadcrumbs } from 'shared/components';
import { array, bool, func, object, string } from 'prop-types';

const Content = ({
  isMobile,
  products,
  isEmptyView,
  categoryByLink,
  formatMessage,
  getCarModels,
  getCarBrands,
  carBrands,
  carModels,
  deliveryCountries,
  authorizedUser,
  addToFavorites,
  removeFromFavorites,
  translation,
  setIsEmptyView,
  isModalOpened,
  cleaningAllProducts,
  categories,
}) => {
  useEffect(async () => {
    !carBrands && (await getCarBrands());
  }, []);
  const empty = isEmptyView;
  const searchQueryOrCountry = JSON.parse(localStorage.getItem('searchEngine')) || localStorage.getItem('deliveryCountry');
  return (
    <div className="product-lists__content">
      <div className="product-lists__breadcrumb--wrapper">
        <Breadcrumbs
          translation={translation}
          categories={categories}
          formatMessage={formatMessage}
          className="product-lists__breadcrumb"
        />
        {isMobile && (
          <Filters
            isMobile={isMobile}
            formatMessage={formatMessage}
            categoryByLink={categoryByLink}
            authorizedUser={authorizedUser}
            carBrands={carBrands}
            carModels={carModels}
            getCarModels={getCarModels}
            getCarBrands={getCarBrands}
            deliveryCountries={deliveryCountries}
            cleaningAllProducts={cleaningAllProducts}
          />
        )}
      </div>

      {!isMobile && (searchQueryOrCountry || authorizedUser) && (
        <SearchByVehicle
          categoryByLink={categoryByLink}
          authorizedUser={authorizedUser}
          formatMessage={formatMessage}
          carBrands={carBrands}
          carModels={carModels}
          getCarModels={getCarModels}
          getCarBrands={getCarBrands}
          deliveryCountries={deliveryCountries}
          cleaningAllProducts={cleaningAllProducts}
        />
      )}
      {/* <ProductsFilters formatMessage={formatMessage} /> */}

      {!empty ? (
        <ListsProducts
          removeFromFavorites={removeFromFavorites}
          addToFavorites={addToFavorites}
          products={products}
          isMobile={isMobile}
          translation={translation}
          formatMessage={formatMessage}
          setIsEmptyView={setIsEmptyView}
          isModalOpened={isModalOpened}
          authorizedUser={authorizedUser}
        />
      ) : (
        <EmptyView formatMessage={formatMessage} />
      )}
    </div>
  );
};

export default Content;

Content.propTypes = {
  addToFavorites: func.isRequired,
  authorizedUser: object.isRequired,
  carBrands: array.isRequired,
  carModels: array.isRequired,
  categories: object.isRequired,
  categoryByLink: array.isRequired,
  cleaningAllProducts: func.isRequired,
  deliveryCountries: array.isRequired,
  formatMessage: func.isRequired,
  getCarBrands: func.isRequired,
  getCarModels: func.isRequired,
  isEmptyView: bool.isRequired,
  isMobile: bool.isRequired,
  isModalOpened: bool.isRequired,
  products: array.isRequired,
  removeFromFavorites: func.isRequired,
  setIsEmptyView: func.isRequired,
  translation: string.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { MENU_TYPES } from '../const';

const items = (formatMessage, statusCounter) => [
  {
    type: MENU_TYPES(formatMessage).ALL,
    count: statusCounter('all'),
  },
  {
    type: MENU_TYPES(formatMessage).UNPAID,
    count: statusCounter('unpaid'),
  },
  {
    type: MENU_TYPES(formatMessage).PAID,
    count: statusCounter('paid'),
  },
  {
    type: MENU_TYPES(formatMessage).RETURNS,
    count: statusCounter('return'),
  },
  {
    type: MENU_TYPES(formatMessage).CANCELED,
    count: statusCounter('canceled'),
  },
];

const isSmallViewPort = window.innerWidth < 480;
const settings = {
  dots: false,
  arrows: false,
  slidesToShow: isSmallViewPort ? 2 : 3,
};

const OrdersMenu = ({ menuType, setMenuType, isMobile, formatMessage, countCheckoutStatus }) => {
  const statusCounter = status => {
    if (status === 'all') {
      const count = countCheckoutStatus.map(item => item.count).reduce((prev, curr) => prev + curr, 0);
      return count;
    }
    const findStatus = countCheckoutStatus.find(item => item.status === status);
    return findStatus?.count || null;
  };
  return (
    <>
      {!isMobile ? (
        <div className="advertisements__menu">
          {items(formatMessage, statusCounter).map(({ type, count }) => (
            <div
              className={`advertisements__menu--item ${menuType === type ? 'advertisements__menu--active' : ''}`}
              onClick={() => setMenuType(type)}
            >
              <div className="label">{type}</div>
              <div className={`counter my-orders--counter ${count === null ? 'my-orders--counter--empty' : ''}`}>{count}</div>
            </div>
          ))}
        </div>
      ) : (
        <div className="advertisements__menu">
          <Slider {...settings}>
            {items(formatMessage, statusCounter).map(({ type, count }) => (
              <div
                className={`advertisements__menu--item ${menuType === type ? 'advertisements__menu--active' : ''}`}
                onClick={() => setMenuType(type)}
              >
                <div className="label">{type}</div>
                <div className={`counter my-orders--counter ${count === null ? 'my-orders--counter--empty' : ''}`}>{count}</div>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </>
  );
};

export default OrdersMenu;

OrdersMenu.propTypes = {
  countCheckoutStatus: PropTypes.array.isRequired,
  formatMessage: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  menuType: PropTypes.bool.isRequired,
  setMenuType: PropTypes.func.isRequired,
};

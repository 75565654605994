import React from 'react';
import { Finder, CategorySearcher, SingleCategory, CategoryBarLists } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

const Sidebar = () => (
  <div className="product-sidebar">
    <Finder />
    <CategorySearcher />
    <SingleCategory changeCategory type={SVG_TYPE.BODY} label="Body parts" className="product-sidebar__change-category" />
    <CategoryBarLists />
  </div>
);

export default Sidebar;

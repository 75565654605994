import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const ITEMS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];

const Colors = ({ setFieldValue, formatMessage, data }) => {
  const [pickedColor, setPickedColor] = useState(false);

  useEffect(() => {
    if (data) {
      setPickedColor(data.color);
      setFieldValue('color', data.color);
    }
  }, []);

  const handlePickedColor = e => {
    setFieldValue('color', e.target.id);
    setPickedColor(e.target.id);
    if (pickedColor === e.target.id) {
      setFieldValue('color', null);
      setPickedColor(false);
      console.log('kliknąłem w ten ssam kolor');
    }
  };
  return (
    <section>
      <h3 className="heading">{formatMessage({ id: 'global.colors' })}</h3>
      <div className="choose-color">
        {ITEMS.map(item => (
          <div
            id={`color__${item}`}
            onClick={e => handlePickedColor(e)}
            className={`choose-color__item choose-color__item--${item} ${
              pickedColor === `color__${item}` ? 'choose-color__item--active' : ''
            }`}
          />
        ))}
      </div>
    </section>
  );
};

export default Colors;

Colors.propTypes = {
  data: PropTypes.object.isRequired,
  formatMessage: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

const isValidNip = value => {
  let valid = true;
  if (value && /^[0-9 -]+$/.test(value.toString())) {
    const valueNoSpace = value.toString().replace(/[-\s]+/g, '');
    const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    let sum = 0;
    let i = 0;
    if (valueNoSpace === null || valueNoSpace.length !== 10) {
      return false;
    }
    const max = weights.length;
    for (i = 0; i < max; i++) {
      if (Number.isNaN(parseInt(valueNoSpace[i], 10))) {
        return false;
      }
      sum += parseInt(valueNoSpace.substring(i, i + 1), 10) * weights[i];
    }
    sum %= 11;
    valid = sum === parseInt(valueNoSpace.substring(9, 10), 10);
  } else {
    valid = false;
  }
  return valid;
};
export default isValidNip;

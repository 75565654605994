import React, { useState, useEffect } from 'react';
import { Modal, RegularField, ModalActions, SelectField, PhotoCategory, Switch } from 'shared/components';
import { bool, func, object } from 'prop-types';
import * as Yup from 'yup';
import { MODAL_TYPE, VALIDATION_FIELD, USER_ROLES } from 'shared/consts';
import { Field, Formik } from 'formik';
import { deliveryCountryOptions } from 'shared/utils';

const AddCourierModal = ({
  isOpened,
  onClose,
  userInfo,
  setMenuType,
  formatMessage,
  deleteFiles,
  deliveryCountries,
  addDeliveryMethod,
  edit,
  info,
}) => {
  const [fileInputValue, setFileInputValue] = useState('');
  const [isDeliveryPhoto, isSetDeliveryPhoto] = useState(!!info?.item?.files.length > 0);
  const isAdmin = userInfo.role === USER_ROLES.ADMIN;

  useEffect(() => {
    isOpened && setFileInputValue(info.item?.files.slice(0, 1));
    isOpened && isSetDeliveryPhoto(!!info?.item?.files.length > 0);
    // console.log(info?.item?.files);
    // console.log(fileInputValue)
  }, [info]);
  useEffect(() => {
  }, [deliveryCountries]);

  const onSubmit = async values => {
    if ((!edit && fileInputValue.length > 0) || (edit && isDeliveryPhoto !== 'empty')) {
      if (edit) {
        if (!fileInputValue[0].uuid) {
          edit({ ...values, uuid: info.item.uuid, files: fileInputValue });
          deleteFiles(info.item.files[0].uuid);
        } else {
          edit({ ...values, uuid: info.item.uuid });
        }
      } else {
        isSetDeliveryPhoto('empty');
      }
      onClose();
      await addDeliveryMethod({ ...values, files: fileInputValue });
      setMenuType(values.country_uuid);
      setFileInputValue(null);
    }
  };

  const handleFileAdd = e => {
    setFileInputValue(e.target.files);
  };

  const validation = () =>
    Yup.object().shape({
      country_uuid: Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED),
      // currency: Yup.string().required(VALIDATION_FIELD(formatMessage).REQUIRED),
      name: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254),
      price: Yup.string()
        .required(VALIDATION_FIELD(formatMessage).REQUIRED)
        .matches(/^\d{0,100}(\.\d{0,99}||,\d{0,99})?$/, VALIDATION_FIELD(formatMessage).ONLY_NUMBER)
        .matches(/^\d{0,6}(\.\d{0,99}||,\d{0,99})?$/, VALIDATION_FIELD(formatMessage).MAX_6)
        .matches(/^[0-9][0-9]*[(.|,)]?[0-9]{0,2}$/, VALIDATION_FIELD(formatMessage).DECIMAL_2),
      // .matches(/^\d(\.\d{1,2})?$/, 'po przecinku'),
    });
  return (
    <>
      {isOpened && (
        <Modal
          className={`${MODAL_TYPE.BASIC} editUser`}
          onClose={() => {
            onClose();
            isSetDeliveryPhoto(!!edit);
            setFileInputValue(undefined);
          }}
          opened={isOpened}
        >
          <div>
            {!edit ? (
              <div className="modal__heading modal__heading--center">{formatMessage({ id: 'global.adding' })}</div>
            ) : (
              <div className="modal__heading modal__heading--center">{formatMessage({ id: 'global.editing' })}</div>
            )}
            <Formik
              validationSchema={validation()}
              initialValues={{
                name: edit ? info.item.name : '',
                price: edit ? info.item.price : '',
                country_uuid: edit ? info.item.country.label || userInfo.country.label : userInfo.country.label || '',
                is_worldwide: edit ? info.item.is_worldwide : true,
              }}
              onSubmit={onSubmit}
            >
              {({ values, handleSubmit, errors, setFieldValue }) => (
                <form className="categoryManagement__addCategory__wrapper" onSubmit={handleSubmit}>
                  <div className="courier__add">
                    <Field
                      type="text"
                      label={formatMessage({ id: 'global.label' })}
                      name="name"
                      component={RegularField}
                      errors={errors}
                    />
                    <Field
                      type="text"
                      label={formatMessage({ id: 'global.price' })}
                      name="price"
                      component={RegularField}
                      errors={errors}
                    />
                    <div className="courier__add--country">
                      {isAdmin && (
                        <Field
                          className="add-advertisement__select"
                          component={SelectField}
                          placeholder={formatMessage({ id: 'inputs.choose' })}
                          label={formatMessage({ id: 'global.country' })}
                          errors={errors}
                          name="country_uuid"
                          options={deliveryCountryOptions(deliveryCountries, formatMessage)}
                          isCustomLabel
                        />
                      )}
                    </div>
                    {(fileInputValue || !edit) && (
                      <div className="courier__add__photo--wrapper">
                        <p className="paragraph">{formatMessage({ id: 'global.delivery_logo' })}</p>
                        <Field
                          name="files"
                          handleChange={e => handleFileAdd(e)}
                          className="add-advertisement__field add-advertisement__field--textarea"
                          component={PhotoCategory}
                          fileInputValue={fileInputValue}
                          isSetCategoryPhoto={isDeliveryPhoto}
                          setpPhotoUuidDelete={isSetDeliveryPhoto}
                          setIsSetCategoryPhoto={isSetDeliveryPhoto}
                          deleteFiles={deleteFiles}
                          formatMessage={formatMessage}
                        />
                        {isDeliveryPhoto === 'empty' && (
                          <p className="add__photos--error add__photos--error2">
                            {formatMessage({ id: VALIDATION_FIELD(formatMessage).REQUIRED })}{' '}
                          </p>
                        )}
                      </div>
                    )}
                    <p className="courier__add--paragraph">{formatMessage({ id: 'global.is_worldwide' })}</p>
                    <Switch
                      className="courier__add--switch"
                      formatMessage={formatMessage}
                      checked={values.is_worldwide}
                      setFieldValue={setFieldValue}
                      name="is_worldwide"
                    />
                    <ModalActions
                      formatMessage={formatMessage}
                      className="courier__add--buttons"
                      type={!edit ? MODAL_TYPE.ADD_CATEGORY : MODAL_TYPE.EDIT_COURIER}
                      onClose={() => {
                        onClose();
                        isSetDeliveryPhoto('empty');
                        setFileInputValue([]);
                      }}
                      onSubmit={() => {
                        !fileInputValue && isSetDeliveryPhoto('empty');
                        handleSubmit();
                      }}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
    </>
  );
};

export default AddCourierModal;

AddCourierModal.defaultProps = {
  edit: false,
  setMenuType: () => console.log(),
};

AddCourierModal.propTypes = {
  addDeliveryMethod: func.isRequired,
  deleteFiles: object.isRequired,
  deliveryCountries: object.isRequired,
  edit: bool,
  formatMessage: func.isRequired,
  info: object.isRequired,
  isOpened: bool.isRequired,
  mobile: bool.isRequired,
  onClose: func.isRequired,
  setMenuType: func,
  userInfo: object.isRequired,
};

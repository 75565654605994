/* eslint-disable react/sort-prop-types */
import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { object, string, array } from 'yup';
import PropTypes, { func, shape } from 'prop-types';
import { VALIDATION_FIELD, ROUTES } from 'shared/consts';
import { FormikOnError } from 'shared/utils';
import { useHistory } from 'react-router-dom';
import { generatePath } from 'react-router';
import { Preloader } from 'shared/components';
import { MENU_TYPES } from 'screens/dictionary/components/const';
import {
  Colors,
  ItemCondition,
  PriceAndCount,
  Localization,
  BasicInfo,
  Photos,
  ButtonActions,
  CancelAdvertisement,
} from './components';

const EditAdvertisemenets = ({
  actions: {
    getAllCategory,
    getCarModels,
    getCarBrands,
    getSubcategoryForCategory,
    editAdvertisements,
    getDelivery,
    addProductPreview,
    getCustomTranslations,
  },
  intl: { formatMessage },
  categories,
  authorizedUser,
  subcategoryForCategory,
  delivery,
  carBrands,
  carModels,
  translation,
  customTranslations,
}) => {
  const history = useHistory();
  !history.location?.state?.data && history.push('/404');
  const {
    state: { data },
  } = history.location;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [photoInputValue, setPhotoInputValue] = useState(data.photoInputValue || []);
  const [categoryLink, setCategoryLink] = useState('');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [fileArr, setFileArr] = useState(data.fileArr || []);
  const [base64, setBase64] = useState(data.base64 || []);
  const { is_percentage } = data;
  const priceWithoutMargin = (parseFloat(data.price.replace(',', '.')) - data.price_margin_value).toFixed(2).toString();
  const priceWithoutMarginProcentage = (parseFloat(data.price.replace(',', '.')) / ((data.price_margin_value + 100) / 100))
    .toFixed(2)
    .toString();
  const handlePriceWithoutMargin = () => (is_percentage ? priceWithoutMarginProcentage : priceWithoutMargin);
  const priceWithMargin = price => (parseFloat(price.replace(',', '.')) + data.price_margin_value).toFixed(2).toString();
  const priceWithMarginProcentage = price =>
    (parseFloat(price.replace(',', '.')) * ((data.price_margin_value + 100) / 100)).toFixed(2).toString();
  const handlePriceWithMargin = value => (is_percentage ? priceWithMarginProcentage(value) : priceWithMargin(value));
  const country_uuid = MENU_TYPES(formatMessage)[translation === 'pl' ? 'PL' : 'DE'].options;
  const [files, setFiles] = useState(data.files);
  const [photoToDeleteUuid, setPhotoToDeleteUuid] = useState(data.photoToDeleteUuid || []);
  const handlePhotoRemove = id => {
    const filteredFiles = files.filter(item => item?.id !== parseInt(id, 10));
    const uuidOfPhoto = files.find(item => item?.id === parseInt(id, 10))?.uuid;
    setFiles(filteredFiles);
    uuidOfPhoto && setPhotoToDeleteUuid([...photoToDeleteUuid, uuidOfPhoto]);
  };
  // console.log(handlePriceWithoutMargin());
  useEffect(() => {
    getAllCategory();
    getCarBrands();
    getSubcategoryForCategory(data.subcategory.category.uuid);
    setCategoryLink(data.subcategory.category.link);
    getCustomTranslations({ country_uuid });
  }, []);

  useEffect(async () => {
    const lang = authorizedUser.country.label;
    await getDelivery(lang);
  }, [authorizedUser]);

  const handleOnSubmit = async values => {
    if (!isPreview) {
      setIsFormSubmitted(true);
      const response = await editAdvertisements({ uuid: data.uuid, form: values, photos: photoInputValue, photoToDeleteUuid });

      response === 'success'
        ? history.push(
            generatePath(ROUTES.SINGLE_PRODUCT, {
              category: categoryLink,
              product: data.uuid,
            }),
          )
        : setIsFormSubmitted(false);
      addProductPreview();
    } else {
      addProductPreview({
        ...data,
        ...values,
        files,
        price: handlePriceWithMargin(values.price),
        fileArr,
        base64,
        photoInputValue,
        photoToDeleteUuid,
        priceWithoutMargin: values.price,
      });
      history.push({ pathname: '/product-preview', state: { edit: true } });
    }
  };
  const validationSchema = () =>
    object().shape({
      title: string()
        .required(' ')
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254),
      title_ger: string()
        .required(' ')
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254),
      category_uuid: string().required(' '),
      subcategory_uuid: string().required(' '),
      brand_uuid: string().required(' '),
      year_of_production: string()
        .required(' ')
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .min(4, VALIDATION_FIELD(formatMessage).DATA)
        .max(4, VALIDATION_FIELD(formatMessage).DATA)
        .matches(/^(19|20)\d{2}$/, VALIDATION_FIELD(formatMessage).DATA),
      description: string()
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .min(80, '')
        .max(6000, VALIDATION_FIELD(formatMessage).MAX_6000)
        .required(' '),
      description_ger: string()
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .min(80, '')
        .max(6000, VALIDATION_FIELD(formatMessage).MAX_6000)
        .required(' '),
      price: string()
        .required(' ')
        .matches(/^\d{0,100}(\.\d{0,99}||,\d{0,99})?$/, VALIDATION_FIELD(formatMessage).ONLY_NUMBER)
        .matches(/^\d{0,6}(\.\d{0,99}||,\d{0,99})?$/, VALIDATION_FIELD(formatMessage).MAX_6)
        .matches(/^[0-9][0-9]*[(.|,)]?[0-9]{0,2}$/, VALIDATION_FIELD(formatMessage).DECIMAL_2)
        .matches(/^[0-9,.]*$/, ' ')
        .test('min-0', VALIDATION_FIELD(formatMessage).REQUIRED, value => Number(value) !== 0)
        .test(
          'no-leading-zero',
          VALIDATION_FIELD(formatMessage).FIRST_NO_ZERO,
          (value, context) => context.originalValue && !context.originalValue.startsWith('0'),
      ),
      
      condition: string().required(VALIDATION_FIELD(formatMessage).REQUIRED),
      location: string()
        .required(' ')
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254),
      amount: string()
        .required(' ')
        .matches(/^\d{0,100}?$/, VALIDATION_FIELD(formatMessage).ONLY_NUMBER)
        .matches(/^\d{0,6}?$/, VALIDATION_FIELD(formatMessage).MAX_6)
        .test('min-0', VALIDATION_FIELD(formatMessage).REQUIRED, value => Number(value) !== 0)
        .matches(/^\d+$/, ' '),
      delivery_uuid: array().min(1, VALIDATION_FIELD(formatMessage).DELIVERY).required(VALIDATION_FIELD(formatMessage).DELIVERY),
    });

  const initialValues = {
    title: data.title,
    title_ger: data.title_ger,
    category_uuid: data.subcategory.category.uuid,
    subcategory_uuid: data.subcategory.uuid,
    brand_uuid: data.brand_uuid,
    model_uuid: data.model_uuid,
    year_of_production: data.year_of_production,
    description: data.description,
    description_ger: data.description_ger,
    price: handlePriceWithoutMargin(),
    condition: data.condition,
    location: data.location?.trim(),
    delivery_uuid: '',
    color: '',
    amount: data?.stock?.in_stock || data.amount,
  };

  return (
    <div className="add-advertisement">
      {isFormSubmitted && <Preloader />}
      <h1 className="add-advertisement--heading">{formatMessage({ id: 'global.edit_addvertisement' })}</h1>
      <Formik initialValues={initialValues} onSubmit={handleOnSubmit} validationSchema={validationSchema()}>
        {({ errors, setFieldValue, values, handleSubmit }) => (
          <>
            <form onSubmit={handleSubmit}>
              <FormikOnError>
                <BasicInfo
                  setCategoryLink={setCategoryLink}
                  setFieldValue={setFieldValue}
                  carModels={carModels}
                  getCarModels={getCarModels}
                  carBrands={carBrands}
                  formatMessage={formatMessage}
                  getSubcategoryForCategory={getSubcategoryForCategory}
                  categories={categories}
                  errors={errors}
                  subcategoryForCategory={subcategoryForCategory}
                  translation={translation}
                  advertisment={data}
                  customTranslations={customTranslations}
                />
                <Photos
                  translation={translation}
                  setFieldValue={setFieldValue}
                  setPhotoInputValue={setPhotoInputValue}
                  formatMessage={formatMessage}
                  errors={errors}
                  setFileArr={setFileArr}
                  setBase64={setBase64}
                  advertisment={{ ...data, ...files }}
                  customTranslations={customTranslations}
                  handlePhotoRemove={handlePhotoRemove}
                />
                <Colors formatMessage={formatMessage} setFieldValue={setFieldValue} data={data} />
                <Field
                  formatMessage={formatMessage}
                  component={ItemCondition}
                  errors={errors}
                  name="condition"
                  setFieldValue={setFieldValue}
                  data={data}
                />
                <PriceAndCount currency={delivery} formatMessage={formatMessage} errors={errors} />
                <Field
                  formatMessage={formatMessage}
                  component={Localization}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  delivery={delivery}
                  values={values}
                  data={data}
                />
                {/* <Localization delivery={delivery} formatMessage={formatMessage} errors={errors} values={values} setFieldValue={setFieldValue} /> */}
                <ButtonActions
                  edit
                  formatMessage={formatMessage}
                  openModal={setIsModalOpened}
                  handleSubmit={handleSubmit}
                  isSubmitted={isFormSubmitted}
                  setIsPreview={setIsPreview}
                />
                <CancelAdvertisement
                  isModalOpened={isModalOpened}
                  formatMessage={formatMessage}
                  setIsModalOpened={setIsModalOpened}
                />
              </FormikOnError>
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default EditAdvertisemenets;

EditAdvertisemenets.propTypes = {
  actions: shape({
    addProducts: func.isRequired,
    getAllCategory: func.isRequired,
    getSubCategoryForCategory: func.isRequired,
    getDelivery: func.isRequired,
    getCarBrands: func.isRequired,
    getCarModels: func.isRequired,
  }).isRequired,
  authorizedUser: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  carBrands: PropTypes.array.isRequired,
  carModels: PropTypes.array.isRequired,
  delivery: PropTypes.object.isRequired,
  formatMessage: PropTypes.func.isRequired,
  subcategoryForCategory: PropTypes.object.isRequired,
  translation: PropTypes.string.isRequired,
  customTranslations: PropTypes.object.isRequired,
};

import * as Connector from 'shared/connectors/carConnector';
import { CAR } from 'store/types';

const init = () => ({
  type: CAR.GET_CAR_MODELS_INIT,
});

export const success = data => ({
  type: CAR.GET_CAR_MODELS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: CAR.GET_CAR_MODELS_FAILURE,
});

const getCarModels = brandName => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getCarModels(brandName);
    dispatch(success(data.models));
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default getCarModels;

import React from 'react';
import { SingleTile, SVG } from 'shared/components';
import { bool, func, object } from 'prop-types';
import { SVG_TYPE } from 'shared/consts';
import { FormattedMessage } from 'react-intl';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const SkeletonContent = ({ isMobile, formatMessage, authorizedUser }) => (
  <div>
    <div className="breadcrumbs">
      <div className="breadcrumbs__item breadcrumbs__item--first">
        <SVG className="breadcrumbs--icon" type={SVG_TYPE.CHEVRONE} />
        <FormattedMessage id="global.go_back" />
      </div>
    </div>
    <div className="single-product__content">
      <div>
        <div className="single-product__slider">
          <div className="photo-slider">
            <>
              <div className="photo-slider__item">
                <div className="photo-slider__image photo-slider__image--empty">
                  <Skeleton className="photo-slider__image" />
                  <div className="skeleton-thumbnail__wrapper">
                    <Skeleton className="photo-slider__icon-image skeleton-thumbnail__item" count={4} height={87} width={100} />
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
        {!isMobile && (
          <>
            <div className="single-product__descript">
              <div className="product-descript">
                <Skeleton className="skeleton--dark" count={1} height={30} width={199} />
                <SkeletonTheme>
                  <div className="skeleton__wrapper">
                    <div className="skeleton__column">
                      <Skeleton count={4} colum={2} width={116} height={20} />
                    </div>
                    <div className="skeleton__column">
                      <Skeleton count={4} colum={2} width={116} height={20} />
                    </div>
                  </div>
                </SkeletonTheme>
              </div>
              <div className="seller-comment seller-comment--skeleton">
                <p className="seller-comment--heading seller-comment--heading--skeleton">
                  <Skeleton className="skeleton--dark" count={1} height={30} width={199} />
                  <div className="seller-comment--heading--skeleton--first">
                    <Skeleton width={548} height={13} />
                  </div>
                  <div className="seller-comment--heading--skeleton--column">
                    <Skeleton count={3} width={548} height={13} />
                  </div>
                </p>
              </div>
            </div>
          </>
        )}
      </div>
      <div>
        <div className="product-name product-name--skeleton">
          <Skeleton width={283} height={34} />
          <div className="product-name--skeleton--mid">
            <Skeleton className="skeleton--dark" width={188} height={26} />
          </div>
          <Skeleton width={283} height={26} />
          <div className="product-name--bottom" />
          <div className="product-name--skeleton--bottom">
            <Skeleton width={283} height={60} />
          </div>
          <div className="product-name--skeleton--twice">
            <Skeleton width={133} height={43} />
            <Skeleton className="skeleton--dark" width={133} height={43} />
          </div>
        </div>
        <div className="seller">
          <div className="seller--top seller--top--skeleton">
            <Skeleton width={239} height={23} />
          </div>
          <div className="seller__location seller__location--skeleton">
            <Skeleton className="skeleton--dark" width={133} height={23} />
            <Skeleton width={239} height={16} />
          </div>
          <div className="seller__delivery">
            <Skeleton className="skeleton--dark" width={133} height={23} />
            <Skeleton className="skeleton--dark" width={85} height={23} />
          </div>
        </div>
        <SingleTile
          header={formatMessage({ id: 'global.guaranteed_30_days' })}
          descript={formatMessage({ id: 'global.guaranteed_30' })}
          secondDescript={formatMessage({ id: 'global.full_refund' })}
          color="orange"
          button="arrow-right"
          guaranted
        />
      </div>

      {!isMobile && !authorizedUser && (
        <SingleTile
          header={formatMessage({ id: 'global.plan_your_business_join' })}
          descript={formatMessage({ id: 'global.here_buy_parts' })}
          color="light-orange"
          double
          join={formatMessage({ id: 'inputs.join_now' })}
        />
      )}
      {isMobile && !authorizedUser && (
        <SingleTile
          header={formatMessage({ id: 'global.plan_your_business_join' })}
          descript={formatMessage({ id: 'global.here_buy_parts' })}
          color="light-orange"
          double
          join={formatMessage({ id: 'inputs.join_now' })}
        />
      )}
    </div>
  </div>
);

export default SkeletonContent;

SkeletonContent.propTypes = {
  authorizedUser: object.isRequired,
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
};

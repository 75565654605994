import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { isMobile } from 'shared/utils';
import {
  getAllProducts,
  getAllTransfers,
  addTransfer,
  editTransfer,
  getAllProductsByFilter,
  deleteProduct,
  recallTheProduct,
  editProduct,
  resumeTheProduct,
} from 'store/actions/warehouse';
import { getDeliveryCountries } from 'store/actions/delivery';
import { getAllCategoryAndSubcategory, getSubcategoryForCategory } from 'store/actions/category';
import { editAdvertisements, getCategoriesOptions, getInvoiceFromUuid } from 'store/actions/products';
import {
  getProducts as getAllProductsSelector,
  getAllTransfers as getAllTransfersSelector,
  getCategoriesOptions as getCategoriesOptionsSelector,
} from 'store/selectors/productsSelector';
import { getDeliveryCountries as getDelivertCountriesSelector } from 'store/selectors/deliverySelector';
import { getCategoryAndSubcategory, getSubcategoryForCategory as getSubcategoryForCategorySelector } from 'store/selectors/categorySelector';
import { getCheckout as getCheckoutSelector} from 'store/selectors/checkoutSelector';
import * as ViewManagementSelector from 'store/selectors/viewManagementSelector';
import { getCheckout } from 'store/actions/checkout';
import Magazine from './Magazine.main';

const mapDispatchToProps = {
  getAllProducts: () => getAllProducts(),
  getAllTransfers: () => getAllTransfers(),
  getCheckout: (values) => getCheckout(values),
  addTransfer: values => addTransfer(values),
  editTransfer: values => editTransfer(values),
  editAdvertisements: values => editAdvertisements(values),
  getDeliveryCountries: () => getDeliveryCountries(),
  getAllCategoryAndSubcategory: () => getAllCategoryAndSubcategory(),
  getAllProductsByFilter: (status, title, category) => getAllProductsByFilter(status, title, category),
  getCategoriesOptions: () => getCategoriesOptions(),
  deleteProduct: uuid => deleteProduct(uuid),
  recallTheProduct: uuid => recallTheProduct(uuid),
  editProduct: (uuid, values) => editProduct(uuid, values),
  resumeTheProduct: uuid => resumeTheProduct(uuid),
  getSubcategoryForCategory: uuid => getSubcategoryForCategory(uuid),
  getInvoice: checkout_uuid => getInvoiceFromUuid(checkout_uuid)
};

const mapStateToProps = state => ({
  isMobile: isMobile(),
  products: getAllProductsSelector(state),
  transfers: getAllTransfersSelector(state),
  deliveryCountries: getDelivertCountriesSelector(state),
  categoryAndSubcategory: getCategoryAndSubcategory(state),
  categoriesOptions: getCategoriesOptionsSelector(state),
  translation: ViewManagementSelector.getTranslation(state),
  subcategoryForCategory: getSubcategoryForCategorySelector(state),
  checkout: getCheckoutSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Magazine));

import { createSelector } from 'reselect';

const getProductsStore = state => state.productsStore;
const getProducts = createSelector(getProductsStore, ({ products: { data = [] } }) => data);
const getProductsByFilter = createSelector(getProductsStore, ({ productsByFilter: { data = [] } }) => data);
const getSingleProduct = createSelector(getProductsStore, ({ singleProduct: { data = {} } }) => data);
const getFavoriteProducts = createSelector(getProductsStore, ({ favoriteProducts: { data = [] } }) => data);
const getMyAdvertisements = createSelector(getProductsStore, ({ myAdvertisements: { data = [] } }) => data);
const getAdvertisementsCount = createSelector(getProductsStore, ({ advertisementsCount: { data = {} } }) => data);
const getAllTransfers = createSelector(getProductsStore, ({ transfers: { data = [] } }) => data);
const getCategoriesOptions = createSelector(getProductsStore, ({ categoriesOptions: { data = [] } }) => data);
const getPopularBrandsSelector = createSelector(getProductsStore, ({ popularBrands: { data = [] } }) => data);
const productPreviewSelector = createSelector(getProductsStore, ({ productPreview: { data = {} } }) => data);

export {
  getSingleProduct,
  getProducts,
  getProductsByFilter,
  getFavoriteProducts,
  getMyAdvertisements,
  getAdvertisementsCount,
  getAllTransfers,
  getCategoriesOptions,
  getPopularBrandsSelector,
  productPreviewSelector,
};

import React from 'react';
import { SingleTile, SVG } from 'shared/components';
import { SVG_TYPE, ROUTES } from 'shared/consts';
import { useHistory } from 'react-router-dom';
import { func } from 'prop-types';

const MapSection = ({ formatMessage }) => {
  const history = useHistory();
  return (
    <section className="map-section">
      <div className="map-section__map">
        <SVG type={SVG_TYPE.MAP} />
      </div>
      <div className="map-section__biznes">
        <SingleTile
          header={formatMessage({ id: 'global.plan_your_business_place' })}
          descript={formatMessage({ id: 'global.here_buy_parts' })}
          color="light-orange test"
          button="arrow-right"
        />
      </div>
      <div className="map-section__guaranted">
        <SingleTile
          header={formatMessage({ id: 'global.take_premium_service' })}
          descript={formatMessage({ id: 'global.connection_allegro_service' })}
          color="orange"
          buttonTitle={formatMessage({ id: 'inputs.find_out_more' })}
          button="orange"
          buttonOnClick={() => history.push(ROUTES.PARTNER_PROGRAM)}
        />
      </div>
      <div className="map-section__invite">
        <SingleTile
          header={formatMessage({ id: 'global.plan_your_business_join' })}
          descript={formatMessage({ id: 'global.here_buy_parts' })}
          color="light-orange"
          join={formatMessage({ id: 'inputs.join_now' })}
          buttonOnClick={() => history.push(ROUTES.REGISTER)}
        />
      </div>
    </section>
  );
};
export default MapSection;

MapSection.propTypes = {
  formatMessage: func.isRequired,
};

const VALIDATION_FIELD = formatMessage => ({
  REQUIRED: formatMessage({ id: 'validation.required' }),
  EMAIL: formatMessage({ id: 'validation.email' }),
  MIN_8_PASSWORD: formatMessage({ id: 'validation.min_8_password' }),
  MIN_2: formatMessage({ id: 'validation.min_2' }),
  MIN_80: formatMessage({ id: 'validation.min_80' }),
  USER_INACTIVE: formatMessage({ id: 'validation.user_inactive' }),
  LOGIN_ERROR: formatMessage({ id: 'validation.login_error' }),
  PASSWORD_THIS_SAME: formatMessage({ id: 'validation.password_this_same' }),
  EMAIL_OCCUPIED: formatMessage({ id: 'validation.email_occupied' }),
  ERROR_EMAIL: formatMessage({ id: 'validation.email_occupied' }),
  DELIVERY: formatMessage({ id: 'validation.delivery' }),
  MAX_6000: formatMessage({ id: 'validation.max_6000' }),
  WRONG_NIP: formatMessage({ id: 'validation.wrong_nip' }),
  REQUIRED_9: formatMessage({ id: 'validation.required_9' }),
  ONLY_4: formatMessage({ id: 'validation.only_4' }),
  DIGITS_9: formatMessage({ id: 'validation.digits_9' }),
  DATA: formatMessage({ id: 'validation.data' }),
  EMPTY_SPACE: formatMessage({ id: 'validation.empty_space' }),
  BAD_EMAIL: formatMessage({ id: 'validation.bad_email' }),
  MAX_6: formatMessage({ id: 'validation.max_6' }),
  MAX_3: formatMessage({ id: 'validation.max_3' }),
  DECIMAL_PRICE: formatMessage({ id: 'validation.decimal_price' }),
  ONLY_NUMBER: formatMessage({ id: 'validation.only_number' }),
  FIRST_NO_ZERO: formatMessage({ id: 'validation.first_no_zero' }),
  DECIMAL_2: formatMessage({ id: 'validation.decimal_2' }),
  MAX_254: formatMessage({ id: 'validation.max_254' }),
  MAX_30: formatMessage({ id: 'validation.max_30' }),
  MAX_20: formatMessage({ id: 'validation.max_20' }),
  MAX_50: formatMessage({ id: 'validation.max_50' }),
  MAX_500: formatMessage({ id: 'validation.max_500' }),
  POST_CODE: formatMessage({ id: 'validation.post_code_format' }),
  POST_CODE_DE: formatMessage({ id: 'validation.post_code_format_de' }),
  NO_ZERO: formatMessage({ id: 'validation.no_leading_zero' }),
  POSITIVE: formatMessage({ id: 'validation.positive_value_required' }),
  UNIQUE: formatMessage({ id: 'validation.unique' }),
  BAD_VALUE: formatMessage({ id: 'validation.bad_value' }),
  MIN_3: formatMessage({ id: 'validation.min_3' }),
});

export default VALIDATION_FIELD;

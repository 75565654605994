import React from 'react';
import PropTypes from 'prop-types';
import { MENU_TYPES } from '../const';

const items = formatMessage => [
  {
    type: MENU_TYPES(formatMessage).PL.label,
    options: MENU_TYPES(formatMessage).PL.options,
  },
  {
    type: MENU_TYPES(formatMessage).DE.label,
    options: MENU_TYPES(formatMessage).DE.options,
  },
];

const LanguageMenu = ({ menuType, setMenuType, formatMessage }) => (
  <>
    <div className="menu">
      {items(formatMessage).map(({ type, options }) => (
        <div
          className={`menu--item ${menuType.label === type ? 'menu--active' : ''}`}
          onClick={() => setMenuType({ label: type, options })}
        >
          <div className="label">{type}</div>
        </div>
      ))}
    </div>
  </>
);

export default LanguageMenu;

LanguageMenu.propTypes = {
  formatMessage: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  menuType: PropTypes.bool.isRequired,
  setMenuType: PropTypes.func.isRequired,
};

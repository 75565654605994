const PRODUCTS = {
  ADD_PRODUCTS_INIT: 'ADD_PRODUCTS_INIT',
  ADD_PRODUCTS_SUCCESS: 'ADD_PRODUCTS_SUCCESS',
  ADD_PRODUCTS_FAILURE: 'ADD_PRODUCTS_FAILURE',
  GET_ALL_PRODUCTS_INIT: 'GET_ALL_PRODUCTS_INIT',
  GET_ALL_PRODUCTS_SUCCESS: 'GET_ALL_PRODUCTS_SUCCESS',
  GET_ALL_PRODUCTS_FAILURE: 'GET_ALL_PRODUCTS_FAILURE',
  GET_PRODUCTS_BY_CATEGORY_INIT: 'GET_PRODUCTS_BY_CATEGORY_INIT',
  GET_PRODUCTS_BY_CATEGORY_SUCCESS: 'GET_PRODUCTS_BY_CATEGORY_SUCCESS',
  GET_PRODUCTS_BY_CATEGORY_FAILURE: 'GET_PRODUCTS_BY_CATEGORY_FAILURE',
  GET_PRODUCTS_BY_SUB_CATEGORY_INIT: 'GET_PRODUCTS_BY_SUB_CATEGORY_INIT',
  GET_PRODUCTS_BY_SUB_CATEGORY_SUCCESS: 'GET_PRODUCTS_BY_SUB_CATEGORY_SUCCESS',
  GET_PRODUCTS_BY_SUB_CATEGORY_FAILURE: 'GET_PRODUCTS_BY_SUB_CATEGORY_FAILURE',
  GET_PRODUCTS_BY_FILTER_INIT: 'GET_PRODUCTS_BY_FILTER_INIT',
  GET_PRODUCTS_BY_FILTER_SUCCESS: 'GET_PRODUCTS_BY_FILTER_SUCCESS',
  GET_PRODUCTS_BY_FILTER_FAILURE: 'GET_PRODUCTS_BY_FILTER_FAILURE',
  GET_SINGLE_PRODUCT_INIT: 'GET_SINGLE_PRODUCT_INIT',
  GET_SINGLE_PRODUCT_SUCCESS: 'GET_SINGLE_PRODUCT_SUCCESS',
  GET_SINGLE_PRODUCT_FAILURE: 'GET_SINGLE_PRODUCT_FAILURE',
  GET_FAVORITE_PRODUCTS_INIT: 'GET_FAVORITE_PRODUCTS_INIT',
  GET_FAVORITE_PRODUCTS_SUCCESS: 'GET_FAVORITE_PRODUCTS_SUCCESS',
  GET_FAVORITE_PRODUCTS_FAILURE: 'GET_FAVORITE_PRODUCTS_FAILURE',
  SUBTRUCT_PRODUCTS_BY_SUB_CATEGORY_SUCCESS: 'SUBTRUCT_PRODUCTS_BY_SUB_CATEGORY_SUCCESS',
  ADD_TO_FAVORITE_SUCCESS: 'ADD_TO_FAVORITE_SUCCESS',
  REMOVE_FROM_FAVORITE_SUCCESS: 'REMOVE_FROM_FAVORITE_SUCCESS',
  MARK_SINGLE_PRODUCT_FAVORITE_SUCCESS: 'MARK_SINGLE_PRODUCT_FAVORITE_SUCCESS',
  UNMARK_SINGLE_PRODUCT_FAVORITE_SUCCESS: 'UNMARK_SINGLE_PRODUCT_FAVORITE_SUCCESS',
  DELETE_FOLLOWED_PRODUCT_SUCCESS: 'DELETE_FOLLOWED_PRODUCT_SUCCESS',
  GET_MY_ADVERTISEMENTS_INIT: 'GET_MY_ADVERTISEMENTS_INIT',
  GET_MY_ADVERTISEMENTS_SUCCESS: 'GET_MY_ADVERTISEMENTS_SUCCESS',
  GET_MY_ADVERTISEMENTS_FAILURE: 'GET_MY_ADVERTISEMENTS_FAILURE',
  CLEANING_ALL_PRODUCTS: 'CLEANING_ALL_PRODUCTS',
  EDIT_ADVERTISEMENT_INIT: 'EDIT_ADVERTISEMENT_INIT',
  EDIT_ADVERTISEMENT_SUCCESS: 'EDIT_ADVERTISEMENT_SUCCESS',
  EDIT_ADVERTISEMENT_FAILURE: 'EDIT_ADVERTISEMENT_FAILURE',
  REMOVE_ADVERTISEMENT_SUCCESS: 'REMOVE_ADVERTISEMENT_SUCCESS',
  GET_ADVERTISEMENTS_COUNT_INIT: 'GET_ADVERTISEMENTS_COUNT_INIT',
  GET_ADVERTISEMENTS_COUNT_SUCCESS: 'GET_ADVERTISEMENTS_COUNT_SUCCESS',
  GET_ADVERTISEMENTS_COUNT_FAILURE: 'GET_ADVERTISEMENTS_COUNT_FAILURE',
  GET_TRANSFERS_INIT: 'GET_TRANSFERS_INIT',
  GET_TRANSFERS_SUCCESS: 'GET_TRANSFERS_SUCCESS',
  GET_TRANSFERS_FAILURE: 'GET_TRANSFERS_FAILURE',
  ADD_TRANSFER_INIT: 'ADD_TRANSFER_INIT',
  ADD_TRANSFER_SUCCESS: 'ADD_TRANSFER_SUCCESS',
  ADD_TRANSFER_FAILURE: 'ADD_TRANSFER_FAILURE',
  EDIT_TRANSFER_INIT: 'EDIT_TRANSFER_INIT',
  EDIT_TRANSFER_SUCCESS: 'EDIT_TRANSFER_SUCCESS',
  EDIT_TRANSFER_FAILURE: 'EDIT_TRANSFER_FAILURE',
  GET_CATEGORIES_OPTIONS_INIT: 'GET_CATEGORIES_OPTIONS_INIT',
  GET_CATEGORIES_OPTIONS_SUCCESS: 'GET_CATEGORIES_OPTIONS_SUCCESS',
  GET_CATEGORIES_OPTIONS_FAILURE: 'GET_CATEGORIES_OPTIONS_FAILURE',
  GET_POPULAR_BRANDS_INIT: 'GET_POPULAR_BRANDS_INIT',
  GET_POPULAR_BRANDS_SUCCESS: 'GET_POPULAR_BRANDS_SUCCESS',
  GET_POPULAR_BRANDS_FAILURE: 'GET_POPULAR_BRANDS_FAILURE',
  DELETE_PRODUCT_WAREHOUSE_SUCCESS: 'DELETE_PRODUCT_WAREHOUSE_SUCCESS',
  RECALL_THE_PRODUCT_SUCCESS: 'RECALL_THE_PRODUCT_SUCCESS',
  EDIT_PRODUCT_WAREHOUSE_SUCCESS: 'EDIT_PRODUCT_WAREHOUSE_SUCCESS',
  RESUME_THE_PRODUCTS_SUCCESS: 'RESUME_THE_PRODUCTS_SUCCESS',
  ADD_PRODUCT_PREVIEW_INIT: 'ADD_PRODUCT_PREVIEW_INIT',
  ADD_PRODUCT_PREVIEW_SUCCESS: 'ADD_PRODUCT_PREVIEW_SUCCESS',
  ADD_PRODUCT_PREVIEW_FAILURE: 'ADD_PRODUCT_PREVIEW_FAILURE',
  RESUME_MY_PRODUCT_SUCCESS: 'RESUME_MY_PRODUCT_SUCCESS',
  RECALL_MY_PRODUCT_SUCCESS: 'RECALL_MY_PRODUCT_SUCCESS',
};

export default PRODUCTS;

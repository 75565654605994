const CAR = {
  GET_CAR_BRANDS_INIT: 'GET_CAR_BRANDS_INIT',
  GET_CAR_BRANDS_SUCCESS: 'GET_CAR_BRANDS_SUCCESS',
  GET_CAR_BRANDS_FAILURE: 'GET_CAR_BRANDS_FAILURE',
  GET_CAR_MODELS_INIT: 'GET_CAR_MODELS_INIT',
  GET_CAR_MODELS_SUCCESS: 'GET_CAR_MODELS_SUCCESS',
  GET_CAR_MODELS_FAILURE: 'GET_CAR_MODELS_FAILURE',
};

export default CAR;

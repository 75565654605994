import { connect } from 'react-redux';
import { isMobile as checkIsMobile } from 'shared/utils';
import { bindActionCreators } from 'redux';
import { getAllCategory, getSubcategoryForCategory } from 'store/actions/category';
import { getCategory, getSubcategoryForCategory as getSubcategoryForCategorySelector } from 'store/selectors/categorySelector';
import { addProductPreview, addProducts } from 'store/actions/products';
import { injectIntl } from 'react-intl';
import { getDelivery } from 'store/actions/delivery';
import { getDelivery as getDeliverySelector } from 'store/selectors/deliverySelector';
import { getCarBrands, getCarModels } from 'store/actions/car';
import { getCarBrands as getCarBrandsSelector, getCarModels as getCarModelsSelector } from 'store/selectors/carSelector';
import { getAuthorizedUser } from 'store/selectors/userSelector';
import * as ViewManagementSelector from 'store/selectors/viewManagementSelector';
import { getCustomTranslations } from 'store/actions/custom-translations';
import { getCustomTranslations as getCustomTranslationsSelector } from 'store/selectors/customTranslationsSelector';
import { productPreviewSelector } from 'store/selectors/productsSelector';
import AddAdvertisement from './AddAdvertisement.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getAllCategory: () => getAllCategory(),
      getSubcategoryForCategory: uuid => getSubcategoryForCategory(uuid),
      addProducts: (data, photos) => addProducts(data, photos),
      getDelivery: lang => getDelivery(lang),
      getCarBrands: () => getCarBrands(),
      getCarModels: brandName => getCarModels(brandName),
      getCustomTranslations: (data) => getCustomTranslations(data),
      addProductPreview: (data)=> addProductPreview(data),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  isMobile: checkIsMobile(),
  categories: getCategory(state),
  subcategoryForCategory: getSubcategoryForCategorySelector(state),
  delivery: getDeliverySelector(state),
  carBrands: getCarBrandsSelector(state),
  carModels: getCarModelsSelector(state),
  authorizedUser: getAuthorizedUser(state),
  translation: ViewManagementSelector.getTranslation(state),
  customTranslations: getCustomTranslationsSelector(state),
  productPreview: productPreviewSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddAdvertisement));

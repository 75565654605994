import React from 'react';
import { object, func } from 'prop-types';

const Address = ({ customCheckoutAddress, formatMessage }) => (
  <div className="settings__content settings__content--address">
    <div className="settings__content__item">
      <p>{formatMessage({ id: 'inputs.name' })}</p>
      <p>{customCheckoutAddress?.first_name ? customCheckoutAddress.first_name : '-'}</p>
    </div>
    <div className="settings__content__item">
      <p>{formatMessage({ id: 'inputs.last_name' })}</p>
      <p>{customCheckoutAddress?.last_name ? customCheckoutAddress.last_name : '-'}</p>
    </div>
    <div className="settings__content__item">
      <p>{formatMessage({ id: 'inputs.email' })}</p>
      <p>{customCheckoutAddress?.email}</p>
    </div>
    <div className="settings__content__item">
      <p>{formatMessage({ id: 'global.telephone' })}</p>
      <p>{customCheckoutAddress?.phone_number ? customCheckoutAddress.phone_number : '-'}</p>
    </div>
    <div className="settings__content__item">
      <p>{formatMessage({ id: 'inputs.street' })}</p>
      <p>{customCheckoutAddress?.street ? customCheckoutAddress.street : '-'}</p>
    </div>

    <div className="settings__content__item">
      <p>{formatMessage({ id: 'inputs.build_number' })}</p>
      <p>{customCheckoutAddress?.house_number ? customCheckoutAddress.house_number : '-'}</p>
    </div>
    <div className="settings__content__item">
      <p>{formatMessage({ id: 'inputs.apartement_number' })}</p>
      <p>{customCheckoutAddress?.apartment_number ? customCheckoutAddress.apartment_number : '-'}</p>
    </div>
    <div className="settings__content__item">
      <p>{formatMessage({ id: 'inputs.city' })}</p>
      <p>{customCheckoutAddress?.city ? customCheckoutAddress.city : '-'}</p>
    </div>
    <div className="settings__content__item">
      <p>{formatMessage({ id: 'inputs.post_code' })}</p>
      <p>{customCheckoutAddress?.post_code ? customCheckoutAddress.post_code : '-'}</p>
    </div>
  </div>
);

export default Address;
Address.propTypes = {
  authorizedUser: object.isRequired,
  customCheckoutAddress: object.isRequired,
  editUser: func.isRequired,
  formatMessage: func.isRequired,
  setModalManagement: func.isRequired,
};

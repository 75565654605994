import React from "react";
import { object } from "prop-types";

const Translation = ({data}) => (
  <div className="productNameWrapper">
    <div className="dictionary__word">{data?.translation}</div>
  </div>
);

export default Translation;

Translation.propTypes = {
  data: object.isRequired,
};
import * as Connector from 'shared/connectors/productsConnector';
import { PRODUCTS } from 'store/types';

// const init = () => ({
//   type: PRODUCTS.GET_ALL_PRODUCTS_INIT,
// });

export const success = data => ({
  type: PRODUCTS.GET_ALL_PRODUCTS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: PRODUCTS.GET_ALL_PRODUCTS_FAILURE,
});

const getAllProductsByFilter = (status, title, categoryUuid) => async dispatch => {
  try {
    const {
      data: { products },
    } = await Connector.getAllProductsByFilter(status, title, categoryUuid);
    dispatch(success(products));
    return products;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default getAllProductsByFilter;

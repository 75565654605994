import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE, ROUTES } from 'shared/consts';
import { FormattedMessage } from 'react-intl';
import { removeParamsFromUrl, querySearching } from 'shared/utils';
import { useHistory } from 'react-router-dom';
import { getQueryFromUrl } from '../../utils';

const SearchQueryBar = () => {
  const history = useHistory();

  return (
    <div className="search-query-bar">
      <p className="search-query-bar--label">
        <FormattedMessage id="global.you_are_looking_for" />: {getQueryFromUrl()}
      </p>
      <div
        className="search-query-bar--close"
        onClick={() => {
          history.push(`${ROUTES.CATEGORY}/?s=&${querySearching().main_cat ? '&' : ''}${removeParamsFromUrl(['s'])}`);
          localStorage.setItem('removeSearch', true);
          const allHiddenProduct = document.getElementsByClassName('main-categories--item--disabled');
          for (let i = 0; i < allHiddenProduct.length; i) {
            const el = allHiddenProduct[i];
            el.classList.remove('main-categories--item--disabled');
          }
        }}
      >
        <SVG type={SVG_TYPE.CLOSE} />
      </div>
    </div>
  );
};

export default SearchQueryBar;

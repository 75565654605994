import React from 'react';
import Nissan from 'assets/files/nissan.png';

const Finder = () => (
  <div className="finder">
    <div className="finder__image">
      <img src={Nissan} alt="marka pojazdu" />
    </div>
    <p className="finder--descript">
      Znaleźliśmy <span className="finder--descript--bold">4390</span> części do Twojego modelu auta
    </p>
  </div>
);

export default Finder;

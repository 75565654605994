const shoppingCartStickyRight = () => {
    const footer = document.getElementById('footer');
    const rightSection = document.getElementById('right-section');
    const rightSectionWrapper = document.getElementById('right-section--wrapper');
    const rightSectionInitialDistanceToTop = rightSection?.getBoundingClientRect().top;

    function checkOffset() {

    const rightSectionDistanceToTop = rightSection?.getBoundingClientRect().top;
    function getRectTop(el) {
        const rect = el && el.getBoundingClientRect();
        return el && rect.top;
    }
      
      if (rightSection) {
        if (getRectTop(rightSection) + document.body.scrollTop + rightSection.offsetHeight >=
          getRectTop(footer) + document.body.scrollTop - 44 ) {
          rightSectionWrapper && rightSectionWrapper.classList.add('right--section--sticky')
        } else if(rightSectionDistanceToTop > rightSectionInitialDistanceToTop){
          rightSectionWrapper && rightSectionWrapper.classList.remove('right--section--sticky')
        }
      }
    }

    document.addEventListener('scroll', () => {
      checkOffset();
    });

    return true;

  };
  
  export default shoppingCartStickyRight;
  
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

const SingleFilter = ({ label, partner }) => {
  const [isChecked, setIsChecked] = useState(false);
  const handleClickElement = () => {
    setIsChecked(!isChecked);
  };
  return (
    <div className={`single-filter ${isChecked ? 'single-filter--checked' : ''}`} onClick={handleClickElement}>
      <p className="single-filter--label">{label}</p>
      {partner && <SVG className="single-filter--image" type={SVG_TYPE.LOGO} />}
    </div>
  );
};

export default SingleFilter;

SingleFilter.propTypes = {
  label: PropTypes.string.isRequired,
  partner: PropTypes.bool.isRequired,
};

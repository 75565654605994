import { VIEW_MANAGEMENT } from 'store/types';

const INITIAL_STATE = {
  translation: '',
};

const viewManagementReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VIEW_MANAGEMENT.SET_APP_TRANSLATION_SUCCESS: {
      return {
        ...state,
        translation: action.payload.translation,
      };
    }
    default:
      return state;
  }
};

export default viewManagementReducer;

import React, { useEffect, useState } from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { shape, func, string } from 'prop-types';

const ResetPassword = ({ actions: { resetPassword }, intl: { formatMessage }, translation }) => {
  const [token, setToken] = useState(sessionStorage.getItem('emailReset'));
  useEffect(() => {
    setToken(token.replace(/['"]+/g, ''));
  }, []);
  const sendAgain = async () => {
    await resetPassword(token, translation);
  };
  return (
    <main className="login__wrapper">
      <div className="login__logo">
        <SVG type={SVG_TYPE.LOGO} />
      </div>
      <div className="login__form">
        <div className="login__items">
          <h1 className="login--header login--header--small">{formatMessage({ id: 'global.we_sent_a_message' })}</h1>
          <p className="login__paragraph login__paragraph--black">{formatMessage({ id: 'global.reset_click_here' })}</p>
          <p className="login__paragraph login__paragraph--bottom">
            {formatMessage({ id: 'global.dont_have_email' })}{' '}
            <span onClick={sendAgain}>{formatMessage({ id: 'global.send_it_again' })}</span>
          </p>
        </div>
      </div>
    </main>
  );
};

export default ResetPassword;

ResetPassword.propTypes = {
  actions: shape({
    resetPassword: func.isRequired,
  }).isRequired,
  translation: string.isRequired,
};

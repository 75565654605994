import React, { useEffect } from 'react';
import { Button } from 'shared/components';
import { bool, func, object,array } from 'prop-types';
import { AddDictionary } from 'screens/dictionary/components';

const TableHeading = ({
  isModalOpen,
  setIsModalOpen,
  formatMessage,
  addElement,
  menuType,
  settingsType,
  setSettingsType,
  editElement,
  restricted_word,
  isMobile,
}) => {
  const openModal = () => {
    setIsModalOpen(true);
  };
  useEffect(() => {
    settingsType.type === 'edit' && setIsModalOpen(true);
  }, [settingsType]);


  const Languages = lang => {
    switch (lang) {
      case 'PL': {
        return formatMessage({ id: 'inputs.polish' });
      }
      case 'DE': {
        return formatMessage({ id: 'inputs.germany' });
      }
      default: {
        return null;
      }
    }
  };
  return (
    <>
      <div className="table__heading">
        <div className="left">
          <span>{Languages(menuType.label)}</span>
        </div>
        <div className="right">
          <span>{Languages(menuType.label === 'DE' ? 'PL' : 'DE')}</span>
          {!isMobile && (
            <Button
              onClick={() => openModal()}
              label={formatMessage({ id: 'inputs.add_plus' })}
              className="btn--gray categoryManagement__header--button"
            />
          )}
        </div>
      </div>
      <AddDictionary
        editElement={editElement}
        menuType={menuType}
        addElement={addElement}
        formatMessage={formatMessage}
        isOpened={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        edit={settingsType.type === 'edit'}
        setSettingsType={setSettingsType}
        settingsType={settingsType}
        restricted_word={restricted_word}
      />
    </>
  );
};

export default TableHeading;

TableHeading.propTypes = {
  addElement: func.isRequired,
  editElement: func.isRequired,
  formatMessage: func.isRequired,
  isMobile: bool.isRequired,
  isModalOpen: bool.isRequired,
  menuType: object.isRequired,
  restricted_word:array.isRequired,
  setIsModalOpen: func.isRequired,
  setSettingsType: func.isRequired,
  settingsType: object.isRequired,
};

const BADGE_TYPES = {
  CLIENT: 'CLIENT',
  COORDINATOR: 'COORDINATOR',
  STOREMAN: 'STOREMAN',
  PARTNER: 'PARTNER',
  UNACTIVE: 'UNACTIVE',
  SUSPENDED: 'SUSPENDED',
};

export default BADGE_TYPES;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'shared/components';

const LABEL_BUTTON = formatMessage => ({
  VERY_GOOD: formatMessage({ id: 'global.very_good_condition' }),
  GOOD: formatMessage({ id: 'global.good_condition' }),
  SATISFACTORY: formatMessage({ id: 'global.satisfactory_condition' }),
});

const BUTTONS = formatMessage => [
  {
    label: LABEL_BUTTON(formatMessage).VERY_GOOD,
    status: 'very_good',
  },
  {
    label: LABEL_BUTTON(formatMessage).GOOD,
    status: 'good',
  },
  {
    label: LABEL_BUTTON(formatMessage).SATISFACTORY,
    status: 'acceptable',
  },
];

const ItemCondition = ({ setFieldValue, field: { name }, errors, form, formatMessage, data }) => {
  const [pickedItem, setPickedItem] = useState(false);
  useEffect(() => {
    setFieldValue(name, data.condition);
    setPickedItem(data.condition);
  }, []);
  const handleButtonClick = status => {
    setFieldValue(name, status);
    setPickedItem(status);
  };
  const isError = errors && !!(form.touched[name] && errors[name]);
  return (
    <section>
      <h3 id="validate--condition" className="heading">
        {formatMessage({ id: 'global.item_condition' })}
      </h3>
      <div className="item-condition__wrapper">
        <div className="item-condition">
          {BUTTONS(formatMessage).map(({ label, status }) => (
            <Button
              label={label}
              className={`btn--orange-border-color-black ${pickedItem === status ? 'btn--orange-fill-color-black' : ''}`}
              onClick={() => handleButtonClick(status)}
            />
          ))}
          {isError && <div className="item-condition--errors">{errors.condition}</div>}
        </div>
      </div>
    </section>
  );
};

export default ItemCondition;

ItemCondition.propTypes = {
  data: PropTypes.object.isRequired,
  errors: PropTypes.string.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  form: PropTypes.object.isRequired,
  formatMessage: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

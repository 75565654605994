import { connect } from 'react-redux';
import { setPassword } from 'store/actions/user';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import SetPassword from './SetPassword.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setPassword: data => setPassword(data),
    },
    dispatch,
  ),
});

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SetPassword));

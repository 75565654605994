import { connect } from 'react-redux';
import { isMobile as checkIsMobile } from 'shared/utils';
import { getAuthorizedUser } from 'store/selectors/userSelector';
import { getAllCategory, getAllCategoryAndSubcategory } from 'store/actions/category';
import { logout } from 'store/actions/auth';
import * as ViewManagement from 'store/actions/viewManagement';
import * as ViewManagementSelector from 'store/selectors/viewManagementSelector';
import * as UserActions from 'store/actions/user';
import { bindActionCreators } from 'redux';
import { getCategory, getCategoryAndSubcategory } from 'store/selectors/categorySelector';
import { getShoppingCart as getShoppingCartSelector } from 'store/selectors/shoppingCartSelector';
import { getShoppingCart, modifyShoppingCart } from 'store/actions/shopping-cart';
import { createCheckoutSession } from 'store/actions/payments';
import { cancelCheckout } from 'store/actions/checkout';
import { getDeliveryCountries as getDeliveryCountriesSelector } from 'store/selectors/deliverySelector';
import { getDeliveryCountries } from 'store/actions/delivery';
import MainRoutes from './MainRoutes.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getAllCategory: () => getAllCategory(),
      logout: () => logout(),
      getAllCategoryAndSubcategory: () => getAllCategoryAndSubcategory(),
      getAuthUser: UserActions.getAuthorizedUser,
      checkToken: (token, which) => UserActions.checkToken(token, which),
      setPrevPath: ViewManagement.setPrevPath,
      setAppTranslation: lang => ViewManagement.setAppTranslation(lang),
      getShoppingCart: uuid => getShoppingCart(uuid),
      modifyShoppingCart: uuid => modifyShoppingCart(uuid),
      createCheckoutSession: uuid => createCheckoutSession(uuid),
      cancelCheckout: checkout_uuid => cancelCheckout(checkout_uuid),
      getDeliveryCountries: () => getDeliveryCountries(),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  authorizedUser: getAuthorizedUser(state),
  isMobile: checkIsMobile(),
  categories: getCategory(state),
  categoryAndSubcategory: getCategoryAndSubcategory(state),
  translation: ViewManagementSelector.getTranslation(state),
  shoppingCart: getShoppingCartSelector(state),
  deliveryCountries: getDeliveryCountriesSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainRoutes);

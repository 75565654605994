import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import authReducer from './authReducer';
import categoryReducer from './categoryReducer';
import deliveryReducer from './deliveryReducer';
import viewManagementReducer from './viewManagementReducer';
import userReducer from './userReducer';
import partnerReducer from './partnerReducer';
import productsReducer from './productsReducer';
import carReducer from './carReducer';
import shoppingCartReducer from './shoppingCartReducer';
import checkoutReducer from './checkoutReducer';
import roomReducer from './roomReducer';
import customTranslationsReducer from './customTranslationsReducer';
import priceReducer from './priceReducer';
import allegroReducer from './allegro.reducer';

export const historyBrowser = createBrowserHistory();

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    allegroStore:allegroReducer,
    authStore: authReducer,
    categoryStore: categoryReducer,
    deliveryStore: deliveryReducer,
    viewManagementStore: viewManagementReducer,
    userStore: userReducer,
    partnerStore: partnerReducer,
    productsStore: productsReducer,
    carStore: carReducer,
    priceStore: priceReducer,
    shoppingCartStore: shoppingCartReducer,
    checkoutStore: checkoutReducer,
    roomStore: roomReducer,
    customTranslationsStore: customTranslationsReducer,
  });

export default rootReducer;

import React from 'react';

import { bool, func, string } from 'prop-types';
import { Modal, ModalActions } from 'shared/components';
import { MODAL_TYPE } from 'shared/consts';

const DeleteDictionaryModal = ({ isOpened, close, deleteElement, formatMessage }) => {
  const onClose = () => {
    close();
  };
  const onSubmit = () => {
    deleteElement()
    close()
  }
  return (
    <div>
      {isOpened && (
        <Modal className={MODAL_TYPE.CANCEL} onClose={onClose} opened={isOpened}>
          <div>
            <div className="modal__heading modal__heading--center">{formatMessage({ id: 'inputs.delete' })}</div>
            <div className="modal__description modal__description--center ">
              {formatMessage({ id: 'global.sure_delete_word' })}
            </div>
            <ModalActions formatMessage={formatMessage} type={MODAL_TYPE.DELETE_MODAL} onClose={close} onSubmit={onSubmit} />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default DeleteDictionaryModal;

DeleteDictionaryModal.defaultProps = {
  subcategory: false,
};
DeleteDictionaryModal.propTypes = {
  close: func.isRequired,
  deleteElement: func.isRequired,
  formatMessage: func.isRequired,
  isOpened: bool.isRequired,
  subcategory: bool,
  title: string.isRequired,
};

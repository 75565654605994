import { DELIVERY_COUNTRIES } from 'shared/consts';

const deliveryCountryOptions = (deliveryCountries, formatMessage) => {
  const arr = [];
  deliveryCountries.forEach(({ label, value }) =>
    arr.push({
      label: DELIVERY_COUNTRIES(formatMessage)[label],
      searchableLabel: DELIVERY_COUNTRIES(formatMessage)[label],
      value,
    }),
  );
  return arr;
};

export default deliveryCountryOptions;

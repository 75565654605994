import { bool, func, string } from 'prop-types';

import React from 'react';

const switchWithoutText = ({ checked, className, setFieldValue = false, name, onClick=false }) => {
  const handleChange =()=> {

    setFieldValue &&  setFieldValue(name, !checked);
    onClick && onClick(!checked)
  };
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={`switchWithoutText ${className || ''}`} id="switchWithoutText">
      <input type="checkbox" className="checkbox" checked={checked} onClick={() => handleChange()} />
      <span className="switchWithoutText__slider switchWithoutText__slider--round" />
    </label>
  );
};

export default switchWithoutText;

switchWithoutText.propTypes = {
  checked: bool.isRequired,
  formatMessage: func.isRequired,
  name: string.isRequired,
  setFieldValue: func.isRequired,
};

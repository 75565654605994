const SHOPPING_CART = {
  CREATE_ROOM_INIT: 'CREATE_ROOM_INIT',
  CREATE_ROOM_FAILURE: 'CREATE_ROOM_FAILURE',
  CREATE_ROOM_SUCCESS: 'CREATE_ROOM_SUCCESS',
  GET_ROOM_MESSAGES_INIT: 'GET_ROOM_MESSAGES_INIT',
  GET_ROOM_MESSAGES_FAILURE: 'GET_ROOM_MESSAGES_FAILURE',
  GET_ROOM_MESSAGES_SUCCESS: 'GET_ROOM_MESSAGES_SUCCESS',
  GET_ROOM_INIT: 'GET_ROOM_INIT',
  GET_ROOM_FAILURE: 'GET_ROOM_FAILURE',
  GET_ROOM_SUCCESS: 'GET_ROOM_SUCCESS',
  SEND_MESSAGE_INIT: 'SEND_MESSAGE_INIT',
  SEND_MESSAGE_FAILURE: 'SEND_MESSAGE_FAILURE',
  SEND_MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS',
  UPDATE_CHAT_STATUS_SUCCESS: 'UPDATE_CHAT_STATUS_SUCCESS',
  UPDATE_CHAT_STATUS_FAILURE: 'UPDATE_CHAT_STATUS_FAILURE',
};

export default SHOPPING_CART;

import { PRODUCTS } from 'store/types';

export const success = () => ({
  type: PRODUCTS.CLEANING_ALL_PRODUCTS,
});

const cleaningAllProducts = () => dispatch => {
  try {
    dispatch(success());
    return true;
  } catch (err) {
    return false;
  }
};

export default cleaningAllProducts;

import React, { useEffect } from 'react';
import { func, shape, object } from 'prop-types';
import { ProgressIndicator } from 'shared/components';
import { shoppingCartStickyRight } from 'shared/utils';
import { Left, Right, EmptyBasket } from './components';

const Basket = ({
  intl: { formatMessage },
  actions: { getShoppingCart, getCheckoutAddress, modifyShoppingCart, addToFavoritesCheckout, removeFromFavoritesCheckout },
  shoppingCart,
  authorizedUser,
}) => {
  useEffect(async () => {
    !shoppingCart && (await getShoppingCart());
  }, []);

  useEffect(async () => {
    authorizedUser && (await getCheckoutAddress());
  });

  useEffect(()=> {
    shoppingCartStickyRight()
  },[]);

  return (
    <main className="checkout">
      <div className="sticky">
        <ProgressIndicator formatMessage={formatMessage} />
      </div>
      <div className="checkout--content">
        {shoppingCart.products_amount === 0 ? (
          <EmptyBasket formatMessage={formatMessage} />
        ) : (
          <>
            <Left
              authorizedUser={authorizedUser}
              shoppingCart={shoppingCart}
              modifyShoppingCart={modifyShoppingCart}
              addToFavorites={addToFavoritesCheckout}
              removeFromFavorites={removeFromFavoritesCheckout}
              formatMessage={formatMessage}
            />
            <Right data={shoppingCart} formatMessage={formatMessage} />
          </>
        )}
      </div>
    </main>
  );
};

export default Basket;

Basket.propTypes = {
  actions: shape({
    addToFavorites: func.isRequired,
    modifyShoppingCart: func.isRequired,
    getShoppingCart: func.isRequired,
    getCheckoutAddress: func.isRequired,
    removeFromFavoritesCheckout: func.isRequired,
  }).isRequired,
  authorizedUser: object.isRequired,
  formatMessage: func.isRequired,
  shoppingCart: object.isRequired,
};

import React from 'react';
import { Button } from 'shared/components';
import { func, object, number } from 'prop-types';
import { ROUTES, USER_ROLES } from 'shared/consts';
import { useHistory } from 'react-router-dom';

const ProductButtons = ({ amount, formatMessage, modifyShoppingCart, data, authorizedUser, shoppingCart }) => {
  const countryUuid = localStorage.getItem('deliveryCountry');
  const isActive = !authorizedUser || authorizedUser.role === USER_ROLES.PARTNER || authorizedUser.role === USER_ROLES.CLIENT;
  const history = useHistory();
  const ifProductIsInBasket = data.uuid && shoppingCart?.shopping_cart?.products.find(item => item.uuid === data.uuid);
  const CheckoutUuid = localStorage.getItem('checkoutUuid');
  const unpaidOrderEndTime = localStorage.getItem('orderEndTime');
  const isUnpaidOrder = CheckoutUuid && unpaidOrderEndTime;
  const buttonOnClick = async buy_now => {
    if (isUnpaidOrder) {
      document.getElementById('basket').click();
    } else {
      await modifyShoppingCart({
        amount,
        update_option: 'reset',
        product_uuid: data.uuid,
        user_id: authorizedUser && data.user_id,
        country_uuid: !authorizedUser ? countryUuid : authorizedUser.country.label,
      });
      buy_now && history.push(ROUTES.CHECKOUT);
    }
  };

  return (
    <div className="product-buttons">
      <Button
        onClick={() => buttonOnClick(false)}
        label={`${ifProductIsInBasket ? formatMessage({ id: 'global.update' }) : `+ ${formatMessage({ id: 'inputs.to_cart' })}`}`}
        className={`btn--orange-border ${data?.own_product ? 'btn--orange-border--disabled' : ''} ${
          !isActive ? 'btn--orange-border--disabled' : ''
        }`}
      />
      <Button
        onClick={async () => {
          await buttonOnClick(true);
        }}
        label={formatMessage({ id: 'inputs.buy_now' })}
        className={`btn--orange btn--orange--sharp ${data?.own_product ? 'btn--orange--sharp--disabled' : ''} ${
          !isActive ? 'btn--orange--sharp--disabled' : ''
        }`}
      />
    </div>
  );
};

export default ProductButtons;

ProductButtons.propTypes = {
  amount: number.isRequired,
  amountInBasket: object.isRequired,
  authorizedUser: object.isRequired,
  data: object.isRequired,
  formatMessage: func.isRequired,
  modifyShoppingCart: func.isRequired,
  shoppingCart: object.isRequired,
};

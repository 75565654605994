import React from 'react';
import { Button, SVG } from 'shared/components';
import { SVG_TYPE, USER_ROLES } from 'shared/consts';
import { object, func, string } from 'prop-types';
import { SUBSCRIPTION } from 'screens/partner-program/const';

const BecomePartner = ({ setSelectedSubscription, formatMessage, translation, authorizedUser }) => {
  const addressLang = authorizedUser ? authorizedUser?.country?.short.toLowerCase() : translation;
  const isAvaliable = (authorizedUser && authorizedUser.role !== USER_ROLES.PARTNER) || !authorizedUser;

  return (
    <div className="partnerProgram__premium">
      <h3 className="partnerProgram__premium--heading">{formatMessage({ id: 'global.partner_why_worth_it' })}</h3>
      <p className="partnerProgram__premium--paragraph">{formatMessage({ id: 'global.partner_why_worth_id_descript' })}</p>
      <div className="partnerProgram__premium--tiles">
        <div className="partnerProgram__premium__tile">
          <p className="partnerProgram__premium__tile--heading">{formatMessage({ id: 'global.standard' })}</p>
          <p className="partnerProgram__premium__tile--price">
            {addressLang === 'pl' ? `500 zł` : '125 eur'}{' '}
            {addressLang === 'pl' ? <span className="netto">netto</span> : <span className="netto">netz</span>}
          </p>
          <p className="partnerProgram__premium__tile--paragraph">
            {formatMessage({ id: 'global.subscrtiption_monthly_paragraph' })}
          </p>
          <p className="partnerProgram__premium__tile--button">
            <Button
              label={formatMessage({ id: 'inputs.go_to_the_application' })}
              className={`${isAvaliable ? 'btn--orange' : 'btn--disabled-gray'}`}
              iconType={SVG_TYPE.ARROW_RIGHT}
              onClick={() => setSelectedSubscription({ isOpened: true, selected: SUBSCRIPTION.STANDARD })}
            />
          </p>
        </div>
        <div className="partnerProgram__premium__tile partnerProgram__premium__tile--pr">
          <p className="partnerProgram__premium__tile--heading ">{formatMessage({ id: 'global.premium' })}</p>
          <p className="partnerProgram__premium__tile--price partnerProgram__premium__tile--price--pr">
            {formatMessage({ id: 'global.individual_conditions' })}
          </p>
          <p className="partnerProgram__premium__tile--paragraph">
            {formatMessage({ id: 'global.subscrtiption_monthly_paragraph' })}
          </p>
          <p className="partnerProgram__premium__tile--button">
            <Button
              label={formatMessage({ id: 'inputs.go_to_the_application' })}
              className={`${isAvaliable ? 'btn--orange btn--white' : 'btn--disabled-gray'}`}
              iconType={SVG_TYPE.ARROW_RIGHT}
              onClick={() => setSelectedSubscription({ isOpened: true, selected: SUBSCRIPTION.PREMIUM })}
            />
          </p>
          <div className="icon">
            <SVG type={SVG_TYPE.STAR_CIRCLE} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BecomePartner;

BecomePartner.propTypes = {
  authorizedUser: object.isRequired,
  formatMessage: func.isRequired,
  setSelectedSubscription: object.isRequired,
  translation: string.isRequired,
};

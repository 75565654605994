import React from 'react';
import * as Connector from 'shared/connectors/productsConnector';
import { SHOPPING_CART } from 'store/types';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import { NOTIFY_TYPE } from 'shared/consts';

export const success = uuid => ({
  type: SHOPPING_CART.ADD_FAVORITES,
  payload: {
    uuid,
  },
});

const addToFavoritesCheckout = uuid => async dispatch => {
  try {
    const { data } = await Connector.addToFavorites(uuid);
    dispatch(success(uuid));
    toast(<Notify label="global.added_to_watchlist" type={NOTIFY_TYPE.ADD_TO_FAVORITE} />);
    return data;
  } catch (err) {
    return err.response;
  }
};

export default addToFavoritesCheckout;

import React from 'react';
import { Button } from 'shared/components';
import Guaranted from 'assets/files/guaranted.png';

const AdvertisementTile = () => (
  <div className="advertisement-tile">
    <h3 className="advertisement-tile--heading">Rozwijaj swój biznes</h3>
    <h3 className="advertisement-tile--heading">To miejsce czeka na Ciebie</h3>
    <p className="advertisement-tile--paragraph">Tutaj kupisz części i sprzedasz je gdzie </p>
    <p className="advertisement-tile--paragraph">tylko chcesz w całej europie</p>
    <div className="advertisement-tile--actions">
      <Button label="zobacz więcej" className="btn--white-border" />
    </div>
    <img className="advertisement-tile--image" src={Guaranted} alt="engine" />
  </div>
);

export default AdvertisementTile;

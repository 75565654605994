import React from 'react';
import { Button } from 'shared/components';
import { func, object } from 'prop-types';
import { USER_ROLES } from 'shared/consts';

const countryUuid = localStorage.getItem('deliveryCountry');
const ProductButtons = ({ formatMessage, modifyShoppingCart, data, authorizedUser }) => {
  const isActive = !authorizedUser || authorizedUser.role === USER_ROLES.PARTNER || authorizedUser.role === USER_ROLES.CLIENT;
  return (
    <div className="product-buttons">
      <Button
        onClick={() =>
          modifyShoppingCart({
            product_uuid: data.uuid,
            user_id: authorizedUser && data.user_id,
            country_uuid: !authorizedUser && countryUuid,
          })
        }
        label={`+ ${formatMessage({ id: 'inputs.to_cart' })}`}
        className={`btn--orange-border ${data?.stock?.in_stock === 0 ? 'btn--orange-border--disabled' : ''} ${
          !isActive ? 'btn--orange-border--disabled' : ''
        }`}
      />
      <Button label={formatMessage({ id: 'inputs.buy_now' })} className="btn--orange btn--orange--sharp" />
    </div>
  );
};

export default ProductButtons;

ProductButtons.propTypes = {
  authorizedUser: object.isRequired,
  data: object.isRequired,
  formatMessage: func.isRequired,
  modifyShoppingCart: func.isRequired,
};

import React from 'react';
import * as Connector from 'shared/connectors/userConnector';
import { getUsersByRole } from 'store/actions/user';
import { NOTIFY_TYPE } from 'shared/consts';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';

// export const success = () => ({
//   type: USER.EDIT_USER_SUCCESS,
// });

const editUser = (uuid, form, group) => async dispatch => {
  try {
    const data = await Connector.editUser(uuid, form);
    toast(<Notify label="global.user_editted" type={NOTIFY_TYPE.CHECKED} />);
    // dispatch(success(data));
    dispatch(getUsersByRole(group));
    return data;
  } catch (err) {
    console.log(err)
    return err.response.status;
  }
};

export default editUser;

import * as Connector from 'shared/connectors/checkoutConnector';
import { CHECKOUT } from 'store/types';

export const success = () => ({
  type: CHECKOUT.SET_CHECKOUT_SUCCESS,
});

const notLoggedUserBasketUuid = localStorage.getItem('notLoggedUserBasketUuid');
const isUserLogged = localStorage.getItem('isUserLogged');
const setCheckout = form => async () => {
  const summary = localStorage.getItem('checkout');
  localStorage.removeItem('summary');
  try {
    if (notLoggedUserBasketUuid && !isUserLogged) {
      form.shopping_cart_uuid = notLoggedUserBasketUuid;
    }
    const { data } = await Connector.setCheckout(summary);
    if (data.status === 'success' && form.pay_later) {
      return { pay_later: true };
    }
    localStorage.setItem('checkoutUuid', data.checkout.uuid);
    return data.checkout;
  } catch (err) {
    return err.response;
  }
};

export default setCheckout;

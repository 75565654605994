import * as Connector from 'shared/connectors/userConnector';
import { USER } from 'store/types';

const init = () => ({
  type: USER.SEND_RESET_PASSWORD_EMAIL_INIT,
});

export const success = () => ({
  type: USER.SEND_RESET_PASSWORD_EMAIL_SUCCESS,
});

const failure = () => ({
  type: USER.SEND_RESET_PASSWORD_EMAIL_FAILURE,
});

const sendResetPasswordEmail = (email, translation) => async dispatch => {
  dispatch(init());
  try {
    const data = await Connector.resetPassword(email, translation);
    dispatch(success());
    return data;
  } catch (err) {
    dispatch(failure());
    return false;
  }
};

export default sendResetPasswordEmail;

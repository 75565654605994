import React from 'react';
import PropTypes from 'prop-types';
import { SVG } from 'shared/components';
import { SVG_TYPE, ROUTES } from 'shared/consts';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const SingleCategory = ({ label, count, products_count, className, changeCategory, link, back, files, cleaningAllProducts }) => {
  const { pathname, search } = window.location;
  const isSearching = search.slice(0, 2) === '?s';
  const history = useHistory();
  const isSearchingCategory = search.search('main_cat=') > 0;
  const handleRedirect = () => {
    cleaningAllProducts && cleaningAllProducts();
    !changeCategory && !isSearching && history.push(`${ROUTES.CATEGORY}?s=&main_cat=${link}`);
    isSearching && !isSearchingCategory && history.push(`${pathname}${search}&main_cat=${link}`);
  };

  return (
    <section
      className={`single-category ${!changeCategory && products_count <= 0 ? `single-category--disabled` : ''} ${className}`}
      onClick={handleRedirect}
    >
      <div className="single-category--left">
        <h3 className="single-category--label">{label}</h3>
        {count > 0 && (
          <p className="single-category--quantity ">
            {count} <FormattedMessage id="global.categories" /> {products_count > 0 && `(${products_count})`}
          </p>
        )}
        {changeCategory && (
          <div
            className="product-sidebar--change"
            onClick={() => {
              back(true);
            }}
          >
            <SVG className="product-sidebar--change--icon" type={SVG_TYPE.CHEVRONE} />
            <FormattedMessage id="global.change_category" />
          </div>
        )}
      </div>
      <div className="single-category__right">
        {files && <img className="single-category__right--image" src={files[0]?.path} alt={files[0]?.name} />}
      </div>
    </section>
  );
};

export default SingleCategory;

SingleCategory.defaultProps = {
  back: null,
  changeCategory: false,
  className: '',
  cleaningAllProducts: null,
  count: '',
  link: '',
  products_count: '',
};
SingleCategory.propTypes = {
  back: PropTypes.func,
  changeCategory: PropTypes.bool,
  className: PropTypes.string,
  cleaningAllProducts: PropTypes.func,
  count: PropTypes.number,
  files: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
  products_count: PropTypes.string,
};

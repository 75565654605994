import React from 'react';
import { array } from 'prop-types';

const DeliveryList = ({sameDelivery, delivery}) => (
  <div className='delivery'>
    <div className="delivery__list">
      {delivery.length > 0 && delivery.map((element) => {
        sameDelivery.push(element)
        return (
          <div className="delivery-item delivery__item">
            <div className='delivery__item__image--wrapper'>
              <img className='delivery__item__image' src={element.files && element.files.length > 0 && element.files[0].path} alt="" />
            </div>
            <div className="delivery__item--label">
              {element.name}
            </div>
            <div className="delivery__item--price-wrapper">
              {element.price} {element.currency && element.currency.currency.toUpperCase()}
            </div>
          </div>
        )})}
    </div>
  </div>
)

export default DeliveryList;

DeliveryList.propTypes = {
    delivery: array.isRequired,
    sameDelivery: array.isRequired,
}
const ROUTES = {
  HOME_PAGE: '/',
  CATEGORY: '/category',
  CATEGORY_NAME: '/category?s=&main_cat=:category',
  SUBCATEGORY_NAME: '/category?s=&main_cat=:category&cat=:subcategory',
  DASHBOARD: '/dashboard',
  ADVERTISEMENTS: '/advertisements',
  EDIT_ADVERTISEMENTS: '/edit-advertisement',
  SINGLE_PRODUCT: '/category/:category/:product',
  // SINGLE_PRODUCT: '/marka/model/kategoria/single-product',
  LISTS_PRODUCT: '/marka/model/kategoria',
  LOGIN: '/login',
  SEND_ACTIVATE_MAIL: '/send-activate-email',
  ACTIVATE_ACCOUNT: '/activate-account',
  INACTIVE_ACCOUNT: '/inactive-account',
  RESET_PASSWORD: '/reset-password',
  REMIND_PASSWORD: '/remind-password',
  NEW_PASSWORD: '/new-password',
  REGISTER: '/register',
  TOKEN_EXPIRED: '/token-expired',
  INVOICES: '/invoices',
  WATCHED_PRODUCTS: '/watched-products',
  PARTNER_PROGRAM: '/partner-program',
  CATEGORY_MANAGEMENT: '/category-management',
  USER_MANAGEMENT: '/user-management',
  PRICE_MANAGEMENT: '/price-management',
  PAGE_404: '/404',
  ORDERS: '/orders',
  LOGOUT: '/logout',
  SETTINGS: '/settings',
  DELIVERY_OPTIONS: '/delivery-options',
  SET_PASSWORD: '/set-password',
  USER_BY_ADMIN_TOKEN_EXPIRED: '/bad-token',
  CONTACT: '/contact',
  ABOUT_US: '/about-us',
  MAGAZINE: '/magazine',
  BASKET: '/basket',
  CHECKOUT: '/checkout',
  DICTIONARY: '/dictionary',
  CHAT: '/chat',
  PAYMENT_ACCEPTED: '/payment-accepted',
  PAYMENT_CANCELED: '/payment-canceled',
  ADD_ADVERTISEMENT: '/add-advertisement',
  ALLEGRO_CONNECTION: '/allegro-connection',
  SUMMARY: '/summary',
  PAY_LATER: '/pay-later',
  PRODUCT_PREVIEW: '/product-preview',
};

export default ROUTES;

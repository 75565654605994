import * as Connector from 'shared/connectors/userConnector';
import { USER } from 'store/types';

const init = () => ({
  type: USER.CHECK_TOKEN_INIT,
});

export const success = data => ({
  type: USER.CHECK_TOKEN_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: USER.CHECK_TOKEN_FAILURE,
});

const checkToken = (token, which) => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.checkToken(token, which);
    dispatch(success(data));
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default checkToken;

const carModelsOptions = models => {
  const arr = [];
  models.forEach(({ label, value }) =>
    arr.push({
      label: value,
      searchableLabel: value,
      value: label,
    }),
  );
  return arr;
};

export default carModelsOptions;

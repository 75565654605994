import { bool, func, string } from 'prop-types';

import React from 'react';

const Switch = ({ formatMessage, checked, className, setFieldValue, name }) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label className={`switch ${className || ''}`} id="Switch">
    <input type="checkbox" className="checkbox" checked={!checked} onClick={() => setFieldValue(name, !checked)} />
    <span className="switch__slider switch__slider--round" />
    <p className="switch__slider--text">{formatMessage({ id: 'global.yes' })}</p>
    <p className="switch__slider--text">{formatMessage({ id: 'global.no' })}</p>
  </label>
);

export default Switch;

Switch.propTypes = {
  checked: bool.isRequired,
  formatMessage: func.isRequired,
  name: string.isRequired,
  setFieldValue: func.isRequired,
};

import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { func, object } from 'prop-types';
import { Address } from 'screens/summary/components';

const Left = ({ formatMessage, summary }) => {
  const { owners } = summary.data;
  return (
    <div className="left-side">
      <Address summary={summary} formatMessage={formatMessage} />
      <div className="content">
        {Object.keys(owners)?.map(item => {
          const { products, first_name, last_name, id, foreign_country, deliveries } = owners[item];
          return (
            <>
              <div className={`seller-box seller-box--${id}`}>
                <div className="seller-box--wrapper">
                  <div className="seller-box--name">
                    <p>{formatMessage({ id: 'global.seller' })}:</p>
                    <span>
                      {!foreign_country && first_name} {!foreign_country && last_name} {foreign_country && 'GETMOTOR'}{' '}
                    </span>
                  </div>
                  {Object.keys(products).map((key, ajdi) => {
                    const { files, price, currency, title, quantity, total_price } = products[key];
                    return (
                      <>
                        <div id={`product--${products[key].uuid}`} className="seller-box__product--wrapper">
                          <div className="seller-box__product--item">
                            <div className="seller-box__product--image-box">
                              {files.length > 0 && files[0] ? (
                                <img
                                  className="seller-box__product--image"
                                  src={files && files.length > 0 && files[0].path}
                                  alt=""
                                />
                              ) : (
                                <SVG className="seller-box__product--image" type={SVG_TYPE.DEFAULT_PHOTO} />
                              )}
                            </div>
                            <div className="seller-box__product--mid">
                              <div className="seller-box__product--name">{title}</div>
                              <div className="seller-box__product--descript">
                                {/* <div><p>marka:</p> <span>{products[key]?.brand && products[key]?.brand[0]?.brand}</span></div>
                                <div><p>model:</p> <span>{products[key]?.model && products[key]?.model[0]?.model}</span></div> */}
                              </div>
                            </div>
                            <div className="seller-box__product__price--wrapper">
                              <div className="seller-box__product__price">
                                {price} {currency}
                              </div>
                              <div className="seller-box__product__amount">x{quantity}</div>
                              <div className="seller-box__product__all-price">
                                {total_price} <span className="currency">{currency}</span>
                              </div>
                            </div>
                          </div>
                          <div className="method">{formatMessage({ id: 'global.delivery_method' })}:</div>
                          {Object.keys(deliveries).map((element, index) => (
                            <>
                              {index === ajdi && (
                                <div className="delivery__item-set">
                                  <div className="delivery__item__image--wrapper">
                                    <img
                                      className="delivery__item__image"
                                      src={
                                        deliveries[element].files &&
                                        deliveries[element].files.length > 0 &&
                                        deliveries[element].files[0].path
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="delivery__item--label">{deliveries[element].name}</div>
                                  <div className="delivery__item--price-wrapper">
                                    {deliveries[element].price} {currency.toUpperCase()}
                                  </div>
                                </div>
                              )}
                            </>
                          ))}
                          {/* <div className="delivery__item-set">
                            <div className="delivery__item__image--wrapper">
                              <img
                                className="delivery__item__image"
                                src={delivery[0].files && delivery[0].files.length > 0 && delivery[0].files[0].path}
                                alt=""
                              />
                            </div>
                            <div className="delivery__item--label">{delivery[0].name}</div>
                            <div className="delivery__item--price-wrapper">
                              {delivery[0].price} {currency.toUpperCase()}
                            </div>
                          </div> */}
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </>
          );
        })}
      </div>
      <div className="right">
        <div className="heading">{formatMessage({ id: 'global.payment_method' })}</div>
        <SVG type={SVG_TYPE.PRZELEWY_24} />
        <p className="text">{formatMessage({ id: 'global.to_complete_the_payment' })}</p>
      </div>
    </div>
  );
};

export default Left;

Left.propTypes = {
  formatMessage: func.isRequired,
  summary: object.isRequired,
};

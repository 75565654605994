import * as Connector from 'shared/connectors/productsConnector';
import { PRODUCTS } from 'store/types';

const init = () => ({
  type: PRODUCTS.GET_FAVORITE_PRODUCTS_INIT,
});

export const success = data => ({
  type: PRODUCTS.GET_FAVORITE_PRODUCTS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: PRODUCTS.GET_FAVORITE_PRODUCTS_FAILURE,
});

const getFavoriteProducts = () => async dispatch => {
  dispatch(init());
  try {
    const {
      data: { products },
    } = await Connector.getFavoriteProducts();
    dispatch(success(products));
    return products;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default getFavoriteProducts;

import React from 'react';
import { func, object } from 'prop-types';

const Address = ({ formatMessage, summary }) => {
  const { first_name, last_name, phone_prefix, phone_number, email, city, address, post_code } = summary.transaction_data;
  return (
    <div className="content">
      <div className="heading">{formatMessage({ id: 'global.delivery_address' })}</div>
      <>
        <div className="address-box__wrapper">
          <div className="address-box__content">
            <p>{formatMessage({ id: 'global.delivery_address' })}</p>
            <div className="address-box">
              <div>
                <span>
                  {first_name} {last_name}
                </span>
                <span>{email}</span>
                <span>{address}</span>
                <span>
                  {post_code} {city}
                </span>
                <span>
                  {phone_prefix} {phone_number}
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Address;

Address.propTypes = {
  formatMessage: func.isRequired,
  summary: object.isRequired,
};
